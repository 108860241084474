export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTH_MAPPING = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "Nov",
  Dec: "December",
};

export const MONTH_NAME_NUMBER_MAP = {
  Jan: "1",
  Feb: "2",
  Mar: "3",
  Apr: "4",
  May: "5",
  Jun: "6",
  Jul: "7",
  Aug: "8",
  Sep: "9",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const MONTH_NUMBER_NAME_MAP = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const qualityParamMapArray = [
  { col: "c_time", displayText: "Time" },
  { col: "c_cost", displayText: "Cost" },
  { col: "c_quality", displayText: "Quality" },
  { col: "c_scope", displayText: "Scope" },
  { col: "c_h_s", displayText: "H&S" },
  { col: "c_sme_self_assessments", displayText: "SME" },
];

export const TIME_LIMIT_TRAIN_MODEL_PROGRESS_SCREEN = 900000;
