export function escapeFilterValue(filterJsonObject) {
  let encodedFilterJsonObject = {};
  Object.entries(filterJsonObject).forEach((filterItem) => {
    let encodedFilterValueArray = [];
    filterItem[1].forEach((filterValue) => {
      encodedFilterValueArray.push(encodeURIComponent(filterValue));
    });
    encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
  });
  return encodedFilterJsonObject;
}
