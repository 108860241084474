import React, { useState, useEffect } from "react";
import { Button, Col, Modal, ModalBody, Row, Spinner, Table } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, ToggleButton } from "@material-ui/lab";
import BarChartIcon from "@material-ui/icons/BarChart";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import chroma from "chroma-js";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";
import "jspdf-autotable";

import HorizontalStackChart from "../../currentstatus/charts/HorizontalStackChart";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

export default function ProjectCompletionChartDetailsView({
  closeModal,
  activeInfo,
}) {
  const [projectTabSelected, setProjectSelected] = useState(false);
  const [projectData, SetProjectData] = useState(null);
  const [activityData, SetActivityData] = useState(null);
  const [toggleActivity, SetToggleActivity] = useState(true);
  useEffect(() => {
    if (activeInfo?.activeData) {
      const { activities, projects } = activeInfo.activeData;
      if (activities) {
        SetActivityData(activities);
      }
      if (projects) {
        SetProjectData(projects);
      }
    }
  }, [activeInfo]);

  if (!activeInfo) {
    return <div></div>;
  }

  return (
    <>
      <div>
        <Modal
          isOpen={true}
          centered={true}
          size="lg"
          className="tcqs-current-modal"
        >
          <ModalBody
            id="show-key-activities-modal"
            className="p-0 tcqs-current-modal-body"
            style={{ height: "83.5vh" }}
          >
            <div className="p-2">
              <CancelIcon onClick={closeModal} className="cancel-close-icon" />
              <Row>
                <Col>
                  <center>
                    <span id="key-tasks-to-text" style={{ color: "#252525" }}>
                    Critical activities with a count of the number of projects that have completed {activeInfo.percent} activities of {activeInfo.label} phase.
                    </span>
                  </center>
                </Col>
              </Row>
            </div>
            <Row className="m-0 menu-tabs">
              <Col
                className={`text-right ${
                  projectTabSelected === false ? "selected" : ""
                }`}
                onClick={() => setProjectSelected(false)}
              >
                <BootstrapTooltip
                placement="left"
                title={
                  <>
                  <div>
                  The bar chart represents the count of projects with their BRAG status for each activity that feeds into the selected phase of project
                  </div>
                  <div className="mt-2">
                    <ul>
                      <li>Red - needs attention or action</li>
                      <li>Amber - a risk but not an issue yet</li>
                      <li>Green - on track, no issues</li>
                      <li>Blue - complete</li>
                      <li>Grey - expired, not required, not due yet</li>
                      <li>NA - data not available</li>
                    </ul>
                  </div>
                  </>
                }
                >
                <span>List of Activities</span>
                </BootstrapTooltip>
              </Col>
              <Col
                className={projectTabSelected ? "selected" : ""}
                onClick={() => setProjectSelected(true)}
              >
                <BootstrapTooltip
                placement="right-start" 
                title={
                  <>
                  <div>
                  ​The table represents the list of projects that fall in the selected phase and their percent complete category. The columns represent the activities that feed into the phase. The colour of the dot represents the BRAG status  of the activity for each project
                  </div>
                  <div className="mt-2">
                    <ul>
                      <li>Red - needs attention or action</li>
                      <li>Amber - a risk but not an issue yet</li>
                      <li>Green - on track, no issues</li>
                      <li>Blue - complete</li>
                      <li>Grey - expired, not required, not due yet</li>
                      <li>NA - data not available</li>
                    </ul>
                  </div>
                  </>
                }
                >
                <span>List of Projects</span>
                </BootstrapTooltip>
              </Col>
            </Row>
            {projectTabSelected === false ? (
              <ListActivity
                activityData={activityData}
                activeInfo={activeInfo}
                toggleActivity={toggleActivity}
                SetToggleActivity={SetToggleActivity}
              />
            ) : (
              <ListProject projectData={projectData} activeInfo={activeInfo} 
              toggleActivity={toggleActivity}
              SetToggleActivity={SetToggleActivity} />
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

// Activity Details

const criticalActivityColumns = Object.freeze({
  Identify: [
    "Requirements statement",
    "Risk and value 0",
    "Delivery route selection",
  ],
  Define: [
    "Risk and value 1",
    "Constructability review",
    "Notional solution",
    "Risk and value 2",
    "EWI package",
    "Risk review",
    "Gateway review 2",
  ],
  Deliver: [
    "Award contract",
    "Risk and value 3",
    "Outline design",
    "Detailed design",
    "Telemetry RAG status",
    "FRM CDM letter to principal contractor",
    "Work authorisation",
    "Has CS approved SoS",
    "Start on site",
    "Contract management",
    "Overall ASE assessment",
    "Agreement to operate",
  ],
  Close: [
    "Project in use",
    "Completion date",
    "Operational takeover certificate",
    "Final acceptance",
    "Final acceptance sign-off",
  ],
});

function ListActivity({ activityData, activeInfo, toggleActivity, SetToggleActivity}) {
  
  const [viewActivity, SetViewActivity] = useState(false);

  useEffect(() => {
    if (toggleActivity) {
      const filterCol = [...criticalActivityColumns[activeInfo?.label]];
      let data = activityData;
      if (filterCol) {
        data = {};
        filterCol.forEach((item) => {
          data[item] = activityData[item] || {};
        });
      }
      SetViewActivity(data);
      console.log("data", data);
    } else {
      SetViewActivity(activityData);
    }
  }, [toggleActivity, activityData, activeInfo.label]);
  const empty = (
    <>
      <div style={{ height: "55.5vh" }}>
        <br />
        <div className="no-data-available-current-month-activity">
          No data available of list of activities
        </div>
      </div>
    </>
  );
  if (!viewActivity) {
    return empty;
  }

  return (
    <>
      <ToggleActivities
        toggleActivity={toggleActivity}
        SetToggleActivity={SetToggleActivity}
      />
      <HorizontalStackChart
        qualityValue={activeInfo?.label}
        qualityParam={activeInfo?.percent}
        stackChartData={viewActivity}
        summaryPage={true}
      />
    </>
  );
}

const ToggleActivities = ({ toggleActivity, SetToggleActivity }) => {
  return (
    <>
      <div className="p2">
        <Row>
          <Col style={{ fontFamily: "Ubuntu-Medium" }}>
            <center>
              <span style={{ float: "left" }}>
                <BootstrapTooltip
                 title="Toggle between critical and all activities"
                  // title={
                  //   toggleActivity === false
                  //     ? "Click here to display critical activities"
                  //     : "Click here to display all activities"
                  // }
                  placement="right"
                >
                  <ToggleButton
                    value="check"
                    selected={!toggleActivity}
                    onChange={() => {
                      SetToggleActivity(!toggleActivity);
                    }}
                    size="small"
                    style={{
                      backgroundColor: "#268cd1",
                      marginLeft: "0.3rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    {toggleActivity === false ? (
                      <BarChartIcon
                        style={{ color: "#FFFFFF" }}
                        fontSize="small"
                      />
                    ) : (
                      <span
                        style={{
                          transform: "rotate(-90deg)",
                          fontSize: "0.2rem",
                        }}
                      >
                        <SortRoundedIcon
                          style={{ color: "#FFFFFF" }}
                          fontSize="small"
                        />
                      </span>
                    )}
                  </ToggleButton>
                </BootstrapTooltip>
              </span>
            </center>
          </Col>
        </Row>
      </div>
    </>
  );
};

// Project Details
function ListProject({ projectData, activeInfo , toggleActivity, SetToggleActivity }) {
  const empty = (
    <>
      <div style={{ height: "55.5vh" }}>
        <br />
        <div className="no-data-available-current-month-activity">
          No data available of list of project
        </div>
      </div>
    </>
  );
  const [masterProjectData, SetMasterProjectData] = useState(projectData);
  const [projectSelectionOption, SetProjectSectionOption] = useState([]);
  const [viewProject, SetViewProject] = useState([]);
  const [filterProject, SetFilterProject] = useState(null);
  const [tableHeader, SetTableHeaderColumns] = useState([]);
  const [headerActiveFilter, SetHeaderActiveFilter] = useState(null);
  const [isDownloading, SetIsDownloading] = useState(false);

  useEffect(() => {
    if (projectData && Array.isArray(projectData)) {
      let prjData = [];
      if (toggleActivity) {
        let filterCol = [...criticalActivityColumns[activeInfo?.label]];
        let data = [...projectData];
        if (filterCol) {
          filterCol.push("projectId", "projectName");
          let filterData = [];
          data.forEach((prjItem, index) => {
            const temp = { ...prjItem };
            let obj = {};
            filterCol.forEach((item) => {
                obj[item] = temp[item] || '';
            });
            filterData[index] = { ...obj };
          });
          prjData = filterData[0];
          SetMasterProjectData([...filterData]);
        } else {
          SetMasterProjectData([...data]);
          prjData = data[0];
        }
      } else {
        SetMasterProjectData([...projectData]);
        prjData = projectData[0];
      }
      let label;
      let data = [];
      projectData.forEach((item) => {
        label = `${item.projectId} (${item.projectName})`;
        data.push({ id: item.projectId, label });
      });
      SetProjectSectionOption(data);

      const keys = { ...prjData };
      delete keys["projectId"];
      delete keys["projectName"];
      SetTableHeaderColumns(Object.keys(keys));
    }
  }, [projectData, toggleActivity, activeInfo.label]);

  useEffect(() => {
    SetHeaderActiveFilter(null);
  }, [filterProject]);

  useEffect(() => {
    if (masterProjectData && Array.isArray(masterProjectData)) {
      if (filterProject) {
        const viewData = masterProjectData.filter((data) => {
          const { projectId } = data;
          if (headerActiveFilter && headerActiveFilter.val?.length) {
            return (
              projectId === filterProject &&
              headerActiveFilter.val.some(
                (item) => item.value === data[headerActiveFilter.item]
              )
            );
          } else {
            return projectId === filterProject;
          }
        });
        SetViewProject([...viewData]);
      } else if (headerActiveFilter && headerActiveFilter.val?.length) {
        const viewData = masterProjectData.filter((data) => {
          return headerActiveFilter.val.some(
            (item) => item.value === data[headerActiveFilter.item]
          );
        });
        SetViewProject([...viewData]);
      } else {
        SetViewProject([...masterProjectData]);
      }
    }
  }, [filterProject, masterProjectData, headerActiveFilter]);

  useEffect(() => {
    if (headerActiveFilter && headerActiveFilter.val.length === 0) {
      SetHeaderActiveFilter(null);
    }
  }, [headerActiveFilter]);

  useEffect(() => {
    if (isDownloading) {
      const title = `List of Projects for For Status ${activeInfo.label} in ${activeInfo.percent}`;
      DownloadData({ title, filterData: viewProject });
      SetIsDownloading(false);
    }
  }, [isDownloading, activeInfo, viewProject]);

  if (!projectData) {
    return empty;
  }

  const projectChange = (e, val) => {
    console.log(e, val);
    if (val) {
      SetFilterProject(val.id);
    } else {
      SetFilterProject(null);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <center>
            <div className="pull-left">
              <ToggleActivities
                toggleActivity={toggleActivity}
                SetToggleActivity={SetToggleActivity}
              />
            </div>
            <div className="form-input-wrapper">
              <Autocomplete
                options={projectSelectionOption}
                onChange={projectChange}
                getOptionLabel={(option) => option.label.toString()}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search Project" />
                )}
                size="small"
              />
            </div>
          </center>
        </Col>
      </Row>
      <Row>
        <Col>
          <center>
            <div className="table-fixed-start-columns">
              <Table
                responsive
                className="current-vs-forecasted table table-md table-custom table-list-of-forecasted-projects table-scroll-modal table-modal
                             table-md table-list-of-projects table-tcqs-modal"
                id="table-task-list"
                style={{ fontSize: "0.7rem" }}
              >
                <thead className="table-head">
                  <tr key="header">
                    <th
                      style={{ paddingBottom: "1rem" }}
                      className="project-id-col"
                    >
                      Project Id
                    </th>
                    <th
                      style={{ paddingBottom: "1rem" }}
                      className="project-name-col"
                    >
                      Project Name
                    </th>
                    {tableHeader.map((item, index) => (
                      <th
                        className="table-data-black  centre-align-data"
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                        key={index}
                      >
                        <BootstrapTooltip placement="top" title={item}>
                          <span>
                            {!headerActiveFilter ||
                            (headerActiveFilter &&
                              headerActiveFilter.item === item) ? (
                              <SelectOptions
                                selectedTableOptionsValue={
                                  headerActiveFilter?.val
                                }
                                setHeaderChangeVal={SetHeaderActiveFilter}
                                item={item}
                              />
                            ) : (
                              item
                            )}
                          </span>
                        </BootstrapTooltip>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {projectObject[item]}  */}
                  {viewProject ? (
                    viewProject?.length > 0 ? (
                      viewProject.map((projectObject, index) => (
                        <tr key={index}>
                          <td className="project-id-col">
                            {projectObject["projectId"]}
                          </td>
                          <td className="project-name-col">
                            {projectObject["projectName"]}
                          </td>
                          {tableHeader.map((item, index) => (
                            <td
                              style={{
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                              }}
                              className="table-data-black centre-align-data"
                              key={index}
                            >
                              <GetColorCodeViaStatus
                                itemVal={projectObject[item]}
                              />
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr key="empty"></tr>
                    )
                  ) : (
                    <tr key="noData">
                      <td
                        style={{
                          width: "100vw",
                          paddingLeft: "40vw",
                        }}
                      >
                        <span style={{ marginLeft: "18rem" }}>
                          No data available for list of projects
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </center>
        </Col>
      </Row>
      <Row>
        <Col>
          <center>
            <Button
              color="primary"
              onClick={() => SetIsDownloading(true)}
              size="sm"
              id="download-list-key-actvity"
              disabled={
                viewProject &&
                JSON.stringify(viewProject) !== JSON.stringify([])
                  ? false
                  : true
              }
              className="download-list-current-month"
            >
              {isDownloading ? (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                  Downloading...
                </div>
              ) : (
                <div>
                  <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                </div>
              )}
            </Button>
          </center>
        </Col>
      </Row>
    </>
  );
}

const GetColorCodeViaStatus = ({ itemVal }) => {
  let classVal = "";
  if (!itemVal) {
    return <div>-</div>;
  }
  if (itemVal === "NA") {
    return <div></div>;
  }
  switch (itemVal) {
    case "R":
      classVal = "red-circle";
      break;
    case "A":
      classVal = "amber-circle";
      break;
    case "GR":
      classVal = "grey-circle";
      break;
    case "B":
      classVal = "blue-circle";
      break;
    case "G":
      classVal = "green-circle";
      break;
    default:
      classVal = "text-center no-data";
  }
  return (
    <>
      <div className={classVal}></div>
    </>
  );
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.5px solid #F0F0F0",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.4rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
    paddinLeft: "0rem",
    textAlign: "left",
    marginLeft: "0rem",
  }),
};

const SelectOptions = ({
  selectedTableOptionsValue,
  setHeaderChangeVal,
  item,
}) => {
  return (
    <>
      {" "}
      <Select
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isMulti
        options={colourOptions}
        styles={colourStyles}
        value={selectedTableOptionsValue}
        onChange={(val) => setHeaderChangeVal({ val, item })}
        placeholder={<div style={{whiteSpace:'nowrap'}} className="custom-placeholder-placement">{item}</div>}
        isSearchable
      />
    </>
  );
};

const DownloadData = ({ title, filterData }) => {
  const unit = "pt";
  const size = "A1"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const keys = { ...filterData[0] };
  delete keys["projectId"];
  delete keys["projectName"];

  let headers = [];
  let processDownload = [];
  let dataArr = filterData;
  let headerArr = Object.keys(keys);

  headers.push("Project ID");
  headers.push("Project Name");
  headers.push(...headerArr);

  for (let i = 0; i < dataArr.length; i++) {
    let array = [];
    for (let key in dataArr[i]) {
      if (dataArr[i][key]) {
        array.push(dataArr[i][key]);
      } else {
        array.push("NA");
      }
    }
    processDownload.push(array);
  }

  let content = {
    didParseCell: (data) => {
      if (data.cell.raw === "G") {
        data.cell.styles.textColor = [45, 197, 147];
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "R") {
        data.cell.styles.textColor = [228, 82, 102];
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "A") {
        data.cell.styles.textColor = [255, 191, 0];
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "NA") {
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "B") {
        data.cell.styles.textColor = "#268cd1";
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "GR") {
        data.cell.styles.textColor = "#808080";
        data.cell.styles.halign = "center";
      }

      if (data.cell.raw === "-") {
        data.cell.styles.halign = "center";
      }
    },
    didDrawPage: (data) => {},
    startY: 50,
    head: [headers],
    body: processDownload,
    theme: "grid",
    fontStyle: "bold",
    margin: { top: 10 },
    headStyles: { halign: "center" },
    pageBreak: "auto",
    rowPageBreak: "auto",
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable({ ...content });
  doc.save(title + ".pdf");
  return true;
};
