import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MdInfo } from "react-icons/md";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import PageSpinner from "../../PageSpinner";
import TablePagination from "../../TablePagination";
import "../../../styles/components/admin/all-users/TableAllUsers.scss";
import SaveIcon from "@material-ui/icons/Save";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    if (key === "creationDate") {
      let dateresponse = props.data[key].split(",");
      props.data[key] = dateresponse[0];
    }
    if (
      index === 2 ||
      key === "isAdmin" ||
      (key && key.toLowerCase() === "userroleid") ||
      key === "selectedUserRoleId" ||
      key === "selectedUserTypeId"
    ) {
      return null;
    }

    let selfUser = false;
    if (
      key == "username" &&
      props.data[key].toLowerCase() ===
        sessionStorage.getItem("username").toLowerCase()
    ) {
      selfUser = true;
    }
    return (
      <td
        key={props.data[key]}
        style={{
          color: index === 0 ? "#252525" : "#919BA3",
          fontFamily: index === 0 ? "Ubuntu" : "Ubuntu-Medium",
          paddingTop: "0.63rem",
          width: key == "username" ? "25%" : "",
        }}
      >
        {props.data[key]}
        {/*props.data["isAdmin"] == 1 && key == "username" && 
          <span style={{ color: "green", fontSize: ".5rem" }}>&nbsp;(admin)</span>*/}
      </td>
    );
  });
};

class TableAllUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 5,
      showDeleteModal: false,
      username: null,
      userId: null,
      isSortingCalled: 1,
      lessClickKey: "",
      moreClickKey: "",
      showMakeAdminModal: false,
      showSaveRoleModal: false,
      makeAdmin: false,
      isSaveRoleEnabled: {},
      isSaveTypeEnabled: {},
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.showDeleteConfirmationModal =
      this.showDeleteConfirmationModal.bind(this);
    this.onClickingDeleteUser = this.onClickingDeleteUser.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);

    this.toggleMakeAdminModal = this.toggleMakeAdminModal.bind(this);
    this.showMakeAdminConfirmationModal =
      this.showMakeAdminConfirmationModal.bind(this);
    this.onMakeAdminUser = this.onMakeAdminUser.bind(this);
    this.onClickingMakeAdminUser = this.onClickingMakeAdminUser.bind(this);

    this.toggleSaveRoleModal = this.toggleSaveRoleModal.bind(this);
    this.showSaveRoleConfirmationModal =
      this.showSaveRoleConfirmationModal.bind(this);
    this.onSaveRole = this.onSaveRole.bind(this);
    this.onClickingSaveRole = this.onClickingSaveRole.bind(this);
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  getKeys = function () {
    if (this.props.data.length === 0) {
      return null;
    }
    return Object.keys(this.props.data[0]);
  };

  getHeader = function () {
    let keys = this.getKeys();
    if (keys === null) {
      return null;
    }

    return keys.map((key, index) => {
      let headerlabel = key;
      if (key === "creationDate") {
        headerlabel = "Request Date";
      }
      if (key === "loginCount") {
        headerlabel = "Sessions in last 30 days";
      }
      if (key === "userId") {
        headerlabel = "User ID";
      }
      if (
        index === 2 ||
        key === "isAdmin" ||
        (key && key.toLowerCase() === "userroleid") ||
        key === "selectedUserRoleId" ||
        key === "selectedUserTypeId"
      ) {
        return null;
      }

      return (
        <th
          key={key}
          style={{
            paddingBottom: "0rem",
            width: key == "username" ? "25%" : "",
          }}
        >
          <span>
            {headerlabel == "Sessions in last 30 days" ? (
              <>
                Sessions in
                <br />
                last 30 days
              </>
            ) : (
              <>{headerlabel}</>
            )}
          </span>
          <ExpandLessIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (prop == "loginCount") {
                    a[prop] = a[prop] ? parseInt(a[prop]) || 0 : 0;
                    b[prop] = b[prop] ? parseInt(b[prop]) || 0 : 0;
                  }
                  if (a[prop] < b[prop]) {
                    return 1;
                  } else if (a[prop] > b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ lessClickKey: key, moreClickKey: "" });
            }}
            fontSize="small"
            className="expand-less-icon-sorting"
            style={{
              color: key === this.state.lessClickKey ? "red" : "black",
            }}
          />
          <ExpandMoreIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] > b[prop]) {
                    return 1;
                  } else if (a[prop] < b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ moreClickKey: key, lessClickKey: "" });
            }}
            fontSize="small"
            className="expand-more-icon-sorting"
            style={{
              color: key === this.state.moreClickKey ? "red" : "black",
            }}
          />
        </th>
      );
    });
  };

  getRowsData = function (userRoleListOptions) {
    const items = this.props.data;
    const keys = this.getKeys();

    if (keys == null) {
      return null;
    }

    return items
      .slice(
        this.state.currentPage * this.state.pageSize,
        (this.state.currentPage + 1) * this.state.pageSize
      )
      .map((row, index) => {
        let userId = row.userId;
        let username = row.username;
        let isAdmin = row.isAdmin;
        let selfUser = false;
        let userRoleId = row.userRoleId;

        if (
          username.toLowerCase() ===
          sessionStorage.getItem("username").toLowerCase()
        ) {
          selfUser = true;
        }

        return (
          <tr key={index} id="active-users-row">
            <RenderRow
              key={index}
              data={row}
              keys={keys}
              userRoleNameList={this.props.userRoleNameList}
              userRoleListOptions={userRoleListOptions}
            />
            <td>
              <span id="user-role-list-dropdown">
                <select
                  id="user-role-list-dropdown-select"
                  onChange={(e) => {
                    row.selectedUserRoleId = e.target.value;
                    let isSaveRoleEnabled = this.state.isSaveRoleEnabled;
                    if (row.selectedUserRoleId == row.userRoleId) {
                      isSaveRoleEnabled[userId] = 0;
                    } else {
                      isSaveRoleEnabled[userId] = 1;
                    }
                    this.setState({ isSaveRoleEnabled: isSaveRoleEnabled });
                  }}
                  value={
                    row.selectedUserRoleId
                      ? row.selectedUserRoleId
                      : row.userRoleId
                  }
                >
                  <option key={0} value={0}>
                    {"Select"}
                  </option>
                  {userRoleListOptions}
                </select>
              </span>
              {this.state.isSaveRoleEnabled[userId] ? (
                <SaveIcon
                  className="save-button-users-table"
                  onClick={async () => {
                    await this.onClickingSaveRole(
                      userId,
                      row.selectedUserRoleId,
                      username
                    );
                    row.userRoleId = row.selectedUserRoleId;
                  }}
                />
              ) : (
                <SaveIcon className="disabled-save-button-users-table" />
              )}
            </td>
            <td>
              <span id="user-role-list-dropdown">
                <select
                  id="user-role-list-dropdown-select"
                  onChange={(e) => {
                    row.selectedUserTypeId = e.target.value;
                    let isSaveTypeEnabled = this.state.isSaveTypeEnabled;
                    if (row.selectedUserTypeId == row.isAdmin) {
                      isSaveTypeEnabled[userId] = 0;
                    } else {
                      isSaveTypeEnabled[userId] = 1;
                    }
                    this.setState({ isSaveTypeEnabled: isSaveTypeEnabled });
                  }}
                  value={
                    row.selectedUserTypeId
                      ? row.selectedUserTypeId
                      : row.isAdmin
                  }
                >
                  <option key={0} value={0}>
                    {"Normal"}
                  </option>
                  <option key={1} value={1}>
                    {"Admin"}
                  </option>
                </select>
              </span>
              {!selfUser ? (
                this.state.isSaveTypeEnabled[userId] ? (
                  <SaveIcon
                    className="save-button-users-table"
                    onClick={async () => {
                      await this.onClickingMakeAdminUser(
                        userId,
                        username,
                        row.selectedUserTypeId == 0 ? false : true
                      );
                      row.isAdmin = row.selectedUserTypeId;
                    }}
                  />
                ) : (
                  <SaveIcon className="disabled-save-button-users-table" />
                )
              ) : (
                <BootstrapTooltip
                  placement="bottom-start"
                  title="Contact another admin user to revoke your admin rights"
                >
                  <SaveIcon
                    className="disabled-save-button-users-table"
                    style={{ cursor: "not-allowed" }}
                  />
                </BootstrapTooltip>
              )}
            </td>
            {/*
            <td>
              {isAdmin == 0 && selfUser && (
                <BootstrapTooltip
                  placement="bottom-start"
                  title="Contact another admin user to revoke your admin rights"
                >
                  <Button
                    type="button"
                    color="primary"
                    id="reset-admin-button"
                    size="sm"
                    style={{ cursor: "not-allowed", color: "#99c8e9" }}
                  >
                    <div id="reset-admin-button-text">
                      &#10005;{" "}
                      <span className="admin-string">&nbsp;Normal&nbsp;</span>
                    </div>
                  </Button>
                </BootstrapTooltip>
              )}
              {isAdmin == 0 && !selfUser && (
                <Button
                  type="button"
                  color="primary"
                  id="reset-admin-button"
                  size="sm"
                  onClick={this.onClickingMakeAdminUser.bind(
                    this,
                    userId,
                    username,
                    true
                  )}
                >
                  <div id="reset-admin-button-text">
                    &#10005;{" "}
                    <span className="admin-string">&nbsp;Normal&nbsp;</span>
                  </div>
                </Button>
              )}

              {isAdmin == 1 && selfUser && (
                <BootstrapTooltip
                  placement="bottom-start"
                  title="Contact another admin user to revoke your admin rights"
                >
                  <Button
                    type="button"
                    color="primary"
                    id="reset-admin-button"
                    size="sm"
                    style={{ cursor: "not-allowed", color: "#b9daf0" }}
                  >
                    <div id="reset-admin-button-text">
                      &#10005;{" "}
                      <span className="admin-string">&nbsp;Admin&nbsp;</span>
                    </div>
                  </Button>
                </BootstrapTooltip>
              )}
              {isAdmin == 1 && !selfUser && (
                <Button
                  type="button"
                  color="primary"
                  id="reset-admin-button"
                  size="sm"
                  onClick={this.onClickingMakeAdminUser.bind(
                    this,
                    userId,
                    username,
                    false
                  )}
                >
                  <div id="reset-admin-button-text">
                    &#10005;{" "}
                    <span className="admin-string">&nbsp;Admin&nbsp;</span>
                  </div>
                </Button>
              )}
            </td>
            */}
            <td style={{ paddingTop: "0.36rem" }}>
              {!selfUser && (
                <Button
                  type="button"
                  color="light"
                  id="delete-user-button"
                  size="sm"
                  onClick={this.onClickingDeleteUser.bind(
                    this,
                    userId,
                    username
                  )}
                >
                  <div id="delete-user-button-text">&#10005; Delete</div>
                </Button>
              )}
              {selfUser && (
                <BootstrapTooltip
                  placement="bottom-start"
                  title="You cannot delete your own account"
                >
                  <Button
                    type="button"
                    color="light"
                    id="delete-user-button"
                    size="sm"
                    style={{ cursor: "not-allowed", color: "#99c8e9" }}
                  >
                    <div id="delete-user-button-text">&#10005; Delete</div>
                  </Button>
                </BootstrapTooltip>
              )}
            </td>
          </tr>
        );
      });
  };

  onClickingDeleteUser(userId, username) {
    this.setState({
      userId: userId,
      showDeleteModal: true,
      username: username,
    });
  }

  onClickingMakeAdminUser(userId, username, makeAdmin) {
    this.setState({
      userId: userId,
      //showMakeAdminModal: true,
      username: username,
      makeAdmin: makeAdmin,
    });

    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.makeAdminUser(
      userId,
      username,
      makeAdmin,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );

    let isSaveTypeEnabled = this.state.isSaveTypeEnabled;
    isSaveTypeEnabled[userId] = 0;
    this.setState({ isSaveTypeEnabled: isSaveTypeEnabled });
  }

  onClickingSaveRole(userId, userRoleId, username) {
    this.setState({
      userId: userId,
      //showSaveRoleModal: true,
      userRoleId: userRoleId,
      username: username,
    });

    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.updateUserRole(
      userId,
      userRoleId,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );

    let isSaveRoleEnabled = this.state.isSaveRoleEnabled;
    isSaveRoleEnabled[userId] = 0;
    this.setState({ isSaveRoleEnabled: isSaveRoleEnabled });
  }

  toggleModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  toggleMakeAdminModal() {
    this.setState({ showMakeAdminModal: !this.state.showMakeAdminModal });
  }

  toggleSaveRoleModal() {
    this.setState({ showSaveRoleModal: !this.state.showSaveRoleModal });
  }

  onDeleteUser() {
    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.deleteUser(
      this.state.userId,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );

    this.toggleModal();
  }

  onMakeAdminUser() {
    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.makeAdminUser(
      this.state.userId,
      this.state.username,
      this.state.makeAdmin,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );

    this.toggleMakeAdminModal();
  }

  onSaveRole() {
    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.updateUserRole(
      this.state.userId,
      this.state.userRoleId,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );

    this.toggleSaveRoleModal();
  }

  showDeleteConfirmationModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Delete Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Delete the user{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onDeleteUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  showMakeAdminConfirmationModal() {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            {this.state.makeAdmin == 1 && (
              <div id="delete-confirmation-text">Approve Confirmation</div>
            )}
            {this.state.makeAdmin == 0 && (
              <div id="delete-confirmation-text">Approve Confirmation</div>
            )}
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            {this.state.makeAdmin == 1 && (
              <div>
                Make{" "}
                <span id="delete-confirmation-username-text">
                  {this.state.username}
                </span>{" "}
                an Admin user?
              </div>
            )}
            {this.state.makeAdmin == 0 && (
              <div>
                Remove{" "}
                <span id="delete-confirmation-username-text">
                  {this.state.username}
                </span>{" "}
                from the list of Admin users?
              </div>
            )}
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onMakeAdminUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleMakeAdminModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  showSaveRoleConfirmationModal() {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Save Role Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Save role for user{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onSaveRole}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleSaveRoleModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    if (this.props.isloading) {
      return <PageSpinner />;
    }

    let userRoleListOptions = [];

    if (this.props.userRoleList) {
      userRoleListOptions = this.props.userRoleList.map((v, i) => {
        return (
          <option key={i + 1} value={v.roleId} title={v.roleName}>
            {v.roleShortName}
          </option>
        );
      });
    }

    if (this.getKeys() === null) {
      return (
        <div>
          <div className="table-responsive">
            <Table
              responsive
              className="table table-border table-sm table-users"
            >
              <thead className="table-head">
                <tr className="text-capitalize" id="active-users-row">
                  <th>Username</th>
                  <th>Request Date</th>
                  <th>Sessions In Last 30 Days</th>
                  <th>User Role</th>
                  <th>User Type</th>
                  <th>Delete</th>
                </tr>
              </thead>
            </Table>
            <Card id="no-active-user-card">
              <h6 id="no-active-user-heading">
                No data available for all users
              </h6>
            </Card>
          </div>
          <br />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.state.showDeleteModal ? this.showDeleteConfirmationModal() : ""}
        {this.state.showMakeAdminModal
          ? this.showMakeAdminConfirmationModal()
          : ""}
        {this.state.showSaveRoleModal
          ? this.showSaveRoleConfirmationModal()
          : ""}
        <div className="table-responsive">
          <Table responsive className="table table-border table-sm table-users">
            <thead className="table-head">
              <tr className="text-capitalize" id="active-users-row">
                {this.getHeader()}
                <th style={{ paddingBottom: "0.4rem" }}>
                  <span style={{ paddingLeft: "0.4rem" }}>User Role</span>
                </th>
                <th style={{ paddingBottom: "0.4rem" }}>
                  <span style={{ paddingLeft: "0.4rem" }}>User Type</span>
                </th>
                <th style={{ paddingBottom: "0.4rem" }}>
                  <span style={{ paddingLeft: "0.4rem" }}>Delete</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.getRowsData(userRoleListOptions)}</tbody>
          </Table>
        </div>
        {Math.ceil(this.props.data.length / this.state.pageSize) > 1 ? (
          <div>
            <span className="showing-span">
              <span className="showing-text-pagination">Showing </span>
              <span>{this.state.currentPage + 1} </span>
              <span className="showing-text-pagination">out of </span>
              <span>
                {Math.ceil(this.props.data.length / this.state.pageSize)}{" "}
              </span>
              <span className="showing-text-pagination">pages </span>
            </span>
            <div id="active-users-pagination-div">
              <div id="active-users-pagination-style">
                <TablePagination
                  pagesCount={Math.ceil(
                    this.props.data.length / this.state.pageSize
                  )}
                  currentPage={this.state.currentPage}
                  setCurrentPage={this.setCurrentPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    );
  }
}

export default TableAllUsers;
