import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import AMP7Icon from "../../../../images/summary-icons/amp7.svg";
import OverspendListIcon from "../../../../images/summary-icons/overspend_list.svg";
import TotalSanctionIcon from "../../../../images/summary-icons/total_sanction.svg";
import AvgValueRemainingIcon from "../../../../images/summary-icons/avg_value_remaining.svg";
import IdentifyIcon from "../../../../images/summary-icons/identify.svg";
import DefineIcon from "../../../../images/summary-icons/define.svg";
import DeliverIcon from "../../../../images/summary-icons/deliver.svg";
import ClosureIcon from "../../../../images/summary-icons/closure.svg";

class OverviewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="chart-top"
        style={{ padding: "0.55rem", fontSize: "0.9rem" }}
      >
        <div>
          <Row>
            <Col style={{ fontSize: "0.94rem", textAlign: "left" }}>
              Overview
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={AMP7Icon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">
                AMP7 Optimised Value​ <br /> £ 1.66 Billion
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={OverspendListIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">
                Number of Projects on overspend list​ ​​ <br /> 79 (26%)
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={TotalSanctionIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">
                Total Sanction​ ​​ <br /> £ 916 Million
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={AvgValueRemainingIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">
                Average Value Remaining​ <br /> 64%
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col style={{ fontSize: "0.75rem", textAlign: "left" }}>
              Count of projects by phase
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={IdentifyIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">Identify</span>
            </Col>
            <Col>
              <span className="summary-icon-text">249</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={DefineIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">Define</span>
            </Col>
            <Col>
              <span className="summary-icon-text">249</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={DeliverIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">Deliver</span>
            </Col>
            <Col>
              <span className="summary-icon-text">249</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <img
                src={ClosureIcon}
                alt=""
                className="img-fuid"
                id="icon-summary"
              />
            </Col>
            <Col>
              <span className="summary-icon-text">Close</span>
            </Col>
            <Col>
              <span className="summary-icon-text">249</span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default OverviewChart;
