import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import { MdInfo } from "react-icons/md";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Row, Col } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";

import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";

const staticMilestonesLabels = {
  c_create_the_project_batch: "Project / batch creation",
  c_programme_approval_committee_1_decision: "PAC1",
  "Programme approval committee 2 decision": "PAC2",
  "Current contract award Date vs baseline contract award date":
    "Award contract",
  "Forecasted start on site date vs baseline start on site  date":
    "Start on site",
  c_forecasted_piu_vs_baseline_piu_date: "Project in Use",
  "Forecasted contract completion date vs baseline contract completion date":
    "Completion date",
  c_progamme_approval_committee_3_decision: "PAC3",
  c_gateway_4_decision: "Gateway 4",
  c_forecasted_final_acceptance_date_vs_baseline_final_acceptance:
    "End of project / batch",
  "2. _Third party_ environment and customer action plan (TPECAP)": "2.TPECAP",
  "1. Third party environment and customer action plan (TPECAP)": "1.TPECAP",
  "%age of responses on-time to payment certificates":
    "Payment certificates on time",
  "Has the capitalisation process been completed for the output?":
    "Capitalisation process",
  "Has the evidence pack been completed for the output?": "Evidence pack",
  "Construction supervisor confidence in achieving PIU":
    "Confidence in achieving PIU",
  "Safety (construction)": "Safety",
  "Right first time (construction)": "Right first time",
  "Environment (construction)": "Environment",
  "%age of responses on-time to CDP's quote replies (QTE replies)":
    "QTE replies",
  "Forecasted  PIU vs regulatory target date":
    "Forecasted PIU vs regulatory target date",
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class BarChartWithFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityList: Object.keys(this.props.chartData),
      selectedActivity: Object.keys(this.props.chartData)[0],
      barChartData: this.props.chartData,
      stateChangeVar: false,
      selectedToggle: false,
    };
    this.onActivityChange = this.onActivityChange.bind(this);
    this.changeState = this.changeState.bind(this);
    this.chartDataTemplate = {
      data: {
        labels: ["A", "B", "C", "D"],
        datasets: [
          {
            label: "Green",
            backgroundColor: "#268cd1", //"#96e079", //#C4F2B2',
            //borderColor: 'rgba(255,99,132,1)',
            borderWidth: 0,
            //stack: 1,
            //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //hoverBorderColor: 'rgba(255,99,132,1)',
            data: null,
            barThickness: 20,
            fontFamily: "Ubuntu-Medium",
          },
        ],
      },
    };
    this.chartOptions = {
      responsive: false,
      title: {
        display: true,
        text: "",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
        position: "left",
        align: "left",
        fontFamily: "Ubuntu-Medium",
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              display: true,
              fontColor: "#252525",
              minRotation: 0,
              fontSize: 10,
              maxRotation: 0,
              fontFamily: "Ubuntu-Medium",
              callback: function (label, index, labels) {
                if (/\s/.test(label)) {
                  label = label.split(" ");
                  return label
                    ? label.length >= 13
                      ? label.substring(0, 13) + ".."
                      : label
                    : label;
                } else {
                  return label
                    ? label.length >= 13
                      ? label.substring(0, 13) + ".."
                      : label
                    : label;
                }
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: false,
              stepSize: 10,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: (label, index, labels) => {
            return parseFloat(label).toFixed(2);
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
    };
  }

  onActivityChange = (event) => {
    // Update the state
    this.setState({
      selectedActivity:
        event.target.value || Object.keys(this.props.chartData)[0],
    });
  };

  changeState() {
    this.setState({
      stateChangeVar: !this.state.stateChangeVar,
      selectedToggle: !this.state.selectedToggle,
    });
  }

  render() {
    const barChartData = this.props.chartData;
    let finalChartData = null;
    let chartLabels = [];
    let chartValues = [];
    let tempActivityObjectArray = [];

    if (
      barChartData &&
      this.state.selectedActivity &&
      this.props.csvColumnMapping
    ) {
      let chartData = JSON.parse(JSON.stringify(this.chartDataTemplate));

      if (this.state.selectedToggle == true) {
        tempActivityObjectArray = [];
        if (barChartData[this.state.selectedActivity]) {
          barChartData[this.state.selectedActivity].activities.forEach(
            (item) => {
              tempActivityObjectArray.push([
                this.props.csvColumnMapping
                  ? this.props.csvColumnMapping[
                      item["activity_id"].replace(/-/g, "_")
                    ]
                    ? this.props.csvColumnMapping[
                        item["activity_id"].replace(/-/g, "_")
                      ]
                    : item["activity_id"].replace(/_/g, " ")
                  : item["activity_id"].replace(/-/g, " "),
                parseFloat(item["avg"]).toFixed(2),
              ]);
            }
          );

          //sort the values in descending order
          tempActivityObjectArray = tempActivityObjectArray.sort((a, b) => {
            return b[1] - a[1];
          });

          tempActivityObjectArray.forEach((item) => {
            chartLabels.push(
              staticMilestonesLabels[item[0]]
                ? staticMilestonesLabels[item[0]]
                : this.props.csvColumnMapping[item[0]]
                ? this.props.csvColumnMapping[item[0]]
                : item[0]
            );
            chartValues.push(item[1]);
          });

          chartData.data.datasets[0].data = chartValues;
          chartData.data.labels = chartLabels;
          finalChartData = (
            <div>
              <Bar
                data={chartData.data}
                width={1950}
                height={400}
                options={this.chartOptions}
              />
            </div>
          );
        }
      } else {
        if (barChartData[this.state.selectedActivity]) {
          barChartData[this.state.selectedActivity].activities.forEach(
            (item) => {
              tempActivityObjectArray.push([
                this.props.csvColumnMapping
                  ? this.props.csvColumnMapping[
                      item["activity_id"].replace(/-/g, "_")
                    ]
                    ? this.props.csvColumnMapping[
                        item["activity_id"].replace(/-/g, "_")
                      ]
                    : item["activity_id"].replace(/_/g, " ")
                  : item["activity_id"].replace(/-/g, " "),
                parseFloat(item["avg"]).toFixed(2),
              ]);
            }
          );

          //sort the values in descending order
          //tempActivityObjectArray = tempActivityObjectArray.sort((a, b) => {
          //  return b[1] - a[1];
          //});

          tempActivityObjectArray.forEach((item) => {
            chartLabels.push(
              staticMilestonesLabels[item[0]]
                ? staticMilestonesLabels[item[0]]
                : this.props.csvColumnMapping[item[0]]
                ? this.props.csvColumnMapping[item[0]]
                : item[0]
            );
            chartValues.push(item[1]);
          });

          chartData.data.datasets[0].data = chartValues;
          chartData.data.labels = chartLabels;
          finalChartData = (
            <div>
              <Bar
                data={chartData.data}
                width={1950}
                height={400}
                options={this.chartOptions}
              />
            </div>
          );
        }
      }
    }

    let getAccuracyLabel = (value) => {
      if (value >= 90) {
        return "Very High";
      }
      if (value >= 70) {
        return "High";
      }
      if (value >= 50) {
        return "Medium";
      }
      if (value >= 25) {
        return "Low";
      }
      return "Very Low";
    };

    return (
      <div className="chart-top">
        <div className="chart-header">
          <span style={{ fontSize: "0.83rem" }}>
            Key activities of focus and previous milestone by their importance
            for the performance of the forecasted milestone{" "}
          </span>
          <span
            data-title="The chart based on milestone selection represents the key activities by their coefficient that represents the importance of that activity. The higher the coefficient the higher the importance of the activity for achieving that milestone.​"
            data-title-xlg
            style={{ marginLeft: "-0.3rem" }}
          >
            <MdInfo size={20} className="c-info"></MdInfo>
          </span>
          <span id="activity-list-dropdown-span">
            <span id="showing-text">Showing&nbsp;&nbsp;</span>
            <div id="activity-list-dropdown">
              <BootstrapTooltip
                title={
                  this.state.selectedActivity
                    ? this.props.csvColumnMapping[this.state.selectedActivity]
                      ? this.props.csvColumnMapping[this.state.selectedActivity]
                          .length > 28
                        ? this.props.csvColumnMapping[
                            this.state.selectedActivity
                          ]
                        : ""
                      : ""
                    : ""
                }
                placement="left-start"
              >
                <select
                  onChange={this.onActivityChange}
                  value={
                    this.state.selectedActivity ||
                    Object.keys(this.props.chartData)[0]
                  }
                  id="activity-list-dropdown-select"
                >
                  {this.state.activityList && this.props.csvColumnMapping
                    ? this.state.activityList.map((option, index) => (
                        <BootstrapTooltip
                          key={index}
                          title={
                            this.props.csvColumnMapping[option]
                              ? this.props.csvColumnMapping[option].length > 28
                                ? this.props.csvColumnMapping[option]
                                : ""
                              : ""
                          }
                          placement="left-start"
                        >
                          <option key={option} value={option}>
                            {this.props.csvColumnMapping[option]
                              ? this.props.csvColumnMapping[option].length > 28
                                ? this.props.csvColumnMapping[option].substr(
                                    0,
                                    28
                                  ) + "..."
                                : this.props.csvColumnMapping[option]
                              : option}
                          </option>
                        </BootstrapTooltip>
                      ))
                    : ""}
                </select>
              </BootstrapTooltip>
            </div>
          </span>
          <span id="accuracy-top-focus-area">
            <span id="accuracy-text">Accuracy &nbsp;</span>
            {barChartData && this.state.selectedActivity ? (
              barChartData[this.state.selectedActivity] ? (
                barChartData[this.state.selectedActivity].accuracy ? (
                  <span>
                    {Math.round(
                      barChartData[this.state.selectedActivity].accuracy
                    )}
                    %
                    {/*(
                  {getAccuracyLabel(
                    Math.round(
                      barChartData[this.state.selectedActivity].accuracy
                    )
                    )}
                  )*/}
                  </span>
                ) : (
                  <span>NA</span>
                )
              ) : (
                <span>NA</span>
              )
            ) : (
              <span>NA</span>
            )}
          </span>
        </div>
        {/*<div style={{ overflowX: chartLabels.length >= 3 ? "auto" : "hidden" }}>*/}
        <Row>
          <Col>
            <span
              style={{ float: "left", marginTop: "0.8rem", marginLeft: "1rem" }}
            >
              {/*
            <label className="switch">
              <input
                type="checkbox"
                onClick={() => {
                  this.toggleMilestones();
                }}
                checked={this.state.isMilestoneVisible}
              />
              <span className="toggle-slider round"></span>
            </label>
            */}
              <BootstrapTooltip
                title={
                  this.state.selectedToggle
                    ? "Click here to display activities in order of execution"
                    : "Click here to display activities in order of importance"
                }
                placement="right"
              >
                <ToggleButton
                  value="check"
                  selected={this.state.selectedToggle}
                  onChange={() => {
                    this.changeState();
                  }}
                  size="small"
                  style={{ backgroundColor: "#268cd1" }}
                >
                  {this.state.selectedToggle ? (
                    <BarChartIcon style={{ color: "#FFFFFF" }} />
                  ) : (
                    <span style={{ transform: "rotate(-90deg)" }}>
                      <SortRoundedIcon style={{ color: "#FFFFFF" }} />
                    </span>
                  )}
                </ToggleButton>
              </BootstrapTooltip>
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ overflowX: "auto" }}>
              {finalChartData != null && (
                <React.Fragment>{finalChartData}</React.Fragment>
              )}
              {finalChartData == null && <span>No data available</span>}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BarChartWithFilter;
