import React, { Component } from "react";
import * as d3 from "d3";
import axios from "axios";
import { MdRemove, MdAdd, MdSearch, MdInfo, MdUndo } from "react-icons/md";
import { Row, Col, Button, Modal, Spinner, ModalBody, Table } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import chroma from "chroma-js";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
//import XLSX from "xlsx";
//import { saveAs } from "file-saver";

import PageSpinner from "../../../PageSpinner";
import map from "../../../../utils/tubemap/map";
import * as data from "../../../../utils/tubemap/activities.json";
import "../../../../styles/components/Chart.scss";
import "../../../../styles/components/ProjectMapPredictions.scss";
import { ErrorHandler } from "../../../ErrorHandler";
import AutoCloseAlert from "../../../AutoCloseAlert";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
//import TablePagination from "../../../TablePagination";
import SingleProjectHealthScoreTrendChart from "../../summary/charts/SingleProjectHealthScoreTrendChart";
import SingleProjectActivities from "../../summary/SingleProjectActivities";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.5px solid #F0F0F0",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.4rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
    paddinLeft: "0rem",
    textAlign: "left",
    marginLeft: "0rem",
  }),
};

const colourOptions = [
  { value: "In date", label: "In date", color: "#2DC593" },
  { value: "Out of date", label: "Out of date", color: "#E45266" },
];

const colourOptionsStatus = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class ProjectMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchProjectNamesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/pred-project-names?filters=`,
      fetchProjectDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-by-project/`,
      fetchProjectQualityParamsPredictionRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-params-data/`,
      fetchProjectKeyActivitiesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/key-activities-for-project/`,
      getSingleProjectHealthURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-health-trend/`,
      getSingleProjectActivitiesURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-important-activities/`,
      projectAllData: null,
      selectedMonthData: null,
      allData: null,
      zoomSliderVal: 2,
      projectName: null,
      projectNameIdMap: null,
      showMap: null,
      isLoading: null,
      isActivitiesVisible: true,
      isMilestoneVisible: true,
      singleProjectData: null,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      qualityParamData: null,
      keyData: null,
      keyFilterData: null,
      keyActivitiesData: null,
      timeData: null,
      qualityData: null,
      smeData: null,
      hsData: null,
      costData: null,
      scopeData: null,
      isDownloading: false,
      currentPage: 0,
      pageSize: 5,
      isZoom: false,
      defaultProjectVal:null,
      defaultValueChecked: false,
      selectedRelevanceValue: [
        { value: "In date", label: "In date", color: "#2DC593" },
      ],
      InDateEmptyMessage: false,
      goToCurrentPerformance: false
    };
    this.props.updateProjectMapDataLoadStatus(false);
    this.showTubeMap = this.showTubeMap.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.changeMapZoom = this.changeMapZoom.bind(this);
    this.fetchProjectNames = this.fetchProjectNames.bind(this);
    this.fetchProjectData = this.fetchProjectData.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
    this.toggleActivities = this.toggleActivities.bind(this);
    this.toggleMilestones = this.toggleMilestones.bind(this);
    this.updateDataToShow = this.updateDataToShow.bind(this);
    this.fetchProjectQualityParamsPrediction =
      this.fetchProjectQualityParamsPrediction.bind(this);
    this.fetchKeyActivities = this.fetchKeyActivities.bind(this);
    this.closeTaskListModal = this.closeTaskListModal.bind(this);
    this.downloadKeyActivitiesExcel =
      this.downloadKeyActivitiesExcel.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.onRelevanceSelect = this.onRelevanceSelect.bind(this);
    this.onStatusSelect = this.onStatusSelect.bind(this);
    this.onRelevanceSelectSecond = this.onRelevanceSelectSecond.bind(this);
    this.onStatusSelectSecond = this.onStatusSelectSecond.bind(this);
    this.getSingleProjectHealthScore = this.getSingleProjectHealthScore.bind(this);
    this.getSingleProjectActivities = this.getSingleProjectActivities.bind(this);
    this.handleCurrentPerformance = this.handleCurrentPerformance.bind(this);
  }

  handleCurrentPerformance() {
    this.setState({
      goToCurrentPerformance: true
    });
  }

  getSingleProjectHealthScore() {
    //this.setState({ isLoading: true });
    const projectId = this.props.projectNameIdMap[this.state.projectName];
    axios({
      url: this.state.getSingleProjectHealthURL + projectId,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;

        this.setState({
          isLoading: false,
          projectCurrentHealthData: data.projectHealthScoreData.current,
          projectForecastedHealthData: data.projectHealthScoreData.forecasted,
        });
      })
      .catch((error) => {
        console.log("error in getting project health score" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
          },
        );
      });
  }

  getSingleProjectActivities(measureType) {
    //this.setState({ isLoading: true });
    const projectId = this.props.projectNameIdMap[this.state.projectName];
    this.setState({ selectedMeasure: measureType });

    axios({
      url:
        this.state.getSingleProjectActivitiesURL +
        projectId +
        "/" +
        measureType,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;

        this.setState({
          isLoading: false,
          singleProjectActivities: data.allActivities,
        });
      })
      .catch((error) => {
        console.log("error in getting project health score" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
          },
        );
      });
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  componentDidMount() { }

  setProjectName(projectName, fetchData = false) {
    this.setState({ projectName: projectName }, () => {
      if(fetchData){
        this.fetchProjectData();
      }
    });
  }

  fetchProjectNames() {
    this.setState({ isLoading: true });
    const filters = this.props.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.fetchProjectNamesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const projectNameIdMap = response.data.projectNameIdMap;
        this.setState({ projectNameIdMap: projectNameIdMap });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log("error in fetching project names");
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  fetchProjectData() {
    const { projectName } = this.state;
    const { projectNameIdMap } = this.props;
    this.setState({
      showMap: false,
      qualityParamData: null,
      keyData: null,
      keyFilterData: null,
      keyActivitiesData: null,
      timeData: null,
      qualityData: null,
      smeData: null,
      hsData: null,
      costData: null,
      scopeData: null,
    });
    this.props.parentCallback(projectName);
    if (projectNameIdMap && projectNameIdMap[projectName]) {
      this.props.updateProjectMapDataLoadStatus(false);
      const projectId = projectNameIdMap[projectName];
      axios({
        url: this.state.fetchProjectDataRequestURL + projectId,
        method: "GET",
        headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
      })
        .then((response) => {
          const res = response;
          const projectAllData = res.data.projectData;
          this.setState({ projectAllData: projectAllData });
          this.setState({ showMap: true });
          this.updateMap();
          this.props.updateProjectMapDataLoadStatus(true);
          // fetch health score and activities data
          //this.getSingleProjectHealthScore();
          //this.getSingleProjectActivities("c_time");
        })

        .catch((error) => {
          console.log("error in fetching project data");
          this.setState({ isLoading: false });
          let alert = ErrorHandler(error);
          this.setState({
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
          });
          this.props.updateProjectMapDataLoadStatus(true);
        });
    } else {
      this.setState({ showMap: null });
    }
  }

  updateMap() {
    const projectAllData = this.state.projectAllData;
    const yearList = Object.keys(projectAllData);
    const year = yearList[0];
    const month = Object.keys(projectAllData[year])[0];
    const monthData = projectAllData[year][month];
    monthData.sort(function (a, b) {
      return a.project_id - b.project_id;
    });
    this.setState({ selectedMonthData: monthData });

    // get the project data for first project
    const singleProjectData = monthData[0];
    this.setState({ singleProjectData: singleProjectData });
    this.props.updateSingleProjectMapData(singleProjectData);
  }

  showTubeMap(isActivitiesVisible, isMilestoneVisible, singleProjectData) {
    d3.select("#projectTubeMapDiv").select("svg").remove();
    let container = d3.select("#projectTubeMapDiv");
    const showActivities = isActivitiesVisible;
    const tubemapData = {
      singleProjectData: singleProjectData,
      isMilestoneVisible: isMilestoneVisible,
      isActivitiesVisible: showActivities,
    };

    let tubeMap = map(tubemapData);
    let dataJson = data.default;

    container.datum(dataJson).call(tubeMap);
    this.changeMapZoom();
  }

  changeMapZoom() {
    let containerProjectMap = d3.select("#projectTubeMapDiv");
    let svgProjectMap = containerProjectMap.select("svg");
    let zoomProjectMap = d3
      .zoom()
      .scaleExtent([1, 10])
      .on("zoom", zoomedProjectMap);

    svgProjectMap.call(zoomProjectMap);

    d3.select("#zoomInProjectMap").on("click", zoomInProjectMap);

    d3.select("#zoomOutProjectMap").on("click", zoomOutProjectMap);
    d3.select("#zoomInitialForcastSearchByProject").on("click", zoomInitialMap);

    let zoomContainerProjectMap = svgProjectMap.call(zoomProjectMap);
    //let initialScaleProjectMap = 1.7;
    //let initialTranslateProjectMap = [65, 50];

    let initialScaleProjectMap = sessionStorage.getItem(
      "initialScaleProjectMap"
    );
    let initialTranslateXProjectMap = parseInt(
      sessionStorage.getItem("initialTranslateXProjectMap")
    );
    let initialTranslateYProjectMap = parseInt(
      sessionStorage.getItem("initialTranslateYProjectMap")
    );

    zoomProjectMap.scaleTo(zoomContainerProjectMap, initialScaleProjectMap);
    zoomProjectMap.translateTo(
      zoomContainerProjectMap,
      initialTranslateXProjectMap / initialScaleProjectMap,
      initialTranslateYProjectMap / initialScaleProjectMap
    );

    function zoomedProjectMap() {
      const currentTransform = d3.event.transform;
      svgProjectMap.select("g").attr("transform", currentTransform);
      initialScaleProjectMap = currentTransform.k;
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
      sessionStorage.setItem(
        "initialTranslateXProjectMap",
        currentTransform.x * -1
      );
      sessionStorage.setItem(
        "initialTranslateYProjectMap",
        currentTransform.y * -1
      );
    }

    function zoomInProjectMap(d) {
      initialScaleProjectMap += 1;
      if (initialScaleProjectMap > 10) {
        initialScaleProjectMap = 10;
      }
      zoomProjectMap.scaleTo(svgProjectMap, initialScaleProjectMap);
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
    }

    function zoomOutProjectMap(d) {
      initialScaleProjectMap -= 1;
      if (initialScaleProjectMap < 2) {
        initialScaleProjectMap = 2;
      }
      zoomProjectMap.scaleTo(svgProjectMap, initialScaleProjectMap);
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
    }

    let copyThis = this;
    function zoomInitialMap(d) {
      sessionStorage.setItem("initialScaleProjectMap", 1.7);
      sessionStorage.setItem("initialTranslateXProjectMap", 50);
      sessionStorage.setItem("initialTranslateYProjectMap", 50);
      copyThis.setState({ isZoom: false });
    }
  }

  resetZoom() {
    sessionStorage.setItem("initialScaleProjectMap", 1.7);
    sessionStorage.setItem("initialTranslateXProjectMap", 50);
    sessionStorage.setItem("initialTranslateYProjectMap", 50);
    this.setState({ isZoom: false });
  }

  toggleActivities() {
    const isActivitiesVisible = this.state.isActivitiesVisible;
    this.setState({ isActivitiesVisible: !isActivitiesVisible });
    // if isActivitiesVisible is false then make isMilestoneVisible to true because at a time either of one should be visible
    let isMilestoneVisible = this.state.isMilestoneVisible;
    if (!isActivitiesVisible == false) {
      isMilestoneVisible = true;
      this.setState({ isMilestoneVisible: isMilestoneVisible });
    }
  }

  toggleMilestones() {
    const isMilestoneVisible = this.state.isMilestoneVisible;
    this.setState({ isMilestoneVisible: !isMilestoneVisible });
    // if isMilestoneVisible is false then make isActivitiesVisible to true because at a time either of one should be visible
    let isActivitiesVisible = this.state.isActivitiesVisible;
    if (!isMilestoneVisible == false) {
      isActivitiesVisible = true;
      this.setState({ isActivitiesVisible: isActivitiesVisible });
    }
  }

  updateDataToShow(e) {
    if (e.target.value === "milestone") {
      this.setState({
        isMilestoneVisible: true,
        isActivitiesVisible: false,
      });
    } else if (e.target.value === "activity") {
      this.setState({
        isActivitiesVisible: true,
        isMilestoneVisible: false,
      });
    } else {
      this.setState({
        isActivitiesVisible: true,
        isMilestoneVisible: true,
      });
    }
  }

  fetchProjectQualityParamsPrediction() {
    axios({
      url:
        this.state.fetchProjectQualityParamsPredictionRequestURL +
        this.props.projectNameIdMap[this.state.projectName],
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        // get current year
        const currentYear = Object.keys(data)[0];
        // get current month
        const currentMonth = Object.keys(data[currentYear])[0];
        const qualityParamData = data[currentYear][currentMonth];
        this.setState({ qualityParamData: qualityParamData });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params forecast - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  fetchKeyActivities(key, tableData) {
    if (this.state.timeData) {
      this.setState({ showKeyActivitiesModal: true }, this.bindEvent);
      let relevanceValue = this.state.selectedRelevanceValue;
      if (key == "cost") {
        // for cost remove relevance filter
        relevanceValue = null;
      }
      this.onRelevanceSelect(tableData, relevanceValue);
    } else {
      axios({
        url:
          this.state.fetchProjectKeyActivitiesRequestURL +
          this.props.projectNameIdMap[this.state.projectName] +
          "?filters={}",
        method: "GET",
        headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
      })
        .then((response) => {
          let data = response.data.chartData;
          // get current year
          const currentYear =
            data != null &&
              data !== undefined &&
              JSON.stringify(data) !== JSON.stringify({})
              ? Object.keys(data)[0]
              : null;
          // get current month
          const currentMonth =
            data != null &&
              data !== undefined &&
              JSON.stringify(data) !== JSON.stringify({})
              ? Object.keys(data[currentYear])[0]
              : null;
          const chartData =
            data != null &&
              data !== undefined &&
              JSON.stringify(data) !== JSON.stringify({})
              ? data[currentYear][currentMonth]
              : null;

          let keyActivitiesData = [];
          let timeData = [];
          let costData = [];
          let qualityData = [];
          let hsData = [];
          let scopeData = [];
          let smeData = [];

          if (chartData != null) {
            Object.entries(chartData).forEach((milestoneItem) => {
              if (milestoneItem[0] == "c_cost") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  costData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (costData !== null) {
                  //costData.sort(GetSortOrder("weight"));
                }
              } else if (milestoneItem[0] == "c_time") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  timeData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (timeData !== null) {
                  //timeData.sort(GetSortOrder("weight"));
                }
              } else if (milestoneItem[0] == "c_sme_self_assessments") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  smeData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (smeData !== null) {
                  //smeData.sort(GetSortOrder("weight"));
                }
              } else if (milestoneItem[0] == "c_scope") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  scopeData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (scopeData !== null) {
                  //scopeData.sort(GetSortOrder("weight"));
                }
              } else if (milestoneItem[0] == "c_h_s") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  hsData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (hsData !== null) {
                  //hsData.sort(GetSortOrder("weight"));
                }
              } else if (milestoneItem[0] == "c_quality") {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  qualityData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (qualityData !== null) {
                  //qualityData.sort(GetSortOrder("weight"));
                }
              } else {
                milestoneItem[1].activities.forEach((activityItem) => {
                  /*
                  if (
                    Math.round(activityItem["shap_val"]) >
                    process.env.REACT_APP_PREDICTION_PROJECT_TASKLIST_THRESHOLD
                  ) {
                  */
                  keyActivitiesData.push({
                    milestone: this.props.csvColumnMapping[milestoneItem[0]]
                      ? this.props.csvColumnMapping[milestoneItem[0]]
                      : milestoneItem[0],
                    activity: this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                    ]
                      ? this.props.csvColumnMapping[
                      activityItem["activity_id"].replace(/-/g, "_")
                      ]
                      : activityItem["activity_id"],
                    weight: parseFloat(activityItem["shap_val"]).toFixed(2),
                    status: activityItem["brag_val"],
                    relevance:
                      activityItem["relevance"] == "O"
                        ? "Out of date"
                        : "In date",
                    action: activityItem["action"],
                  });
                });
                if (keyActivitiesData !== null) {
                  //keyActivitiesData.sort(GetSortOrder("weight"));
                }
              }
            });
          }
          this.setState(
            {
              keyActivitiesData: keyActivitiesData,
              timeData: timeData,
              qualityData: qualityData,
              smeData: smeData,
              hsData: hsData,
              scopeData: scopeData,
              costData: costData,
              showKeyActivitiesModal: true,
            },
            this.bindEvent
          );
          if (key == "keyActivities") {
            this.setState({
              keyData: keyActivitiesData,
              keyFilterData: keyActivitiesData,
              isMilestoneTasks: true,
            });
            this.onRelevanceSelect(
              keyActivitiesData,
              this.state.selectedRelevanceValue
            );
          } else if (key == "time") {
            this.setState({
              keyData: timeData,
              keyFilterData: timeData,
              isMilestoneTasks: false,
            });
            this.onRelevanceSelect(timeData, this.state.selectedRelevanceValue);
          } else if (key == "cost") {
            this.setState({
              keyData: costData,
              keyFilterData: costData,
              isMilestoneTasks: false,
            });
            let relevanceValue = null; // for cost remove relevance filter
            this.onRelevanceSelect(costData, relevanceValue);
          } else if (key == "quality") {
            this.setState({
              keyData: qualityData,
              keyFilterData: qualityData,
              isMilestoneTasks: false,
            });
            this.onRelevanceSelect(
              qualityData,
              this.state.selectedRelevanceValue
            );
          } else if (key == "sme") {
            this.setState({
              keyData: smeData,
              keyFilterData: smeData,
              isMilestoneTasks: false,
            });
            this.onRelevanceSelect(smeData, this.state.selectedRelevanceValue);
          } else if (key == "hs") {
            this.setState({
              keyData: hsData,
              keyFilterData: hsData,
              isMilestoneTasks: false,
            });
            this.onRelevanceSelect(hsData, this.state.selectedRelevanceValue);
          } else {
            this.setState({
              keyData: scopeData,
              keyFilterData: scopeData,
              isMilestoneTasks: false,
            });
            this.onRelevanceSelect(
              scopeData,
              this.state.selectedRelevanceValue
            );
          }
        })
        .catch((error) => {
          console.log(
            "error in fetching project quality params forecast - " + error
          );
          this.setState({ isLoading: false });
          let alert = ErrorHandler(error);
          this.setState({
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
          });
        });
    }
  }

  closeTaskListModal() {
    this.setState({
      keyFilterData: null,
      showKeyActivitiesModal: false,
      currentPage: 0,
      lessClickKey: null,
      moreClickKey: null,
      selectedValue: null,
      selectedStatusValue: null,
      selectedRelevanceValue: [
        { value: "In date", label: "In date", color: "#2DC593" },
      ],
    });
  }

  downloadKeyActivitiesExcel = () => {
    // this.setState({ isDownloading: true });
    // const fileType =
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    // const fileExtension = ".xlsx";
    // debugger;
    // const ws1 = XLSX.utils.json_to_sheet(this.state.keyData);

    // const wb = {
    //   Sheets: {
    //     KeyActivities: ws1,
    //   },
    //   SheetNames: ["KeyActivities"],
    // };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // saveAs(data, "Forecast_KeyActivitiesData.xlsx");
    // this.setState({ isDownloading: false });
    let mapAction = {
      R: " - review",
      AR: " - action required",
      NAR: " - no action required",
    };

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title =
      "Key activities forecasted for '" +
      this.state.keyData[0].milestone +
      "' by their activity importance";
    const headers = [
      [
        this.state.isMilestoneTasks
          ? "Forecasted Milestone"
          : "Forecasted Business Measure",
        "Activity",
        "Current Status",
        "Recommendation",
        "Activity Importance",
      ],
    ];

    let data = this.state.keyFilterData.map((elt) => [
      elt.milestone,
      elt.activity,
      elt.status,
      elt.relevance
        ? elt.action.length != 0
          ? elt.relevance + mapAction[elt.action]
          : elt.relevance == "In date"
            ? elt.status == "R" || elt.status == "NA"
              ? elt.relevance + " - action required"
              : elt.status == "A"
                ? elt.relevance + " - review"
                : elt.relevance + " - no action required"
            : elt.relevance
        : elt.status === "B"
          ? "Out of date"
          : "In date",
      elt.weight,
    ]);

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => { },
      startY: 85,
      startX: marginLeft,
      head: headers,
      columnStyles: { 4: { halign: "center" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    let splitTitle = doc.splitTextToSize(title, 500);
    doc.text(splitTitle, marginLeft, 40);
    doc.setFontSize(10);
    doc.text(this.state.projectName, marginLeft, 75);
    doc.autoTable(content);
    doc.save(
      this.state.projectName
        ? this.state.projectName.split("(")[0] + "-" + title + ".pdf"
        : title + ".pdf"
    );
  };

  getSortDesOrder(prop) {
    return function (a, b) {
      if (parseFloat(a[prop]) < parseFloat(b[prop])) {
        return 1;
      } else if (parseFloat(a[prop]) > parseFloat(b[prop])) {
        return -1;
      }
      return 0;
    };
  }

  getSortAscOrder(prop) {
    return function (a, b) {
      if (parseFloat(a[prop]) > parseFloat(b[prop])) {
        return 1;
      } else if (parseFloat(a[prop]) < parseFloat(b[prop])) {
        return -1;
      }
      return 0;
    };
  }

  arraySortAscending(key) {
    let array = this.state.keyFilterData;
    let getSortAscOrder = (prop) => {
      return function (a, b) {
        if (parseFloat(a[prop]) > parseFloat(b[prop])) {
          return 1;
        } else if (parseFloat(a[prop]) < parseFloat(b[prop])) {
          return -1;
        }
        return 0;
      };
    };
    if (array !== null) {
      array.sort(getSortAscOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      keyFilterData: array,
      lessClickKey: "",
      moreClickKey: key,
    });
  }

  arraySortDescending(key) {
    let array = this.state.keyFilterData;
    let getSortDesOrder = (prop) => {
      return function (a, b) {
        if (parseFloat(a[prop]) < parseFloat(b[prop])) {
          return 1;
        } else if (parseFloat(a[prop]) > parseFloat(b[prop])) {
          return -1;
        }
        return 0;
      };
    };
    if (array !== null) {
      array.sort(getSortDesOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      keyFilterData: array,
      lessClickKey: key,
      moreClickKey: "",
    });
  }

  onStatusSelect(tableData, selectedList, selectedItem) {
    this.setState({
      selectedStatusValue: selectedList,
      lessClickKey: null,
      moreClickKey: null,
    });
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.onRelevanceSelectSecond(
        tableData,
        this.state.selectedRelevanceValue
      );
    } else if (selectedList.length == 0) {
      this.onRelevanceSelectSecond(
        tableData,
        this.state.selectedRelevanceValue
      );
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["status"].toLowerCase() == selectedList[i].value.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.onRelevanceSelectSecond(
            modalTableFilterData,
            this.state.selectedRelevanceValue
          );
        }
      }
    }
  }

  onRelevanceSelect(tableData, selectedList, selectedItem) {
    this.setState({
      selectedRelevanceValue: selectedList,
      lessClickKey: null,
      moreClickKey: null,
      InDateEmptyMessage: false
    });
    let keyFilterData = [];
    let dataArray = [];
    if (selectedList == null) {
      this.onStatusSelectSecond(tableData, this.state.selectedStatusValue);
    } else if (selectedList.length == 0) {
      this.onStatusSelectSecond(tableData, this.state.selectedStatusValue);
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            selectedList[i].value
              .toLowerCase()
              .indexOf(data["relevance"].toLowerCase()) === 0
          ) {
            keyFilterData.push(data);
          }
          /*
            if (selectedList[i].value == "In date") {
              if (data["status"] != "B") {
                
              }
            } else if (selectedList[i].value == "Out of date") {
              if (data["status"] == "B") {
                keyFilterData.push(data);
              }
            }
          */
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.onStatusSelectSecond(
            keyFilterData,
            this.state.selectedStatusValue
          );
        }
      }
      if (keyFilterData.length === 0 && selectedList.length === 1 && selectedList[0].value === 'In date') {
        this.setState({ InDateEmptyMessage: true })
      } else {
        this.setState({ InDateEmptyMessage: false })
      }
    }
  }

  onStatusSelectSecond(tableData, selectedList, selectedItem) {
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        keyFilterData: tableData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        keyFilterData: tableData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["status"].toLowerCase() == selectedList[i].value.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({ keyFilterData: modalTableFilterData });
        }
      }
    }
  }

  onRelevanceSelectSecond(tableData, selectedList, selectedItem) {
    let keyFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        keyFilterData: tableData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        keyFilterData: tableData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            selectedList[i].value
              .toLowerCase()
              .indexOf(data["relevance"].toLowerCase()) === 0
          ) {
            keyFilterData.push(data);
          }
          /*
          if (selectedList[i].value == "In date") {
            if (data["status"] != "B") {
              keyFilterData.push(data);
            }
          } else if (selectedList[i].value == "Out of date") {
            if (data["status"] == "B") {
              keyFilterData.push(data);
            }
          }
          */
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({ keyFilterData: keyFilterData });
        }
      }
    }
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if(this.state?.defaultProjectVal === null && this.props.projectsDataDefaultVal){
      const {projectNameIdMap} = prevProps;
        if(projectNameIdMap || this.props?.projectNameIdMap){
            this.setDefaultVal();
        }
    }
  }

  setDefaultVal() {
    if(this.props?.projectsDataDefaultVal){
      const {projectNameIdMap} = this.props;
      if(projectNameIdMap){
        for(let key in projectNameIdMap){
          if(projectNameIdMap[key] === this.props.projectsDataDefaultVal){
            this.setState({
              defaultProjectVal:true
            })
            this.setProjectName(key,true);
            break;
          }
        }
        this.setState({defaultValueChecked: true});
      }
      
    }
    
  }

  render() {
    if (this.state?.goToCurrentPerformance) {
      let pathname = `/ui/landing/currentstatus`;
      const projectId = this.props.projectNameIdMap[this.state.projectName];
      return (
        <Redirect
          to={{
            pathname,
            state: {
              projectId: projectId
            },
          }}
        />
      );
    }
    let mapAction = {
      R: " - review",
      AR: " - action required",
      NAR: " - no action required",
    };
    const { showMap, isLoading } = this.state;
    const { projectNameIdMap } = this.props;
    const projectNameIdMapKeys = projectNameIdMap && Object.keys(projectNameIdMap);

    this.showTubeMap(
      this.state.isActivitiesVisible,
      this.state.isMilestoneVisible,
      this.state.singleProjectData
    );
    let year = 0,
      month = 0;
    /*const milestoneButtonLabel = this.state.isMilestoneVisible
    ? "Hide Milestones"
    : "Show Milestones";
  const activityButtonLabel = this.state.isActivitiesVisible
    ? "Hide Activities"
    : "Show Activities";
  const milestoneButtonColor = this.state.isMilestoneVisible
    ? "map-primary"
    : "map-secondary";
  const activityButtonColor = this.state.isActivitiesVisible
    ? "map-primary"
    : "map-secondary";*/
    let milestoneRadioChecked = false;
    let activityRadioChecked = false;
    let bothRadioChecked = false;
    if (this.state.isMilestoneVisible && !this.state.isActivitiesVisible) {
      milestoneRadioChecked = true;
    }
    if (!this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      activityRadioChecked = true;
    }
    if (this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      bothRadioChecked = true;
    }

    let divStyle = {
      height: 0 + "rem",
    };

    if (this.state.keyData) {
      if (this.state.keyData.length) {
        if (
          Math.ceil(this.state.keyData.length / this.state.pageSize) -
          this.state.currentPage ===
          1 &&
          this.state.keyData.length % this.state.pageSize !== 0
        ) {
          divStyle = {
            height:
              2.5 *
              (this.state.pageSize -
                (this.state.keyData.length % this.state.pageSize)) +
              "rem",
          };
        }
      }
    }

    return (
      <div>
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        {this.props.isFetchProjectNamesLoading == false &&
          (projectNameIdMap == null || projectNameIdMap == "{}") && (
            <div className="no-data-available-dashboard">
              No project data available for dashboard. Please upload data to
              view dashboard
            </div>
          )}
        {this.props.isFetchProjectNamesLoading == true && (
          <PageSpinner></PageSpinner>
        )}
        {isLoading == true && <PageSpinner></PageSpinner>}
        {this.props.isFetchProjectNamesLoading == false &&
          projectNameIdMap != null && (
            <div>
              <Row>
                <Col
                  md={{ size: 8, offset: 2 }}
                  sm={{ size: 10, offset: 1 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <center>
                    {/* <div style={{ width: "650px" }}> */}
                    <Row>
                      <div className="col-8">
                      {((this.props.projectsDataDefaultVal && (this.state.defaultValueChecked || this.state?.defaultProjectVal !== null)) || (!this.props.projectsDataDefaultVal)) && projectNameIdMap != null && (
                          <Autocomplete
                            id="projectSearch"
                            defaultValue={this.state.projectName}
                            options={projectNameIdMapKeys}
                            className="auto-complete"
                            onSelect={(e) =>
                              this.setProjectName(e.target.value)
                            }
                            renderInput={(params) => (
                              <TextField
                                className="search-input"
                                {...params}
                                placeholder="Search Project"
                              />
                            )}
                          />
                        )}
                        {projectNameIdMap == null && (
                          <div>
                            <div className="auto-complete">
                              <input
                                className="search-input"
                                placeholder="Loading..."
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <Button
                        color="primary"
                        size="md"
                        style={{
                          width: window.innerWidth < 1000 ? "7rem" : "9rem",
                        }}
                        onClick={(e) => this.fetchProjectData()}
                      >
                        <MdSearch size={20}></MdSearch>
                        <div className="search-text">Search</div>
                      </Button>
                    </Row>
                    {/* </div> */}
                  </center>
                </Col>
              </Row>
              {showMap == false && (
                <div>
                  <PageSpinner />
                </div>
              )}
              {showMap == null && <div></div>}
              {showMap == true && (
                <div>
                  <Row>
                    <Col>
                      <div className="map-top">
                        <div className="chart-header">
                          Forecasted performance by milestones{" "}
                          <span
                            data-title="The tube map represents the selected project with it's status of current activities and milestones and the next forecasted milestone.​ Clicking on each of the key business measures of the chart shows a list of activities that impact the milestone or the business measure​​"
                            data-title-xlg
                          >
                            <MdInfo size={20} className="c-info"></MdInfo>
                          </span>
                          <div className="chart-buttons2">
                            {/*<Button className='map-buttons' size='sm' color={milestoneButtonColor} onClick={() => { this.toggleMilestones() }}>
                              {milestoneButtonLabel}
                            </Button> &nbsp;
                            <Button className="map-buttons" size="sm" color={activityButtonColor} onClick={() => { this.toggleActivities() }}>
                              {activityButtonLabel}
                            </Button>*/}
                            <div>
                              <label className="radio-label">
                                Milestones
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="milestone"
                                  checked={milestoneRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>

                              <label className="radio-label">
                                Activities
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="activity"
                                  checked={activityRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>

                              <label className="radio-label">
                                Both
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="both"
                                  checked={bothRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            {/*  <div>
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {" "}
                            Milestones &nbsp;{" "}
                          </div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={() => {
                                this.toggleMilestones();
                              }}
                              checked={this.state.isMilestoneVisible}
                            />
                            <span className="toggle-slider round"></span>
                          </label>
                          &nbsp;
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {" "}
                            Activities &nbsp;{" "}
                          </div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={() => {
                                this.toggleActivities();
                              }}
                              checked={this.state.isActivitiesVisible}
                            />
                            <span className="toggle-slider round"></span>
                          </label>
                        </div>*/}
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              float: "right",
                              paddingTop: "0.5rem",
                              paddingRight: "0.5rem",
                            }}
                          >
                            <Button
                              size="sm"
                              onClick={() => this.handleCurrentPerformance()}
                              id="current-performance-button"                              
                              color="primary"                              
                            >
                              Current Performance
                            </Button>
                          </div>
                        </div>
                        <div className="map-zoom-div1" style={{ width: "auto" }}>
                          <div className="map-zoom-div2">
                            <div id="zoomDiv" className="zoom-div">
                              <div className="zoom-out" id="zoomOutProjectMap">
                                <MdRemove size="20px"></MdRemove>
                              </div>
                              <div className="zoom-in" id="zoomInProjectMap">
                                <MdAdd size="20px"></MdAdd>
                              </div>
                              <div className="zoom-reset">
                                <MdUndo
                                  size="15px"
                                  onClick={() => this.resetZoom()}
                                ></MdUndo>
                              </div>
                              <div
                                className="zoom"
                                id="zoomInitialForcastSearchByProject"
                              ></div>
                            </div>
                            <div id="bragDiv" className="brag-div">
                              <div>
                                <div className="brag-inner-div1">
                                  Needs attention or action
                                  <div
                                    className="brag-circle"
                                    style={{ background: "red" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="redDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  A risk but not an issue yet
                                  <div
                                    className="brag-circle"
                                    style={{ background: "#FFBF00" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="amberDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  On track, no issues
                                  <div
                                    className="brag-circle"
                                    style={{ background: "#0dc70d" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="greenDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Completed, no further action required
                                  <div
                                    className="brag-circle"
                                    style={{ background: "blue" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="blueDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Expired, not due yet, not required
                                  <div
                                    className="brag-circle"
                                    style={{ background: "grey" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="greyDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Data not available (NA)
                                  <div
                                    className="brag-circle"
                                    style={{
                                      background: "white",
                                      border: "1px solid #22A2DF",
                                    }}
                                  ></div>
                                </div>
                                <div className="brag-inner-div2" id="naDiv"></div>
                              </div>
                            </div>
                            <div id="projectTubeMapDiv" className="tubemap"></div>
                          </div>
                        </div>
                        <br />
                        <Row>
                          {this.state.qualityParamData ? (
                            <Col md={2}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.setState({
                                    keyData: this.state.keyActivitiesData,
                                    keyFilterData: this.state.keyActivitiesData,
                                    isMilestoneTasks: true,
                                  });
                                  this.fetchKeyActivities(
                                    "keyActivities",
                                    this.state.keyActivitiesData
                                  );
                                }}
                                size="sm"
                                id="t-c-q-s-buttons"
                              >
                                Milestone Tasks
                              </Button>{" "}
                            </Col>
                          ) : (
                            ""
                          )}
                          {!this.state.qualityParamData ? (
                            <Col md={2}>
                              <Button
                                color="primary"
                                onClick={this.fetchProjectQualityParamsPrediction}
                                size="sm"
                                id="predict-button"
                              >
                                FORECAST
                              </Button>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col>
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_time
                                  ? this.state.qualityParamData.c_time == "G"
                                    ? "success"
                                    : this.state.qualityParamData.c_time == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_time
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.timeData,
                                  keyFilterData: this.state.timeData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities(
                                  "time",
                                  this.state.timeData
                                );
                              }}
                            >
                              &#8239;&nbsp;&#8239;{"Time"}&#8239;&nbsp;&#8239;
                            </Button>
                            {"  "}&nbsp;
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_cost
                                  ? this.state.qualityParamData.c_cost == "G"
                                    ? "success"
                                    : this.state.qualityParamData.c_cost == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_cost
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.costData,
                                  keyFilterData: this.state.costData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities(
                                  "cost",
                                  this.state.costData
                                );
                              }}
                            >
                              &#8239;&nbsp;&#8239;{"Cost"}&#8239;&nbsp;&#8239;
                            </Button>
                            {"  "}&nbsp;
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_quality
                                  ? this.state.qualityParamData.c_quality == "G"
                                    ? "success"
                                    : this.state.qualityParamData.c_quality == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_quality
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.qualityData,
                                  keyFilterData: this.state.qualityData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities(
                                  "quality",
                                  this.state.qualityData
                                );
                              }}
                            >
                              {"Quality"}
                            </Button>
                            {"  "}&nbsp;
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_h_s
                                  ? this.state.qualityParamData.c_h_s == "G"
                                    ? "success"
                                    : this.state.qualityParamData.c_h_s == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_h_s
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.hsData,
                                  keyFilterData: this.state.hsData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities("hs", this.state.hsData);
                              }}
                            >
                              &#8239;&nbsp;&nbsp;{"H&S"}&nbsp;&nbsp;&#8239;
                            </Button>
                            {"  "}&nbsp;
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_scope
                                  ? this.state.qualityParamData.c_scope == "G"
                                    ? "success"
                                    : this.state.qualityParamData.c_scope == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_scope
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.scopeData,
                                  keyFilterData: this.state.scopeData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities(
                                  "scope",
                                  this.state.scopeData
                                );
                              }}
                            >
                              &nbsp;{"Scope"}&nbsp;
                            </Button>
                            {"  "}&nbsp;
                            <Button
                              id="t-c-q-s-buttons"
                              size="sm"
                              color={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_sme_self_assessments
                                  ? this.state.qualityParamData
                                    .c_sme_self_assessments == "G"
                                    ? "success"
                                    : this.state.qualityParamData
                                      .c_sme_self_assessments == "A"
                                      ? "amber"
                                      : "danger"
                                  : "light"
                              }
                              disabled={
                                this.state.qualityParamData &&
                                  this.state.qualityParamData.c_sme_self_assessments
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                this.setState({
                                  keyData: this.state.smeData,
                                  keyFilterData: this.state.smeData,
                                  isMilestoneTasks: false,
                                });
                                this.fetchKeyActivities(
                                  "sme",
                                  this.state.smeData
                                );
                              }}
                            >
                              &#8239;{"SME SA"}&#8239;
                            </Button>
                            {"  "}
                          </Col>
                          <Col xs={2}></Col>
                        </Row>
                        {this.state.showKeyActivitiesModal ? (
                          <div>
                            <Modal
                              isOpen="true"
                              centered="true"
                              size="lg"
                              className="current-forecasted-modal"
                            >
                              <ModalBody
                                id="show-key-activities-modal"
                                className="tcqs-current-modal-body"
                              >
                                <CancelIcon
                                  onClick={this.closeTaskListModal}
                                  className="cancel-close-icon"
                                />
                                <Row>
                                  <Col sm={{ size: 12, offset: 0 }}>
                                    <center>
                                      <span id="show-key-activities-modal-project-name">
                                        {this.state.projectName}
                                      </span>
                                    </center>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <center>
                                      <span id="key-tasks-to-text">
                                        Key tasks to focus on
                                      </span>
                                    </center>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <center>
                                      <Table
                                        responsive
                                        className="table table-md table-custom table-single-project-forecast table-scroll-modal table-modal"
                                        id="table-task-list"
                                      >
                                        <thead className="table-head">
                                          <tr>
                                            <th className="non-filter-col milestone-col">
                                              {this.state.isMilestoneTasks
                                                ? "Forecasted Milestone"
                                                : "Forecasted Business Measure"}
                                            </th>
                                            <th className="non-filter-col activity-col">
                                              Activity
                                            </th>
                                            <BootstrapTooltip
                                              title="Current Status"
                                              placement="top-center"
                                            >
                                              <th
                                                style={{
                                                  paddingBottom: "0.2rem",
                                                  paddingTop: "0.2rem",
                                                  width: "10vw",
                                                }}
                                              >
                                                <Select
                                                  closeMenuOnSelect={false}
                                                  blurInputOnSelect={false}
                                                  isMulti
                                                  options={colourOptionsStatus}
                                                  styles={colourStyles}
                                                  value={
                                                    this.state.selectedStatusValue
                                                  }
                                                  onChange={this.onStatusSelect.bind(
                                                    this,
                                                    this.state.keyData
                                                  )}
                                                  placeholder="Current Status"
                                                  isSearchable
                                                />
                                              </th>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip
                                              title="Recommendation"
                                              placement="top-center"
                                            >
                                              <th
                                                style={{
                                                  paddingBottom: "0.2rem",
                                                  paddingTop: "0.2rem",
                                                  width: "12vw",
                                                }}
                                              >
                                                <Select
                                                  closeMenuOnSelect={false}
                                                  blurInputOnSelect={false}
                                                  isMulti
                                                  options={colourOptions}
                                                  styles={colourStyles}
                                                  value={
                                                    this.state
                                                      .selectedRelevanceValue
                                                  }
                                                  onChange={this.onRelevanceSelect.bind(
                                                    this,
                                                    this.state.keyData
                                                  )}
                                                  placeholder="Recommendation"
                                                  isSearchable
                                                />
                                              </th>
                                            </BootstrapTooltip>
                                            <th
                                              className="centre-align-data"
                                              style={{
                                                paddingBottom: "0.45rem",
                                                width: "12vw",
                                              }}
                                            >
                                              Activity Importance
                                              <ExpandLessIcon
                                                onClick={() => {
                                                  this.arraySortDescending(
                                                    "weight"
                                                  );
                                                }}
                                                fontSize="small"
                                                className="expand-less-icon-sorting"
                                                style={{
                                                  color:
                                                    "weight" ===
                                                      this.state.lessClickKey
                                                      ? "red"
                                                      : "black",
                                                }}
                                              />
                                              <ExpandMoreIcon
                                                onClick={() => {
                                                  this.arraySortAscending(
                                                    "weight"
                                                  );
                                                }}
                                                fontSize="small"
                                                className="expand-more-icon-sorting"
                                                style={{
                                                  color:
                                                    "weight" ===
                                                      this.state.moreClickKey
                                                      ? "red"
                                                      : "black",
                                                }}
                                              />
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.keyFilterData
                                            ? this.state.keyFilterData
                                              /*.slice(
                                              this.state.currentPage *
                                              this.state.pageSize,
                                              (this.state.currentPage + 1) *
                                                this.state.pageSize
                                              )
                                            */
                                              .map((activityObject) => (
                                                <tr>
                                                  <td className="table-data-main non-filter-col milestone-col">
                                                    {activityObject.milestone}
                                                  </td>
                                                  <td
                                                    id="activity-object-milestone"
                                                    className="non-filter-col activity-col"
                                                  >
                                                    {activityObject.activity}
                                                  </td>
                                                  <td
                                                    className="centre-align-data"
                                                    style={{ width: "10vw" }}
                                                  >
                                                    {activityObject[
                                                      "status"
                                                    ] ? (
                                                      activityObject[
                                                        "status"
                                                      ] != "NA" ? (
                                                        <div
                                                          className={
                                                            activityObject[
                                                              "status"
                                                            ] === "R"
                                                              ? "red-circle"
                                                              : activityObject[
                                                                "status"
                                                              ] === "GR"
                                                                ? "grey-circle"
                                                                : activityObject[
                                                                  "status"
                                                                ] === "A"
                                                                  ? "amber-circle"
                                                                  : activityObject[
                                                                    "status"
                                                                  ] === "B"
                                                                    ? "blue-circle"
                                                                    : "green-circle"
                                                          }
                                                        ></div>
                                                      ) : (
                                                        "NA"
                                                      )
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    id="activity-object-milestone"
                                                    style={{
                                                      width: "12vw",
                                                      paddingLeft: "2rem",
                                                    }}
                                                    className="rele-col"
                                                  >
                                                    <span style={{}}>
                                                      {activityObject.relevance
                                                        ? activityObject.action
                                                          .length != 0
                                                          ? activityObject.relevance +
                                                          mapAction[
                                                          activityObject
                                                            .action
                                                          ]
                                                          : activityObject.relevance ==
                                                            "In date"
                                                            ? activityObject.status ==
                                                              "R" ||
                                                              activityObject.status ==
                                                              "NA"
                                                              ? activityObject.relevance +
                                                              " - action required"
                                                              : activityObject.status ==
                                                                "A"
                                                                ? activityObject.relevance +
                                                                " - review"
                                                                : activityObject.relevance +
                                                                " - no action required"
                                                            : activityObject.relevance
                                                        : activityObject.status ===
                                                          "B"
                                                          ? "Out of date"
                                                          : "In date"}
                                                    </span>
                                                  </td>
                                                  <td
                                                    className="centre-align-data"
                                                    style={{ width: "12vw" }}
                                                  >
                                                    {activityObject.weight}
                                                  </td>
                                                </tr>
                                              ))
                                            : ""}
                                            {this.state.InDateEmptyMessage && <div style={{ textAlign: 'center', padding: '20px', color: 'black', fontSize: '13px' }}>
                                              No key tasks to focus means all activities still impacting the forecasted business measure are out of date.
                                              <br></br>Remove the filter 'in date' from recommendation to see all activities.
                                            </div>}
                                        </tbody>
                                      </Table>
                                      {this.state.keyData &&
                                        JSON.stringify(this.state.keyData) !==
                                        JSON.stringify([]) ? (
                                        <div>
                                          {/*
                                      {Math.ceil(
                                        this.state.keyData.length /
                                          this.state.pageSize
                                      ) > 1 ? (
                                        <div>
                                          <span className="showing-span">
                                            <span className="showing-text-pagination">
                                              Showing{" "}
                                            </span>
                                            <span>
                                              {this.state.currentPage + 1}{" "}
                                            </span>
                                            <span className="showing-text-pagination">
                                              out of{" "}
                                            </span>
                                            <span>
                                              {Math.ceil(
                                                this.state.keyData.length /
                                                  this.state.pageSize
                                              )}{" "}
                                            </span>
                                            <span className="showing-text-pagination">
                                              pages{" "}
                                            </span>
                                          </span>
                                          <div id="tcqs-modal-pagination-div">
                                            <div id="tcqs-modal-pagination-style">
                                              <TablePagination
                                                pagesCount={Math.ceil(
                                                  this.state.keyData.length /
                                                    this.state.pageSize
                                                )}
                                                currentPage={
                                                  this.state.currentPage
                                                }
                                                setCurrentPage={
                                                  this.setCurrentPage
                                                }
                                              />
                                            </div>
                                              </div>
                                          
                                        </div>
                                      ) : (
                                        ""
                                        )}
                                      */}
                                        </div>
                                      ) : (
                                        <div id="current-month-forecast-modal-no-data">
                                          No data available for key activities
                                        </div>
                                      )}
                                    </center>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <center>
                                      <Button
                                        color="primary"
                                        onClick={this.downloadKeyActivitiesExcel}
                                        size="sm"
                                        id="download-list-key-actvity"
                                        disabled={
                                          this.state.keyFilterData &&
                                            JSON.stringify(
                                              this.state.keyFilterData
                                            ) !== JSON.stringify([])
                                            ? false
                                            : true
                                        }
                                      >
                                        {this.state.isDownloading ? (
                                          <div>
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                            <span className="sr-only">
                                              Loading...
                                            </span>{" "}
                                            Downloading...
                                          </div>
                                        ) : (
                                          <div>
                                            <GetAppIcon fontSize="small" />{" "}
                                            DOWNLOAD LIST
                                          </div>
                                        )}
                                      </Button>
                                    </center>
                                  </Col>
                                </Row>
                              </ModalBody>
                            </Modal>
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <SingleProjectHealthScoreTrendChart
                      projectCurrentHealthData={this.state.projectCurrentHealthData}
                      projectForecastedHealthData={this.state.projectForecastedHealthData} projectId={this.props.projectNameIdMap[this.state.projectName]}
                      isForecasted={true}
                    />
                    <SingleProjectActivities
                      singleProjectActivities={this.state.singleProjectActivities}
                      selectedMeasure={this.state.selectedMeasure}
                      csvColumnMapping={this.props.csvColumnMapping}
                      getSingleProjectActivities={this.getSingleProjectActivities} />
                  </Row>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

export default ProjectMap;
