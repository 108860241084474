import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import axios from "axios";
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { ListGroupItemHeading } from "reactstrap";
import { Spinner } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import { Modal, ModalBody, Button, Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockIcon from "@material-ui/icons/Lock";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import "../../styles/components/layout/Sidebar.scss";
import AutoCloseAlert from "../AutoCloseAlert";
import { ErrorHandler } from "../ErrorHandler";
import { HEADER_TYPE_JSON } from "../RequestHeader";
import { ErrorHandlerChangePassword } from "../ErrorHandler";
import logo from "../../images/logo-image.png";
import SummarizeIcon from "../../images/sidebar-icons/summarize.svg"; 
import SummarizeActiveIcon from "../../images/sidebar-icons/summarize-active.svg"; 
import CurrentStatusIcon from "../../images/sidebar-icons/current-status.svg";
import CurrentStatusActiveIcon from "../../images/sidebar-icons/current-status-active.svg";
import PredictionsIcon from "../../images/sidebar-icons/predictions.svg";
import PredictionsActiveIcon from "../../images/sidebar-icons/predictions-active.svg";
import CurrentVsForecastedIcon from "../../images/sidebar-icons/current-vs-forecasted.svg";
import CurrentVsForecastedActiveIcon from "../../images/sidebar-icons/current-vs-forecasted-active.svg";
import UploadDataIcon from "../../images/sidebar-icons/upload-data.svg";
import UploadDataActiveIcon from "../../images/sidebar-icons/upload-data-active.svg";
import TrainModelIcon from "../../images/sidebar-icons/train-model.svg";
import TrainModelActiveIcon from "../../images/sidebar-icons/train-model-active.svg";
import UploadHistoryIcon from "../../images/sidebar-icons/upload-history.svg";
import UploadHistoryActiveIcon from "../../images/sidebar-icons/upload-history-active.svg";
import PendingRequestsIcon from "../../images/sidebar-icons/pending-requests.svg";
import PendingRequestsActiveIcon from "../../images/sidebar-icons/pending-requests-active.svg";
import AllUsersIcon from "../../images/sidebar-icons/all-users.svg";
import AllUsersActiveIcon from "../../images/sidebar-icons/all-users-active.svg";
import ProgramAssuranceIcon from "../../images/sidebar-icons/program-assurance.svg";
import ProgramAssuranceActiveIcon from "../../images/sidebar-icons/program-assurance-active.svg";
import LogoutIcon from "../../images/sidebar-icons/logout.svg";

let drawerWidth = 220;

if (window.innerWidth < 1000) {
  drawerWidth = 160;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
    backgroundColor: "#fff",
    color: "black",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
    overflowX: "hidden",
    width: window.innerWidth < 1000 ? "2.1rem" : "3.3rem",
    [theme.breakpoints.up("sm")]: {
      width: window.innerWidth < 1000 ? "2.1rem" : "3.3rem",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const Logout = (setLogoutDone, setShowAlert, setAlertMsg, setAlertcolor) => {
  return axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/logout`,
    method: "POST",
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("isAdmin");
      //sessionStorage.removeItem("traningProgressShowOnOpen");
      //sessionStorage.removeItem("startTime");
      // remove session data for Months Activity chart
      sessionStorage.removeItem("MonthsActivityChartData-R");
      sessionStorage.removeItem("MonthsActivityChartData-G");
      sessionStorage.removeItem("MonthsActivityChartData-B");
      sessionStorage.removeItem("MonthsActivityChartData-A");
      sessionStorage.removeItem("MonthsActivityChartData-NA");
      sessionStorage.removeItem("MonthsActivityChartData-All");
      setLogoutDone(true);
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const Sidebar = (props) => {
  const page = props.page;
  const open = props.open;
  const pendingReqs = props.pendingReqs;
  const setLogoutDone = props.setLogoutDone;
  const setPage = props.setPage;
  const username = sessionStorage.getItem("username");
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertcolor, setAlertcolor] = React.useState("danger");
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState(false);

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const handelCloseInTablet = () => {
    if (window.innerWidth < 1000) props.setOpen(false);
  };

  const AdminSidebarList = () => {
    return (
      <React.Fragment>
        <List>
          <ListGroupItemHeading id="list-group-heading">
            <b>Users</b>
          </ListGroupItemHeading>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("pendingrequests");
              history.push({
                pathname: "/ui/landing/pendingrequests",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "pendingrequests" ? (
                <img
                  src={PendingRequestsActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={PendingRequestsIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "pendingrequests" ? "#268CD1" : "#88888a",
                }}
              >
                Pending Requests &nbsp;
                {pendingReqs > 0 && (
                  <div className="pending-items"> {pendingReqs} </div>
                )}
                {pendingReqs < 0 && (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </span>
                )}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("allusers");
              history.push({
                pathname: "/ui/landing/allusers",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "allusers" ? (
                <img
                  src={AllUsersActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={AllUsersIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "allusers" ? "#268CD1" : "#88888a",
                }}
              >
                All Users
              </div>
            </ListItemText>
          </ListItem>
        </List>
      </React.Fragment>
    );
  };

  const passwordRegex = RegExp(
    /(?=^.{8,200}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
  );

  class ChangePasswordModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        changePasswordRequestURL: `${[
          process.env.REACT_APP_BACKEND_API,
        ]}/change-password`,
        currentPassword: null,
        password: null,
        confirmPassword: null,
        formErrors: {
          currentPassword: "",
          password: "",
          confirmPassword: "",
        },
        showMessage: false,
        message: "",
        showAlert: false,
        alertMsg: "",
        alertcolor: "danger",
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.closeModal = this.closeModal.bind(this);
    }

    setShowAlertMessage = (value) => {
      this.setState({ showAlert: value });
    };

    handleSubmit = (e) => {
      e.preventDefault();
      if (this.state.username === null) {
        this.setState((prevState) => {
          let formErrors = Object.assign({}, prevState.formErrors);
          formErrors.username = "Password cannot be empty";
          return { formErrors };
        });
      }
      if (this.state.password === null) {
        this.setState((prevState) => {
          let formErrors = Object.assign({}, prevState.formErrors);
          formErrors.password = "New Password cannot be empty";
          return { formErrors };
        });
      }
      if (this.state.confirmPassword === null) {
        this.setState((prevState) => {
          let formErrors = Object.assign({}, prevState.formErrors);
          formErrors.confirmPassword = "Confirm New Password cannot be empty";
          return { formErrors };
        });
      }
      const postData = {
        data: {
          currentPassword: this.state.currentPassword,
          newPassword: this.state.password,
          confirmPassword: this.state.confirmPassword,
        },
      };
      if (
        this.state.formErrors.currentPassword === "" &&
        this.state.formErrors.password === "" &&
        this.state.formErrors.confirmPassword === ""
      ) {
        return axios({
          url: this.state.changePasswordRequestURL,
          method: "POST",
          data: postData,
          headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
        })
          .then((response) => {
            if (response.data.success === true) {
              this.setState({
                alertcolor: "success",
                showAlert: true,
                alertMsg: "Success: Password changed successfully",
              });
              window.setTimeout(() => {
                Logout(setLogoutDone, setShowAlert, setAlertMsg, setAlertcolor);
              }, 1300);
            } else if (response.data.success === false) {
              this.setState({ message: response.data.message });
              this.setState({ showMessage: true });
            }
          })
          .catch((error) => {
            this.setState({ alertcolor: "danger" });
            let alert = ErrorHandlerChangePassword(error);
            this.setState({
              showAlert: alert.setAlertShow,
              alertMsg: alert.setMsgAlert,
              showMessage: alert.showMessage,
              message: alert.message,
            });
          });
      }
    };

    handleChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      let formErrors = { ...this.state.formErrors };
      switch (name) {
        case "currentPassword":
          formErrors.currentPassword =
            value.length === 0 ? "Current Password cannot be empty" : "";
          break;
        case "password":
          formErrors.password = passwordRegex.test(value)
            ? value === this.state.currentPassword
              ? "Current Password and New Password cannot be same"
              : ""
            : "Min 8 characters with at least one capital, one special character and one number required";
          if (passwordRegex.test(value)) {
            this.setState({ found: true });
          }
          break;
        case "confirmPassword":
          formErrors.confirmPassword =
            value === this.state.password
              ? ""
              : "Password and Confirm Password are not matching";
          break;
        default:
          break;
      }
      this.setState({ formErrors, [name]: value });
    };

    closeModal() {
      setShowChangePasswordModal(false);
    }

    render() {
      return (
        <div>
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            {this.state.showAlert ? (
              <AutoCloseAlert
                color={this.state.alertcolor}
                body={this.state.alertMsg}
                showAlert={this.state.showAlert}
                setShowAlert={this.setShowAlertMessage}
              />
            ) : (
              ""
            )}
            <ModalBody size="sm" id="confirmation-modal-body">
              <CancelIcon
                onClick={this.closeModal}
                id="change-password-cancel-icon"
              />
              <br />
              <Row>
                <Col md={{ size: 10, offset: 0 }}>
                  <img
                    src={logo}
                    alt="United Utilities"
                    id="logo-change-password"
                    align="left"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={{ size: 10, offset: 1 }}>
                  <div id="change-password-heading">Change Password</div>
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 10, offset: 1 }}>
                  <div id="please-set-new-password-sidebar">
                    Please set new password to continue
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={{ size: 10, offset: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {this.state.showMessage && (
                        <span
                          className="errorMessage"
                          id="main-error-message"
                          style={{ marginBottom: "0.8rem" }}
                        >
                          {this.state.message}
                        </span>
                      )}
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="currentPassword"
                        label="Current Password"
                        type="password"
                        id="currentPassword"
                        noValidate
                        onChange={this.handleChange}
                        className="text-field-change-password"
                        size="small"
                      />
                      {this.state.formErrors.currentPassword.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.currentPassword}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        noValidate
                        onChange={this.handleChange}
                        className="text-field-change-password"
                        size="small"
                      />
                      {this.state.formErrors.password.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.password}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        onChange={this.handleChange}
                        className="text-field-change-password"
                        size="small"
                      />
                      {this.state.formErrors.confirmPassword.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.confirmPassword}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    type="submit"
                    color="primary"
                    id="submit-style-sidebar"
                    onClick={this.handleSubmit}
                    className="rounded-lg"
                    disabled={
                      this.state.formErrors.currentPassword === "" &&
                      this.state.formErrors.password === "" &&
                      this.state.formErrors.confirmPassword === "" &&
                      this.state.currentPassword !== null &&
                      this.state.password !== null &&
                      this.state.confirmPassword !== null
                        ? false
                        : true
                    }
                    block
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div id="please-set-new-password-sidebar">
                    <center>
                      <i>Please login again with your new password</i>
                    </center>
                  </div>
                </Col>
              </Row>
              <br />
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }

  if (open === false) {
    return (
      <div className={classes.root}>
        {showAlert ? (
          <AutoCloseAlert
            color={alertcolor}
            body={alertMsg}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
          />
        ) : (
          ""
        )}
        {showChangePasswordModal ? <ChangePasswordModal /> : <div></div>}
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          id="drawer-main-div-font"
          transitionDuration={0}
        >
          <div className={classes.drawerHeader}>
            <img
              src={require("../../images/logo-image.png")}
              alt=""
              className="img-fuid"
              id="logo-sidebar"
            />
            <BootstrapTooltip title="Close Sidebar">
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </BootstrapTooltip>
          </div>
          <Divider />
          <List>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("summary");
                history.push({
                  pathname: "/ui/landing/summary",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Summary">
                <ListItemIcon>
                  {page === "summary" ? (
                    <img
                      src={SummarizeActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={SummarizeIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("currentstatus");
                history.push({
                  pathname: "/ui/landing/currentstatus",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Current Performance">
                <ListItemIcon>
                  {page === "currentstatus" ? (
                    <img
                      src={CurrentStatusActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={CurrentStatusIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("forecast");
                history.push({
                  pathname: "/ui/landing/forecast",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Forecasted Performance">
                <ListItemIcon id="list-icon-div-closed-sidebar">
                  {page === "forecast" ? (
                    <img
                      src={PredictionsActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={PredictionsIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("currentvsforecasted");
                history.push({
                  pathname: "/ui/landing/currentvsforecasted",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Current vs Forecasted">
                <ListItemIcon id="list-icon-div-closed-sidebar">
                  {page === "currentvsforecasted" ? (
                    <img
                      src={CurrentVsForecastedActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={CurrentVsForecastedIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
          </List>
          <List>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("uploaddata");
                history.push({
                  pathname: "/ui/landing/uploaddata",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Upload Data">
                <ListItemIcon>
                  {page === "uploaddata" ? (
                    <img
                      src={UploadDataActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={UploadDataIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("trainmodel");
                history.push({
                  pathname: "/ui/landing/trainmodel",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="Train Model">
                <ListItemIcon id="list-icon-div-closed-sidebar">
                  {page === "trainmodel" ? (
                    <img
                      src={TrainModelActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={TrainModelIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("history");
                history.push({
                  pathname: "/ui/landing/history",
                  state: {},
                });
              }}
            >
              <BootstrapTooltip title="View Upload History">
                <ListItemIcon id="list-icon-div-closed-sidebar">
                  {page === "history" ? (
                    <img
                      src={UploadHistoryActiveIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  ) : (
                    <img
                      src={UploadHistoryIcon}
                      alt=""
                      className="img-fuid"
                      id="list-icon-sidebar"
                    />
                  )}
                </ListItemIcon>
              </BootstrapTooltip>
            </ListItem>
          </List>
          {isAdmin === true ? (
            <div>
              <List>
                <ListItem
                  button
                  id="list-item"
                  onClick={() => {
                    handelCloseInTablet();
                    setShowChangePasswordModal(false);
                    setPage("pendingrequests");
                    history.push({
                      pathname: "/ui/landing/pendingrequests",
                      state: {},
                    });
                  }}
                >
                  <BootstrapTooltip title="Pending Requests">
                    <ListItemIcon>
                      {page === "pendingrequests" ? (
                        <img
                          src={PendingRequestsActiveIcon}
                          alt=""
                          className="img-fuid"
                          id="list-icon-sidebar"
                        />
                      ) : (
                        <img
                          src={PendingRequestsIcon}
                          alt=""
                          className="img-fuid"
                          id="list-icon-sidebar"
                        />
                      )}
                    </ListItemIcon>
                  </BootstrapTooltip>
                </ListItem>
                <ListItem
                  button
                  id="list-item"
                  onClick={() => {
                    handelCloseInTablet();
                    setShowChangePasswordModal(false);
                    setPage("allusers");
                    history.push({
                      pathname: "/ui/landing/allusers",
                      state: {},
                    });
                  }}
                >
                  <BootstrapTooltip title="All Users">
                    <ListItemIcon id="list-icon-div-closed-sidebar">
                      {page === "allusers" ? (
                        <img
                          src={AllUsersActiveIcon}
                          alt=""
                          className="img-fuid"
                          id="list-icon-sidebar"
                        />
                      ) : (
                        <img
                          src={AllUsersIcon}
                          alt=""
                          className="img-fuid"
                          id="list-icon-sidebar"
                        />
                      )}
                    </ListItemIcon>
                  </BootstrapTooltip>
                </ListItem>
              </List>
            </div>
          ) : (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*
          <Divider />
          <BootstrapTooltip title="Program Assurance">
            <ListItem
              button
              id="list-item"
              onClick={() => {
                handelCloseInTablet();
                setShowChangePasswordModal(false);
                setPage("programassurance");
                history.push({
                  pathname: "/ui/landing/programassurance",
                  state: {},
                });
              }}
              style={{ marginBottom: "0.27rem" }}
            >
              <ListItemIcon>
                {page === "programassurance" ? (
                  <img
                    src={ProgramAssuranceActiveIcon}
                    alt=""
                    className="img-fuid"
                    id="list-icon-sidebar"
                  />
                ) : (
                  <img
                    src={ProgramAssuranceIcon}
                    alt=""
                    className="img-fuid"
                    id="list-icon-sidebar"
                  />
                )}
              </ListItemIcon>
            </ListItem>
          </BootstrapTooltip>
          */}
          <Divider light={true} />
          <BootstrapTooltip title={username}>
            <ListItem id="list-item" style={{ marginBottom: "0.1rem" }}>
              <ListItemIcon>
                <AccountCircleIcon
                  fontSize={window.innerWidth < 1000 ? "inherit" : "medium"}
                  style={{
                    marginLeft:
                      window.innerWidth < 1000 ? "-0.5rem" : "-0.2rem",
                  }}
                />
              </ListItemIcon>
            </ListItem>
          </BootstrapTooltip>
          <Divider />
          <ListItem
            button
            id="list-item"
            onClick={() => {
              setShowChangePasswordModal(true);
            }}
          >
            <BootstrapTooltip title="Change Password">
              <ListItemIcon>
                <LockIcon
                  fontSize={window.innerWidth < 1000 ? "inherit" : "small"}
                  style={{
                    color: showChangePasswordModal ? "#268CD1" : "#bfbfc7",
                    marginLeft:
                      window.innerWidth < 1000 ? "-0.5rem" : "-0.1rem",
                  }}
                />
              </ListItemIcon>
            </BootstrapTooltip>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              Logout(setLogoutDone, setShowAlert, setAlertMsg, setAlertcolor);
            }}
          >
            <BootstrapTooltip title="Sign Out">
              <ListItemIcon style={{ marginTop: "0.4rem" }}>
                <img
                  src={LogoutIcon}
                  alt=""
                  className="img-fuid"
                  id="logout-icon-sidebar"
                />
              </ListItemIcon>
            </BootstrapTooltip>
          </ListItem>
          <br />
        </Drawer>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {showAlert ? (
        <AutoCloseAlert
          color={alertcolor}
          body={alertMsg}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        />
      ) : (
        ""
      )}
      {showChangePasswordModal ? <ChangePasswordModal /> : <div></div>}
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        id="drawer-main-div-font"
      >
        <div className={classes.drawerHeader}>
          <img
            src={require("../../images/logo-image.png")}
            alt=""
            className="img-fuid"
            id="logo-sidebar"
          />
          <BootstrapTooltip title="Close Sidebar">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </BootstrapTooltip>
        </div>
        <Divider />
        <List>
          <ListGroupItemHeading id="list-group-heading">
            <b>Dashboard</b>
          </ListGroupItemHeading>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("summary");
              history.push({
                pathname: "/ui/landing/summary",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "summary" ? (
                <img
                  src={SummarizeActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={SummarizeIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "summary" ? "#268CD1" : "#88888a",
                }}
              >
                Summary
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("currentstatus");
              history.push({
                pathname: "/ui/landing/currentstatus",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "currentstatus" ? (
                <img
                  src={CurrentStatusActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={CurrentStatusIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "currentstatus" ? "#268CD1" : "#88888a",
                }}
              >
                Current Performance
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("forecast");
              history.push({
                pathname: "/ui/landing/forecast",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "forecast" ? (
                <img
                  src={PredictionsActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={PredictionsIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "forecast" ? "#268CD1" : "#88888a",
                }}
              >
                Forecasted Performance
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("currentvsforecasted");
              history.push({
                pathname: "/ui/landing/currentvsforecasted",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "currentvsforecasted" ? (
                <img
                  src={CurrentVsForecastedActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={CurrentVsForecastedIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "currentvsforecasted" ? "#268CD1" : "#88888a",
                }}
              >
                Current vs Forecasted
              </div>
            </ListItemText>
          </ListItem>
        </List>

        <List>
          <ListGroupItemHeading id="list-group-heading">
            <b>Model</b>
          </ListGroupItemHeading>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("uploaddata");
              history.push({
                pathname: "/ui/landing/uploaddata",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "uploaddata" ? (
                <img
                  src={UploadDataActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={UploadDataIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "uploaddata" ? "#268CD1" : "#88888a",
                }}
              >
                Upload Data
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("trainmodel");
              history.push({
                pathname: "/ui/landing/trainmodel",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "trainmodel" ? (
                <img
                  src={TrainModelActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={TrainModelIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "trainmodel" ? "#268CD1" : "#88888a",
                }}
              >
                Train Model
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            id="list-item"
            onClick={() => {
              handelCloseInTablet();
              setShowChangePasswordModal(false);
              setPage("history");
              history.push({
                pathname: "/ui/landing/history",
                state: {},
              });
            }}
          >
            <ListItemIcon>
              {page === "history" ? (
                <img
                  src={UploadHistoryActiveIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              ) : (
                <img
                  src={UploadHistoryIcon}
                  alt=""
                  className="img-fuid"
                  id="list-icon-sidebar"
                />
              )}
            </ListItemIcon>
            <ListItemText id="list-item-text">
              <div
                style={{
                  fontFamily: "Ubuntu-Medium",
                  fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                  color: page === "history" ? "#268CD1" : "#88888a",
                }}
              >
                View Upload History
              </div>
            </ListItemText>
          </ListItem>
        </List>

        {isAdmin === true ? (
          <div>
            <AdminSidebarList />
          </div>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        {/*
        <Divider />
        <ListItem
          button
          id="list-item"
          onClick={() => {
            handelCloseInTablet();
            setShowChangePasswordModal(false);
            setPage("programassurance");
            history.push({
              pathname: "/ui/landing/programassurance",
              state: {},
            });
          }}
          style={{ marginBottom: "0.27rem" }}
        >
          <ListItemIcon>
            {page === "programassurance" ? (
              <img
                src={ProgramAssuranceActiveIcon}
                alt=""
                className="img-fuid"
                id="list-icon-sidebar"
              />
            ) : (
              <img
                src={ProgramAssuranceIcon}
                alt=""
                className="img-fuid"
                id="list-icon-sidebar"
              />
            )}
          </ListItemIcon>
          <ListItemText id="list-item-text">
            <div
              style={{
                fontFamily: "Ubuntu-Medium",
                fontSize: window.innerWidth < 1000 ? "0.65rem" : "0.8rem",
                color: page === "programassurance" ? "#268CD1" : "#88888a",
              }}
            >
              Program Assurance
            </div>
          </ListItemText>
        </ListItem>
        */}
        <Divider />
        <ListItem id="list-item" style={{ marginBottom: "0.1rem" }}>
          <ListItemIcon>
            <AccountCircleIcon
              fontSize={window.innerWidth < 1000 ? "inherit" : "medium"}
              style={{
                marginLeft: window.innerWidth < 1000 ? "-0.36rem" : "-0.2rem",
              }}
            />
          </ListItemIcon>
          <ListItemText id="list-item-text">
            <div id="username-text-sidebar" className="text-capitalize">
              {username}
            </div>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          id="list-item"
          onClick={() => {
            handelCloseInTablet();
            setShowChangePasswordModal(true);
          }}
        >
          <ListItemIcon>
            <LockIcon
              fontSize={window.innerWidth < 1000 ? "inherit" : "small"}
              style={{
                color: showChangePasswordModal ? "#268CD1" : "#bfbfc7",
                marginLeft: window.innerWidth < 1000 ? "-0.4rem" : "-0.1rem",
              }}
            />
          </ListItemIcon>
          <ListItemText id="list-item-text">
            <div
              id="logout-text"
              style={{
                color: showChangePasswordModal ? "#268CD1" : "#88888a",
              }}
            >
              Change Password
            </div>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          id="list-item"
          onClick={() => {
            Logout(setLogoutDone, setShowAlert, setAlertMsg, setAlertcolor);
          }}
        >
          <ListItemIcon>
            <img
              src={LogoutIcon}
              alt=""
              className="img-fuid"
              id="logout-icon-sidebar"
            />
          </ListItemIcon>
          <ListItemText id="list-item-text">
            <div id="logout-text">Sign Out</div>
          </ListItemText>
        </ListItem>
        <br />
      </Drawer>
    </div>
  );
};

export default Sidebar;
