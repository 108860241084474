import React, { Component } from "react";
import { Row, Col , Modal, ModalBody} from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { HorizontalBar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ConfidenceScoreChartDetails from "./ConfidenceScoreChartDetails";
import CancelIcon from "@material-ui/icons/Cancel";


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class ConfidenceScoreChart extends Component {
  labels = ["Close", "Deliver", "Define", "Identify"];

  constructor(props) {
    super(props);
    this.state = {
      CRadioChecked: true,
      FRadioChecked: false,
    };
    this.onRadioCheck = this.onRadioCheck.bind(this);
  }

  onBarClick(event, i) {
    
    if(!i[0]){
      return;
    }
    
    let data = null;
    if(this.state.CRadioChecked){
      data = this.props.confidenceProjectsData?.currentProjects[this.labels[i[0]._index].toLowerCase()];
    } else {
      data = this.props.confidenceProjectsData?.forecastedProjects[this.labels[i[0]._index].toLowerCase()];
    }
    if(data){
      const title =`List of projects within the ${this.labels[i[0]._index]} phase by their ${this.state.CRadioChecked?'':'forecasted '}confidence score`;
      this.setState({ showProjectListModal: true,modalTableData: data,selectedItem:this.labels[i[0]._index],modalTitle: title});
    }

  }

  closeModal(){
    this.setState({showProjectListModal: false});
  }

  onRadioCheck = (e) => {
    if (e.target.value === "C") {
      this.setState({ CRadioChecked: true, FRadioChecked: false,redraw: true });
    } else {
      this.setState({ CRadioChecked: false, FRadioChecked: true,redraw: true });
    }
  };

  render() {
    let chartData = this.props.chartData;
    let finalChartData = null;
    let cScore = 0;
    let chartDataTemplate = {
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Confidence Score",
            backgroundColor: "#268cd1",
            borderWidth: 0,
            data: null,
            barThickness: 25,
          },
        ],
      },
    };

    const options = {
      responsive: false,
      title: {
        display: false,
        text: "Confidence Score",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            // minBarLength:25, // TODO for 0 bar's view
            ticks: {
              beginAtZero: true,
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return `${value}%`;
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return value;
              },
            },
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 40,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "end",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return `${val}%`;
          },
        },
      },
      tooltips: {
        displayColors: false,
        // enabled: false,
        callbacks: {
          label: (tooltipItem, data) => {
              let dataLen = 0;
              if(this.state.CRadioChecked){
                dataLen = this.props.confidenceProjectsData?.currentProjects[tooltipItem.label.toLowerCase()]?.length || 0;
              } else {
                dataLen = this.props.confidenceProjectsData?.forecastedProjects[tooltipItem.label.toLowerCase()]?.length || 0;
              }
              
              return dataLen.toString();
          },
        },
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      // TODO: Click cross check
      onClick: this.onBarClick.bind(this),
    };

    if (chartData) {
      if (this.state.CRadioChecked) {
        let cData = chartData.currentCS;
        if (cData) {
          chartDataTemplate.data.datasets[0].data = [
            cData.currentClosureCS || 0,
            cData.currentDeliveryCS || 0,
            cData.currentDefineCS || 0,
            cData.currentIdentifyCS || 0,
          ];

          console.log(chartDataTemplate.data.datasets[0]);
          
          chartDataTemplate.data.datasets[0].minBarLength = [];
          chartDataTemplate.data.datasets[0].backgroundColor = [];
          

          for(let item =0; item < chartDataTemplate.data.datasets[0].data.length; item++){
            if(chartDataTemplate.data.datasets[0].data[item] === 0){
              let data = 0;
              if(this.state.CRadioChecked){
                data = this.props.confidenceProjectsData?.currentProjects[chartDataTemplate.data.labels[item].toLowerCase()];
              } else {
                data = this.props.confidenceProjectsData?.forecastedProjects[this.labels[item].toLowerCase()];
              }
              if(data && data.length){
                chartDataTemplate.data.datasets[0].minBarLength.push(5);
                chartDataTemplate.data.datasets[0].backgroundColor.push("#E45266");
              } else {
                chartDataTemplate.data.datasets[0].minBarLength.push(0);
                //chartDataTemplate.data.datasets[0].data[item] = null;
              }
              
            } else {
              chartDataTemplate.data.datasets[0].minBarLength.push(20);
              chartDataTemplate.data.datasets[0].backgroundColor.push("#268cd1");

            }
     
          
          
          }

          cScore = cData.avgCurrentCS;
        }

        finalChartData = (
          <HorizontalBar
            data={chartDataTemplate.data}
            width={500}
            height={250}
            options={options}
            redraw={this.state.redraw}
          />
        );
      } else {
        let cData = chartData.forecastedCS;
        if (cData) {
          chartDataTemplate.data.datasets[0].data = [
            cData.forecastedClosureCS || 0,
            cData.forecastedDeliveryCS || 0, 
            cData.forecastedDefineCS || 0,
            cData.forecastedIdentifyCS || 0,
          ];

          chartDataTemplate.data.datasets[0].minBarLength = [];
          chartDataTemplate.data.datasets[0].backgroundColor = [];
          

          for(let item =0; item < chartDataTemplate.data.datasets[0].data.length; item++){
            if(chartDataTemplate.data.datasets[0].data[item] === 0){
              let data = 0;
              if(this.state.CRadioChecked){
                data = this.props.confidenceProjectsData?.currentProjects[chartDataTemplate.data.labels[item].toLowerCase()];
              } else {
                data = this.props.confidenceProjectsData?.forecastedProjects[this.labels[item].toLowerCase()];
              }
              if(data && data.length){
                chartDataTemplate.data.datasets[0].minBarLength.push(5);
                chartDataTemplate.data.datasets[0].backgroundColor.push("#E45266");
              } else {
                chartDataTemplate.data.datasets[0].minBarLength.push(0);
                //chartDataTemplate.data.datasets[0].data[item] = null;
              }
              
            } else {
              chartDataTemplate.data.datasets[0].minBarLength.push(20);
              chartDataTemplate.data.datasets[0].backgroundColor.push("#268cd1");

            }
     
          
          
          }

          cScore = cData.avgForecastedCS;
        }

        finalChartData = (
          <HorizontalBar
            data={chartDataTemplate.data}
            width={500}
            height={250}
            options={options}
            redraw={this.state.redraw}
          />
        );
      }
    }

    return (
      <Col>



{this.state.showProjectListModal ? (
            <div>
              <Modal
                isOpen={true}
                centered={true}
                size="lg"
                className="tcqs-current-modal"
              >
                <ModalBody
                  id="show-key-activities-modal"
                  className="p-0 tcqs-current-modal-body"
                >
                  <div className="p-2">
                    <CancelIcon
                      onClick={() => this.closeModal()}
                      className="cancel-close-icon"
                    />
                                        <Row>
                      <Col>
                        <center>
                          <span
                            id="key-tasks-to-text"
                            style={{ color: "#252525" }}
                          >
                            
                              <span>
                               {this.state.modalTitle}
                              </span>
        
                          </span>
                        </center>
                      </Col>
                    </Row>
                  </div>
                  <ConfidenceScoreChartDetails activeItem={this.state.selectedItem} modalTitle={this.state.modalTitle} modalTableData={this.state.modalTableData}  current={this.state.CRadioChecked} />

                </ModalBody>
              </Modal>
            </div>
          ) : (
            ""
          )}





        <div
          className="chart-top"
          style={{
            padding: "0rem",
            fontSize: "0.8rem",
            textAlign: "center",
          }}
        >

          <Row>
          <Col xs={4} className="f-14 mt-2 pt-2">
              <center className="mt-2">
                    <span className="f-14">Overall confidence score</span>
                    <BootstrapTooltip
                      placement="right-start"
                      title={<>
                      <div>
                      The doughnut chart represents the overall confidence score in percentage of the entire programme or a selected set of projects as a sub-programme.
                      </div>
                      <div className="mt-3">
                      The overall confidence score is calculated as the average of confidence score of identify, define, deliver and close phase.
                      </div>
                      </>}
                    >
                      <span>
                        <MdInfo
                          size={20}
                          className="c-info"
                          style={{ marginTop: "-0.5rem" }}
                        ></MdInfo>
                      </span>
                    </BootstrapTooltip>
                  </center>
            </Col>
            <Col xs={8} className="d-flex justify-content-between f-14 mt-2 pt-2">
            <Col xs={8}>
            <div className="d-flex justify-content-center mt-2">

            <span>Confidence score by phase</span>
                  <BootstrapTooltip
                    placement="right-start"
                    title={<>
                      <div>
                      The bar chart represents the confidence score of identify, define, deliver and close phase in percentage. The confidence score of a phase is calculated based on the count of BRAG statuses of the milestones in the respective phase.
                      </div>
                      <div>
                      Click on a bar to see the list of projects with their confidence score, last achieved milestone and BRAG status of the milestones within the phase.
                      </div>
                      </>}
                  >
                    <span>
                      <MdInfo
                        size={20}
                        className="c-info"
                        style={{ marginTop: "-0.5rem" }}
                      ></MdInfo>
                    </span>
                  </BootstrapTooltip>
                  </div>

            </Col>
            <Col xs={4}>
            <div className=" d-flex justify-content-end">
            <span className="current-forecasted-radio-buttons m-0 p-0">
              <label className="radio-label">
                Current
                <input
                  type="radio"
                  name="CdataToShow"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="C"
                  checked={this.state.CRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>
              <label className="radio-label">
                Forecasted
                <input
                  type="radio"
                  name="FdataToShow"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="F"
                  checked={this.state.FRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>
            </span>
          </div>

            </Col>
            </Col>
          </Row>
         
          <Row>
            <Col xs={4}>
              <center className="mt-3 pt-1">
                <div>
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      marginTop: "2.5rem",
                    }}
                  >
                    <CircularProgressbar value={cScore || 0} text={`${cScore || 0}%`} />
                  </div>
                  <br />
                </div>
              </center>
            </Col>
            <Col>
              <center>
                <div className="f-14">
                  
                  <div style={{marginTop:'1rem'}} className="d-flex align-items-center justify-content-center">
                    <div className="custom-y-label">Phase</div>
                    <div>
                    {finalChartData}
                    <div className="d-flex justify-content-center custom-x-label">
                    % Confidence score
                    </div>
                    </div>
                  
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default React.memo(ConfidenceScoreChart);
