import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import { MdInfo } from "react-icons/md";
import axios from "axios";

import PageSpinner from "../PageSpinner";
import "../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../RequestHeader";
import { ErrorHandler } from "../ErrorHandler";

class HistoricalRecommendationChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const stackedBarChartData = this.props.chartData;
    let finalChartData = null;
    if (stackedBarChartData != null) {
      const chartDataTemplate = {
        data: {
          labels: null,
          datasets: [
            {
              label: "Green",
              backgroundColor: "#96e079", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "start",
                color: "black",
              },
            },
            {
              label: "Amber",
              backgroundColor: "#FFBF00", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
            {
              label: "Red",
              backgroundColor: "#E45266", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
          ],
        },
      };

      let options = {
        responsive: false,
        title: {
          display: true,
          text: "",
          position: "left",
          fontFamily: "Ubuntu-Medium",
        },
        legend: {
          display: false,
          position: "left",
          align: "left",
          fontFamily: "Ubuntu-Medium",
        },
        type: "bar",
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                display: true,
                fontColor: "#252525",
                minRotation: 0,
                maxRotation: 0,
                fontFamily: "Ubuntu-Medium",
                callback: function (label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  } else {
                    return label;
                  }
                },
              },
              stacked: true,
              display: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                beginAtZero: true,
                display: false,
                stepSize: 10,
                fontColor: "#919BA3",
                fontFamily: "Ubuntu-Medium",
              },
              stacked: true,
              display: true,
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
            color: "#919BA3",
            //align: 'top, middle',
            align: "top",
            anchor: "end",
            fontFamily: "Ubuntu-Medium",
            formatter: (label, index, labels) => {
              if (label == 0) {
                return "";
              }
            },
          },
        },
        tooltips: {
          displayColors: false,
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
        onClick: this.onBarClick,
      };
      let chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
      let greenChartValues = [];
      let redChartValues = [];
      let amberChartValues = [];
      let milestonesLabels = [];
      stackedBarChartData.greenDataSet.forEach((item) => {
        greenChartValues.push(item ? item : 0);
      });
      stackedBarChartData.redDataSet.forEach((item) => {
        redChartValues.push(item ? item : 0);
      });
      stackedBarChartData.amberDataSet.forEach((item) => {
        amberChartValues.push(item ? item : 0);
      });
      stackedBarChartData.milestones.forEach((item) => {
        milestonesLabels.push(
          this.props.csvColumnMapping[item]
            ? this.props.csvColumnMapping[item]
            : item.replace(/_/g, " ")
        );
      });

      chartData.data.datasets[0].data = greenChartValues;
      chartData.data.datasets[1].data = amberChartValues;
      chartData.data.datasets[2].data = redChartValues;
      chartData.data.labels = milestonesLabels;
      finalChartData = (
        <div>
          <Bar
            data={chartData.data}
            width={1000}
            height={400}
            options={options}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="chart-top">
          <div className="chart-header">
            Count of projects forecasted as 'Green', 'Amber' or 'Red' by
            milestone{" "}
            <span
              data-title="The chart represents how many projects are forecasted as Green, Red, and Amber for each of the milestones"
              data-title-md
            >
              <MdInfo size={20} className="c-info"></MdInfo>
            </span>
            <div className="rg-div">
              <div className="g-div"></div>
              Green
              <div className="a-div"></div>
              Amber
              <div className="r-div"></div>
              Red
            </div>
          </div>
          {finalChartData != null && (
            <React.Fragment>{finalChartData}</React.Fragment>
          )}
          {finalChartData == null && <PageSpinner></PageSpinner>}
        </div>
      </React.Fragment>
    );
  }
}

export default HistoricalRecommendationChart;
