import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

import loginImage from "../images/login-image.svg";
import logo from "../images/logo-image.png";
import "../styles/pages/SignInPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandlerSignIn } from "../components/ErrorHandler";
import { HEADER_SIGNIN_PAGES } from "../components/RequestHeader";

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInRequestURL: `${[process.env.REACT_APP_BACKEND_API]}/login`,
      username: null,
      password: null,
      message: "",
      showMessage: false,
      formErrors: {
        username: "",
        password: "",
      },
      redirectToReferrer: false,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
    };
    this.usernameInput = React.createRef();
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.username === null) {
      this.setState((prevState) => {
        let formErrors = Object.assign({}, prevState.formErrors);
        formErrors.username = "Username cannot be empty";
        return { formErrors };
      });
    }
    if (this.state.password === null) {
      this.setState((prevState) => {
        let formErrors = Object.assign({}, prevState.formErrors);
        formErrors.password = "Password cannot be empty";
        return { formErrors };
      });
    }
    const postData = {
      data: {
        username: this.state.username.toLowerCase(),
        password: this.state.password,
      },
    };
    if (
      this.state.formErrors.username === "" &&
      this.state.formErrors.password === ""
    ) {
      return axios({
        url: this.state.signInRequestURL,
        method: "POST",
        data: postData,
        headers: HEADER_SIGNIN_PAGES(),
      })
        .then((response) => {
          if (response.data.success === true) {
            sessionStorage.setItem("token", response.data.user.token);
            sessionStorage.setItem("username", this.state.username);
            sessionStorage.setItem("isAdmin", response.data.user.isAdmin);
            this.setState({ redirectToReferrer: true });
          } else if (response.data.success === false) {
            this.setState({
              message: response.data.message,
              showMessage: true,              
            });
          }
        })
        .catch((error) => {
          let alert = ErrorHandlerSignIn(error);
          this.setState({
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            message: alert.message,
            showMessage: alert.showMessage,
            username:"",
            password:"",
          });
          this.usernameInput.current.focus();
        });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };
    switch (name) {
      case "username":
        formErrors.username =
          value.length < 1 ? "Username cannot be empty" : "";
        break;
      case "password":
        formErrors.password =
          value.length < 1 ? "Password cannot be empty" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    if (this.state.redirectToReferrer) {
      return (
        <Redirect
          to={{
            pathname: "/ui/landing/summary",
            state: {},
          }}
        />
      );
    }
    return (
      <div id="main-div-signin">
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        <Row style={{ margin: "0" }}>
          <Col md={6} className="login-form" style={{ padding: "0" }}>
            <Container
              component="main"
              maxWidth="xs"
              style={{ alignItems: "flex-start" }}
            >
              <CssBaseline />
              <br />
              <div>
                <img src={logo} alt="" id="logo-signin" align="left" />
                &nbsp;
              </div>
              <br />
              <br />
              <div id="paper-style">
                <form id="form-style">
                  <div id="assurance-tool-text">
                    <b>Digital Assurance Tool</b>
                  </div>
                  <div id="please-authenticate-text">
                    <br />
                    Please sign-in to continue
                  </div>
                  <br />

                  {this.state.showMessage && (
                    <span className="errorMessage" id="main-error-message" style={{width: "100%"}}>
                      {this.state.message}
                      <div style={{ alignItems: "flex-end" }}>
                        <Link
                          to={`/ui/forgotpassword`}
                          style={{ textAlign: "right" }}
                        >
                          <span id="forgot-password-link-new">
                            FORGOT PASSWORD?
                          </span>
                        </Link>
                      </div>
                    </span>
                  )}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    
                    style={{ fontFamily: "Ubuntu" }}
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.username}
                    inputRef={this.usernameInput}
                  />
                  {this.state.formErrors.username.length > 0 && (
                    <span className="errorMessage" id="error-message">
                      {this.state.formErrors.username}
                      <br />
                    </span>
                  )}
                  {/* 
                  <div style={{ alignItems: "flex-end" }}>
                    <Link
                      to={`/ui/forgotpassword`}
                      style={{ textAlign: "right" }}
                    >
                      <span id="forgot-password-link">FORGOT PASSWORD?</span>
                    </Link>
                  </div>
                  */}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    style={{ fontFamily: "Ubuntu" }}
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                  {this.state.formErrors.password.length > 0 && (
                    <span className="errorMessage" id="error-message">
                      {this.state.formErrors.password}
                    </span>
                  )}
                  {!this.state.showMessage &&
                    <div style={{ alignItems: "flex-end" }}>
                      <Link
                        to={`/ui/forgotpassword`}
                        style={{ textAlign: "right" }}
                      >
                        <span id="forgot-password-link-new">
                          FORGOT PASSWORD?
                        </span>
                      </Link>
                    </div>
                  }
                  <Button
                    type="submit"
                    block
                    color="primary"
                    id="submit-style"
                    onClick={this.handleSubmit}
                    className="rounded-lg"
                    disabled={
                      this.state.formErrors.username === "" &&
                      this.state.formErrors.password === "" &&
                      this.state.username !== null &&
                      this.state.password !== null
                        ? false
                        : true
                    }
                  >
                    SIGN IN
                  </Button>
                  <Grid container>
                    &nbsp; &nbsp;
                    <br />
                  </Grid>
                </form>
              </div>
            </Container>
            <Divider />
            <br />
            <br />
            <Container component="main" maxWidth="xs">
              <Row>
                <Col xm={6}>
                  <span>
                    <div id="dont-have-account-text">
                      Don't have an account?
                    </div>
                  </span>
                </Col>
                <Col>
                  <Link to={`/ui/signup`}>
                    <span>
                      <Button
                        color="light"
                        className="rounded-lg"
                        id="create-account-button"
                        size="lg"
                      >
                        CREATE ACCOUNT
                      </Button>
                    </span>
                  </Link>
                </Col>
              </Row>
            </Container>
            <br />
            <br />
          </Col>
          <Col md={6} className="login-img">
            <img
              src={loginImage}
              alt=""
              className="img-fuid"
              id="login-image"
              align="right"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignInPage;
