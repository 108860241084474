export const HEADER_TYPE_JSON = (token) => ({
  "Content-Type": "application/json",
  authorization: token,
});

export const HEADER_TYPE_TEXT = (token) => ({
  "Content-Type": "text/plain",
  authorization: token,
});

export const HEADER_SIGNIN_PAGES = () => ({
  "Content-Type": "application/json",
});
