import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import axios from "axios";
import { HEADER_TYPE_JSON } from "../../components/RequestHeader";

import Sidebar from "./Sidebar";
import "../../styles/components/layout/MainLayoutRoute.scss";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
    backgroundColor: "#fff",
    color: "black",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const MainLayout = ({ children, ...rest }) => {
  const page = children.props.page;
  const setPage = children.props.setPage;
  const classes = useStyles();
  //const theme = useTheme();
  const [logoutDone, setLogoutDone] = React.useState(false);
  const open = children.props.open;
  const setOpen = children.props.setOpen;
  const pendingReqs = rest.pendingReqs

  if (logoutDone === true) {
    return <Redirect to="/ui/signin" />;
  }

  return (
    <div className={classes.root} id="classes-root-style">
      <CssBaseline />
      <Sidebar
        page={page}
        setPage={setPage}
        open={open}
        setOpen={setOpen}
        pendingReqs={pendingReqs}
        setLogoutDone={setLogoutDone}
        id="classes-sidebar-style"
      />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        id="classes-content-style"
      >
        <div className={classes.drawerHeader} />
        {children}
      </div>
    </div>
  );
};

const MainLayoutRoute = ({ component: Component, ...rest }) => {
  const [x, setX] = React.useState(-1);
  const [y, setY] = React.useState(-1);
  const [z, setZ] = React.useState(-1);
  //const [pendingData, setPendingData] = React.useState(x+y+z);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState(0);
  const pathname = rest.location.pathname;
  const [page, setPage] = React.useState(pathname.split("/")[3]);
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  useEffect(() => {
    console.log('Location changed',location);
    if(location && location.pathname){
      setPage(location.pathname.split("/")[3]);
    }
    return () => { }
  }, [location]);

  const callPendingReqs = () => {
    // call pending requests
    let reqPassReset = 0;
    let reqNewUsers = 0;
    let reqLockedUsers = 0;
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/password-reset`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          //setY(response.data.userList.length)
          reqPassReset = response.data.userList.length
          axios({
            url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=2`,
            method: "GET",
            headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
          })
            .then((response) => {
              if (response.data.success === true) {
                //setX(response.data.userList.length)
                reqNewUsers = response.data.userList.length
                axios({
                  url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=4`,
                  method: "GET",
                  headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
                })
                  .then((response) => {
                    if (response.data.success === true) {
                      //setZ(response.data.userList.length)
                      reqLockedUsers = response.data.userList.length
                      //setPendingData(reqPassReset+reqNewUsers+reqLockedUsers)
                      setX(reqNewUsers)
                      setY(reqPassReset)
                      setZ(reqLockedUsers)
                      //setTimeout(() => callPendingReqs(), 10000);
                    }
                  })
              }
            })
        }
      })
  };

  useEffect(() => callPendingReqs(),[]);
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (sessionStorage.getItem("token") != null) {
          return (
            <MainLayout pendingReqs={x+y+z}>
              <Component
                {...matchProps}
                setX={setX}
                setY={setY}
                setZ={setZ}
                x={x}
                y={y}
                z={z}
                page={page}
                setPage={setPage}
                month={month}
                year={year}
                setMonth={setMonth}
                setYear={setYear}
                open={open}
                setOpen={setOpen}
              />
            </MainLayout>
          );
        } else {
          return <Redirect to="/ui/signin" />;
        }
      }}
    />
  );
};

export default MainLayoutRoute;
