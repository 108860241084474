import React, { Component } from "react";
import { Bar, getDatasetAtEvent } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import { MdInfo } from "react-icons/md";
import { Row, Col, Modal, ModalBody, Table, Button } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import { ErrorHandler } from "../../../ErrorHandler";
//import TablePagination from "../../../TablePagination";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
import SessionExpired from "../../../SessionExpired";
import ProjectCompletionChartDetailsView, { BootstrapTooltip } from "./ProjectCompletionChartDetailsView";

class CompletionPercentageChart extends Component {
  chartRef;
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      showProjectListModal: false,
      currentPage: 0,
      pageSize: 5,
      viewCriticalDetails: false,
    };
  }

  phasePercent = ["0 - 33%", ">33 - 67%", ">67 - 99%", "100%"];
  chartKeysSubDataCategory = [
    "Perct_0-33",
    "Perct_34-67",
    "Perct_68-99",
    "Perct_100",
  ];
  chartKeysMap = {
    Identify: "identifyData",
    Define: "defineData",
    Deliver: "deliverData",
    Close: "closureData",
  };

  closeModal() {
    this.setState({ viewCriticalDetails: false });
  }

  render() {
    const colors = ["#e45266", "#eb7b15", "#96e079", "#268cd1"];
    const stackedBarChartData = this.props.chartData;
    let finalChartData = null;
    if (stackedBarChartData != null) {
      const chartDataTemplate = {
        data: {
          labels: null,
          datasets: [
            {
              label: "0% - 33%",
              backgroundColor: colors[0], // "#C04000", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 25,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
            {
              label: ">33 - 67%",
              backgroundColor: colors[1], //"#E97451", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 25,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
            {
              label: ">67 - 99%",
              backgroundColor: colors[2], //"#988558", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 25,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
            {
              label: "100%",
              backgroundColor: colors[3], // "#F5DEB3", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 25,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
          ],
        },
      };

      let options = {
        responsive: false,
        title: {
          display: true,
          text: "",
          position: "left",
          fontFamily: "Ubuntu-Medium",
        },
        legend: {
          display: false,
          position: "left",
          align: "left",
          fontFamily: "Ubuntu-Medium",
        },
        type: "bar",
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                display: true,
                fontColor: "#252525",
                minRotation: 0,
                maxRotation: 0,
                fontFamily: "Ubuntu-Medium",
                callback: function (label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  } else {
                    return label;
                  }
                },
              },
              display: true,
              beginAtZero: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                beginAtZero: true,
                display: true,
                stepSize: 10,
                fontColor: "#919BA3",
                fontFamily: "Ubuntu-Medium",
              },
              display: true,
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
            color: "#919BA3",
            //align: 'top, middle',
            align: "top",
            anchor: "end",
            fontFamily: "Ubuntu-Medium",
            // formatter: (label, index, labels) => {
            //   if (label == 0) {
            //     return "";
            //   }
            // },
          },
        },
        tooltips: {
          displayColors: false,
        },
        onClick: (event, i) => {
          // console.log(event,chartElement);
          // console.log(getDatasetAtEvent(this.chartRef.current,event));
          if (i && i[0]) {
            // let month = monthNames[i[0]._view.label];
            let label = i[0]._view.label;
            // let year = monthToYearMapping[i[0]._view.label];
            // get's what category is clicked
            let selectedDatasetIndex =
              i[0]._chart.getElementAtEvent(event)[0]._datasetIndex;

            this.setState({
              viewCriticalDetails: true,
              activeInfo: {
                label,
                percent: this.phasePercent[selectedDatasetIndex],
                activeData:
                  stackedBarChartData[this.chartKeysMap[label]][
                    this.chartKeysSubDataCategory[selectedDatasetIndex]
                  ],
              },
            });

            // this.setState(
            //   {
            //     activitySelected: activity,
            //     monthSelected: i[0]._view.label,
            //     yearSelected: year,
            //     activityValSelected: activityVal,
            //   },
            // this.fetchActivityProjectsBreakdownData(
            //   activity,
            //   month,
            //   year,
            //   activityVal
            // )
            // );
          }
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      };

      let chartData = JSON.parse(JSON.stringify(chartDataTemplate));

      chartData.data.datasets[0].data = [
        (stackedBarChartData?.identifyData["Perct_0-33"]?.projects?.length || 0),
        (stackedBarChartData?.defineData["Perct_0-33"]?.projects?.length || 0),
        (stackedBarChartData?.deliverData["Perct_0-33"]?.projects?.length || 0),
        (stackedBarChartData?.closureData["Perct_0-33"]?.projects?.length || 0),
      ];
      chartData.data.datasets[1].data = [
        stackedBarChartData?.identifyData["Perct_34-67"]?.projects?.length,
        stackedBarChartData?.defineData["Perct_34-67"]?.projects?.length,
        stackedBarChartData?.deliverData["Perct_34-67"]?.projects?.length,
        stackedBarChartData?.closureData["Perct_34-67"]?.projects?.length,
      ];

      chartData.data.datasets[2].data = [
        stackedBarChartData?.identifyData["Perct_68-99"]?.projects?.length,
        stackedBarChartData?.defineData["Perct_68-99"]?.projects?.length,
        stackedBarChartData?.deliverData["Perct_68-99"]?.projects?.length,
        stackedBarChartData?.closureData["Perct_68-99"]?.projects?.length,
      ];

      chartData.data.datasets[3].data = [
        stackedBarChartData?.identifyData["Perct_100"]?.projects?.length,
        stackedBarChartData?.defineData["Perct_100"]?.projects?.length,
        stackedBarChartData?.deliverData["Perct_100"]?.projects?.length,
        stackedBarChartData?.closureData["Perct_100"]?.projects?.length,
      ];
      chartData.data.labels = Object.keys(this.chartKeysMap);;

      finalChartData = (
        <div>
          <Bar
            ref={this.chartRef}
            data={chartData.data}
            width={700}
            height={400}
            options={options}
          />
        </div>
      );
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <React.Fragment>
        <div
          className="chart-top"
          style={{
            padding: "0.55rem",
            fontSize: "0.9rem",
            width: "100%",
            marginLeft: "0.95rem",
            marginRight: "0.95rem",
          }}
        >
          <div className="chart-header">
          Count of projects based on activities completed within each phase
            <BootstrapTooltip 
              title={<>
                <div>
                The bar charts represent the count of projects by their percent complete of the phase. The percent complete of a phase is represented in four categories.
                </div>
                <div className="mt-3">
                Click on any bar to see the list of projects and list of activities within the selected phase and percent completion range.
                </div>
                </>}
                >
            <span>
              <MdInfo size={20} className="c-info mb-1"></MdInfo>
            </span>
            </BootstrapTooltip>
            <div className="rg-div">
              <div className="g-div" style={{ background: colors[0] }}></div>
              {"0 - 33%"}
              &nbsp;&nbsp;
              <div className="g-div" style={{ background: colors[1] }}></div>
              {">33 - 67%"}
              &nbsp;&nbsp;
              <div className="g-div" style={{ background: colors[2] }}></div>
              {">67 - 99%"}
              &nbsp;&nbsp;
              <div className="g-div" style={{ background: colors[3] }}></div>
              {"100%"}
            </div>
          </div>
          <div  className="d-flex align-items-center">
                <div className="custom-y-label">Count of projects</div>
                <div>
          {finalChartData != null && (
            <React.Fragment>{finalChartData}</React.Fragment>
          )}
           <div className="d-flex justify-content-center custom-x-label">
                   Phase
                    </div>
          </div>
          </div>
        </div>
        {this.state.viewCriticalDetails && (
          <ProjectCompletionChartDetailsView
            chartData={this.props.chartData}
            activeInfo={this.state.activeInfo}
            closeModal={() => this.closeModal()}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CompletionPercentageChart;
