import React, { Component } from "react";
import { Row, Col, Table } from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import chroma from "chroma-js";
import Select from "react-select";

import { HEADER_TYPE_TEXT } from "../../RequestHeader";
import { ErrorHandler } from "../../ErrorHandler";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}



const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class SingleProjectTcqiActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActivityData: false,
      //allMeasures: { "all": "Overall", "c_time": "Time", "c_cost": "Cost", "c_quality": "Quality", "c_scope": "Scope", "c_h_s": "H&S", "c_sme_self_assessments": "SME" },
      allMeasures: {
        c_time: "Time",
        c_cost: "Cost",
        c_quality: "Quality",
        c_scope: "Scope",
        c_h_s: "H&S",
        c_sme_self_assessments: "SME SA",
      },
      isLoading: false,
      selectedValue: [],
      allActivities: this.props.allActivities,
      filteredActivities: this.props.filteredActivities,
    };
  }

  componentDidMount() {
    const defaultOptions = [
      { value: "A", label: "A", color: "#eb7b15" },
      { value: "R", label: "R", color: "#FF5630" },
      { value: "NA", label: "NA", color: "black" },
    ];
    this.onSelect(defaultOptions);
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedValue: selectedList });
    let filteredActivities = [];
    let dataArray = [];
    if (!selectedList || selectedList.length == 0) {
      this.setState({
        filteredActivities: this.state.allActivities,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < this.state.allActivities.length; j++) {
          let data = this.state.allActivities[j];
          dataArray.push(data);
          if (data["activityValue"]) {
            if (
              data["activityValue"].toLowerCase() ==
              selectedList[i].value.toLowerCase()
            ) {
              filteredActivities.push(data);
            } else if (selectedList[i].value == "NA" && data["activityValue"] == "N/A") {
              filteredActivities.push(data);
            }
          } else {
            if (selectedList[i].value == "NA") {
              filteredActivities.push(data);
            }
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.allActivities.length
        ) {
          this.setState({ filteredActivities: filteredActivities });
        }
      }
    }
  };

  onRemove(selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList });
    let filteredActivities = [];
    let dataArray = [];
    if (!selectedList || selectedList.length == 0) {
      this.setState({
        filteredActivities: this.state.allActivities,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < this.state.allActivities.length; j++) {
          let data = this.state.allActivities[j];
          dataArray.push(data);
          if (
            data["activityValue"].toLowerCase() ==
            selectedList[i].name.toLowerCase()
          ) {
            filteredActivities.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.allActivities.length
        ) {
          this.setState({ filteredActivities: filteredActivities });
        }
      }
    }
  }table

  render() {
    let showActivityData = false;
    let allActivities = this.state.allActivities;
    let filteredActivities = this.state.filteredActivities;

    if (allActivities != null) {
      showActivityData = true;
    }

    let infoIconText = {
      "Time":"The table represents a list of all the activities that feed into Time as a business measure. The default view displays the activities that require action. The activities between the last milestone achieved and the next milestone are considered to have a higher impact on time and therefore, carry higher weightage. The out-of-date activities are considered to have a relatively lower impact on time so they carry lower weightage.​ Any activities post the next milestone the project is working up to are not considered until they become in-date.",
      "Cost":'The table represents a list of all the activities that feed into Cost as a business measure. The default view displays the activities that require action. The activity "Project LBE v\'s optimised programme value (AMP7)" is considered to have the highest impact on cost. Any activities post the next milestone the project is working up to are not considered until they become in-date.',
      "Quality":"The table represents a list of all the activities that feed into Quality as a business measure. The default view displays the activities that require action. All the activities are considered to have an equal impact on quality. Any activities post the next milestone the project is working up to are not considered until they become in-date.",
      "Scope":"The table represents a list of all the activities that feed into Scope as a business measure. The default view displays the activities that require action. All the activities are considered to have an equal impact on scope. Any activities post the next milestone the project is working up to are not considered until they become in-date.",
      "H&S":"The table represents a list of all the activities that feed into H&S as a business measure. The default view displays the activities that require action. All the activities are considered to have an equal impact on H&S. Any activities post the next milestone the project is working up to are not considered until they become in-date.",
      "SME SA":"The table represents a list of all the activities that feed into SME as a business measure. The default view displays the activities that require action. All the activities are considered to have an equal impact on SME. Any activities post the next milestone the project is working up to are not considered until they become in-date.",
    }
    

    return (
      <Col xs="12" lg="12" md="12">
        {showActivityData == false && <div></div>}
        {showActivityData == true && (
          <div
            className="chart-top"
            style={{
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            <Row>
              <Col>
                <div className="chart-header" style={{ paddingBottom: "50px" }}>
                  <div
                    style={{ float: "left", paddingTop: "12px" }}
                    className="chart-buttons2"
                  >
                    <span>
                    List of activities feeding into {this.props.measureVal} up to the milestone {this.props.milestoneToAchieve} that the project is working up to
                    </span>
                  </div>
                  <div
                    style={{ float: "right", paddingTop: "12px" }}
                    className="chart-buttons2"
                  >
                    <BootstrapTooltip style={{ display: 'block' }}
                      placement="bottom-end"
                      title={infoIconText[this.props.measureVal]}
                    >
                      <span>
                        <MdInfo
                          size={25}
                          className="c-info"
                          style={{ marginTop: "-0.5rem" }}
                        ></MdInfo>
                      </span>
                    </BootstrapTooltip>                  
                  </div>
                </div>
                <center>
                  <div
                    style={{ overflowX: "auto", margin: "10px" }}
                    className="single-project-activity-weight"
                  >
                    <Table
                      className="table-scroll-modal table-modal table table-md table-custom table-single-project-activities"
                      id="table-task-list"
                      style={{
                        fontSize: "0.7rem",
                        height: "318px",
                        border: "1px solid",
                      }}
                    >
                      <thead className="table-head">
                        <tr>
                          <th className="other-col-comp">
                            <span>Activity</span>
                          </th>
                          <th
                            className="other-col-comp centre-align-data"
                            style={{ width: "12vh" }}
                          >
                            <BootstrapTooltip
                              placement="left-center"
                              title={"Current Status"}
                            >
                              <Select
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                isMulti
                                options={colourOptions}
                                styles={colourStyles}
                                value={this.state.selectedValue}
                                onChange={this.onSelect}
                                placeholder={"Current Status"}
                                isSearchable
                              />
                            </BootstrapTooltip>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredActivities && filteredActivities.length
                          ? filteredActivities.map((activityObject) => (
                              <tr>
                                <td className="table-data-bold">
                                  {activityObject["activityName"]}
                                </td>
                                <td className="table-data-black centre-align-data">
                                  {activityObject["activityValue"] ? (
                                    activityObject["activityValue"] != "NA" && activityObject["activityValue"] != "N/A" ? (
                                      <div
                                        className={
                                          activityObject["activityValue"] ===
                                          "R"
                                            ? "red-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "GR"
                                            ? "grey-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "A"
                                            ? "amber-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "B"
                                            ? "blue-circle"
                                            : activityObject[
                                              "activityValue"
                                            ] === "G"
                                            ?'green-circle':'-'
                                        }
                                      ></div>
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                            ))
                          : <center><strong>No activities feeding into {this.state.allMeasures[this.state.selectedMeasure]} up to the next milestone to be achieved.</strong></center>}
                      </tbody>
                    </Table>
                  </div>
                </center>
              </Col>
            </Row>
          </div>
        )}
      </Col>
    );
  }
}

export default SingleProjectTcqiActivities;
