import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { FaTimes } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Spinner } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import chroma from "chroma-js";
import Select from "react-select";

import * as GenericConstants from "../src-constants/GenericConstants";
import ProjectMap from "../components/dashboard/predictions/tubemap/ProjectMap";
import CurrentMonthMap from "../components/dashboard/predictions/tubemap/CurrentMonthMap";
import CurrentMonthChart from "../components/dashboard/predictions/charts/CurrentMonthPieChart";
import StackedBarChart from "../components/dashboard/predictions/charts/StackedBarChart";
import BarChartWithFilter from "../components/dashboard/predictions/charts/BarChartWithFilter";
import Filter from "../components/dashboard/Filter";
import "../styles/pages/PredictionPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandler } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import * as PredictionDataConverter from "../services/PredictionDataConverter";
import * as FilterProcessingService from "../services/FilterProcessingService";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.5px solid #E4E4E4",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.4rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class PredictionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: null,
      getAllProjectsDataURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/forecasted-projects?filters=`,
      getProjectCountURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/pred-project-counts?filters=`,
      getPredictionMilestoneActivityRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
        //]}/dashboard/prediction-by-milestones-n-activities?filters=`,
      ]}/dashboard/forecast-performance-by-milestones?filters=`,
      getTopFocusAreasRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/top-focus-areas?filters=`,
      getTcqsRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-params-data?filters=`,
      getPredictionMilestonesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-by-milestones?filters=`,
      getFiltersDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/pred-filters-data`,
      getCsvColumnsMappingRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/column-mapping`,
      fetchProjectNamesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/pred-project-names?filters=`,
      getLatestCSVDateURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/latest-csv-date`,
      zoomSliderVal: 2,
      selectedFiltersData: {},
      isFilterOpen: false,
      filtersAPIData: null,
      currentYear: 0,
      currentMonth: 0,
      isLoading: false,
      latestData: null,
      tcqsChartData: null,
      csvColumnMapping: null,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      excelDownloading: false,
      pdfDownloading: false,
      downloadEnabled: false,
      showMatchingProjects: false,
      totalProjectLoading: true,
      tablet: 5,
      totalProjects: null,
      singleProjectData: null,
      isDownloadOpen: false,
      activityList: null,
      milestoneDbColName: null,
      selectedMilestone: 1,
      milestoneProjectCount: 0,
      activeTab: (props?.location?.state?.projectId)?"2":"1",
      projectsDataDefaultVal: props?.location?.state?.projectId,
      latestCSVDate: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.getPredictionMilestoneActivityData =
      this.getPredictionMilestoneActivityData.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getCsvColumnsMapping = this.getCsvColumnsMapping.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.getTopFocusAreas = this.getTopFocusAreas.bind(this);
    this.getTCQSData = this.getTCQSData.bind(this);
    this.getPredictionMilestoneData =
      this.getPredictionMilestoneData.bind(this);
    this.downloadChartPdf = this.downloadChartPdf.bind(this);
    this.downloadAllProgramsDataExcel =
      this.downloadAllProgramsDataExcel.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.updateDownloadButtonState = this.updateDownloadButtonState.bind(this);
    this.updateProjectMapDataLoadStatus =
      this.updateProjectMapDataLoadStatus.bind(this);
    this.updateSingleProjectMapData =
      this.updateSingleProjectMapData.bind(this);
    this.downloadProjectMapDataExcel =
      this.downloadProjectMapDataExcel.bind(this);
    this.toggleDownload = this.toggleDownload.bind(this);
    this.downloadExcelClicked = this.downloadExcelClicked.bind(this);
    this.downloadPdfClicked = this.downloadPdfClicked.bind(this);
    this.toggleCloseConfirmationModal =
      this.toggleCloseConfirmationModal.bind(this);
    this.closeFilterConfirmation = this.closeFilterConfirmation.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.noFilterSelectedCloseFilter =
      this.noFilterSelectedCloseFilter.bind(this);
    this.updateSelectedMilestone = this.updateSelectedMilestone.bind(this);
    this.closeGoToTrainingModal = this.closeGoToTrainingModal.bind(this);
    this.onGoToTraining = this.onGoToTraining.bind(this);
    this.getAllProjectsData = this.getAllProjectsData.bind(this);
    this.openListOfProjectsModal = this.openListOfProjectsModal.bind(this);
    this.closeListOfProjectsModal = this.closeListOfProjectsModal.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    this.fetchProjectNames = this.fetchProjectNames.bind(this);
    this.keepFilters = this.keepFilters.bind(this);
    this.closeKeepFiltersModal = this.closeKeepFiltersModal.bind(this);
    this.closeFilterPaneOnApplying = this.closeFilterPaneOnApplying.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getLatestCSVDate = this.getLatestCSVDate.bind(this);
  }

  openListOfProjectsModal() {
    this.setState({ showListOfProjectsModal: true }, this.bindEvent);
  }

  closeListOfProjectsModal() {
    this.setState({
      showListOfProjectsModal: false,
      selectedValue: null,
      listOfProjectsFilterData: this.state.listOfProjectsData,
      listOfProjectsProjectFilterData: this.state.listOfProjectsData,
    });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  toggleCloseConfirmationModal() {
    this.setState({ openCloseConfirmation: !this.state.openCloseConfirmation });
  }

  toggleDownload = () => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen });
  };

  componentDidMount() {
    if (window.innerWidth < 1000) {
      this.setState({ tablet: 2 });
    } else this.setState({ tablet: 3 });

        // get latest CSV date
        this.getLatestCSVDate();
    // tubemap settings for current month map
    sessionStorage.setItem("initialScale", 1.7);
    sessionStorage.setItem("initialTranslateX", 50);
    sessionStorage.setItem("initialTranslateY", 50);

    // tubemap settings for project map
    sessionStorage.setItem("initialScaleProjectMap", 1.7);
    sessionStorage.setItem("initialTranslateXProjectMap", 50);
    sessionStorage.setItem("initialTranslateYProjectMap", 50);

    this.getFiltersData();
    this.getCsvColumnsMapping();

    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    if (selectedFiltersData) {
      if (JSON.stringify(selectedFiltersData) != "{}") {
        this.setState({ showKeepFiltersModal: true });
      } else {
        this.getAllProjectsData();
        this.getPredictionMilestoneActivityData(1);
        this.getTopFocusAreas();
        this.getTCQSData();
        this.getPredictionMilestoneData();
        this.getProjectCount();
        this.fetchProjectNames();
      }
    } else {
      this.getAllProjectsData();
      this.getPredictionMilestoneActivityData(1);
      this.getTopFocusAreas();
      this.getTCQSData();
      this.getPredictionMilestoneData();
      this.getProjectCount();
      this.fetchProjectNames();
      sessionStorage.setItem("selectedFiltersData", "{}");
    }
  }

  getAllProjectsData() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getAllProjectsDataURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let allProjectsData = response.data.allProjectsData;
        let projectIdName = [];

        if (allProjectsData) {
          for (let i = 0; i < allProjectsData.length; i++) {
            projectIdName.push(
              allProjectsData[i]["Project Id"] +
                "(" +
                allProjectsData[i]["Project Name"] +
                ")"
            );
          }
        }

        this.setState({
          projectIdName: projectIdName,
          listOfProjectsData: allProjectsData,
          listOfProjectsFilterData: allProjectsData,
          listOfProjectsProjectFilterData: allProjectsData,
        });
      })
      .catch((error) => {
        console.log("error in projects data " + error);
      });
  }

  getLatestCSVDate() {
    axios({
      url: this.state.getLatestCSVDateURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        // get current year
        let csvDate = response.data.latestUploadDate
        csvDate = moment(csvDate).format('DD-MMM-YYYY, hh:mm a')
        this.setState(
          {
            latestCSVDate: csvDate.replace(",", ' at'),
          },
        );
      })
      .catch((error) => {
        console.log("error in fetching latest csv date" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
        );
      });
  }

  getProjectCount() {
    this.setState({ isLoading: true, totalProjectLoading: true });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectCountURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        let goToTrainingModal = false;

        if (data.projectCount == 0 && filterQuery == "{}") {
          goToTrainingModal = true;
        }

        this.setState({
          totalProjects: data.projectCount,
          totalProjectLoading: false,
          goToTrainingModal: goToTrainingModal,
        });
      })
      .catch((error) => {
        console.log("error in getting project counts" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            totalProjectLoading: false,
          },
          this.updateDownloadButtonState
        );
      });
  }

  fetchProjectNames() {
    const filters = this.state.selectedFiltersData;
    this.setState({ isFetchProjectNamesLoading: true });
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.fetchProjectNamesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const projectNameIdMap = response.data.projectNameIdMap;
        this.setState({
          projectNameIdMap: projectNameIdMap,
          isFetchProjectNamesLoading: false,
        });
      })
      .catch((error) => {
        console.log("error in fetching project names");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isFetchProjectNamesLoading: false,
        });
      });
  }

  updateSelectedMilestone(selectedMilestone) {
    this.setState({ selectedMilestone: selectedMilestone });
    //this.getPredictionMilestoneActivityData(selectedMilestone)
  }

  getPredictionMilestoneActivityData(selectedMilestone) {
    this.setState({
      isLoading: true,
      predictionMilestoneActivityLoaded: false,
    });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.getPredictionMilestoneActivityRequestURL +
        filterQuery +
        "&milestoneToForecast=" +
        selectedMilestone,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data.chartData;
        const activityList = res.data.activityList;
        const milestoneDbColName = res.data.milestoneDbColName;
        const milestoneProjectCount = res.data.milestoneProjectCount;

        let currentYear, currentMonth, latestData;
        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = currentYear ? Object.keys(data[currentYear])[0] : null;
          latestData =
            currentYear != null && currentMonth != null
              ? data[currentYear][currentMonth]
              : null;
        } else {
          currentYear = null;
          currentMonth = null;
          latestData = null;
        }

        this.setState(
          {
            currentMonth: currentMonth,
            currentYear: currentYear,
            latestData: latestData,
            isLoading: false,
            predictionMilestoneActivityLoaded: true,
            activityList: activityList,
            milestoneDbColName: milestoneDbColName,
            milestoneProjectCount: milestoneProjectCount,
          },
          this.updateDownloadButtonState
        );
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching latest data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
            predictionMilestoneActivityLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getTopFocusAreas() {
    this.setState({ topFocusAreasLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getTopFocusAreasRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        let currentYear, currentMonth, topFocusAreasData;

        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = data[currentYear]
            ? Object.keys(data[currentYear])[0]
            : null;
          topFocusAreasData = data[currentYear][currentMonth];
        } else {
          currentYear = null;
          currentMonth = null;
          topFocusAreasData = null;
        }

        this.setState(
          { topFocusAreasData: topFocusAreasData, topFocusAreasLoaded: true },
          this.updateDownloadButtonState
        );
      })
      .catch((error) => {
        console.log("error in fetching top focus areas data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            topFocusAreasLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getTCQSData() {
    this.setState({ tcqsDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getTcqsRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        let currentYear,
          currentMonth,
          latestYearMonthData,
          tcqsChartData,
          greenDataSet,
          redDataSet;

        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = data[currentYear]
            ? Object.keys(data[currentYear])[0]
            : null;
          latestYearMonthData = data[currentYear][currentMonth];
          if (
            latestYearMonthData != null &&
            latestYearMonthData !== undefined &&
            JSON.stringify(latestYearMonthData) != "{}"
          ) {
            // prepare chart data for time,cost,quality, h&s,scope and sme
            greenDataSet = [
              latestYearMonthData.c_time ? latestYearMonthData.c_time.G : 0,
              latestYearMonthData.c_cost ? latestYearMonthData.c_cost.G : 0,
              latestYearMonthData.c_quality
                ? latestYearMonthData.c_quality.G
                : 0,
              latestYearMonthData.c_scope ? latestYearMonthData.c_scope.G : 0,
              latestYearMonthData.c_h_s ? latestYearMonthData.c_h_s.G : 0,
              latestYearMonthData.c_sme_self_assessments
                ? latestYearMonthData.c_sme_self_assessments.G
                : 0,
            ];

            redDataSet = [
              latestYearMonthData.c_time ? latestYearMonthData.c_time.R : 0,
              latestYearMonthData.c_cost ? latestYearMonthData.c_cost.R : 0,
              latestYearMonthData.c_quality
                ? latestYearMonthData.c_quality.R
                : 0,
              latestYearMonthData.c_scope ? latestYearMonthData.c_scope.R : 0,
              latestYearMonthData.c_h_s ? latestYearMonthData.c_h_s.R : 0,
              latestYearMonthData.c_sme_self_assessments
                ? latestYearMonthData.c_sme_self_assessments.R
                : 0,
            ];
            tcqsChartData = { greenDataSet, redDataSet };
          } else {
            tcqsChartData = null;
            currentYear = null;
            currentMonth = null;
            latestYearMonthData = null;
            greenDataSet = null;
            redDataSet = null;
          }

          this.setState(
            {
              currentMonth: currentMonth,
              currentYear: currentYear,
              tcqsChartData: tcqsChartData,
              isLoading: false,
              tcqsDataLoaded: true,
            },
            this.updateDownloadButtonState
          );
          this.props.setMonth(currentMonth);
          this.props.setYear(currentYear);
        } else {
          this.setState(
            {
              currentMonth: currentMonth,
              currentYear: currentYear,
              isLoading: false,
              tcqsDataLoaded: true,
            },
            this.updateDownloadButtonState
          );
        }
      })
      .catch((error) => {
        console.log("error in fetching data by activity type" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getPredictionMilestoneData = () => {
    this.setState({ predictionMilestoneDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getPredictionMilestonesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        // get current year
        const currentYear =
          data != null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
            ? Object.keys(data)[0]
            : null;
        // get current month
        const currentMonth =
          data != null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
            ? Object.keys(data[currentYear])[0]
            : null;
        const latestYearMonthData =
          data != null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
            ? data[currentYear][currentMonth]
            : null;
        const greenDataSet = [];
        const redDataSet = [];
        const amberDataSet = [];
        const milestones = [];
        let predictionMilestoneData = {};
        if (
          latestYearMonthData != null &&
          latestYearMonthData !== undefined &&
          JSON.stringify(latestYearMonthData) !== JSON.stringify({})
        ) {
          Object.entries(latestYearMonthData).forEach((activity) => {
            greenDataSet.push(activity[1].G);
            redDataSet.push(activity[1].R);
            amberDataSet.push(activity[1].A);
            milestones.push(activity[0]);
          });
          predictionMilestoneData = {
            milestones,
            greenDataSet,
            redDataSet,
            amberDataSet,
          };
          this.props.setMonth(currentMonth);
          this.props.setYear(currentYear);
        }

        this.setState(
          {
            currentMonth: currentMonth,
            currentYear: currentYear,
            predictionMilestoneData: predictionMilestoneData,
            isLoading: false,
            predictionMilestoneDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      })
      .catch((error) => {
        console.log("error in fetching data by activity type" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            predictionMilestoneDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  };

  updateDownloadButtonState() {
    const {
      latestData,
      tcqsChartData,
      topFocusAreasData,
      predictionMilestoneData,
    } = this.state;
    this.setState({
      downloadEnabled:
        this.state.activeTab == "1"
          ? this.state.predictionMilestoneActivityLoaded &&
            this.state.topFocusAreasLoaded &&
            this.state.tcqsDataLoaded &&
            this.state.predictionMilestoneDataLoaded &&
            !(
              (latestData == null ||
                JSON.stringify(latestData) === JSON.stringify({})) &&
              (topFocusAreasData == null ||
                JSON.stringify(topFocusAreasData) === JSON.stringify({})) &&
              (tcqsChartData == null ||
                JSON.stringify(tcqsChartData) === JSON.stringify({})) &&
              (predictionMilestoneData == null ||
                JSON.stringify(predictionMilestoneData) === JSON.stringify({}))
            )
          : this.state.projectMapDataLoaded &&
            !(
              this.state.singleProjectData == null ||
              JSON.stringify(this.state.singleProjectData) ===
                JSON.stringify({})
            ),
    });
  }

  updateProjectMapDataLoadStatus(status) {
    this.setState(
      { projectMapDataLoaded: status },
      this.updateDownloadButtonState
    );
  }

  updateSingleProjectMapData(singleProjectData) {
    this.setState({ singleProjectData: singleProjectData });
  }

  applyFilters() {
    this.closeFilterPaneOnApplying();
    this.getAllProjectsData();
    this.getPredictionMilestoneActivityData(this.state.selectedMilestone);
    this.getTopFocusAreas();
    this.getTCQSData();
    this.getPredictionMilestoneData();
    this.getProjectCount();
    this.fetchProjectNames();
    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(this.state.selectedFiltersData)
    );
  }

  getFiltersData() {
    axios({
      url: this.state.getFiltersDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const filtersData = response.data.filtersData;
        this.setState({ filtersAPIData: filtersData });
      })
      .catch((error) => {
        console.log("error in fetching filters data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getCsvColumnsMapping() {
    axios({
      url: this.state.getCsvColumnsMappingRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const csvColumnMapping = response.data.csvColumnMapping;
        this.setState({ csvColumnMapping: csvColumnMapping });
      })
      .catch((error) => {
        console.log("error in fetching csv columns mapping data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  updateFilters(name, list) {
    // update values in selectedFiltersData
    let selectedFiltersData = this.state.selectedFiltersData;
    if (selectedFiltersData == null) {
      selectedFiltersData = {};
    }
    if (list == null || list.length == 0) {
      delete selectedFiltersData[name];
    } else {
      selectedFiltersData[name] = list;
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
  }

  removeFilter(filterName, filterVal) {
    // remove filter
    let selectedFiltersData = this.state.selectedFiltersData;
    let filterList = selectedFiltersData[filterName];
    selectedFiltersData[filterName] = filterList.filter((item) => {
      return item != filterVal;
    });
    if (
      selectedFiltersData[filterName] == null ||
      selectedFiltersData[filterName].length == 0
    ) {
      delete selectedFiltersData[filterName];
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
    // update data
    this.getAllProjectsData();
    this.getPredictionMilestoneActivityData(this.state.selectedMilestone);
    this.getTopFocusAreas();
    this.getTCQSData();
    this.getPredictionMilestoneData();
    this.getProjectCount();
    this.fetchProjectNames();
    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(selectedFiltersData)
    );
  }

  toggleFilter() {
    const isFilterOpen = this.state.isFilterOpen;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  closeFilterPaneOnApplying() {
    this.setState({ isFilterOpen: false });
  }

  async clearFilters() {
    await this.setState({ selectedFiltersData: {} });
    this.applyFilters();
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab }, this.updateDownloadButtonState);
    }
  }

  downloadChartPdf(elementId, fileName) {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentMonthForcast").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialForcastSearchByProject").click();
    }
    setTimeout(() => {
      const input = document.getElementById(elementId);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        var a = Object.keys(this.state.selectedFiltersData);
        var filter = [];
        for (let i = 0; i < a.length; i++) {
          for (
            let j = 0;
            j < this.state.selectedFiltersData[a[i]].length;
            j++
          ) {
            filter.push(this.state.selectedFiltersData[a[i]][j] + " ");
          }
        }
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("portrait", "pt", [
          canvas.width + 20,
          canvas.height + 20,
        ]);
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.setFontSize(width / 70);

        pdf.text(50, 150, "Forecasted Performance");

        pdf.text(
          width / 3,
          150,
          this.state.activeTab == 1
            ? "All Programmes"
            : this.state.activeTab == 2
            ? this.state.selectedProject
            : ""
        );
        pdf.setFontSize(width / 70);

        pdf.text(
          50,
          250,
          "Based on data uploaded for " + this.props.month + this.props.year
        );
        if (this.state.activeTab == 1) {
          pdf.setFontSize(width / 70);
          pdf.text(50, 350, "Total Projects: " + this.state.totalProjects);
        }

        if (filter.length > 0 && this.state.activeTab == 1) {
          pdf.text(50, 450, "Filters: " + filter.toString());
        }

        pdf.addImage(imgData, "JPEG", 20, 500, width, canvas.height - 500);
        pdf.save(fileName);
        this.setState({ pdfDownloading: false });
      });
    }, 1000);
  }

  // downloadChartPdf(elementId, fileName) {
  //   const input = document.getElementById(elementId);
  //   html2canvas(input, { scale: 5 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const pdf = new jsPDF("landscape", "mm", [
  //       canvas.width + 20,
  //       canvas.height + 20,
  //     ]);
  //     var width = pdf.internal.pageSize.getWidth();
  //     var height = pdf.internal.pageSize.getHeight();
  //     pdf.addImage(imgData, "JPEG", 10, 10, width, height);
  //     pdf.save(fileName);
  //     this.setState({ pdfDownloading: false });
  //   });
  // }

  downloadAllProgramsDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    let sheets = {};
    let sheetNames = [];

    if (this.state.latestData) {
      const ws1 = XLSX.utils.json_to_sheet(
        PredictionDataConverter.convertPerformanceMilestoneData(
          this.state.latestData,
          this.state.csvColumnMapping
        )
      );
      sheets["PerformanceMilestone"] = ws1;
      sheetNames.push("PerformanceMilestone");
    }

    if (this.state.topFocusAreasData) {
      const ws2 = XLSX.utils.json_to_sheet(
        PredictionDataConverter.convertTopFocusAreasData(
          this.state.topFocusAreasData,
          this.state.csvColumnMapping
        )
      );
      sheets["TopFocusAreas"] = ws2;
      sheetNames.push("TopFocusAreas");
    }

    if (this.state.tcqsChartData) {
      const ws3 = XLSX.utils.json_to_sheet(
        PredictionDataConverter.convertTCQSData(
          this.state.tcqsChartData,
          this.state.csvColumnMapping
        )
      );
      sheets["TCQS"] = ws3;
      sheetNames.push("TCQS");
    }

    if (this.state.predictionMilestoneData) {
      const ws4 = XLSX.utils.json_to_sheet(
        PredictionDataConverter.convertPredictionMilestoneData(
          this.state.predictionMilestoneData,
          this.state.csvColumnMapping
        )
      );
      sheets["ForecastMilestone"] = ws4;
      sheetNames.push("ForecastMilestone");
    }

    const wb = {
      Sheets: sheets,
      SheetNames: sheetNames,
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "Forecast_AllProgramsChartsData.xlsx");
  };

  downloadProjectMapDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws1 = XLSX.utils.json_to_sheet(
      PredictionDataConverter.convertProjectMapData(
        this.state.singleProjectData,
        this.state.csvColumnMapping
      )
    );

    const wb = {
      Sheets: {
        PerformanceMilestone: ws1,
      },
      SheetNames: ["PerformanceMilestone"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "Forecast_ProjectMapData.xlsx");
  };

  downloadData = () => {
    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "Forecast_AllProgramsCharts.pdf"
      );
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "projectTubeMapDiv",
        "Forecast_ProjectMapChart.pdf"
      );
      this.downloadProjectMapDataExcel();
    }
  };

  downloadExcelClicked = () => {
    if (this.state.activeTab == "1") {
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadProjectMapDataExcel();
    }
  };

  downloadPdfClicked = () => {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentMonthForcast").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialForcastSearchByProject").click();
    }

    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "Forecast_AllProgramsCharts.pdf"
      );
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "projectTubeMapDiv",
        "Forecast_ProjectMapChart.pdf"
      );
    }
  };

  closeFilter() {
    this.clearFilters();
    this.setState({
      showMatchingProjects: false,
      totalProjectLoading: true,
      openCloseConfirmation: false,
    });
  }

  noFilterSelectedCloseFilter() {
    this.toggleFilter();
    this.setState({
      openCloseConfirmation: false,
    });
  }

  closeFilterConfirmation() {
    this.setState({ openCloseConfirmation: true });
  }

  callbackFunction = (selectedProject) => {
    this.setState({ selectedProject: selectedProject });
  };

  closeGoToTrainingModal() {
    this.setState({ goToTrainingModal: false });
  }

  onGoToTraining() {
    this.props.setPage("trainmodel");
    this.setState({ goToTrainingPage: true });
  }

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let listOfProjectsFilterData = [];
    let dataArray = [];
    this.setState({ selectedValue: null });
    if (value === null || value === "") {
      this.setState({
        listOfProjectsFilterData: this.state.listOfProjectsData,
        listOfProjectsProjectFilterData: this.state.listOfProjectsData,
      });
    } else {
      this.state.listOfProjectsData.map((data) => {
        dataArray.push(data);
        if (
          data["Project Id"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          listOfProjectsFilterData.push(data);
        }
        if (dataArray.length === this.state.listOfProjectsData.length) {
          this.setState({
            listOfProjectsFilterData: listOfProjectsFilterData,
            listOfProjectsProjectFilterData: listOfProjectsFilterData,
          });
        }
      });
    }
  }

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A2"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let title = "List Of Selected Projects In Forecasted Performance";

    let headers = [];
    let arr = [];
    for (
      let k = 0;
      k < Object.entries(this.state.listOfProjectsData[0]).length;
      k++
    ) {
      arr.push(Object.entries(this.state.listOfProjectsData[0])[k][0]);
    }
    headers.push(arr);

    let data = [];
    for (let j = 0; j < this.state.listOfProjectsData.length; j++) {
      let arr = [];
      for (
        let k = 0;
        k < Object.entries(this.state.listOfProjectsData[j]).length;
        k++
      ) {
        arr.push(Object.entries(this.state.listOfProjectsData[j])[k][1]);
      }
      data.push(arr);
    }

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "Correct") {
          data.cell.styles.textColor = [45, 197, 147];
        }

        if (data.cell.raw == "Incorrect") {
          data.cell.styles.textColor = "#E45266";
        }

        if (data.cell.raw == "Pending") {
          data.cell.styles.textColor = "#919BA3";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("List_Of_Selected_Projects_In_Forecasted_Performance.pdf");
  };

  keepFilters() {
    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    this.setState(
      {
        selectedFiltersData: selectedFiltersData,
        showKeepFiltersModal: false,
        showMatchingProjects: true,
        totalProjectLoading: true,
      },
      this.applyFilters
    );
  }

  closeKeepFiltersModal() {
    this.getAllProjectsData();
    this.getPredictionMilestoneActivityData(1);
    this.getTopFocusAreas();
    this.getTCQSData();
    this.getPredictionMilestoneData();
    this.getProjectCount();
    this.fetchProjectNames();
    this.setState({ showKeepFiltersModal: false, totalProjectLoading: true });
    sessionStorage.setItem("selectedFiltersData", "{}");
  }

  onSelect(selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList });
    let modalTableFilterData = [];
    let tableData = this.state.listOfProjectsProjectFilterData;
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        listOfProjectsFilterData: tableData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        listOfProjectsFilterData: tableData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["Forecasted Status"].toLowerCase() ==
            selectedList[i].value.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({ listOfProjectsFilterData: modalTableFilterData });
        }
      }
    }
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 0);
  }

  render() {
    const {
      activeTab,
      latestData,
      isFilterOpen,
      tcqsChartData,
      csvColumnMapping,
      filtersAPIData,
      topFocusAreasData,
      predictionMilestoneData,
      predictionMilestoneActivityLoaded,
      topFocusAreasLoaded,
      tcqsDataLoaded,
      predictionMilestoneDataLoaded,
      activityList,
      milestoneDbColName,
      milestoneProjectCount,
    } = this.state;
    let uiFilterData, filterSection;
    let filtersData = filtersAPIData;
    if (filtersData) {
      const filterNames = Object.keys(filtersData);
      uiFilterData = filterNames.map((item, index) => {
        const filterName = item;
        /*const filterValues = filtersData[filterName].map((itm, idx) => {
          return itm == "" ? "(Blanks)" : itm;
        });*/
        const filterValues = filtersData[filterName].filter((itm) => {
          return itm != null && itm.trim() != "";
        });
        return (
          <span
            style={{
              float: index % 2 === 0 ? "left" : "right",
            }}
          >
            <Filter
              key={index}
              filterValues={filterValues}
              csvColumnMapping={csvColumnMapping}
              filterData={this.state.selectedFiltersData}
              filterName={filterName}
              setSelectedOptions={(name, list) =>
                this.updateFilters(name, list)
              }
            ></Filter>
          </span>
        );
      });
    }
    if (uiFilterData) {
      filterSection = <div>{uiFilterData}</div>;
    }
    const filters = this.state.selectedFiltersData;

    let filtersArray = [];
    Object.entries(filters).forEach((filterItem) => {
      filterItem[1].forEach((filter) => {
        filtersArray.push({
          filterCategory: filterItem[0],
          filter: filter,
        });
      });
    });
    const getSingleFilterObject = (length, filterCategory, filter) => {
      return (
        <BootstrapTooltip title={filter}>
          <span>
            <div
              className="filter-summary-inner"
              style={
                length > this.state.tablet - 1
                  ? { display: "none" }
                  : { display: "inline" }
              }
            >
              {filter.length < 15 ? filter : filter.substr(0, 14) + "..."}{" "}
              <FaTimes
                className="filter-delete"
                onClick={() => this.removeFilter(filterCategory, filter)}
              ></FaTimes>
            </div>
          </span>
        </BootstrapTooltip>
      );
    };

    let individualFilterArray = [];
    for (let i = 0; i < filtersArray.length; i++) {
      if (filtersArray[i]) {
        individualFilterArray.push(
          getSingleFilterObject(
            i,
            filtersArray[i].filterCategory,
            filtersArray[i].filter
          )
        );
      }
    }

    let filterSummaryData, list, singleFilter;
    // if (Object.keys(filters).length != 0) {
    //   filterSummaryData = Object.keys(filters).map((filterName, index) => {
    //     list = filters[filterName];
    //     singleFilter = list.map((filterVal, idx) => {
    //       return (
    //         <div className="filter-summary-inner">
    //           {filterVal}{" "}
    //           <FaTimes
    //             className="filter-delete"
    //             onClick={() => this.removeFilter(filterName, filterVal)}
    //           ></FaTimes>
    //         </div>
    //       );
    //     });
    //     if (singleFilter.length != 0) {
    //       return (
    //         <div className="filter-summary-outer">
    //           {csvColumnMapping[filterName]}: {singleFilter}
    //         </div>
    //       );
    //     }
    //   });
    // }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    if (this.state.goToTrainingPage) {
      return (
        <Redirect
          to={{
            pathname: "/ui/landing/trainmodel",
            state: {},
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.showListOfProjectsModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={this.closeListOfProjectsModal}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col>
                    <center>
                      <span
                        id="key-tasks-to-text"
                        style={{ color: "#252525", fontSize: "1rem" }}
                      >
                        List Of Selected Projects
                      </span>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper">
                        <Autocomplete
                          options={this.state.projectIdName}
                          onInputChange={this.searchByProject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Project"
                            />
                          )}
                          size="small"
                        />
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="table-fixed-start-columns">
                        <Table
                          responsive
                          className="current-vs-forecasted table table-md table-custom table-list-of-forecasted-projects table-scroll-modal table-modal"
                          id="table-task-list"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <thead className="table-head">
                            {this.state.listOfProjectsFilterData &&
                            JSON.stringify(
                              this.state.listOfProjectsFilterData
                            ) !== JSON.stringify([]) ? (
                              this.state.listOfProjectsFilterData.length >=
                              1 ? (
                                <tr>
                                  {Object.entries(
                                    this.state.listOfProjectsFilterData[0]
                                  ).map((item) => (
                                    <th
                                      className={
                                        item[0] == "Project Id" ||
                                        item[0] == "Project Name"
                                          ? item[0] == "Project Id"
                                            ? "project-id-col"
                                            : ""
                                          : item[0] == "Forecasted Status" ||
                                            item[0] ==
                                              "Status of Last Milestone Achieved" ||
                                            item[0] == "Recommendation Status"
                                          ? "centre-align-data forcasted-status-col"
                                          : ""
                                      }
                                      style={{
                                        paddingTop:
                                          item[0] === "Forecasted Status"
                                            ? "0.1rem"
                                            : "",
                                        paddingBottom:
                                          item[0] === "Forecasted Status"
                                            ? "0.1rem"
                                            : "",
                                        width:
                                          item[0] == "Project Id"
                                            ? "6vw"
                                            : item[0] == "Forecasted Status" ||
                                              item[0] ==
                                                "Status of Last Milestone Achieved"
                                            ? "11vw"
                                            : "",
                                      }}
                                    >
                                      <span className="heading-right-shift">
                                        {item[0] == "Forecasted Status" ? (
                                          <BootstrapTooltip
                                            placement="left-start"
                                            title="Forecasted Milestone Status"
                                          >
                                            <Select
                                              closeMenuOnSelect={false}
                                              blurInputOnSelect={false}
                                              isMulti
                                              options={colourOptions}
                                              styles={colourStyles}
                                              value={this.state.selectedValue}
                                              onChange={this.onSelect}
                                              placeholder="Forecasted Milestone Status"
                                              isSearchable
                                            />
                                          </BootstrapTooltip>
                                        ) : (
                                          item[0]
                                        )}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              ) : (
                                <tr>
                                  <th className="project-id-col">Project Id</th>
                                  <th>Project Name</th>
                                  <th>Last Milestone Achieved</th>
                                  <th>Forecasted Milestone</th>
                                  <th
                                    className="centre-align-data"
                                    style={{
                                      paddingTop: "0.1rem",
                                      paddingBottom: "0.1rem",
                                    }}
                                  >
                                    <BootstrapTooltip
                                      placement="left-start"
                                      title="Forecasted Status"
                                    >
                                      <Select
                                        closeMenuOnSelect={false}
                                        blurInputOnSelect={false}
                                        isMulti
                                        options={colourOptions}
                                        styles={colourStyles}
                                        value={this.state.selectedValue}
                                        onChange={this.onSelect}
                                        placeholder="Forecasted Status"
                                        isSearchable
                                      />
                                    </BootstrapTooltip>
                                  </th>
                                </tr>
                              )
                            ) : (
                              <tr>
                                <th className="project-id-col">Project Id</th>
                                <th>Project Name</th>
                                <th>Last Milestone Achieved</th>
                                <th>Forecasted Milestone</th>
                                <th
                                  className="centre-align-data"
                                  style={{
                                    paddingTop: "0.1rem",
                                    paddingBottom: "0.1rem",
                                  }}
                                >
                                  <BootstrapTooltip
                                    placement="left-start"
                                    title="Forecasted Status"
                                  >
                                    <Select
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      isMulti
                                      options={colourOptions}
                                      styles={colourStyles}
                                      value={this.state.selectedValue}
                                      onChange={this.onSelect}
                                      placeholder="Forecasted Status"
                                      isSearchable
                                    />
                                  </BootstrapTooltip>
                                </th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {this.state.listOfProjectsFilterData &&
                            JSON.stringify(
                              this.state.listOfProjectsFilterData
                            ) !== JSON.stringify([]) ? (
                              this.state.listOfProjectsFilterData.length >=
                              1 ? (
                                this.state.listOfProjectsFilterData.map(
                                  (activityObject) => (
                                    <tr>
                                      {activityObject
                                        ? Object.entries(activityObject).map(
                                            (item) => (
                                              <td
                                                className={
                                                  item[0] == "Project Id" ||
                                                  item[0] == "Project Name"
                                                    ? item[0] == "Project Id"
                                                      ? "table-data-bold"
                                                      : "padding-data table-data-bold"
                                                    : item[0] ==
                                                        "Forecasted Status" ||
                                                      item[0] ==
                                                        "Status of Last Milestone Achieved" ||
                                                      item[0] ==
                                                        "Recommendation Status"
                                                    ? "centre-align-data forcasted-status-col"
                                                    : "table-data-black padding-data"
                                                }
                                                style={{
                                                  width:
                                                    item[0] == "Project Id"
                                                      ? "6vw"
                                                      : item[0] ==
                                                          "Forecasted Status" ||
                                                        item[0] ==
                                                          "Status of Last Milestone Achieved" ||
                                                        item[0] ==
                                                          "Recommendation Status"
                                                      ? "11vw"
                                                      : "",
                                                }}
                                              >
                                                {item[0] ==
                                                  "Forecasted Status" ||
                                                item[0] ==
                                                  "Status of Last Milestone Achieved" ||
                                                item[0] ==
                                                  "Recommendation Status" ? (
                                                  item[1] ? (
                                                    item[1] != "NA" ? (
                                                      <div
                                                        className={
                                                          item[1] === "R"
                                                            ? "red-circle"
                                                            : item[1] === "GR"
                                                            ? "grey-circle"
                                                            : item[1] === "A"
                                                            ? "amber-circle"
                                                            : item[1] === "B"
                                                            ? "blue-circle"
                                                            : "green-circle"
                                                        }
                                                      ></div>
                                                    ) : (
                                                      "-"
                                                    )
                                                  ) : (
                                                    "-"
                                                  )
                                                ) : (
                                                  <span>{item[1]}</span>
                                                )}
                                              </td>
                                            )
                                          )
                                        : ""}
                                    </tr>
                                  )
                                )
                              ) : (
                                <div></div>
                              )
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                        {this.state.listOfProjectsData &&
                        JSON.stringify(this.state.listOfProjectsData) !==
                          JSON.stringify([]) ? (
                          <div></div>
                        ) : (
                          <span>
                            <div
                              id="current-month-forecast-modal-no-data"
                              className="no-data-text-modal"
                            >
                              No data available for list of projects
                            </div>
                            <br />
                          </span>
                        )}
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <Button
                        color="primary"
                        onClick={this.downloadTablePdf}
                        size="sm"
                        id="download-list-key-actvity"
                        disabled={
                          this.state.listOfProjectsData &&
                          JSON.stringify(this.state.listOfProjectsData) !==
                            JSON.stringify([])
                            ? false
                            : true
                        }
                      >
                        {this.state.isListOfProjectsDataDownloading ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>{" "}
                            Downloading...
                          </div>
                        ) : (
                          <div>
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </div>
                        )}
                      </Button>
                    </center>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {this.state.showKeepFiltersModal === true ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Retain Filters</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div>Would you like to retain the filters applied?</div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                id="yes-no-button"
                onClick={this.keepFilters}
              >
                YES
              </Button>
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeKeepFiltersModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        {this.state.openCloseConfirmation === true ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Close Filter Pane</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div>Are you sure to close the filter pane?</div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeFilter}
              >
                YES
              </Button>
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.toggleCloseConfirmationModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        {this.state.goToTrainingModal ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Train Model</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div style={{ fontSize: "0.81rem" }}>
                Please Train Model To View{" "}
                <span id="delete-confirmation-username-text">
                  Forecasted Performance
                </span>
              </div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                onClick={this.onGoToTraining}
                id="yes-no-button"
                active={false}
              >
                YES
              </Button>{" "}
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeGoToTrainingModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}

        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <Row>
            <Col xs={4}>
              <div className="currentClass">Forecasted Performance</div>
              <div className="basedClass">
                <span className="year-month-dashboard">
                  {GenericConstants.MONTH_MAPPING[this.props.month]
                    ? GenericConstants.MONTH_MAPPING[this.props.month]
                    : this.props.month}{" "}
                  {this.props.year}
                </span>
                {this.props.month ? " snapshot" : ""}, <span style={{color:'black'}}>Uploaded On:</span> {this.state.latestCSVDate}
                <br />
                <span style={{ float: "left" }}>{"Live Projects Only"}</span>
              </div>
            </Col>
            <Col xs={5}>
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    id="nav-link-all-programs-search-project"
                    className={classnames({
                      activeTab: activeTab === "1",
                      "nav-label": true,
                    })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    All Programmes
                  </NavLink>
                  <div
                    className="triangle-outer"
                    style={{ display: activeTab === "1" ? "block" : "none" }}
                  >
                    <div className="triangle-inner"></div>
                  </div>
                </NavItem>
                &nbsp;&nbsp;&nbsp;
                <NavItem>
                  <NavLink
                    id="nav-link-all-programs-search-project"
                    className={classnames({
                      activeTab: activeTab === "2",
                      "nav-label": true,
                    })}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Search By Project
                  </NavLink>
                  <div
                    className="triangle-outer"
                    style={{
                      display: activeTab === "2" ? "block" : "none",
                      marginLeft: "1.1rem",
                    }}
                  >
                    <div
                      className="triangle-inner"
                      style={{ marginLeft: "1.1rem" }}
                    ></div>
                  </div>
                </NavItem>
              </Nav>
            </Col>
            <Col xs={3}>
              <ButtonDropdown
                isOpen={this.state.isDownloadOpen}
                toggle={this.toggleDownload}
                color="primary"
                className="button-dropdown-dashboard"
              >
                <DropdownToggle
                  caret
                  size="sm"
                  color="primary"
                  disabled={
                    !this.state.downloadEnabled || this.state.pdfDownloading
                      ? true
                      : false
                  }
                >
                  {this.state.pdfDownloading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span> Downloading...
                    </span>
                  ) : (
                    <span>
                      <GetAppIcon fontSize="small" />
                      <span className="download-text-dashboard"> Download</span>
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu size="sm">
                  <DropdownItem
                    onClick={this.downloadPdfClicked}
                    className="dropdown-item-dashboard"
                    size="sm"
                  >
                    Pdf
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadExcelClicked}
                  >
                    Xlsx
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadData}
                  >
                    Both
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </Header>
        <div>
          {this.state.showAlert ? (
            <AutoCloseAlert
              color={this.state.alertcolor}
              body={this.state.alertMsg}
              showAlert={this.state.showAlert}
              setShowAlert={this.setShowAlert}
            />
          ) : (
            ""
          )}
          <TabContent activeTab={activeTab} style={{ marginTop: "-14px" }}>
            <TabPane tabId="1">
              <Row>
                <Col xs={2} md={2}>
                  {
                    <Row>
                      <Col xs={12} md={12} className="total-label">
                        {this.state.totalProjects &&
                        !(
                          this.state.totalProjectLoading ||
                          !predictionMilestoneActivityLoaded ||
                          !topFocusAreasLoaded ||
                          !tcqsDataLoaded ||
                          !predictionMilestoneDataLoaded
                        ) ? (
                          <BootstrapTooltip title="Click here to see the list of projects">
                            <a
                              onClick={this.openListOfProjectsModal}
                              className="list-of-proj-hyperlink"
                            >
                              <span style={{ color: "black" }}>
                                {individualFilterArray.length !== 0 &&
                                this.state.showMatchingProjects === true
                                  ? "Matching Projects"
                                  : "Forecasted Milestones"}
                              </span>
                            </a>
                          </BootstrapTooltip>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} md={12} className="project-count">
                        {this.state.totalProjectLoading ||
                        !predictionMilestoneActivityLoaded ||
                        !topFocusAreasLoaded ||
                        !tcqsDataLoaded ||
                        !predictionMilestoneDataLoaded ? (
                          <div style={{ color: "#268cd1" }}>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div>
                            <BootstrapTooltip title="Click here to see the list of projects">
                              <a
                                onClick={this.openListOfProjectsModal}
                                className="list-of-proj-hyperlink"
                              >
                                <span>
                                  <u>{this.state.totalProjects}</u>
                                </span>
                              </a>
                            </BootstrapTooltip>
                          </div>
                        )}
                      </Col>
                    </Row>
                  }
                </Col>
                <Col xs={5} md={5}>
                  {
                    <div className="filter-summary">
                      <div className="filter-summary-title">
                        {/* Applied Filters */}
                      </div>
                      {/* {filterSummaryData} */}
                      {individualFilterArray}

                      <div
                        className="filter-summary-inner"
                        style={
                          individualFilterArray.length <= this.state.tablet
                            ? { display: "none" }
                            : { display: "inline" }
                        }
                      >
                        {individualFilterArray.length > this.state.tablet
                          ? individualFilterArray.length -
                            this.state.tablet +
                            " +"
                          : ""}
                      </div>
                    </div>
                  }
                </Col>
                <Col xs={3} md={3}>
                  {/*
                  {latestData != null && (
                    <Button
                      color="primary"
                      onClick={this.openListOfProjectsModal}
                      size="sm"
                      className="list-of-proj-dashboard"
                    >
                      List Of Projects
                    </Button>
                  )}
                  */}
                </Col>
                <Col xs={2} md={2}>
                  {latestData != null && (
                    <div style={{ position: "relative" }}>
                      <div className="filter">
                        <CardHeader className="filter-header">
                          <span onClick={this.toggleFilter}>
                            <b>All Filters </b>
                            <span style={{ color: "#BD2967" }}>
                              {!isFilterOpen && <FaAngleDown></FaAngleDown>}{" "}
                              {isFilterOpen && <FaAngleUp></FaAngleUp>}
                            </span>
                          </span>
                        </CardHeader>
                        <Collapse
                          isOpen={isFilterOpen}
                          className="filter-main"
                          timeout={0}
                        >
                          <Row>
                            <Col>
                              <CancelIcon
                                className="cancel-close-icon"
                                onClick={
                                  individualFilterArray.length !== 0
                                    ? this.closeFilterConfirmation
                                    : this.noFilterSelectedCloseFilter
                                }
                                id="cancel-filter"
                                fontSize="small"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {filterSection && (
                                <div className="filter-div">
                                  {filterSection}
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="filter-buttons">
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.clearFilters();
                                    this.setState({
                                      showMatchingProjects: false,
                                      totalProjectLoading: true,
                                    });
                                  }}
                                  outline
                                >
                                  Reset All
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.applyFilters();
                                    this.setState({
                                      showMatchingProjects: true,
                                      totalProjectLoading: true,
                                    });
                                  }}
                                >
                                  Apply Filters
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Collapse>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row id="allprogramscontentdiv">
                <Col>
                  {/*
                  {(!predictionMilestoneActivityLoaded ||
                    !topFocusAreasLoaded ||
                    !tcqsDataLoaded ||
                    !predictionMilestoneDataLoaded) && (
                    <div>
                      <PageSpinner />
                    </div>
                    )}
                    */}
                  {predictionMilestoneActivityLoaded &&
                    latestData &&
                    JSON.stringify(latestData) !== JSON.stringify({}) && (
                      <CurrentMonthMap
                        mapData={latestData}
                        activityList={activityList}
                        milestoneDbColName={milestoneDbColName}
                        milestoneProjectCount={milestoneProjectCount}
                        selectedFiltersData={this.state.selectedFiltersData}
                        updateSelectedMilestone={this.updateSelectedMilestone}
                        selectedMilestone={this.state.selectedMilestone}
                      ></CurrentMonthMap>
                    )}
                  {predictionMilestoneActivityLoaded &&
                  topFocusAreasLoaded &&
                  topFocusAreasData &&
                  csvColumnMapping &&
                  JSON.stringify(topFocusAreasData) !== JSON.stringify({}) &&
                  JSON.stringify(csvColumnMapping) !== JSON.stringify({})
                    ? Object.entries(topFocusAreasData).length != 0 && (
                        <BarChartWithFilter
                          chartData={topFocusAreasData}
                          csvColumnMapping={csvColumnMapping}
                        ></BarChartWithFilter>
                      )
                    : ""}
                  {predictionMilestoneActivityLoaded &&
                    tcqsDataLoaded &&
                    tcqsChartData &&
                    csvColumnMapping &&
                    JSON.stringify(tcqsChartData) !== JSON.stringify({}) &&
                    JSON.stringify(csvColumnMapping) !== JSON.stringify({}) && (
                      <CurrentMonthChart
                        chartData={tcqsChartData}
                        filterData={this.state.selectedFiltersData}
                        csvColumnMapping={csvColumnMapping}
                      ></CurrentMonthChart>
                    )}
                  {predictionMilestoneActivityLoaded &&
                    predictionMilestoneDataLoaded &&
                    predictionMilestoneData &&
                    predictionMilestoneData.length != 0 &&
                    csvColumnMapping &&
                    JSON.stringify(predictionMilestoneData) !==
                      JSON.stringify({}) &&
                    JSON.stringify(csvColumnMapping) !== JSON.stringify({}) && (
                      <StackedBarChart
                        chartData={predictionMilestoneData}
                        filterData={this.state.selectedFiltersData}
                        csvColumnMapping={csvColumnMapping}
                      ></StackedBarChart>
                    )}
                  {predictionMilestoneActivityLoaded &&
                    topFocusAreasLoaded &&
                    tcqsDataLoaded &&
                    predictionMilestoneDataLoaded &&
                    (latestData == null ||
                      JSON.stringify(latestData) === JSON.stringify({})) &&
                    (topFocusAreasData == null ||
                      JSON.stringify(topFocusAreasData) ===
                        JSON.stringify({})) &&
                    (tcqsChartData == null ||
                      JSON.stringify(tcqsChartData) === JSON.stringify({})) &&
                    (predictionMilestoneData == null ||
                      JSON.stringify(predictionMilestoneData) ===
                        JSON.stringify({})) && (
                      <div className="no-data-available-dashboard">
                        No project data available for dashboard. Please upload
                        data to view dashboard
                      </div>
                    )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <ProjectMap
                    updateProjectMapDataLoadStatus={
                      this.updateProjectMapDataLoadStatus
                    }
                    parentCallback={this.callbackFunction}
                    updateSingleProjectMapData={this.updateSingleProjectMapData}
                    csvColumnMapping={csvColumnMapping}
                    selectedFiltersData={this.state.selectedFiltersData}
                    projectNameIdMap={this.state.projectNameIdMap}
                    isFetchProjectNamesLoading={
                      this.state.isFetchProjectNamesLoading
                    }
                    projectsDataDefaultVal={this.state.projectsDataDefaultVal}
                  ></ProjectMap>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </React.Fragment>
    );
  }
}
export default PredictionPage;
