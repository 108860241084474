import React from "react";
import { Route } from "react-router-dom";

const Layout = ({ children }) => children;

const LayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (sessionStorage.getItem("token") != null) {
          sessionStorage.removeItem("token");
        }
        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

export default LayoutRoute;
