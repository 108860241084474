import React from "react";
import axios from "axios";
import { useEffect } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Row, Col, Button, Modal, ModalBody, Spinner, Table } from "reactstrap";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandler } from "../components/ErrorHandler";
import TableAllUsers from "../components/admin/all-users/TableAllUsers";
import TableAllDeletedUsers from "../components/admin/all-users/TableAllDeletedUsers";
import Header from "../components/Header";
import { HEADER_TYPE_JSON } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import NotAuthorized from "../components/NotAuthorized";

const GetAllActiveUsers = (props) => {
  const [rows, setRows] = React.useState([]);
  const [cnt, setCnt] = React.useState(2);
  const [isloading, setIsLoading] = React.useState(true);
  const setShowAlert = props.setShowAlert;
  const setAlertMsg = props.setAlertMsg;
  const setAlertcolor = props.setAlertcolor;
  const userRoleList = props.userRoleList;
  const userRoleNameList = props.userRoleNameList;
  const setAllActiveUsersList = props.setAllActiveUsersList;

  useEffect(() => {
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=1`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          let temp = response.data;
          setRows(temp.userList);
          setAllActiveUsersList(temp.userList);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setAlertcolor("danger");
        let alert = ErrorHandler(error);
        setShowAlert(alert.setAlertShow);
        setAlertMsg(alert.setMsgAlert);
      });
  }, [cnt]);
  return (
    <TableAllUsers
      deleteUser={deleteUser}
      makeAdminUser={makeAdminUser}
      updateUserRole={updateUserRole}
      data={rows}
      setData={setRows}
      cnt={cnt}
      setCnt={setCnt}
      isloading={isloading}
      setShowAlert={setShowAlert}
      setAlertMsg={setAlertMsg}
      setAlertcolor={setAlertcolor}
      count={props.count}
      setCount={props.setCount}
      userRoleList={userRoleList}
      userRoleNameList={userRoleNameList}
    />
  );
};

const deleteUser = (
  index,
  cnt,
  setCnt,
  setShowAlert,
  setAlertMsg,
  setAlertcolor,
  count,
  setCount
) => {
  const userId = index;

  axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/${userId}`,
    method: "DELETE",
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCnt(++cnt);
      setCount(++count);
      setShowAlert(true);
      setAlertMsg("Success: User deleted successfully");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const makeAdminUser = (
  index,
  username,
  isAdmin,
  cnt,
  setCnt,
  setShowAlert,
  setAlertMsg,
  setAlertcolor,
  count,
  setCount
) => {
  const userId = index;
  const postData = {
    data: {
      isAdmin: isAdmin,
    },
  };

  axios({
    url: `${[
      process.env.REACT_APP_BACKEND_API,
    ]}/admin/users/make-admin/${userId}`,
    method: "PUT",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      //setCnt(++cnt);
      //setCount(++count);
      setShowAlert(true);
      if (isAdmin == 1) {
        setAlertMsg("Success: " + username + " is now an admin user");
      } else {
        setAlertMsg(
          "Success: Admin rights revoked for " + username + " successfully "
        );
      }

      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const updateUserRole = (
  userId,
  userRoleId,
  cnt,
  setCnt,
  setShowAlert,
  setAlertMsg,
  setAlertcolor,
  count,
  setCount
) => {
  const postData = {
    data: {
      userToUpdate: userId,
      userRoleId: userRoleId,
    },
  };

  axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/roles`,
    method: "POST",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      console.log(response);
      //setCnt(++cnt);
      //setCount(++count);
      setShowAlert(true);
      setAlertMsg("Success: User role updated successfully");
      setAlertcolor("success");
    })
    .catch((error) => {
      console.log(error);
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const getUserRoleList = (
  setShowAlert,
  setAlertMsg,
  setAlertcolor,
  setUserRoleList,
  setUserRoleNameList,
  setUserRoleIdNameMap
) => {
  axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/roles`,
    method: "GET",
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      console.log(response);
      if (response.data) {
        let userRoleList = response.data.userRoleList;
        let userRoleNameList = [];
        let userRoleIdNameMap = {};
        userRoleIdNameMap[0] = "-";

        if (userRoleList) {
          setUserRoleList(userRoleList);
          for (let i = 0; i < userRoleList.length; i++) {
            if (userRoleList[i]) {
              userRoleNameList.push(userRoleList[i].roleShortName);
              userRoleIdNameMap[userRoleList[i].roleId] =
                userRoleList[i].roleShortName;
            }
          }
        }

        setUserRoleNameList(userRoleNameList);
        setUserRoleIdNameMap(userRoleIdNameMap);
      }
    })
    .catch((error) => {
      console.log(error);
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const GetAllDeletedUsers = (props) => {
  const [rows, setRows] = React.useState([]);
  const [isloading, setIsLoading] = React.useState(true);
  const setShowAlert = props.setShowAlert;
  const setAlertMsg = props.setAlertMsg;
  const setAlertcolor = props.setAlertcolor;

  useEffect(() => {
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=3`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          let temp = response.data;
          setRows(temp.userList);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setAlertcolor("danger");
        let alert = ErrorHandler(error);
        setShowAlert(alert.setAlertShow);
        setAlertMsg(alert.setMsgAlert);
        setIsLoading(false);
      });
  }, [props.count]);
  return (
    <TableAllDeletedUsers
      deleteUser={deleteUser}
      data={rows}
      setData={setRows}
      isloading={isloading}
      setShowAlert={setShowAlert}
      setAlertMsg={setAlertMsg}
      setAlertcolor={setAlertcolor}
    />
  );
};

const excelDataConverter = (allActiveUsersList, userRoleIdNameMap) => {
  let outputData = [];
  if (allActiveUsersList) {
    allActiveUsersList.forEach((item) => {
      if (item) {
        outputData.push({
          Username: item.username,
          "Request Date": item.creationDate,
          "Sessions in last 30 days": item.loginCount,
          "User Role": userRoleIdNameMap
            ? userRoleIdNameMap[item.userRoleId]
            : "",
          "User Type": item.isAdmin ? "Admin" : "Normal",
        });
      }
    });
  }
  return outputData;
};

const downloadActiveUsersExcel = (allActiveUsersList, userRoleIdNameMap) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
  let sheets = {};
  let sheetNames = [];

  if (allActiveUsersList) {
    const ws1 = XLSX.utils.json_to_sheet(
      excelDataConverter(allActiveUsersList, userRoleIdNameMap)
    );
    sheets["All Active Users"] = ws1;
    sheetNames.push("All Active Users");
  }

  const wb = {
    Sheets: sheets,
    SheetNames: sheetNames,
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, "All_Active_Users.xlsx");
};

const AllUsersPage = (props) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertcolor, setAlertcolor] = React.useState("success");
  const [userRoleList, setUserRoleList] = React.useState([]);
  const [userRoleNameList, setUserRoleNameList] = React.useState([]);
  const [allActiveUsersList, setAllActiveUsersList] = React.useState([]);
  const [userRoleIdNameMap, setUserRoleIdNameMap] = React.useState({});
  const [count, setCount] = React.useState(0);
  const open = props.open;
  const setOpen = props.setOpen;

  useEffect(() => {
    getUserRoleList(
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      setUserRoleList,
      setUserRoleNameList,
      setUserRoleIdNameMap
    );
  }, [count]);

  if (alertMsg === ErrorConstants.ERROR_SESSION) {
    return <SessionExpired />;
  }
  if (alertMsg === ErrorConstants.ERROR_NOT_AUTHORIZED) {
    return <NotAuthorized />;
  }
  return (
    <React.Fragment>
      <Header open={open} setOpen={setOpen}>
        <h5 className="header-common-heading">All Users</h5>
      </Header>
      {showAlert ? (
        <AutoCloseAlert
          color={alertcolor}
          body={alertMsg}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        />
      ) : (
        ""
      )}
      <div>
        <h6 className="users-list-heading">
          All Active Users{" "}
          <span style={{ float: "right", marginBottom: "0.6rem" }}>
            <Button
              color="primary"
              onClick={() => {
                downloadActiveUsersExcel(allActiveUsersList, userRoleIdNameMap);
              }}
              size="sm"
              id="download-list-key-actvity"
              style={{ fontSize: "0.58rem" }}
            >
              <GetAppIcon fontSize="small" style={{ fontSize: "0.93rem" }} />{" "}
              DOWNLOAD
            </Button>
          </span>
        </h6>
        <GetAllActiveUsers
          setShowAlert={setShowAlert}
          setAlertMsg={setAlertMsg}
          setAlertcolor={setAlertcolor}
          count={count}
          setCount={setCount}
          userRoleList={userRoleList}
          userRoleNameList={userRoleNameList}
          setAllActiveUsersList={setAllActiveUsersList}
        />
        <br />
      </div>
      <div>
        <h6 className="users-list-heading">All Deleted Users</h6>
        <GetAllDeletedUsers
          setShowAlert={setShowAlert}
          setAlertMsg={setAlertMsg}
          setAlertcolor={setAlertcolor}
          count={count}
          setCount={setCount}
        />
        <br />
      </div>
    </React.Fragment>
  );
};

export default AllUsersPage;
