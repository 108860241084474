export const ERROR_GENERIC = "Something went wrong. Please try again later";
export const ERROR_SERVER =
  "Internal server error occured. Please try again later";
export const ERROR_SESSION = "Session expired. Please login again";
export const ERROR_NOT_AUTHORIZED =
  "You are not authorized to perform this action";
export const ERROR_GENERIC_TIMEDATA =
  "Something went wrong in fetching year and month data";
export const ERROR_INVALID_FILE_UPLOAD =
  "File is incorrect. Please upload file in given template";
export const ERROR_TRAINING_FAILED = "Model Training Failed";
