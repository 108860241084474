import React, {useEffect} from "react";
import { PaginationItem, PaginationLink, Pagination } from "reactstrap";

import "../styles/components/TablePagination.scss";

const TablePagination = ({ pagesCount, currentPage, setCurrentPage }) => {
  const pageNumberArray = new Array(pagesCount);
  const showPageNumbers = 3;
  const [startIndex, setStartIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(showPageNumbers - 1);

  //execute if the pagecount changes
  useEffect(() => {
    if(currentPage >= pagesCount){
      setStartIndex(pagesCount - showPageNumbers > 0 ? pagesCount - showPageNumbers : 0);
      setLastIndex(pagesCount - 1);
      setCurrentPage(pagesCount - 1);
    }
    if(pagesCount > lastIndex && lastIndex - startIndex < showPageNumbers - 1){
      setLastIndex(startIndex + showPageNumbers - 1);
    }
  }, [pagesCount]);
  
  const handlePageClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };
  
  const handlePreviousClick = (e) => {
    e.preventDefault();
    let newStartIndex = startIndex - 1 > 0 ? startIndex - 1 : 0;
    setStartIndex(newStartIndex);
    setLastIndex(newStartIndex + showPageNumbers - 1);
    setCurrentPage(currentPage - 1);
  };
  
  const handleNextClick = (e) => {
    e.preventDefault();
    let newLastIndex =
      lastIndex + 1 < pagesCount ? lastIndex + 1 : pagesCount - 1;
    setStartIndex(newLastIndex - showPageNumbers + 1 > 0 ? newLastIndex - showPageNumbers + 1 : 0);
    setLastIndex(newLastIndex);
    setCurrentPage(currentPage + 1);
  };
  
  const handleFirstClick = (e) => {
    e.preventDefault();
    setStartIndex(0);
    setLastIndex(showPageNumbers - 1);
    setCurrentPage(0);
  };
  
  const handleLastClick = (e) => {
    e.preventDefault();
    setStartIndex(pagesCount - showPageNumbers > 0 ? pagesCount - showPageNumbers : 0);
    setLastIndex(pagesCount - 1);
    setCurrentPage(pagesCount - 1);
  };

  return (
    <React.Fragment>
      <Pagination aria-label="Page navigation" size="sm">
        <PaginationItem disabled={currentPage <= 0}>
          <PaginationLink
            onClick={handleFirstClick}
            first
            href="#"
            className="pagination-link-custom"
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage <= 0}>
          <PaginationLink
            onClick={handlePreviousClick}
            previous
            href="#"
            className="pagination-link-custom"
          />
        </PaginationItem>
        {[...pageNumberArray]
          .slice(startIndex, lastIndex + 1)
          .map((page, i) => (
            <PaginationItem active={startIndex + i === currentPage} key={i}>
              <PaginationLink
                onClick={(e) => handlePageClick(e, startIndex + i)}
                href="#"
                className="pagination-link-custom"
              >
                {startIndex + i + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        <PaginationItem disabled={currentPage >= pagesCount - 1}>
          <PaginationLink
            onClick={handleNextClick}
            next
            href="#"
            className="pagination-link-custom"
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage >= pagesCount - 1}>
          <PaginationLink
            onClick={handleLastClick}
            last
            href="#"
            className="pagination-link-custom"
          />
        </PaginationItem>
      </Pagination>
    </React.Fragment>
  );
};

export default TablePagination;
