import React, { Component } from "react";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

export default class ForcastedVsCurrentMonthChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: Object.keys(this.props.chartData),
        datasets: [
          {
            label: "% Accuracy",
            backgroundColor: "#4472C4",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
        ],
      },
    };
    this.onBarClick = this.onBarClick.bind(this);
  }

  onBarClick(event, i) {
    this.props.openModal(event, i, true);
  }

  render() {
    const chartData = this.state.data;
    chartData.datasets[0].data = Object.values(this.props.chartData);

    const options = {
      responsive: false,
      title: {
        display: false,
        text: "Count of T, C, Q, S, H&S and SMESA",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return value + "%";
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      onClick: this.onBarClick,
    };

    return (
      <React.Fragment>
        <div className="chart-top forcasted-vs-current-chart">
          <div className="chart-header" style={{ paddingTop: "16px" }}>
            <span>
              Actual project performance vs forecasted model performance by
              month
            </span>
            <BootstrapTooltip
              placement="right-start"
              title="The chart represents the level of accuracy of the forecast by month. The accuracy is calculated as the ratio of current milestone status as 'R' or 'G' against the forecasted status of the same milestone in the previous month. The ratio is converted into a percentage value. Projects with current status “A” and “GR” are excluded from the calculation.​"
            >
              <span>
                <MdInfo
                  size={20}
                  className="c-info"
                  style={{ marginTop: "-0.5rem" }}
                ></MdInfo>
              </span>
            </BootstrapTooltip>
            <div className="legend-list-item ml-5">
              <div
                className="legend-color"
                style={{ backgroundColor: "#4472C4" }}
              ></div>
              <div className="legend-label">% Accuracy</div>
            </div>
            <span
              id="accuracy-current-vs-forecast"
              style={{ marginTop: "-0.08rem" }}
            >
              <span id="accuracy-text-current-vs-forecast">
                Average Forecasted Level of Accuracy: &nbsp;
              </span>
              {this.props.averageAccuracy
                ? Math.round(this.props.averageAccuracy)
                : "NA"}
              %
            </span>
          </div>

          <div>
            <Bar data={chartData} width={1000} height={300} options={options} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
