import * as ErrorConstants from "../src-constants/ErrorConstants";

let alert;
export const ErrorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_SERVER,
        setAlertShow: true,
      };
    } else if (error.response.status === 440) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_SESSION,
        setAlertShow: true,
      };
    } else if (error.response.status === 401) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_NOT_AUTHORIZED,
        setAlertShow: true,
      };
    } else {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_GENERIC,
        setAlertShow: true,
      };
    }
  } else {
    alert = {
      setMsgAlert: ErrorConstants.ERROR_GENERIC,
      setAlertShow: true,
    };
  }
  return alert;
};

export const ErrorHandlerTimeData = (error) => {
  if (error.response) {
    if (error.response.status === 500) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_SERVER,
        setAlertShow: true,
      };
    } else if (error.response.status === 440) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_SESSION,
        setAlertShow: true,
      };
    } else {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_GENERIC_TIMEDATA,
        setAlertShow: true,
      };
    }
  } else {
    alert = {
      setMsgAlert: ErrorConstants.ERROR_GENERIC_TIMEDATA,
      setAlertShow: true,
    };
  }
  return alert;
};

export const ErrorHandlerSignIn = (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      alert = {
        message: error.response.data.message,
        showMessage: true,
        setAlertShow: false,
        setMsgAlert: "",
      }
    }  else if (error.response.status === 500) {
      alert = {
        message: "",
        showMessage: false,
        setMsgAlert: ErrorConstants.ERROR_SERVER,
        setAlertShow: true,
      };
    } else {
      alert = {
        message: "",
        showMessage: false,
        setMsgAlert: ErrorConstants.ERROR_GENERIC,
        setAlertShow: true,
      };
    }
  } else {
    alert = {
      message: "",
      showMessage: false,
      setMsgAlert: ErrorConstants.ERROR_GENERIC,
      setAlertShow: true,
    };
  }
  return alert;
};

export const ErrorHandlerSignUpReset = (error, msg) => {
  if (error.response) {
    if (error.response.status === 400) {
      alert = {
        message: msg,
        showMessage: true,
        setAlertShow: false,
        setMsgAlert: "",
      };
    } else if (error.response.status === 500) {
      alert = {
        message: "",
        showMessage: false,
        setMsgAlert: ErrorConstants.ERROR_SERVER,
        setAlertShow: true,
      };
    } else {
      alert = {
        message: "",
        showMessage: false,
        setMsgAlert: ErrorConstants.ERROR_GENERIC,
        setAlertShow: true,
      };
    }
  } else {
    alert = {
      message: "",
      showMessage: false,
      setMsgAlert: ErrorConstants.ERROR_GENERIC,
      setAlertShow: true,
    };
  }
  return alert;
};

export const ErrorHandlerChangePassword = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      alert = {
        setAlertShow: false,
        setMsgAlert: "",
        showMessage: true,
        message: error.response.data.message,
      };
    } else if (error.response.status === 500) {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_SERVER,
        setAlertShow: true,
        showMessage: false,
        message: "",
      };
    } else {
      alert = {
        setMsgAlert: ErrorConstants.ERROR_GENERIC,
        setAlertShow: true,
        showMessage: false,
        message: "",
      };
    }
  } else {
    alert = {
      setMsgAlert: ErrorConstants.ERROR_GENERIC,
      setAlertShow: true,
      showMessage: false,
      message: "",
    };
  }
  return alert;
};
