import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { saveAs } from "file-saver";
import { FaAngleDown, FaAngleUp, FaTimes } from "react-icons/fa";
import GetAppIcon from "@material-ui/icons/GetApp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import CancelIcon from "@material-ui/icons/Cancel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import "jspdf-autotable";
import TextField from "@material-ui/core/TextField";
import chroma from "chroma-js";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { MdInfo } from "react-icons/md";
//import { Multiselect } from "multiselect-react-dropdown";
//import ExpandLessIcon from "@material-ui/icons/ExpandLess";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AutoCloseAlert from "../components/AutoCloseAlert";
import PageSpinner from "../components/PageSpinner";
import * as GenericConstants from "../src-constants/GenericConstants";
import ForcastedVsCurrentMonthChart from "../components/dashboard/predictions/charts/ForcastedVsCurrentMonthChart";
import { ErrorHandler } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import "../styles/components/Chart.scss";
import * as PredictionDataConverter from "../services/PredictionDataConverter";
import Filter from "../components/dashboard/Filter";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.5px solid #E4E4E4",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.4rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "Correct", label: "Correct", color: "#2DC593" },
  { value: "Incorrect", label: "Incorrect", color: "#E45266" },
  { value: "Not applicable", label: "Not applicable", color: "#919BA3" },
];
class CurrentVsForecastedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      getLatestDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-milestone?filters=`,
      getTCQSDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/quality-params-data?filters=`,
      getFiltersDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/filters-data`,
      getCsvColumnsMappingRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/column-mapping`,
      getLatestCSVDateURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/latest-csv-date`,
      selectedFiltersData: {},
      tablet: 5,
      forcastedVsCurrentMonthData: {},
      forcastedVsCurrentResponseData: [],
      selectedMonthYear: null,
      forcastedVsCurrentMonthDataLoaded: false,
      modalTableData: null,
      modalTableFilterData: null,
      downloadEnabled: false,
      showAlert: false,
      alertMsg: "",
      latestCSVDate: null,
      alertcolor: "danger",
      options: [
        { name: "C", id: 1, fullName: "Correct" },
        { name: "I", id: 2, fullName: "Incorrect" },
        { name: "N", id: 3, fullName: "Not applicable" },
      ],
    };
    this.style = {
      chips: {
        background: "#268cd1",
        fontSize: "0.3rem",
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px",
        fontSize: "0.45rem",
        marginTop: "0.14rem",
      },
      multiselectContainer: {
        color: "#268cd1",
        width: "60%",
      },
      optionContainer: {
        // To change css for option container
        fontSize: "0.55rem",
      },
    };
    this.getLatestData = this.getLatestData.bind(this);
    this.getModalTableData = this.getModalTableData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.downloadTablePdf = this.downloadTablePdf.bind(this);
    this.downloadExcelClicked = this.downloadExcelClicked.bind(this);
    this.downloadPdfClicked = this.downloadPdfClicked.bind(this);
    this.toggleDownload = this.toggleDownload.bind(this);
    this.updateDownloadButtonState = this.updateDownloadButtonState.bind(this);
    this.downloadBothPdfAndExcel = this.downloadBothPdfAndExcel.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    this.getCurrentVsForecastData = this.getCurrentVsForecastData.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.getCurrentMonthAndYear = this.getCurrentMonthAndYear.bind(this);
    this.onMilestoneSelect = this.onMilestoneSelect.bind(this);
    this.onComparisonSelect = this.onComparisonSelect.bind(this);
    this.onMilestoneSelectSecond = this.onMilestoneSelectSecond.bind(this);
    this.onComparisonSelectSecond = this.onComparisonSelectSecond.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.getCsvColumnsMapping = this.getCsvColumnsMapping.bind(this);
    this.escapeFilterValue = this.escapeFilterValue.bind(this);
    this.keepFilters = this.keepFilters.bind(this);
    this.closeKeepFiltersModal = this.closeKeepFiltersModal.bind(this);
    this.getLatestCSVDate = this.getLatestCSVDate.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 1000) {
      this.setState({ tablet: 2 });
    } else this.setState({ tablet: 3 });
    
    // get latest CSV date
    this.getLatestCSVDate();
    this.getCsvColumnsMapping();
    this.getFiltersData();

    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    if (selectedFiltersData) {
      if (JSON.stringify(selectedFiltersData) != "{}") {
        this.setState({
          showKeepFiltersModal: true,
        });
      } else {
        this.getCurrentMonthAndYear();
        this.getCurrentVsForecastData();
      }
    } else {
      this.getCurrentMonthAndYear();
      this.getCurrentVsForecastData();
      sessionStorage.setItem("selectedFiltersData", JSON.stringify({}));
    }
  }

  escapeFilterValue(filterJsonObject) {
    let encodedFilterJsonObject = {};
    Object.entries(filterJsonObject).forEach((filterItem) => {
      let encodedFilterValueArray = [];
      filterItem[1].forEach((filterValue) => {
        encodedFilterValueArray.push(encodeURIComponent(filterValue));
      });
      encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
    });
    return encodedFilterJsonObject;
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedMilestoneValue: null,
      selectedComparisonValue: null,
    });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };
  
  getLatestCSVDate() {
    axios({
      url: this.state.getLatestCSVDateURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        // get current year
        let csvDate = response.data.latestUploadDate
        csvDate = moment(csvDate).format('DD-MMM-YYYY, hh:mm a')
        this.setState(
          {
            latestCSVDate: csvDate.replace(",", ' at'),
          },
        );
      })
      .catch((error) => {
        console.log("error in fetching latest csv date" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
        );
      });
  }

  getCurrentMonthAndYear() {
    axios({
      url: this.state.getTCQSDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let currentYear, currentMonth;
        if (
          response.data.chartData &&
          JSON.stringify(response.data.chartData) !== JSON.stringify({})
        ) {
          currentYear = Object.keys(response.data.chartData)[0];
          currentMonth = Object.keys(response.data.chartData[currentYear])[0];
        } else {
          currentYear = null;
          currentMonth = null;
        }
        this.setState({
          currentMonth: currentMonth,
          currentYear: currentYear,
        });
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching TCQS data" + error);
      });
  }

  getLatestData() {
    axios({
      url: this.state.getLatestDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data.chartData;
        let currentYear = null,
          currentMonth = null;

        if (data && JSON.stringify(data) !== JSON.stringify({})) {
          currentYear = Object.keys(data)[0];
          currentMonth =
            currentYear != null ? Object.keys(data[currentYear])[0] : null;
        }

        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching current month and year" + error);
      });
  }

  getCurrentVsForecastData() {
    this.setState({ forcastedVsCurrentMonthDataLoaded: false });
    const url = `${process.env.REACT_APP_BACKEND_API}/dashboard/current-vs-forecasted?filters=`;
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: url + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response.data;
        let data = res.data.chartData;
        let forcastedData = {};

        data.forEach((item) => {
          forcastedData[`${item.month}'${item.year}`] = item.successPercentage;
        });
        this.setState(
          {
            forcastedVsCurrentMonthData: forcastedData,
            forcastedVsCurrentResponseData: data,
            isLoading: false,
            forcastedVsCurrentMonthDataLoaded: true,
            averageAccuracy: res.data.average,
          },
          this.updateDownloadButtonState
        );
      })
      .catch((error) => {
        console.log("error in fetching current vs forecast data " + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  openModal(event, i, status) {
    if (i && i[0]) {
      let data = [];
      let projectIdName = [];
      let milestoneList = [];
      let milestoneOptions = [];
      data = this.state.forcastedVsCurrentResponseData;
      let month = i[0]._view.label.split("'")[0];
      for (let j = 0; j < data.length; j++) {
        if (data[j].month == month) {
          this.setState({
            showModal: status,
            modalTableData: data[j].monthData,
            modalTableFilterData: data[j].monthData,
            modalTableProjectFilterData: data[j].monthData,
            selectedMonthYear: i[0]._view.label,
          });
          for (let k = 0; k < data[j].monthData.length; k++) {
            projectIdName.push(
              data[j].monthData[k]["Project Id"] +
                "(" +
                data[j].monthData[k]["Project Name"] +
                ")"
            );
            let milestone = data[j].monthData[k]["Milestone"];
            if (milestoneList.find((element) => element == milestone)) {
            } else {
              milestoneList.push(milestone);
              milestoneOptions.push({
                value: milestone,
                label: milestone,
                color: "black",
              });
            }
          }
          this.setState(
            {
              projectIdName: projectIdName,
              milestoneOptions: milestoneOptions,
            },
            this.bindEvent
          );
          break;
        }
      }
    }
  }

  getModalTableData() {
    axios({
      url: this.state.getModalTableDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        let data = res.data.chartData;
      })
      .catch((error) => {
        console.log("error in fetching current month and year" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title =
      "Actual project performance vs forecasted model performance for " +
      this.state.selectedMonthYear;

    const headers = [
      [
        "Project ID",
        "Project Name",
        "Forecasted Milestone",
        "Current(" + this.state.selectedMonthYear + ")",
        GenericConstants.MONTH_NAME_NUMBER_MAP[
          this.state.selectedMonthYear.split("'")[0]
        ] == "1"
          ? "Forecast(" +
            "Dec'" +
            (this.state.selectedMonthYear.split("'")[1] - 1).toString() +
            ")"
          : "Forecast(" +
            GenericConstants.MONTH_NUMBER_NAME_MAP[
              GenericConstants.MONTH_NAME_NUMBER_MAP[
                this.state.selectedMonthYear.split("'")[0]
              ] - 1
            ] +
            "'" +
            this.state.selectedMonthYear.split("'")[1] +
            ")",
        "Comparison",
      ],
    ];

    let data = this.state.modalTableFilterData.map((elt) => [
      elt["Project Id"],
      elt["Project Name"],
      elt["Milestone"],
      elt["Actual"],
      elt["Forecast"],
      elt["Comparison"],
    ]);

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "Correct") {
          data.cell.styles.textColor = [45, 197, 147];
        }

        if (data.cell.raw == "Incorrect") {
          data.cell.styles.textColor = "#E45266";
        }

        if (data.cell.raw == "Pending") {
          data.cell.styles.textColor = "#919BA3";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      "Forecasted_vs_Actual_Performance_for_" +
        this.state.selectedMonthYear +
        ".pdf"
    );
  };

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let modalTableFilterData = [];
    let dataArray = [];
    let arr = [];
    if (value === null || value === "") {
      this.setState(
        {
          modalTableFilterData: this.state.modalTableData,
          modalTableProjectFilterData: this.state.modalTableData,
        },
        this.onSelect(arr)
      );
    } else {
      this.state.modalTableData.map((data) => {
        dataArray.push(data);
        if (
          data["Project Id"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          modalTableFilterData.push(data);
        }
        if (dataArray.length === this.state.modalTableData.length) {
          this.setState(
            {
              modalTableFilterData: modalTableFilterData,
              modalTableProjectFilterData: modalTableFilterData,
            },
            this.onSelect(arr)
          );
        }
      });
    }
  }

  updateDownloadButtonState() {
    const { forcastedVsCurrentMonthData, forcastedVsCurrentMonthDataLoaded } =
      this.state;

    this.setState({
      downloadEnabled:
        forcastedVsCurrentMonthDataLoaded &&
        !(
          forcastedVsCurrentMonthData == null ||
          JSON.stringify(forcastedVsCurrentMonthData) === JSON.stringify({})
        ),
    });
  }

  downloadChartPdf(elementId, fileName) {
    setTimeout(() => {
      const input = document.getElementById(elementId);

      html2canvas(input, { scale: 5 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("portrait", "pt", [
          canvas.width + 20,
          canvas.height + 20,
        ]);
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.setFontSize(width / 80);
        pdf.text(40, 100, "Current vs Forecasted Programme Performance");
        pdf.text(
          40,
          180,
          "Based on data uploaded for " +
            this.props.month +
            " " +
            this.props.year
        );
        pdf.addImage(imgData, "JPEG", 20, 220, width, canvas.height - 1280);
        pdf.save(fileName);
        this.setState({ pdfDownloading: false });
        // });
      });
    }, 1000);
  }

  downloadAllProgramsDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    let sheets = {};
    let sheetNames = [];
    let forecastedData = [];
    forecastedData.push(this.state.forcastedVsCurrentMonthData);

    if (this.state.forcastedVsCurrentMonthData) {
      const ws1 = XLSX.utils.json_to_sheet(
        PredictionDataConverter.convertCurrentVsForecast(forecastedData)
      );
      sheets["CurrentVsForecasted"] = ws1;
      sheetNames.push("CurrentVsForecasted");
    }
    const wb = {
      Sheets: sheets,
      SheetNames: sheetNames,
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "Current_vs_Forecasted" + ".xlsx");
  };

  downloadPdfClicked = () => {
    this.setState({ pdfDownloading: true });
    this.downloadChartPdf(
      "current-vs-forecasted-content-div",
      "Current_Vs_Forecasted" + ".pdf"
    );
  };

  downloadExcelClicked = () => {
    this.downloadAllProgramsDataExcel();
  };

  toggleDownload = () => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen });
  };

  downloadBothPdfAndExcel = () => {
    this.setState({ pdfDownloading: true });
    this.downloadChartPdf(
      "current-vs-forecasted-content-div",
      "Current_Vs_Forecasted" + ".pdf"
    );
    this.downloadAllProgramsDataExcel();
  };

  getSortDesOrder(prop) {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  getSortAscOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  arraySortAscending(key) {
    let array = this.state.modalTableFilterData;
    if (array !== null) {
      array.sort(this.getSortAscOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: "",
      moreClickKey: key,
    });
  }

  arraySortDescending(key) {
    let array = this.state.modalTableFilterData;
    if (array !== null) {
      array.sort(this.getSortDesOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: key,
      moreClickKey: "",
    });
  }

  onMilestoneSelect(selectedList, selectedItem) {
    this.setState({
      selectedMilestoneValue: selectedList,
      lessClickKey: null,
      moreClickKey: null,
    });
    let tableData = this.state.modalTableProjectFilterData;
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.onComparisonSelectSecond(
        tableData,
        this.state.selectedComparisonValue
      );
    } else if (selectedList.length == 0) {
      this.onComparisonSelectSecond(
        tableData,
        this.state.selectedComparisonValue
      );
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["Milestone"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.onComparisonSelectSecond(
            modalTableFilterData,
            this.state.selectedComparisonValue
          );
        }
      }
    }
  }

  onComparisonSelect(selectedList, selectedItem) {
    this.setState({
      selectedComparisonValue: selectedList,
      lessClickKey: null,
      moreClickKey: null,
    });
    let tableData = this.state.modalTableProjectFilterData;
    let modalTableFilterData = [];
    let dataArray = [];
    if (selectedList == null) {
      this.onMilestoneSelectSecond(
        tableData,
        this.state.selectedMilestoneValue
      );
    } else if (selectedList.length == 0) {
      this.onMilestoneSelectSecond(
        tableData,
        this.state.selectedMilestoneValue
      );
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["Comparison"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.onMilestoneSelectSecond(
            modalTableFilterData,
            this.state.selectedMilestoneValue
          );
        }
      }
    }
  }

  onMilestoneSelectSecond(tableData, selectedList, selectedItem) {
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: tableData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: tableData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["Milestone"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  onComparisonSelectSecond(tableData, selectedList, selectedItem) {
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: tableData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: tableData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["Comparison"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  onSelect(selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList });
    let modalTableFilterData = [];
    let dataArray = [];
    if (selectedList == null) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (
            data["Comparison"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  onRemove(selectedList, removedItem) {
    console.log(selectedList);
    this.setState({ selectedValue: selectedList });
    let modalTableFilterData = [];
    let dataArray = [];
    if (
      selectedList == null &&
      JSON.stringify(selectedList) != JSON.stringify([])
    ) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (
            data["Comparison"]
              .toLowerCase()
              .indexOf(selectedList[i].name.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 0);
  }

  getFiltersData() {
    axios({
      url: this.state.getFiltersDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const filtersData = response.data.filtersData;
        this.setState({ filtersAPIData: filtersData });
      })
      .catch((error) => {
        console.log("error in fetching filters data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getCsvColumnsMapping() {
    axios({
      url: this.state.getCsvColumnsMappingRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const csvColumnMapping = response.data.csvColumnMapping;
        this.setState({ csvColumnMapping: csvColumnMapping });
      })
      .catch((error) => {
        console.log("error in fetching csv columns mapping data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  updateFilters(name, list) {
    let selectedFiltersData = this.state.selectedFiltersData;
    if (selectedFiltersData == null) {
      selectedFiltersData = {};
    }
    if (list == null || list.length == 0) {
      delete selectedFiltersData[name];
    } else {
      selectedFiltersData[name] = list;
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
  }

  removeFilter(filterName, filterVal) {
    let selectedFiltersData = this.state.selectedFiltersData;
    let filterList = selectedFiltersData[filterName];
    selectedFiltersData[filterName] = filterList.filter((item) => {
      return item != filterVal;
    });

    if (
      selectedFiltersData[filterName] == null ||
      selectedFiltersData[filterName].length == 0
    ) {
      delete selectedFiltersData[filterName];
    }

    this.setState({ selectedFiltersData: selectedFiltersData });
    this.getCurrentMonthAndYear();
    this.getCurrentVsForecastData();

    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(this.state.selectedFiltersData)
    );
  }

  closeFilterPaneOnApplying() {
    this.setState({ isFilterOpen: false });
  }

  toggleFilter() {
    const isFilterOpen = this.state.isFilterOpen;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  async clearFilters() {
    await this.setState({ selectedFiltersData: {} });
    this.applyFilters();
  }

  applyFilters() {
    this.closeFilterPaneOnApplying();
    this.getCurrentVsForecastData();

    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(this.state.selectedFiltersData)
    );
  }

  keepFilters() {
    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    this.setState(
      {
        selectedFiltersData: selectedFiltersData,
        showKeepFiltersModal: false,
        showMatchingProjects: true,
        totalProjectLoading: true,
      },
      this.applyFilters
    );
  }

  closeKeepFiltersModal() {
    this.getAllProjectsData();
    this.getLatestData();
    this.getTCQSData();
    this.getAllMonthsData();
    this.getProjectCount();
    this.getLineChartData();
    this.fetchProjectNames();
    this.setState({ showKeepFiltersModal: false, totalProjectLoading: true });
    sessionStorage.setItem("selectedFiltersData", "{}");
  }

  render() {
    const { isFilterOpen, csvColumnMapping, filtersAPIData } = this.state;

    let uiFilterData, filterSection;
    let filtersData = filtersAPIData;

    if (filtersData) {
      const filterNames = Object.keys(filtersData);
      uiFilterData = filterNames.map((item, index) => {
        const filterName = item;
        const filterValues = filtersData[filterName].filter((itm) => {
          return itm != null && itm.trim() != "";
        });

        return (
          <span
            key={`span${index}`}
            style={{
              float: index % 2 === 0 ? "left" : "right",
            }}
          >
            <Filter
              key={index}
              filterValues={filterValues}
              csvColumnMapping={this.state.csvColumnMapping}
              filterData={this.state.selectedFiltersData}
              filterName={filterName}
              setSelectedOptions={(name, list) =>
                this.updateFilters(name, list)
              }
            ></Filter>
          </span>
        );
      });
    }

    if (uiFilterData) {
      filterSection = <div>{uiFilterData}</div>;
    }

    const filters = this.state.selectedFiltersData;
    let filterSummaryData, list, singleFilter;

    let filtersArray = [];

    Object.entries(filters).forEach((filterItem) => {
      filterItem[1].forEach((filter) => {
        filtersArray.push({
          filterCategory: filterItem[0],
          filter: filter,
        });
      });
    });

    const getSingleFilterObject = (length, filterCategory, filter) => {
      return (
        <BootstrapTooltip title={filter}>
          <span>
            <div
              className="filter-summary-inner"
              style={
                length > this.state.tablet - 1
                  ? { display: "none" }
                  : { display: "inline" }
              }
            >
              {filter.length < 15 ? filter : filter.substr(0, 14) + "..."}{" "}
              <FaTimes
                className="filter-delete"
                onClick={() => this.removeFilter(filterCategory, filter)}
              ></FaTimes>
            </div>
          </span>
        </BootstrapTooltip>
      );
    };

    let individualFilterArray = [];
    for (let i = 0; i < filtersArray.length; i++) {
      if (filtersArray[i]) {
        individualFilterArray.push(
          getSingleFilterObject(
            i,
            filtersArray[i].filterCategory,
            filtersArray[i].filter
          )
        );
      }
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <React.Fragment>
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        {this.state.showKeepFiltersModal === true ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Retain Filters</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div>Would you like to retain the filters applied?</div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                id="yes-no-button"
                onClick={this.keepFilters}
              >
                YES
              </Button>
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeKeepFiltersModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        <Header
          id="currhead"
          open={this.props.open}
          setOpen={this.props.setOpen}
        >
          <Row>
            <Col xs={8}>
              <div className="currentClass">
                Current vs Forecasted Programme Performance
              </div>
              <div className="basedClass">
                <span className="year-month-dashboard">
                  {GenericConstants.MONTH_MAPPING[this.props.month]
                    ? GenericConstants.MONTH_MAPPING[this.props.month]
                    : this.props.month}{" "}
                  {this.props.year}
                </span>
                {this.props.month ? " snapshot" : ""}, <span style={{color:'black'}}>Uploaded On:</span> {this.state.latestCSVDate}
                <br />
                <span style={{ float: "left" }}>{"Live Projects Only"}</span>
              </div>
            </Col>
            <Col xs={4}>
              <ButtonDropdown
                isOpen={this.state.isDownloadOpen}
                toggle={this.toggleDownload}
                color="primary"
                className="button-dropdown-dashboard"
              >
                <DropdownToggle
                  caret
                  size="sm"
                  color="primary"
                  disabled={
                    !this.state.downloadEnabled || this.state.pdfDownloading
                      ? true
                      : false
                  }
                >
                  {this.state.pdfDownloading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span> Downloading...
                    </span>
                  ) : (
                    <span>
                      <GetAppIcon fontSize="small" />
                      <span className="download-text-dashboard"> Download</span>
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu size="sm">
                  <DropdownItem
                    onClick={this.downloadPdfClicked}
                    className="dropdown-item-dashboard"
                    size="sm"
                  >
                    Pdf
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadExcelClicked}
                  >
                    Xlsx
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadBothPdfAndExcel}
                  >
                    Both
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </Header>
        <Row>
          <Col xs={2} md={2}></Col>
          <Col xs={5} md={5}>
            {
              <div
                className="filter-summary"
                style={{
                  width: "auto",
                  background: "none",
                  marginBottom: "0px",
                }}
              >
                <div className="filter-summary-title">
                  {/* Applied Filters */}
                </div>
                {/* {filterSummaryData} */}
                {individualFilterArray}
                <div
                  className="filter-summary-inner"
                  style={
                    individualFilterArray.length <= this.state.tablet
                      ? { display: "none" }
                      : { display: "inline" }
                  }
                >
                  {individualFilterArray.length > this.state.tablet
                    ? individualFilterArray.length - this.state.tablet + " +"
                    : ""}
                </div>
              </div>
            }
          </Col>
          <Col xs={3} md={3}></Col>
          <Col xs={2} md={2}>
            {filtersData && csvColumnMapping && (
              <div style={{ position: "relative" }}>
                <div className="filter">
                  <CardHeader className="filter-header">
                    <span onClick={this.toggleFilter}>
                      <b>All Filters </b>
                      <span style={{ color: "#BD2967" }}>
                        {!isFilterOpen && <FaAngleDown></FaAngleDown>}{" "}
                        {isFilterOpen && <FaAngleUp></FaAngleUp>}
                      </span>
                    </span>
                  </CardHeader>
                  <Collapse
                    isOpen={isFilterOpen}
                    className="filter-main"
                    timeout={0}
                  >
                    <Row>
                      <Col>
                        <CancelIcon
                          className="cancel-close-icon"
                          onClick={
                            individualFilterArray.length !== 0
                              ? this.closeFilterConfirmation
                              : this.noFilterSelectedCloseFilter
                          }
                          id="cancel-filter"
                          fontSize="small"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {filterSection && (
                          <div className="filter-div">{filterSection}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="filter-buttons">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.clearFilters();
                              this.setState({
                                showMatchingProjects: false,
                                totalProjectLoading: true,
                              });
                            }}
                            outline
                          >
                            Reset All
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.applyFilters();
                              this.setState({
                                showMatchingProjects: true,
                                totalProjectLoading: true,
                              });
                            }}
                          >
                            Apply Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row id="current-vs-forecasted-content-div">
          <Col>
            {!this.state.forcastedVsCurrentMonthDataLoaded && (
              <div>
                <PageSpinner />
              </div>
            )}
            {this.state.forcastedVsCurrentMonthDataLoaded &&
              this.state.forcastedVsCurrentMonthData &&
              JSON.stringify(this.state.forcastedVsCurrentMonthData) !==
                JSON.stringify({}) && (
                <ForcastedVsCurrentMonthChart
                  chartData={this.state.forcastedVsCurrentMonthData}
                  openModal={this.openModal}
                  averageAccuracy={this.state.averageAccuracy}
                ></ForcastedVsCurrentMonthChart>
              )}
            {this.state.forcastedVsCurrentMonthDataLoaded &&
              (this.state.forcastedVsCurrentMonthData == null ||
                JSON.stringify(this.state.forcastedVsCurrentMonthData) ==
                  JSON.stringify({})) && (
                <div className="no-data-available-dashboard">
                  No project data available for dashboard. Please upload data to
                  view dashboard
                </div>
              )}
            <div>
              {this.state.showModal ? (
                <div>
                  <Modal
                    isOpen="true"
                    centered="true"
                    size="lg"
                    className="current-forecasted-modal"
                  >
                    <ModalBody
                      id="show-key-activities-modal"
                      className="tcqs-current-modal-body"
                    >
                      <CancelIcon
                        onClick={this.closeModal}
                        className="cancel-close-icon"
                      />
                      <Row>
                        <Col>
                          <center>
                            <div
                              id="key-tasks-to-text"
                              style={{
                                color: "#252525",
                                fontSize: "0.94rem",
                                marginTop: "-0.92rem",
                              }}
                            >
                              Actual project performance vs forecasted model
                              performance for {this.state.selectedMonthYear}
                              <span
                                data-title="The table represents the list of projects with their milestone status forecasted in the previous month and their status in the current month. The comparison column can be filtered for correct, incorrect or not applicable categories​"
                                data-title-xmg
                              >
                                <MdInfo size={20} className="c-info"></MdInfo>
                              </span>
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <div className="form-input-wrapper-current-vs-forecasted">
                              <Autocomplete
                                options={this.state.projectIdName}
                                onInputChange={this.searchByProject}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Project"
                                  />
                                )}
                                size="small"
                              />
                              {/*
                            <input
                              type="text"
                              value={this.state.value}
                              onChange={this.searchByProject}
                              className="form-input-textbox"
                              placeholder="Search By Project ID"
                            />
                            */}
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <div>
                              <Table
                                responsive
                                className="current-vs-forecasted table-scroll-modal table-modal table table-md table-custom table-current-vs-forecasted"
                                id="table-task-list"
                                style={{ fontSize: "0.7rem" }}
                              >
                                <thead className="table-head">
                                  <tr>
                                    <th className="project-id-col other-col-comp">
                                      <span>Project ID</span>
                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending("Project Id");
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Project Id" ===
                                          this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Project Id");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Project Id" ===
                                          this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                    <th className="other-col-comp">
                                      <span>Project Name</span>
                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending(
                                          "Project Name"
                                        );
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Project Name" ===
                                          this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Project Name");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Project Name" ===
                                          this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                    <th
                                      style={{
                                        paddingBottom: "0.2rem",
                                        paddingTop: "0.2rem",
                                        width: "12vw",
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title="Forecasted Milestone"
                                        placement="top-start"
                                      >
                                        <Select
                                          closeMenuOnSelect={false}
                                          blurInputOnSelect={false}
                                          isMulti
                                          options={this.state.milestoneOptions}
                                          styles={colourStyles}
                                          value={
                                            this.state.selectedMilestoneValue
                                          }
                                          onChange={this.onMilestoneSelect}
                                          placeholder="Forecasted Milestone"
                                          isSearchable
                                        />
                                      </BootstrapTooltip>

                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending("Milestone");
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Milestone" ===
                                          this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Milestone");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Milestone" ===
                                          this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                    <th
                                      style={{
                                        paddingBottom: "0.2rem",
                                        paddingTop: "0.2rem",
                                        width: "12vw",
                                      }}
                                    >
                                      <BootstrapTooltip
                                        title="Comparison"
                                        placement="top-start"
                                      >
                                        <Select
                                          closeMenuOnSelect={false}
                                          blurInputOnSelect={false}
                                          isMulti
                                          options={colourOptions}
                                          styles={colourStyles}
                                          value={
                                            this.state.selectedComparisonValue
                                          }
                                          onChange={this.onComparisonSelect}
                                          placeholder="Comparison"
                                          isSearchable
                                        />
                                      </BootstrapTooltip>
                                      {/*
                                    <Multiselect
                                      options={this.state.options} // Options to display in the dropdown
                                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                      onSelect={this.onSelect} // Function will trigger on select event
                                      onRemove={this.onRemove} // Function will trigger on remove event
                                      displayValue="fullName" // Property name to display in the dropdown options
                                      style={this.style}
                                      placeholder="Comparison"
                                    />
                                    */}
                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending("Comparison");
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Comparison" ===
                                          this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Comparison");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Comparison" ===
                                          this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                    <th className="centre-align-data other-col-comp">
                                      <span>
                                        Current Status{" "}
                                        <span style={{ color: "#919BA3" }}>
                                          ({this.state.selectedMonthYear})
                                        </span>
                                      </span>
                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending("Actual");
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Actual" === this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Actual");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Actual" === this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                    <th className="centre-align-data other-col-comp">
                                      <span>
                                        Forecasted Status{" "}
                                        <span style={{ color: "#919BA3" }}>
                                          (
                                          {GenericConstants
                                            .MONTH_NAME_NUMBER_MAP[
                                            this.state.selectedMonthYear.split(
                                              "'"
                                            )[0]
                                          ] == "1"
                                            ? "Dec'" +
                                              (
                                                this.state.selectedMonthYear.split(
                                                  "'"
                                                )[1] - 1
                                              ).toString()
                                            : GenericConstants
                                                .MONTH_NUMBER_NAME_MAP[
                                                GenericConstants
                                                  .MONTH_NAME_NUMBER_MAP[
                                                  this.state.selectedMonthYear.split(
                                                    "'"
                                                  )[0]
                                                ] - 1
                                              ] +
                                              "'" +
                                              this.state.selectedMonthYear.split(
                                                "'"
                                              )[1]}
                                          )
                                        </span>
                                      </span>
                                      {/* <ExpandLessIcon
                                      onClick={() => {
                                        this.arraySortDescending("Forecast");
                                      }}
                                      fontSize="small"
                                      className="expand-less-icon-sorting"
                                      style={{
                                        color:
                                          "Forecast" === this.state.lessClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    <ExpandMoreIcon
                                      onClick={() => {
                                        this.arraySortAscending("Forecast");
                                      }}
                                      fontSize="small"
                                      className="expand-more-icon-sorting"
                                      style={{
                                        color:
                                          "Forecast" === this.state.moreClickKey
                                            ? "red"
                                            : "black",
                                      }}
                                    /> */}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.modalTableFilterData ? (
                                    this.state.modalTableFilterData.length >=
                                    1 ? (
                                      this.state.modalTableFilterData.map(
                                        (activityObject) => (
                                          <tr>
                                            <td className="table-data-bold project-id-col">
                                              {activityObject["Project Id"]}
                                            </td>
                                            <td className="table-data-bold">
                                              {activityObject["Project Name"]}
                                            </td>
                                            <td
                                              className="table-data-black"
                                              style={{
                                                width: "12vw",
                                                paddingLeft: "2rem",
                                              }}
                                            >
                                              <span
                                                style={{ marginLeft: "0.7rem" }}
                                              >
                                                {activityObject["Milestone"]}
                                              </span>
                                            </td>
                                            <td
                                              style={{
                                                color:
                                                  activityObject[
                                                    "Comparison"
                                                  ] === "Correct"
                                                    ? "#2DC593"
                                                    : activityObject[
                                                        "Comparison"
                                                      ] === "Incorrect"
                                                    ? "#E45266"
                                                    : "#919BA3",
                                                width: "12vw",
                                                paddingLeft: "2rem",
                                              }}
                                            >
                                              <span
                                                style={{ marginLeft: "0.7rem" }}
                                              >
                                                {activityObject["Comparison"]}
                                              </span>
                                            </td>
                                            <td className="table-data-black centre-align-data">
                                              {activityObject["Actual"] ? (
                                                activityObject["Actual"] !=
                                                "NA" ? (
                                                  <div
                                                    className={
                                                      activityObject[
                                                        "Actual"
                                                      ] === "R"
                                                        ? "red-circle"
                                                        : activityObject[
                                                            "Actual"
                                                          ] === "GR"
                                                        ? "grey-circle"
                                                        : activityObject[
                                                            "Actual"
                                                          ] === "A"
                                                        ? "amber-circle"
                                                        : activityObject[
                                                            "Actual"
                                                          ] === "B"
                                                        ? "blue-circle"
                                                        : "green-circle"
                                                    }
                                                  ></div>
                                                ) : (
                                                  "-"
                                                )
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                            <td className="table-data-black centre-align-data">
                                              {activityObject["Forecast"] ? (
                                                activityObject["Forecast"] !=
                                                "NA" ? (
                                                  <div
                                                    className={
                                                      activityObject[
                                                        "Forecast"
                                                      ] === "R"
                                                        ? "red-circle"
                                                        : activityObject[
                                                            "Forecast"
                                                          ] === "GR"
                                                        ? "grey-circle"
                                                        : activityObject[
                                                            "Forecast"
                                                          ] === "A"
                                                        ? "amber-circle"
                                                        : activityObject[
                                                            "Forecast"
                                                          ] === "B"
                                                        ? "blue-circle"
                                                        : "green-circle"
                                                    }
                                                  ></div>
                                                ) : (
                                                  "-"
                                                )
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr></tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td style={{ width: "15vw" }}></td>
                                      <td style={{ width: "26vw" }}>
                                        No data available for{" "}
                                        {this.state.selectedMonthYear}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <Button
                              color="primary"
                              onClick={this.downloadTablePdf}
                              size="sm"
                              id="download-list-key-actvity"
                              disabled={
                                this.state.modalTableFilterData &&
                                JSON.stringify(
                                  this.state.modalTableFilterData
                                ) !== JSON.stringify([])
                                  ? false
                                  : true
                              }
                            >
                              {this.state.isDownloading ? (
                                <div>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Loading...</span>{" "}
                                  Downloading...
                                </div>
                              ) : (
                                <div>
                                  <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                                </div>
                              )}
                            </Button>
                          </center>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CurrentVsForecastedPage;
