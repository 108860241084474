import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { MdInfo } from "react-icons/md";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import AMP7Icon from "../../../../images/summary-icons/amp7.svg";
import OverspendListIcon from "../../../../images/summary-icons/overspend_list.svg";
import TotalSanctionIcon from "../../../../images/summary-icons/total_sanction.svg";
import AvgValueRemainingIcon from "../../../../images/summary-icons/avg_value_remaining.svg";
import IdentifyIcon from "../../../../images/summary-icons/identify.svg";
import DefineIcon from "../../../../images/summary-icons/define.svg";
import DeliverIcon from "../../../../images/summary-icons/deliver.svg";
import ClosureIcon from "../../../../images/summary-icons/closure.svg";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class OverviewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  viewDetails() {
    if (
      this.props.projectsOverviewData &&
      this.props.projectsOverviewData.overspent
    ) {
      this.props.viewProjectDetails();
    }
  }

  render() {
    let projectsOverviewData = this.props.projectsOverviewData;
    let projectsCompletedByPhaseData = this.props.projectsCompletedByPhaseData;
    let amp7OptimisedVal,
      overspent,
      totalSanction,
      avgPerctValRemaining,
      identifyCount,
      defineCount,
      deliverCount,
      closureCount;

    if (projectsOverviewData) {
      amp7OptimisedVal = projectsOverviewData.amp7OptimisedVal;
      overspent = projectsOverviewData.overspent;
      totalSanction = projectsOverviewData.totalSanction;
      avgPerctValRemaining = projectsOverviewData.avgPerctValRemaining;
    }

    if (projectsCompletedByPhaseData) {
      identifyCount = projectsCompletedByPhaseData.identifyCount;
      defineCount = projectsCompletedByPhaseData.defineCount;
      deliverCount = projectsCompletedByPhaseData.deliverCount;
      closureCount = projectsCompletedByPhaseData.closureCount;
    }

    return (
      <Row style={{ width: "100%" }}>
        <Col style={{ paddingRight: "0.3rem" }}>
          <div
            className="chart-top"
            style={{
              padding: "0.55rem",
              fontSize: "0.9rem",
              width: "100%",
              marginLeft: "0.95rem",
              marginRight: "0.95rem",
            }}
          >
            <Row>
              <Col
                style={{
                  fontSize: "0.9rem",
                  textAlign: "left",
                }}
              >
                Programme overview
                <BootstrapTooltip
                  placement="right-start"
                  title="The overview information represents key numbers as part of the summary of the programme or a sub-programme"
                >
                  <span>
                    <MdInfo
                      size={20}
                      className="c-info"
                      style={{ marginTop: "-0.5rem" }}
                    ></MdInfo>
                  </span>
                </BootstrapTooltip>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="right-border-col">
                <img
                  src={AMP7Icon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">£ {amp7OptimisedVal}</b>
                  <div className="f-10"> AMP7 optimised value​</div>
                </div>
              </Col>
              <Col className="right-border-col">
                <img
                  src={OverspendListIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div
                  className={overspent > 0 ? 'summary-icon-text pointer' : 'summary-icon-text'}
                  onClick={() => this.viewDetails()}
                >
                 <b className="f-12">{overspent > 0 ? <a className="list-of-proj-hyperlink"><u> {overspent} </u></a>: overspent }</b>
                  <div className="f-10"> Projects on the overspend list​</div>
                </div>
              </Col>
              <Col className="right-border-col">
                <img
                  src={TotalSanctionIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">£ {totalSanction}​</b>
                  <div className="f-10"> Total Sanction​ ​​ </div>
                </div>
              </Col>
              <Col>
                <img
                  src={AvgValueRemainingIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">{avgPerctValRemaining} %</b>
                  <div className="f-10"> Average value remaining​ </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col style={{ paddingLeft: "0.3rem" }}>
          <div
            className="chart-top"
            style={{
              padding: "0.55rem",
              fontSize: "0.9rem",
              width: "100%",
              marginLeft: "0.95rem",
              marginRight: "0.95rem",
            }}
          >
            <Row>
              <Col style={{ fontSize: "0.9rem", textAlign: "left" }}>
              Programme maturity by phase
                <BootstrapTooltip
                  placement="right-start"
                  title="The following information represents the stage each project is up to in its project lifecycle"
                >
                  <span>
                    <MdInfo
                      size={20}
                      className="c-info"
                      style={{ marginTop: "-0.5rem" }}
                    ></MdInfo>
                  </span>
                </BootstrapTooltip>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="right-border-col">
                <img
                  src={IdentifyIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">{identifyCount}</b>
                  <div className="f-10"> Identify </div>
                </div>
              </Col>
              <Col className="right-border-col">
                <img
                  src={DefineIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">{defineCount}</b>
                  <div className="f-10"> Define </div>
                </div>
              </Col>
              <Col className="right-border-col">
                <img
                  src={DeliverIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">{deliverCount}</b>
                  <div className="f-10"> Deliver </div>
                </div>
              </Col>
              <Col>
                <img
                  src={ClosureIcon}
                  alt=""
                  className="img-fuid"
                  id="icon-summary"
                />
                <div className="summary-icon-text">
                  <b className="f-12">{closureCount}</b>
                  <div className="f-10"> Close </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default OverviewChart;
