import React from "react";
import PropTypes from "prop-types";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import "../../styles/components/Filter.scss";

function FilterHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <input
      type="checkbox"
      checked={rowCount > 0 && numSelected === rowCount}
      onChange={onSelectAllClick}
    />
  );
}

FilterHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

Filter.propTypes = {
  filterValues: PropTypes.array,
  filterData: PropTypes.object,
  setSelectedOptions: PropTypes.func,
  csvColumnMapping: PropTypes.object,
};

export default function Filter(props) {
  const {
    filterValues,
    filterData,
    setSelectedOptions,
    filterName,
    csvColumnMapping,
  } = props;
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [filterDisplayValues, setFilterDisplayValues] = React.useState(
    filterValues
  );
  let selected = filterData[filterName];

  if (selected == undefined) {
    selected = [];
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filterDisplayValues.map((val) => val);
      setSelectedOptions(filterName, newSelecteds);
      return;
    }
    setSelectedOptions(filterName, []);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelectedOptions(filterName, newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const searchByName = (event) => {
    let value = event.target.value;
    let filterDataArraySearch = [];
    let dataArray = [];

    if (value === null || value === "") {
      setFilterDisplayValues(filterValues);
    } else {
      filterValues.map((data) => {
        dataArray.push(data);
        if (data.toLowerCase().includes(value.toLowerCase())) {
          filterDataArraySearch.push(data);
        }
        if (dataArray.length === filterValues.length) {
          setFilterDisplayValues(filterDataArraySearch);
        }
      });
    }
  };

  return (
    <div
      className="filter-top"
      onMouseLeave={(e) => {
        setShowDropDown(false);
        setFilterDisplayValues(filterValues);
      }}
    >
      {csvColumnMapping && (
        <div>
          <div className="filter-name">{csvColumnMapping[filterName]}</div>
          <div
            onClick={(e) => {
              if (!showDropDown) {
                setShowDropDown(true);
                setFilterDisplayValues(filterValues);
              } else {
                setShowDropDown(false);
                setFilterDisplayValues(filterValues);
              }
            }}
            className="filter-section"
            style={{ border: showDropDown ? "1px solid #268CD1" : "" }}
          >
            {selected.length == 0 && <div className="fl">Select Filter</div>}
            {selected.length > 0 && (
              <div className="fl">{selected.length} Selected</div>
            )}
            <div className="filter-action-div">
              {!showDropDown && (
                <FaAngleDown
                  onClick={(e) => setShowDropDown(true)}
                ></FaAngleDown>
              )}
              {showDropDown && (
                <FaAngleUp onClick={(e) => setShowDropDown(false)}></FaAngleUp>
              )}
            </div>
          </div>
          {showDropDown && (
            <div className="filter-outer">
              {filterValues.length != 0 && (
                <div>
                  <div className="filter-select-all">
                    <span>
                      <span className="filter-head-span">
                        <FilterHead
                          numSelected={selected.length}
                          onSelectAllClick={handleSelectAllClick}
                          rowCount={filterDisplayValues.length}
                        />
                      </span>
                      &nbsp;
                      <span className="select-all-text">Select All</span>
                    </span>
                    <span className="filter-search-input-wrapper">
                      <input
                        type="text"
                        onChange={searchByName}
                        placeholder="Search"
                        className="filter-search-input-textbox"
                      />
                    </span>
                  </div>
                  <div className="filter-options">
                    {filterDisplayValues.map((row, index) => {
                      const isItemSelected = isSelected(row);
                      return (
                        <div key={index}>
                          <input
                            key={"x" + index}
                            type="checkbox"
                            className="chkBox"
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, row)}
                          />
                          &nbsp;{row}
                        </div>
                      );
                    })}
                  </div>
                  <div className="filter-bottom"></div>
                </div>
              )}
              {filterValues.length == 0 && (
                <div>
                  <div className="filter-no-data">No data</div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
