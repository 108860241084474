function Activities(activities) {
    this.activities = activities;
  }

Activities.prototype.toArray = function () {
	var activities = [];

	for (var name in this.activities) {
		if (this.activities.hasOwnProperty(name)) {
			var activity = this.activities[name];
			activity.name = name;
			activities.push(activity);
		}
	}

	return activities;
};

Activities.prototype.interchanges = function () {
	var interchangeActivities = this.toArray();

	return interchangeActivities.filter(function (activity) {
		return activity.marker[0].marker === 'interchange';
	});
};

Activities.prototype.normalActivities = function () {
	var activities = this.toArray();

	var activitieActivities = activities.filter(function (activity) {
		return activity.marker[0].marker !== 'interchange';
	});

	var activitieMarkers = [];

	activitieActivities.forEach(function (activity) {
		activity.marker.forEach(function (marker) {
			activitieMarkers.push({
				name: activity.name,
				line: marker.line,
				x: activity.x,
				y: activity.y,
				color: marker.color,
				shiftX: marker.shiftX,
				shiftY: marker.shiftY,
				labelPos: activity.labelPos,
			});
		});
	});

	return activitieMarkers;
};

export default function(activities) {
    return new Activities(activities);
  }