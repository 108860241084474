import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class ProjectTCQIChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Health Score",
            backgroundColor: "#d5dadc",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
          {
            label: "Health Score",
            backgroundColor: "#239bec",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
        ],
      },
    };
  }

  render() {
    const chartData = this.state.data;
    const tcqiChartData = this.props.tcqiChartData;
    // tcqiChartData will have 2 months data
    if (tcqiChartData) {
      const dataMonths = Object.keys(tcqiChartData);
      for (let i = 0; i < dataMonths.length; i++) {
        const values = Object.values(
          tcqiChartData[dataMonths[i]]
        );
        chartData.datasets[i].data =  values.map((item) => item || 0);
        chartData.datasets[i].label = dataMonths[i];
      }
      const labels = Object.keys(tcqiChartData[dataMonths[0]]);
      chartData.labels = labels.map(
        (item) => item[0].toUpperCase() + item.slice(1)
      );
    }
    const options = {
      responsive: false,
      title: {
        display: false,
        text: "TCQI trend",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: true,
        position: "bottom",
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return value + "%";
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
    };
    return (
      <Row>
        <Col>
          <center>
            <div style={{ overflowX: "auto" }}>
              <Bar
                data={chartData}
                width={670}
                height={400}
                options={options}
              />
            </div>
          </center>
        </Col>
      </Row>
    );
  }
}

export default ProjectTCQIChart;
