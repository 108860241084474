import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import { MdInfo } from "react-icons/md";

import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: ["Top 5", "Bottom 5", "Aggregate", "All Performers"],
      selectedActivity: "Top 5",
      selectedMilestone: "All Milestones",
      GRadioChecked: true,
      BRadioChecked: false,
    };
    this.onActivityChange = this.onActivityChange.bind(this);
    this.onRadioCheck = this.onRadioCheck.bind(this);
  }

  getColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  formatData(data) {
    if (data) {
      let chartData = data.chartData
      if (this.state.GRadioChecked) {
        chartData = data.chartData["G"];
      } else {
        chartData = data.chartData["B"];
      }
      let formattedData = {};
      if (chartData && this.props.csvColumnMapping) {
        Object.entries(chartData).forEach((series, seriesindex) => {
          if (series[0] != "totalDiff" && series[0] != "avgDiff") {
            formattedData[series[0]] = {};
            Object.entries(series[1]).forEach((yearItem, index) => {
              Object.entries(yearItem[1]).forEach((monthItem, index) => {
                let key = monthItem[0] + " - " + String(yearItem[0]).substr(2);
                /*
                formattedData[series[0]][key] =
                  Math.round(
                    (((monthItem[1]["G"] + monthItem[1]["B"]) * 100) /
                      monthItem[1]["T"]) *
                      100
                  ) / 100;
                */
                if (this.state.GRadioChecked) {
                  formattedData[series[0]][key] = monthItem[1]["G"];
                } else {
                  formattedData[series[0]][key] = monthItem[1]["B"];
                }
              });
            });
          }
        });
      }
      return formattedData;
    }
  }

  getColorsList(data) {
    let list = [];

    for (let i = 0; i < Object.keys(data).length; i++) {
      list.push(this.getColors());
    }

    list = [
      "rgb(104, 46, 69)",
      "rgb(239, 50, 113)",
      "rgb(190, 82, 216)",
      "rgb(193, 253, 61)",
      "rgb(204, 171, 148)",
      "rgb(164, 72, 0)",
      "rgb(51, 102, 153)",
      "rgb(127, 141, 32)",
      "rgb(124, 231, 250)",
      "rgb(156, 234, 164)",
      "rgb(153, 153, 102)",
      "rgb(255, 102, 0)",
      "rgb(204, 51, 0)",
      "rgb(0, 0, 0)",
    ];

    return list;
  }

  onActivityChange = (event) => {
    this.setState({
      selectedActivity: event.target.value,
      selectedMilestone: "All Milestones",
    });
  };

  onMilestoneChange = (event) => {
    this.setState({
      selectedMilestone: event.target.value,
    });
  };

  onRadioCheck = (e) => {
    if (e.target.value === "G") {
      this.setState({ GRadioChecked: true, BRadioChecked: false });
    } else {
      this.setState({ GRadioChecked: false, BRadioChecked: true });
    }
  };

  render() {
    let finalChartData = null;
    let milestoneDropdown = null;
    let perChange = null;
    let csvColumnMapping = this.props.csvColumnMapping;

    if (this.props.chartData != null) {
      const chartDataTemplate = {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
      };

      let options = {
        responsive: false,
        title: {
          display: true,
          text: "",
          position: "left",
          fontFamily: "Ubuntu-Medium",
        },
        legend: {
          display: false,
          position: "right",
          align: "left",
          fontFamily: "Ubuntu-Medium",
          labels: {
            boxWidth: 12,
            fontFamily: "Ubuntu-Medium",
          },
        },
        scales: {
          xAxes: [
            {
              offset: true,
              type: "category",
              gridLines: {
                display: true,
                color: "#EAF0F3",
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                display: true,
                fontColor: "#252525",
                fontFamily: "Ubuntu-Medium",
              },
              display: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
              },
              ticks: {
                beginAtZero: true,
                display: true,
                fontColor: "#919BA3",
                stepSize: 25,
                fontFamily: "Ubuntu-Medium",
              },
              display: true,
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
            color: "black",
            align: "top",
            anchor: "end",
            fontFamily: "Ubuntu-Medium",
            formatter: (label, index, labels) => {
              return label;
            },
          },
        },
        tooltips: {
          enabled: false,
          custom: function (tooltipModel) {
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.style.backgroundColor = "white";
              tooltipEl.style.borderRadius = "10px";
              tooltipEl.style.boxShadow = "1px 3px 11px #c4c4c4";

              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body && tooltipModel.dataPoints) {
              tooltipEl.innerHTML = "";
              let heroDiv = document.createElement("div");
              heroDiv.classList.add("hero");
              tooltipEl.appendChild(heroDiv);

              tooltipModel.dataPoints.forEach((point, index) => {
                let containerDiv = document.createElement("div");
                containerDiv.style.display = "flex";
                containerDiv.style.alignItems = "center";

                let legendColorDiv = document.createElement("div");
                legendColorDiv.style.height = "12px";
                legendColorDiv.style.width = "12px";
                legendColorDiv.style.borderRadius = "2px";
                legendColorDiv.style.backgroundColor =
                  tooltipModel.labelColors[index].borderColor;

                let legendLabelDiv = document.createElement("div");
                let label = tooltipModel.body[index].lines[0];
                legendLabelDiv.innerText = csvColumnMapping
                  ? csvColumnMapping[
                      tooltipModel.body[index].lines[0].substring(
                        0,
                        label.lastIndexOf(":")
                      )
                    ]
                    ? csvColumnMapping[
                        tooltipModel.body[index].lines[0].substring(
                          0,
                          label.lastIndexOf(":")
                        )
                      ]
                    : tooltipModel.body[index].lines[0].substring(
                        0,
                        label.lastIndexOf(":")
                      )
                  : tooltipModel.body[index].lines[0].substring(
                      0,
                      label.lastIndexOf(":")
                    );
                legendLabelDiv.style.fontFamily = "Ubuntu-Medium";
                legendLabelDiv.style.fontWeight = "500";
                legendLabelDiv.style.color = "#919BA3";
                legendLabelDiv.style.marginLeft = "5px";

                let legendValueDiv = document.createElement("div");
                legendValueDiv.innerText = point.value;
                legendValueDiv.style.color = "#252525";
                legendValueDiv.style.fontWeight = "bold";
                legendValueDiv.style.marginLeft = "10px";

                let legendYAxisLabelDiv = document.createElement("div");
                legendYAxisLabelDiv.innerText = `(${point.label})`;
                legendYAxisLabelDiv.style.color = "#252525";
                legendYAxisLabelDiv.style.marginLeft = "5px";
                legendYAxisLabelDiv.style.fontWeight = "300";

                containerDiv.appendChild(legendColorDiv);
                containerDiv.appendChild(legendLabelDiv);
                containerDiv.appendChild(legendValueDiv);
                containerDiv.appendChild(legendYAxisLabelDiv);
                tooltipEl.appendChild(containerDiv);
              });
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left +
              window.pageXOffset +
              tooltipModel.caretX -
              window
                .getComputedStyle(tooltipEl, null)
                .getPropertyValue("width")
                .replace("px", "") /
                2 +
              "px";
            tooltipEl.style.top =
              position.top +
              window.pageYOffset +
              tooltipModel.caretY +
              10 +
              "px";
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding =
              tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
            tooltipEl.style.pointerEvents = "none";
          },
        },
      };

      let lineChartData = this.props?.chartData?.top5;
      let val = this.state.selectedActivity;

      if (val == "All Performers") {
        lineChartData = this.props?.chartData?.all;
      } else if (val == "Top 5") {
        lineChartData = this.props?.chartData?.top5;
      } else if (val == "Bottom 5") {
        lineChartData = this.props?.chartData?.bottom5;
      } else {
        lineChartData = this.props?.chartData?.aggregate;
      }

      let dataFor
      if (this.state.GRadioChecked) {
        dataFor = 'G';
      } else {
        dataFor = 'B';
      }

      let formattedLineChartData = this.formatData(lineChartData);
      let displayedformattedLineChartData = formattedLineChartData;
      let milestoneOptions = ["All Milestones"];
      let object = {};
      if (this.state.selectedMilestone == "All Milestones") {
        perChange = lineChartData?.chartData[dataFor]?.avgDiff;
      } else {
        perChange = lineChartData?.chartData[dataFor][this.state.selectedMilestone].diff;
      }

      if (displayedformattedLineChartData) {
        /*
        if (this.state.selectedActivity === "All Performers") {
          displayedformattedLineChartData = formattedLineChartData;
          milestoneOptions = milestoneOptions.concat(
            Object.keys(displayedformattedLineChartData)
          );
        } else if (this.state.selectedActivity === "Top 5") {
          displayedformattedLineChartData = formattedLineChartData;
          let sortable = [];

          for (let mil in displayedformattedLineChartData) {
            sortable.push([mil, displayedformattedLineChartData[mil]]);
          }

          sortable.sort(function (a, b) {
            //console.log(Object.keys(a[1]));
            return (
              Object.entries(b[1])[Object.keys(b[1]).length - 1][1] -
              Object.entries(a[1])[Object.keys(a[1]).length - 1][1]
            );
          });
          //console.log(sortable);
          let newObject = {};
          for (let j = 0; j < 2; j++) {
            newObject[sortable[j][0]] = sortable[j][1];
          }
          displayedformattedLineChartData = newObject;
          milestoneOptions = milestoneOptions.concat(
            Object.keys(displayedformattedLineChartData)
          );
        } else if (this.state.selectedActivity === "Bottom 5") {
          displayedformattedLineChartData = formattedLineChartData;
          let sortable = [];

          for (let mil in displayedformattedLineChartData) {
            sortable.push([mil, displayedformattedLineChartData[mil]]);
          }

          sortable.sort(function (a, b) {
            //console.log(Object.keys(a[1]));
            return (
              Object.entries(a[1])[Object.keys(a[1]).length - 1][1] -
              Object.entries(b[1])[Object.keys(b[1]).length - 1][1]
            );
          });
          //console.log(sortable);
          let newObject = {};
          for (let j = 0; j < 2; j++) {
            newObject[sortable[j][0]] = sortable[j][1];
          }
          displayedformattedLineChartData = newObject;
          milestoneOptions = milestoneOptions.concat(
            Object.keys(displayedformattedLineChartData)
          );
        } else {
          displayedformattedLineChartData = formattedLineChartData;
        }
        */

        displayedformattedLineChartData = formattedLineChartData;
        milestoneOptions = milestoneOptions.concat(
          Object.keys(displayedformattedLineChartData)
        );

        if (this.state.selectedMilestone !== "All Milestones") {
          object[this.state.selectedMilestone] =
            displayedformattedLineChartData[this.state.selectedMilestone];
          displayedformattedLineChartData = object;
        }

        let chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
        let chartLabels = displayedformattedLineChartData
          ? Object.keys(Object.values(displayedformattedLineChartData)[0])
          : {};
        let colors = this.getColorsList(displayedformattedLineChartData);
        let colorMapObj = {};

        Object.entries(displayedformattedLineChartData).forEach(
          (item, index) => {
            let element = {
              // backgroundColor: "#96e079", //#C4F2B2',
              label: item[0],
              data: Object.values(item[1]),
              fontFamily: "Ubuntu-Medium",
              fill: false,
              lineTension: 0,
              borderColor: colors[index],
              pointRadius: 2,
              pointHitRadius: 20,
            };
            colorMapObj[item[0]] = colors[index];
            chartData.data.datasets.push(element);
          }
        );

        chartData.data.labels = chartLabels;

        finalChartData = (
          <div className="d-flex align-items-center">
            <Line
              data={chartData.data}
              width={800}
              height={300}
              options={options}
            />
            <div className="legend-list">
              {Object.entries(colorMapObj).map((item, index) => {
                return (
                  <div className="legend-list-item" key={index}>
                    <div
                      className="legend-color"
                      style={{ backgroundColor: item[1] }}
                    ></div>
                    <div className="legend-label">
                      {this.state.selectedActivity == "Aggregate"
                        ? "Aggregate"
                        : this.props.csvColumnMapping
                        ? this.props.csvColumnMapping[item[0]]
                          ? this.props.csvColumnMapping[item[0]]
                          : item[0]
                        : item[0]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );

        milestoneDropdown = (
          <select
            onChange={this.onMilestoneChange}
            value={this.state.selectedMilestone}
            id="activity-list-dropdown-select"
            disabled={
              this.state.selectedActivity === "Aggregate" ? true : false
            }
            style={{width: '135px'}}
          >
            {milestoneOptions.map((option) => (
              <option key={option} value={option}>
                {this.props.csvColumnMapping
                  ? this.props.csvColumnMapping[option]
                    ? this.props.csvColumnMapping[option]
                    : option
                  : option}
              </option>
            ))}
          </select>
        );
      }
    }

    return (
      <span>
        {finalChartData != null ? (
          <div className="line-chart chart-top">
            <div className="chart-header">
              Milestone performance trend by month{" "}
              <span
                data-title="The line chart represents trend of milestones (Green and Blue merged together) being achieved to give a percentage by month. The ‘Average Percentage Change’ shows a trend of performance as positive or negative over the last six months.​"
                data-title-xlg
              >
                <MdInfo size={20} className="c-info"></MdInfo>
              </span>
              <span id="accuracy-line-chart">
                <span id="accuracy-text">Percentage Change:&nbsp;</span>
                {perChange ? Math.round(perChange) : "NA"}%
              </span>
              <span id="activity-list-dropdown-span">
                <span className="line-chart-radio-buttons">
                  <label className="radio-label">
                  Forecasted
                    <input
                      type="radio"
                      name="GdataToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="G"
                      checked={this.state.GRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  Actualised
                    <input
                      type="radio"
                      name="BdataToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="B"
                      checked={this.state.BRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </span>
                <span id="activity-list-dropdown">
                  <select
                    onChange={this.onActivityChange}
                    value={this.state.selectedActivity}
                    id="activity-list-dropdown-select"
                  >
                    {this.state.labels.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div id="activity-list-dropdown">{milestoneDropdown}</div>
              </span>
            </div>
            {finalChartData != null && (
              <React.Fragment>{finalChartData}</React.Fragment>
            )}
            {/*{finalChartData == null && <PageSpinner></PageSpinner>}*/}
          </div>
        ) : (
          <div></div>
        )}
      </span>
    );
  }
}

export default LineChart;
