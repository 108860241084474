import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import "../styles/components/SessionExpired.scss";

class NotAuthorized extends Component {
  render() {
    return (
      <div>
        <Modal isOpen="true" centered="true" size="sm">
          <ModalHeader size="sm" id="session-expired-modal-header">
            <div id="session-expired-text">
              <LockOpenIcon
                fontSize="small"
                color="secondary"
                id="access-time-icon-session"
              />{" "}
              <b>Authorization Failed!</b>
            </div>
          </ModalHeader>
          <ModalBody size="sm" id="session-expired-modal-body">
            You are not authorized to perform this action. Please contact
            administrator
          </ModalBody>
          <ModalFooter size="sm" id="session-expired-modal-footer">
            <Link
              to={{
                pathname: "/ui/landing/currentstatus",
                state: {},
              }}
            >
              <Button color="secondary" size="sm" id="login-again-button">
                <div id="login-again-button-text">
                  <b>OK</b>
                </div>
              </Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default NotAuthorized;
