import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";

import MainLayoutRoute from "./components/layout/MainLayoutRoute";
import LayoutRoute from "./components/layout/LayoutRoute";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CurrentStatusPage from "./pages/CurrentStatusPage";
import HistoryPage from "./pages/HistoryPage";
import PredictionPage from "./pages/PredictionPage";
import AllUsersPage from "./pages/AllUsersPage";
import PendingRequestsPage from "./pages/PendingRequestsPage";
import TrainModelPage from "./pages/TrainModelPage";
import UploadDataPage from "./pages/UploadDataPage";
import CurrentVsForecastedPage from "./pages/CurrentVsForecastedPage";
import ProgramAssurancePage from "./pages/ProgramAssurancePage";
import SummaryPage from "./pages/SummaryPage";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Switch location={this.props.location}>
          <LayoutRoute path="/ui/signin" component={SignInPage} />
          <LayoutRoute exact path="/ui/signup" component={SignUpPage} />
          <LayoutRoute
            exact
            path="/ui/forgotpassword"
            component={ForgotPasswordPage}
          />
          <MainLayoutRoute
            path="/ui/landing/currentstatus"
            component={CurrentStatusPage}
          />
          <MainLayoutRoute
            path="/ui/landing/forecast"
            component={PredictionPage}
          />
          <MainLayoutRoute path="/ui/landing/history" component={HistoryPage} />
          <MainLayoutRoute
            path="/ui/landing/uploaddata"
            component={UploadDataPage}
          />
          <MainLayoutRoute
            path="/ui/landing/trainmodel"
            component={TrainModelPage}
          />
          <MainLayoutRoute
            path="/ui/landing/pendingrequests"
            component={PendingRequestsPage}
          />
          <MainLayoutRoute
            path="/ui/landing/allusers"
            component={AllUsersPage}
          />
          <MainLayoutRoute
            path="/ui/landing/currentvsforecasted"
            component={CurrentVsForecastedPage}
          />
          <MainLayoutRoute
            path="/ui/landing/programassurance"
            component={ProgramAssurancePage}
          />
          <MainLayoutRoute path="/ui/landing/summary" component={SummaryPage} />
          <Redirect to="/ui/signin" />
        </Switch>
      </div>
    );
  }
}

export default Main;
