import React, { Component } from "react";
import { Alert } from "reactstrap";

import "../styles/components/AutoCloseAlert.scss";

class AutoCloseAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onDismiss = this.onDismiss.bind(this);
    window.setTimeout(() => {
      this.props.setShowAlert(false);
    }, 5000);
  }

  onDismiss = () => {
    this.props.setShowAlert(false);
  };

  render() {
    let colorMap = {
      warning: {
        color: "warning",
        icon: <span id="alert-icon">&#9888;</span>,
      },
      danger: {
        color: "danger",
        icon: <span id="alert-icon">&#8856;</span>,
      },
      success: {
        color: "success",
        icon: <span id="alert-icon">&#10004;</span>,
      },
    };
    const color = this.props.color;
    return (
      <Alert
        color={colorMap[color].color}
        isOpen={this.props.showAlert}
        toggle={this.onDismiss}
        fade={true}
        id="alert-box-auto-close-alert"
      >
        {colorMap[color].icon} <span id="alert-text">{this.props.body}</span>
      </Alert>
    );
  }
}

export default AutoCloseAlert;
