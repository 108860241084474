import React, { Component } from "react";
import { MdRemove, MdAdd, MdInfo, MdUndo } from "react-icons/md";
import {
  Row,
  Col,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  Table,
  Spinner,
} from "reactstrap";
import * as d3 from "d3";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import jsPDF from "jspdf";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";
import chroma from "chroma-js";

import * as data from "../../../../utils/tubemap/activities.json";
import map from "../../../../utils/tubemap/map";
import "../../../../styles/components/Tubemap.scss";
import "../../../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import SessionExpired from "../../../SessionExpired";
import { ErrorHandler } from "../../../ErrorHandler";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
import {compareAsc, compareDesc, isValid, parse} from 'date-fns';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class CurrentMonthMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZoom: false,
      isMilestoneVisible: true,
      isActivitiesVisible: true,
      selectedMilestone: 0,
      loadFromParent: true,
      activityList: null,
      milestoneDbColName: null,
      milestoneProjectCount: -1,
      searchSelection: true,
      milestoneProjectsFilterData: this.props.milestoneProjectsData,
      allMilestones: [
        "All",
        "Project / batch creation",
        "PAC1",
        "PAC2",
        "Award contract",
        "Start on site",
        "Project in use",
        "Completion date",
        "PAC3",
        "Final acceptance",
        "Gateway 4",
      ],
    };
    this.showTubeMap = this.showTubeMap.bind(this);
    this.updateSelectedMilestone = this.updateSelectedMilestone.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.toggleMilestones = this.toggleMilestones.bind(this);
    this.toggleActivities = this.toggleActivities.bind(this);
    this.updateDataToShow = this.updateDataToShow.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.fetchSelectedCurrentData = this.fetchSelectedCurrentData.bind(this);
    this.openListOfProjectsModal = this.openListOfProjectsModal.bind(this);
    this.closeListOfProjectsModal = this.closeListOfProjectsModal.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
  }

  showTubeMap() {
    const latestData = this.state.loadFromParent
      ? this.props.mapData
      : this.state.mapData;
    const activityList = this.state.loadFromParent
      ? this.props.activityList
      : this.state.activityList;
    const milestoneDbColName = this.state.loadFromParent
      ? this.props.milestoneDbColName
      : this.state.milestoneDbColName;
      const selectedMilestone =  this.state.loadFromParent
      ? this.props.selectedMilestone
      : this.state.selectedMilestone;
    d3.select("#currentMonthTubeMapDiv").select("svg").remove();
    let container = d3.select("#currentMonthTubeMapDiv");
    const isMilestoneVisible = this.state.isMilestoneVisible;
    const isActivitiesVisible = this.state.isActivitiesVisible;
    let tubemapData;
    if (selectedMilestone == 0) {
      tubemapData = {
        allProjectsData: latestData,
        isMilestoneVisible: isMilestoneVisible,
        isActivitiesVisible: isActivitiesVisible,
        isPredictionData: false,
        activityList: [],
        milestoneDbColName: null,
      };
    } else {
      tubemapData = {
        allProjectsData: latestData,
        isMilestoneVisible: isMilestoneVisible,
        isActivitiesVisible: isActivitiesVisible,
        isPredictionData: true,
        isCurrentData: true,
        activityList: activityList,
        milestoneDbColName: milestoneDbColName,
        showInDateMilestone: true,
      };
    }

    let tubeMap = map(tubemapData);
    let dataJson = data.default;

    container.datum(dataJson).call(tubeMap);
    this.changeZoom();
  }

  updateSelectedMilestone(e) {
    const selectedMilestoneVal = parseInt(e.target.value);
    this.setState({ selectedMilestone: selectedMilestoneVal });
    this.props.updateSelectedMilestone(selectedMilestoneVal);
    this.fetchSelectedCurrentData(selectedMilestoneVal);
  }

  fetchSelectedCurrentData(selectedMilestone) {
    const filters = this.props.selectedFiltersData;
    this.setState({ fetchingMilestoneProjectsData: true });
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    const predictionMilestoneActivityRequestURL = `${[
      process.env.REACT_APP_BACKEND_API,
    ]}/dashboard/performance-by-milestone?filters=`;
    axios({
      url:
        predictionMilestoneActivityRequestURL +
        filterQuery +
        "&selectedMilestone=" +
        selectedMilestone,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data.chartData;
        let activityList;
        let milestoneProjectCount;
        let milestoneDbColName;
        let milestoneProjectsData;
        let projectIdName = [];

        if (selectedMilestone != 0) {
          activityList = res.data.activityList;
          milestoneProjectCount = res.data.milestoneProjectCount;
          milestoneDbColName = res.data.milestoneDbColName;
          milestoneProjectsData = res.data.milestoneProjectsData;

          if (milestoneProjectsData) {
            for (let k = 0; k < milestoneProjectsData.length; k++) {
              if (milestoneProjectsData[k]) {
                projectIdName.push(
                  milestoneProjectsData[k].projectId +
                    "(" +
                    milestoneProjectsData[k].projectName +
                    ")"
                );

                if (milestoneProjectsData[k].noOfDaysFloat == null) {
                  milestoneProjectsData[k].noOfDaysFloat = "Not available";
                }
              }
            }
          }
        }

        let currentYear, currentMonth, latestData;

        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = currentYear ? Object.keys(data[currentYear])[0] : null;
          latestData =
            currentYear != null && currentMonth != null
              ? data[currentYear][currentMonth]
              : null;
        } else {
          currentYear = null;
          currentMonth = null;
          latestData = null;
        }

        this.setState({
          mapData: latestData,
          activityList: activityList,
          milestoneProjectCount: milestoneProjectCount,
          milestoneDbColName: milestoneDbColName,
          loadFromParent: false,
          milestoneProjectsData: milestoneProjectsData,
          milestoneProjectsFilterData: milestoneProjectsData,
          projectIdName: projectIdName,
          fetchingMilestoneProjectsData: false,
        });
      })
      .catch((error) => {
        console.log("error in fetching latest data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          fetchingMilestoneProjectsData: false,
        });
      });
  }

  changeZoom() {
    let container = d3.select("#currentMonthTubeMapDiv");
    let svg = container.select("svg");
    let zoom = d3.zoom().scaleExtent([1, 10]).on("zoom", zoomed);

    svg.call(zoom);

    d3.select("#zoomIn").on("click", zoomInMap);

    d3.select("#zoomOut").on("click", zoomOutMap);
    d3.select("#zoomInitialCurrentonthMap").on("click", zoomInitialMap);

    let zoomContainer = svg.call(zoom);
    //let initialScale = 1.7;
    //let initialTranslate = [75, 50];
    let initialScale = sessionStorage.getItem("initialScale");
    let initialTranslateX = parseInt(
      sessionStorage.getItem("initialTranslateX")
    );
    let initialTranslateY = parseInt(
      sessionStorage.getItem("initialTranslateY")
    );

    zoom.scaleTo(zoomContainer, initialScale);
    zoom.translateTo(
      zoomContainer,
      initialTranslateX / initialScale,
      initialTranslateY / initialScale
    );

    function zoomed() {
      const currentTransform = d3.event.transform;
      svg.select("g").attr("transform", currentTransform);
      initialScale = currentTransform.k;
      sessionStorage.setItem("initialScale", initialScale);
      sessionStorage.setItem("initialTranslateX", currentTransform.x * -1);
      sessionStorage.setItem("initialTranslateY", currentTransform.y * -1);
    }

    function zoomInMap(d) {
      initialScale += 1;
      if (initialScale > 10) {
        initialScale = 10;
      }
      zoom.scaleTo(svg, initialScale);
      sessionStorage.setItem("initialScale", initialScale);
    }

    function zoomOutMap(d) {
      initialScale -= 1;
      if (initialScale < 2) {
        initialScale = 2;
      }
      zoom.scaleTo(svg, initialScale);
      sessionStorage.setItem("initialScale", initialScale);
    }
    let copyThis = this;
    function zoomInitialMap(d) {
      console.log("in zoom");
      sessionStorage.setItem("initialScale", 1.7);
      sessionStorage.setItem("initialTranslateX", 50);
      sessionStorage.setItem("initialTranslateY", 50);
      copyThis.setState({ isZoom: false });
    }
  }

  resetZoom() {
    sessionStorage.setItem("initialScale", 1.7);
    sessionStorage.setItem("initialTranslateX", 50);
    sessionStorage.setItem("initialTranslateY", 50);
    this.setState({ isZoom: false });
  }

  toggleMilestones() {
    const isMilestoneVisible = this.state.isMilestoneVisible;
    this.setState({ isMilestoneVisible: !isMilestoneVisible });
    // if isMilestoneVisible is false then make isActivitiesVisible to true because at a time either of one should be visible
    if (!isMilestoneVisible == false) {
      this.setState({ isActivitiesVisible: true });
    }
  }

  toggleActivities() {
    const isActivitiesVisible = this.state.isActivitiesVisible;
    this.setState({ isActivitiesVisible: !isActivitiesVisible });
    // if isActivitiesVisible is false then make isMilestoneVisible to true because at a time either of one should be visible
    if (!isActivitiesVisible == false) {
      this.setState({ isMilestoneVisible: true });
    }
  }

  updateDataToShow(e) {
    if (e.target.value === "milestone") {
      this.setState({ isMilestoneVisible: true, isActivitiesVisible: false });
    } else if (e.target.value === "activity") {
      this.setState({ isActivitiesVisible: true, isMilestoneVisible: false });
    } else {
      this.setState({ isActivitiesVisible: true, isMilestoneVisible: true });
    }
  }

  openListOfProjectsModal() {
    this.forecastDataSort(this.state.milestoneProjectsFilterData,false);
    this.setState({ showListOfProjectsModal: true, forecastLess: 'forecastedDate' }, this.bindEvent);
  }

  closeListOfProjectsModal() {
    const milestoneProjectsData = this.state.loadFromParent
    ? this.props.milestoneProjectsData
    : this.state.milestoneProjectsData;
    this.setState({
      showListOfProjectsModal: false,
      milestoneProjectsFilterData: milestoneProjectsData,
      lessClickKey: "",
      moreClickKey: "",
      forecastMore: '',
      forecastLess: '',
    });
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 5);
  }

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let milestoneProjectsFilterData = [];
    let dataArray = [];
    const prjData = this.state.loadFromParent
      ? this.props.milestoneProjectsData
      : this.state.milestoneProjectsData;
    if (value === null || value === "") {
      this.setState({
        milestoneProjectsFilterData: prjData,
      });
    } else {
      prjData.map((data) => {
        dataArray.push(data);
        if (
          data["projectId"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          milestoneProjectsFilterData.push(data);
        }
        if (dataArray.length === prjData.length) {
          this.setState({
            milestoneProjectsFilterData: milestoneProjectsFilterData,
          });
        }
      });
    }
  }

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title =
      "List of in date projects at milestone " +
      this.state.allMilestones[this.state.selectedMilestone];

    const headers = [
      [
        "Project ID",
        "Project Name",
        "Milestone status",
        "Float in number of days",
        "Forecasted date"
      ],
    ];

    let data = this.state.milestoneProjectsFilterData.map((elt) => [
      elt["projectId"],
      elt["projectName"],
      elt["milestoneVal"],
      elt["noOfDaysFloat"],
      elt["forecastedDate"],
    ]);

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      "List of in date projects at milestone " +
        this.state.allMilestones[this.state.selectedMilestone] +
        ".pdf"
    );
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedValue: selectedList,
      searchSelection: !this.state.searchSelection,
    });
    let milestoneProjectsFilterData = [];
    let dataArray = [];
    const prjData = this.state.loadFromParent
    ? this.props.milestoneProjectsData
    : this.state.milestoneProjectsData;
    if (!selectedList) {
      this.setState({
        milestoneProjectsFilterData: prjData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        milestoneProjectsFilterData: prjData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < prjData.length; j++) {
          let data = prjData[j];
          dataArray.push(data);
          if (data["milestoneVal"]) {
            if (
              data["milestoneVal"].toLowerCase() ==
              selectedList[i].value.toLowerCase()
            ) {
              milestoneProjectsFilterData.push(data);
            }
          } else {
            if (selectedList[i].value == "NA") {
              milestoneProjectsFilterData.push(data);
            }
          }
        }
        if (
          dataArray.length ===
          selectedList.length * prjData.length
        ) {
          this.setState({
            milestoneProjectsFilterData: milestoneProjectsFilterData,
          });
        }
      }
    }
  };

  forecastDataSort(prjData, asc = true){
    if(!prjData || !prjData?.length){
      return;
    }
    let key = "forecastedDate";


    let sortArray = [];
    let otherArray = [];
    prjData.forEach((item) => {
      let aDate = parse(item[key].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
      if(isValid(aDate)){
        sortArray.push(item);
      } else {
        otherArray.push(item);
      }
    })
    
    function GetDateSort(prop) {
      return function (a, b) {
        let aDate = parse(a[prop].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
        let bDate = parse(b[prop].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
        return asc?compareAsc(aDate,bDate):compareDesc(aDate,bDate);
      };
    }
    if (sortArray !== null) {
      sortArray.sort(GetDateSort(key));
    }
    this.setState({
      isSortingCalled:
        this.state.isSortingCalled + 1,
      milestoneProjectsFilterData: [...sortArray,...otherArray],
      moreClickKey: '',
      lessClickKey: "",
      forecastMore: (!asc)?key:'',
      forecastLess: (asc)?key:'',
    });
  }

  render() {
    this.showTubeMap();
    /*const milestoneButtonLabel = this.state.isMilestoneVisible ? 'Hide Milestones' : 'Show Milestones'
    const activityButtonLabel = this.state.isActivitiesVisible ? 'Hide Activities' : 'Show Activities'
    const milestoneButtonColor = this.state.isMilestoneVisible ? 'map-primary' : 'map-secondary'
    const activityButtonColor = this.state.isActivitiesVisible ? 'map-primary' : 'map-secondary'*/

    let milestoneRadioChecked = false;
    let activityRadioChecked = false;
    let bothRadioChecked = false;
    if (this.state.isMilestoneVisible && !this.state.isActivitiesVisible) {
      milestoneRadioChecked = true;
    }
    if (!this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      activityRadioChecked = true;
    }
    if (this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      bothRadioChecked = true;
    }

    const selectedMilestone = this.state.loadFromParent
      ? this.props.selectedMilestone
      : this.state.selectedMilestone;
    const milestoneProjectCount = this.state.loadFromParent
      ? this.props.milestoneProjectCount
      : this.state.milestoneProjectCount;

    const projectIdName = this.state.loadFromParent
    ? this.props.projectIdName
    : this.state.projectIdName;

    const milestoneOptions = this.state.allMilestones.map((v, i) => {
      if (i == selectedMilestone) {
        return (
          <option key={i} value={i} selected="selected">
            {v}
          </option>
        );
      } else {
        return (
          <option key={i} value={i}>
            {v}
          </option>
        );
      }
    });

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    /*const prjData = this.state.loadFromParent
    ? this.props.milestoneProjectsData
    : this.state.milestoneProjectsFilterData;*/
    const prjData = this.state.milestoneProjectsFilterData;

    return (
      <div className="map-top">
        {this.state.showListOfProjectsModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={this.closeListOfProjectsModal}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col>
                    <center>
                      <span
                        id="key-tasks-to-text"
                        style={{ color: "#252525", fontSize: "1rem" }}
                      >
                        List of in date projects at milestone{" "}
                        {this.state.allMilestones[selectedMilestone]}
                      </span>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper">
                        <Autocomplete
                          key={this.state.searchSelection}
                          options={projectIdName}
                          onInputChange={this.searchByProject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Project"
                            />
                          )}
                          size="small"
                        />
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="table-fixed-start-columns">
                        <Table
                          responsive
                          className="current-vs-forecasted table table-md table-custom table-list-of-forecasted-projects table-scroll-modal table-modal"
                          id="table-task-list"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <thead className="table-head">
                            <tr>
                              <th className="project-id-col">Project Id</th>
                              <th>Project Name</th>
                              <th
                                style={{
                                  paddingBottom: "0.2rem",
                                  paddingTop: "0.2rem",
                                  width: "6vw",
                                  cursor: "pointer",
                                }}
                              >
                                <BootstrapTooltip
                                  title="Milestone status"
                                  placement="top-start"
                                >
                                  <Select
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    isMulti
                                    options={colourOptions}
                                    styles={colourStyles}
                                    value={this.state.selectedValue}
                                    onChange={this.onSelect}
                                    placeholder={"Milestone status"}
                                    isSearchable
                                  />
                                </BootstrapTooltip>
                              </th>
                              <th>
                                <span>Float in number of days</span>
                                <ExpandLessIcon
                                  onClick={() => {
                                    let array = prjData;
                                    let key = "noOfDaysFloat";
                                    function GetSortOrder(prop) {
                                      return function (a, b) {
                                        if (
                                          (a[prop] == "Not available" ||
                                            a[prop] == null) &&
                                          b[prop] != "Not available" &&
                                          b[prop] != null
                                        ) {
                                          return 1;
                                        }
                                        if (
                                          a[prop] != "Not available" &&
                                          a[prop] != null &&
                                          (b[prop] == "Not available" ||
                                            b[prop] == null)
                                        ) {
                                          return -1;
                                        }
                                        if (a[prop] < b[prop]) {
                                          return 1;
                                        } else if (a[prop] > b[prop]) {
                                          return -1;
                                        }
                                        return 0;
                                      };
                                    }
                                    if (array !== null) {
                                      array.sort(GetSortOrder(key));
                                    }
                                    this.setState({
                                      isSortingCalled:
                                        this.state.isSortingCalled + 1,
                                    });
                                    this.setState({
                                      lessClickKey: key,
                                      moreClickKey: "",
                                      forecastMore: '',
                                      forecastLess: '',
                                    });
                                  }}
                                  fontSize="small"
                                  className="expand-less-icon-sorting"
                                  style={{
                                    color:
                                      "noOfDaysFloat" ===
                                      this.state.lessClickKey
                                        ? "red"
                                        : "black",
                                  }}
                                />
                                <ExpandMoreIcon
                                  onClick={() => {
                                    let array = prjData;
                                    let key = "noOfDaysFloat";
                                    function GetSortOrder(prop) {
                                      return function (a, b) {
                                        if (
                                          (a[prop] == "Not available" ||
                                            a[prop] == null) &&
                                          b[prop] != "Not available" &&
                                          b[prop] != null
                                        ) {
                                          return 1;
                                        }
                                        if (
                                          a[prop] != "Not available" &&
                                          a[prop] != null &&
                                          (b[prop] == "Not available" ||
                                            b[prop] == null)
                                        ) {
                                          return -1;
                                        }
                                        if (a[prop] > b[prop]) {
                                          return 1;
                                        } else if (a[prop] < b[prop]) {
                                          return -1;
                                        }
                                        return 0;
                                      };
                                    }
                                    if (array !== null) {
                                      array.sort(GetSortOrder(key));
                                    }
                                    this.setState({
                                      isSortingCalled:
                                        this.state.isSortingCalled + 1,
                                    });
                                    this.setState({
                                      moreClickKey: key,
                                      lessClickKey: "",
                                      forecastMore: '',
                                      forecastLess: '',
                                    });
                                  }}
                                  fontSize="small"
                                  className="expand-more-icon-sorting"
                                  style={{
                                    color:
                                      "noOfDaysFloat" ===
                                      this.state.moreClickKey
                                        ? "red"
                                        : "black",
                                  }}
                                />
                              </th>
                              <th>Forecasted date

                              <ExpandLessIcon
                                  onClick={() => this.forecastDataSort(prjData,true)}
                                  fontSize="small"
                                  className="expand-less-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedDate" ===
                                      this.state.forecastLess
                                        ? "red"
                                        : "black",
                                  }}
                                />
                                <ExpandMoreIcon
                                  onClick={() => this.forecastDataSort(prjData,false)}
                                  fontSize="small"
                                  className="expand-more-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedDate" ===
                                      this.state.forecastMore
                                        ? "red"
                                        : "black",
                                  }}></ExpandMoreIcon>


                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {prjData ? (
                              prjData.length >=
                              1 ? (
                                prjData.map(
                                  (activityObject) => (
                                    <tr>
                                      <td className="table-data-bold project-id-col">
                                        {activityObject.projectId}
                                      </td>
                                      <td className="table-data-bold">
                                        {activityObject.projectName}
                                      </td>
                                      <td className="table-data-black centre-align-data">
                                        {activityObject["milestoneVal"] ? (
                                          activityObject["milestoneVal"] !=
                                          "NA" ? (
                                            <div
                                              className={
                                                activityObject[
                                                  "milestoneVal"
                                                ] === "R"
                                                  ? "red-circle"
                                                  : activityObject[
                                                      "milestoneVal"
                                                    ] === "A"
                                                  ? "amber-circle"
                                                  : activityObject[
                                                      "milestoneVal"
                                                    ] === "GR"
                                                  ? "grey-circle"
                                                  : activityObject[
                                                      "milestoneVal"
                                                    ] === "B"
                                                  ? "blue-circle"
                                                  : "green-circle"
                                              }
                                            ></div>
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td className="table-data-bold">
                                        {activityObject.noOfDaysFloat >= 0 && (
                                          <span style={{ color: "green" }}>
                                            {activityObject.noOfDaysFloat}
                                          </span>
                                        )}
                                        {activityObject.noOfDaysFloat < 0 && (
                                          <span style={{ color: "red" }}>
                                            {activityObject.noOfDaysFloat}
                                          </span>
                                        )}
                                        {activityObject.noOfDaysFloat ==
                                          "Not available" && (
                                          <span>
                                            {activityObject.noOfDaysFloat}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table-data-bold">
                                        {activityObject.forecastedDate}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <div></div>
                              )
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    width: "100vw",
                                    paddingLeft: "40vw",
                                  }}
                                >
                                  <span style={{ marginLeft: "18rem" }}>
                                    No data available for list of projects
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <Button
                        color="primary"
                        onClick={this.downloadTablePdf}
                        size="sm"
                        id="download-list-key-actvity"
                        disabled={
                          prjData &&
                          JSON.stringify(
                            prjData
                          ) !== JSON.stringify([])
                            ? false
                            : true
                        }
                      >
                        {this.state.isListOfProjectsDataDownloading ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>{" "}
                            Downloading...
                          </div>
                        ) : (
                          <div>
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </div>
                        )}
                      </Button>
                    </center>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <div className="chart-header">
          Performance by milestones{" "}
          <span
            data-title="The tube map represents the count of live projects and their current BRAG status at each milestone and activity shown in respective colour when hovered over it.​"
            data-title-lg
          >
            <MdInfo size={20} className="c-info"></MdInfo>
          </span>
          <div className="milestone-select">
            <span>
              <span
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  fontFamily: "Ubuntu-Regular",
                  marginLeft: "-1rem",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "0.9rem",
                    fontFamily: "Ubuntu",
                  }}
                >
                  {milestoneProjectCount !=-1 ? (
                    milestoneProjectCount
                  ) : (
                    <span style={{ color: "white" }}>2</span>
                  )}
                </span>{" "}
                Projects at{"  "}
              </span>
              <span
                id="activity-list-dropdown"
                style={{
                  fontSize: "0.76rem",
                  marginLeft: "0.2rem",
                  borderRadius: "5px",
                }}
              >
                <select
                  id="activity-list-dropdown-select"
                  onChange={this.updateSelectedMilestone}
                  value={selectedMilestone}
                >
                  {milestoneOptions}
                </select>
              </span>{" "}
              <span>
                {selectedMilestone != 0 ? (
                  <Button
                    color="primary"
                    onClick={this.openListOfProjectsModal}
                    size="sm"
                    id="download-list-key-actvity"
                    className="download-list-current-month"
                    style={{ fontSize: "0.63rem", marginLeft: "0.4rem" }}
                    disabled={this.state.fetchingMilestoneProjectsData == true}
                  >
                    {this.state.fetchingMilestoneProjectsData == true ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      "LIST OF PROJECTS"
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>
          <div className="chart-buttons2">
            {/*<Button className='map-buttons' size='sm' color={milestoneButtonColor} onClick={() => { this.toggleMilestones() }}>
              {milestoneButtonLabel}
            </Button> &nbsp;
            <Button className="map-buttons" size="sm" color={activityButtonColor} onClick={() => { this.toggleActivities() }}>
              {activityButtonLabel}
            </Button>*/}
            <div>
              <label className="radio-label">
                Milestones
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="milestone"
                  checked={milestoneRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>

              <label className="radio-label">
                Activities
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="activity"
                  checked={activityRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>

              <label className="radio-label">
                Both
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="both"
                  checked={bothRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>
            </div>
            {/*<div>
              <div style={{ display: "inline-block", verticalAlign: "middle" }}> Milestones &nbsp; </div>
              <label className="switch">
                <input type="checkbox" onClick={() => { this.toggleMilestones(); }} checked={this.state.isMilestoneVisible} />
                <span className="toggle-slider round"></span>
              </label>
              &nbsp;
              <div style={{ display: "inline-block", verticalAlign: "middle" }}> Activities &nbsp; </div>
              <label className="switch">
                <input type="checkbox" onClick={() => { this.toggleActivities(); }} checked={this.state.isActivitiesVisible} />
                <span className="toggle-slider round"></span>
              </label>
            </div>*/}
          </div>
        </div>
        <div style={{ width: "auto" }}>
          <div className="map-zoom-div1">
            <div className="map-zoom-div2">
              <div id="zoomDiv" className="zoom-div">
                <div className="zoom-out" id="zoomOut">
                  <MdRemove size="20px"></MdRemove>
                </div>
                <div className="zoom-in" id="zoomIn">
                  <MdAdd size="20px"></MdAdd>
                </div>
                <div className="zoom-reset">
                  <MdUndo size="15px" onClick={() => this.resetZoom()}></MdUndo>
                </div>
                <div className="zoom" id="zoomInitialCurrentonthMap"></div>
              </div>
              <div id="bragDivCurrMonth" className="brag-div-curr-month">
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "red" }}
                  >
                    R:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Needs attention or action
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#FFBF00" }}
                  >
                    A:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    A risk but not an issue yet
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#0dc70d" }}
                  >
                    G:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    On track, no issues
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "blue" }}
                  >
                    B:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Completed, no further action required
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "grey" }}
                  >
                    GR:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Expired, not due yet, not required
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#8c60dc" }}
                  >
                    NA:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Data not available
                  </div>
                </div>
              </div>
              <div id="currentMonthTubeMapDiv" className="tubemap"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentMonthMap;
