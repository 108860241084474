import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import "../../../styles/components/admin/pending-requests/PendingRequestsHeader.scss";

class PendingRequestsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="main-div-pending-header">
        <Row>
          <Col sm="5" md="2" xs="5" lg="2">
            <h6 id="pending-requests-heading">Pending Requests</h6>
          </Col>
          <Col sm="4" md="4" xs="4" lg="4">
            <table className="table" id="pend-req-header-table">
              <tbody id="pend-req-header-table-body">
                <tr id="pend-req-header-table-row">
                  <td id="pend-req-header-table-data">
                    Total Requests
                    <br />
                    <div id="number-div">{this.props.x + this.props.y+ this.props.z}</div>
                  </td>
                  <td id="pend-req-header-table-data">
                    Reset Password
                    <br />
                    <div id="number-div">{this.props.y}</div>
                  </td>
                  <td id="pend-req-header-table-data">
                    New Users
                    <br />
                    <div id="number-div">{this.props.x}</div>
                  </td>                 
                  <td id="pend-req-header-table-data">
                    Locked Users
                    <br />
                    <div id="number-div">{this.props.z}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PendingRequestsHeader;
