import React, { Component } from "react";
import { MdRemove, MdAdd, MdInfo, MdUndo } from "react-icons/md";
import { Spinner } from "reactstrap";
import * as d3 from "d3";
import axios from "axios";

import * as data from "../../../../utils/tubemap/activities.json";
import map from "../../../../utils/tubemap/map";
import "../../../../styles/components/Tubemap.scss";
import "../../../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import SessionExpired from "../../../SessionExpired";
import { ErrorHandler } from "../../../ErrorHandler";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";

class CurrentMonthMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZoom: false,
      isMilestoneVisible: true,
      isActivitiesVisible: true,
      selectedMilestone: 0,
      loadFromParent: true,
      mapData: null,
      activityList: null,
      milestoneDbColName: null,
      milestoneProjectCount: 0,
      allMilestones: [
        "Project / batch creation",
        "PAC1",
        "PAC2",
        "Award contract",
        "Start on site",
        "Project in use",
        "Completion date",
        "PAC3",
        "Final acceptance",
        "Gateway 4",
      ],
    };
    this.showTubeMap = this.showTubeMap.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.toggleMilestones = this.toggleMilestones.bind(this);
    this.toggleActivities = this.toggleActivities.bind(this);
    this.updateDataToShow = this.updateDataToShow.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.updateMilestoneValue = this.updateMilestoneValue.bind(this);
    this.fetchPredictionData = this.fetchPredictionData.bind(this);
  }

  showTubeMap() {
    const latestData = this.state.loadFromParent
      ? this.props.mapData
      : this.state.mapData;
    const activityList = this.state.loadFromParent
      ? this.props.activityList
      : this.state.activityList;
    const milestoneDbColName = this.state.loadFromParent
      ? this.props.milestoneDbColName
      : this.state.milestoneDbColName;
    d3.select("#currentMonthTubeMapDiv").select("svg").remove();
    let container = d3.select("#currentMonthTubeMapDiv");
    const isMilestoneVisible = this.state.isMilestoneVisible;
    const isActivitiesVisible = this.state.isActivitiesVisible;
    const tubemapData = {
      allProjectsData: latestData,
      isMilestoneVisible: isMilestoneVisible,
      isActivitiesVisible: isActivitiesVisible,
      isPredictionData: true,
      activityList: activityList,
      milestoneDbColName: milestoneDbColName,
    };
    let tubeMap = map(tubemapData);
    let dataJson = data.default;

    container.datum(dataJson).call(tubeMap);
    this.changeZoom();
  }

  changeZoom() {
    let container = d3.select("#currentMonthTubeMapDiv");
    let svg = container.select("svg");
    let zoom = d3.zoom().scaleExtent([1, 10]).on("zoom", zoomed);

    svg.call(zoom);

    d3.select("#zoomIn").on("click", zoomInMap);

    d3.select("#zoomOut").on("click", zoomOutMap);
    d3.select("#zoomInitialCurrentMonthForcast").on("click", zoomInitialMap);

    let zoomContainer = svg.call(zoom);
    //let initialScale = 1.7;
    //let initialTranslate = [75, 50];
    let initialScale = sessionStorage.getItem("initialScale");
    let initialTranslateX = parseInt(
      sessionStorage.getItem("initialTranslateX")
    );
    let initialTranslateY = parseInt(
      sessionStorage.getItem("initialTranslateY")
    );

    zoom.scaleTo(zoomContainer, initialScale);
    zoom.translateTo(
      zoomContainer,
      initialTranslateX / initialScale,
      initialTranslateY / initialScale
    );

    function zoomed() {
      const currentTransform = d3.event.transform;
      svg.select("g").attr("transform", currentTransform);
      initialScale = currentTransform.k;
      sessionStorage.setItem("initialScale", initialScale);
      sessionStorage.setItem("initialTranslateX", currentTransform.x * -1);
      sessionStorage.setItem("initialTranslateY", currentTransform.y * -1);
    }

    function zoomInMap(d) {
      initialScale += 1;
      if (initialScale > 10) {
        initialScale = 10;
      }
      zoom.scaleTo(svg, initialScale);
      sessionStorage.setItem("initialScale", initialScale);
    }

    function zoomOutMap(d) {
      initialScale -= 1;
      if (initialScale < 2) {
        initialScale = 2;
      }
      zoom.scaleTo(svg, initialScale);
      sessionStorage.setItem("initialScale", initialScale);
    }
    let v = this;
    function zoomInitialMap(d) {
      sessionStorage.setItem("initialScale", 1.7);
      sessionStorage.setItem("initialTranslateX", 50);
      sessionStorage.setItem("initialTranslateY", 50);
      v.setState({ isZoom: false });
    }
  }

  resetZoom() {
    sessionStorage.setItem("initialScale", 1.7);
    sessionStorage.setItem("initialTranslateX", 50);
    sessionStorage.setItem("initialTranslateY", 50);
    this.setState({ isZoom: false });
  }

  toggleMilestones() {
    const isMilestoneVisible = this.state.isMilestoneVisible;
    this.setState({ isMilestoneVisible: !isMilestoneVisible });
    // if isMilestoneVisible is false then make isActivitiesVisible to true because at a time either of one should be visible
    if (!isMilestoneVisible == false) {
      this.setState({ isActivitiesVisible: true });
    }
  }

  toggleActivities() {
    const isActivitiesVisible = this.state.isActivitiesVisible;
    this.setState({ isActivitiesVisible: !isActivitiesVisible });
    // if isActivitiesVisible is false then make isMilestoneVisible to true because at a time either of one should be visible
    if (!isActivitiesVisible == false) {
      this.setState({ isMilestoneVisible: true });
    }
  }

  updateDataToShow(e) {
    if (e.target.value === "milestone") {
      this.setState({ isMilestoneVisible: true, isActivitiesVisible: false });
    } else if (e.target.value === "activity") {
      this.setState({ isActivitiesVisible: true, isMilestoneVisible: false });
    } else {
      this.setState({ isActivitiesVisible: true, isMilestoneVisible: true });
    }
  }

  updateMilestoneValue(e) {
    const milestoneVal = parseInt(e.target.value);
    this.setState({ selectedMilestone: milestoneVal });
    this.props.updateSelectedMilestone(milestoneVal + 1);
    this.fetchPredictionData(milestoneVal + 1);
  }

  fetchPredictionData(selectedMilestone) {
    const filters = this.props.selectedFiltersData;
    this.setState({ fetchingMilestoneData: true });
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    const predictionMilestoneActivityRequestURL = `${[
      process.env.REACT_APP_BACKEND_API,
    ]}/dashboard/forecast-performance-by-milestones?filters=`;
    axios({
      url:
        predictionMilestoneActivityRequestURL +
        filterQuery +
        "&milestoneToForecast=" +
        selectedMilestone,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data.chartData;
        const activityList = res.data.activityList;
        const milestoneProjectCount = res.data.milestoneProjectCount;
        const milestoneDbColName = res.data.milestoneDbColName;

        let currentYear, currentMonth, latestData;
        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = currentYear ? Object.keys(data[currentYear])[0] : null;
          latestData =
            currentYear != null && currentMonth != null
              ? data[currentYear][currentMonth]
              : null;
        } else {
          currentYear = null;
          currentMonth = null;
          latestData = null;
        }

        this.setState({
          mapData: latestData,
          activityList: activityList,
          milestoneProjectCount: milestoneProjectCount,
          milestoneDbColName: milestoneDbColName,
          loadFromParent: false,
          fetchingMilestoneData: false,
        });
      })
      .catch((error) => {
        console.log("error in fetching latest data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          fetchingMilestoneData: false,
        });
      });
  }

  render() {
    this.showTubeMap();
    /*const milestoneButtonLabel = this.state.isMilestoneVisible ? 'Hide Milestones' : 'Show Milestones'
    const activityButtonLabel = this.state.isActivitiesVisible ? 'Hide Activities' : 'Show Activities'
    const milestoneButtonColor = this.state.isMilestoneVisible ? 'map-primary' : 'map-secondary'
    const activityButtonColor = this.state.isActivitiesVisible ? 'map-primary' : 'map-secondary'*/
    let milestoneRadioChecked = false;
    let activityRadioChecked = false;
    let bothRadioChecked = false;
    if (this.state.isMilestoneVisible && !this.state.isActivitiesVisible) {
      milestoneRadioChecked = true;
    }
    if (!this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      activityRadioChecked = true;
    }
    if (this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      bothRadioChecked = true;
    }

    const milestoneVal = this.state.loadFromParent
      ? this.props.selectedMilestone - 1
      : this.state.selectedMilestone;
    const milestoneProjectCount = this.state.loadFromParent
      ? this.props.milestoneProjectCount
      : this.state.milestoneProjectCount;

    const milestoneOptions = this.state.allMilestones.map((v, i) => {
      if (i + 1 == milestoneVal) {
        return (
          <option key={i} value={i} selected="selected">
            {v}
          </option>
        );
      } else {
        return (
          <option key={i} value={i}>
            {v}
          </option>
        );
      }
    });

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <div className="map-top">
        <div className="chart-header">
          Forecasted performance by milestones{" "}
          <span
            data-title="The tube map based on the selection of a milestone represents the BRAG status of activities which impact the status of the forecasted milestone when hovered over them. The forecasted milestone is shown in the bright blue colour, while the previous milestones are shown in light blue and future milestones are shown in white colour​"
            data-title-xxlg
          >
            <MdInfo size={20} className="c-info"></MdInfo>
          </span>
          <div className="milestone-select">
            <span>
              <span
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  fontFamily: "Ubuntu-Regular",
                  marginLeft: "-1rem",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "0.9rem",
                    fontFamily: "Ubuntu",
                  }}
                >
                  {milestoneProjectCount}
                </span>{" "}
                Projects at{"  "}
              </span>
              <span
                id="activity-list-dropdown"
                style={{
                  fontSize: "0.76rem",
                  marginLeft: "0.2rem",
                  borderRadius: "5px",
                }}
              >
                <select
                  id="activity-list-dropdown-select"
                  onChange={this.updateMilestoneValue}
                  value={milestoneVal}
                >
                  {milestoneOptions}
                </select>
              </span>{" "}
              <span>
                {this.state.fetchingMilestoneData ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                  ""
                )}
              </span>
            </span>
          </div>
          <div className="chart-buttons2">
            {/*<Button className='map-buttons' size='sm' color={milestoneButtonColor} onClick={() => { this.toggleMilestones() }}>
              {milestoneButtonLabel}
            </Button> &nbsp;
            <Button className="map-buttons" size="sm" color={activityButtonColor} onClick={() => { this.toggleActivities() }}>
              {activityButtonLabel}
            </Button>*/}
            <div>
              <label className="radio-label">
                Milestones
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="milestone"
                  checked={milestoneRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>

              <label className="radio-label">
                Activities
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="activity"
                  checked={activityRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>

              <label className="radio-label">
                Both
                <input
                  type="radio"
                  name="dataToShow"
                  onClick={(e) => {
                    this.updateDataToShow(e);
                  }}
                  value="both"
                  checked={bothRadioChecked}
                ></input>
                <span className="checkmark"></span>
              </label>
            </div>
            {/*<div>
              <div style={{ display: "inline-block", verticalAlign: "middle" }}> Milestones &nbsp; </div>
              <label className="switch">
                <input type="checkbox" onClick={() => { this.toggleMilestones(); }} checked={this.state.isMilestoneVisible} />
                <span className="toggle-slider round"></span>
              </label>
              &nbsp;
              <div style={{ display: "inline-block", verticalAlign: "middle" }}> Activities &nbsp; </div>
              <label className="switch">
                <input type="checkbox" onClick={() => { this.toggleActivities(); }} checked={this.state.isActivitiesVisible} />
                <span className="toggle-slider round"></span>
              </label>
            </div>*/}
          </div>
        </div>
        <div style={{ width: "auto" }}>
          <div className="map-zoom-div1">
            <div className="map-zoom-div2">
              <div id="zoomDiv" className="zoom-div">
                <div className="zoom-out" id="zoomOut">
                  <MdRemove size="20px"></MdRemove>
                </div>
                <div className="zoom-in" id="zoomIn">
                  <MdAdd size="20px"></MdAdd>
                </div>
                <div className="zoom-reset">
                  <MdUndo size="15px" onClick={() => this.resetZoom()}></MdUndo>
                </div>
                <div className="zoom" id="zoomInitialCurrentMonthForcast"></div>
              </div>
              <div id="bragDivCurrMonth" className="brag-div-curr-month">
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "red" }}
                  >
                    R:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Needs attention or action
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#FFBF00" }}
                  >
                    A:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    A risk but not an issue yet
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#0dc70d" }}
                  >
                    G:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    On track, no issues
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "blue" }}
                  >
                    B:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Completed, no further action required
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "grey" }}
                  >
                    GR:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Expired, not due yet, not required
                  </div>
                </div>
                <div>
                  <div
                    className="brag-div-curr-month-inner1"
                    style={{ color: "#8c60dc" }}
                  >
                    NA:
                  </div>
                  <div className="brag-div-curr-month-inner2">
                    Data not available
                  </div>
                </div>
              </div>
              <div id="currentMonthTubeMapDiv" className="tubemap"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentMonthMap;
