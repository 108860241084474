import React, { Component } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { MdInfo } from "react-icons/md";
import "chartjs-plugin-datalabels";
import axios from "axios";
import { Row, Col, Button, Modal, ModalBody, Table } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import chroma from "chroma-js";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import "../../../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import { ErrorHandler } from "../../../ErrorHandler";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import * as GenericConstants from "../../../../src-constants/GenericConstants";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
import SessionExpired from "../../../SessionExpired";
import HorizontalBarChart from "./HorizontalBarChart";

const qualityParamDisplayTextArray = GenericConstants.qualityParamMapArray.map(
  (qualityParamObject) => qualityParamObject.displayText
);

const getQualityParamCol = (qualityParamDisplayText) => {
  let output = GenericConstants.qualityParamMapArray.find((item) => {
    return item.displayText == qualityParamDisplayText;
  });
  if (output) {
    return output.col;
  }
  return "";
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class CurrentMonthPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //final url -> /dashboard/prediction-quality-param/:qualityParam/breakdown
      fetchProjectByQualityParamRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-param/`,
      fetchQualityParamActivityBreakdownRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-params-data/activityBreakdown/`,
      fetchQualityParamProjectBreakdownRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-params-data/projectBreakdown/`,
      filterOptions: [
        { name: "A", id: 1, fullName: "A" },
        { name: "G", id: 2, fullName: "G" },
        { name: "R", id: 3, fullName: "R" },
        { name: "GR", id: 4, fullName: "GR" },
        { name: "B", id: 5, fullName: "B" },
        { name: "NA", id: 6, fullName: "NA" },
      ],
      selectedValue: [],
      selectedColumnHeader: null,
      isProjectTabSelected: false,
      pieData0: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      pieData1: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      pieData2: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      pieData3: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      pieData4: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      pieData5: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#96e079", "#E45266"],
            hoverBackgroundColor: ["#b4f09c", "#f46767"],
          },
        ],
      },
      showProjectListModal: false,
      qualityParamSelected: null,
      currentPage: 0,
      pageSize: 5,
    };
    this.style = {
      chips: {
        background: "#268cd1",
        fontSize: "0.3rem",
        fontFamily: "Ubuntu-Regular",
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px",
        fontSize: "0.45rem",
        marginTop: "0rem",
        textAlign: "center",
        width: "10%",
      },
      multiselectContainer: {
        color: "#268cd1",
        width: "10%",
        alignItems: "center",
      },
      optionContainer: {
        fontSize: "0.55rem",
        fontFamily: "Ubuntu-Regular",
      },
    };
    this.fetchProjectDataByQualityParam =
      this.fetchProjectDataByQualityParam.bind(this);
    this.closeProjectQualityParamModal =
      this.closeProjectQualityParamModal.bind(this);
    this.onBarClick = this.onBarClick.bind(this);
    this.downloadProjectQualityParamExcel =
      this.downloadProjectQualityParamExcel.bind(this);
    this.convertQualityParamValueToText =
      this.convertQualityParamValueToText.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.convertQualityParamValueToColor =
      this.convertQualityParamValueToColor.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  fetchProjectDataByQualityParam(qualityParamSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchProjectByQualityParamRequestURL +
        getQualityParamCol(qualityParamSelected) +
        "/breakdown?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        // get current year
        const currentYear = Object.keys(data)[0];
        // get current month
        const currentMonth = Object.keys(data[currentYear])[0];
        const qualityParamData = data[currentYear][currentMonth];
        this.setState({
          showProjectListModal: true,
          qualityParamSelected: qualityParamSelected,
          qualityParamData: qualityParamData,
          modalTableFilterData:
            qualityParamData[this.state.qualityValueSelected],
        });

        let projectIdName = [];
        for (
          let k = 0;
          k < qualityParamData[this.state.qualityValueSelected].length;
          k++
        ) {
          projectIdName.push(
            qualityParamData[this.state.qualityValueSelected][k].c_project_id +
              "(" +
              qualityParamData[this.state.qualityValueSelected][k]
                .c_project_name +
              ")"
          );
        }
        this.setState({ projectIdName: projectIdName });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params forecast - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  fetchQualityParamActivityBreakdown(qualityParam, qualityValueSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchQualityParamActivityBreakdownRequestURL +
        getQualityParamCol(qualityParam) +
        "/" +
        qualityValueSelected +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data.chartData;
        let currentYear, currentMonth, horizontalBarChartData;

        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth = data[currentYear]
            ? Object.keys(data[currentYear])[0]
            : null;
          horizontalBarChartData =
            data[currentYear][currentMonth][getQualityParamCol(qualityParam)][
              "activities"
            ];
        } else {
          currentYear = null;
          currentMonth = null;
          horizontalBarChartData = null;
        }
        this.setState({
          horizontalBarChartData: horizontalBarChartData,
          showProjectListModal: true,
        });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params current - " + error
        );
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
        });
      });
  }

  fetchQualityParamProjectBreakdown(qualityParam, qualityValueSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchQualityParamProjectBreakdownRequestURL +
        getQualityParamCol(qualityParam) +
        "/" +
        qualityValueSelected +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data.chartData;
        let currentYear = data ? Object.keys(data)[0] : null;
        let currentMonth = currentYear
          ? Object.keys(data[currentYear])[0]
          : null;
        let qualityParamData =
          currentYear && currentMonth && data
            ? Object.entries(data[currentYear][currentMonth])
            : null;
        let projectIdName = [];
        if (qualityParamData) {
          for (let k = 0; k < qualityParamData.length; k++) {
            projectIdName.push(
              qualityParamData[k][0] +
                "(" +
                qualityParamData[k][1]["projectName"] +
                ")"
            );
          }
        }
        /*
        let tableHead = [];
        for (let i = 0; i < qualityParamData.length; i++) {
          let entriesArr = Object.entries(qualityParamData[i][1]);
          for (let j = 0; j < entriesArr.length; j++) {
            if (entriesArr[j][0] != "projectName") {
              if (tableHead.find((element) => element == entriesArr[j][0])) {
              } else {
                tableHead.push(entriesArr[j][0]);
              }
            }
          }
        }
        */

        let dataArray = [];
        for (let i = 0; i < qualityParamData.length; i++) {
          let entriesArr = qualityParamData[i][1]["activities"];
          let activityData = {};
          for (let j = 0; j < entriesArr.length; j++) {
            activityData[entriesArr[j]["activity_id"]] =
              entriesArr[j]["brag_val"];
          }
          dataArray.push([
            qualityParamData[i][0],
            qualityParamData[i][1]["projectName"],
            activityData,
          ]);
        }

        this.setState({
          showModal: true,
          modalTableData: dataArray,
          modalTableFilterData: dataArray,
          modalTableProjectFilterData: dataArray,
          showProjectListModal: true,
          projectIdName: projectIdName,
        });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params current - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  closeProjectQualityParamModal() {
    this.setState({
      showProjectListModal: false,
      currentPage: 0,
      selectedColumnHeader: null,
      selectedValue: null,
      horizontalBarChartData: null,
      isProjectTabSelected: false,
    });
  }

  downloadProjectQualityParamExcel = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title =
      "List of projects with their forecast for Time, Cost, Quality, H&S, Scope, and SME Assessment";
    const headers = [
      [
        "Project Id",
        "Project Name",
        "Time",
        "Cost",
        "Quality",
        "H&S",
        "Scope",
        "SME SA",
      ],
    ];

    let data = this.state.qualityParamData[this.state.qualityValueSelected].map(
      (elt) => [
        elt.c_project_id,
        elt.c_project_name ? elt.c_project_name : "-",
        elt.c_time,
        elt.c_cost,
        elt.c_quality,
        elt.c_h_s,
        elt.c_scope,
        elt.c_sme_self_assessments,
      ]
    );

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
      columnStyles: { 0: { halign: "center" } },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Forecast_ProjectQualityParamData.pdf");
  };

  onBarClick(label, event, i) {
    if (i && i[0]) {
      let qualityValueSelected = "G";
      if (i[0]._view.label == " Red") {
        qualityValueSelected = "R";
      }
      this.setState(
        {
          qualityValueSelected: qualityValueSelected,
          qualityParamSelected: label,
        },
        () => {
          this.fetchQualityParamProjectBreakdown(label, qualityValueSelected);
          this.fetchQualityParamActivityBreakdown(label, qualityValueSelected);
        }
      );
    }
  }

  convertQualityParamValueToText(value) {
    if (value == "G") {
      return "Green";
    } else if (value == "R") {
      return "Red";
    } else if (value == "A") {
      return "Amber";
    } else if (value == "B") {
      return "Blue";
    } else {
      return value;
    }
  }

  convertQualityParamValueToColor(value) {
    if (value == "G") {
      return "#2DC593";
    } else if (value == "R") {
      return "#E45266";
    } else if (value == "A") {
      return "#FFBF00";
    } else if (value == "B") {
      return "#268CD1";
    } else {
      return "light";
    }
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let modalTableFilterData = [];
    let dataArray = [];
    let item = null;
    let arr = [];
    if (value === null || value === "") {
      this.setState(
        {
          modalTableFilterData: this.state.modalTableData,
          modalTableProjectFilterData: this.state.modalTableData,
        },
        this.onSelect(item, arr)
      );
    } else {
      this.state.modalTableData.map((data) => {
        dataArray.push(data);
        if (data[0].toLowerCase().indexOf(value.toLowerCase()) === 0) {
          modalTableFilterData.push(data);
        }
        if (dataArray.length === this.state.modalTableData.length) {
          this.setState(
            {
              modalTableFilterData: modalTableFilterData,
              modalTableProjectFilterData: modalTableFilterData,
            },
            this.onSelect(item, arr)
          );
        }
      });
    }
  }

  getSortDesOrder(prop) {
    return function (a, b) {
      if (a[2][prop] < b[2][prop]) {
        return 1;
      } else if (a[2][prop] > b[2][prop]) {
        return -1;
      }
      return 0;
    };
  }

  getSortAscOrder(prop) {
    return function (a, b) {
      if (a[2][prop] > b[2][prop]) {
        return 1;
      } else if (a[2][prop] < b[2][prop]) {
        return -1;
      }
      return 0;
    };
  }

  arraySortAscending(key) {
    let array = this.state.modalTableFilterData;
    if (array !== null) {
      array.sort(this.getSortAscOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: "",
      moreClickKey: key,
    });
  }

  arraySortDescending(key) {
    let array = this.state.modalTableFilterData;
    if (array !== null) {
      array.sort(this.getSortDesOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: key,
      moreClickKey: "",
    });
  }

  downloadTablePdf = () => {
    this.setState({ isDownloading: true });
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title =
      "List of Projects For Forecasted Status '" +
      this.state.qualityValueSelected +
      "' in " +
      this.state.qualityParamSelected;
    let headers = [];
    let headings = [];
    let data = [];
    let dataArr = this.state.modalTableFilterData;
    let headerArr = Object.entries(this.state.modalTableData[0][2]);

    headings.push("Project ID");
    headings.push("Project Name");

    for (let i = 0; i < headerArr.length; i++) {
      headings.push(
        this.props.csvColumnMapping[headerArr[i][0].replace(/-/g, "_")]
          ? this.props.csvColumnMapping[headerArr[i][0].replace(/-/g, "_")]
          : headerArr[i].replace(/-/g, " ")
      );
    }
    headers.push(headings);

    for (let i = 0; i < dataArr.length; i++) {
      let array = [];
      array.push(dataArr[i][0]);
      array.push(dataArr[i][1]);
      for (let j = 0; j < headerArr.length; j++) {
        if (dataArr[i][2][headerArr[j][0]]) {
          array.push(dataArr[i][2][headerArr[j][0]]);
        } else {
          array.push("-");
        }
      }
      data.push(array);
    }

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#808080";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "Correct") {
          data.cell.styles.textColor = [45, 197, 147];
        }

        if (data.cell.raw == "Incorrect") {
          data.cell.styles.textColor = "#E45266";
        }

        if (data.cell.raw == "Pending") {
          data.cell.styles.textColor = "#919BA3";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(title + ".pdf");
    this.setState({ isDownloading: false });
  };

  onSelect(item, selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList, selectedColumnHeader: item });
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (
            data[2][item].toLowerCase() == selectedList[i].value.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  onRemove(item, selectedList, selectedItem) {
    console.log(selectedList);
    this.setState({ selectedValue: selectedList, selectedColumnHeader: item });
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (
            data[2][item].toLowerCase() == selectedList[i].name.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  bindEvent() {
    if (document.querySelector(".table-scroll-modal"))
      document
        .querySelector(".table-scroll-modal")
        .addEventListener("scroll", () => {
          let maxWidth = parseInt(
            window
              .getComputedStyle(document.querySelector("thead"))
              .width.replace("px", "")
          );
          let computedWidth =
            parseInt(
              window
                .getComputedStyle(document.querySelector(".table-scroll-modal"))
                .width.replace("px", "")
            ) +
            document.querySelector(".table-scroll-modal").scrollLeft +
            1;
          if (computedWidth > maxWidth) {
            computedWidth = maxWidth;
          }
          document.querySelectorAll("table > *")[1].style.width =
            computedWidth + "px";
          document.querySelectorAll("table > *")[0].style.width =
            computedWidth + "px";
        });
  }

  render() {
    const pieOptions = (label) => {
      return {
        legend: {
          display: false,
          position: "right",
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            fontFamily: "Ubuntu-Medium",
            fontSize: 4,
            font: {
              size: 10,
              family: "Ubuntu-Medium",
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                if (data) {
                  sum += parseInt(data);
                }
              });
              if (!value) {
                return "";
              }
              let percentage = ((value * 100) / sum).toFixed(0) + "%";
              return percentage;
            },
          },
        },
        onClick: this.onBarClick.bind(this, label),
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      };
    };

    const options = {
      responsive: false,
      title: {
        display: false,
        text: "Count of T, C, Q, S, H&S and SMESA",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
        position: "left",
        align: "left",
        fontFamily: "Ubuntu-Medium",
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: false,
              stepSize: 50,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
        },
      },
      tooltips: {
        displayColors: false,
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      onClick: this.onBarClick,
    };

    let greenDataSet = this.props.chartData.greenDataSet;
    let redDataSet = this.props.chartData.redDataSet;

    let chartData0 = this.state.pieData0;
    let chartData1 = this.state.pieData1;
    let chartData2 = this.state.pieData2;
    let chartData3 = this.state.pieData3;
    let chartData4 = this.state.pieData4;
    let chartData5 = this.state.pieData5;

    if (greenDataSet) {
      chartData0.datasets[0].data[0] = greenDataSet[0];
      chartData0.datasets[0].data[1] = redDataSet[0];

      chartData1.datasets[0].data[0] = greenDataSet[1];
      chartData1.datasets[0].data[1] = redDataSet[1];

      chartData2.datasets[0].data[0] = greenDataSet[2];
      chartData2.datasets[0].data[1] = redDataSet[2];

      chartData3.datasets[0].data[0] = greenDataSet[3];
      chartData3.datasets[0].data[1] = redDataSet[3];

      chartData4.datasets[0].data[0] = greenDataSet[4];
      chartData4.datasets[0].data[1] = redDataSet[4];

      chartData5.datasets[0].data[0] = greenDataSet[5];
      chartData5.datasets[0].data[1] = redDataSet[5];
    }

    let divStyle = {
      height: 0 + "rem",
    };

    if (this.state.qualityParamData) {
      if (this.state.qualityParamData[this.state.qualityValueSelected]) {
        if (
          this.state.qualityParamData[this.state.qualityValueSelected].length
        ) {
          if (
            Math.ceil(
              this.state.qualityParamData[this.state.qualityValueSelected]
                .length / this.state.pageSize
            ) -
              this.state.currentPage ===
              1 &&
            this.state.qualityParamData[this.state.qualityValueSelected]
              .length %
              this.state.pageSize !==
              0
          ) {
            divStyle = {
              height:
                2.5 *
                  (this.state.pageSize -
                    (this.state.qualityParamData[
                      this.state.qualityValueSelected
                    ].length %
                      this.state.pageSize)) +
                "rem",
            };
          }
        }
      }
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <React.Fragment>
        <div className="chart-top">
          <div className="chart-header">
            Count of forecasted projects with their impact on Time, Cost,
            Quality, Scope, H&S, and SME Assessment{" "}
            <span
              data-title="The doughnut chart represents the count of projects in percentage of total forecasted projects having a business impact on the forecasted status of Time, Cost, Quality, Scope, H&S and SME Self Assessment as Red or Green​​"
              data-title-xmg
            >
              <MdInfo size={20} className="c-info"></MdInfo>
            </span>
            <div className="rg-div">
              <div className="g-div"></div>
              Green
              <div className="r-div"></div>
              Red
            </div>
          </div>
          <div>
            <Row
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.2rem",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <Col xs={2}>
                <Doughnut
                  data={chartData0}
                  options={pieOptions("Time")}
                  height={180}
                />
                Time
              </Col>
              <Col xs={2}>
                <Doughnut
                  data={chartData1}
                  options={pieOptions("Cost")}
                  height={180}
                />
                Cost
              </Col>
              <Col xs={2}>
                <Doughnut
                  data={chartData2}
                  options={pieOptions("Quality")}
                  height={180}
                />
                Quality
              </Col>
              <Col xs={2}>
                <Doughnut
                  data={chartData3}
                  options={pieOptions("Scope")}
                  height={180}
                />
                Scope
              </Col>
              <Col xs={2}>
                <Doughnut
                  data={chartData4}
                  options={pieOptions("H&S")}
                  height={180}
                />
                H&S
              </Col>
              <Col xs={2}>
                <Doughnut
                  data={chartData5}
                  options={pieOptions("SME")}
                  height={180}
                />
                SME
              </Col>
            </Row>
          </div>
        </div>
        {this.state.showProjectListModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="tcqs-current-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="p-0 tcqs-current-modal-body"
              >
                <div className="p-2">
                  <CancelIcon
                    onClick={this.closeProjectQualityParamModal}
                    className="cancel-close-icon"
                  />
                  <Row>
                    <Col>
                      <center>
                        <span
                          id="key-tasks-to-text"
                          style={{ color: "#252525" }}
                        >
                          {/*
                            List of Activities & Projects Forcasted as '
                            {this.convertQualityParamValueToText(
                              this.state.qualityValueSelected
                            )}
                            ' for TCQi indicator '
                            {this.state.qualityParamSelected}'
                          */}
                          {this.state.isProjectTabSelected ? (
                            <span>
                              List of projects with current status of the
                              activities and milestones that impact forecasted
                              status of {this.state.qualityParamSelected} as{" "}
                              {this.convertQualityParamValueToText(
                                this.state.qualityValueSelected
                              )}
                            </span>
                          ) : (
                            <span>
                              List of activities by their importance that impact
                              forecasted status of{" "}
                              {this.state.qualityParamSelected} as{" "}
                              {this.convertQualityParamValueToText(
                                this.state.qualityValueSelected
                              )}
                            </span>
                          )}
                        </span>
                      </center>
                    </Col>
                  </Row>
                </div>
                <Row className="m-0 menu-tabs">
                  <Col
                    className={`text-right ${
                      !this.state.isProjectTabSelected ? "selected" : ""
                    }`}
                    onClick={() =>
                      this.setState({ isProjectTabSelected: false })
                    }
                  >
                    <BootstrapTooltip
                      placement="left"
                      title="The chart represents activities that impact the selected business measure with their coefficient as the importance of the activity. The higher the coefficient the higher the importance of the activity on the business measure​"
                    >
                      <span>List of Activities</span>
                    </BootstrapTooltip>
                  </Col>
                  <Col
                    className={
                      this.state.isProjectTabSelected ? "selected" : ""
                    }
                    onClick={() =>
                      this.setState({ isProjectTabSelected: true }, () => {
                        this.bindEvent();
                      })
                    }
                  >
                    <BootstrapTooltip
                      placement="right"
                      title="The table represents a list of projects with current BRAG status of activities that impact the forecasted status of the selected measure​"
                    >
                      <span>List of Projects</span>
                    </BootstrapTooltip>
                  </Col>
                </Row>
                {!this.state.isProjectTabSelected ? (
                  this.state.horizontalBarChartData &&
                  JSON.stringify(this.state.horizontalBarChartData) !==
                    JSON.stringify({}) ? (
                    <HorizontalBarChart
                      chartData={this.state.horizontalBarChartData}
                      csvColumnMapping={this.props.csvColumnMapping}
                      qualityValue={this.state.qualityValueSelected}
                      qualityParam={this.state.qualityParamSelected}
                      chartHeight={
                        this.state.horizontalBarChartData.length * 45
                      }
                    />
                  ) : (
                    <div style={{ height: "55.5vh" }}>
                      <br />
                      <div className="no-data-available-current-month-activity">
                        No data available of list of activities for status '
                        {this.state.qualityValueSelected}' in{" "}
                        {this.state.qualityParamSelected}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
                {this.state.isProjectTabSelected ? (
                  this.state.modalTableData &&
                  JSON.stringify(this.state.modalTableData) !==
                    JSON.stringify({}) ? (
                    <div className="p-3">
                      <Row>
                        <Col>
                          <center>
                            <div className="form-input-wrapper-current-month-modal">
                              <Autocomplete
                                options={this.state.projectIdName}
                                onInputChange={this.searchByProject}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Project"
                                  />
                                )}
                                size="small"
                              />
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <div className="current-month-chart">
                              <Table
                                responsive
                                className="table table-md table-modal table-scroll-modal table-list-of-projects table-tcqs-modal"
                                id="table-task-list"
                              >
                                <thead className="table-head">
                                  <tr>
                                    <th
                                      style={{ paddingBottom: "0.97rem" }}
                                      className="project-id-col"
                                    >
                                      Project ID
                                    </th>
                                    <th
                                      style={{ paddingBottom: "0.97rem" }}
                                      className="project-name-col"
                                    >
                                      Project Name
                                    </th>
                                    {this.state.modalTableData
                                      ? Object.entries(
                                          this.state.modalTableData[0][2]
                                        ).map((item) => (
                                          <BootstrapTooltip
                                            placement="top-center"
                                            title={
                                              this.props.csvColumnMapping[
                                                item[0].replace(/-/g, "_")
                                              ]
                                                ? this.props.csvColumnMapping[
                                                    item[0].replace(/-/g, "_")
                                                  ]
                                                : item[0].replace(/-/g, " ")
                                            }
                                          >
                                            <th
                                              className="centre-align-data"
                                              style={{
                                                paddingBottom: this.state
                                                  .selectedColumnHeader
                                                  ? this.state
                                                      .selectedColumnHeader ==
                                                    item[0]
                                                    ? "0.2rem"
                                                    : "0.2rem"
                                                  : "0.2rem",
                                                paddingTop: "0.2rem",
                                              }}
                                            >
                                              {this.state
                                                .selectedColumnHeader ? (
                                                this.state
                                                  .selectedColumnHeader ==
                                                item[0] ? (
                                                  <Select
                                                    closeMenuOnSelect={false}
                                                    blurInputOnSelect={false}
                                                    isMulti
                                                    options={colourOptions}
                                                    styles={colourStyles}
                                                    value={
                                                      this.state.selectedValue
                                                    }
                                                    onChange={this.onSelect.bind(
                                                      this,
                                                      item[0]
                                                    )}
                                                    placeholder={
                                                      this.props
                                                        .csvColumnMapping[
                                                        item[0].replace(
                                                          /-/g,
                                                          "_"
                                                        )
                                                      ]
                                                        ? this.props.csvColumnMapping[
                                                            item[0].replace(
                                                              /-/g,
                                                              "_"
                                                            )
                                                          ].substr(0, 15)
                                                        : item[0]
                                                            .replace(/-/g, " ")
                                                            .substr(0, 15)
                                                    }
                                                    isSearchable
                                                  />
                                                ) : (
                                                  <span>
                                                    {this.props
                                                      .csvColumnMapping[
                                                      item[0].replace(/-/g, "_")
                                                    ]
                                                      ? this.props
                                                          .csvColumnMapping[
                                                          item[0].replace(
                                                            /-/g,
                                                            "_"
                                                          )
                                                        ]
                                                      : item[0].replace(
                                                          /-/g,
                                                          " "
                                                        )}
                                                  </span>
                                                )
                                              ) : (
                                                <Select
                                                  closeMenuOnSelect={false}
                                                  blurInputOnSelect={false}
                                                  isMulti
                                                  options={colourOptions}
                                                  styles={colourStyles}
                                                  value={
                                                    this.state.selectedValue
                                                  }
                                                  onChange={this.onSelect.bind(
                                                    this,
                                                    item[0]
                                                  )}
                                                  placeholder={
                                                    this.props.csvColumnMapping[
                                                      item[0].replace(/-/g, "_")
                                                    ]
                                                      ? this.props.csvColumnMapping[
                                                          item[0].replace(
                                                            /-/g,
                                                            "_"
                                                          )
                                                        ].substr(0, 15)
                                                      : item[0]
                                                          .replace(/-/g, " ")
                                                          .substr(0, 15)
                                                  }
                                                  isSearchable
                                                />
                                              )}
                                              {/*
                                            <ExpandLessIcon
                                              onClick={() => {
                                                this.arraySortDescending(
                                                  item[0]
                                                );
                                              }}
                                              fontSize="small"
                                              className="expand-less-icon-sorting"
                                              style={{
                                                color:
                                                  item[0] ===
                                                  this.state.lessClickKey
                                                    ? "red"
                                                    : "black",
                                              }}
                                            />
                                            <ExpandMoreIcon
                                              onClick={() => {
                                                this.arraySortAscending(
                                                  item[0]
                                                );
                                              }}
                                              fontSize="small"
                                              className="expand-more-icon-sorting"
                                              style={{
                                                color:
                                                  item[0] ===
                                                  this.state.moreClickKey
                                                    ? "red"
                                                    : "black",
                                              }}
                                            />
                                            */}
                                            </th>
                                          </BootstrapTooltip>
                                        ))
                                      : ""}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.modalTableFilterData ? (
                                    this.state.modalTableFilterData.length >=
                                    1 ? (
                                      this.state.modalTableFilterData.map(
                                        (activityObject) => (
                                          <tr>
                                            <td className="table-data-bold project-id-col">
                                              {activityObject[0]}
                                            </td>
                                            <td className="table-data-bold project-name-col">
                                              {activityObject[1]}
                                            </td>
                                            {activityObject[2]
                                              ? Object.entries(
                                                  activityObject[2]
                                                ).map((item) => (
                                                  <td className="table-data-black centre-align-data">
                                                    {item[1] ? (
                                                      item[1] !== "NA" ? (
                                                        <div
                                                          className={
                                                            item[1] === "R"
                                                              ? "red-circle"
                                                              : item[1] === "A"
                                                              ? "amber-circle"
                                                              : item[1] === "GR"
                                                              ? "grey-circle"
                                                              : item[1] === "B"
                                                              ? "blue-circle"
                                                              : "green-circle"
                                                          }
                                                        ></div>
                                                      ) : (
                                                        "-"
                                                      )
                                                    ) : (
                                                      "-"
                                                    )}
                                                  </td>
                                                ))
                                              : ""}
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr></tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        style={{
                                          width: "100vw",
                                          paddingLeft: "40vw",
                                        }}
                                      >
                                        <span style={{ marginLeft: "18rem" }}>
                                          No data available for '
                                          {this.state.qualityValueSelected}' in{" "}
                                          {this.state.qualityParamSelected}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 12, offset: 0 }}>
                          <Row>
                            <Col>
                              <center>
                                <Button
                                  color="primary"
                                  onClick={this.downloadTablePdf}
                                  size="sm"
                                  id="download-list-key-actvity"
                                  disabled={
                                    this.state.modalTableFilterData &&
                                    JSON.stringify(
                                      this.state.modalTableFilterData
                                    ) !== JSON.stringify([])
                                      ? false
                                      : true
                                  }
                                  className="download-list-current-month"
                                >
                                  <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                                </Button>
                              </center>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div style={{ height: "55.5vh" }}>
                      <br />
                      <div className="no-data-available-current-month-activity">
                        No data available of list of projects for status '
                        {this.state.qualityValueSelected}' in{" "}
                        {this.state.qualityParamSelected}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
              </ModalBody>
            </Modal>
            {/*
                {this.state.qualityParamData &&
                this.state.qualityParamData[
                  this.state.qualityValueSelected
                ] ? (
                  Math.ceil(
                    this.state.qualityParamData[
                      this.state.qualityValueSelected
                    ].length / this.state.pageSize
                  ) > 1 ? (
                    <div>
                      <span className="showing-span">
                        <span className="showing-text-pagination">
                          Showing{" "}
                        </span>
                        <span>{this.state.currentPage + 1} </span>
                        <span className="showing-text-pagination">
                          out of{" "}
                        </span>
                        <span>
                          {Math.ceil(
                            this.state.qualityParamData[
                              this.state.qualityValueSelected
                            ].length / this.state.pageSize
                          )}{" "}
                        </span>
                        <span className="showing-text-pagination">
                          pages{" "}
                        </span>
                      </span>
                      <div id="tcqs-modal-pagination-div">
                        <div id="tcqs-modal-pagination-style">
                          <TablePagination
                            pagesCount={Math.ceil(
                              this.state.qualityParamData[
                                this.state.qualityValueSelected
                              ].length / this.state.pageSize
                            )}
                            currentPage={this.state.currentPage}
                            setCurrentPage={this.setCurrentPage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
            */}
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default CurrentMonthPieChart;
