import React, { Component } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import ProjectTCQIChart from "./ProjectTCQIChart";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class SingleProjectHealthScoreTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Health Score",
            backgroundColor: "#4472C4",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
        ],
      },
      showChart: false,
      showCurrentData: true,
      showForecastedData: this.props.isForecasted,
      showMonthlyTCQIChart: false,
      projectId: this.props.projectId,
      getSingleProjectTCQIURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-tcqi-performance/`,
      montlyTCQIData: null,
      dataMonths: null,
    };
  }

  closeModal = () => {
    this.setState({
      showMonthlyTCQIChart: false,
      montlyTCQIData: null
    });
  };

  onBarClick = (event, elements) => {
    // call API to get project monthly tcqi chart data
    const projectId = this.props.projectId;

    const chart = elements[0]._chart;
    const element = chart.getElementAtEvent(event)[0];
    //const dataset = chart.data.datasets[element._datasetIndex];
    const xLabel = chart.data.labels[element._index];
    // const value = dataset.data[element._index];

    const monthNames = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const month = monthNames[xLabel.split('-')[0].trim()]
    const year = xLabel.split('-')[1].trim();
    axios({
      url: this.state.getSingleProjectTCQIURL + projectId + '?' + 'month=' + month + '&year=' + year,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        const montlyTCQIData = data.comparisonData
        const dataMonths = Object.keys(montlyTCQIData)

        this.setState({
          montlyTCQIData: montlyTCQIData,
          showMonthlyTCQIChart: true,
          dataMonths: dataMonths
        });
      })
      .catch((error) => {
        console.log("error in getting project tcqi data" + error);
      });

  }

  updateData = (e) => {
    if (e.target.value === "C") {
      this.setState({ showForecastedData: false, showCurrentData: true });
    } else {
      this.setState({ showForecastedData: true, showCurrentData: false });
    }
  };

  render() {
    const chartData = this.state.data;
    let showChart = this.state.showChart
    let showForecastedData
    if ((this.props.projectCurrentHealthData || this.props.projectForecastedHealthData)) {
      if (Object.keys(this.props.projectCurrentHealthData).length > 0 || Object.keys(this.props.projectForecastedHealthData).length > 0) {
        showChart = true
      }
      showForecastedData = this.state.showForecastedData
      chartData.datasets[0].data = showForecastedData ? Object.values(this.props.projectForecastedHealthData) : Object.values(this.props.projectCurrentHealthData)
      chartData.labels = showForecastedData ? Object.keys(this.props.projectForecastedHealthData) : Object.keys(this.props.projectCurrentHealthData)

    }

    const options = {
      responsive: false,
      title: {
        display: false,
        text: "Health Score Trend",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return value + "%";
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      onClick: this.onBarClick,
    };

    return (
      <Col>
        {showChart == false && <div></div>}
        {showChart == true && <div
          className="chart-top"
          style={{
            fontSize: "0.8rem",
            textAlign: "center",
          }}
        >
          <Row>
            <Col>
              <div className="chart-header" style={{ paddingBottom: '50px' }}>
                <div style={{ float: 'left', paddingTop: '12px' }} className="chart-buttons2">
                  <span>Health score trend</span>
                  <BootstrapTooltip
                    placement="right-start"
                    title="The chart represents the Health score trend​"
                  >
                    <span>
                      <MdInfo
                        size={20}
                        className="c-info"
                        style={{ marginTop: "-0.5rem" }}
                      ></MdInfo>
                    </span>
                  </BootstrapTooltip>
                </div>
                <div style={{ float: 'right', display:'none' }} className="chart-buttons2"> 
                {// hide this div. Remove it later
                }
                  <label className="radio-label">
                    Forecasted
                    <input
                      type="radio"
                      name="showForecastedData"
                      onChange={(e) => {
                        this.updateData(e);
                      }}
                      value="F"
                      checked={this.state.showForecastedData}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                    Current
                    <input
                      type="radio"
                      name="showCurrentData"
                      onChange={(e) => {
                        this.updateData(e);
                      }}
                      value="C"
                      checked={this.state.showCurrentData}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </div>

              </div>
              <center>
                <br />
                <div style={{ overflowX: 'auto' }}>
                  <Bar
                    data={chartData}
                    width={1000}
                    height={150}
                    options={options}
                  />
                </div>
                {this.state.showMonthlyTCQIChart &&
                  <Modal
                    isOpen="true"
                    centered="true"
                    size="lg"

                  >
                    <ModalBody
                      id="show-key-activities-modal"
                      className="p-0 tcqs-health-modal-body"
                    >
                      <div className="p-2">
                        <CancelIcon
                          onClick={this.closeModal}
                          className="cancel-close-icon"
                        />
                        <Row>
                          <Col>
                            <center>
                              <span
                                id="key-tasks-to-text"
                                style={{ color: "#252525" }}
                              >
                                Comparison of current performance of six key business measures between {this.state.dataMonths[0]} and {this.state.dataMonths[1]}
                              </span>
                            </center>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3" style={{ background: "#fff" }}>
                        <ProjectTCQIChart tcqiChartData={this.state.montlyTCQIData}></ProjectTCQIChart>
                      </div>
                    </ModalBody>
                  </Modal>
                }
              </center>
            </Col>
          </Row>
        </div>}
      </Col>
    );
  }
}

export default SingleProjectHealthScoreTrendChart;
