import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Progress,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
} from "reactstrap";
import axios from "axios";
import { Redirect } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AutoCloseAlert from "../components/AutoCloseAlert";
import PageSpinner from "../components/PageSpinner";
import * as GenericConstants from "../src-constants/GenericConstants";
import "../styles/pages/TrainModelPage.scss";
import { ErrorHandler, ErrorHandlerTimeData } from "../components/ErrorHandler";
import Header from "../components/Header";
import {
  HEADER_TYPE_TEXT,
  HEADER_TYPE_JSON,
} from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";

class TrainModelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/time-data`,
      getModelTrainingProgressDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/model/train-model-progress?trainingId=`,
      startModelTrainingRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/model/train-model`,
      getDataTimelineRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/data-timeline`,
      getTrainingStatusRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/model/training-status`,
      progressData: null,
      yearList: null,
      monthNames: null,
      fromMonth: null,
      toMonth: null,
      fromYear: null,
      toYear: null,
      fromToError: "",
      trainingModel: false,
      goToPredictions: false,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      trainingId: 0,
      trainingProgressPercentage: -1,
      checkTrainingModelProgressIntervalDelay: 6000, //in milliseconds
      checkTrainingModelProgressIntervalId: null,
      timelineFromYear: null,
      timelineFromMonth: null,
      timelineToYear: null,
      timelineToMonth: null,
      trainingOnFromYear: null,
      trainingOnFromMonth: null,
      trainingOnToYear: null,
      trainingOnToMonth: null,
      trainingAlreadyOn: false,
      isLoading: true,
      showTimedOutModal: false,
      failed: false,
      completed: false,
      adminSelectedMonth: null,
      adminSelectedYear: null,
    };
    this.createFromDropdown = this.createFromDropdown.bind(this);
    this.createToDropdown = this.createToDropdown.bind(this);
    this.onFromMonthChange = this.onFromMonthChange.bind(this);
    this.onFromYearChange = this.onFromYearChange.bind(this);
    this.onToMonthChange = this.onToMonthChange.bind(this);
    this.onToYearChange = this.onToYearChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.viewDashboard = this.viewDashboard.bind(this);
    this.startModelTraining = this.startModelTraining.bind(this);
    this.startModelTrainingAdmin = this.startModelTrainingAdmin.bind(this);    
    this.updateAdminSelectedEndMonth = this.updateAdminSelectedEndMonth.bind(this);
    this.updateAdminSelectedEndYear = this.updateAdminSelectedEndYear.bind(this);
    this.getModelTrainingProgressData = this.getModelTrainingProgressData.bind(
      this
    );
    this.createCheckTrainingModelProgressInterval = this.createCheckTrainingModelProgressInterval.bind(
      this
    );
    this.removeCheckTrainingModelProgressInterval = this.removeCheckTrainingModelProgressInterval.bind(
      this
    );
    this.updateTrainingProgressPercentage = this.updateTrainingProgressPercentage.bind(
      this
    );
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleCloseConfirmationModal = this.toggleCloseConfirmationModal.bind(
      this
    );
    this.closeProgressViewConfirmation = this.closeProgressViewConfirmation.bind(
      this
    );
    this.closeProgressView = this.closeProgressView.bind(this);
    this.updateHealthScoreTesting = this.updateHealthScoreTesting(this);
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  toggleModal() {
    this.setState({ showTimedOutModal: !this.state.showTimedOutModal });
  }

  toggleCloseConfirmationModal() {
    this.setState({ openCloseConfirmation: !this.state.openCloseConfirmation });
  }

  componentWillMount() {
    //this.getTimeData();
    this.getDataTimeline();
    this.getTrainingStatus();
    //this.updateHealthScoreTesting();
  }

  componentWillUnmount() {
    this.removeCheckTrainingModelProgressInterval();
  }

  getTimeData = () => {
    axios({
      url: this.state.timeDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            monthNames: response.data.timeData.monthNames,
            yearList: response.data.timeData.years,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  getDataTimeline = () => {
    axios({
      url: this.state.getDataTimelineRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data.dataTimeline;
          this.setState({
            timelineFromYear: data.startYear,
            timelineFromMonth: data.startMonth,
            timelineToYear: data.endYear,
            timelineToMonth: data.endMonth,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  getTrainingStatus = () => {
    axios({
      url: this.state.getTrainingStatusRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success) {
          const data = response.data.trainingStatus;
          //if (sessionStorage.getItem("traningProgressShowOnOpen") === "false") {
          //this.setState({ trainingModel: false, isLoading: false });
          //} else if (data.isTrainingOn === true) {
          if (data.isTrainingOn === true) {
            this.setState(
              {
                trainingModel: data.isTrainingOn,
                trainingAlreadyOn: data.isTrainingOn,
                trainingId: data.trainingId,
                trainingOnFromYear: data.startYear,
                trainingOnFromMonth: data.startMonth,
                trainingOnToYear: data.endYear,
                trainingOnToMonth: data.endMonth,
                isLoading: false,
              },
              this.createCheckTrainingModelProgressInterval
            );
          } else {
            this.setState({ isLoading: false });
          }
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        let alert = ErrorHandler(error);
        this.setState({ isLoading: false });
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  startModelTraining = () => {
    /*
    const postData = {
      data: {
        startTime: { month: this.state.fromMonth, year: this.state.fromYear },
        endTime: { month: this.state.toMonth, year: this.state.toYear },
      },
    };
    */
    //sessionStorage.setItem("traningProgressShowOnOpen", true);
    //sessionStorage.setItem("startTime", Date.now());
    this.setState({
      failed: false,
      progressData: null,
      trainingProgressPercentage: -1,
      completed: false,
    });
    const postData = {
      data: {
        startTime: {
          month: this.state.timelineFromMonth,
          year: this.state.timelineFromYear,
        },
        endTime: {
          month: this.state.timelineToMonth,
          year: this.state.timelineToYear,
        },
      },
    };
    axios({
      url: this.state.startModelTrainingRequestURL,
      method: "POST",
      data: postData,
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response.data;
        this.setState(
          {
            trainingModel: true,
            trainingId: res.trainData.data.training_id,
          },
          this.createCheckTrainingModelProgressInterval
        );
      })
      .catch((error) => {
        console.log("error");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  startModelTrainingAdmin = () => { //This is only for backadmin
    
    this.setState({
      failed: false,
      progressData: null,
      trainingProgressPercentage: -1,
      completed: false,
      timelineFromMonth: parseInt(this.state.adminSelectedMonth),
      timelineFromYear: parseInt(this.state.adminSelectedYear),
      timelineToMonth: parseInt(this.state.adminSelectedMonth),
      timelineToYear: parseInt(this.state.adminSelectedYear),
    });
    const postData = {
      data: {
        startTime: {
          month: this.state.timelineFromMonth,
          year: this.state.timelineFromYear,
        },
        endTime: {
          month: parseInt(this.state.adminSelectedMonth),
          year: parseInt(this.state.adminSelectedYear),
        },
      },
    };
    axios({
      url: this.state.startModelTrainingRequestURL,
      method: "POST",
      data: postData,
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response.data;
        this.setState(
          {
            trainingModel: true,
            trainingId: res.trainData.data.training_id,
          },
          this.createCheckTrainingModelProgressInterval
        );
      })
      .catch((error) => {
        console.log("error");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  getModelTrainingProgressData() {
    axios({
      url:
        this.state.getModelTrainingProgressDataRequestURL +
        this.state.trainingId,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (
          response.data.trainingStatus &&
          response.data.trainingStatus == "Completed"
        ) {
          this.removeCheckTrainingModelProgressInterval();
          this.setState({ completed: true });
          //sessionStorage.setItem("traningProgressShowOnOpen", false);
          // after successful training, update the health score
          const postData = {
            data: {
              month: this.state.timelineToMonth,
              year: this.state.timelineToYear,
            }
          };
          axios({
            url: `${[process.env.REACT_APP_BACKEND_API,]}/dashboard/health-score`,
            method: "POST",
            data: postData,
            headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
          })
            .then((response) => {
              const res = response.data;

            })
            .catch((error) => {
              let alert = ErrorHandler(error);
              this.setState(
                {
                  showAlert: alert.setAlertShow,
                  alertMsg: alert.setMsgAlert,
                  trainingModel: false,
                },
                this.removeCheckTrainingModelProgressInterval
              );

            });
        }
        if (
          response.data.trainingStatus &&
          response.data.trainingStatus == "Failed"
        ) {
          this.setState(
            {
              showAlert: true,
              alertMsg: ErrorConstants.ERROR_TRAINING_FAILED,
              failed: true,
            },
            this.removeCheckTrainingModelProgressInterval
          );
        }
        if (response.data.progressData) {
          response.data.progressData.map((progress) => {
            if (progress.startTimeInMillis) {
              if (
                Date.now() - progress.startTimeInMillis >
                  GenericConstants.TIME_LIMIT_TRAIN_MODEL_PROGRESS_SCREEN &&
                progress.status == "In progress"
              ) {
                this.removeCheckTrainingModelProgressInterval();
                this.setState({
                  trainingModel: false,
                  showTimedOutModal: true,
                });
              } else if (progress.status == "Failed") {
                if (this.state.failed === false) {
                  this.setState(
                    {
                      showAlert: true,
                      alertMsg: ErrorConstants.ERROR_TRAINING_FAILED,
                      failed: true,
                    },
                    this.removeCheckTrainingModelProgressInterval
                  );
                }
              }
            }
          });
        }
        this.setState(
          { progressData: response.data.progressData },
          this.updateTrainingProgressPercentage
        );
      })
      .catch((error) => {
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            trainingModel: false,
          },
          this.removeCheckTrainingModelProgressInterval
        );
        //sessionStorage.setItem("traningProgressShowOnOpen", false);
      });
  }

  updateHealthScoreTesting() { //only for local testing
    const postData = {
      data: {
        month: 8,
        year: 2022,
      }
    };
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API,]}/dashboard/health-score`,
      method: "POST",
      data: postData,
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response.data;

      })
      .catch((error) => {
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            trainingModel: false,
          },
          this.removeCheckTrainingModelProgressInterval
        );

      });
  }

  createCheckTrainingModelProgressInterval() {
    //setInterval first call happens after the delay only, hence explicitly calling the method once
    this.getModelTrainingProgressData();
    let intervalId = setInterval(
      this.getModelTrainingProgressData,
      this.state.checkTrainingModelProgressIntervalDelay
    );
    this.setState({
      checkTrainingModelProgressIntervalId: intervalId,
    });
  }

  removeCheckTrainingModelProgressInterval() {
    console.log("removeCheckTrainingModelProgressInterval called");
    clearInterval(this.state.checkTrainingModelProgressIntervalId);
    this.setState({
      checkTrainingModelProgressIntervalId: null,
    });
  }

  createFromDropdown = () => {
    return (
      <div>
        <Row>
          <Col>
            <div id="year-month-list-wrapper">
              {/*
              <select
                onChange={this.onFromYearChange}
                id="year-month-select"
                value={this.state.timelineFromYear}
                //value={this.state.fromYear || "Select"}
                onClick={this.handleError}
                disabled
              >
                <option value="">{this.state.timelineFromYear}</option>
                {this.state.yearList
                  ? this.state.yearList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                  : ""}
              </select>
              */}
              <input
                id="year-month-select"
                value={this.state.timelineFromYear}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div id="year-month-list-wrapper">
              {/*
              <select
                onChange={this.onFromMonthChange}
                id="year-month-select"
                value={
                  GenericConstants.MONTH_NAMES[this.state.timelineFromMonth - 1]
                }
                //value={this.state.fromMonth || "Select"}
                onClick={this.handleError}
                disabled
              >
                <option value="">
                  {
                    GenericConstants.MONTH_NAMES[
                      this.state.timelineFromMonth - 1
                    ]
                  }
                </option>
                {this.state.monthNames
                  ? Object.keys(this.state.monthNames).map((key) => (
                      <option key={key} value={key}>
                        {this.state.monthNames[key]}
                      </option>
                    ))
                  : ""}
              </select>
              */}
              <input
                id="year-month-select"
                value={
                  GenericConstants.MONTH_NAMES[this.state.timelineFromMonth - 1]
                }
                disabled
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  onFromMonthChange = (event) => {
    this.setState({ fromMonth: event.target.value || null });
  };

  onFromYearChange = (event) => {
    this.setState({ fromYear: event.target.value || null });
  };

  updateAdminSelectedEndMonth = (event) => {
    this.setState({ adminSelectedMonth: event.target.value || null });
  };

  updateAdminSelectedEndYear = (event) => {
    this.setState({ adminSelectedYear: event.target.value || null });
  };

  createToDropdown = () => {
    return (
      <div>
        <Row>
          <Col>
            <div id="year-month-list-wrapper">
              {/*
              <select
                onChange={this.onToYearChange}
                id="year-month-select"
                value={this.state.timelineToYear}
                //value={this.state.toYear || "Select"}
                onClick={this.handleError}
                disabled
              >
                <option value="">{this.state.timelineToYear}</option>
                {this.state.yearList
                  ? this.state.yearList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                  : ""}
              </select>
              */}
              <input
                id="year-month-select"
                value={this.state.timelineToYear}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div id="year-month-list-wrapper">
              {/*
              <select
                onChange={this.onToMonthChange}
                id="year-month-select"
                value={
                  GenericConstants.MONTH_NAMES[this.state.timelineToMonth - 1]
                }
                //value={this.state.toMonth || "Select"}
                onClick={this.handleError}
                disabled
              >
                <option value="">
                  {GenericConstants.MONTH_NAMES[this.state.timelineToMonth - 1]}
                </option>
                {this.state.monthNames
                  ? Object.keys(this.state.monthNames).map((key) => (
                      <option key={key} value={key}>
                        {this.state.monthNames[key]}
                      </option>
                    ))
                  : ""}
              </select>
              */}
              <input
                id="year-month-select"
                value={
                  GenericConstants.MONTH_NAMES[this.state.timelineToMonth - 1]
                }
                disabled
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  onToMonthChange = (event) => {
    this.setState({ toMonth: event.target.value });
  };

  onToYearChange = (event) => {
    this.setState({ toYear: event.target.value });
  };

  handleError = () => {
    if (
      this.state.yearList !== null &&
      this.state.monthNames !== null &&
      this.state.fromMonth !== null &&
      this.state.toMonth !== null &&
      this.state.fromYear !== null &&
      this.state.toYear !== null
    ) {
      let timelineErrorMessage =
        "Data is available from " +
        this.state.timelineFromMonth +
        " " +
        this.state.timelineFromYear +
        " to " +
        this.state.timelineToMonth +
        " " +
        this.state.timelineToYear +
        ". Please select in this time interval";

      if (this.state.toYear > this.state.fromYear) {
        this.setState({ fromToError: "" });
      } else if (this.state.toYear === this.state.fromYear) {
        if (this.state.toMonth > this.state.fromMonth) {
          this.setState({ fromToError: "" });
        } else {
          this.setState({ fromToError: "From must be less than To" });
        }
      } else {
        this.setState({ fromToError: "From must be less than To" });
      }
    }
  };

  viewDashboard = () => {
    this.props.setPage("forecast");
    this.setState({ goToPredictions: true });
  };

  updateTrainingProgressPercentage = () => {
    let inProgressCount = 0;
    let completedCount = 0;
    //Total 17 trainings will happen, hence hardcoding the total count
    let totalCount = 17;
    if (this.state.completed) {
      //if completed is set to true, set the trainingProgressPercentage to 100%
      this.setState({ trainingProgressPercentage: 100 });
    } else if (this.state.progressData !== null) {
      this.state.progressData.map((progress) => {
        if (progress.status === "In progress") {
          inProgressCount++;
        } else {
          completedCount++;
        }
        if (progress.status === "Failed") {
          completedCount--;
        }
        //totalCount++;
      });
      let trainingProgressPercentage =
        totalCount > 0 ? 100 * (completedCount / totalCount) : -1;
      //round to 2 decimal degits
      let roundedTrainingProgressPercentage =
        Math.round((trainingProgressPercentage + Number.EPSILON) * 100) / 100;
      this.setState({
        trainingProgressPercentage: roundedTrainingProgressPercentage,
      });
    } else {
      this.setState({ trainingProgressPercentage: -1 });
    }
  };

  closeProgressView() {
    this.removeCheckTrainingModelProgressInterval();
    this.setState({ trainingModel: false });
  }

  closeProgressViewConfirmation() {
    this.setState({ openCloseConfirmation: true });
  }

  render() {
    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }
    if (this.state.goToPredictions) {
      return (
        <Redirect
          to={{
            pathname: "/ui/landing/forecast",
            state: {},
          }}
        />
      );
    }
    const username = sessionStorage.getItem("username");

    if (this.state.isLoading) {
      return (
        <div>
          {this.state.showAlert ? (
            <AutoCloseAlert
              color={this.state.alertcolor}
              body={this.state.alertMsg}
              showAlert={this.state.showAlert}
              setShowAlert={this.setShowAlert}
            />
          ) : (
            ""
          )}
          <Header open={this.props.open} setOpen={this.props.setOpen}>
            <h5 className="header-common-heading">Train Model</h5>
          </Header>
          <PageSpinner />
        </div>
      );
    }

    if (this.state.trainingModel) {
      /*
      let remainingTime;
      if (
        Date.now() - parseInt(sessionStorage.getItem("startTime")) <=
        GenericConstants.TIME_LIMIT_TRAIN_MODEL_PROGRESS_SCREEN
      ) {
        remainingTime =
          parseInt(sessionStorage.getItem("startTime")) +
          GenericConstants.TIME_LIMIT_TRAIN_MODEL_PROGRESS_SCREEN -
          Date.now();
      } else {
        remainingTime = 0;
      }
      window.setTimeout(() => {
        this.removeCheckTrainingModelProgressInterval();
        sessionStorage.setItem("traningProgressShowOnOpen", false);
        this.setState({ trainingModel: false });
      }, remainingTime);
      
      let monfrom = this.state.fromMonth;
      let yearfrom = this.state.fromYear;
      let monto = this.state.toMonth;
      let yearto = this.state.toYear;
      */
      const arr = GenericConstants.MONTH_NAMES;
      let monfrom = this.state.timelineFromMonth;
      let yearfrom = this.state.timelineFromYear;
      let monto = this.state.timelineToMonth;
      let yearto = this.state.timelineToYear;

      if (this.state.trainingAlreadyOn === true) {
        monfrom = this.state.trainingOnFromMonth;
        yearfrom = this.state.trainingOnFromYear;
        monto = this.state.trainingOnToMonth;
        yearto = this.state.trainingOnToYear;
      }

      return (
        <div>
          {this.state.openCloseConfirmation === true ? (
            <Modal
              isOpen="true"
              centered="true"
              id="confirmation-modal"
              size="sm"
            >
              <ModalHeader id="confirmation-modal-head" size="sm">
                <div id="delete-confirmation-text">
                  Close Screen Confirmation
                </div>
              </ModalHeader>
              <ModalBody size="sm" id="confirmation-modal-body">
                <div>Are you sure to close the training progress screen?</div>
              </ModalBody>
              <ModalFooter id="confirmation-modal-footer" size="sm">
                <Button
                  color="primary"
                  size="sm"
                  id="yes-no-button"
                  onClick={this.closeProgressView}
                >
                  YES
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  id="yes-no-button"
                  onClick={this.toggleCloseConfirmationModal}
                  active={true}
                >
                  NO
                </Button>
              </ModalFooter>
            </Modal>
          ) : (
            ""
          )}
          {/* {this.getModelTrainingProgressData()} */}
          <Header open={this.props.open} setOpen={this.props.setOpen}>
            <h5 className="header-common-heading">Train Model</h5>
          </Header>
          <CancelIcon
            className="cancel-close-icon" style={{display: "none"}}
            onClick={this.closeProgressView}
          />
          <Row>
            <Col
              md={{
                size: window.innerWidth < 1000 ? 6 : 4,
                offset: window.innerWidth < 1000 ? 3 : 4,
              }}
              sm={{ size: 10, offset: 1 }}
            >
              <div id="select-year-text">
                From
                <br />
                <div id="year-month-list-wrapper">
                  <input
                    id="year-month-select"
                    value={arr[monfrom - 1] + " " + yearfrom}
                    disabled
                  />
                </div>
              </div>
              <div id="select-month-text">
                To
                <br />
                <div id="year-month-list-wrapper">
                  <input
                    id="year-month-select"
                    value={arr[monto - 1] + " " + yearto}
                    disabled
                  />
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            {this.state.trainingProgressPercentage === 100 &&
            this.state.failed === false &&
            this.state.completed === true ? (
              <Col md={{ size: 4, offset: 4 }} id="model-sucessfull-text">
                <CheckCircleIcon id="check-icon-train-model" />
                &nbsp;&nbsp;Model Created Successfully
              </Col>
            ) : this.state.failed === true ? (
              <Col md={{ size: 4, offset: 4 }} id="model-sucessfull-text">
                <CancelIcon fontSize="small" color="secondary" />
                &nbsp;&nbsp;Modal Creation Failed
              </Col>
            ) : (
              <Col md={{ size: 6, offset: 3 }}>
                {this.state.trainingProgressPercentage == -1
                  ? "0%"
                  : this.state.trainingProgressPercentage + "%"}
                {this.state.trainingProgressPercentage !== 100 ? (
                  <span style={{ marginLeft: "0.8rem" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="primary"
                    />
                    <span className="sr-only">Loading...</span>
                  </span>
                ) : (
                  ""
                )}
                <Progress
                  value={
                    this.state.trainingProgressPercentage == -1
                      ? 0
                      : this.state.trainingProgressPercentage
                  }
                />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col
              md={{
                size: window.innerWidth < 1000 ? 12 : 10,
                offset: window.innerWidth < 1000 ? 0 : 1,
              }}
            >
              <Table responsive className="table table-sm table-custom">
                <thead className="table-head">
                  <tr>
                    <th>Milestone</th>
                    <th>Trained By</th>
                    <th className="centre-align-data">Date of Training</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.progressData
                    ? this.state.progressData.map((progress) => (
                        <tr>
                          <td className="table-data-main">
                            {progress.recordId}
                          </td>
                          <td id="validate-table-data">{progress.trainBy}</td>
                          <td
                            id="validate-table-data"
                            className="centre-align-data"
                          >
                            {progress.trainingDate.split(",")[0]}
                          </td>
                          <td
                            id="validate-table-data"
                            style={{
                              color:
                                progress.status === "Completed"
                                  ? "#2DC593"
                                  : progress.status === "Failed"
                                  ? "#f46767"
                                  : "#EB7B15",
                            }}
                          >
                            {progress.status}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </Col>
          </Row>
          <br />
          <Row>
            {this.state.trainingProgressPercentage === 100 ? (
              <Col md={{ size: 4, offset: 4 }} xs={{ size: 12, offset: 0 }}>
                <centre>
                  <Button
                    color="primary"
                    size="lg"
                    onClick={this.viewDashboard}
                    id="view-dashboard-button"
                  >
                    VIEW DASHBOARD
                  </Button>
                </centre>
              </Col>
            ) : (
              <div></div>
            )}
          </Row>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <h5 className="header-common-heading">Train Model</h5>
        </Header>
        <div>
          {this.state.showTimedOutModal ? (
            <Modal
              isOpen="true"
              centered="true"
              id="confirmation-modal"
              size="sm"
            >
              <ModalHeader id="confirmation-modal-head" size="sm">
                <div id="delete-confirmation-text">
                  Model Training Timed-out
                </div>
              </ModalHeader>
              <ModalBody size="sm" id="confirmation-modal-body">
                <div>
                  This training is taking longer than expected. Please contact
                  your technology support team.
                </div>
              </ModalBody>
              <ModalFooter id="confirmation-modal-footer" size="sm">
                <Button
                  color="secondary"
                  size="sm"
                  id="yes-no-button"
                  onClick={this.toggleModal}
                  active={true}
                >
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          ) : (
            ""
          )}
          {this.state.showAlert ? (
            <AutoCloseAlert
              color={this.state.alertcolor}
              body={this.state.alertMsg}
              showAlert={this.state.showAlert}
              setShowAlert={this.setShowAlert}
            />
          ) : (
            ""
          )}
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              <div id="select-month-text-train-model">
                <div id="from-text">From</div>
                <br />
                {this.createFromDropdown()}
              </div>
              <br />
              <div id="select-year-text-train-model">
                <div id="from-text">To</div>
                <br />
                {this.createToDropdown()}
              </div>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              {
                <span className="errorMessage" id="error-message-from-to">
                  {this.state.fromToError}
                </span>
              }
              {this.state.fromToError === "" ? <br /> : <div></div>}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={{ size: 4, offset: 4 }}>
              {username !== 'backadmin' &&
                <Button
                  color="primary"
                  size="lg"
                  id="train-model-main-button"
                  onClick={this.startModelTraining}
                  /*
                  disabled={
                    this.state.fromToError === ""
                      ? this.state.fromMonth !== null &&
                        this.state.toMonth !== null &&
                        this.state.fromYear !== null &&
                        this.state.toYear !== null
                        ? false
                        : true
                      : true
                  }
                  */
                  disabled={
                    this.state.timelineFromYear !== null &&
                      this.state.timelineFromMonth !== null &&
                      this.state.timelineToMonth !== null &&
                      this.state.timelineToYear !== null &&
                      this.state.timelineFromYear !== undefined &&
                      this.state.timelineFromMonth !== undefined &&
                      this.state.timelineToMonth !== undefined &&
                      this.state.timelineToYear !== undefined
                      ? false
                      : true
                  }
                  block
                >
                  TRAIN MODEL
                </Button>
              }
              {username === 'backadmin' &&
                <div>
                  Enter Month: <input name="adminSelectedEndMonth" onChange={(e) => this.updateAdminSelectedEndMonth(e)}></input>
                  <br></br>
                  Enter Year: <input name="adminSelectedEndYear" onChange={(e) => this.updateAdminSelectedEndYear(e)}></input>
                  <Button
                    color="primary"
                    size="lg"
                    id="train-model-main-button"
                    onClick={this.startModelTrainingAdmin}
                    disabled={
                      this.state.timelineFromYear !== null &&
                        this.state.timelineFromMonth !== null &&
                        this.state.timelineToMonth !== null &&
                        this.state.timelineToYear !== null &&
                        this.state.timelineFromYear !== undefined &&
                        this.state.timelineFromMonth !== undefined &&
                        this.state.timelineToMonth !== undefined &&
                        this.state.timelineToYear !== undefined
                        ? false
                        : true
                    }
                    block
                  >
                    TRAIN MODEL
                  </Button>
                </div>
              }
            </Col>
          </Row>
          {/*
          <br />
          <br />
          <br />
          <Row>
            <Col>
              <Card id="no-pending-registration-card">
                <h6 id="training-history-heading">Training History</h6>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Table responsive className="table table-md table-custom">
                <thead className="table-head">
                  <tr>
                    <th>
                      <span>File Name</span>
                      <ExpandLessIcon
                        onClick={() => {
                          this.arraySortDescending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-less-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.lessClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          this.arraySortAscending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-more-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.moreClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                    </th>
                    <th>
                      <span>User</span>
                      <ExpandLessIcon
                        onClick={() => {
                          this.arraySortDescending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-less-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.lessClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          this.arraySortAscending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-more-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.moreClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                    </th>
                    <th>
                      <span>From</span>
                      <ExpandLessIcon
                        onClick={() => {
                          this.arraySortDescending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-less-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.lessClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          this.arraySortAscending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-more-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.moreClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                    </th>
                    <th>
                      <span>To</span>
                      <ExpandLessIcon
                        onClick={() => {
                          this.arraySortDescending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-less-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.lessClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          this.arraySortAscending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-more-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.moreClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                    </th>
                    <th>
                      <span>Trained On</span>
                      <ExpandLessIcon
                        onClick={() => {
                          this.arraySortDescending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-less-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.lessClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                      <ExpandMoreIcon
                        onClick={() => {
                          this.arraySortAscending("Project Id");
                        }}
                        fontSize="small"
                        className="expand-more-icon-sorting"
                        style={{
                          color:
                            "Project Id" === this.state.moreClickKey
                              ? "red"
                              : "black",
                        }}
                      />
                    </th>
                  </tr>
                </thead>
              </Table>
              <Card id="no-history-data-upload-card">
                <h6 id="no-history-data-upload">
                  No data available for training history
                </h6>
              </Card>
            </Col>
          </Row>
          */}
        </div>
      </React.Fragment>
    );
  }
}

export default TrainModelPage;
