import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class ProjectTCQIChartSingleMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Health Score",
            //backgroundColor: "#239bec",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
        ],
      },
    };
  }

  render() {
    const chartData = this.state.data;
    const tcqiChartData = this.props.tcqiChartData;
    if (tcqiChartData) {
      const dataMonths = Object.keys(tcqiChartData);
      const values = Object.values(
        tcqiChartData[dataMonths[0]]
      );
      const dataArr = values.map((item) => item || 0);
      let barColors = [], dataVal
      for (let i = 0; i < dataArr.length; i++) {
        dataVal = dataArr[i]
        if (dataVal >= 0 && dataVal <= 33) {
          barColors.push('#e45266')
        } else if (dataVal >= 34 && dataVal <= 66) {
          barColors.push('#eb7b15')
        } else if (dataVal >= 67 && dataVal <= 100) {
          barColors.push('#96e079')
        }/* else if (dataVal == 100) {
          barColors.push('#268cd1')
        }*/
      }
      chartData.datasets[0].data = dataArr
      chartData.datasets[0].label = dataMonths[0];
      chartData.datasets[0].backgroundColor = barColors;
      const labels = Object.keys(tcqiChartData[dataMonths[0]]);
      chartData.labels = labels.map(
        (item) => item[0].toUpperCase() + item.slice(1)
      );
    }
    const options = {
      responsive: false,
      title: {
        display: false,
        text: "TCQI trend",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
        position: "bottom",
        backgroundColor: "grey"
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return value + "%";
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      onClick: (event, i) => {
        const barValues = ['c_time', 'c_cost', 'c_quality', 'c_scope','c_h_s','c_sme_self_assessments']
        let barVal = barValues[i[0]._index]
        this.props.barClickFunc(barVal);
      },
    };
    return (
      <Row>
        <Col>
          <center>
            <div>
              <div className="custom-y-label" style={{ left: "-50%", top: "215px" }}>% Health score</div>
              <Bar
                data={chartData}
                width={670}
                height={350}
                options={options}
              /><br></br>
              <div className="custom-x-label" style={{ marginLeft: "60%" }}>Business Measures</div>
            </div>
          </center>
        </Col>
      </Row>
    );
  }
}

export default ProjectTCQIChartSingleMonth;
