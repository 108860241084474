import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from "axios";
import copy from "copy-to-clipboard";

import loginImage from "../images/login-image.svg";
import logo from "../images/logo-image.png";
import Divider from "@material-ui/core/Divider";
import "../styles/pages/ForgotPasswordPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandlerSignUpReset } from "../components/ErrorHandler";
import { HEADER_SIGNIN_PAGES } from "../components/RequestHeader";

const passwordRegex = RegExp(
  /(?=^.{8,200}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
);

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/forgot-password`,
      username: null,
      password: null,
      confirmPassword: null,
      showMessage: false,
      found: false,
      message: "",
      formErrors: {
        username: "",
        password: "",
        confirmPassword: "",
      },
      redirectToReferrer: false,
      tokenNumber: "",
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
    };
    this.Copytext = this.Copytext.bind(this);
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.username === null) {
      this.setState((prevState) => {
        let formErrors = Object.assign({}, prevState.formErrors);
        formErrors.username = "Username cannot be empty";
        return { formErrors };
      });
    }
    if (this.state.password === null) {
      this.setState((prevState) => {
        let formErrors = Object.assign({}, prevState.formErrors);
        formErrors.password = "Password cannot be empty";
        return { formErrors };
      });
    }
    if (this.state.confirmPassword === null) {
      this.setState((prevState) => {
        let formErrors = Object.assign({}, prevState.formErrors);
        formErrors.confirmPassword = "Confirm Password cannot be empty";
        return { formErrors };
      });
    }

    const postData = {
      data: {
        username: this.state.username.toLowerCase(),
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      },
    };
    if (
      this.state.formErrors.username === "" &&
      this.state.formErrors.password === "" &&
      this.state.formErrors.confirmPassword === ""
    ) {
      return axios({
        url: this.state.forgotPasswordRequestURL,
        method: "POST",
        data: postData,
        headers: HEADER_SIGNIN_PAGES(),
      })
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ redirectToReferrer: true });
            this.setState({ tokenNumber: response.data.passwordToken });
          } else if (response.data.success === false) {
            this.setState({ message: response.data.message });
            this.setState({ showMessage: true });
          }
        })
        .catch((error) => {
          let alert = ErrorHandlerSignUpReset(
            error,
            "RESET PASSWORD failed, please contact your Assurance Champion for assistance"
          );
          this.setState({
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            message: alert.message,
            showMessage: alert.showMessage,
          });
        });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "username":
        formErrors.username =
          value.length < 5 ? "Minimum 5 characters required" : "";
        break;
      case "password":
        formErrors.password = passwordRegex.test(value)
          ? ""
          : "Min 8 characters with at least one capital, one special character and one number required";

        if (passwordRegex.test(value)) {
          this.setState({ found: true });
        }
        break;
      case "confirmPassword":
        formErrors.confirmPassword =
          value === this.state.password
            ? ""
            : "Password and Confirm Password are not matching";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  Copytext(e) {
    e.preventDefault();
    this.setState({ tokenMessage: "Token number copied to clipboard" });
    copy(this.state.tokenNumber);
  }

  render() {
    if (this.state.redirectToReferrer) {
      return (
        <div id="main-div-forgot-password">
          <Row style={{ margin: "0" }}>
            <Col md={6} className="login-form" style={{ padding: "0" }}>
              <Container
                component="main"
                maxWidth="xs"
                style={{ alignItems: "flex-start" }}
              >
                <CssBaseline />
                <br />
                <div>
                  <img src={logo} alt="" id="logo-signin" align="left" />
                  &nbsp;
                </div>
                <br />
                <br />
                <div id="paper-style">
                  <form id="form-style">
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ fontFamily: "Ubuntu", textAlign: "left" }}
                    >
                      Request Generated
                    </Typography>
                    <div id="please-send-token-text">
                      <br />
                      Please copy the following token number and email it to
                      your assurance analyst
                      <br />
                      or
                      <br />
                      Please contact the PMO assurance team
                    </div>
                    {/*<div id="token-send-email-text">Administrator</div>*/}
                    <br />
                    <Row>
                      <Col xm={6}>
                        <input
                          type="text"
                          name="name"
                          value={this.state.tokenNumber}
                          id="token-box"
                          className="rounded-lg"
                          disabled
                        />
                      </Col>
                      <Col xm={6}>
                        <Button
                          color="light"
                          onClick={this.Copytext}
                          id="copy-button"
                          className="rounded-lg"
                        >
                          <div id="copy-text">Copy</div>
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <span style={{ color: "#268CD1", float: "left" }}>
                      {this.state.tokenMessage}
                    </span>
                  </form>
                  <br />
                </div>
              </Container>
              <Container component="main" maxWidth="xs">
                <Divider />
                <br />
                <div>
                  <Row>
                    <Col xm={6}>
                      <div style={{ fontFamily: "Ubuntu", textAlign: "left" }}>
                        You can login once your request is approved
                      </div>
                      <br />
                    </Col>
                    <Col>
                      <Link to={`/ui/signin`}>
                        <Button
                          color="light"
                          id="go-back-to-login-button"
                          className="text-white rounded-lg"
                        >
                          <div style={{ color: "#268CD1" }}>
                            Go Back To Login
                          </div>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
                <br />
                <br />
              </Container>
            </Col>
            <Col md={6} className="login-img">
              <img
                src={loginImage}
                alt=""
                className="img-fuid"
                id="login-image"
                align="right"
              />
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div id="main-div-forgot-password">
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        <Row style={{ margin: "0" }}>
          <Col md={6} className="login-form" style={{ padding: "0" }}>
            <Container
              component="main"
              maxWidth="xs"
              style={{ alignItems: "flex-start" }}
            >
              <CssBaseline />
              <br />
              <div>
                <img src={logo} alt="" id="logo-signin" align="left" />
                &nbsp;
              </div>
              <br />
              <br />
              <div id="paper-style">
                <form id="form-style">
                  <div id="assurance-tool-text" style={{ textAlign: "left" }}>
                    <b>Forgot Password</b>
                  </div>
                  <div id="please-authenticate-text">
                    <br />
                    Please set new password to continue
                  </div>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {this.state.showMessage && (
                        <span className="errorMessage" id="main-error-message">
                          {this.state.message}
                        </span>
                      )}
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        style={{ fontFamily: "Ubuntu" }}
                        noValidate
                        onChange={this.handleChange}
                      />
                      {this.state.formErrors.username.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.username}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        noValidate
                        onChange={this.handleChange}
                      />
                      {this.state.formErrors.password.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.password}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        onChange={this.handleChange}
                      />
                      {this.state.formErrors.confirmPassword.length > 0 && (
                        <span className="errorMessage" id="error-message">
                          {this.state.formErrors.confirmPassword}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    block
                    color="primary"
                    id="submit-style"
                    onClick={this.handleSubmit}
                    className="rounded-lg"
                    disabled={
                      this.state.formErrors.username === "" &&
                      this.state.formErrors.password === "" &&
                      this.state.formErrors.confirmPassword === "" &&
                      this.state.username !== null &&
                      this.state.password !== null &&
                      this.state.confirmPassword !== null
                        ? false
                        : true
                    }
                  >
                    RESET PASSWORD
                  </Button>
                  <Link to="login">
                    <Button
                      color="light"
                      block
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "0.4rem",
                        color: "#268cd1",
                      }}
                    >
                      CANCEL
                    </Button>
                  </Link>
                </form>
                <br />
                <br />
              </div>
            </Container>
          </Col>
          <Col md={6} className="login-img">
            <img
              src={loginImage}
              alt=""
              className="img-fuid"
              id="login-image"
              align="right"
            />
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ForgotPasswordPage;
