import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import { MdInfo } from "react-icons/md";
import { Row, Col, Modal, ModalBody, Table, Button } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import { ErrorHandler } from "../../../ErrorHandler";
//import TablePagination from "../../../TablePagination";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
import SessionExpired from "../../../SessionExpired";

const staticMilestonesLabels = {
  c_create_the_project_batch: "Project / Batch Creation",
  c_programme_approval_committee_1_decision: "PAC1",
  c_current_forecasted_pac_2_date_vs_baseline_pac_2_date: "PAC2",
  c_current_contract_award_date_vs_baseline_contract_award_date:
    "Award contract",
  c_forecasted_start_on_site_date_vs_baseline_start_on_site_date:
    "Start on site",
  c_forecasted_piu_vs_baseline_piu_date: "Project in use",
  c_forecasted_contract_completion_date_vs_baseline_contract_comp:
    "Completion date",
  c_progamme_approval_committee_3_decision: "PAC3",
  c_forecasted_final_acceptance_date_vs_baseline_final_acceptance: "Final acceptance",
  c_gateway_4_decision: "Gateway 4",
};

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjectListModal: false,
      //final url -> /dashboard/prediction-quality-param/:milestoneActivityName/breakdown?userId=ac&filters={}
      fetchPredictionMilestoneBreakdownRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/prediction-quality-param/`,
      currentPage: 0,
      pageSize: 5,
    };
    this.onBarClick = this.onBarClick.bind(this);
    this.closeProjectListModal = this.closeProjectListModal.bind(this);
    this.fetchPredictionMilestoneBreakdownData = this.fetchPredictionMilestoneBreakdownData.bind(
      this
    );
    this.downloadPredictionMilestoneBreakdownExcel = this.downloadPredictionMilestoneBreakdownExcel.bind(
      this
    );
    this.getMilestoneCol = this.getMilestoneCol.bind(this);
    this.convertQualityParamValueToColor = this.convertQualityParamValueToColor.bind(
      this
    );
    this.convertQualityParamValueToText = this.convertQualityParamValueToText.bind(
      this
    );
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  fetchPredictionMilestoneBreakdownData(milestoneSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      //final url -> /dashboard/prediction-quality-param/:qualParam/breakdown
      url:
        this.state.fetchPredictionMilestoneBreakdownRequestURL +
        this.getMilestoneCol(milestoneSelected) +
        "/breakdown?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        // get current year
        const currentYear = Object.keys(data)[0];
        // get current month
        const currentMonth = Object.keys(data[currentYear])[0];
        const milestoneData = data[currentYear][currentMonth];
        this.setState(
          {
            showProjectListModal: true,
            milestoneSelected: this.getMilestoneCol(milestoneSelected),
            milestoneData: milestoneData,
            modalTableFilterData:
              milestoneData[this.state.qualityValueSelected],
            lastCrossedMilestoneName:
              milestoneData[this.state.qualityValueSelected][0][
                "last_crossed_milestone"
              ],
          },
          this.bindEvent
        );

        let projectIdName = [];
        for (
          let k = 0;
          k < milestoneData[this.state.qualityValueSelected].length;
          k++
        ) {
          projectIdName.push(
            milestoneData[this.state.qualityValueSelected][k].c_project_id +
              "(" +
              milestoneData[this.state.qualityValueSelected][k].c_project_name +
              ")"
          );
        }
        this.setState({ projectIdName: projectIdName });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params forecast - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  closeProjectListModal() {
    this.setState({ showProjectListModal: false, currentPage: 0 });
  }

  getMilestoneCol = (value) => {
    let key = Object.keys(staticMilestonesLabels).find(
      (key) => staticMilestonesLabels[key] == value
    );
    if (key) {
      return key;
    }
    return Object.keys(this.props.csvColumnMapping).find(
      (key) => this.props.csvColumnMapping[key] == value
    );
  };

  downloadPredictionMilestoneBreakdownExcel = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = this.props.csvColumnMapping
      ? this.props.csvColumnMapping[this.state.milestoneSelected]
        ? "List of projects forecasted for the milestone '" +
          this.props.csvColumnMapping[this.state.milestoneSelected] +
          "'"
        : "List of projects forecasted for the milestone '" +
          this.state.milestoneSelected +
          "'"
      : "List of projects forecasted for the milestone '" +
        this.state.milestoneSelected +
        "'";
    const headers = [
      [
        "Project Id",
        "Project Name",
        "Last Achieved Milestone",
        "Status Of Last Achieved Milestone",
        "Forecasted Milestone Status",
      ],
    ];

    let data = this.state.milestoneData[
      this.state.qualityValueSelected
    ].map((elt) => [
      elt.c_project_id,
      elt.c_project_name ? elt.c_project_name : "-",
      this.props.csvColumnMapping
        ? this.props.csvColumnMapping[elt["last_crossed_milestone"]]
          ? this.props.csvColumnMapping[elt["last_crossed_milestone"]]
          : elt["last_crossed_milestone"]
        : elt["last_crossed_milestone"],
      elt["last_crossed_milestone_status"],
      elt[this.state.milestoneSelected],
    ]);

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
      columnStyles: { 0: { halign: "center" } },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Forecast_ProjectMilestoneData.pdf");
  };

  onBarClick(event, i) {
    if (i && i[0]) {
      let selectedDatasetIndex = i[0]._chart.getElementAtEvent(event)[0]
        ._datasetIndex;
      let qualityValueSelected = "G";
      if (selectedDatasetIndex == 1) {
        qualityValueSelected = "A";
      } else if (selectedDatasetIndex == 2) {
        qualityValueSelected = "R";
      }
      this.setState(
        { qualityValueSelected: qualityValueSelected },
        this.fetchPredictionMilestoneBreakdownData(i[0]._view.label)
      );
    }
  }

  convertQualityParamValueToText(value) {
    if (value == "G") {
      return "Green";
    } else if (value == "R") {
      return "Red";
    } else if (value == "A") {
      return "Amber";
    } else if (value == "B") {
      return "Blue";
    } else if (value == "GR") {
      return "Grey";
    } else {
      return value;
    }
  }

  convertQualityParamValueToColor(value) {
    if (value == "G") {
      return "#2DC593";
    } else if (value == "R") {
      return "#E45266";
    } else if (value == "A") {
      return "#FFBF00";
    } else if (value == "B") {
      return "#268CD1";
    } else if (value == "GR") {
      return "grey";
    } else {
      return "light";
    }
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let modalTableFilterData = [];
    let dataArray = [];
    if (value === null || value === "") {
      this.setState({
        modalTableFilterData: this.state.milestoneData[
          this.state.qualityValueSelected
        ],
      });
    } else {
      this.state.milestoneData[this.state.qualityValueSelected].map((data) => {
        dataArray.push(data);
        if (
          data.c_project_id.toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          modalTableFilterData.push(data);
        }
        if (
          dataArray.length ===
          this.state.milestoneData[this.state.qualityValueSelected].length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      });
    }
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 0);
  }
  
  render() {
    const stackedBarChartData = this.props.chartData;
    let finalChartData = null;
    if (stackedBarChartData != null) {
      const chartDataTemplate = {
        data: {
          labels: null,
          datasets: [
            {
              label: "Green",
              backgroundColor: "#96e079", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "start",
                color: "black",
              },
            },
            {
              label: "Amber",
              backgroundColor: "#FFBF00", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
            {
              label: "Red",
              backgroundColor: "#E45266", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                fontSize: 8,
                align: "top",
                anchor: "end",
                color: "black",
              },
            },
          ],
        },
      };

      let options = {
        responsive: false,
        title: {
          display: true,
          text: "",
          position: "left",
          fontFamily: "Ubuntu-Medium",
        },
        legend: {
          display: false,
          position: "left",
          align: "left",
          fontFamily: "Ubuntu-Medium",
        },
        type: "bar",
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                display: true,
                fontColor: "#252525",
                minRotation: 0,
                maxRotation: 0,
                fontFamily: "Ubuntu-Medium",
                callback: function (label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  } else {
                    return label;
                  }
                },
              },
              stacked: true,
              display: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "#F1F4F9",
                borderDash: [5, 3],
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                beginAtZero: true,
                display: false,
                stepSize: 10,
                fontColor: "#919BA3",
                fontFamily: "Ubuntu-Medium",
              },
              stacked: true,
              display: true,
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
            color: "#919BA3",
            //align: 'top, middle',
            align: "top",
            anchor: "end",
            fontFamily: "Ubuntu-Medium",
            formatter: (label, index, labels) => {
              if (label == 0) {
                return "";
              }
            },
          },
        },
        tooltips: {
          displayColors: false,
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
        onClick: this.onBarClick,
      };
      let chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
      let greenChartValues = [];
      let redChartValues = [];
      let amberChartValues = [];
      let milestonesLabels = [];
      stackedBarChartData.greenDataSet.forEach((item) => {
        greenChartValues.push(item ? item : 0);
      });
      stackedBarChartData.redDataSet.forEach((item) => {
        redChartValues.push(item ? item : 0);
      });
      stackedBarChartData.amberDataSet.forEach((item) => {
        amberChartValues.push(item ? item : 0);
      });
      stackedBarChartData.milestones.forEach((item) => {
        milestonesLabels.push(
          staticMilestonesLabels[item]
            ? staticMilestonesLabels[item]
            : this.props.csvColumnMapping[item]
            ? this.props.csvColumnMapping[item]
            : item.replace(/_/g, " ")
        );
      });

      chartData.data.datasets[0].data = greenChartValues;
      chartData.data.datasets[1].data = amberChartValues;
      chartData.data.datasets[2].data = redChartValues;
      chartData.data.labels = milestonesLabels;
      finalChartData = (
        <div>
          <Bar
            data={chartData.data}
            width={1000}
            height={400}
            options={options}
          />
        </div>
      );
    }

    let divStyle = {
      height: 0 + "rem",
    };

    if (this.state.milestoneData) {
      if (this.state.milestoneData[this.state.qualityValueSelected]) {
        if (this.state.milestoneData[this.state.qualityValueSelected].length) {
          if (
            Math.ceil(
              this.state.milestoneData[this.state.qualityValueSelected].length /
                this.state.pageSize
            ) -
              this.state.currentPage ===
              1 &&
            this.state.milestoneData[this.state.qualityValueSelected].length %
              this.state.pageSize !==
              0
          ) {
            divStyle = {
              height:
                2.5 *
                  (this.state.pageSize -
                    (this.state.milestoneData[this.state.qualityValueSelected]
                      .length %
                      this.state.pageSize)) +
                "rem",
            };
          }
        }
      }
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <React.Fragment>
        <div className="chart-top">
          <div className="chart-header">
            Count of projects forecasted as 'Green' or 'Red' by milestone{" "}
            <span
              data-title="The chart represents the count of projects forecasted for each milestone with status as either 'G' or 'R'. Click on a bar to see the list of projects that belong to the desired milestone and its forecasted status​"
              data-title-xmg
            >
              <MdInfo size={20} className="c-info"></MdInfo>
            </span>
            <div className="rg-div">
              <div className="g-div"></div>
              Green
              <div className="r-div"></div>
              Red
            </div>
          </div>
          {finalChartData != null && (
            <React.Fragment>{finalChartData}</React.Fragment>
          )}
          {finalChartData == null && <PageSpinner></PageSpinner>}
        </div>
        {this.state.showProjectListModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              id="current-month-forecast-modal-main"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="current-month-forecast-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={this.closeProjectListModal}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <center>
                      <div id="current-month-forecast-modal-head">
                        List of Projects Forecasted as '
                        {this.convertQualityParamValueToText(
                          this.state.qualityValueSelected
                        )}
                        ' for milestone '
                        {staticMilestonesLabels[this.state.milestoneSelected]}'
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper">
                        <Autocomplete
                          options={this.state.projectIdName}
                          onInputChange={this.searchByProject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Project"
                            />
                          )}
                          size="small"
                        />
                        {/*
                            <input
                              type="text"
                              value={this.state.value}
                              onChange={this.searchByProject}
                              className="form-input-textbox"
                              placeholder="Search By Project ID"
                            />
                            */}
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <center>
                      <div>
                        <Table
                          responsive
                          className="stacked-chart-forecast table-current-vs-forecasted table table-md table-scroll-modal table-modal"
                        >
                          <thead className="table-head">
                            <tr>
                              <th className="table-string project-id-col">
                                Project Id
                              </th>
                              <th className="table-string project-name-col">
                                Project Name
                              </th>
                              <th className="table-string">
                                Last Achieved Milestone
                              </th>
                              <th
                                style={{ paddingBottom: "0.97rem" }}
                                className="centre-align-data"
                              >
                                Status of Last Milestone Achieved
                              </th>
                              <th>Forecasted Milestone Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.milestoneData &&
                            this.state.milestoneData[
                              this.state.qualityValueSelected
                            ]
                              ? this.state.modalTableFilterData
                                  //.slice(
                                  // this.state.currentPage * this.state.pageSize,
                                  //(this.state.currentPage + 1) *
                                  //this.state.pageSize
                                  //)
                                  .map((projectObject) => (
                                    <tr>
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-id-col"
                                      >
                                        {projectObject.c_project_id &&
                                        projectObject.c_project_id != ""
                                          ? projectObject.c_project_id
                                          : "-"}
                                      </td>
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-name-col"
                                      >
                                        {projectObject.c_project_name &&
                                        projectObject.c_project_name != ""
                                          ? projectObject.c_project_name
                                          : "-"}
                                      </td>
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string"
                                      >
                                        {this.props.csvColumnMapping
                                          ? this.props.csvColumnMapping[
                                              projectObject[
                                                "last_crossed_milestone"
                                              ]
                                            ]
                                            ? this.props.csvColumnMapping[
                                                projectObject[
                                                  "last_crossed_milestone"
                                                ]
                                              ]
                                            : projectObject[
                                                "last_crossed_milestone"
                                              ]
                                          : projectObject[
                                              "last_crossed_milestone"
                                            ]}
                                      </td>
                                      <td
                                        style={{
                                          color: this.convertQualityParamValueToColor(
                                            projectObject[
                                              "last_crossed_milestone_status"
                                            ]
                                          ),
                                        }}
                                      >
                                        {projectObject[
                                          "last_crossed_milestone_status"
                                        ] ? (
                                          <div
                                            className="quality-param-circle"
                                            style={{
                                              backgroundColor: this.convertQualityParamValueToColor(
                                                projectObject[
                                                  "last_crossed_milestone_status"
                                                ]
                                              ),
                                            }}
                                          ></div>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          color: this.convertQualityParamValueToColor(
                                            projectObject[
                                              this.state.milestoneSelected
                                            ]
                                          ),
                                        }}
                                      >
                                        <div
                                          className="quality-param-circle"
                                          style={{
                                            backgroundColor: this.convertQualityParamValueToColor(
                                              projectObject[
                                                this.state.milestoneSelected
                                              ]
                                            ),
                                          }}
                                        ></div>
                                      </td>
                                    </tr>
                                  ))
                              : ""}
                          </tbody>
                        </Table>
                        {this.state.milestoneData &&
                        this.state.milestoneData[
                          this.state.qualityValueSelected
                        ] ? (
                          ""
                        ) : (
                          <div>No data available for project list</div>
                        )}
                        {/*
                      {this.state.milestoneData &&
                      this.state.milestoneData[
                        this.state.qualityValueSelected
                      ] ? (
                        Math.ceil(
                          this.state.milestoneData[
                            this.state.qualityValueSelected
                          ].length / this.state.pageSize
                        ) > 1 ? (
                          <div>
                            <span className="showing-span">
                              <span className="showing-text-pagination">
                                Showing{" "}
                              </span>
                              <span>{this.state.currentPage + 1} </span>
                              <span className="showing-text-pagination">
                                out of{" "}
                              </span>
                              <span>
                                {Math.ceil(
                                  this.state.milestoneData[
                                    this.state.qualityValueSelected
                                  ].length / this.state.pageSize
                                )}{" "}
                              </span>
                              <span className="showing-text-pagination">
                                pages{" "}
                              </span>
                            </span>
                            <div id="registration-pagination-div">
                              <div id="registration-pagination-style">
                                <TablePagination
                                  pagesCount={Math.ceil(
                                    this.state.milestoneData[
                                      this.state.qualityValueSelected
                                    ].length / this.state.pageSize
                                  )}
                                  currentPage={this.state.currentPage}
                                  setCurrentPage={this.setCurrentPage}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                        )}
                      */}
                      </div>
                    </center>
                  </Col>
                </Row>
                {/*
                <Row>
                  <div style={divStyle}></div>
                </Row>
                */}
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <Row>
                      <Col>
                        <center>
                          <Button
                            color="primary"
                            onClick={
                              this.downloadPredictionMilestoneBreakdownExcel
                            }
                            size="sm"
                            className="standard-modal-button"
                            disabled={
                              this.state.milestoneData &&
                              this.state.milestoneData[
                                this.state.qualityValueSelected
                              ]
                                ? false
                                : true
                            }
                          >
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </Button>
                        </center>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default BarChart;
