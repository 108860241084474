import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Row, Col, Button, Modal, ModalBody, Spinner, Table } from "reactstrap";
import { MdInfo } from "react-icons/md";
import "chartjs-plugin-datalabels";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import HorizontalStackChart from "./HorizontalStackChart";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import jsPDF from "jspdf";
import "jspdf-autotable";
import chroma from "chroma-js";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import "../../../../styles/components/Chart.scss";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import { ErrorHandler } from "../../../ErrorHandler";
import * as GenericConstants from "../../../../src-constants/GenericConstants";
import AutoCloseAlert from "../../../AutoCloseAlert";
import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
import SessionExpired from "../../../SessionExpired";

const getQualityParamCol = (qualityParamDisplayText) => {
  let output = GenericConstants.qualityParamMapArray.find((item) => {
    return item.displayText == qualityParamDisplayText;
  });
  if (output) {
    return output.col;
  }
  return "";
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class CurrentMonthPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjectListModal: false,
      stackChartData: {},
      isProjectTabSelected: false,
      fetchQualityParamProjectBreakdownRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/quality-params-data/projectBreakdown/`,
      fetchQualityParamActivityBreakdownRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/quality-params-data/activityBreakdown/`,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      filterOptions: [
        { name: "A", id: 1, fullName: "A" },
        { name: "G", id: 2, fullName: "G" },
        { name: "R", id: 3, fullName: "R" },
        { name: "GR", id: 4, fullName: "GR" },
        { name: "B", id: 5, fullName: "B" },
        { name: "NA", id: 6, fullName: "NA" },
      ],
      selectedValue: [],
      selectedColumnHeader: null,
      pieData0: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData1: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData2: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData3: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData4: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData5: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#96e079", "#E45266", "#eb7b15"],
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      qualityValueSelected: "Time",
    };
    this.style = {
      chips: {
        background: "#268cd1",
        fontSize: "0.3rem",
        fontFamily: "Ubuntu-Regular",
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px",
        fontSize: "0.45rem",
        marginTop: "0rem",
        textAlign: "center",
      },
      multiselectContainer: {
        color: "#268cd1",
        width: "100%",
        alignItems: "center",
      },
      optionContainer: {
        // To change css for option container
        fontSize: "0.55rem",
        fontFamily: "Ubuntu-Regular",
      },
    };
    this.onBarClick = this.onBarClick.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    /*
    this.fetchQualityParamActivityBreakdown = this.fetchQualityParamActivityBreakdown(
      this
    );
    this.fetchQualityParamProjectBreakdown = this.fetchQualityParamProjectBreakdown.bind(
      this
    );
    */
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  closeModal = () => {
    this.setState({
      showProjectListModal: false,
      selectedColumnHeader: null,
      selectedValue: null,
      stackChartData: {},
      isProjectTabSelected: false,
    });
  };

  fetchProjectDataByQualityParam(qualityParamSelected) {
    console.log(qualityParamSelected);
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchProjectByQualityParamRequestURL +
        getQualityParamCol(qualityParamSelected) +
        "/breakdown?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        // get current year
        const currentYear = Object.keys(data)[0];
        // get current month
        const currentMonth = Object.keys(data[currentYear])[0];
        const qualityParamData = data[currentYear][currentMonth];
        this.setState({
          qualityParamSelected: qualityParamSelected,
          qualityParamData: qualityParamData,
        });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params forecast - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  onBarClick(label, event, i) {
    if (i && i[0]) {
      let qualityValueSelected = "G";
      if (i[0]._view.label == " Red") {
        qualityValueSelected = "R";
      }
      if (i[0]._view.label == " Amber") {
        qualityValueSelected = "A";
      }
      this.setState(
        {
          qualityValueSelected: qualityValueSelected,
          qualityParamSelected: label,
        },
        () => {
          this.fetchQualityParamProjectBreakdown(label, qualityValueSelected);
          this.fetchQualityParamActivityBreakdown(label, qualityValueSelected);
        }
      );
    }
  }

  fetchQualityParamActivityBreakdown(qualityParam, qualityValueSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchQualityParamActivityBreakdownRequestURL +
        getQualityParamCol(qualityParam) +
        "/" +
        qualityValueSelected +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        this.setState({
          stackChartData: response.data.chartData,
          showProjectListModal: true,
        });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params current - " + error
        );
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
        });
      });
  }

  fetchQualityParamProjectBreakdown(qualityParam, qualityValueSelected) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.fetchQualityParamProjectBreakdownRequestURL +
        getQualityParamCol(qualityParam) +
        "/" +
        qualityValueSelected +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data.chartData;
        let currentYear = data ? Object.keys(data)[0] : null;
        let currentMonth = currentYear
          ? Object.keys(data[currentYear])[0]
          : null;
        let qualityParamData =
          currentYear && currentMonth && data
            ? Object.entries(data[currentYear][currentMonth])
            : null;
        let projectIdName = [];
        if (qualityParamData) {
          for (let k = 0; k < qualityParamData.length; k++) {
            projectIdName.push(
              qualityParamData[k][0] +
                "(" +
                qualityParamData[k][1]["projectName"] +
                ")"
            );
          }
        }
        let tableHead = [];

        qualityParamData.forEach((param) => {
          let entriesArr = param[1].activities;
          entriesArr.forEach((entity) => {
            param[1][entity.activity] = entity.value;
            if (tableHead.find((element) => element == entity.activity)) {
            } else {
              if (entity.activity != getQualityParamCol(qualityParam)) {
                tableHead.push(entity.activity);
              }
            }
          });
          delete param[1].activities;
        });

        this.setState({
          showModal: true,
          modalTableData: qualityParamData,
          modalTableFilterData: qualityParamData,
          modalTableProjectFilterData: qualityParamData,
          showProjectListModal: true,
          projectIdName: projectIdName,
          tableHead: data.activityList,
        });
      })
      .catch((error) => {
        console.log(
          "error in fetching project quality params current - " + error
        );
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getSortDesOrder(prop) {
    return function (a, b) {
      if (!a[1][prop]) {
        return 1;
      }
      if (!b[1][prop]) {
        return -1;
      }
      if (a[1][prop] < b[1][prop] || a[1][prop] == b[1][prop]) {
        return 1;
      } else if (a[1][prop] > b[1][prop]) {
        return -1;
      }
      return 0;
    };
  }

  getSortAscOrder(prop) {
    return function (a, b) {
      if (!a[1][prop]) {
        return 1;
      }
      if (!b[1][prop]) {
        return -1;
      }
      if (a[1][prop] > b[1][prop] || a[1][prop] == b[1][prop]) {
        return 1;
      } else if (a[1][prop] < b[1][prop]) {
        return -1;
      }
      return 0;
    };
  }

  arraySortAscending(key) {
    let array = this.state.modalTableFilterData;
    if (array) {
      array.sort(this.getSortAscOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: "",
      moreClickKey: key,
    });
  }

  arraySortDescending(key) {
    let array = this.state.modalTableFilterData;
    if (array) {
      array.sort(this.getSortDesOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      modalTableFilterData: array,
      lessClickKey: key,
      moreClickKey: "",
    });
  }

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let modalTableFilterData = [];
    let dataArray = [];
    let item = null;
    let arr = [];
    if (value === null || value === "") {
      this.setState(
        {
          modalTableFilterData: this.state.modalTableData,
          modalTableProjectFilterData: this.state.modalTableData,
        },
        this.onSelect(item, arr)
      );
    } else {
      this.state.modalTableData.map((data) => {
        dataArray.push(data);
        if (data[0].toLowerCase().indexOf(value.toLowerCase()) === 0) {
          modalTableFilterData.push(data);
        }
        if (dataArray.length === this.state.modalTableData.length) {
          this.setState(
            {
              modalTableFilterData: modalTableFilterData,
              modalTableProjectFilterData: modalTableFilterData,
            },
            this.onSelect(item, arr)
          );
        }
      });
    }
  }

  downloadTablePdf = () => {
    this.setState({ isDownloading: true });
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title =
      "List of Projects For Status '" +
      this.state.qualityValueSelected +
      "' in " +
      this.state.qualityParamSelected;
    let headers = [];
    let headings = [];
    let data = [];
    let dataArr = this.state.modalTableFilterData;
    let headerArr = this.state.tableHead;

    headings.push("Project ID");
    headings.push("Project Name");
    headings.push("Last Achieved Milestone");
    headings.push("Status of Last Milestone Achieved");
    for (let i = 0; i < headerArr.length; i++) {
      headings.push(
        this.props.csvColumnMapping[headerArr[i]]
          ? this.props.csvColumnMapping[headerArr[i]]
          : headerArr[i].replace(/_/g, " ")
      );
    }
    headers.push(headings);

    for (let i = 0; i < dataArr.length; i++) {
      let array = [];
      array.push(dataArr[i][0]);
      array.push(dataArr[i][1]["projectName"]);
      array.push(
        this.props.csvColumnMapping
          ? this.props.csvColumnMapping[dataArr[i][1]["lastCrossedMilestone"]]
            ? this.props.csvColumnMapping[dataArr[i][1]["lastCrossedMilestone"]]
            : dataArr[i][1]["lastCrossedMilestone"]
          : dataArr[i][1]["lastCrossedMilestone"]
      );
      array.push(dataArr[i][1]["lastCrossedMilestoneStatus"]);
      for (let j = 0; j < headerArr.length; j++) {
        if (dataArr[i][1][headerArr[j]]) {
          array.push(dataArr[i][1][headerArr[j]]);
        } else {
          array.push("NA");
        }
      }
      data.push(array);
    }

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "Correct") {
          data.cell.styles.textColor = [45, 197, 147];
        }

        if (data.cell.raw == "Incorrect") {
          data.cell.styles.textColor = "#E45266";
        }

        if (data.cell.raw == "Pending") {
          data.cell.styles.textColor = "#919BA3";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(title + ".pdf");
    this.setState({ isDownloading: false });
  };

  onSelect = (item, selectedList, selectedItem) => {
    this.setState({ selectedValue: selectedList, selectedColumnHeader: item });
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (data[1][item]) {
            if (
              data[1][item].toLowerCase() == selectedList[i].value.toLowerCase()
            ) {
              modalTableFilterData.push(data);
            }
          } else {
            if (selectedList[i].value == "NA") {
              modalTableFilterData.push(data);
            }
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  };

  onRemove(item, selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList, selectedColumnHeader: item });
    let modalTableFilterData = [];
    let dataArray = [];
    if (!selectedList) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        modalTableFilterData: this.state.modalTableProjectFilterData,
        selectedColumnHeader: null,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (
          let j = 0;
          j < this.state.modalTableProjectFilterData.length;
          j++
        ) {
          let data = this.state.modalTableProjectFilterData[j];
          dataArray.push(data);
          if (
            data[1][item].toLowerCase() == selectedList[i].name.toLowerCase()
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.modalTableProjectFilterData.length
        ) {
          this.setState({ modalTableFilterData: modalTableFilterData });
        }
      }
    }
  }

  bindEvent() {
    if (document.querySelector(".table-scroll-modal"))
      document
        .querySelector(".table-scroll-modal")
        .addEventListener("scroll", () => {
          let maxWidth = parseInt(
            window
              .getComputedStyle(document.querySelector("thead"))
              .width.replace("px", "")
          );
          let computedWidth =
            parseInt(
              window
                .getComputedStyle(document.querySelector(".table-scroll-modal"))
                .width.replace("px", "")
            ) +
            document.querySelector(".table-scroll-modal").scrollLeft +
            1;
          if (computedWidth > maxWidth) {
            computedWidth = maxWidth;
          }
          document.querySelectorAll("table > *")[1].style.width =
            computedWidth + "px";
          document.querySelectorAll("table > *")[0].style.width =
            computedWidth + "px";
        });
  }

  render() {
    const pieOptions = (label) => {
      return {
        legend: {
          display: false,
          position: "right",
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            fontFamily: "Ubuntu-Medium",
            fontSize: 4,
            font: {
              size: 10,
              family: "Ubuntu-Medium",
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                if (data) {
                  sum += parseInt(data);
                }
              });
              if (!value) {
                return "";
              }
              let percentage = ((value * 100) / sum).toFixed(0) + "%";
              return percentage;
            },
          },
        },
        onClick: this.onBarClick.bind(this, label),
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      };
    };

    let greenDataSet = this.props.chartData.greenDataSet;
    let redDataSet = this.props.chartData.redDataSet;
    let amberDataSet = this.props.chartData.amberDataSet;
    let chartData0 = this.state.pieData0;
    let chartData1 = this.state.pieData1;
    let chartData2 = this.state.pieData2;
    let chartData3 = this.state.pieData3;
    let chartData4 = this.state.pieData4;
    let chartData5 = this.state.pieData5;

    if (greenDataSet) {
      chartData0.datasets[0].data[0] = greenDataSet[0];
      chartData0.datasets[0].data[1] = redDataSet[0];
      chartData0.datasets[0].data[2] = amberDataSet[0];

      chartData1.datasets[0].data[0] = greenDataSet[1];
      chartData1.datasets[0].data[1] = redDataSet[1];
      chartData1.datasets[0].data[2] = amberDataSet[1];

      chartData2.datasets[0].data[0] = greenDataSet[2];
      chartData2.datasets[0].data[1] = redDataSet[2];
      chartData2.datasets[0].data[2] = amberDataSet[2];

      chartData3.datasets[0].data[0] = greenDataSet[3];
      chartData3.datasets[0].data[1] = redDataSet[3];
      chartData3.datasets[0].data[2] = amberDataSet[3];

      chartData4.datasets[0].data[0] = greenDataSet[4];
      chartData4.datasets[0].data[1] = redDataSet[4];
      chartData4.datasets[0].data[2] = amberDataSet[4];

      chartData5.datasets[0].data[0] = greenDataSet[5];
      chartData5.datasets[0].data[1] = redDataSet[5];
      chartData5.datasets[0].data[2] = amberDataSet[5];
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <div>
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        <div className="chart-top">
          <div className="chart-header">
            Count of projects which impact Time, Cost, Quality, Scope, H&S, and
            SME Assessment{" "}
            <span
              data-title="The doughnut chart represents a count of projects in percentage of total projects having a business impact on Time, Cost, Quality, Scope, H&S and SME Self Assessment as Red, Amber and Green​"
              data-title-lg
            >
              <MdInfo size={20} className="c-info"></MdInfo>
            </span>
            <div className="rg-div">
              <div className="g-div"></div>
              Green
              <div className="r-div"></div>
              Red
              <div className="a-div"></div>
              Amber
            </div>
          </div>
          <div>
            <Row
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.2rem",
                marginLeft: "15px",
                marginRight: "15px",
                padding: "0rem",
              }}
            >
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData0}
                  options={pieOptions("Time")}
                  height={180}
                />
                Time
              </Col>
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData1}
                  options={pieOptions("Cost")}
                  height={180}
                />
                Cost
              </Col>
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData2}
                  options={pieOptions("Quality")}
                  height={180}
                />
                Quality
              </Col>
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData3}
                  options={pieOptions("Scope")}
                  height={180}
                />
                Scope
              </Col>
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData4}
                  options={pieOptions("H&S")}
                  height={180}
                />
                H&S
              </Col>
              <Col xs={2} style={{ padding: "0rem" }}>
                <Doughnut
                  data={chartData5}
                  options={pieOptions("SME")}
                  height={180}
                />
                SME
              </Col>
            </Row>
          </div>
        </div>
        {this.state.showProjectListModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="tcqs-current-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="p-0 tcqs-current-modal-body"
              >
                <div className="p-2">
                  <CancelIcon
                    onClick={this.closeModal}
                    className="cancel-close-icon"
                  />
                  <Row>
                    <Col>
                      <center>
                        <span
                          id="key-tasks-to-text"
                          style={{ color: "#252525" }}
                        >
                          {/*
                            List of Activities & Projects For Status '
                            {this.state.qualityValueSelected}' in{" "}
                            {this.state.qualityParamSelected}
                          */}
                          {this.state.isProjectTabSelected ? (
                            <span>
                              List of Projects For Status '
                              {this.state.qualityValueSelected}' in{" "}
                              {this.state.qualityParamSelected}
                            </span>
                          ) : (
                            <span>
                              List of Activities For Status '
                              {this.state.qualityValueSelected}' in{" "}
                              {this.state.qualityParamSelected}
                            </span>
                          )}
                        </span>
                      </center>
                    </Col>
                  </Row>
                </div>
                <Row className="m-0 menu-tabs">
                  <Col
                    className={`text-right ${
                      !this.state.isProjectTabSelected ? "selected" : ""
                    }`}
                    onClick={() =>
                      this.setState({ isProjectTabSelected: false })
                    }
                  >
                    <BootstrapTooltip
                      placement="left"
                      title="The  bar chart represents count of projects with status 'R’, 'A’ and ‘G’ for the activities that impact the selected business measure​"
                    >
                      <span>List of Activities</span>
                    </BootstrapTooltip>
                  </Col>
                  <Col
                    className={
                      this.state.isProjectTabSelected ? "selected" : ""
                    }
                    onClick={() =>
                      this.setState({ isProjectTabSelected: true }, () => {
                        this.bindEvent();
                      })
                    }
                  >
                    <BootstrapTooltip
                      placement="right"
                      title="The table represents a list of projects with there corresponding BRAG status of the activities that impact the selected business measure​"
                    >
                      <span>List of Projects</span>
                    </BootstrapTooltip>
                  </Col>
                </Row>
                {!this.state.isProjectTabSelected ? (
                  this.state.stackChartData &&
                  JSON.stringify(this.state.stackChartData) !==
                    JSON.stringify({}) ? (
                    <HorizontalStackChart
                      qualityValue={this.state.qualityValueSelected}
                      qualityParam={this.state.qualityParamSelected}
                      stackChartData={this.state.stackChartData}
                      csvColumnMapping={this.props.csvColumnMapping}
                    />
                  ) : (
                    <div style={{ height: "55.5vh" }}>
                      <br />
                      <div className="no-data-available-current-month-activity">
                        No data available of list of activities for status '
                        {this.state.qualityValueSelected}' in{" "}
                        {this.state.qualityParamSelected}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
                {this.state.isProjectTabSelected ? (
                  this.state.modalTableData &&
                  JSON.stringify(this.state.modalTableData) !==
                    JSON.stringify({}) ? (
                    <div className="p-3">
                      <Row>
                        <Col>
                          <center>
                            <div className="form-input-wrapper-current-month-modal">
                              <Autocomplete
                                options={this.state.projectIdName}
                                onInputChange={this.searchByProject}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Project"
                                  />
                                )}
                                size="small"
                              />
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <div className="current-month-chart">
                              <Table
                                responsive
                                className="table table-scroll-modal table-modal table-md table-list-of-projects table-tcqs-modal"
                                id="table-task-list"
                              >
                                <thead className="table-head">
                                  <tr>
                                    <th
                                      style={{
                                        paddingBottom: "0.97rem",
                                      }}
                                      className="project-id-col"
                                    >
                                      Project ID
                                    </th>
                                    <th
                                      style={{ paddingBottom: "0.97rem" }}
                                      className="project-name-col"
                                    >
                                      Project Name
                                    </th>
                                    <th style={{ paddingBottom: "0.97rem" }}>
                                      Last Achieved Milestone
                                    </th>
                                    <th
                                      style={{ paddingBottom: "0.97rem" }}
                                      className="centre-align-data"
                                    >
                                      Status of Last Milestone Achieved
                                    </th>
                                    {this.state.tableHead
                                      ? this.state.tableHead.map((item) => (
                                          <BootstrapTooltip
                                            placement="top-center"
                                            title={
                                              this.props.csvColumnMapping[item]
                                                ? this.props.csvColumnMapping[
                                                    item
                                                  ]
                                                : item.replace(/_/g, " ")
                                            }
                                          >
                                            <th
                                              className="centre-align-data"
                                              style={{
                                                paddingBottom: this.state
                                                  .selectedColumnHeader
                                                  ? this.state
                                                      .selectedColumnHeader ==
                                                    item
                                                    ? "0.2rem"
                                                    : "0.2rem"
                                                  : "0.2rem",
                                                paddingTop: "0.2rem",
                                              }}
                                            >
                                              {this.state
                                                .selectedColumnHeader ? (
                                                this.state
                                                  .selectedColumnHeader ==
                                                item ? (
                                                  <Select
                                                    closeMenuOnSelect={false}
                                                    blurInputOnSelect={false}
                                                    isMulti
                                                    options={colourOptions}
                                                    styles={colourStyles}
                                                    value={
                                                      this.state.selectedValue
                                                    }
                                                    onChange={this.onSelect.bind(
                                                      this,
                                                      item
                                                    )}
                                                    placeholder={
                                                      this.props
                                                        .csvColumnMapping[item]
                                                        ? this.props.csvColumnMapping[
                                                            item
                                                          ].substr(0, 15)
                                                        : item
                                                            .replace(/_/g, " ")
                                                            .substr(0, 15)
                                                    }
                                                    isSearchable
                                                  />
                                                ) : (
                                                  <span>
                                                    {this.props
                                                      .csvColumnMapping[item]
                                                      ? this.props
                                                          .csvColumnMapping[
                                                          item
                                                        ]
                                                      : item.replace(/_/g, " ")}
                                                  </span>
                                                )
                                              ) : (
                                                <Select
                                                  closeMenuOnSelect={false}
                                                  blurInputOnSelect={false}
                                                  isMulti
                                                  options={colourOptions}
                                                  styles={colourStyles}
                                                  value={
                                                    this.state.selectedValue
                                                  }
                                                  onChange={this.onSelect.bind(
                                                    this,
                                                    item
                                                  )}
                                                  placeholder={
                                                    this.props.csvColumnMapping[
                                                      item
                                                    ]
                                                      ? this.props.csvColumnMapping[
                                                          item
                                                        ].substr(0, 15)
                                                      : item
                                                          .replace(/_/g, " ")
                                                          .substr(0, 15)
                                                  }
                                                  isSearchable
                                                />
                                              )}
                                            </th>
                                          </BootstrapTooltip>
                                        ))
                                      : ""}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.modalTableFilterData ? (
                                    this.state.modalTableFilterData.length >=
                                    1 ? (
                                      this.state.modalTableFilterData.map(
                                        (activityObject) => (
                                          <tr>
                                            <td className="table-data-bold project-id-col">
                                              {activityObject[0]}
                                            </td>
                                            <td className="table-data-bold project-name-col">
                                              {activityObject[1]["projectName"]}
                                            </td>
                                            <td className="table-data-bold">
                                              {this.props.csvColumnMapping
                                                ? this.props.csvColumnMapping[
                                                    activityObject[1][
                                                      "lastCrossedMilestone"
                                                    ]
                                                  ]
                                                  ? this.props.csvColumnMapping[
                                                      activityObject[1][
                                                        "lastCrossedMilestone"
                                                      ]
                                                    ]
                                                  : activityObject[1][
                                                      "lastCrossedMilestone"
                                                    ]
                                                : activityObject[1][
                                                    "lastCrossedMilestone"
                                                  ]}
                                            </td>
                                            <td className="table-data-black centre-align-data">
                                              {activityObject[1][
                                                "lastCrossedMilestoneStatus"
                                              ] ? (
                                                activityObject[1][
                                                  "lastCrossedMilestoneStatus"
                                                ] != "NA" ? (
                                                  <div
                                                    className={
                                                      activityObject[1][
                                                        "lastCrossedMilestoneStatus"
                                                      ] === "R"
                                                        ? "red-circle"
                                                        : activityObject[1][
                                                            "lastCrossedMilestoneStatus"
                                                          ] === "A"
                                                        ? "amber-circle"
                                                        : activityObject[1][
                                                            "lastCrossedMilestoneStatus"
                                                          ] === "GR"
                                                        ? "grey-circle"
                                                        : activityObject[1][
                                                            "lastCrossedMilestoneStatus"
                                                          ] === "B"
                                                        ? "blue-circle"
                                                        : "green-circle"
                                                    }
                                                  ></div>
                                                ) : (
                                                  "-"
                                                )
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                            {this.state.tableHead
                                              ? this.state.tableHead.map(
                                                  (item) => (
                                                    <td className="table-data-black centre-align-data">
                                                      {activityObject[1][
                                                        item
                                                      ] ? (
                                                        activityObject[1][
                                                          item
                                                        ] != "NA" ? (
                                                          <div
                                                            className={
                                                              activityObject[1][
                                                                item
                                                              ] === "R"
                                                                ? "red-circle"
                                                                : activityObject[1][
                                                                    item
                                                                  ] === "A"
                                                                ? "amber-circle"
                                                                : activityObject[1][
                                                                    item
                                                                  ] === "GR"
                                                                ? "grey-circle"
                                                                : activityObject[1][
                                                                    item
                                                                  ] === "B"
                                                                ? "blue-circle"
                                                                : "green-circle"
                                                            }
                                                          ></div>
                                                        ) : (
                                                          "-"
                                                        )
                                                      ) : (
                                                        "-"
                                                      )}
                                                    </td>
                                                  )
                                                )
                                              : ""}
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr></tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        style={{
                                          width: "100vw",
                                          paddingLeft: "40vw",
                                        }}
                                      >
                                        <span style={{ marginLeft: "18rem" }}>
                                          No data available for '
                                          {this.state.qualityValueSelected}' in{" "}
                                          {this.state.qualityParamSelected}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </center>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <center>
                            <Button
                              color="primary"
                              onClick={this.downloadTablePdf}
                              size="sm"
                              id="download-list-key-actvity"
                              disabled={
                                this.state.modalTableFilterData &&
                                JSON.stringify(
                                  this.state.modalTableFilterData
                                ) !== JSON.stringify([])
                                  ? false
                                  : true
                              }
                              className="download-list-current-month"
                            >
                              {this.state.isDownloading ? (
                                <div>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Loading...</span>{" "}
                                  Downloading...
                                </div>
                              ) : (
                                <div>
                                  <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                                </div>
                              )}
                            </Button>
                          </center>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div style={{ height: "55.5vh" }}>
                      <br />
                      <div className="no-data-available-current-month-activity">
                        No data available of list of projects for status '
                        {this.state.qualityValueSelected}' in{" "}
                        {this.state.qualityParamSelected}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CurrentMonthPieChart;
