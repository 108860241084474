import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { MdRemove, MdAdd, MdSearch, MdInfo, MdUndo } from "react-icons/md";
import classnames from "classnames";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Spinner } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Doughnut } from "react-chartjs-2";

import Header from "../components/Header";
import PageSpinner from "../components/PageSpinner";
import * as GenericConstants from "../src-constants/GenericConstants";
import OverviewChart from "../components/dashboard/summary/charts/OverviewChart";
import OverviewHorizontalChart from "../components/dashboard/summary/charts/OverviewHorizontalChart";
import HealthScoreTrendChart from "../components/dashboard/summary/charts/HealthScoreTrendChart";
import ConfidenceScoreChart from "../components/dashboard/summary/charts/ConfidenceScoreChart";
import BusinessMeasuresPerformanceChart from "../components/dashboard/summary/charts/BusinessMeasuresPerformanceChart";
import ProjectsCompletionChart from "../components/dashboard/summary/charts/ProjectsCompletionChart";
import Filter from "../components/dashboard/Filter";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import { ErrorHandler } from "../components/ErrorHandler";
import SessionExpired from "../components/SessionExpired";
import SummaryProjectDetails from "../components/dashboard/summary/ProjectDetails";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class SummaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      getProjectCountURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-counts?filters=`,
      fetchProjectNamesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-names?filters=`,
      getLatestDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-milestone?filters=`,
      getTCQSDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/quality-params-data?filters=`,
      getFiltersDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/filters-data`,
      getCsvColumnsMappingRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/column-mapping`,
      getProjectsOverviewRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/projects-overview?filters=`,
      getProjectsDetailsRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}//dashboard/summary-projects?filters=`,

      getProjectsCompletedByPhaseRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/projects-by-phase?filters=`,
      getHealthScoreTrendRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/overall-health-trend?filters=`,
      getForecastHealthScoreTrendRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/forecasted-overall-health-trend?filters=`,
      getConfidenceScoreRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/confidence-score?filters=`,
      getBusinessMeasurePerformanceRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/tcqi-performance?filters=`,
      getForecastBusinessMeasurePerformanceRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/forecasted-performance?filters=`,
      getProjectsCompletionURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/projects-by-phase-completion?filters=`,
      getLatestCSVDateURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/latest-csv-date`,
      projectsOverviewData: {},
      projectsCompletedByPhaseData: {},
      activeTab: "1",
      selectedFiltersData: {},
      isFilterOpen: false,
      filtersAPIData: null,
      currentYear: 0,
      currentMonth: 0,
      selectedMilestone: 0,
      alertMsg: "",
      alertcolor: "danger",
      countFilter: 0,
      tablet: 5,
      totalProjectLoading: true,
      GRadioChecked: true,
      BRadioChecked: false,
      projectName: null,
      projectNameIdMap: null,
      projectCurrentHealthData: {},
      selectedMeasure: "all",
      healthScoreTrendForecastedData: null,
      businessMeasurePerformanceForecastData: null,
      latestCSVDate: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.getLatestData = this.getLatestData.bind(this);
    this.getCurrentMonthAndYear = this.getCurrentMonthAndYear.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.getCsvColumnsMapping = this.getCsvColumnsMapping.bind(this);
    this.escapeFilterValue = this.escapeFilterValue.bind(this);
    this.fetchProjectNames = this.fetchProjectNames.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
    this.getProjectsOverview = this.getProjectsOverview.bind(this);
    this.getProjectsCompletedByPhase =
      this.getProjectsCompletedByPhase.bind(this);
    this.getHealthScoreTrend = this.getHealthScoreTrend.bind(this);
    this.getConfidenceScore = this.getConfidenceScore.bind(this);
    this.getBusinessMeasurePerformance =
      this.getBusinessMeasurePerformance.bind(this);
      this.getLatestCSVDate = this.getLatestCSVDate.bind(this);
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab }, this.updateDownloadButtonState);
    }
  }

  componentDidMount() {
    if (window.innerWidth < 1000) {
      this.setState({ tablet: 2 });
    } else this.setState({ tablet: 3 });
    
    // get latest CSV date
    this.getLatestCSVDate();
    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );
    this.getCsvColumnsMapping();
    this.getFiltersData();

    if (selectedFiltersData && JSON.stringify(selectedFiltersData) != "{}") {
      this.setState({ showKeepFiltersModal: true });
    } else {
      this.fetchSummaryDashboardData();
      sessionStorage.setItem("selectedFiltersData", "{}");
    }
  }

  fetchSummaryDashboardData() {
    this.getLatestData();
    this.getProjectCount();
    this.fetchProjectNames();
    this.getProjectsOverview();
    this.getProjectsCompletedByPhase();
    this.getHealthScoreTrend();
    this.getConfidenceScore();
    this.getBusinessMeasurePerformance();
    this.getProjectsCompletion();
    this.getSummaryProjectsDetails();
  }

  keepFilters() {
    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    this.setState(
      {
        selectedFiltersData: selectedFiltersData,
        showKeepFiltersModal: false,
        showMatchingProjects: true,
        totalProjectLoading: true,
      },
      this.applyFilters
    );
  }

  closeKeepFiltersModal() {
    this.fetchSummaryDashboardData();
    this.setState({ showKeepFiltersModal: false, totalProjectLoading: true });
    sessionStorage.setItem("selectedFiltersData", "{}");
  }

  getProjectCount() {
    this.setState({ isLoading: true, totalProjectLoading: true });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectCountURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        this.setState({
          totalProjects: data.projectCount,
          totalProjectLoading: false,
        });
      })
      .catch((error) => {
        console.log("error in getting project counts" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            totalProjectLoading: false,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getLatestCSVDate() {
    axios({
      url: this.state.getLatestCSVDateURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        // get current year
        let csvDate = response.data.latestUploadDate
        csvDate = moment(csvDate).format('DD-MMM-YYYY, hh:mm a')
        this.setState(
          {
            latestCSVDate: csvDate.replace(",", ' at'),
          },
        );
      })
      .catch((error) => {
        console.log("error in fetching latest csv date" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
        );
      });
  }

  escapeFilterValue(filterJsonObject) {
    let encodedFilterJsonObject = {};
    Object.entries(filterJsonObject).forEach((filterItem) => {
      let encodedFilterValueArray = [];
      filterItem[1].forEach((filterValue) => {
        encodedFilterValueArray.push(encodeURIComponent(filterValue));
      });
      encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
    });
    return encodedFilterJsonObject;
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedMilestoneValue: null,
      selectedComparisonValue: null,
    });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  getCurrentMonthAndYear() {
    axios({
      url: this.state.getTCQSDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let currentYear, currentMonth;
        if (
          response.data.chartData &&
          JSON.stringify(response.data.chartData) !== JSON.stringify({})
        ) {
          currentYear = Object.keys(response.data.chartData)[0];
          currentMonth = Object.keys(response.data.chartData[currentYear])[0];
        } else {
          currentYear = null;
          currentMonth = null;
        }
        this.setState({
          currentMonth: currentMonth,
          currentYear: currentYear,
        });
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching current month and year" + error);
      });
  }

  getLatestData() {
    this.setState({ isLoading: true, latestDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    const selectedMilestone = this.state.selectedMilestone;
    axios({
      url:
        this.state.getLatestDataRequestURL +
        filterQuery +
        "&selectedMilestone=" +
        selectedMilestone,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let res = response;
        let data = res.data.chartData;

        let currentYear = null,
          currentMonth = null,
          latestData = null;

        if (data && JSON.stringify(data) !== JSON.stringify({})) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth =
            currentYear != null ? Object.keys(data[currentYear])[0] : null;
          latestData =
            currentYear != null && currentMonth != null
              ? data[currentYear][currentMonth]
              : null;
        }

        this.setState(
          {
            currentMonth: currentMonth,
            currentYear: currentYear,
            latestData: latestData,
            isLoading: false,
            latestDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching all projects data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
          latestDataLoaded: true,
        });
      });
  }

  getFiltersData() {
    axios({
      url: this.state.getFiltersDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const filtersData = response.data.filtersData;
        this.setState({ filtersAPIData: filtersData });
      })
      .catch((error) => {
        console.log("error in fetching filters data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  fetchProjectNames() {
    this.setState({ isLoading: true, isFetchProjectNamesLoading: true });
    const filters = this.props.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.fetchProjectNamesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const projectNameIdMap = response.data.projectNameIdMap;
        this.setState({ projectNameIdMap: projectNameIdMap });
        this.setState({ isLoading: false, isFetchProjectNamesLoading: false });
      })
      .catch((error) => {
        console.log("error in fetching project names");
        this.setState({ isLoading: false, isFetchProjectNamesLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getCsvColumnsMapping() {
    axios({
      url: this.state.getCsvColumnsMappingRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const csvColumnMapping = response.data.csvColumnMapping;
        this.setState({ csvColumnMapping: csvColumnMapping });
      })
      .catch((error) => {
        console.log("error in fetching csv columns mapping data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getProjectsOverview() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectsOverviewRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let projectsOverviewData = {
          amp7OptimisedVal: response.data.amp7OptimisedVal,
          totalSanction: response.data.totalSanction,
          overspent: response.data.overspent,
          avgPerctValRemaining: response.data.avgPerctValRemaining,
        };

        this.setState({
          projectsOverviewData: projectsOverviewData,
          overSpendProjects: response.data?.overSpendProjects,
        });
      })
      .catch((error) => {
        console.log("error in fetching projects overview data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getSummaryProjectsDetails() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectsDetailsRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let listOfSummaryProjects = [];
        if (response && response.data && response.data["allProjectsData"]) {
          listOfSummaryProjects = response.data["allProjectsData"];
        }
        this.setState({
          projectsSummaryData: listOfSummaryProjects,
        });
      })
      .catch((error) => {
        console.log("error in fetching projects overview data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getProjectsCompletedByPhase() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectsCompletedByPhaseRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let projectsCompletedByPhaseData = {
          identifyCount: response?.data?.projectPhaseData?.identifyCount,
          defineCount: response?.data?.projectPhaseData?.defineCount,
          deliverCount: response?.data?.projectPhaseData?.deliverCount,
          closureCount: response?.data?.projectPhaseData?.closureCount,
        };

        this.setState({
          projectsCompletedByPhaseData: projectsCompletedByPhaseData,
        });
      })
      .catch((error) => {
        console.log("error in fetching projects by phase data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getHealthScoreTrend() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getHealthScoreTrendRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        console.log(res.data);
        this.setState({
          healthScoreTrendData: res.data.finalHealthScoreData,
        });
      })
      .catch((error) => {
        console.log("error in fetching health score data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });

    // forecasted
    axios({
      url: this.state.getForecastHealthScoreTrendRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        console.log(res.data);
        this.setState({
          healthScoreTrendForecastedData: res.data.finalHealthScoreData,
        });
      })
      .catch((error) => {
        console.log("error in fetching forecasted health score data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getConfidenceScore() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getConfidenceScoreRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        this.setState({ confidenceScoreData: res.data.confidenceScoreData,
          confidenceProjectsData: res.data?.confidenceProjectsData
        });
      })
      .catch((error) => {
        console.log("error in fetching confidence score data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getBusinessMeasurePerformance() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getBusinessMeasurePerformanceRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        console.log(res.data);
        this.setState({
          businessMeasurePerformanceData: res.data,
        });
      })
      .catch((error) => {
        console.log("error in fetching business measures performance data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });

    axios({
      url:
        this.state.getForecastBusinessMeasurePerformanceRequestURL +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        console.log(res.data);
        this.setState({
          businessMeasurePerformanceForecastData: res.data,
        });
      })
      .catch((error) => {
        console.log("error in fetching business measures performance data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getProjectsCompletion() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectsCompletionURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((res) => {
        console.log(res.data.phaseCompletionData);
        this.setState({
          phaseCompletionData: res.data.phaseCompletionData,
        });
      })
      .catch((error) => {
        console.log("error in fetching projects completion data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  updateFilters(name, list) {
    let selectedFiltersData = this.state.selectedFiltersData;
    if (selectedFiltersData == null) {
      selectedFiltersData = {};
    }
    if (list == null || list.length == 0) {
      delete selectedFiltersData[name];
    } else {
      selectedFiltersData[name] = list;
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
  }

  removeFilter(filterName, filterVal) {
    let selectedFiltersData = this.state.selectedFiltersData;
    let filterList = selectedFiltersData[filterName];
    selectedFiltersData[filterName] = filterList.filter((item) => {
      return item != filterVal;
    });

    if (
      selectedFiltersData[filterName] == null ||
      selectedFiltersData[filterName].length == 0
    ) {
      delete selectedFiltersData[filterName];
    }

    this.setState({ selectedFiltersData: selectedFiltersData },() =>{
      sessionStorage.setItem(
        "selectedFiltersData",
        JSON.stringify(this.state.selectedFiltersData)
      );
    });
    this.getCurrentMonthAndYear();
    this.getLatestData();
    this.getProjectCount();
    this.fetchProjectNames();
    this.getProjectsOverview();
    this.getProjectsCompletedByPhase();
    this.getHealthScoreTrend();
    this.getConfidenceScore();
    this.getBusinessMeasurePerformance();
    this.getProjectsCompletion();
    this.getSummaryProjectsDetails();
  }

  closeFilterPaneOnApplying() {
    this.setState({ isFilterOpen: false });
  }

  toggleFilter() {
    const isFilterOpen = this.state.isFilterOpen;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  async clearFilters() {
    await this.setState({ selectedFiltersData: {} });
    this.applyFilters();
  }

  applyFilters() {
    this.closeFilterPaneOnApplying();
    this.getCurrentMonthAndYear();
    this.getLatestData();
    this.getProjectCount();
    this.fetchProjectNames();
    this.getProjectsOverview();
    this.getProjectsCompletedByPhase();
    this.getHealthScoreTrend();
    this.getConfidenceScore();
    this.getBusinessMeasurePerformance();
    this.getProjectsCompletion();
    this.getSummaryProjectsDetails();
    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(this.state.selectedFiltersData)
    );
  }

  onRadioCheck = (e) => {
    if (e.target.value === "G") {
      this.setState({ GRadioChecked: true, BRadioChecked: false });
    } else {
      this.setState({ GRadioChecked: false, BRadioChecked: true });
    }
  };

  setProjectName(projectName) {
    this.setState({ projectName: projectName });
  }

  openListOfProjectsModal() {
    this.setState({ showListOfProjectsModal: true }, this.bindEvent);
  }

  openListOfOverviewProjectsModal() {
    this.setState({ showProjectOverview: true }, this.bindEvent);
  }

  closeListOfProjectsModal() {
    this.setState({
      showListOfProjectsModal: false,
      showProjectOverview: false,
    });
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 5);
  }

  render() {
    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    if (this.state.showKeepFiltersModal === true) {
      return (
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Retain Filters</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>Would you like to retain the filters applied?</div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              id="yes-no-button"
              onClick={() => this.keepFilters()}
            >
              YES
            </Button>
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={() => this.closeKeepFiltersModal()}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    let {
      activeTab,
      latestData,
      isFilterOpen,
      chartData,
      monthsChartData,
      csvColumnMapping,
      filtersAPIData,
      latestDataLoaded,
      tcqsDataLoaded,
      allMonthsDataLoaded,
      lineChartData,
      lineChartDataLoaded,
      activityList,
      milestoneDbColName,
      milestoneProjectCount,
      projectIdName,
      projectNameIdMap,
      selectedSliderData,
      showMap,
      sliderSize,
      sliderOptionList,
      isLoading,
      projectsOverviewData,
      projectsCompletedByPhaseData,
      confidenceScoreData,
      confidenceProjectsData,
      healthScoreTrendData,
      phaseCompletionData,
      businessMeasurePerformanceData,
      businessMeasurePerformanceForecastData,
      healthScoreTrendForecastedData,
    } = this.state;

    /*
    healthScoreTrendData = {
      "Jan-2022": 25,
      "Feb-2022": 25,
      "Mar-2022": 25,
    };
    */

    let uiFilterData, filterSection;
    let filtersData = filtersAPIData;

    if (filtersData) {
      const filterNames = Object.keys(filtersData);
      uiFilterData = filterNames.map((item, index) => {
        const filterName = item;
        const filterValues = filtersData[filterName].filter((itm) => {
          return itm != null && itm.trim() != "";
        });
        /*
        const filterValues = filtersData[filterName].map((itm, idx) => {
          return itm == "" ? "(Blanks)" : itm;
        });
        */
        return (
          <span
            key={`span${index}`}
            style={{
              float: index % 2 === 0 ? "left" : "right",
            }}
          >
            <Filter
              key={index}
              filterValues={filterValues}
              csvColumnMapping={csvColumnMapping}
              filterData={this.state.selectedFiltersData}
              filterName={filterName}
              setSelectedOptions={(name, list) =>
                this.updateFilters(name, list)
              }
            ></Filter>
          </span>
        );
      });
    }
    if (uiFilterData) {
      filterSection = <div>{uiFilterData}</div>;
    }
    const filters = this.state.selectedFiltersData;
    let filterSummaryData, list, singleFilter;
    let test = [];
    let filtersArray = [];
    Object.entries(filters).forEach((filterItem) => {
      filterItem[1].forEach((filter) => {
        filtersArray.push({
          filterCategory: filterItem[0],
          filter: filter,
        });
      });
    });

    const getSingleFilterObject = (length, filterCategory, filter) => {
      return (
        <BootstrapTooltip title={filter}>
          <span>
            <div
              className="filter-summary-inner"
              style={
                length > this.state.tablet - 1
                  ? { display: "none" }
                  : { display: "inline" }
              }
            >
              {filter.length < 15 ? filter : filter.substr(0, 14) + "..."}{" "}
              <FaTimes
                className="filter-delete"
                onClick={() => this.removeFilter(filterCategory, filter)}
              ></FaTimes>
            </div>
          </span>
        </BootstrapTooltip>
      );
    };

    let individualFilterArray = [];
    for (let i = 0; i < filtersArray.length; i++) {
      if (filtersArray[i]) {
        individualFilterArray.push(
          getSingleFilterObject(
            i,
            filtersArray[i].filterCategory,
            filtersArray[i].filter
          )
        );
      }
    }

    return (
      <React.Fragment>
        <Header
          id="currhead"
          open={this.props.open}
          setOpen={this.props.setOpen}
        >
          <Row>
            <Col xs={4}>
              <div className="currentClass">Summary</div>
              <div className="basedClass">
                <span className="year-month-dashboard">
                  {GenericConstants.MONTH_MAPPING[this.props.month]
                    ? GenericConstants.MONTH_MAPPING[this.props.month]
                    : this.props.month}{" "}
                  {this.props.year}
                </span>
                {this.props.month ? " snapshot" : ""}, <span style={{color:'black'}}>Uploaded On:</span> {this.state.latestCSVDate}
                <br />
                <span style={{ float: "left" }}>{"Live Projects Only"}</span>
              </div>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Header>
        <Row>
          <Col xs={2} md={2}>
            <Row>
              <Col xs={12} md={12} className="total-label">
                {this.state.totalProjects && !this.state.totalProjectLoading ? (
                  <BootstrapTooltip title="Click here to see the list of projects">
                    <a
                      onClick={() => this.openListOfProjectsModal()}
                      className="list-of-proj-hyperlink"
                    >
                      <span style={{ color: "black" }}>
                        {individualFilterArray.length !== 0 &&
                        this.state.showMatchingProjects === true
                          ? "Matching Projects"
                          : "Total Projects"}
                      </span>
                    </a>
                  </BootstrapTooltip>
                ) : (
                  ""
                )}
              </Col>
              <Col xs={12} md={12} className="project-count">
                {this.state.totalProjectLoading ? (
                  <div style={{ color: "#268cd1" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>
                    <BootstrapTooltip title="Click here to see the list of projects">
                      <a
                        onClick={() => this.openListOfProjectsModal()}
                        className="list-of-proj-hyperlink"
                      >
                        <span>
                          <u>{this.state.totalProjects}</u>
                        </span>
                      </a>
                    </BootstrapTooltip>
                  </div>
                )}
                {this.state.showListOfProjectsModal &&
                this.state.totalProjects &&
                this.state.projectsSummaryData ? (
                  <SummaryProjectDetails
                    allSummaryProject={this.state.projectsSummaryData}
                    closeModal={() => this.closeListOfProjectsModal()}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={5} md={5}>
            {/* {individualFilterArray} */}
            {
              <div
                className="filter-summary"
                style={{
                  width: "auto",
                  background: "none",
                  marginBottom: "0px",
                }}
              >
                <div className="filter-summary-title">
                  {/* Applied Filters */}
                </div>
                {/* {filterSummaryData} */}
                {individualFilterArray}
                <div
                  className="filter-summary-inner"
                  style={
                    individualFilterArray.length <= this.state.tablet
                      ? { display: "none" }
                      : { display: "inline" }
                  }
                >
                  {individualFilterArray.length > this.state.tablet
                    ? individualFilterArray.length - this.state.tablet + " +"
                    : ""}
                </div>
              </div>
            }
          </Col>
          <Col xs={3} md={3}>
            {/*
                  {latestData != null && (
                    <Button
                      color="primary"
                      onClick={this.openListOfProjectsModal}
                      size="sm"
                      className="list-of-proj-dashboard"
                    >
                      List Of Projects
                    </Button>
                  )}
                  */}
            {/*
                <span className="line-chart-radio-buttons">
                  <label className="radio-label">
                    Forecasted
                    <input
                      type="radio"
                      name="BdataToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="B"
                      checked={this.state.BRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                    Current
                    <input
                      type="radio"
                      name="GdataToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="G"
                      checked={this.state.GRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </span>
                */}
          </Col>
          <Col xs={2} md={2}>
            {latestData && (
              <div style={{ position: "relative" }}>
                <div className="filter">
                  <CardHeader className="filter-header">
                    <span onClick={this.toggleFilter}>
                      <b>All Filters </b>
                      <span style={{ color: "#BD2967" }}>
                        {!isFilterOpen && <FaAngleDown></FaAngleDown>}{" "}
                        {isFilterOpen && <FaAngleUp></FaAngleUp>}
                      </span>
                    </span>
                  </CardHeader>
                  <Collapse
                    isOpen={isFilterOpen}
                    className="filter-main"
                    timeout={0}
                  >
                    <Row>
                      <Col>
                        <CancelIcon
                          className="cancel-close-icon"
                          onClick={
                            individualFilterArray.length !== 0
                              ? this.closeFilterConfirmation
                              : this.noFilterSelectedCloseFilter
                          }
                          id="cancel-filter"
                          fontSize="small"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {filterSection && (
                          <div className="filter-div">{filterSection}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="filter-buttons">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.clearFilters();
                              this.setState({
                                showMatchingProjects: false,
                                totalProjectLoading: true,
                              });
                            }}
                            outline
                          >
                            Reset All
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.applyFilters();
                              this.setState({
                                showMatchingProjects: true,
                                totalProjectLoading: true,
                              });
                            }}
                          >
                            Apply Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              </div>
            )}
          </Col>
        </Row>
        {/*<Row className="chart-top" style={{ paddingBottom: "1rem" }}>*/}
        <Row>
          {/*<Col xs={2}>
                <OverviewChart />
              </Col>*/}
          <Col>
            <Row>
              <OverviewHorizontalChart
                projectsOverviewData={projectsOverviewData}
                projectsCompletedByPhaseData={projectsCompletedByPhaseData}
                viewProjectDetails={() =>
                  this.openListOfOverviewProjectsModal()
                }
              />
              {this.state.showProjectOverview &&
              this.state.overSpendProjects?.length ? (
                <SummaryProjectDetails
                  allSummaryProject={this.state.overSpendProjects}
                  closeModal={() => this.closeListOfProjectsModal()}
                  listTitle={'List of projects on the overspend list'}
                />
              ) : (
                ""
              )}
            </Row>
            <Row>
              {healthScoreTrendData ? (
                <HealthScoreTrendChart
                  chartData={healthScoreTrendData}
                  forecastChartData={healthScoreTrendForecastedData}
                  projectCurrentHealthData={this.state.projectCurrentHealthData}
                  projectForecastedHealthData={
                    this.state.projectForecastedHealthData
                  }
                  isForecasted={false}
                />
              ) : (
                ""
              )}
            </Row>
            <Row>
              <BusinessMeasuresPerformanceChart
                chartData={businessMeasurePerformanceData}
                forecastChartData={businessMeasurePerformanceForecastData}
                csvColumnMapping={csvColumnMapping}
              />
            </Row>
            <Row>
              {confidenceScoreData ? (
                <ConfidenceScoreChart chartData={confidenceScoreData}  confidenceProjectsData={confidenceProjectsData} />
              ) : (
                ""
              )}
            </Row>
            <Row>
              {phaseCompletionData ? (
                <ProjectsCompletionChart chartData={phaseCompletionData} />
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SummaryPage;
