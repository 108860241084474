import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Row, Col, Button, Modal, ModalBody, Spinner, Table } from "reactstrap";
import { MdInfo } from "react-icons/md";
import "chartjs-plugin-datalabels";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "jspdf-autotable";
import chroma from "chroma-js";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Radio } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "../../../../styles/components/Chart.scss";
// import axios from "axios";
// import jsPDF from "jspdf";
// import Select from "react-select";
// import * as FilterProcessingService from "../../../../services/FilterProcessingService";
// import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
// import { ErrorHandler } from "../../../ErrorHandler";
// import * as GenericConstants from "../../../../src-constants/GenericConstants";
// import AutoCloseAlert from "../../../AutoCloseAlert";
// import * as ErrorConstants from "../../../../src-constants/ErrorConstants";
// import SessionExpired from "../../../SessionExpired";
import HorizontalBarChart from "../../predictions/charts/HorizontalBarChart";
import jsPDF from "jspdf";
import HorizontalStackChart from "../../currentstatus/charts/HorizontalStackChart";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class HealthScoreTCQIChart extends Component {
  bgColors = ["#96e079", "#E45266", "#eb7b15"];
  constructor(props) {
    super(props);
    this.state = {
      redraw: false,
      dataToShow: true,
      pieData0: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData1: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData2: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData3: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData4: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData5: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      pieData6: {
        maintainAspectRatio: true,
        responsive: false,
        labels: [" Green", " Red", " Amber"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: this.bgColors,
            hoverBackgroundColor: ["#b4f09c", "#f46767", "#ffd700"],
          },
        ],
      },
      selectedCurrentProjectValue: null,
      sortData: {
        hitem:'',
        dir:'',
      }
    };
    this.closeModal = this.closeModal.bind(this);
    this.fetchQualityParamBreakdown =
      this.fetchQualityParamBreakdown.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    this.downloadTablePdf = this.downloadTablePdf.bind(this);
  }

  downloadTablePdf() {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title = `List of projects with the health score in the range  ${this.state.qualityValueSelected} for  ${this.state.qualityParamSelected}`;

    const headers = [
      ["Project Id", "Project Name", ...this.state.modalTableDataHeader],
    ];

    let data = this.state.modalTableFilterData.map((dataItem) => [
      dataItem["projectId"],
      dataItem["projectName"],
      ...this.state.modalTableDataHeader.map((item) => dataItem[item]),
      //elt["recommendation"],
    ]);

    let content = {
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`List of Projects ${this.state.qualityValueSelected}.pdf`);
  }

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let modalTableFilterData = [];
    let dataArray = [];

    let arr = [];
    if (value === null || value === "") {
      this.setState({
        modalTableFilterData: this.state.modalTableData,
        modalTableProjectFilterData: this.state.modalTableData,
      },() => {
        this.SetSortData({hitem:'% Change from last month',dir: 'asc'})
      });
    } else {
      this.state.modalTableData.map((data) => {
        dataArray.push(data);
        if (
          data["projectId"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          modalTableFilterData.push(data);
        }
        if (dataArray.length === this.state.modalTableData.length) {
          this.setState({
            modalTableFilterData: modalTableFilterData,
            modalTableProjectFilterData: modalTableFilterData,
          },(() => {
            this.SetSortData({hitem:'% Change from last month',dir: 'asc'})
          }));
        }
      });
    }
  }

  fetchQualityParamBreakdown(qualityParam, qualityValueSelected) {
    if (qualityParam && qualityValueSelected) {
      let qualityParamLowerCase = qualityParam.toLowerCase();
      if (qualityParamLowerCase=='h&s'){
        qualityParamLowerCase = 'hs'
      }
      let modalTableDataHeader = [];
      let tcqiActvityFeatureWeights = this.state.dataToShow?this.props?.chartData?.tcqiActivityData:this.props?.forecastChartData?.tcqiActivityData;
      let allData = this.state.dataToShow? this.props?.chartData?.allData:this.props?.forecastChartData?.allData;


      if (tcqiActvityFeatureWeights && tcqiActvityFeatureWeights[qualityParamLowerCase]) {
        let activityData =
          tcqiActvityFeatureWeights[qualityParamLowerCase][qualityValueSelected];
        this.setState({ horizontalBarChartData: activityData });
      }

      if (allData) {
        let projectsData = allData[qualityParamLowerCase];

        if (projectsData) {
          let numData = [];
          let naData = []; 
          projectsData[qualityValueSelected].forEach((item,key) => {
            
            if(isNaN(item['% Change from last month'])){
              naData.push(item);
            } else{
              numData.push(item);
            }
          })

           projectsData = [...numData,...naData];
          let projectIdName = [];
          if (projectsData) {
            for (let k = 0; k < projectsData.length; k++) {
              projectIdName.push(
                projectsData[k].projectId +
                  "(" +
                  projectsData[k].projectName +
                  ")"
              );
            }
            if (projectsData[0]) {
              const keys = { ...projectsData[0] };
              delete keys.projectId;
              delete keys.projectName;
              modalTableDataHeader = Object.keys(keys);
            }
          }
          const hideActivityTab = qualityParamLowerCase === 'overall';

          
          this.setState({
            modalTableData: [...projectsData],
            modalTableDataHeader,
            modalTableFilterData: [...projectsData],
            projectIdName: projectIdName,
            isProjectTabSelected: hideActivityTab,
            hideListOfActivity: hideActivityTab,
            showProjectListModal: true,
          },() => {
            this.SetSortData({hitem:'% Change from last month',dir: 'asc'});
          });
        }
      }
    } else {
      this.setState({ showProjectListModal: true });
    }

  }

  onBarClick(label, event, i) {
    if (i && i[0]) {
      let labels = {
        red: { key: "R", value: "0 to 33%" },
        green: { key: "G", value: ">67 to 100%" },
        amber: { key: "A", value: ">33 to 67%" },
      };
      let qualityValueSelected = labels.green.value;
      let qualityKeySelected = labels.green.key;
      const optionVal = i[0]._view.label.trim().toLowerCase();
      if (labels[optionVal]) {
        qualityValueSelected = labels[optionVal].value;
        qualityKeySelected = labels[optionVal].key;
      }
      this.setState(
        {
          qualityValueSelected: qualityValueSelected,
          qualityParamSelected: label,
        },
        () => {
          this.fetchQualityParamBreakdown(label, qualityKeySelected);
        }
      );
    }
  }

  closeModal() {
    this.setState({
      showProjectListModal: false,
      horizontalBarChartData: null,
      isProjectTabSelected: false,
      selectedCurrentProjectValue: null,
      sortData:{
        hitem:'',
        dir:'',
      },
      goToCurrentPerformanceViaProject: null,
    });
  }

  bindEvent() {
    if (document.querySelector(".table-scroll-modal"))
      document
        .querySelector(".table-scroll-modal")
        .addEventListener("scroll", () => {
          let maxWidth = parseInt(
            window
              .getComputedStyle(document.querySelector("thead"))
              .width.replace("px", "")
          );
          let computedWidth =
            parseInt(
              window
                .getComputedStyle(document.querySelector(".table-scroll-modal"))
                .width.replace("px", "")
            ) +
            document.querySelector(".table-scroll-modal").scrollLeft +
            1;
          if (computedWidth > maxWidth) {
            computedWidth = maxWidth;
          }
          document.querySelectorAll("table > *")[1].style.width =
            computedWidth + "px";
          document.querySelectorAll("table > *")[0].style.width =
            computedWidth + "px";
        });
  }

  SetSortData(sortData){
    if(this.state.modalTableFilterData && this.state.modalTableFilterData.length){
      const data = [...this.state.modalTableFilterData];
      const getSortOrder = ({hitem:prop, dir}) => {
        const transform = k => {
          if (isNaN(k)) return  dir === 'asc'?Infinity:-Infinity;
          else return k;
      }
  
        return function (a, b) {
            const floatA = transform(Number(a[prop]));
            const floatB = transform(Number(b[prop]));

            if((floatA > floatB)){
                return dir === 'asc'?1:-1;
            }
            else if((floatA < floatB)){
                return dir === 'asc'?-1:1;
            }
            return 0;
        };
      };
      data.sort(getSortOrder(sortData));
      this.setState({modalTableFilterData: data,sortData});
    }
    

  }

  handleChangeCurrentPerformance(projectVal) {
    this.setState({
      selectedCurrentProjectValue: projectVal,
    });
  }

  handleCurrentPerformance() {
    if (this.state?.selectedCurrentProjectValue) {
      this.setState({ goToCurrentPerformanceViaProject: true });
    }
  }

  onRadioCheck = (e) => {
    this.setState({ dataToShow: e.target.value === "C", redraw: true });
  };

  getOverAllChartData(chartData0) {
    let chartData = this.state.dataToShow
      ? this.props?.chartData?.allData
      : this.props?.forecastChartData?.allData;
    if (chartData) {
      chartData0.datasets[0].data[0] = chartData.overall["G"].length;
      chartData0.datasets[0].data[1] = chartData.overall["R"].length;
      chartData0.datasets[0].data[2] = chartData.overall["A"].length;
    } else {
      chartData0.datasets[0].data[0] = 0;
      chartData0.datasets[0].data[1] = 0;
      chartData0.datasets[0].data[2] = 0;
    }
  }

  render() {
    if (this.state?.goToCurrentPerformanceViaProject) {
      let pathname = `/ui/landing/currentstatus`;
      if(this.state.dataToShow === false){
        pathname = `/ui/landing/forecast`;
      }
      return (
        <Redirect
          to={{
            pathname,
            state: {
              projectId: this.state?.selectedCurrentProjectValue,
              qualityParam: this.state?.qualityParamSelected,
              from: "summary",
            },
          }}
        />
      );
    }

    const pieOptions = (label) => {
      const options = {
        legend: {
          display: false,
          position: "right",
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            fontFamily: "Ubuntu-Medium",
            fontSize: 4,
            font: {
              size: 10,
              family: "Ubuntu-Medium",
            },
            // formatter: (value, ctx) => {
            //   let sum = 0;
            //   let dataArr = ctx.chart.data.datasets[0].data;
            //   dataArr.map((data) => {
            //     if (data) {
            //       sum += parseInt(data);
            //     }
            //   });
            //   if (!value) {
            //     return "";
            //   }
            //   let percentage = ((value * 100) / sum).toFixed(0) + "%";
            //   return percentage;
            // },
          },
        },
        onClick: this.onBarClick.bind(this, label),
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      };
      return { ...options };
    };


    let chartData0 = this.state.pieData0;
    let chartData1 = this.state.pieData1;
    let chartData2 = this.state.pieData2;
    let chartData3 = this.state.pieData3;
    let chartData4 = this.state.pieData4;
    let chartData5 = this.state.pieData5;
    let chartData6 = this.state.pieData6;

    let chartData =  this.state.dataToShow
    ? this.props?.chartData?.allData
    : this.props?.forecastChartData?.allData;

    if (chartData) {
      this.getOverAllChartData(chartData0);

      chartData1.datasets[0].data[0] = chartData.time["G"].length;
      chartData1.datasets[0].data[1] = chartData.time["R"].length;
      chartData1.datasets[0].data[2] = chartData.time["A"].length;

      chartData2.datasets[0].data[0] = chartData.cost["G"].length;
      chartData2.datasets[0].data[1] = chartData.cost["R"].length;
      chartData2.datasets[0].data[2] = chartData.cost["A"].length;

      chartData3.datasets[0].data[0] = chartData.quality["G"].length;
      chartData3.datasets[0].data[1] = chartData.quality["R"].length;
      chartData3.datasets[0].data[2] = chartData.quality["A"].length;

      chartData4.datasets[0].data[0] = chartData.scope["G"].length;
      chartData4.datasets[0].data[1] = chartData.scope["R"].length;
      chartData4.datasets[0].data[2] = chartData.scope["A"].length;

      chartData5.datasets[0].data[0] = chartData.hs["G"].length;
      chartData5.datasets[0].data[1] = chartData.hs["R"].length;
      chartData5.datasets[0].data[2] = chartData.hs["A"].length;

      chartData6.datasets[0].data[0] = chartData.sme["G"].length;
      chartData6.datasets[0].data[1] = chartData.sme["R"].length;
      chartData6.datasets[0].data[2] = chartData.sme["A"].length;
    }

    return (
      <Col>
        <div
          className="chart-top"
          style={{
            //padding: "0.55rem",
            fontSize: "0.8rem",
            textAlign: "center",
            paddingBottom: "0.9rem",
          }}
        >
          <div className=" d-flex justify-content-between">
          <div style={{ marginLeft: "2rem" , marginTop:'1.7%'}} className="f-14">
          Total number of projects by its range in RAG score representing their performance feeding into an overall score
                  <BootstrapTooltip
                    placement="right-start"
                    title={
                      <>
                      <div>
                      The doughnut charts represent the total number of projects by its range in RAG score. The performance of the six business measures feed into the overall score.
                      </div>
                      <div className="mt-2">
                        Red represents the score range between 0 and 33%, Amber represents score range 33 - 67% and Green represents score range 67% - 100%
                      </div>
                      <div className="mt-2">
                      Click on Red, Amber or Green part of a doughnut to see the list of projects in the respective score range and the list of activities that feed into the selected business measure.
                      </div>
                      </>
                    }
                  >
                  <span>
                    <MdInfo size={20} className="c-info mb-1"></MdInfo>
                  </span>
                  </BootstrapTooltip>
                </div>


            <span className="current-forecasted-radio-buttons">
              <label className="radio-label">
                Current
                <input
                  type="radio"
                  name="dataToShow"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="C"
                  checked={this.state.dataToShow}
                ></input>
                <span className="checkmark"></span>
              </label>
              <label className="radio-label">
                Forecasted
                <input
                  type="radio"
                  name="dataToShow"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="F"
                  checked={this.state.dataToShow === false}
                ></input>
                <span className="checkmark"></span>
              </label>
            </span>
          </div>
          <Row
            style={{
              marginTop: "0rem",
              padding: "0rem",
            }}
          >
            <Col>
              <Row>
                <div className="chart-colors row justify-content-center w-100">
                  <div className="d-flex justify-content-center align-items-center mr-2">
                    <div
                      className="rect mr-2"
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: this.bgColors[1],
                      }}
                    ></div>
                    {"0 to 33%"}
                  </div>
                  <div className="d-flex justify-content-center align-items-center mr-2">
                    <div
                      className="rect mr-2"
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: this.bgColors[2],
                      }}
                    ></div>
                    {">33 to 67%"}
                  </div>
                  <div className="d-flex justify-content-center align-items-center mr-2">
                    <div
                      className="rect mr-2"
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: this.bgColors[0],
                      }}
                    ></div>
                    {">67 to 100%"}
                  </div>
                </div>
              </Row>
              <Row>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData0}
                    options={pieOptions("Overall")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  Overall
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData1}
                    options={pieOptions("Time")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  Time
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData2}
                    options={pieOptions("Cost")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  Cost
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData3}
                    options={pieOptions("Quality")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  Quality
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData4}
                    options={pieOptions("Scope")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  Scope
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData5}
                    options={pieOptions("H&S")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  H&S
                </Col>
                <Col
                  style={{
                    padding: "0rem",
                    width: "12.499999995%",
                  }}
                >
                  <br />
                  <Doughnut
                    data={chartData6}
                    options={pieOptions("SME")}
                    height={160}
                    redraw={this.state.redraw}
                  />
                  SME
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.showProjectListModal ? (
            <div>
              <Modal
                isOpen={true}
                centered={true}
                size="lg"
                className="tcqs-current-modal"
              >
                <ModalBody
                  id="show-key-activities-modal"
                  className="p-0 tcqs-current-modal-body"
                >
                  <div className="p-2">
                    <CancelIcon
                      onClick={this.closeModal}
                      className="cancel-close-icon"
                    />
                    <Row>
                      <Col>
                        <center>
                          <span
                            id="key-tasks-to-text"
                            style={{ color: "#252525" }}
                          >
                            {(this.state.isProjectTabSelected) ? (
                              <span>
                                {`List of projects with the health score in the range 
                                ${this.state.qualityValueSelected} for 
                                ${this.state.qualityParamSelected}`}
                              </span>
                            ) : (
                              <span>
                                {`List of activities for ${this.state.qualityParamSelected} where the health score is in the range ${this.state.qualityValueSelected}`}
                              </span>
                            )}
                          </span>
                        </center>
                      </Col>
                    </Row>
                  </div>
                  <Row className="m-0 menu-tabs">
                   {(this.state.hideListOfActivity === false)?<Col
                      className={`text-right ${
                        !this.state.isProjectTabSelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        this.setState({ isProjectTabSelected: false })
                      }
                    >
                      <BootstrapTooltip
                        placement="left"
                        title={
                          <>
                          <div>
                          The list of activities chart represents all the activities that feed into the selected business measure.
                           Each bar in the chart represents a BRAG status with the count of projects with a particular status that signifies the following -
                          </div>
                          <div className="mt-2">
                            <ul>
                              <li>Red - needs attention or action</li>
                              <li>Amber - a risk but not an issue yet</li>
                              <li>Green - on track, no issues</li>
                              <li>Blue - complete</li>
                              <li>Grey - expired, not required, not due yet</li>
                              <li>NA - data not available</li>
                            </ul>
                          </div>
                          </>
                        }
                      >
                        <span>List of Activities</span>
                      </BootstrapTooltip>
                    </Col>:<Col></Col>}
                    <Col
                      className={
                        (this.state.isProjectTabSelected)  ? "selected" : ""
                      }
                      onClick={() =>
                        this.setState({ isProjectTabSelected: true }, () => {
                          this.bindEvent();
                        })
                      }
                    >
                      <BootstrapTooltip
                        placement="right"
                        title={<>
                          <div>
                          This table represents the list of projects with their current and the past month score for the selected business measure.
                          </div>
                          <div className="mt-2">
                          Select a project from the list and click on {this.state.dataToShow?'current':'forecasted'} performance button on the top left to view the {this.state.dataToShow?'current':'forecasted'} performance of the selected project.
                          </div>
                        </>}
                      >
                        <span>List of Projects</span>
                      </BootstrapTooltip>
                    </Col>
                  </Row>
                  {!this.state.isProjectTabSelected ? (
                    this.state.horizontalBarChartData &&
                    JSON.stringify(this.state.horizontalBarChartData) !==
                      JSON.stringify({}) ? (
                      // JSON.stringify(this.state.horizontalBarChartData)
                      // create again a bar chart
                      <HorizontalStackChart
                        stackChartData={this.state.horizontalBarChartData}
                        qualityValue={this.state.qualityValueSelected}
                        qualityParam={this.state.qualityParamSelected}
                        summaryPage={true}
                        chartHeight={
                          this.state.horizontalBarChartData.length * 45
                        }
                      />
                    ) : (
                      <div style={{ height: "55.5vh" }}>
                        <br />
                        <div className="no-data-available-current-month-activity">
                          No data available of list of activities for status '
                          {this.state.qualityValueSelected}' in{" "}
                          {this.state.qualityParamSelected}
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  {this.state.isProjectTabSelected ? (
                    this.state.modalTableData &&
                    JSON.stringify(this.state.modalTableData) !==
                      JSON.stringify({}) ? (
                      <div className="p-3">
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <div>
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    this.handleCurrentPerformance()
                                  }
                                  id="current-performance-button"
                                  disabled={
                                    !this.state.selectedCurrentProjectValue
                                  }
                                  color={
                                    this.state.selectedCurrentProjectValue
                                      ? "primary"
                                      : "light"
                                  }
                                >
                                  {this.state.dataToShow?'Current':'Forecasted'} Performance
                                </Button>
                              </div>

                              <center className="flex-grow-1">
                                <div className="form-input-wrapper-current-month-modal">
                                  <Autocomplete
                                    options={this.state.projectIdName}
                                    onInputChange={this.searchByProject}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Project"
                                      />
                                    )}
                                    size="small"
                                  />
                                </div>
                              </center>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <center>
                              <div className="current-month-chart">
                                <Table
                                  responsive
                                  className="table table-scroll-modal table-modal table-md table-list-of-projects table-tcqs-modal"
                                  id="table-task-list"
                                >
                                  <thead className="table-head">
                                    <tr>
                                      <th
                                        style={{
                                          paddingBottom: "0.97rem",
                                        }}
                                        className="project-id-col"
                                      >
                                        Project ID
                                      </th>
                                      <th
                                        style={{ paddingBottom: "0.97rem" }}
                                        className="project-name-col"
                                      >
                                        Project Name
                                      </th>
                                      {this.state.modalTableDataHeader.map(
                                        (item, index) => (
                                          <ListProjectHeader hitem={item} key={index} sortData={this.state.sortData} SetSortData={(e) => this.SetSortData(e)} />
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.modalTableFilterData ? (
                                      this.state.modalTableFilterData.length >=
                                      1 ? (
                                        this.state.modalTableFilterData.map(
                                          (activityObject, index) => (
                                            <tr key={index}>
                                              <td className="table-data-bold project-id-col">
                                                <div className="d-flex align-items-center">
                                                  <Radio
                                                    size="small"
                                                    onChange={() =>
                                                      this.handleChangeCurrentPerformance(
                                                        activityObject.projectId
                                                      )
                                                    }
                                                    value={
                                                      activityObject.projectId
                                                    }
                                                    name="radio-buttons"
                                                    checked={
                                                      this.state
                                                        .selectedCurrentProjectValue ===
                                                      activityObject.projectId
                                                    }
                                                  />
                                                  {activityObject.projectId}
                                                </div>
                                              </td>
                                              <td className="table-data-bold project-name-col">
                                                {activityObject.projectName}
                                              </td>
                                              {this.state.modalTableDataHeader.map(
                                                (item, index) => (
                                                  <td
                                                    key={index}
                                                    className="table-data-black  centre-align-data"
                                                  >
                                                    {activityObject[item]}
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr></tr>
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          style={{
                                            width: "100vw",
                                            paddingLeft: "40vw",
                                          }}
                                        >
                                          <span style={{ marginLeft: "18rem" }}>
                                            No data available for '
                                            {this.state.qualityValueSelected}'
                                            in {this.state.qualityParamSelected}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </center>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <center>
                              <Button
                                color="primary"
                                onClick={this.downloadTablePdf}
                                size="sm"
                                id="download-list-key-actvity"
                                disabled={
                                  this.state.modalTableFilterData &&
                                  JSON.stringify(
                                    this.state.modalTableFilterData
                                  ) !== JSON.stringify([])
                                    ? false
                                    : true
                                }
                                className="download-list-current-month"
                              >
                                {this.state.isDownloading ? (
                                  <div>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">Loading...</span>{" "}
                                    Downloading...
                                  </div>
                                ) : (
                                  <div>
                                    <GetAppIcon fontSize="small" /> DOWNLOAD
                                    LIST
                                  </div>
                                )}
                              </Button>
                            </center>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div style={{ height: "55.5vh" }}>
                        <br />
                        <div className="no-data-available-current-month-activity">
                          No data available of list of projects for status '
                          {this.state.qualityValueSelected}' in{" "}
                          {this.state.qualityParamSelected}
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </ModalBody>
              </Modal>
            </div>
          ) : (
            ""
          )}
        </div>
      </Col>
    );
  }
}

export default HealthScoreTCQIChart;



const ListProjectHeader = ({hitem, SetSortData, sortData}) => {

  let applySorting = hitem.toLowerCase().includes('health score') || hitem.toLowerCase().includes('change');

  if(applySorting){

  return (<th
    style={{paddingBottom: "0.97rem"}}
    className="centre-align-data"
>
  {hitem}
  <ExpandLessIcon
    onClick={() => {
      SetSortData({hitem,dir: 'asc'});
    }}
    fontSize="small"
    className="expand-less-icon-sorting"
    style={{
      color:
      (sortData.hitem === hitem && sortData.dir === 'asc')
          ? "red"
          : "black",
    }}
  />
  <ExpandMoreIcon
    onClick={() => {
      SetSortData({hitem,dir: 'desc'});
    }}
    fontSize="small"
    className="expand-more-icon-sorting"
    style={{ 
      color:
      (sortData.hitem === hitem && sortData.dir === 'desc')
          ? "red"
          : "black",
    }}
  />
</th>)
} else {
    return <th style={{paddingBottom: "0.97rem"}}>
        {hitem}
    </th>
}
}
