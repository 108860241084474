import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, Row, Spinner, Table } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "@material-ui/core";
import jsPDF from 'jspdf';
import GetAppIcon from "@material-ui/icons/GetApp";
import "jspdf-autotable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from '@material-ui/lab';
import { BootstrapTooltip, colourStyles } from './charts/ProjectCompletionChartDetailsView';
import Select from "react-select";



const SummaryProjectDetails = (props) => {
    const [projectData, SetProjectData] = useState([]);
    const [filter, SetFilter] = useState('');
    const { closeModal, allSummaryProject, listTitle } = props;
    const [projectIdName, SetProjectNameData] = useState([]);

    useEffect(() => {
        if(allSummaryProject && projectIdName && !projectIdName.length){
            const data = allSummaryProject.map((item) => {
                return {label:(`${item['Project Id']} (${item['Project Name']})`),id:item['Project Id']}}
                );
            SetProjectNameData(data);
        }
    },[allSummaryProject,projectIdName])

    useEffect(() => {
        console.log(filter)
        
        if (filter &&  filter.trim() && allSummaryProject?.length) {
            const trimFilter = filter.toLowerCase().trim();
            const fil = [...allSummaryProject.filter((item) =>{ return (item['Project Id'].toLowerCase().trim() === trimFilter)})]
            SetProjectData([...fil]);
        } else {
            SetProjectData([...allSummaryProject]);
        }
    }, [allSummaryProject, filter])

    const empty = (<>
        <div style={{ height: "55.5vh" }}>
            <br />
            <div className="no-data-available-current-month-activity">
                No data available of list of project
            </div>
        </div>

    </>)

    if (!projectData) {
        return empty;
    }

    return (<>
        <div>
            <Modal
                isOpen={true}
                centered={true}
                size="lg"
                className="tcqs-current-modal"

            >
                <ModalBody id="show-key-activities-modal" className="p-0 tcqs-current-modal-body">
                    <div className="p-2">
                        <CancelIcon
                            onClick={closeModal}
                            className="cancel-close-icon"
                        />
                        <Row>
                            <Col>
                                <center>
                                    <span
                                        id="key-tasks-to-text"
                                        style={{ color: "#252525" }}
                                    >
                                        {listTitle ? listTitle:'List Of Selected Projects'}
                                    </span>
                                </center>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <center>
                                <div className="form-input-wrapper">
                      
                                <Autocomplete
                                options={projectIdName}
                                getOptionLabel={(option) => option.label.toString()}
                                onChange={(e,val) => SetFilter((val?.id) || null)}

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Project"
                                  />
                                )}
                                size="small"
                              />
                                </div>
                            </center>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <ListSummaryProject key="sumProject" projectData={projectData} listTitle={listTitle} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    </>)


}


//TODO: update it in common helper files | DRY
const getSortOrder = ({hitem:prop, dir}) => {
    return function (a, b) {
        const floatA = parseFloat(a[prop]);
        const floatB = parseFloat(b[prop]);
        if((floatA > floatB)){
            return dir === 'asc'?1:-1;
        }
        else if((floatA < floatB)){
            return dir === 'asc'?-1:1;
        }
        return 0;
    };
  };


const getSortOrderString = ({hitem:prop, dir}) => {
    return function (a, b) {
        const floatA = a[prop];
        const floatB = b[prop];
        if((floatA > floatB)){
            return dir === 'asc'?1:-1;
        }
        else if((floatA < floatB)){
            return dir === 'asc'?-1:1;
        }
        return 0;
    };
  };


function ListSummaryProject({ projectData:mainProjectData, listTitle }) {

    const [isDownloading, SetIsDownloading] = useState(false);
    const [header, SetHeader] = useState([]);
    const [sortData, SetSortData] = useState({});
    const [projectData, SetProjectData] = useState();
    const [bragFilter, SetBragFilter] = useState(null);


    useEffect(() => {
        SetProjectData(mainProjectData);
    },[mainProjectData])

    useEffect(() => {
        if(projectData?.length){
            SetHeader(Object.keys(projectData[0]));
        }
    }, [projectData])


    useEffect(() => {
        if(mainProjectData){
            let filterData = [...mainProjectData];
            if(bragFilter?.val?.value){
                filterData =  filterData.filter((item) => item['Status of Last Milestone Achieved'] ===  bragFilter.val?.value);
            } 

            if(sortData?.hitem){
                if(sortData.hitem.toLowerCase() == 'no. of months overspending (actual or forecast)'){
                    filterData.sort(getSortOrderString(sortData));
                }
                else {
                    filterData.sort(getSortOrder(sortData));
                }
            }

            SetProjectData(filterData)

        }
    },[sortData,mainProjectData,bragFilter]);

    useEffect(() => {
        if (isDownloading) {
            const title = listTitle? listTitle : `List of Projects`;
            DownloadData({ title, filterData: projectData });
            SetIsDownloading(false);
        }
    }, [isDownloading, projectData,listTitle])
    
    return (<>

<Row>
            <Col>
                <center>
                    <div className="table-fixed-start-columns">
                        <Table
                            responsive
                            className="current-vs-forecasted table table-md table-custom table-list-of-forecasted-projects table-scroll-modal table-modal
                             table-md table-list-of-projects table-tcqs-modal"
                            id="table-task-list"
                            style={{ fontSize: "0.7rem" }}
                        >
                            <thead className="table-head">
                                <tr key='header'>
                                    {header.map((item, index) => (
                                    <ListProjectHeader key={index} hitem={item} sortData={sortData} SetSortData={SetSortData} 
                                    bragFilter={bragFilter}  SetBragFilter={SetBragFilter}
                                    />
                                    ))}

                                </tr>
                            </thead>
                            <tbody>
                                {projectData ? (((projectData?.length) > 0) ? (
                                    projectData.map(
                                        (itemData, index) => (
                                            <tr key={index}>
                                                {header.map((hitem, index) =>
                                                 <ListProjectRow hitem={hitem} itemData={itemData} key={index}   />
                                                )
                                                }
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr className='table table-md' key='empty'></tr>
                                )) : (
                                    <tr className='table table-md' key="noData">
                                        <td
                                            style={{
                                                width: "100vw",
                                                paddingLeft: "40vw",
                                            }}
                                        >
                                            <span style={{ marginLeft: "18rem" }}>
                                                No data available for list of projects
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </center>
            </Col>
        </Row>
        <Row className="p-2">
            <Col>
                <center>
                    <Button
                        color="primary"
                        onClick={() => SetIsDownloading(true)}
                        size="sm"
                        id="download-list-key-actvity"
                        disabled={
                            projectData &&
                                JSON.stringify(
                                    projectData
                                ) !== JSON.stringify([])
                                ? false
                                : true
                        }
                        className="download-list-current-month"
                    >
                        {isDownloading ? (
                            <div>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                                Downloading...
                            </div>
                        ) : (
                            <div>
                                <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                            </div>
                        )}
                    </Button>
                </center>
            </Col>
        </Row>
    </>)
}

export default SummaryProjectDetails;

const ListProjectHeader = ({hitem, SetSortData, sortData, bragFilter, SetBragFilter}) => {

    let applySorting = hitem.toLowerCase() === 'health score' || hitem.toLowerCase() === 'no. of months overspending (actual or forecast)';

    if(applySorting){
    return (<th
    style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
    className="table-data-black  centre-align-data"
  >
    {hitem}
    <ExpandLessIcon
      onClick={() => {
        SetSortData({hitem,dir: 'asc'});
      }}
      fontSize="small"
      className="expand-less-icon-sorting"
      style={{
        color:
        (sortData.hitem === hitem && sortData.dir === 'asc')
            ? "red"
            : "black",
      }}
    />
    <ExpandMoreIcon
      onClick={() => {
        SetSortData({hitem,dir: 'desc'});
      }}
      fontSize="small"
      className="expand-more-icon-sorting"
      style={{
        color:
        (sortData.hitem === hitem && sortData.dir === 'desc')
            ? "red"
            : "black",
      }}
    />
  </th>)
    } else {
        if (hitem.toLowerCase() === 'project id' || hitem.toLowerCase() === 'project name') {
            return <th className='table-data-black'>
                {hitem}
            </th>
        } else if(hitem.toLowerCase() === 'status of last milestone achieved') {
            return (<th>
                         <BootstrapTooltip placement="top" title={hitem}>
                          <span>
                            
                              <SelectOptions
                                selectedTableOptionsValue={bragFilter?.val}
                                setHeaderChangeVal={SetBragFilter}
                                item={hitem}
                              />
                            </span>
                        </BootstrapTooltip>
            </th>);
        } else {
            return <th className='table-data-black  centre-align-data'>
                {hitem}
            </th>
        }
    }
}


const ListProjectRow = ({hitem,itemData}) => {
    const headerVal = hitem.toLowerCase();
    let  classValue = 'table-data-black ';
    let colValue = itemData[hitem];
     if(headerVal !== 'project id' && headerVal !== 'project name' && headerVal !== 'last milestone achieved'){
        classValue += ' centre-align-data';
    }
    if (headerVal === 'no. of months overspending (actual or forecast)') {
        return (
            <td style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }} className={classValue}>
                <span> {colValue}</span>
            </td>)
    } else {
        return (
            <td style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }} className={classValue}>
                {colValue === 'R' || colValue === 'GR' || colValue === 'A' || colValue === 'B' || colValue === 'G' ?
                    (<div
                        className={
                            colValue === "R"
                                ? "red-circle"
                                : colValue === "GR"
                                    ? "grey-circle"
                                    : colValue === "A"
                                        ? "amber-circle"
                                        : colValue === "B"
                                            ? "blue-circle"
                                            : colValue === "G"
                                                ? "green-circle"
                                                : ""
                        }
                    >
                    </div>) : (
                        <span> {colValue}</span>
                    )
                }
            </td>)
    }

}


const DownloadData = ({ title, filterData }) => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const keys = { ...filterData[0] };

    let headers = [];
    let processDownload = [];
    let dataArr = filterData;
    let headerArr = Object.keys(keys);
    headers.push(...headerArr);

    for (let i = 0; i < dataArr.length; i++) {
        let array = [];
        for (let key in dataArr[i]) {
            if (dataArr[i][key]) {
                array.push(dataArr[i][key]);
            } else {
                array.push("NA");
            }
        }
        processDownload.push(array);
    }

    let content = {
        didParseCell: (data) => {
            if (data.cell.raw === "G") {
                data.cell.styles.textColor = [45, 197, 147];
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "R") {
                data.cell.styles.textColor = [228, 82, 102];
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "A") {
                data.cell.styles.textColor = [255, 191, 0];
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "NA") {
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "B") {
                data.cell.styles.textColor = "#268cd1";
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "GR") {
                data.cell.styles.textColor = "#808080";
                data.cell.styles.halign = "center";
            }

            if (data.cell.raw === "-") {
                data.cell.styles.halign = "center";
            }
        },
        didDrawPage: (data) => { },
        startY: 50,
        head: [headers],
        body: processDownload,
        theme: "grid",
        fontStyle: "bold",
        margin: { top: 10 },
        headStyles: { halign: "center" },
        pageBreak: 'auto',
        rowPageBreak: 'auto',

    };
    doc.text(title, marginLeft, 40);
    doc.autoTable({ ...content });
    doc.save(title + ".pdf");
    return true;
}


const colourOptions = [
    { value: "A", label: "A", color: "#eb7b15" },
    { value: "R", label: "R", color: "#FF5630" },
    { value: "G", label: "G", color: "#36B37E" },
    { value: "B", label: "B", color: "#0052CC" },
    { value: "GR", label: "GR", color: "grey" },
  ];


const SelectOptions = ({
    selectedTableOptionsValue,
    setHeaderChangeVal,
    item,
  }) => {
    return (
      <>
        <Select
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          isClearable 
          options={colourOptions}
          styles={colourStyles}
          value={selectedTableOptionsValue}
          onChange={(val) => setHeaderChangeVal({ val, item })}
          placeholder={<div style={{whiteSpace:'nowrap'}} className="custom-placeholder-placement">{item}</div>}
          isSearchable
        />
      </>
    );
  };