import React, { Component } from "react";
import { Row, Col, Table } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import chroma from "chroma-js";
import Select from "react-select";

import { HEADER_TYPE_TEXT } from "../../../components/RequestHeader";
import { ErrorHandler } from "../../ErrorHandler";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}



const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class SingleProjectActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActivityData: false,
      //allMeasures: { "all": "Overall", "c_time": "Time", "c_cost": "Cost", "c_quality": "Quality", "c_scope": "Scope", "c_h_s": "H&S", "c_sme_self_assessments": "SME" },
      allMeasures: {
        c_time: "Time",
        c_cost: "Cost",
        c_quality: "Quality",
        c_scope: "Scope",
        c_h_s: "H&S",
        c_sme_self_assessments: "SME SA",
      },
      isLoading: false,
      selectedValue: [],
      getSingleProjectActivitiesURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-important-activities/`,
    };
    this.getSingleProjectActivities =
      this.getSingleProjectActivities.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  componentDidMount() {
    const paramVal = {
      "Time": "c_time",
      "Cost": "c_cost",
      "Quality": "c_quality",
      "Scope": "c_scope",
      "H&S": "c_h_s",
      "SME": "c_sme_self_assessments",
      "Overall": "c_time"
    }
    
    let qualParam = this.props.qualityParamDefaultVal ? paramVal[this.props.qualityParamDefaultVal] : "c_time"
    //this.getSingleProjectActivities(qualParam); //uncomment after summary dashboard is ready
  }

  onSelectionChange = (e) => {
    const measureType = e.target.value;
    this.setState({ selectedValue: [] });
    this.getSingleProjectActivities(measureType);
  };

  getSingleProjectActivities = (measureType) => {
    this.setState({ selectedMeasure: measureType });
    const projectId = this.props.projectId;

    axios({
      url:
        this.state.getSingleProjectActivitiesURL +
        projectId +
        "/" +
        measureType,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;

        this.setState({
          isLoading: false,
          allActivities: data.allActivities,
          filteredActivities: data.allActivities,
        },() => {
          const defaultOptions = [
            { value: "A", label: "A", color: "#eb7b15" },
            { value: "R", label: "R", color: "#FF5630" },
            { value: "NA", label: "NA", color: "black" },
          ];
          this.onSelect(defaultOptions);
        });

      })
      .catch((error) => {
        console.log("error in getting project health score" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
        });
      });
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedValue: selectedList });
    let filteredActivities = [];
    let dataArray = [];
    if (!selectedList || selectedList.length == 0) {
      this.setState({
        filteredActivities: this.state.allActivities,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < this.state.allActivities.length; j++) {
          let data = this.state.allActivities[j];
          dataArray.push(data);
          if (data["activityValue"]) {
            if (
              data["activityValue"].toLowerCase() ==
              selectedList[i].value.toLowerCase()
            ) {
              filteredActivities.push(data);
            }
          } else {
            if (selectedList[i].value == "NA") {
              filteredActivities.push(data);
            }
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.allActivities.length
        ) {
          this.setState({ filteredActivities: filteredActivities });
        }
      }
    }
  };

  onRemove(selectedList, selectedItem) {
    this.setState({ selectedValue: selectedList });
    let filteredActivities = [];
    let dataArray = [];
    if (!selectedList || selectedList.length == 0) {
      this.setState({
        filteredActivities: this.state.allActivities,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < this.state.allActivities.length; j++) {
          let data = this.state.allActivities[j];
          dataArray.push(data);
          if (
            data["activityValue"].toLowerCase() ==
            selectedList[i].name.toLowerCase()
          ) {
            filteredActivities.push(data);
          }
        }
        if (
          dataArray.length ===
          selectedList.length * this.state.allActivities.length
        ) {
          this.setState({ filteredActivities: filteredActivities });
        }
      }
    }
  }

  render() {
    let showActivityData = false;
    let allActivities = this.state.allActivities;
    let filteredActivities = this.state.filteredActivities;

    if (allActivities != null) {
      showActivityData = true;
    }

    const measureOptions = Object.keys(this.state.allMeasures).map((v, i) => {
      if (this.state.allMeasures[v] == this.state.selectedMeasure) {
        return (
          <option key={i} value={v} selected="selected">
            {this.state.allMeasures[v]}
          </option>
        );
      } else {
        return (
          <option key={i} value={v}>
            {this.state.allMeasures[v]}
          </option>
        );
      }
    });

    return (
      <Col xs="12" lg="12" md="12">
        {showActivityData == false && <div></div>}
        {showActivityData == true && (
          <div
            className="chart-top"
            style={{
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            <Row>
              <Col>
                <div className="chart-header" style={{ paddingBottom: "50px" }}>
                  <div
                    style={{ float: "left", paddingTop: "12px" }}
                    className="chart-buttons2"
                  >
                    <span>
                      List of activities contributing to the business measure
                    </span>
                    <BootstrapTooltip
                      placement="right-start"
                      title="The table represents activities for project​"
                    >
                      <span>
                        <MdInfo
                          size={20}
                          className="c-info"
                          style={{ marginTop: "-0.5rem" }}
                        ></MdInfo>
                      </span>
                    </BootstrapTooltip>
                  </div>
                  <div
                    style={{ float: "right", paddingTop: "12px" }}
                    className="chart-buttons2"
                  >
                    <select
                      id="activity-list-dropdown"
                      onChange={this.onSelectionChange}
                      value={this.state.selectedMeasure}
                      style={{ width: "115px" }}
                    >
                      {measureOptions}
                    </select>
                  </div>
                </div>
                <center>
                  <div
                    style={{ overflowX: "auto", margin: "10px" }}
                    className="single-project-activity-weight"
                  >
                    <Table
                      className="table-scroll-modal table-modal table table-md table-custom table-single-project-activities"
                      id="table-task-list"
                      style={{
                        fontSize: "0.7rem",
                        height: "318px",
                        border: "1px solid",
                      }}
                    >
                      <thead className="table-head">
                        <tr>
                          <th className="other-col-comp">
                            <span>Activity</span>
                          </th>
                          <th
                            className="other-col-comp centre-align-data"
                            style={{ width: "12vh" }}
                          >
                            <BootstrapTooltip
                              placement="left-center"
                              title={"Current Status"}
                            >
                              <Select
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                isMulti
                                options={colourOptions}
                                styles={colourStyles}
                                value={this.state.selectedValue}
                                onChange={this.onSelect}
                                placeholder={"Current Status"}
                                isSearchable
                              />
                            </BootstrapTooltip>
                          </th>
                          {
                            //<th className="other-col-comp">
                            //<span>Measure</span>
                            //</th>
                          }

                          {
                            // <th className="other-col-comp">
                            //    <span>Weight</span>
                            //</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {filteredActivities && filteredActivities.length
                          ? filteredActivities.map((activityObject) => (
                              <tr>
                                <td className="table-data-bold">
                                  {activityObject["activityName"]}
                                </td>
                                <td className="table-data-black centre-align-data">
                                  {activityObject["activityValue"] ? (
                                    activityObject["activityValue"] != "NA" ? (
                                      <div
                                        className={
                                          activityObject["activityValue"] ===
                                          "R"
                                            ? "red-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "GR"
                                            ? "grey-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "A"
                                            ? "amber-circle"
                                            : activityObject[
                                                "activityValue"
                                              ] === "B"
                                            ? "blue-circle"
                                            : "green-circle"
                                        }
                                      ></div>
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                {
                                  //<td className="table-data-bold">
                                  //    {this.props.csvColumnMapping[activityObject["measure"]]}
                                  //</td>
                                }
                                {
                                  // <td className="table-data-bold">
                                  //{activityObject["weight"]}
                                  //</td>
                                }
                              </tr>
                            ))
                          : <center><strong>No activities feeding into {this.state.allMeasures[this.state.selectedMeasure]} up to the next milestone to be achieved.</strong></center>}
                      </tbody>
                    </Table>
                  </div>
                </center>
              </Col>
            </Row>
          </div>
        )}
      </Col>
    );
  }
}

export default SingleProjectActivities;
