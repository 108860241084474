import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Row, Col } from "reactstrap";

class HorizontalStackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minHeight: 500,
      data: {},
      options: {
        maintainAspectRatio: false,
        tooltips: {
          displayColors: true,
          callbacks: {
            mode: "x",
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              type: "category",
              ticks: {
                beginAtZero: true,
                callback: function (label, index, labels) {
                  if (label.length > 50) {
                    return label.substring(0, 50) + "....";
                  } else {
                    return label;
                  }
                },
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            color: "#ffffff",
            fontFamily: "Ubuntu-Medium",
            formatter: (value, data) => {
              if (value === 0) {
                if (
                  data.chart.data.datasets.every((item) => item[data.dataIndex] === 0)
                ) {
                  return 0;
                } else {
                  return "";
                }
              }
            },
          },
        },
      },
    };
    this.getChartData = this.getChartData.bind(this);
    this.saveCanvas = this.saveCanvas.bind(this);
  }

  getSummaryChartData() {
    let dataSetCodes = {
      'A': {
        data: [],
        backgroundColor: '#eb7b15',
        barThickness: 18,
      },
      'G': {
        data: [],
        backgroundColor: '#96e079',
        barThickness: 18,
      },
      'GR': {
        data: [],
        backgroundColor: '#696969',
        barThickness: 18,
      },
      'R': {
        data: [],
        backgroundColor: '#e45266',
        barThickness: 18,
      },
      'B': {
        data: [],
        backgroundColor: '#268cd1',
        barThickness: 18,
      },
    }
    let chartData = this.props.stackChartData;
    let labelsList = [];
    for (let item in chartData) {
      labelsList.push(item);
      for(let val in dataSetCodes){
          dataSetCodes[val].data.push(chartData[item][val] || 0)
      }
    }

    let data = {
      labels: labelsList,
      datasets: Object.values(dataSetCodes)
    };
    this.setHeight();
    return {...data};

  }
  datasetKeyProvider(){ 
    return (Math.random() + 1).toString(36).substring(7);
  }
  getChartData() {
    if (this.props?.summaryPage) {
      return this.getSummaryChartData();
    }
    let chartData = this.props.stackChartData;
    let rList = [];
    let aList = [];
    let gList = [];
    let labelsList = [];

    Object.entries(chartData).forEach((yearList) => {
      Object.entries(yearList[1]).forEach((monthList) => {
        monthList[1].forEach((params) => {
          labelsList.push(this.props.csvColumnMapping[params["activity"]]);
          aList.push(params["A"]);
          rList.push(params["R"]);
          gList.push(params["G"]);
        });
      });
    });

    let data = {
      labels: labelsList,
      datasets: [
        {
          data: rList,
          backgroundColor: "#CA0A16",
          barThickness: 18,
        },
        {
          data: aList,
          backgroundColor: "#F27400",
          barThickness: 18,
        },
        {
          data: gList,
          backgroundColor: "#96e079",
          barThickness: 18,
        },
      ],
    };
    return data;
  }

  saveCanvas() {
    html2canvas(document.getElementById("stack-chart-container"), {
      scale: 5,
    }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1.0);
      const doc = new jsPDF("portrait", "pt", [
        canvas.width + 20,
        canvas.height + 20,
      ]);
      const width = doc.internal.pageSize.getWidth();
      doc.setFontSize(width / 50);
      doc.text(
        70,
        110,
        "List of Activities For Status '" +
        this.props.qualityValue +
        "' in " +
        this.props.qualityParam
      );
      doc.addImage(img, "JPEG", 80, 200, width - 480, canvas.height - 790);
      doc.save(
        "List of Activities For Status '" +
        this.props.qualityValue +
        "' in " +
        this.props.qualityParam +
        ".pdf"
      );
    });
  }

  setHeight(){
    const dataLen = Object.keys(this.props.stackChartData).length;
    const minHeight = dataLen  * 25;
    if(dataLen !== this.state.dataLen){
      this.setState({
        minHeight: (minHeight > 350)? minHeight:350,
        dataLen,
        redraw: true,
      });
    } else {
      if(this.state.redraw){
        this.setState({
          redraw: false
        })
      }
    }
  }

  render() {
    return (
      <div>
        <div className="my-custom-scrollbar-horizontal-stack-chart">
          <Row>
            <Col>
              <center>
                {/*
                <span
                  //data-title="The bar chart represents a milestone being achieved (green and blue) merged together to give a percentage by month"
                  data-title="The chart shows a count of projects with status 'R' and 'A' for the activities that impact the selected business measure"
                  data-title-md
                >
                  <MdInfo size={20} className="c-info"></MdInfo>
                </span>
                */}
              </center>
            </Col>
          </Row>
          <div className="p-3 chart-area">
            <div className="chart" id="stack-chart-container" style={{height: this.state.minHeight }}>
              <HorizontalBar
                id="horizontalbar-chart"
                redraw={this.state.redraw}
                datasetKeyProvider={this.datasetKeyProvider}
                data={this.getChartData}
                options={this.state.options}
              />
            </div>
          </div>
        </div>
        <div className="download-chart-btn-horizontal-stack-chart">
          <button onClick={this.saveCanvas}>Download Chart</button>
        </div>
      </div>
    );
  }
}

export default HorizontalStackChart;
