import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { Row, Col } from "reactstrap";
import "chartjs-plugin-datalabels";
import "react-multi-carousel/lib/styles.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MdInfo } from "react-icons/md";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import ToggleButton from "@material-ui/lab/ToggleButton";

import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = { stateChangeVar: false, selectedToggle: false };
    this.saveCanvas = this.saveCanvas.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  saveCanvas() {
    html2canvas(document.getElementById("stack-chart-container"), {
      scale: 5,
    }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1.0);
      const doc = new jsPDF("portrait", "pt", [
        canvas.width + 20,
        canvas.height + 20,
      ]);
      const width = doc.internal.pageSize.getWidth();
      doc.setFontSize(width / 50);
      doc.text(
        100,
        140,
        "List of Activities Forcasted as '" +
          this.props.qualityValue +
          "' for TCQi indicator '" +
          this.props.qualityParam
      );
      doc.addImage(img, "JPEG", 0, 210, width - 100, canvas.height - 600);
      doc.save(
        "List of Activities Forcasted as '" +
          this.props.qualityValue +
          "' for TCQi indicator '" +
          this.props.qualityParam +
          ".pdf"
      );
    });
  }

  changeState() {
    this.setState({
      stateChangeVar: !this.state.stateChangeVar,
      selectedToggle: !this.state.selectedToggle,
    });
  }

  render() {
    let chartData = null;
    let chartHeight = this.props.chartHeight;
    let horizontalBarChartData = this.props.chartData;
    let mapping = this.props.csvColumnMapping;
    let finalChartData = null;
    let tempActivityObjectArray = [];

    if (horizontalBarChartData) {
      const chartDataTemplate = {
        data: {
          labels: ["A", "B", "C", "D"],
          datasets: [
            {
              label: "Activity Importance Value",
              backgroundColor: "#268cd1", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
            },
          ],
        },
      };

      let options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "",
          position: "left",
          fontFamily: "Ubuntu-Medium",
        },
        legend: {
          display: false,
          position: "left",
          align: "left",
          fontFamily: "Ubuntu-Medium",
        },
        type: "horizontalBar",
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "#808080",
                borderDash: [1, 1],
                lineWidth: 0.35,
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              ticks: {
                display: true,
                fontColor: "#919BA3",
                fontFamily: "Ubuntu-Medium",
              },
              //stacked: true,
              display: true,
            },
          ],
          yAxes: [
            {
              barThickness: 19,
              maxBarThickness: 19,
              minBarThickness: 18,
              gridLines: {
                display: false,
                color: "#808080",
                offsetGridLines: false,
                fontFamily: "Ubuntu-Medium",
              },
              type: "category",
              ticks: {
                beginAtZero: true,
                display: true,
                fontSize: 10,
                fontColor: "#252525",
                fontFamily: "Ubuntu-Medium",
                callback: function (label, index, labels) {
                  if (label.length > 60) {
                    return label.substring(0, 60) + "....";
                  } else {
                    return label;
                  }
                },
              },
              //stacked: true,
              display: true,
            },
          ],
        },
        layout: {
          padding: {
            left: 220,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: "#919BA3",
            //align: 'top, middle',
            align: "right",
            anchor: "end",
            fontFamily: "Ubuntu-Medium",
          },
          formatter: (label, index, labels) => {
            return parseFloat(label).toFixed(2);
          },
        },
      };

      chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
      let chartLabels = [];
      let chartValues = [];

      if (this.state.selectedToggle == true) {
        horizontalBarChartData.forEach((item) => {
          tempActivityObjectArray.push([
            mapping
              ? mapping[item["activity_id:"].replace(/-/g, "_")]
                ? mapping[item["activity_id:"].replace(/-/g, "_")]
                : item["activity_id:"].replace(/_/g, " ")
              : item["activity_id:"].replace(/_/g, " "),
            parseFloat(item["avg"]).toFixed(2),
          ]);
        });

        //sort the values in descending order
        tempActivityObjectArray = tempActivityObjectArray.sort((a, b) => {
          return b[1] - a[1];
        });

        tempActivityObjectArray.forEach((item) => {
          chartLabels.push(item[0]);
          chartValues.push(item[1]);
        });

        chartData.data.datasets[0].data = chartValues;
        chartData.data.labels = chartLabels;

        finalChartData = (
          <div>
            <HorizontalBar
              data={chartData.data}
              width={670}
              height={700}
              options={options}
            />
          </div>
        );
      } else {
        horizontalBarChartData.forEach((item) => {
          tempActivityObjectArray.push([
            mapping
              ? mapping[item["activity_id:"].replace(/-/g, "_")]
                ? mapping[item["activity_id:"].replace(/-/g, "_")]
                : item["activity_id:"].replace(/_/g, " ")
              : item["activity_id:"].replace(/_/g, " "),
            parseFloat(item["avg"]).toFixed(2),
          ]);
        });

        tempActivityObjectArray.forEach((item) => {
          chartLabels.push(item[0]);
          chartValues.push(item[1]);
        });

        chartData.data.datasets[0].data = chartValues;
        chartData.data.labels = chartLabels;

        finalChartData = (
          <div>
            <HorizontalBar
              data={chartData.data}
              width={670}
              height={700}
              options={options}
            />
          </div>
        );
      }
    }

    return (
      <div>
        <div className="my-custom-scrollbar-horizontal-stack-chart">
          <div className="p-1 chart-area-current-month-forecast-modal">
            <Row>
              <Col style={{ fontFamily: "Ubuntu-Medium" }}>
                <center>
                  <span style={{ float: "left" }}>
                    <BootstrapTooltip
                      title={
                        this.state.selectedToggle
                          ? "Click here to display activities in order of execution"
                          : "Click here to display activities in order of importance"
                      }
                      placement="right"
                    >
                      <ToggleButton
                        value="check"
                        selected={this.state.selectedToggle}
                        onChange={() => {
                          this.changeState();
                        }}
                        size="small"
                        style={{ backgroundColor: "#268cd1" }}
                      >
                        {this.state.selectedToggle ? (
                          <BarChartIcon
                            style={{ color: "#FFFFFF" }}
                            fontSize="small"
                          />
                        ) : (
                          <span
                            style={{
                              transform: "rotate(-90deg)",
                              fontSize: "0.2rem",
                            }}
                          >
                            <SortRoundedIcon
                              style={{ color: "#FFFFFF" }}
                              fontSize="small"
                            />
                          </span>
                        )}
                      </ToggleButton>
                    </BootstrapTooltip>
                  </span>
                  <span style={{ marginTop: "-0.8rem" }}>
                    Activity Importance
                  </span>
                  <span
                    data-title="The chart represents activities that impact the selected business measure with their coefficient. The higher the coefficient the higher importance of the activity on the measure​"
                    data-title-lg
                  >
                    <MdInfo size={20} className="c-info"></MdInfo>
                  </span>
                </center>
              </Col>
            </Row>
            <div className="chart" id="stack-chart-container">
              {finalChartData && (
                <React.Fragment>{finalChartData}</React.Fragment>
              )}
              {finalChartData == null && <PageSpinner></PageSpinner>}
            </div>
            <div className="rg-div">
              <div className="b-div"></div>
              Importance
            </div>
          </div>
        </div>
        <div className="download-chart-btn-horizontal-stack-chart">
          <button onClick={this.saveCanvas}>Download Chart</button>
        </div>
      </div>
    );
  }
}

export default HorizontalBarChart;
