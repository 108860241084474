import * as d3 from 'd3';
import activityList from './activities';
import lineList from './lines';
import { line, interchange, triangle, square } from './curve';


export default function (tubeMapData) {
    let margin = {
        top: 80,
        right: 80,
        bottom: 20,
        left: 80
    };
    let width = 760;
    let height = 640;
    let xScale = d3.scaleLinear();
    let yScale = d3.scaleLinear();
    let lineWidth;
    let lineWidthMultiplier = 0.8;
    let lineWidthTickRatio = 3 / 2;
    let svg;
    let _data;
    let gMap;

    let fgColor = '#22A2DF';
    let bgColor = '#FFFFFF';
    let activitiesBorderColor = {
        'B': 'blue',
        'R': 'red',
        'A': '#FFBF00',
        'G': '#0dc70d',
        'GR': 'grey'
    }

    let activitiesColor = {
        'B': '#4040ff',
        'R': '#f74d2e',
        'A': '#ffd763',
        'G': '#67db67',
        'GR': '#a09f9f'
    }
    let flagColor = '#FFFFFF'

    const forecastFillColor = '#b2e0ff'
    const forecastStrokeColor = '#5aace4'
    const predictedFlagColor = '#4492c5'
    const predictedFlagBorderColor ='#1e7cbb'

    function map(selection) {
        selection.each(function (data) {
            _data = transformData(data);

            let minX =
                d3.min(_data.raw, function (line) {
                    return d3.min(line.nodes, function (node) {
                        return node.coords[0];
                    });
                }) - 1;

            let maxX =
                d3.max(_data.raw, function (line) {
                    return d3.max(line.nodes, function (node) {
                        return node.coords[0];
                    });
                }) + 1;

            let minY =
                d3.min(_data.raw, function (line) {
                    return d3.min(line.nodes, function (node) {
                        return node.coords[1];
                    });
                }) - 1;

            let maxY =
                d3.max(_data.raw, function (line) {
                    return d3.max(line.nodes, function (node) {
                        return node.coords[1];
                    });
                }) + 1;

            let desiredAspectRatio = (maxX - minX) / (maxY - minY);
            let actualAspectRatio =
                (width - margin.left - margin.right) /
                (height - margin.top - margin.bottom);

            let ratioRatio = actualAspectRatio / desiredAspectRatio;
            let maxXRange;
            let maxYRange;

            if (desiredAspectRatio > actualAspectRatio) {
                maxXRange = width - margin.left - margin.right;
                maxYRange = (height - margin.top - margin.bottom) * ratioRatio;
            } else {
                maxXRange = (width - margin.left - margin.right) / ratioRatio;
                maxYRange = height - margin.top - margin.bottom;
            }

            xScale.domain([minX, maxX]).range([margin.left, margin.left + maxXRange]);
            yScale.domain([minY, maxY]).range([margin.top + maxYRange, margin.top]);

            let unitLength = Math.abs(
                xScale(1) - xScale(0) !== 0 ?
                    xScale(1) - xScale(0) :
                    yScale(1) - yScale(0)
            );

            lineWidth = lineWidthMultiplier * unitLength;

            svg = selection
                .append('svg')
                .style('width', '100%')
                .style('height', '100%');

            gMap = svg.append('g');

            let activities = _data.activities.interchanges();
            // get all mapDividers
            let mapDividers = activities.filter((item) => { return item.type === 'mapDivider' })
            const startId = tubeMapData.allProjectsData ? 'all' : 'single';
            const isMilestoneVisible = tubeMapData.isMilestoneVisible
            const isActivitiesVisible = tubeMapData.isActivitiesVisible

            //drawStartEndPoint();
            drawLines(isActivitiesVisible, isMilestoneVisible);
            if (isActivitiesVisible) {
                drawInterchanges(startId, tubeMapData.singleProjectData, tubeMapData.isPredictionData, tubeMapData.isCurrentData, tubeMapData.activityList, tubeMapData.milestoneDbColName);
            }
            drawLabels(startId, isActivitiesVisible, isMilestoneVisible);
            drawMapDividerLines(startId, mapDividers);
            //drawLegends();
            drawTubeMapInfo();
            /*if (tubeMapData.allProjectsData) {
                drawBRAGInfo();
            }*/

            if (tubeMapData.singleProjectData) {
                drawActivityCircles(tubeMapData.singleProjectData, isActivitiesVisible, isMilestoneVisible);
            }
            if (isMilestoneVisible) {
                drawMileStones(startId, tubeMapData.singleProjectData, tubeMapData.isPredictionData, tubeMapData.isCurrentData, tubeMapData.activityList, tubeMapData.milestoneDbColName);
            }
            if (tubeMapData.allProjectsData) {
                drawHoverPopup(tubeMapData.allProjectsData, tubeMapData.isPredictionData, tubeMapData.isCurrentData, tubeMapData.activityList, tubeMapData.milestoneDbColName);
            }
            // if lastcrossed mileston is not null/empty then draw forecast line
            if (tubeMapData.singleProjectData && tubeMapData.singleProjectData.last_crossed_milestone) {
                let lastCrossedMilestone = tubeMapData.singleProjectData.last_crossed_milestone
                let predictedMilestone = tubeMapData.singleProjectData.predicted_milestone
                let forecastedMilestoneData = activities.filter((item) => { return item.name === lastCrossedMilestone })
                drawForecastLine(startId, forecastedMilestoneData, predictedMilestone);

                let tgColData = activities.filter((item) => { return item.dbColName === 'c_complexity_assessment_rating' })
                drawComplexityRatingTGValue(startId, tgColData, tubeMapData.singleProjectData['c_complexity_assessment_rating'])
            }
            // if current_milestone_id is not null (used on single project currert performance map)
            if (tubeMapData.singleProjectData && tubeMapData.singleProjectData.current_milestone_id) {
                let currentMilestoneId = tubeMapData.singleProjectData.current_milestone_id
                let currentMilestoneData = activities.filter((item) => { return item.name === currentMilestoneId })
                //let currentMilestoneData = activities.filter((item) => { return item.name.includes('Milestone') }) //to show all milestones
                drawCurrentMilestone(startId, currentMilestoneData);
               
                const milestonesCols = [
                    //'c_create_the_project_batch',
                    'c_programme_approval_committee_1_decision',
                    'c_current_forecasted_pac_2_date_vs_baseline_pac_2_date',
                    'c_current_contract_award_date_vs_baseline_contract_award_date',
                    'c_forecasted_start_on_site_date_vs_baseline_start_on_site_date',
                    'c_forecasted_piu_vs_baseline_piu_date',
                    'c_forecasted_contract_completion_date_vs_baseline_contract_comp',
                    'c_progamme_approval_committee_3_decision',
                    'c_forecasted_final_acceptance_date_vs_baseline_final_acceptance',
                    //'c_gateway_4_decision'
                ]
                let milestoneNames = activities.filter((item) => { return milestonesCols.includes(item.dbColName) })
                drawDatePopup(startId, milestoneNames, tubeMapData.singleProjectData)
                //drawLivesPopup(tubeMapData.singleProjectData)
                let tgColData = activities.filter((item) => { return item.dbColName === 'c_complexity_assessment_rating' })
                drawComplexityRatingTGValue(startId, tgColData, tubeMapData.singleProjectData['c_complexity_assessment_rating'])
                drawHoverPopupSingleProject(tubeMapData.singleProjectData);
            }
            // show in-date milestone label
            if(tubeMapData.showInDateMilestone && tubeMapData.milestoneDbColName){
                let currentMilestoneData = activities.filter((item) => { return item.dbColName === tubeMapData.milestoneDbColName })
                drawInDateMilestone(startId, currentMilestoneData);
            }
        });
    }

    map.width = function (w) {
        if (!arguments.length) return width;
        width = w;
        return map;
    };

    map.height = function (h) {
        if (!arguments.length) return height;
        height = h;
        return map;
    };

    map.margin = function (m) {
        if (!arguments.length) return margin;
        margin = m;
        return map;
    };

    /*function drawStartEndPoint() {
        gMap.append('rect')
            .attr('x', 91.9)
            .attr('y', 153.56)
            .attr('height', 3)
            .attr('width', 1)
            .attr('fill', '#0098d9')
            .style('stroke', '#0098d9')
            .style('stroke-width', 0.2)

        gMap.append('rect')
            .attr('x', 679)
            .attr('y', 182)
            .attr('height', 3)
            .attr('width', 1)
            .attr('fill', '#0098d9')
            .style('stroke', '#0098d9')
            .style('stroke-width', 0.2)
    }*/

    function drawLines(isActivitiesVisible, isMilestoneVisible) {
        gMap
            .append('g')
            .attr('class', 'lines')
            .selectAll('path')
            .data(_data.lines.lines.filter((item) => {
                if (isActivitiesVisible && !isMilestoneVisible) {
                    return item.name === 'allDataLine'
                } else if (!isActivitiesVisible && isMilestoneVisible) {
                    return item.name === 'milestoneDataLine'
                } else if (isActivitiesVisible && isMilestoneVisible) {
                    return item
                } else {
                    return
                }

            }))
            .enter()
            .append('path')
            .attr('d', function (d) {
                return line(d, xScale, yScale, lineWidth, lineWidthTickRatio);
            })
            .attr('id', function (d) {
                return d.name;
            })
            .attr('stroke', function (d) {
                return d.color;
            })
            .attr('fill', 'none')
            .attr('stroke-width', function (d) {
                return d.highlighted ? lineWidth * 1.3 : lineWidth;
            })
            .classed('line', true);
    }

    function drawInterchanges(startId, singleProjectData, isPredictionData, isCurrentData, activityList, milestoneDbColName) {

        let interchanges = gMap
            .append('g')
            .attr('class', 'interchanges')
            .selectAll('path')
            //.data(_data.activities.interchanges())
            .data(_data.activities.interchanges().filter((item) => {
                return item.type !== 'mapDivider' && item.type !== 'milestone' && item.label !== ''
            }))
            .enter()
            .append('g')
            .attr('id', function (d) {
                return startId + d.name;
            })
            .append('path')
            .attr('d', interchange(lineWidth))
            .attr('transform', function (d) {
                return (
                    'translate(' +
                    xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier) +
                    ',' +
                    yScale(d.y + d.marker[0].shiftY * lineWidthMultiplier) +
                    ')'
                );
            })
            .attr('stroke-width', lineWidth / 2.5)
            .attr('fill', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesColor[val]
                    if (color) {
                        return color;
                    } else {
                        return bgColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagColor;
                    }
                    else {
                        return forecastFillColor;
                    }
                }
                return bgColor;
            })
            //.attr('stroke-opacity', '0.5')
            .attr('stroke', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesBorderColor[val]
                    if (color) {
                        return color;
                    } else {
                        if (d.color) return d.color;
                        return fgColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagBorderColor;
                    }
                    else {
                        return forecastStrokeColor;
                    }
                }
                if (d.color) return d.color;
                return fgColor;
            })
            .classed('interchange', true)
            .style('cursor', function (d) {
                if (singleProjectData) {
                    if ( isCurrentData && (d.dbColName =='c_has_ato_been_signed_off' || d.dbColName =='c_is_the_output_being_delivered_on_a_temp_solution' || d.dbColName == 'c_environmental_exceedance_report_lives_lost_per_site_report_1')) {
                        return 'pointer';
                    } else {
                        return '';
                    }
                    //return '';
                }
                else {
                    if (isPredictionData) {
                        if (activityList !=null && activityList.includes(d.dbColName)) {
                            return 'pointer';
                        } else {
                            return '';
                        }
                    } else {
                        return 'pointer';
                    }
                }
            })
        if (!singleProjectData) {
            interchanges
                .on('mouseover', function (d) {
                    d3.select('#popup-' + d.name).style('display', 'block');
                })
                .on('click', function (d) {
                    d3.select('#popup-' + d.name).style('display', 'block');
                })
                .on('mouseout', function (d) {
                    d3.select('#popup-' + d.name).style('display', 'none');
                });
        }
        if(singleProjectData) {
            interchanges.on('mouseover', function (d) {
                if (d.dbColName =='c_has_ato_been_signed_off' || d.dbColName =='c_is_the_output_being_delivered_on_a_temp_solution') {
                    // for single project current map show popup with date values
                    d3.select('#single-act-popup-' + d.dbColName).style('display', 'block');
                }
                /*else if (d.dbColName =='c_environmental_exceedance_report_lives_lost_per_site_report') {
                    // for single project current map show popup for lives
                    d3.select('#lives-popup').style('display', 'block');
                }*/
            })
            .on('click', function (d) {
                if (d.dbColName =='c_has_ato_been_signed_off' || d.dbColName =='c_is_the_output_being_delivered_on_a_temp_solution') {
                    // for single project current map show popup with date values
                    d3.select('#single-act-popup-' + d.dbColName).style('display', 'block');
                }
                /*else if (d.dbColName =='c_environmental_exceedance_report_lives_lost_per_site_report') {
                    // for single project current map show popup for lives
                    d3.select('#lives-popup').style('display', 'block');
                }*/
            })
            .on('mouseout', function (d) {
                if (d.dbColName =='c_has_ato_been_signed_off' || d.dbColName =='c_is_the_output_being_delivered_on_a_temp_solution') {
                    // for single project current map show popup with date values
                    d3.select('#single-act-popup-' + d.dbColName).style('display', 'none');
                }
                /*else if (d.dbColName =='c_environmental_exceedance_report_lives_lost_per_site_report') {
                    // for single project current map show popup for lives
                    d3.select('#lives-popup').style('display', 'none');
                }*/
            });
        }
    }

    function drawMileStones(startId, singleProjectData, isPredictionData, isCurrentData, activityList, milestoneDbColName) {

        gMap
            .append('g')
            .attr('class', 'interchanges')
            .selectAll('path')
            .data(_data.activities.toArray().filter((item) => {
                return item.type === 'milestone' && item.name !== 'Milestone-0' && item.name !== 'DupMilestone-0'
            }))// show symbol for milestones.
            .enter()
            .append('g')
            .attr('id', function (d) {
                return d.name + '-mTriangle';
            })
            .append('path')
            .attr('d', triangle(5))
            .attr('transform', function (d) {
                return (
                    'translate(' +
                    xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier) +
                    ',' +
                    yScale(d.y + 0.31 + d.marker[0].shiftY * lineWidthMultiplier) +
                    ')'
                );
            })
            .style('cursor', function (d) {
                if (singleProjectData) {
                    if (isCurrentData && d.dbColName !== 'c_create_the_project_batch' && d.dbColName !== 'c_gateway_4_decision') {
                        return 'pointer';
                    }
                    return '';
                }
                else {
                    return 'pointer';
                }
            })
            .style('fill', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesColor[val]
                    if (color) {
                        return color;
                    } else {
                        return flagColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagColor;
                    }
                    else {
                        return forecastFillColor;
                    }
                }
                return flagColor;
            })
            .style('stroke', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesBorderColor[val]
                    if (color) {
                        return color;
                    } else {
                        return fgColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagBorderColor;
                    }
                    else {
                        return forecastStrokeColor;
                    }
                }
                return fgColor;
            })
            .style('stroke-width', 0.2)
            .style('font-size', '1px')
            .on('mouseover', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'block');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'block');
                }                
            })
            .on('click', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'block');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'block');
                } 
            })
            .on('mouseout', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'none');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'none');
                } 

            });

        gMap
            .append('g')
            .attr('class', 'interchanges')
            .selectAll('path')
            .data(_data.activities.toArray().filter((item) => {
                return item.type === 'milestone' && item.name !== 'Milestone-0' && item.name !== 'DupMilestone-0'
            }))// show symbol for milestones
            .enter()
            .append('g')
            .attr('id', function (d) {
                return startId + d.name;
            })
            .append('path')
            .attr('d', "M7.4 3L7 2H2.5v8h1v-3h2.8l.2 1h3V3z")
            .attr('transform', function (d) {
                return (
                    'translate(' +
                    xScale(d.x - 2.15 + d.marker[0].shiftX * lineWidthMultiplier) +
                    ',' +
                    yScale(d.y + 7.8 + d.marker[0].shiftY * lineWidthMultiplier) +
                    ')'
                );
            })
            .style('cursor', function (d) {
                if (singleProjectData) {
                    if (isCurrentData && d.dbColName !== 'c_create_the_project_batch' && d.dbColName !== 'c_gateway_4_decision') {
                        return 'pointer';
                    }
                    return '';
                }
                if (isPredictionData) {
                    if (activityList !=null && activityList.includes(d.dbColName)) {
                        return 'pointer';
                    } else {
                        return '';
                    }
                }
                else {
                    return 'pointer';
                }
            })
            .style('fill', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesColor[val]
                    if (color) {
                        return color;
                    } else {
                        return flagColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagColor;
                    }
                    else {
                        return forecastFillColor;
                    }
                }
                return flagColor;
            })
            .style('stroke', function (d) {
                if (singleProjectData) {
                    let val = singleProjectData[d.dbColName]
                    let color = activitiesBorderColor[val]
                    if (color) {
                        return color;
                    } else {
                        return fgColor;
                    }
                }
                if (isPredictionData && activityList !=null && activityList.includes(d.dbColName)) {
                    if (d.dbColName == milestoneDbColName) {
                        return predictedFlagBorderColor;
                    }
                    else {
                        return forecastStrokeColor;
                    }
                }
                return fgColor;
            })
            .style('stroke-width', 0.2)
            .style('font-size', '1px')
            .on('mouseover', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'block');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'block');
                } 
            })
            .on('click', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'block');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'block');
                }
            })
            .on('mouseout', function (d) {
                if(singleProjectData && isCurrentData){
                    // for single project current map show popup with date values
                    d3.select('#date-popup-' + d.dbColName).style('display', 'none');
                } else{
                    d3.select('#popup-' + d.name).style('display', 'none');
                }
            });
    }

    function drawLabels(startId, isActivitiesVisible, isMilestoneVisible) {
        gMap
            .append('g')
            .attr('class', 'labels')
            .selectAll('text')
            .data(_data.activities.toArray().filter((item) => {
                if (isActivitiesVisible && !isMilestoneVisible) {
                    return item.type !== 'milestone'
                } else if (isMilestoneVisible && !isActivitiesVisible) {
                    return item.type === 'milestone' || item.name === 'Activity1' || item.type === 'mapDivider' //don't hide start label and map divider
                } else if (isMilestoneVisible && isActivitiesVisible) {
                    return item
                } else {
                    return
                }
            }))
            .enter()
            .append('g')
            .attr('id', function (d) {
                return startId + d.name;
            })
            .classed('label', true)
            .append('text')
            .html(function (d) {
                if (d.type === 'mapDivider') return ''; //don't show label for mapDivider
                return d.label.replace(/_/g, '&nbsp');
            })
            .attr('fill', '#10137E')
            .attr('dy', 0)
            .attr('x', function (d) {
                return xScale(d.x + d.labelShiftX) + textPos(d).pos[0];
            })
            .attr('y', function (d) {
                return yScale(d.y + d.labelShiftY) - textPos(d).pos[1];
            })
            .attr('text-anchor', function (d) {
                return textPos(d).textAnchor;
            })
            .style('display', function (d) {
                return d.hide !== true ? 'block' : 'none';
            })
            .style('text-decoration', function (d) {
                return d.closed ? 'line-through' : 'none';
            })
            .style('font-size', 1.96 * lineWidth + 'px')
            .style('font-weight', function (d) {
                return d.bold ? 'bold' : 'normal';
            })
            .style('-webkit-user-select', 'none')
            .attr('class', function (d) {
                return d.marker
                    .map(function (marker) {
                        return marker.line;
                    })
                    .join(' ');
            })
            .call(wrap, function (d) {
                return textPos(d).alignmentBaseline;
            });
    }

    function drawHoverPopup(allProjectsData, isPredictionData, isCurrentData, activityList, milestoneDbColName) {
        // the activity/milestone labels are separted by \n to show it on new line.
        // split label by \n (data.label.split(/\n/).length) to get the length and render next text after last part of labe.
        // draw popup to be shown on hover 
        const dd = gMap
            .append('g')
            .selectAll('text')
            .data(_data.activities.toArray().filter((item) => {
                if (isPredictionData) {
                    // for prediction data show popup only for activities for selected milestone
                    if (activityList !=null && activityList.includes(item.dbColName)) {
                        return item;
                    } else {
                        return;
                    }
                }
                else {
                    return item;
                }
                //return item
            }))
            .enter()
            .append('g')
            .attr('id', function (d) {
                return 'popup-' + d.name;
            })
            .style('display', 'none')

        dd.append('path')
            .attr('d', triangle(9.2))
            .attr('transform', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                let y = d.type === 'milestone' ? 5 : 0
                return (
                    'translate(' +
                    xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 4.3 + x) +
                    ',' +
                    yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier - 2 + y) +
                    ')  rotate(-90)'
                );
            })
            .attr('fill', '#F1F4F9')
            .style('stroke', '#22A2DF')
            .style('stroke-width', 0.2)
            .style('display', 'block')
            .attr('fill-opacity', '1')

        dd.append('rect')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 5 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                return yScale(d.y + d.marker[0].shiftY * lineWidthMultiplier + 10 + y)
            })
            .attr('height', function (d) {
                let numLines = d.label.split(/\n/).length
                if ((isPredictionData && d.dbColName == milestoneDbColName && !isCurrentData)||(d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col')) {
                    // do not show B and GR values for selected milestone and if colname is c_rv2_sacred_rating_constructability_review_1_col. So height will be reduced
                    // height of each line is 4. So reduce 8.
                    return 28 - 8 + numLines * 4;
                } else if (d.dbColName == 'c_complexity_assessment_rating') {
                    // Show TG1 to TG4
                    // height of each line is 4. So reduce 4.
                    return 28 - 4 + numLines * 4;
                } else {
                    return 28 + numLines * 4;
                }
            })
            .attr('width', function (d) {
                let allLabels = d.label.split(/\n/)
                let numLines = d.label.split(/\n/).length
                let maxWidth = 0
                for (let i = 0; i < numLines; i++) {
                    if (allLabels[i].length > maxWidth) {
                        maxWidth = allLabels[i].length
                    }
                }
                return maxWidth + 20;
            })
            .attr('fill', '#F1F4F9')
            .style('stroke', '#22A2DF')
            .style('stroke-width', 0.2)
            .style('display', 'block')
            .attr('fill-opacity', '1')
            .attr('rx', 1)
            .attr('id', function (d) {
                return 'rect-' + d.name;
            })

        dd.append('path') // This squire to hide one line of trinagle
            .attr('d', square(14))
            .attr('transform', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                let y = d.type === 'milestone' ? 5 : 0
                return (
                    'translate(' +
                    xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 6.3 + x) +
                    ',' +
                    yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier - 2 + y) +
                    ')'
                );
            })
            .attr('fill', '#F1F4F9')
            .style('stroke', '#F1F4F9')
            .style('stroke-width', 0.2)
            .style('display', 'block')
            .attr('fill-opacity', '1')


        dd.append('text')
            .html(function (d) {
                return d.label;
            })
            .attr('fill', '#10137E')
            .attr('dy', 0)
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y)
            })
            .style('fill', 'black')
            .style('font-size', 3 * lineWidth + 'px')
            .attr('font-weight', function (d) {
                if (d.type === 'milestone') {
                    return 'bold'
                } else {
                    return 'normal'
                }
            })
            .call(wrap, function (d) {
                return 'middle';
            });

        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (d.dbColName == 'c_complexity_assessment_rating') {
                    let tg1 = 0
                    if (val) {
                        tg1 = (val['TG1'] ? parseInt(val['TG1']) : 0) + (val['TG 1'] ? parseInt(val['TG 1']) : 0)
                    }
                    return ' TG1: ' + tg1

                } else {
                    let textR= ' R: '
                    if (d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col') {
                        textR = ' High: '
                    }
                    if (val) {
                        if (val['R']) {
                            return textR + val['R']
                        }
                        else {
                            return textR+'0'
                        }
                    }
                    return textR+'0'
                }
            })
            .attr('id', function (d) {
                return 'red-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + 6 - 5;
            })
            .style('display', 'block')
            .style('fill', function (d) {
                if (d.dbColName == 'c_complexity_assessment_rating') { return 'black' }
                else { return 'red' }
            })
            .style('font-size', 3 * lineWidth + 'px')

        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (d.dbColName == 'c_complexity_assessment_rating') {
                    let tg2 = 0
                    if (val) {
                        tg2 = (val['TG2'] ? parseInt(val['TG2']) : 0) + (val['TG 2'] ? parseInt(val['TG 2']) : 0)
                    }
                    return ' TG2: ' + tg2

                } else {
                    let textA= ' A: '
                    if (d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col') {
                        textA = ' Medium: '
                    }
                    if (val) {
                        if (val['A']) {
                            return textA + val['A']
                        }
                        else {
                            return textA +'0'
                        }
                    }
                    return textA +'0'
                }
            })
            .attr('id', function (d) {
                return 'amber-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + 10 - 5;
            })
            .style('display', 'block')
            .style('fill', function (d) {
                if (d.dbColName == 'c_complexity_assessment_rating') { return 'black' }
                else { return '#FFBF00' }
            })
            .style('font-size', 3 * lineWidth + 'px')

        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (d.dbColName == 'c_complexity_assessment_rating') {
                    let tg3 = 0
                    if (val) {
                        tg3 = (val['TG3'] ? parseInt(val['TG3']) : 0) + (val['TG 3'] ? parseInt(val['TG 3']) : 0)
                    }
                    return ' TG3: ' + tg3
                } else {
                    let textG = ' G: '
                    if (d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col') {
                        textG = ' Low: '
                    }
                    if (val) {
                        if (val['G']) {
                            return textG + val['G']
                        }
                        else {
                            return textG+'0'
                        }
                    }
                    return textG+'0'
                }
            })
            .attr('id', function (d) {
                return 'green-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + 14 - 5;
            })
            .style('display', 'block')
            .style('fill', function (d) {
                if (d.dbColName == 'c_complexity_assessment_rating') { return 'black' }
                else { return '#0dc70d' }
            })
            .style('font-size', 3 * lineWidth + 'px')

        //if (milestoneDbColName == null) {  
        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (d.dbColName == 'c_complexity_assessment_rating') {
                    let tg4 = 0
                    if (val) {
                        tg4 = (val['TG4'] ? parseInt(val['TG4']) : 0) + (val['TG 4'] ? parseInt(val['TG 4']) : 0)
                    }
                    return ' TG4: ' + tg4
                } else {
                    if (val) {
                        if (val['B']) {
                            return ' B: ' + val['B']
                        }
                        else {
                            return ' B: 0'
                        }
                    }
                    return ' B: 0'
                }
            })
            .attr('id', function (d) {
                return 'blue-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + 18 - 5;
            })
            .style('display', function (d) {
                if ((isPredictionData && d.dbColName == milestoneDbColName && !isCurrentData)||(d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col')) {
                    // do not show B and GR for predicted milestone and for c_rv2_sacred_rating_constructability_review_1_col as predicted milestone/c_rv2_sacred_rating_constructability_review_1_col will have only R, A and G 
                    return 'none'
                }
                return 'block'
            })
            .style('fill', function (d) {
                if (d.dbColName == 'c_complexity_assessment_rating') { return 'black' }
                else { return 'blue' }
            })
            .style('font-size', 3 * lineWidth + 'px')


        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (val) {
                    if (val['GR']) {
                        return 'GR: ' + val['GR']
                    }
                    else {
                        return 'GR: 0'
                    }
                }
                return 'GR: 0'
            })
            .attr('id', function (d) {
                return 'grey-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length 
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + 22 - 5;
            })
            .style('display', function (d) {
                if  ((isPredictionData && d.dbColName == milestoneDbColName && !isCurrentData)||(d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col')) {
                    // do not show B and GR for predicted milestone and for c_rv2_sacred_rating_constructability_review_1_col as predicted milestone/c_rv2_sacred_rating_constructability_review_1_col will have only R, A and G 
                    return 'none'
                }
                else if (d.dbColName == 'c_complexity_assessment_rating') { return 'none' }
                return 'block'
            })
            .style('fill', 'grey')
            .style('font-size', 3 * lineWidth + 'px')
        //}
        let naYPosition = 26
        //if (isPredictionData) {
        // If predicted data is true then B and GR will not be visible therefore NA data will be rendered little up.
        //   naYPosition = naYPosition - 8
        //}
        let bragList = ['B', 'R', 'A', 'G', 'GR']
        dd.append('text')
            .html(function (d) {
                let val = allProjectsData[d.dbColName]
                if (val) {
                    // val json object will have B, R, A, G, GR and other values.
                    // Example {'A', 220, 'B': 40, '', 45, 'any value': '450'}
                    // Here NA will show sum of all values other than B,R,A,G,GR
                    let allKeys = Object.keys(val)
                    let naCount = 0
                    if (d.dbColName == 'c_complexity_assessment_rating') {
                        let tgList = ['TG1', 'TG 1', 'TG2', 'TG 2', 'TG3', 'TG 3', 'TG4', 'TG 4']
                        allKeys.forEach(key => {
                            if (!tgList.includes(key)) {
                                naCount += parseInt(val[key])
                            }
                        });
                    } else if (d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col') {
                        let ragList = ['R', 'A', 'G', 'GR']
                        allKeys.forEach(key => {
                            if (!ragList.includes(key)) {
                                naCount += parseInt(val[key])
                            }
                        });
                    }
                    else {
                        allKeys.forEach(key => {
                            if (!bragList.includes(key)) {
                                naCount += parseInt(val[key])
                            }
                        });
                    }
                    return 'NA: ' + naCount
                }
                return 'NA: 0'
            })
            .attr('id', function (d) {
                return 'na-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('x', function (d) {
                let x = d.type === 'milestone' ? 3 : 0
                return xScale(d.x + d.marker[0].shiftX * lineWidthMultiplier + 7 + x)
            })
            .attr('y', function (d) {
                if ((isPredictionData && d.dbColName == milestoneDbColName && !isCurrentData)) {
                    // do not show B and GR for predicted milestone as predicted milestone will have only R, A and G 
                    naYPosition = naYPosition - 8
                } else if (d.dbColName == 'c_rv2_sacred_rating_constructability_review_1_col'){
                     // do not show B and GR for c_rv2_sacred_rating_constructability_review_1_col column
                     naYPosition = naYPosition - 8
                }
                else if (d.dbColName == 'c_complexity_assessment_rating'){
                    // do not show B and GR for c_complexity_assessment_rating
                    naYPosition = naYPosition - 4
                } else{
                    naYPosition = 26
                }
                let y = d.type === 'milestone' ? 5 : 0
                let numLines = d.label.split(/\n/).length
                return yScale(d.y + d.marker[0].shiftX * lineWidthMultiplier + 5 + y) + numLines * 4 + naYPosition - 5;
            })
            .style('display', 'block')
            .style('fill', '#8c60dc')
            .style('font-size', 3 * lineWidth + 'px')

    }

    function drawHoverPopupSingleProject(singleProjectData) {
        let hoverActivities = ['c_has_ato_been_signed_off', 'c_is_the_output_being_delivered_on_a_temp_solution']
        let popupLabels = { 'c_has_ato_been_signed_off': ['Permanent solution', 'delivered:'], 'c_is_the_output_being_delivered_on_a_temp_solution': ['Permanent solution', 'delivery date:'] }
        let valueXPositions = { 'c_has_ato_been_signed_off': -6, 'c_is_the_output_being_delivered_on_a_temp_solution': -2 }
        let rectagleWidths = { 'c_has_ato_been_signed_off': 28, 'c_is_the_output_being_delivered_on_a_temp_solution': 33 }

        const popUpPosition = {
            'c_has_ato_been_signed_off': { 'x': 564, 'y': -389 },
            'c_is_the_output_being_delivered_on_a_temp_solution': { 'x': 545, 'y': -380 },
        }

        let activityValue, label1, label2, valueXPosition, rectagleWidth
        for (let cnt = 0; cnt < hoverActivities.length; cnt++) {
            activityValue = singleProjectData[hoverActivities[cnt]]
            label1 = popupLabels[hoverActivities[cnt]][0]
            label2 = popupLabels[hoverActivities[cnt]][1]
            valueXPosition = valueXPositions[hoverActivities[cnt]]
            rectagleWidth = rectagleWidths[hoverActivities[cnt]]

            if (hoverActivities[cnt] == 'c_has_ato_been_signed_off') {
                if (activityValue == 'B') {
                    activityValue = 'Yes'
                }
                else if (activityValue == 'R' || activityValue == 'A'|| activityValue == '') {
                    activityValue = 'No'
                }
                else if (activityValue == 'G') {
                    activityValue = 'In progress'
                }
                else if (activityValue == 'GR') {
                    activityValue = 'NA'
                } else {
                    activityValue = 'NA'
                }
            } else if (hoverActivities[cnt] == 'c_is_the_output_being_delivered_on_a_temp_solution') {
                activityValue = singleProjectData['c_permanent_solution_date']

                if (isNaN(activityValue) || activityValue == '' || activityValue == null) {
                    if (singleProjectData['c_is_the_output_being_delivered_on_a_temp_solution'] == 'GR') {
                        activityValue = 'Not required'
                    } else {
                        activityValue = 'Not provided'
                    }
                }
                else {
                    activityValue = convertExcelDateToDDMMYY(activityValue)
                }
            }

            let svg = gMap
                .append('svg')
                .append('g')

                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        popUpPosition[hoverActivities[cnt]].x +
                        ',' +
                        popUpPosition[hoverActivities[cnt]].y +
                        ')'
                    );
                })
                .attr('id', function (d) {
                    return 'single-act-popup-' + hoverActivities[cnt];
                })
                .style('display', 'none')
            // append line
            // append line
            svg.append('g')
                .append('rect')
                .attr('x', -20)
                .attr('y', -75)
                .attr('width', rectagleWidth)
                .attr('height', 12)
                .attr('fill', "#FFF8EB")
                .style('stroke', '#98670E')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return label1;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', -71)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            /*svg.append('g')
                .append('text')
                .html(function (d) {
                    return baseLineDate;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -1)
                .attr('y', -71)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')*/

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return label2;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return activityValue;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', valueXPosition)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        }
    }

    /*function drawMilestonePopup(startId) {
        // draw popup to be shown on hover 
        const dd = gMap
            .append('g')
            .selectAll('text')
            .data(_data.activities.toArray().filter((item) => { return item.type === 'milestone' }))
            .enter()
            .append('g')

        dd.append('rect')
            .attr('x', function (d) {
                return xScale(d.x + 5 + d.labelShiftX) + textPos(d).pos[0];
            })
            .attr('y', function (d) {
                return yScale(d.y + 10 + d.labelShiftY) - textPos(d).pos[1];
            })
            .attr('height', 8)
            .attr('width', 26)
            .attr('fill', '#fff')
            .style('stroke', '#2196F3')
            .style('stroke-width', 0.2)
            .style('display', 'none')
            .attr('fill-opacity', '1')
            .attr('rx', 1)
            .attr('id', function (d) {
                return startId + 'rectMilestone-' + d.name;
            })
        dd.append('text')
            .html(function (d) {
                return d.name;
            })
            .attr('id', function (d) {
                return startId + 'textMilestone-' + d.name;
            })
            .attr('fill-opacity', '1')
            .attr('font-weight', 'bold')
            .attr('x', function (d) {
                return xScale(d.x + 5 + d.labelShiftX) + textPos(d).pos[0] + 2;
            })
            .attr('y', function (d) {
                return yScale(d.y + 10 + d.labelShiftY) - textPos(d).pos[1] + 5;
            })
            .style('display', 'none')
            .style('fill', 'black')
            .style('font-size', 3 * lineWidth + 'px')

    }*/

    function drawActivityCircles(singleProjectData, isActivitiesVisible, isMilestoneVisible) {

        let redActivities = 0, greenActivities = 0, amberActivities = 0, blueActivities = 0, greyActivities = 0, naActivities = 0
        let allMilestones = _data.activities.toArray().filter((item) => { return item.type === 'milestone' })
        let milestoneNames = []
        allMilestones.forEach(item => {
            if (item.dbColName != '' && item.dbColName != null) {
                milestoneNames.push(item.dbColName)
            }
        });
        let allActivities = _data.activities.toArray().filter((item) => { return item.type !== 'milestone' && item.type !== 'mapDivider' })
        let activityNames = []
        allActivities.forEach(item => {
            if (item.dbColName != '' && item.dbColName != null) {
                activityNames.push(item.dbColName)
            }
        });
        if (isActivitiesVisible) {
            activityNames.forEach(element => {
                const val = singleProjectData[element]
                if (val) {
                    if (val === 'G') {
                        greenActivities++
                    }
                    else if (val === 'R') {
                        redActivities++
                    }
                    else if (val === 'A') {
                        amberActivities++
                    }
                    else if (val === 'B') {
                        blueActivities++
                    } else if (val === 'GR') {
                        greyActivities++
                    } else {
                        naActivities++
                    }
                } else {
                    naActivities++
                }
            })
        }
        if (isMilestoneVisible) {
            milestoneNames.forEach(element => {
                const val = singleProjectData[element]
                if (val) {
                    if (val === 'G') {
                        greenActivities++
                    }
                    else if (val === 'R') {
                        redActivities++
                    }
                    else if (val === 'A') {
                        amberActivities++
                    }
                    else if (val === 'B') {
                        blueActivities++
                    } else if (val === 'GR') {
                        greyActivities++
                    }
                    else {
                        naActivities++
                    }
                } else {
                    naActivities++
                }
            })
        }
        // draw red and green circles

        /*gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 65)
            .attr("r", 2)
            .attr('fill', 'red')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 65 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('Needs attention or action')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 65 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(redActivities)

        ///
        gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 72)
            .attr("r", 2)
            .attr('fill', '#FFBF00')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 72 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('A risk but not an issue yet')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 72 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(amberActivities);

        gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 79)
            .attr("r", 2)
            .attr('fill', '#0dc70d')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 79 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('On track, no issues')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 79 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(greenActivities);

        gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 86)
            .attr("r", 2)
            .attr('fill', 'blue')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 86 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('Completed, no further action required')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 86 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(blueActivities)

        gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 93)
            .attr("r", 2)
            .attr('fill', 'grey')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 93 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('Expired, not due yet, not required')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 93 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(greyActivities);

        gMap.append('g')
            .append('circle')
            .attr('cx', 650 + 10)
            .attr('cy', 100)
            .attr("r", 1.8)
            .attr('fill', '#FFFFFF')
            .attr('stroke', '#22A2DF')
            .attr('stroke-width', 0.2)

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 5)
            .attr('y', 100 + 2)
            .attr('font-size', '4px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text('NA')

        gMap.append('g')
            .append('text')
            .append('tspan')
            .attr('x', 650 + 22)
            .attr('y', 100 + 2)
            .attr('font-size', '6px')
            .attr('font-weight', 'normal')
            .attr("text-anchor", "end")
            .text(naCount); */

        // update count in the div on the map
        d3.select('#redDiv').html(redActivities)
        d3.select('#amberDiv').html(amberActivities)
        d3.select('#greenDiv').html(greenActivities)
        d3.select('#blueDiv').html(blueActivities)
        d3.select('#greyDiv').html(greyActivities)
        d3.select('#naDiv').html(naActivities)
    }

    function transformData(data) {
        return {
            raw: data.lines,
            activities: extractActivities(data),
            lines: extractLines(data.lines),
        };
    }

    function extractActivities(data) {
        data.lines.forEach(function (line) {
            for (let node = 0; node < line.nodes.length; node++) {
                let d = line.nodes[node];

                if (!d.hasOwnProperty('name')) continue;

                if (!data.activities.hasOwnProperty(d.name))
                    throw new Error('Cannot find activity with key: ' + d.name);

                let activity = data.activities[d.name];

                activity.x = d.coords[0];
                activity.y = d.coords[1];

                if (activity.labelPos === undefined) {
                    activity.labelPos = d.labelPos;
                    activity.labelShiftX = d.hasOwnProperty('labelShiftCoords') ?
                        d.labelShiftCoords[0] :
                        d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[0] :
                            line.shiftCoords[0];
                    activity.labelShiftY = d.hasOwnProperty('labelShiftCoords') ?
                        d.labelShiftCoords[1] :
                        d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[1] :
                            line.shiftCoords[1];
                }

                if (d.hasOwnProperty('canonical')) {
                    activity.labelPos = d.labelPos;
                    activity.labelShiftX = d.hasOwnProperty('labelShiftCoords') ?
                        d.labelShiftCoords[0] :
                        d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[0] :
                            line.shiftCoords[0];
                    activity.labelShiftY = d.hasOwnProperty('labelShiftCoords') ?
                        d.labelShiftCoords[1] :
                        d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[1] :
                            line.shiftCoords[1];
                }

                activity.label = data.activities[d.name].label;
                activity.position = data.activities[d.name].position;
                activity.closed = data.activities[d.name].hasOwnProperty('closed') ?
                    data.activities[d.name].closed :
                    false;

                if (!d.hide) {
                    activity.marker = activity.marker || [];

                    activity.marker.push({
                        line: line.name,
                        color: line.color,
                        labelPos: d.labelPos,
                        marker: d.hasOwnProperty('marker') ? d.marker : 'activity',
                        shiftX: d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[0] : line.shiftCoords[0],
                        shiftY: d.hasOwnProperty('shiftCoords') ?
                            d.shiftCoords[1] : line.shiftCoords[1],
                    });
                }
            }
        });

        return activityList(data.activities);
    }

    function extractLines(data) {
        let lines = [];

        data.forEach(function (line) {
            let lineObj = {
                name: line.name,
                title: line.label,
                activities: [],
                color: line.color,
                shiftCoords: line.shiftCoords,
                nodes: line.nodes,
                highlighted: false,
            };

            lines.push(lineObj);

            for (let node = 0; node < line.nodes.length; node++) {
                let data = line.nodes[node];

                if (!data.hasOwnProperty('name')) continue;

                lineObj.activities.push(data.name);
            }
        });

        return lineList(lines);
    }

    function textPos(data) {
        let pos;
        let textAnchor;
        let alignmentBaseline;
        let offset = lineWidth * 1.8;

        let numLines = data.label.split(/\n/).length;

        let sqrt2 = Math.sqrt(2);

        switch (data.labelPos.toLowerCase()) {
            case 'n':
                pos = [0, 2.1 * lineWidth * (numLines - 1) + offset];
                textAnchor = 'middle';
                alignmentBaseline = 'baseline';
                break;
            case 'ne':
                pos = [offset / sqrt2, (lineWidth * (numLines - 1) + offset) / sqrt2];
                textAnchor = 'start';
                alignmentBaseline = 'baseline';
                break;
            case 'e':
                pos = [offset, 0];
                textAnchor = 'start';
                alignmentBaseline = 'middle';
                break;
            case 'se':
                pos = [offset / sqrt2, -offset / sqrt2];
                textAnchor = 'start';
                alignmentBaseline = 'hanging';
                break;
            case 's':
                pos = [0, -lineWidthMultiplier * offset];
                textAnchor = 'middle';
                alignmentBaseline = 'hanging';
                break;
            case 'sw':
                pos = [-offset / sqrt2, -offset / sqrt2];
                textAnchor = 'end';
                alignmentBaseline = 'hanging';
                break;
            case 'w':
                pos = [-offset, 0];
                textAnchor = 'end';
                alignmentBaseline = 'middle';
                break;
            case 'nw':
                pos = [-(lineWidth * (numLines - 1) + offset) / sqrt2,
                (lineWidth * (numLines - 1) + offset) / sqrt2,
                ];
                textAnchor = 'end';
                alignmentBaseline = 'baseline';
                break;
        }

        return {
            pos: pos,
            textAnchor: textAnchor,
            alignmentBaseline: alignmentBaseline,
        };
    }

    function wrap(text, baseline) {
        text.each(function () {
            let text = d3.select(this);
            let lines = text.text().split(/\n/);

            let y = text.attr('y');
            let x = text.attr('x');
            let dy = parseFloat(text.attr('dy'));

            text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em')
                .attr('dominant-baseline', baseline)
                .text(lines[0]);

            for (let lineNum = 1; lineNum < lines.length; lineNum++) {
                text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', lineNum * 1.1 + dy + 'em')
                    .attr('dominant-baseline', baseline)
                    .text(lines[lineNum]);
            }
        });
    }

    function drawMapDividerLines(startId, mapDividers) {

        let lineFunction = d3.line()
            .x(function (d) { return d.x; })
            .y(function (d) { return d.y; })

        let lineStrokes = ['0, 0', '3, 0.3', '2, 0.2'];
        let linesLabel = ['End of\nIdentify', 'End of\nDefine', 'End of\nDeliver']
        let linesCords = [
            [{ 'x': 0, 'y': 91 }, { 'x': 0, 'y': -135 }],
            [{ 'x': 0, 'y': 75 }, { 'x': 0, 'y': -152 }],
            [{ 'x': 0, 'y': 73 }, { 'x': 0, 'y': -153 }]
        ];

        let labelsPosition = [{ x: -20, y: 540 }, { x: -18, y: 525 }, { x: 1, y: 525 }];

        for (let i = 0; i < mapDividers.length; i++) {
            let svg = d3.select('#' + startId + mapDividers[i].name)
                .append('svg')
                .append('g')
                .attr('transform', function (mapDivider) {
                    return (
                        'translate(' +
                        xScale(mapDivider.x + 0.4) +
                        ',' +
                        yScale(mapDivider.y + 457) +
                        ')'
                    );
                });
            //append line
            svg.append('g').append('path')
                .attr('d', lineFunction(linesCords[i]))
                .attr('stroke', 'grey')
                .attr('stroke-width', 0.2)
                .attr('fill', 'none')
                .style('stroke-dasharray', (lineStrokes[i]))
                .attr('transform', 'translate(0,' + height + ')')

            // add labels
            let lables = linesLabel[i].split(/\n/);
            svg.append('text')
                .append('tspan')
                .attr('x', labelsPosition[i].x)
                .attr('y', labelsPosition[i].y)
                .attr('font-size', '5px')
                .attr('font-weight', 'bold')
                .text(lables[0])
                .append('tspan')
                .attr('x', labelsPosition[i].x)
                .attr('y', labelsPosition[i].y + 6)
                .attr('font-size', '5px')
                .attr('font-weight', 'bold')
                .text(lables[1]);
        }
    }

    function drawForecastLine(startId, lastCrosedMilestoneData, predictedMilestone) {
        let data = lastCrosedMilestoneData

        let lineFunction1 = d3.line()
            .x(function (d) { return d.x; })
            .y(function (d) { return d.y; })

        let lineStrokes = '0, 0';

        let linesCords = {
            "Milestone-0": [{ 'x': 0, 'y': 92 }, { 'x': 0, 'y': -135 }],
            "Milestone-1": [{ 'x': 0, 'y': 92 }, { 'x': 0, 'y': -135 }],
            "Milestone-2": [{ 'x': 0, 'y': 92 }, { 'x': 0, 'y': -135 }],
            "Milestone-3": [{ 'x': 0, 'y': 69 }, { 'x': 0, 'y': -158 }],
            "Milestone-4": [{ 'x': 0, 'y': 92 }, { 'x': 0, 'y': -135 }],
            "Milestone-5": [{ 'x': 12, 'y': 73.5 }, { 'x': 12, 'y': -155 }],
            "Milestone-6": [{ 'x': 10, 'y': 73.5 }, { 'x': 10, 'y': -155 }],
            "Milestone-7": [{ 'x': -12, 'y': 73.5 }, { 'x': -12, 'y': -155 }],
            "Milestone-8": [{ 'x': 0, 'y': 73.5 }, { 'x': 0, 'y': -155 }],
            "Milestone-9": [{ 'x': 13, 'y': 64 }, { 'x': 13, 'y': -165 }],
            "Milestone-10": [{ 'x': 0, 'y': 64 }, { 'x': 0, 'y': -165 }],
        }
        // This loop is for single
        for (let cnt = 0; cnt < data.length; cnt++) {
            let xVal = -5;
            if(data[cnt].name == 'Milestone-5'){
                xVal = 5;
            } 
            let svg = d3.select('#' + startId + data[cnt].name)
                .append('svg')
                .append('g')
                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        xScale(d.x + xVal) +
                        ',' +
                        yScale(d.y + 473) +
                        ')'
                    );
                });

            // append label for Last milestone achieved
            svg.append('g')
                .append('rect')
                .attr('x', -10)
                .attr('y', -71)
                .attr('width', 20)
                .attr('height', 7)
                .attr('fill', "#F1F4F9")
                .style('stroke', '#22A2DF')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "Last milestone";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
        
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "achieved";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -66)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')

            // draw forecasted milestone text START
             xVal = -5;
            if(predictedMilestone == 'Milestone-5'){
                xVal = 5;
            } 

            let svg2 = d3.select('#' + startId + predictedMilestone)
                .append('svg')
                .append('g')
                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        xScale(d.x + xVal) +
                        ',' +
                        yScale(d.y + 473) +
                        ')'
                    );
                });
            
            svg2.append('g')
                .append('rect')
                .attr('x', -10)
                .attr('y', -71)
                .attr('width', 20)
                .attr('height', 7)
                .attr('fill', "#F1F4F9")
                .style('stroke', '#22A2DF')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
            
            svg2.append('g')
                .append('text')
                .html(function (d) {
                    return "Forecasted";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -6)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
        
            svg2.append('g')
                .append('text')
                .html(function (d) {
                    return "milestone";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -6)
                .attr('y', -66)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')

            // draw forecasted milestone text END
        }
        // append bottom line
        /*gMap.append('g')
            .append('rect')
            .attr('x', 70)
            .attr('y', 295)
            .attr('width', 615)
            .attr('height', 0.5)
            .attr('fill', "#0098d9")
        // appdend left circle
        gMap.append('g')
            .append('circle')
            .attr('cx', 70)
            .attr('cy', 295)
            .attr("r", 2)
            .attr('fill', '#0098d9')
        //.attr('transform', 'translate(0,' + height + ')')
        // appdend right circle
        gMap.append('g')
            .append('circle')
            .attr('cx', 685)
            .attr('cy', 295)
            .attr("r", 2)
            .attr('fill', '#67db67')*/
        //.attr('transform', 'translate(0,' + height + ')')
    }

    function drawCurrentMilestone(startId, currentMilestoneData) {
        let data = currentMilestoneData

        /*const popUpPosition = {
            'c_create_the_project_batch': {'x': 132, 'y': -435 },
            'c_programme_approval_committee_1_decision': {'x': 170, 'y': -435 },
            'c_current_forecasted_pac_2_date_vs_baseline_pac_2_date':{'x': 317, 'y': -413 },
            'c_current_contract_award_date_vs_baseline_contract_award_date': {'x': 333, 'y': -435 },
            'c_forecasted_start_on_site_date_vs_baseline_start_on_site_date': {'x': 431, 'y': -417 },
            'c_forecasted_piu_vs_baseline_piu_date': {'x': 540, 'y': -417 },
            'c_forecasted_contract_completion_date_vs_baseline_contract_comp': {'x': 577, 'y': -417 },
            'c_progamme_approval_committee_3_decision': {'x': 598, 'y': -417 },
            'c_forecasted_final_acceptance_date_vs_baseline_final_acceptance': {'x': 638, 'y': -408 },
            'c_gateway_4_decision': {'x': 672, 'y': -408 },
        }*/
        const popUpPosition = {
            'Milestone-0': {'x': 83, 'y': -437 },
            'Milestone-1': {'x': 119, 'y': -437 },
            'Milestone-2': {'x': 157, 'y': -437 },
            'Milestone-3':{'x': 307, 'y': -416 },
            'Milestone-4': {'x': 320, 'y': -437 },
            'Milestone-5': {'x': 424, 'y': -419.5 },
            'Milestone-6': {'x': 520, 'y': -419.5 },
            'Milestone-7': {'x': 569, 'y': -419.5 },
            'Milestone-8': {'x': 590, 'y': -419.5 },
            'Milestone-9': {'x': 629, 'y': -410.5 },
            'Milestone-10': {'x': 661, 'y': -410.5 },
        }


        // This loop is for single
        for (let cnt = 0; cnt < data.length; cnt++) {
            let svg = gMap//d3.select('#' + startId + data[cnt].name)
                .append('svg')
                .append('g')
                /*.attr('transform', function (d) {
                    return (
                        'translate(' +
                        xScale(d.x + 5) +
                        ',' +
                        yScale(d.y + 457) +
                        ')'
                    );
                });*/
                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        popUpPosition[data[cnt].name].x +
                        ',' +
                        popUpPosition[data[cnt].name].y +
                        ')'
                    );
                })
            // append line
            svg.append('g')
                .append('rect')
                .attr('x', -10)
                .attr('y', -71)
                .attr('width', 20)
                .attr('height', 7)
                .attr('fill', "#F1F4F9")
                .style('stroke', '#22A2DF')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "Last milestone";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
        
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "achieved";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -66)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')           

        }
    }

    function drawInDateMilestone(startId, currentMilestoneData) {
        let data = currentMilestoneData

        // This loop is for single
        for (let cnt = 0; cnt < data.length; cnt++) {
            let xVal = 5;
            if(data[cnt].name == 'Milestone-3'){
                xVal = -1;
            }            
            let svg = d3.select('#' + startId + data[cnt].name)
                .append('svg')
                .append('g')
                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        xScale(d.x + xVal) +
                        ',' +
                        yScale(d.y + 473) +
                        ')'
                    );
                });
            // append line
            svg.append('g')
                .append('rect')
                .attr('x', -10)
                .attr('y', -71)
                .attr('width', 15)
                .attr('height', 7)
                .attr('fill', "#F1F4F9")
                .style('stroke', '#22A2DF')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "In date";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
        
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "milestone";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -8)
                .attr('y', -66)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')           

        }
    }

    function drawDatePopup(startId, milestoneNames, singleProjectData) {

        const milestoneDateCols = {
            //'c_create_the_project_batch',
            'c_programme_approval_committee_1_decision': ['c_programme_approval_committee_1_baseline_date',
                'c_programme_approval_committee_1_forecasted_date'],
            'c_current_forecasted_pac_2_date_vs_baseline_pac_2_date': ['c_programme_approval_committee_2_baseline_date',
                'c_programme_approval_committee_2_forecasted_date'],
            'c_current_contract_award_date_vs_baseline_contract_award_date': ['c_baseline_contract_award_date',
                'c_forecasted_contract_award_date'],
            'c_forecasted_start_on_site_date_vs_baseline_start_on_site_date': ['c_baseline_start_on_site_date',
                'c_forecasted_start_on_site_date'],
            'c_forecasted_piu_vs_baseline_piu_date': ['c_baseline_piu_date',
                'c_forecasted_piu_date'],
            'c_forecasted_contract_completion_date_vs_baseline_contract_comp': ['c_baseline_contract_completion_date',
                'c_forecasted_contract_completion_date'],
            'c_progamme_approval_committee_3_decision': ['c_progamme_approval_committee_3_baseline_date',
                'c_progamme_approval_committee_3_forecasted_date'],
            'c_forecasted_final_acceptance_date_vs_baseline_final_acceptance': ['c_baseline_final_acceptance_date',
                'c_forecasted_final_acceptance_date'],
            //'c_gateway_4_decision'
        }

        const popUpPosition = {
            'c_programme_approval_committee_1_decision': {'x': 170, 'y': -410 },
            'c_current_forecasted_pac_2_date_vs_baseline_pac_2_date':{'x': 347, 'y': -400 },
            'c_current_contract_award_date_vs_baseline_contract_award_date': {'x': 305, 'y': -425 },
            'c_forecasted_start_on_site_date_vs_baseline_start_on_site_date': {'x': 403, 'y': -400 },
            'c_forecasted_piu_vs_baseline_piu_date': {'x': 535, 'y': -390 },
            'c_forecasted_contract_completion_date_vs_baseline_contract_comp': {'x': 575, 'y': -390 },
            'c_progamme_approval_committee_3_decision': {'x': 600, 'y': -390 },
            'c_forecasted_final_acceptance_date_vs_baseline_final_acceptance': {'x': 640, 'y': -380 },
        }
        
        let data = milestoneNames
        let baseLineDate, forecastedDate, floatDays
        for (let cnt = 0; cnt < data.length; cnt++) {
            baseLineDate = singleProjectData[milestoneDateCols[data[cnt].dbColName][0]]
            forecastedDate = singleProjectData[milestoneDateCols[data[cnt].dbColName][1]]

            if(isNaN(baseLineDate)){
                baseLineDate = ''
            }

            if(isNaN(forecastedDate)){
                forecastedDate = ''
            }

            floatDays = 'NA'
            if(baseLineDate && forecastedDate){
                floatDays = baseLineDate - forecastedDate
            }

            if(baseLineDate == ''){
                baseLineDate = 'NA'
            } else{
                baseLineDate = convertExcelDateToDDMMYY(baseLineDate)
            }
           
            if(forecastedDate == ''){
                forecastedDate = 'NA'
            } else{
                forecastedDate = convertExcelDateToDDMMYY(forecastedDate)
            }
            
            let floatDaysColor = 'black'
            if(floatDays < 0){
                floatDaysColor = 'red'
            } else if(floatDays >= 0){
                floatDaysColor = 'green'
            }

            let svg = gMap// d3.select('#' + startId + data[cnt].name)
                .append('svg')
                .append('g')
                /*.attr('transform', function (d) {
                    return (
                        'translate(' +
                        xScale(d.x + 5) +
                        ',' +
                        yScale(d.y + 457) +
                        ')'
                    );
                })*/
                .attr('transform', function (d) {
                    return (
                        'translate(' +
                        popUpPosition[data[cnt].dbColName].x +
                        ',' +
                        popUpPosition[data[cnt].dbColName].y +
                        ')'
                    );
                })
                .attr('id', function (d) {
                    return 'date-popup-' + data[cnt].dbColName;
                })
                .style('display', 'none')
            // append line
            svg.append('g')
                .append('rect')
                .attr('x', -20)
                .attr('y', -75)
                .attr('width', 35)
                .attr('height', 12)
                .attr('fill', "#FFF8EB")
                .style('stroke', '#98670E')
                .style('stroke-width', 0.2)
                .attr('rx', 1)
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "Baseline Date: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', -71)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return baseLineDate;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -1)
                .attr('y', -71)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "Forecasted Date: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return forecastedDate;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', 2)
                .attr('y', -68)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "Float Days: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', -65)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block') 
            
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return floatDays;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -5)
                .attr('y', -65)
                .style('fill', floatDaysColor)
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block') 

        }
    }

    function drawLivesPopup(singleProjectData) {
        let popupHeight = 0;
        let bodLives = '1 out of 2' //singleProjectData['c_bod']
        let ammoniaLives = ''//'1 out of 2' //singleProjectData['c_ammonia']
        let solidiumLives ='Exceeded (Failing work)' //singleProjectData['c_solidium']
        let otherLives = '1 out of 2' //singleProjectData['c_other']

        if (bodLives) {
            popupHeight += 3.75;
        }
        if (ammoniaLives) {
            popupHeight += 3.75;
        }
        if (solidiumLives) {
            popupHeight += 3.75;
        }
        if (otherLives) {
            popupHeight += 3.75;
        }
        let svg = gMap
            .append('svg')
            .append('g')
            .attr('transform', function (d) {
                return (
                    'translate(' +
                    295 +
                    ',' +
                    -388 +
                    ')'
                );
            })
            .attr('id', function (d) {
                return 'lives-popup';
            })
            .style('display', 'none')
        // append line
        svg.append('g')
            .append('rect')
            .attr('x', -20)
            .attr('y', -74)
            .attr('width', 60)
            .attr('height', popupHeight)
            .attr('fill', "#FFF8EB")
            .style('stroke', '#98670E')
            .style('stroke-width', 0.2)
            .attr('rx', 1)
            .attr('fill-opacity', '1')
            .attr('opacity', '1')
            .attr('transform', 'translate(0,' + height + ')')

        let yStart = -74

        if (bodLives) {
            yStart=yStart+3
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "No of lives lost for BOD: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return bodLives;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', 9)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        }
        if (ammoniaLives) {
            yStart=yStart+3
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "No of lives lost for Ammonia: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return ammoniaLives;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', 14)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        }
        if (solidiumLives) {
            yStart=yStart+3
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "No of lives lost for Solidium: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return solidiumLives;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', 13.5)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        }
        if (otherLives) {
            yStart=yStart+3
            svg.append('g')
                .append('text')
                .html(function (d) {
                    return "No of lives lost for others: ";
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', -18)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'bold')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')

            svg.append('g')
                .append('text')
                .html(function (d) {
                    return otherLives;
                })
                .attr('fill', '#10137E')
                .attr('dy', 0)
                .attr('x', 11)
                .attr('y', yStart)
                .style('fill', 'black')
                .style('font-size', 2 * lineWidth + 'px')
                .attr('font-weight', 'normal')
                .attr('fill-opacity', '1')
                .attr('opacity', '1')
                .attr('transform', 'translate(0,' + height + ')')
                .style('display', 'block')
        }

    }

    function drawComplexityRatingTGValue(startId, tgColData, tgVal) {
        let rectWidth = 6.5
        if (tgVal == '') {
            tgVal = 'NA'
            rectWidth = 5.5
        }



        let svg = d3.select('#' + startId + tgColData[0].name)
            .append('svg')
            .append('g')
            .attr('transform', function (d) {
                return (
                    'translate(' +
                    xScale(d.x + 5) +
                    ',' +
                    yScale(d.y + 456) +
                    ')'
                );
            })
        // append line
        svg.append('g')
            .append('rect')
            .attr('x', -3)
            .attr('y', -56.5)
            .attr('width', rectWidth)
            .attr('height', 3)
            .attr('fill', "#F1F4F9")
            .style('stroke', '#22A2DF')
            .style('stroke-width', 0.2)
            .attr('rx', 1)
            .attr('fill-opacity', '1')
            .attr('opacity', '1')
            .attr('transform', 'translate(0,' + height + ')')

        svg.append('g')
            .append('text')
            .html(function (d) {                
                return tgVal;
            })
            .attr('fill', '#10137E')
            .attr('dy', 0)
            .attr('x', -2)
            .attr('y', -54.3)
            .style('fill', 'black')
            .style('font-size', 2 * lineWidth + 'px')
            .attr('font-weight', 'bold')
            .attr('fill-opacity', '1')
            .attr('opacity', '1')
            .attr('transform', 'translate(0,' + height + ')')
            .style('display', 'block')

    }

    /* function drawLegends() {
     
         let legendData = [
             { x: 70, y: 260, color: '#0099d7', label: 'Major Capital Projects' },
             { x: 70, y: 263, color: '#db241e', label: 'MSP Non-Core' },
             { x: 70, y: 266, color: '#ef7a10', label: 'BIM Touchpoints' },
             { x: 70, y: 269, color: '#000000', label: 'Planning & Control' },
             { x: 70, y: 272, color: '#a1a5a8', label: 'Finance' },
             { x: 70, y: 275, color: '#ffd32a', label: 'Technical Assurance' },
             { x: 70, y: 278, color: '#00bd19', label: 'Third Party Approvals' },
             { x: 70, y: 281, color: '#9a0058', label: 'Digital Services' },
             { x: 70, y: 284, color: '#f4a9be', label: 'Estimating/Cost Capture' },
             { x: 70, y: 287, color: '#b26200', label: 'Environmental Permitting' },
             { x: 70, y: 290, color: '#9364cc', label: 'Benefits Realisation' },
             { x: 70, y: 293, color: '#7bcebc', label: 'Network' },
         ]
         legendData.forEach(element => {
             gMap.append('g')
                 .append('rect')
                 .attr('x', element.x)
                 .attr('y', element.y)
                 .attr('width', 20)
                 .attr('height', 2)
                 .attr('fill', element.color)
     
             gMap.append('g')
                 .append('text')
                 .append('tspan')
                 .attr('x', element.x + 22)
                 .attr('y', element.y + 2)
                 .attr('font-size', '3px')
                 .attr('font-weight', 'normal')
                 .text(element.label);
     
         });
     }*/

    function drawBRAGInfo() {

        let mapInfo = [
            { x: 80, y: 55, color: 'red', label: 'R:', value: 'Needs attention or action' },
            { x: 80, y: 59, color: '#FFBF00', label: 'A:', value: 'A risk but not an issue yet' },
            { x: 80, y: 63, color: '#0dc70d', label: 'G:', value: 'On track, no issues' },
            { x: 80, y: 67, color: 'blue', label: 'B:', value: 'Completed, no further action required' },
            { x: 80, y: 71, color: 'grey', label: 'GR:', value: 'Expired, not due yet, not required' },
            { x: 80, y: 75, color: 'black', label: 'NA:', value: 'Data not available' }
        ]
        mapInfo.forEach(element => {
            gMap.append('g')
                .append('text')
                .append('tspan')
                .attr('x', element.x)
                .attr('y', element.y)
                .attr('font-size', '3px')
                .attr('fill', element.color)
                .attr('font-weight', 'normal')
                .text(element.label);

            gMap.append('g')
                .append('text')
                .append('tspan')
                .attr('x', element.x + 7)
                .attr('y', element.y)
                .attr('font-size', '3px')
                //.attr('fill', element.color)
                .attr('font-weight', 'normal')
                .text(element.value);
        });
    }

    function drawTubeMapInfo() {

        let mapInfo = [
            { x: 80, y: 260, color: '#000000', label: 'Department:', value: 'Programme Management Office (PMO)' },
            { x: 80, y: 265, color: '#000000', label: 'Owner:', value: 'Daniel Sutton' },
            { x: 80, y: 270, color: '#000000', label: 'Version:', value: 'Linked 3.0' },
            { x: 80, y: 275, color: '#000000', label: 'Status:', value: 'FINAL' },
            { x: 80, y: 280, color: '#000000', label: 'Last updated:', value: 'June 20' },

        ]
        mapInfo.forEach(element => {
            gMap.append('g')
                .append('text')
                .append('tspan')
                .attr('x', element.x)
                .attr('y', element.y)
                .attr('font-size', '3px')
                .attr('font-weight', 'normal')
                .text(element.label);

            gMap.append('g')
                .append('text')
                .append('tspan')
                .attr('x', element.x + 25)
                .attr('y', element.y)
                .attr('font-size', '3px')
                .attr('font-weight', 'normal')
                .text(element.value);
        });
    }

    function convertExcelDateToDDMMYY(excelDate) {
        const actualDate = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
        return ('0' + actualDate.getDate()).slice(-2) + '-' + ('0' + (actualDate.getMonth() + 1)).slice(-2) + '-' + actualDate.getFullYear();
    }
    return map;
}