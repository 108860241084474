import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PageSpinner from "../../PageSpinner";
import TablePagination from "../../TablePagination";
import "../../../styles/components/admin/pending-requests/TablePasswordPending.scss";

const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    if (index >= 3) {
      return null;
    }
    if (key === "dateRequested") {
      let dateresponse = props.data[key].split(",");
      props.data[key] = dateresponse[0];
    }
    return (
      <td
        key={props.data[key]}
        style={{
          color: index === 0 ? "#252525" : "#919BA3",
          fontFamily: index === 0 ? "Ubuntu" : "Ubuntu-Medium",
          paddingTop: "0.63rem",
        }}
      >
        {props.data[key]}
      </td>
    );
  });
};

class TablePasswordPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 4,
      showRejectModal: false,
      showAcceptModal: false,
      username: null,
      requestId: null,
      isSortingCalled: 1,
      lessClickKey: "",
      moreClickKey: "",
    };
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.showRejectConfirmationModal = this.showRejectConfirmationModal.bind(
      this
    );
    this.onClickingRejectUser = this.onClickingRejectUser.bind(this);
    this.onRejectUser = this.onRejectUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.showAcceptConfirmationModal = this.showAcceptConfirmationModal.bind(
      this
    );
    this.onClickingAcceptUser = this.onClickingAcceptUser.bind(this);
    this.onAcceptUser = this.onAcceptUser.bind(this);
    this.toggleAcceptModal = this.toggleAcceptModal.bind(this);
    this.showNotifyUserModal = this.showNotifyUserModal.bind(this);
    this.toggleNotifyModal = this.toggleNotifyModal.bind(this);
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  getKeys = function () {
    if (this.props.data.length === 0) {
      return null;
    }
    return Object.keys(this.props.data[0]);
  };

  getHeader = function () {
    let keys = this.getKeys();
    if (keys === null) {
      return null;
    }
    return keys.map((key, index) => {
      if (index >= 3) {
        return null;
      }
      let headerlabel = key;
      if (key === "dateRequested") {
        headerlabel = "Request Date";
      }
      if (key === "passwordToken") {
        headerlabel = "Token ID";
      }
      return (
        <th key={key}>
          <span>{headerlabel}</span>
          <ExpandLessIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] < b[prop]) {
                    return 1;
                  } else if (a[prop] > b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ lessClickKey: key, moreClickKey: "" });
            }}
            fontSize="small"
            className="expand-less-icon-sorting"
            style={{ color: key === this.state.lessClickKey ? "red" : "black" }}
          />
          <ExpandMoreIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] > b[prop]) {
                    return 1;
                  } else if (a[prop] < b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ moreClickKey: key, lessClickKey: "" });
            }}
            fontSize="small"
            className="expand-more-icon-sorting"
            style={{ color: key === this.state.moreClickKey ? "red" : "black" }}
          />
        </th>
      );
    });
  };

  getRowsData = function () {
    let items = this.props.data;
    let keys = this.getKeys();
    let counter = this.props.counter;
    let setCounter = this.props.setCounter;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    if (keys === null) {
      return null;
    }
    return items
      .slice(
        this.state.currentPage * this.state.pageSize,
        (this.state.currentPage + 1) * this.state.pageSize
      )
      .map((row, index) => {
        let requestId = row.requestId;
        let username = row.username;
        return (
          <tr key={index}>
            <RenderRow key={index} data={row} keys={keys} />
            <td>
              <Button
                type="button"
                color="primary"
                id="reset-approve-button"
                size="sm"
                onClick={this.onClickingAcceptUser.bind(
                  this,
                  requestId,
                  username
                )}
              >
                <div id="reset-approve-button-text">
                  &#10003; <span className="approve-string">Approve</span>
                </div>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                type="button"
                color="light"
                id="reset-reject-button"
                size="sm"
                onClick={this.onClickingRejectUser.bind(
                  this,
                  requestId,
                  username
                )}
              >
                <div id="reset-reject-button-text">
                  &#10005;{" "}
                  <span className="reject-string">&nbsp;Reject&nbsp;</span>
                </div>
              </Button>
            </td>
          </tr>
        );
      });
  };

  onClickingRejectUser(requestId, username) {
    this.setState({
      requestId: requestId,
      showRejectModal: true,
      username: username,
    });
  }

  toggleModal() {
    this.setState({ showRejectModal: !this.state.showRejectModal });
  }

  onRejectUser() {
    let counter = this.props.counter;
    let setCounter = this.props.setCounter;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;

    this.toggleModal();
    this.props.setShowNotifyModal(true);
    this.props.setUsername(this.state.username);
    this.props.rejectPasswordReset(
      this.state.requestId,
      counter,
      setCounter,
      setShowAlert,
      setAlertMsg,
      setAlertcolor
    );
  }

  showRejectConfirmationModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Reject Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Reject the request for{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onRejectUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  onClickingAcceptUser(requestId, username) {
    this.setState({
      requestId: requestId,
      showAcceptModal: true,
      username: username,
    });
  }

  toggleAcceptModal() {
    this.setState({ showAcceptModal: !this.state.showAcceptModal });
  }

  onAcceptUser() {
    let counter = this.props.counter;
    let setCounter = this.props.setCounter;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;

    this.toggleAcceptModal();
    this.props.setShowNotifyModal(true);
    this.props.setUsername(this.state.username);
    this.props.approvePasswordReset(
      this.state.requestId,
      counter,
      setCounter,
      setShowAlert,
      setAlertMsg,
      setAlertcolor
    );
  }

  showAcceptConfirmationModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Approve Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Approve the user{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onAcceptUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleAcceptModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  toggleNotifyModal() {
    this.props.setShowNotifyModal(false);
  }

  showNotifyUserModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Notify the user</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Please notify{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              whether the request is approved or rejected.
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleNotifyModal}
              active={true}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    if (this.props.isPasswordResetloading) {
      return <PageSpinner />;
    }

    if (this.getKeys() === null) {
      return (
        <div>
          <div className="table-responsive">
            <table className="table table-border table-sm table-pending-requests table-users">
              <thead className="table-head">
                <tr className="text-capitalize">
                  <th>Username</th>
                  <th>Request Date</th>
                  <th>Token ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
            </table>
            <Card id="no-pending-reset-req-card">
              <h6 id="no-pending-req-heading">
                No data available for reset password
              </h6>
            </Card>
          </div>
          <br />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.state.showRejectModal ? this.showRejectConfirmationModal() : ""}
        {this.state.showAcceptModal ? this.showAcceptConfirmationModal() : ""}

        <div className="table-responsive">
          <table className="table table-border table-sm table-pending-requests table-users">
            <thead className="table-head">
              <tr className="text-capitalize">
                {this.getHeader()}
                <th
                  style={{
                    paddingBottom: "0.68rem",
                  }}
                >
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.getRowsData()}</tbody>
          </table>
        </div>
        {Math.ceil(this.props.data.length / this.state.pageSize) > 1 ? (
          <div>
            <span className="showing-span">
              <span className="showing-text-pagination">Showing </span>
              <span>{this.state.currentPage + 1} </span>
              <span className="showing-text-pagination">out of </span>
              <span>
                {Math.ceil(this.props.data.length / this.state.pageSize)}{" "}
              </span>
              <span className="showing-text-pagination">pages </span>
            </span>
            <div id="reset-password-pagination-div">
              <div id="reset-password-pagination-style">
                <TablePagination
                  pagesCount={Math.ceil(
                    this.props.data.length / this.state.pageSize
                  )}
                  currentPage={this.state.currentPage}
                  setCurrentPage={this.setCurrentPage}
                />{" "}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    );
  }
}

export default TablePasswordPending;
