import React, { Component } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdInfo } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";

import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import ProjectTCQIChart from "./ProjectTCQIChart";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

class HealthScoreTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redraw: false,
      healthTrend: true,
      data: {
        labels: Object.keys(this.props.chartData),
        datasets: [
          {
            label: "Health Score",
            backgroundColor: "#268cd1",
            borderWidth: 0,
            data: null,
            barThickness: 30,
          },
        ],
      },
      showMonthlyTCQIChart: false,
      getProjectTCQIURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/tcqi-performance-comparison?filters=`,
      montlyTCQIData: null,
      dataMonths: null,
    };
  }
  // forecastChartData

  closeModal = () => {
    this.setState({
      showMonthlyTCQIChart: false,
    });
  };

  onRadioCheck = (e) => {
    const value = e.target.value;
    this.setState({
      healthTrend: value === "C",
      data: {
        ...this.state.data,
        labels: this.getChartKeys(value),
      },
      redraw: true,
    });
  };

  getChartKeys(value) {
    try {
      return value === "C"
        ? Object.keys(this.props.chartData)
        : Object.keys(this.props.forecastChartData);
    } catch (e) {
      return [];
    }
  }

  onBarClick = (event, elements) => {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }

    const chart = elements[0]._chart;
    const element = chart.getElementAtEvent(event)[0];
    //const dataset = chart.data.datasets[element._datasetIndex];
    const xLabel = chart.data.labels[element._index];
    // const value = dataset.data[element._index];

    const monthNames = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const month = monthNames[xLabel.split(" - ")[0]];
    const year = xLabel.split(" - ")[1];
    axios({
      url:
        this.state.getProjectTCQIURL +
        filterQuery +
        "&month=" +
        month +
        "&year=" +
        year,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        const montlyTCQIData = data.comparisonData;
        const dataMonths = Object.keys(montlyTCQIData);

        this.setState({
          montlyTCQIData: montlyTCQIData,
          showMonthlyTCQIChart: true,
          dataMonths: dataMonths,
        });
      })
      .catch((error) => {
        console.log("error in getting project tcqi data" + error);
      });
  };

  getChartValues() {
    try {
      const val = this.state.healthTrend
        ? this.props.chartData
        : this.props.forecastChartData;
      return Object.values(val);
    } catch {
      return [];
    }
  }

  render() {
    const chartData = this.state.data;
    let hScore = null;
    chartData.datasets[0].data = this.getChartValues();

    if (chartData.datasets[0].data) {
      const arr = chartData.datasets[0].data;
      hScore = arr[arr.length - 1];
    }

    const options = {
      responsive: false,
      title: {
        display: false,
        text: "Health Score Trend",
        position: "left",
        fontFamily: "Ubuntu-Medium",
      },
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              fontColor: "#252525",
              fontFamily: "Ubuntu-Medium",
            },
            //stacked: true,
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: "#F1F4F9",
              borderDash: [5, 3],
              offsetGridLines: false,
              fontFamily: "Ubuntu-Medium",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              stepSize: 25,
              fontColor: "#919BA3",
              fontFamily: "Ubuntu-Medium",
              callback: function (value, index, values) {
                return `${value}%`;
              },
            },
            //stacked: true,
            display: true,
          },
        ],
        pointLabels: {
          fontStyle: "bold",
          fontFamily: "Ubuntu",
        },
      },
      layout: {
        padding: {
          left: 15,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          //align: 'top, middle',
          align: "top",
          anchor: "end",
          fontFamily: "Ubuntu-Medium",
          formatter: function (val) {
            return `${val}%`;
          },
        },
      },
      tooltips: {
        displayColors: false,
      },
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
      onClick: this.onBarClick,
    };

    return (
      <Col>
        <div
          className="chart-top"
          style={{
            fontSize: "0.8rem",
            textAlign: "center",
          }}
        >
          <Row>
            <Col xs={4} className="f-14 mt-2 pt-2">
              <center className="mt-2">
              <div>
                  <span className="f-14 ml-1">
                    Overall programme health score
                  </span>

                  <BootstrapTooltip
                    placement="right"
                    title={<> <div>
                      The doughnut chart represents the overall health score of the entire programme or a selected set of projects as a subprogramme in percentage.
                      </div>
                      <div className="mt-2">
                      The overall health score is calculated based on weight of the business measures time, cost, quality, scope, H&S and SME and their RAG status across projects under consideration.
                      </div></>
                      }>
                    <span>
                      <MdInfo
                        size={20}
                        className="c-info"
                        style={{ marginTop: "-0.5rem" }}
                      ></MdInfo>
                    </span>
                  </BootstrapTooltip>
                </div>
              </center>
            
            </Col>
            <Col xs={8} className="d-flex justify-content-between f-14 mt-2 pt-2">
            <Col xs={8}>
            <div className="d-flex justify-content-end mt-2">
              <span className="f-14">
                    Overall programme health score trend
                  </span>
                  <BootstrapTooltip
                    placement="right-start"
                    title={<> <div>The chart represents trend of the overall health score in percentange for the last six months. </div>
                    <div className="mt-2">
                      Click on a bar to view the comparison of the health score of the six business measures for the selected month and its previous month.
                    </div></>}
                  >
                    <span>
                      <MdInfo
                        size={20}
                        className="c-info"
                        style={{ marginTop: "-0.5rem" }}
                      ></MdInfo>
                    </span>
                  </BootstrapTooltip>            
                  </div>

                      </Col>
                      <Col xs={4}>
            <div className="d-flex justify-content-end mb-1 ">
            <span className="current-forecasted-radio-buttons p-0 m-0">
              <label className="radio-label">
                Current
                <input
                  type="radio"
                  name="healthTrend"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="C"
                  checked={this.state.healthTrend}
                ></input>
                <span className="checkmark"></span>
              </label>
              <label className="radio-label">
                Forecasted
                <input
                  type="radio"
                  name="healthTrend"
                  onChange={(e) => {
                    this.onRadioCheck(e);
                  }}
                  value="F"
                  checked={this.state.healthTrend === false}
                ></input>
                <span className="checkmark"></span>
              </label>
            </span>
          </div>
          </Col>
            </Col>
          </Row>
          
          <Row>
            <Col xs={4} className="d-flex justify-content-center align-items-center">
            <div className="mt-3 pt-1">
               <div
                  style={{
                    width: 160,
                    height: 160,
                    marginTop: "2.5rem",
                  }}
                >
                  <CircularProgressbar
                    value={hScore || 0}
                    text={`${hScore || 0}%`}
                    redraw={this.state.redraw}
                  />
                </div>
                <br />
              </div>
            </Col>
            <Col>
              <center>
                <div>
                  <div style={{marginTop:'1.5rem'}} className="d-flex align-items-center  justify-content-center">
                  <div className="custom-y-label">% Health score</div>
                  <Bar
                    data={chartData}
                    width={500}
                    height={250}
                    options={options}
                    redraw={this.state.redraw}
                  />
                  </div>
                  {this.state.showMonthlyTCQIChart && (
                    <Modal isOpen="true" centered="true" size="lg">
                      <ModalBody
                        id="show-key-activities-modal"
                        className="p-0 tcqs-health-modal-body"
                      >
                        <div className="p-2">
                          <CancelIcon
                            onClick={this.closeModal}
                            className="cancel-close-icon"
                          />
                          <Row>
                            <Col>
                              <center>
                                <span
                                  id="key-tasks-to-text"
                                  style={{ color: "#252525" }}
                                >
                                  Comparison of {this.state.healthTrend ? 'current': 'forecasted'} performance of six key business measures between {this.state.dataMonths[0]}{" "}
                                  and {this.state.dataMonths[1]}
                                  <BootstrapTooltip
                                  placement="bottom"
                                  title="The chart represents comparison of the current month health score of the business measures with their previous month."
                                >
                                <span>
                                  <MdInfo size={20} className="c-info mb-1"></MdInfo>
                                </span>
                                </BootstrapTooltip>
                                </span>
                              </center>
                            </Col>
                          </Row>
                        </div>
                        <div className="p-3" style={{ background: "#fff" }}>
                          <ProjectTCQIChart
                            tcqiChartData={this.state.montlyTCQIData}
                          ></ProjectTCQIChart>
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </center>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default HealthScoreTrendChart;
