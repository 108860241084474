import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
//import { MdRemove, MdAdd, MdInfo } from "react-icons/md";
import classnames from "classnames";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Spinner } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";

import PageSpinner from "../components/PageSpinner";
import * as GenericConstants from "../src-constants/GenericConstants";
import ProjectMap from "../components/dashboard/currentstatus/tubemap/ProjectMap";
import LineChart from "../components/dashboard/currentstatus/charts/LineChart";
import CurrentMonthMap from "../components/dashboard/currentstatus/tubemap/CurrentMonthMap";
import CurrentMonthChart from "../components/dashboard/currentstatus/charts/CurrentMonthChart";
import MonthsActivityChart from "../components/dashboard/currentstatus/charts/MonthsActivityChart";
import Filter from "../components/dashboard/Filter";
import "../styles/pages/CurrentStatusPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandler } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import * as CurrentStatusDataConverter from "../services/CurrentStatusDataConverter";
import "../styles/pages/ProgramAssurancePage.scss";
import HistoricalRecommendationChart from "../components/program-assurance/historicalRecommendationChart";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class ProgramAssurancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualFilterArray: [],
      getFiltersDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/filters-data`,
      selectedFiltersData: {},
      isFilterOpen: false,
      filtersAPIData: null,
      latestDataLoaded: false,
      lineChartDataLoaded: false,
      tcqsDataLoaded: false,
      allMonthsDataLoaded: false,
      totalProjectLoading: true,
      totalProjects: null,
    };
    this.closeFilter = this.closeFilter.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.escapeFilterValue = this.escapeFilterValue.bind(this);
    this.toggleDownload = this.toggleDownload.bind(this);
    this.downloadExcelClicked = this.downloadExcelClicked.bind(this);
    this.downloadPdfClicked = this.downloadPdfClicked.bind(this);
    this.toggleCloseConfirmationModal = this.toggleCloseConfirmationModal.bind(
      this
    );
    this.closeFilterConfirmation = this.closeFilterConfirmation.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.noFilterSelectedCloseFilter = this.noFilterSelectedCloseFilter.bind(
      this
    );
  }

  componentDidMount() {
    if (window.innerWidth < 1000) {
      this.setState({ tablet: 1 });
    } else this.setState({ tablet: 3 });

    this.getFiltersData();
  }

  getFiltersData() {
    axios({
      url: this.state.getFiltersDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const filtersData = response.data.filtersData;
        this.setState({ filtersAPIData: filtersData });
      })
      .catch((error) => {
        console.log("error in fetching filters data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  escapeFilterValue(filterJsonObject) {
    let encodedFilterJsonObject = {};
    Object.entries(filterJsonObject).forEach((filterItem) => {
      let encodedFilterValueArray = [];
      filterItem[1].forEach((filterValue) => {
        encodedFilterValueArray.push(encodeURIComponent(filterValue));
      });
      encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
    });
    return encodedFilterJsonObject;
  }

  updateFilters(name, list) {
    // update values in selectedFiltersData
    let selectedFiltersData = this.state.selectedFiltersData;
    if (selectedFiltersData == null) {
      selectedFiltersData = {};
    }
    if (list == null || list.length == 0) {
      delete selectedFiltersData[name];
    } else {
      selectedFiltersData[name] = list;
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
  }

  removeFilter(filterName, filterVal) {
    // remove filter
    let selectedFiltersData = this.state.selectedFiltersData;
    let filterList = selectedFiltersData[filterName];
    selectedFiltersData[filterName] = filterList.filter((item) => {
      return item != filterVal;
    });
    if (
      selectedFiltersData[filterName] == null ||
      selectedFiltersData[filterName].length == 0
    ) {
      delete selectedFiltersData[filterName];
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
    // update data
    this.getLatestData();
    this.getTCQSData();
    this.getAllMonthsData();
    this.getGreenMilestoneData();
    this.getProjectCount();
    this.getLineChartData();
  }

  toggleFilter() {
    const isFilterOpen = this.state.isFilterOpen;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  async clearFilters() {
    await this.setState({ selectedFiltersData: {} });
    this.applyFilters();
  }

  updateDownloadButtonState() {
    const {
      latestData,
      chartData,
      monthsChartData,
      lineChartData,
      greenMilestoneData,
    } = this.state;

    this.setState({
      downloadEnabled:
        this.state.activeTab == "1"
          ? this.state.latestDataLoaded &&
            this.state.lineChartDataLoaded &&
            this.state.tcqsDataLoaded &&
            this.state.allMonthsDataLoaded &&
            !(
              (latestData == null ||
                JSON.stringify(latestData) === JSON.stringify({})) &&
              (lineChartData == null ||
                JSON.stringify(lineChartData) === JSON.stringify({})) &&
              (chartData == null ||
                JSON.stringify(chartData) === JSON.stringify({})) &&
              (monthsChartData == null ||
                JSON.stringify(monthsChartData) === JSON.stringify({}))
            )
          : this.state.projectMapDataLoaded &&
            !(
              this.state.singleProjectData == null ||
              JSON.stringify(this.state.singleProjectData) ===
                JSON.stringify({})
            ),
    });
  }

  closeFilter() {
    this.clearFilters();
    this.setState({
      showMatchingProjects: false,
      totalProjectLoading: true,
      openCloseConfirmation: false,
    });
  }

  noFilterSelectedCloseFilter() {
    this.toggleFilter();
    this.setState({
      openCloseConfirmation: false,
    });
  }

  closeFilterConfirmation() {
    this.setState({ openCloseConfirmation: true });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  toggleCloseConfirmationModal() {
    this.setState({ openCloseConfirmation: !this.state.openCloseConfirmation });
  }

  toggleDownload = () => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen });
  };

  downloadChartPdf(elementId, fileName) {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentonthMap").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialProjectMap").click();
    }
    setTimeout(() => {
      const input = document.getElementById(elementId);

      html2canvas(input, { scale: 5 }).then((canvas) => {
        var a = Object.keys(this.state.selectedFiltersData);
        var filter = [];
        for (let i = 0; i < a.length; i++) {
          for (
            let j = 0;
            j < this.state.selectedFiltersData[a[i]].length;
            j++
          ) {
            filter.push(this.state.selectedFiltersData[a[i]][j] + " ");
          }
        }
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("portrait", "pt", [
          canvas.width + 20,
          canvas.height + 20,
        ]);

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        pdf.setFontSize(width / 70);
        pdf.text(50, 150, "Current Performance");
        pdf.text(
          width / 3,
          150,
          this.state.activeTab == 1
            ? "All Programs"
            : this.state.activeTab == 2
            ? this.state.selectedProject
            : ""
        );

        pdf.text(
          50,
          250,
          "Based on data uploaded for " + this.props.month + this.props.year
        );
        if (this.state.activeTab == 1) {
          pdf.setFontSize(width / 70);
          pdf.text(50, 350, "Total Projects: " + this.state.totalProjects);
        }

        if (filter.length > 0 && this.state.activeTab == 1) {
          pdf.text(50, 450, "Filters: " + filter.toString());
        }

        pdf.addImage(imgData, "JPEG", 20, 500, width, canvas.height - 500);
        pdf.save(fileName);
        this.setState({ pdfDownloading: false });
        // });
      });
    }, 1000);
  }

  downloadAllProgramsDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    const fileExtension = ".xlsx";
    let sheets = {};
    let sheetNames = [];
    if (this.state.latestData) {
      const ws1 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertPerformanceMilestoneData(
          this.state.latestData,
          this.state.csvColumnMapping
        )
      );
      sheets["PerformanceMilestone"] = ws1;
      sheetNames.push("PerformanceMilestone");
    }

    if (this.state.greenMilestoneData) {
      const ws2 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertGreenMilestoneData(
          this.state.greenMilestoneData
        )
      );
      sheets["GreenMilestone"] = ws2;
      sheetNames.push("GreenMilestone");
    }

    if (this.state.chartData) {
      const ws3 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertTCQSData(this.state.chartData)
      );
      sheets["TCQS"] = ws3;
      sheetNames.push("TCQS");
    }

    if (this.state.monthsChartData) {
      const ws4 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertActivityPerformanceData(
          this.state.monthsChartData,
          this.state.csvColumnMapping
        )
      );
      sheets["ActivityPerformance"] = ws4;
      sheetNames.push("ActivityPerformance");
    }

    const wb = {
      Sheets: sheets,
      SheetNames: sheetNames,
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "CurrentStatus_AllProgramsChartsData.xlsx");
  };

  downloadProjectMapDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws1 = XLSX.utils.json_to_sheet(
      CurrentStatusDataConverter.convertProjectMapData(
        this.state.singleProjectData,
        this.state.csvColumnMapping
      )
    );

    const wb = {
      Sheets: {
        PerformanceMilestone: ws1,
      },
      SheetNames: ["PerformanceMilestone"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "CurrentStatus_ProjectMapData.xlsx");
  };

  downloadData = () => {
    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "CurrentStatus_AllProgramsCharts.pdf"
      );
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "searchByProjectDIv",
        "CurrentStatus_ProjectMapChart.pdf"
      );
      this.downloadProjectMapDataExcel();
    }
  };

  downloadExcelClicked = () => {
    if (this.state.activeTab == "1") {
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadProjectMapDataExcel();
    }
  };

  downloadPdfClicked = () => {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentonthMap").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialProjectMap").click();
    }
    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "CurrentStatus_AllProgramsCharts.pdf"
      );
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "searchByProjectDIv",
        "CurrentStatus_ProjectMapChart.pdf"
      );
    }
  };

  render() {
    const {
      activeTab,
      latestData,
      isLoading,
      isFilterOpen,
      chartData,
      monthsChartData,
      csvColumnMapping,
      filtersAPIData,
      greenMilestoneData,
      latestDataLoaded,
      greenMilestoneDataLoaded,
      tcqsDataLoaded,
      allMonthsDataLoaded,
      lineChartData,
      lineChartDataLoaded,
      historicalRecommendationChartData,
      historicalRecommendationChartDataLoaded,
    } = this.state;

    let uiFilterData, filterSection;
    let filtersData = filtersAPIData;
    if (filtersData) {
      const filterNames = Object.keys(filtersData);
      uiFilterData = filterNames.map((item, index) => {
        const filterName = item;
        /*const filterValues = filtersData[filterName].map((itm, idx) => {
          return itm == "" ? "(Blanks)" : itm;
        });*/
        const filterValues = filtersData[filterName].filter((itm) => {
          return itm != null && itm.trim() != "";
        });
        return (
          <span
            style={{
              float: index % 2 === 0 ? "left" : "right",
            }}
          >
            <Filter
              key={index}
              filterValues={filterValues}
              csvColumnMapping={csvColumnMapping}
              filterData={this.state.selectedFiltersData}
              filterName={filterName}
              setSelectedOptions={(name, list) =>
                this.updateFilters(name, list)
              }
            ></Filter>
          </span>
        );
      });
    }
    if (uiFilterData) {
      filterSection = <div>{uiFilterData}</div>;
    }
    const filters = this.state.selectedFiltersData;
    let filterSummaryData, list, singleFilter;
    let test = [];
    let filtersArray = [];
    Object.entries(filters).forEach((filterItem) => {
      filterItem[1].forEach((filter) => {
        filtersArray.push({
          filterCategory: filterItem[0],
          filter: filter,
        });
      });
    });

    const getSingleFilterObject = (length, filterCategory, filter) => {
      return (
        <BootstrapTooltip title={filter}>
          <span>
            <div
              className="filter-summary-inner"
              style={
                length > this.state.tablet - 1
                  ? { display: "none" }
                  : { display: "inline" }
              }
            >
              {filter.length < 15 ? filter : filter.substr(0, 14) + "..."}{" "}
              <FaTimes
                className="filter-delete"
                onClick={() => this.removeFilter(filterCategory, filter)}
              ></FaTimes>
            </div>
          </span>
        </BootstrapTooltip>
      );
    };

    let individualFilterArray = [];
    for (let i = 0; i < filtersArray.length; i++) {
      if (filtersArray[i]) {
        individualFilterArray.push(
          getSingleFilterObject(
            i,
            filtersArray[i].filterCategory,
            filtersArray[i].filter
          )
        );
      }
    }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    return (
      <React.Fragment>
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        <Header
          id="currhead"
          open={this.props.open}
          setOpen={this.props.setOpen}
        >
          <Row>
            <Col xs={8}>
              <div className="currentClass">Program Assurance</div>
              <div className="basedClass">
                <span className="year-month-dashboard">
                  {GenericConstants.MONTH_MAPPING[this.props.month]
                    ? GenericConstants.MONTH_MAPPING[this.props.month]
                    : this.props.month}{" "}
                  {this.props.year}
                </span>
                {this.props.month ? " snapshot" : ""}
                <br />
                <span style={{ float: "left" }}>{"Live Projects Only"}</span>
              </div>
            </Col>
            <Col xs={4}>
              <ButtonDropdown
                isOpen={this.state.isDownloadOpen}
                toggle={this.toggleDownload}
                color="primary"
                className="button-dropdown-dashboard"
              >
                <DropdownToggle
                  caret
                  size="sm"
                  color="primary"
                  disabled={
                    !this.state.downloadEnabled || this.state.pdfDownloading
                      ? true
                      : false
                  }
                >
                  {this.state.pdfDownloading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span> Downloading...
                    </span>
                  ) : (
                    <span>
                      <GetAppIcon fontSize="small" />
                      <span className="download-text-dashboard"> Download</span>
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu size="sm">
                  <DropdownItem
                    onClick={this.downloadPdfClicked}
                    className="dropdown-item-dashboard"
                    size="sm"
                  >
                    Pdf
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadExcelClicked}
                  >
                    Xlsx
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadBothPdfAndExcel}
                  >
                    Both
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </Header>
        <Row>
          <Col xs={1} md={1} className="counts-separator-program-assurance">
            <Row>
              <Col xs={12} md={12} className="total-label-program-assurance">
                {this.state.totalProjects &&
                !(
                  this.state.totalProjectLoading ||
                  !latestDataLoaded ||
                  !lineChartDataLoaded ||
                  !tcqsDataLoaded ||
                  !allMonthsDataLoaded
                )
                  ? individualFilterArray.length !== 0 &&
                    this.state.showMatchingProjects === true
                    ? "Matching Projects"
                    : "Total Projects"
                  : ""}
              </Col>
              <Col xs={12} md={12} className="project-count">
                {this.state.totalProjectLoading ||
                !latestDataLoaded ||
                !lineChartDataLoaded ||
                !tcqsDataLoaded ||
                !allMonthsDataLoaded ? (
                  <div style={{ color: "#268cd1" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>{this.state.totalProjects}</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={1} md={1} className="counts-separator-program-assurance">
            <Row>
              <Col xs={12} md={12} className="total-label-program-assurance">
                {this.state.totalProjects &&
                !(
                  this.state.totalProjectLoading ||
                  !latestDataLoaded ||
                  !lineChartDataLoaded ||
                  !tcqsDataLoaded ||
                  !allMonthsDataLoaded
                )
                  ? "Completed"
                  : ""}
              </Col>
              <Col xs={12} md={12} className="project-count">
                {this.state.totalProjectLoading ||
                !latestDataLoaded ||
                !lineChartDataLoaded ||
                !tcqsDataLoaded ||
                !allMonthsDataLoaded ? (
                  ""
                ) : (
                  <div>{this.state.completedProjects}</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={1} md={1}>
            <Row>
              <Col xs={12} md={12} className="total-label-program-assurance">
                {this.state.totalProjects &&
                !(
                  this.state.totalProjectLoading ||
                  !latestDataLoaded ||
                  !lineChartDataLoaded ||
                  !tcqsDataLoaded ||
                  !allMonthsDataLoaded
                )
                  ? "Re-Arranged"
                  : ""}
              </Col>
              <Col xs={12} md={12} className="project-count">
                {this.state.totalProjectLoading ||
                !latestDataLoaded ||
                !lineChartDataLoaded ||
                !tcqsDataLoaded ||
                !allMonthsDataLoaded ? (
                  ""
                ) : (
                  <div>{this.state.reArrangedProjects}</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={4} md={4}>
            {/* {individualFilterArray} */}
            {
              <div
                className="filter-summary"
                style={{
                  width: "auto",
                  background: "none",
                  marginBottom: "0px",
                }}
              >
                <div className="filter-summary-title">
                  {/* Applied Filters */}
                </div>
                {/* {filterSummaryData} */}
                {individualFilterArray}
                <div
                  className="filter-summary-inner"
                  style={
                    individualFilterArray.length <= this.state.tablet
                      ? { display: "none" }
                      : { display: "inline" }
                  }
                >
                  {individualFilterArray.length > this.state.tablet
                    ? individualFilterArray.length - this.state.tablet + " +"
                    : ""}
                </div>
              </div>
            }
          </Col>
          <Col xs={2} md={2}>
            {latestData != null && (
              <div style={{ position: "relative" }}>
                <div className="filter">
                  <CardHeader className="filter-header">
                    <span onClick={this.toggleFilter}>
                      <b>All Filters </b>
                      <span style={{ color: "#BD2967" }}>
                        {!isFilterOpen && <FaAngleDown></FaAngleDown>}{" "}
                        {isFilterOpen && <FaAngleUp></FaAngleUp>}
                      </span>
                    </span>
                  </CardHeader>
                  <Collapse
                    isOpen={isFilterOpen}
                    className="filter-main"
                    timeout={0}
                  >
                    <Row>
                      <Col>
                        <CancelIcon
                          className="cancel-close-icon"
                          onClick={
                            individualFilterArray.length !== 0
                              ? this.closeFilterConfirmation
                              : this.noFilterSelectedCloseFilter
                          }
                          id="cancel-filter"
                          fontSize="small"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {filterSection && (
                          <div className="filter-div">{filterSection}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="filter-buttons">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.clearFilters();
                              this.setState({
                                showMatchingProjects: false,
                                totalProjectLoading: true,
                              });
                            }}
                            outline
                          >
                            Reset All
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              this.applyFilters();
                              this.setState({
                                showMatchingProjects: true,
                                totalProjectLoading: true,
                              });
                            }}
                          >
                            Apply Filters
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {historicalRecommendationChartDataLoaded &&
              historicalRecommendationChartData &&
              csvColumnMapping &&
              JSON.stringify(historicalRecommendationChartData) !==
                JSON.stringify({}) &&
              JSON.stringify(csvColumnMapping) !== JSON.stringify({}) && (
                <HistoricalRecommendationChart
                  chartData={historicalRecommendationChartData}
                  csvColumnMapping={csvColumnMapping}
                  filterData={this.state.selectedFiltersData}
                ></HistoricalRecommendationChart>
              )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ProgramAssurancePage;
