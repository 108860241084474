import React, { Component } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
//import { MdRemove, MdAdd, MdInfo } from "react-icons/md";
import classnames from "classnames";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Spinner } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as GenericConstants from "../src-constants/GenericConstants";
import ProjectMap from "../components/dashboard/currentstatus/tubemap/ProjectMap";
import LineChart from "../components/dashboard/currentstatus/charts/LineChart";
import CurrentMonthMap from "../components/dashboard/currentstatus/tubemap/CurrentMonthMap";
import CurrentMonthChart from "../components/dashboard/currentstatus/charts/CurrentMonthChart";
import CurrentMonthPieChart from "../components/dashboard/currentstatus/charts/CurrentMonthPieChart";
import MonthsActivityChart from "../components/dashboard/currentstatus/charts/MonthsActivityChart";
import Filter from "../components/dashboard/Filter";
import "../styles/pages/CurrentStatusPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandler } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import * as CurrentStatusDataConverter from "../services/CurrentStatusDataConverter";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class CurrentStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: null,
      getAllProjectsDataURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/current-performance-projects?filters=`,
      getProjectCountURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-counts?filters=`,
      getLatestDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-milestone?filters=`,
      getAllMonthsDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-months?filters=`,
      getTCQSDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/quality-params-data?filters=`,
      getGreenMilestoneDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/green-milestone-by-month?filters=`,
      getFiltersDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/filters-data`,
      getCsvColumnsMappingRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/column-mapping`,
      getLineChartDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-milestone-trend-by-month/`,
      fetchProjectNamesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-names?filters=`,
      getLatestCSVDateURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/latest-csv-date`,
      activeTab: props?.location?.state?.projectId ? "2" : "1",
      projectsDataDefaultVal: props?.location?.state?.projectId,
      qualityParamDefaultVal: props?.location?.state?.qualityParam,
      zoomSliderVal: 2,
      selectedFiltersData: {},
      isFilterOpen: false,
      filtersAPIData: null,
      currentYear: 0,
      currentMonth: 0,
      isLoading: false,
      latestData: null,
      chartData: null,
      monthsChartData: null,
      csvColumnMapping: null,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      excelDownloading: false,
      pdfDownloading: false,
      downloadEnabled: false,
      countFilter: 0,
      showMatchingProjects: false,
      totalProjectLoading: true,
      individualFilterArray: [],
      totalProjects: null,
      singleProjectData: null,
      isDownloadOpen: false,
      tablet: 5,
      lineChartData: {},
      activityList: null,
      milestoneDbColName: null,
      selectedMilestone: 0,
      milestoneProjectCount: 0,
      projectIdName: null,
      projectIdNameTotalProj: null,
      activityType: 'R',
      latestCSVDate: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.getLatestData = this.getLatestData.bind(this);
    this.getFiltersData = this.getFiltersData.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getAllMonthsData = this.getAllMonthsData.bind(this);
    this.getCsvColumnsMapping = this.getCsvColumnsMapping.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.getGreenMilestoneData = this.getGreenMilestoneData.bind(this);
    this.downloadChartPdf = this.downloadChartPdf.bind(this);
    this.downloadAllProgramsDataExcel =
      this.downloadAllProgramsDataExcel.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.getTCQSData = this.getTCQSData.bind(this);
    this.updateDownloadButtonState = this.updateDownloadButtonState.bind(this);
    this.updateProjectMapDataLoadStatus =
      this.updateProjectMapDataLoadStatus.bind(this);
    this.updateSingleProjectMapData =
      this.updateSingleProjectMapData.bind(this);
    this.downloadProjectMapDataExcel =
      this.downloadProjectMapDataExcel.bind(this);
    this.escapeFilterValue = this.escapeFilterValue.bind(this);
    this.toggleDownload = this.toggleDownload.bind(this);
    this.downloadExcelClicked = this.downloadExcelClicked.bind(this);
    this.downloadPdfClicked = this.downloadPdfClicked.bind(this);
    this.toggleCloseConfirmationModal =
      this.toggleCloseConfirmationModal.bind(this);
    this.closeFilterConfirmation = this.closeFilterConfirmation.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.noFilterSelectedCloseFilter =
      this.noFilterSelectedCloseFilter.bind(this);
    this.getLineChartData = this.getLineChartData.bind(this);
    this.setSelectedActivityLineChart =
      this.setSelectedActivityLineChart.bind(this);
    this.getAllProjectsData = this.getAllProjectsData.bind(this);
    this.openListOfProjectsModal = this.openListOfProjectsModal.bind(this);
    this.closeListOfProjectsModal = this.closeListOfProjectsModal.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
    this.searchByProject2 = this.searchByProject2.bind(this);
    this.formatLineChartData = this.formatLineChartData.bind(this);
    this.fetchProjectNames = this.fetchProjectNames.bind(this);
    this.keepFilters = this.keepFilters.bind(this);
    this.closeKeepFiltersModal = this.closeKeepFiltersModal.bind(this);
    this.closeFilterPaneOnApplying = this.closeFilterPaneOnApplying.bind(this);
    this.updateSelectedMilestone = this.updateSelectedMilestone.bind(this);
    this.getLatestCSVDate = this.getLatestCSVDate.bind(this);
  }

  async updateSelectedMilestone(selectedMilestone) {
    await this.setState({ selectedMilestone: selectedMilestone });
    // get latest data for tubemap for selected milestone
    //this.getLatestData();
  }

  openListOfProjectsModal() {
    this.setState({ showListOfProjectsModal: true }, this.bindEvent);
  }

  closeListOfProjectsModal() {
    this.setState({
      showListOfProjectsModal: false,
      listOfProjectsFilterData: this.state.listOfProjectsData,
    });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  toggleCloseConfirmationModal() {
    this.setState({ openCloseConfirmation: !this.state.openCloseConfirmation });
  }

  toggleDownload = () => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen });
  };

  componentDidMount() {
    if (window.innerWidth < 1000) {
      this.setState({ tablet: 2 });
    } else this.setState({ tablet: 3 });
    
    // get latest CSV date
    this.getLatestCSVDate();

    // tubemap settings for current month map
    sessionStorage.setItem("initialScale", 1.7);
    sessionStorage.setItem("initialTranslateX", 50);
    sessionStorage.setItem("initialTranslateY", 50);

    // tubemap settings for project map
    sessionStorage.setItem("initialScaleProjectMap", 1.7);
    sessionStorage.setItem("initialTranslateXProjectMap", 50);
    sessionStorage.setItem("initialTranslateYProjectMap", 50);

    this.getFiltersData();
    this.getCsvColumnsMapping();

    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    if (selectedFiltersData) {
      if (JSON.stringify(selectedFiltersData) != "{}") {
        this.setState({
          showKeepFiltersModal: true,
        });
      } else {
        this.getAllProjectsData();
        this.getLatestData();
        this.getTCQSData();
        this.getAllMonthsData();
        this.getProjectCount();
        this.getLineChartData();
        this.fetchProjectNames();
      }
    } else {
      this.getAllProjectsData();
      this.getLatestData();
      this.getTCQSData();
      this.getAllMonthsData();
      this.getProjectCount();
      this.getLineChartData();
      this.fetchProjectNames();
      sessionStorage.setItem("selectedFiltersData", JSON.stringify({}));
    }
  }

  getAllProjectsData() {
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getAllProjectsDataURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let allProjectsData = response.data.allProjectsData;
        let projectIdNameTotalProj = [];

        if (allProjectsData) {
          for (let i = 0; i < allProjectsData.length; i++) {
            projectIdNameTotalProj.push(
              allProjectsData[i]["Project Id"] +
                "(" +
                allProjectsData[i]["Project Name"] +
                ")"
            );
          }
        }
        this.setState({
          projectIdNameTotalProj: projectIdNameTotalProj,
          listOfProjectsData: allProjectsData,
          listOfProjectsFilterData: allProjectsData,
        });
      })
      .catch((error) => {
        console.log("error in projects data " + error);
      });
  }

  getProjectCount() {
    this.setState({ isLoading: true, totalProjectLoading: true });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getProjectCountURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        this.setState({
          totalProjects: data.projectCount,
          totalProjectLoading: false,
        });
      })
      .catch((error) => {
        console.log("error in getting project counts" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            totalProjectLoading: false,
          },
          this.updateDownloadButtonState
        );
      });
  }

  fetchProjectNames() {
    const filters = this.state.selectedFiltersData;
    this.setState({ isFetchProjectNamesLoading: true });
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.fetchProjectNamesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const projectNameIdMap = response.data.projectNameIdMap;
        this.setState({
          projectNameIdMap: projectNameIdMap,
          isFetchProjectNamesLoading: false,
        });
      })
      .catch((error) => {
        console.log("error in fetching project names");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isFetchProjectNamesLoading: false,
        });
      });
  }

  getLatestData() {
    this.setState({ isLoading: true, latestDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    const selectedMilestone = this.state.selectedMilestone;
    axios({
      url:
        this.state.getLatestDataRequestURL +
        filterQuery +
        "&selectedMilestone=" +
        selectedMilestone,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let res = response;
        let data = res.data.chartData;
        let activityList = res.data.activityList;
        let milestoneDbColName = res.data.milestoneDbColName;
        let milestoneProjectCount = res.data.milestoneProjectCount;
        let milestoneProjectsData = res.data.milestoneProjectsData;
        let projectIdName = [];

        if (selectedMilestone != 0) {
          activityList = res.data.activityList;
          milestoneProjectCount = res.data.milestoneProjectCount;
          milestoneDbColName = res.data.milestoneDbColName;
          milestoneProjectsData = res.data.milestoneProjectsData;

          if (milestoneProjectsData) {
            for (let k = 0; k < milestoneProjectsData.length; k++) {
              if (milestoneProjectsData[k]) {
                projectIdName.push(
                  milestoneProjectsData[k].projectId +
                    "(" +
                    milestoneProjectsData[k].projectName +
                    ")"
                );

                if (milestoneProjectsData[k].noOfDaysFloat == null) {
                  milestoneProjectsData[k].noOfDaysFloat = "Not available";
                }
              }
            }
          }
        }

        let currentYear = null,
          currentMonth = null,
          latestData = null;

        if (data && JSON.stringify(data) !== JSON.stringify({})) {
          // get current year
          currentYear = Object.keys(data)[0];
          // get current month
          currentMonth =
            currentYear != null ? Object.keys(data[currentYear])[0] : null;
          latestData =
            currentYear != null && currentMonth != null
              ? data[currentYear][currentMonth]
              : null;
        }

        this.setState(
          {
            currentMonth: currentMonth,
            currentYear: currentYear,
            latestData: latestData,
            isLoading: false,
            latestDataLoaded: true,
            activityList: activityList,
            milestoneDbColName: milestoneDbColName,
            milestoneProjectCount: milestoneProjectCount,
            milestoneProjectsData: milestoneProjectsData,
            projectIdName: projectIdName,
          },
          this.updateDownloadButtonState
        );
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching all projects data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
            latestDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getTCQSData() {
    this.setState({ tcqsDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getTCQSDataRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        // get current year
        let currentYear,
          currentMonth,
          latestData,
          greenDataSet,
          redDataSet,
          amberDataSet,
          chartData;
        if (
          response.data.chartData &&
          JSON.stringify(response.data.chartData) !== JSON.stringify({})
        ) {
          currentYear = Object.keys(response.data.chartData)[0];

          // get current month
          currentMonth = Object.keys(response.data.chartData[currentYear])[0];
          latestData = response.data.chartData[currentYear][currentMonth];

          // prepare chart data for time,cost,quality, h&s,scope and sme
          //DO NOT change sequence of vslues. It is tightly coupled with sequence of bars shown on UI.

          greenDataSet = [
            latestData.c_time ? latestData.c_time.G : 0,
            latestData.c_cost ? latestData.c_cost.G : 0,
            latestData.c_quality ? latestData.c_quality.G : 0,
            latestData.c_scope ? latestData.c_scope.G : 0,
            latestData.c_h_s ? latestData.c_h_s.G : 0,
            latestData.c_sme_self_assessments
              ? latestData.c_sme_self_assessments.G
              : 0,
          ];
          redDataSet = [
            latestData.c_time ? latestData.c_time.R : 0,
            latestData.c_cost ? latestData.c_cost.R : 0,
            latestData.c_quality ? latestData.c_quality.R : 0,
            latestData.c_scope ? latestData.c_scope.R : 0,
            latestData.c_h_s ? latestData.c_h_s.R : 0,
            latestData.c_sme_self_assessments
              ? latestData.c_sme_self_assessments.R
              : 0,
          ];
          amberDataSet = [
            latestData.c_time ? latestData.c_time.A : 0,
            latestData.c_cost ? latestData.c_cost.A : 0,
            latestData.c_quality ? latestData.c_quality.A : 0,
            latestData.c_scope ? latestData.c_scope.A : 0,
            latestData.c_h_s ? latestData.c_h_s.A : 0,
            latestData.c_sme_self_assessments
              ? latestData.c_sme_self_assessments.A
              : 0,
          ];
          chartData = { greenDataSet, redDataSet, amberDataSet };
        } else {
          currentYear = null;
          currentMonth = null;
          latestData = null;
          greenDataSet = null;
          redDataSet = null;
          amberDataSet = null;
          chartData = null;
        }
        this.setState(
          {
            currentMonth: currentMonth,
            currentYear: currentYear,
            chartData: chartData,
            isLoading: false,
            tcqsDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
        this.props.setMonth(currentMonth);
        this.props.setYear(currentYear);
      })
      .catch((error) => {
        console.log("error in fetching TCQS data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getLatestCSVDate() {
    axios({
      url: this.state.getLatestCSVDateURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        // get current year
        let csvDate = response.data.latestUploadDate
        csvDate = moment(csvDate).format('DD-MMM-YYYY, hh:mm a')
        this.setState(
          {
            latestCSVDate: csvDate.replace(",", ' at'),
          },
        );
      })
      .catch((error) => {
        console.log("error in fetching latest csv date" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            tcqsDataLoaded: true,
          },
        );
      });
  }

  getAllMonthsData() {
    this.setState({ isLoading: true, allMonthsDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getAllMonthsDataRequestURL + filterQuery + '&activityType=' + this.state.activityType,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        sessionStorage.setItem("MonthsActivityChartData-" + this.state.activityType, JSON.stringify(data));
        let years,
          months,
          chartMonthLables = [],
          activityRGMap = {},
          rVal,
          gVal,
          bVal,
          aVal,
          naVal,
          count = 0,
          latestRedActivityData = [],
          monthsChartData;
        const monthNames = {
          Jan: "1",
          Feb: "2",
          Mar: "3",
          Apr: "4",
          May: "5",
          Jun: "6",
          Jul: "7",
          Aug: "8",
          Sep: "9",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };
        const monthToYearMapping = {};
        let latestYear = true

        if (data && JSON.stringify(data) !== JSON.stringify({})) {
          // get current year
          years = Object.keys(data);
          if (years && JSON.stringify(years) !== JSON.stringify({})) {
            years.forEach((year) => {
              months = Object.keys(data[year]);
              // sort the months Jan to Dec order
              months = months.sort(function (a, b) {
                return monthNames[a] - monthNames[b];
              });
              months.forEach((month) => {
                chartMonthLables.push(month);
                monthToYearMapping[month] = year;
                count++;
                // prepare chart data for activities
                const activityDataArray = data[year][month];
                activityDataArray.forEach((activityObject) => {
                  Object.entries(activityObject).forEach((activityItem) => {
                    let activityName = activityItem[0];
                    if (activityRGMap[activityName] == null) {
                      activityRGMap[activityName] = {};
                    }
                    if (activityRGMap[activityName].gList == null) {
                      activityRGMap[activityName].gList = [];
                    }
                    if (activityRGMap[activityName].rList == null) {
                      activityRGMap[activityName].rList = [];
                    }
                    if (activityRGMap[activityName].bList == null) {
                      activityRGMap[activityName].bList = [];
                    }
                    if (activityRGMap[activityName].aList == null) {
                      activityRGMap[activityName].aList = [];
                    }
                    if (activityRGMap[activityName].naList == null) {
                      activityRGMap[activityName].naList = [];
                    }
                    rVal = activityItem[1].R;
                    gVal = activityItem[1].G;
                    bVal = activityItem[1].B;
                    aVal = activityItem[1].A;
                    naVal = activityItem[1].NA;
                    activityRGMap[activityName].rList.push(rVal ? rVal : "0"); //TODO
                    activityRGMap[activityName].gList.push(gVal ? gVal : "0"); //TODO
                    activityRGMap[activityName].bList.push(bVal ? bVal : "0"); //TODO
                    activityRGMap[activityName].aList.push(aVal ? aVal : "0");
                    activityRGMap[activityName].naList.push(naVal ? naVal : "0");
                    if (latestYear && count == months.length) {
                      // get latest month activities data to find out activity having maximum red. Activity with maximum red will be show first in the chart.
                      const redVal = rVal ? rVal : "0";
                      latestRedActivityData.push({
                        redVal: redVal,
                        activityName,
                      });
                    }
                  });
                });
              });
              latestYear = false;
            });
          }
          // sort by hightest value first
          //latestRedActivityData = latestRedActivityData.sort((a,b) => a.redVal - b.redVal)
          monthsChartData = {
            activityChartData: activityRGMap,
            chartMonthLables: chartMonthLables,
            latestRedActivityData: latestRedActivityData,
            monthToYearMapping: monthToYearMapping,
          };
        } else {
          monthsChartData = null;
        }
        this.setState(
          {
            isLoading: false,
            monthsChartData: monthsChartData,
            allMonthsDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      })
      .catch((error) => {
        console.log("error in fetching all projects(all months)data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
            allMonthsDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getGreenMilestoneData() {
    this.setState({ greenMilestoneDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.getGreenMilestoneDataRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const data = response.data.chartData;
        let greenMilestoneData = {};
        if (
          data !== null &&
          data !== undefined &&
          JSON.stringify(data) !== JSON.stringify({})
        ) {
          const years = Object.keys(data);
          const monthNames = {
            Jan: "1",
            Feb: "2",
            Mar: "3",
            Apr: "4",
            May: "5",
            Jun: "6",
            Jul: "7",
            Aug: "8",
            Sep: "9",
            Oct: "10",
            Nov: "11",
            Dec: "12",
          };
          if (years !== null && years !== undefined) {
            years.forEach((year) => {
              let months = Object.keys(data[year]);
              // sort the months Jan to Dec order
              months = months.sort(function (a, b) {
                return monthNames[a] - monthNames[b];
              });
              let monthData = data[year];
              months.forEach((month) => {
                if (monthData[month].T > 0) {
                  let totalValue =
                    ((monthData[month].G + monthData[month].B) /
                      monthData[month].T) *
                    100;
                  let greenValue =
                    (monthData[month].G / monthData[month].T) * 100;
                  let blueValue =
                    (monthData[month].B / monthData[month].T) * 100;
                  //Round the value till 2 digits
                  let finalTotalValue = Math.round(totalValue);
                  let finalGreenValue = Math.round(greenValue);
                  let finalBlueValue = Math.round(blueValue);
                  greenMilestoneData[month] = {
                    totalPercentage: finalTotalValue,
                    T: monthData[month].T,
                    G: finalGreenValue,
                    B: finalBlueValue,
                  };
                } else {
                  greenMilestoneData[month] = {
                    totalPercentage: 0,
                    T: monthData[month].T,
                    G: monthData[month].G,
                    B: monthData[month].B,
                  };
                }
              });
            });
          }
        }
        this.setState(
          {
            greenMilestoneData: greenMilestoneData,
            greenMilestoneDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      })
      .catch((error) => {
        console.log("error in fetching green milestone data" + error);
        let alert = ErrorHandler(error);
        this.setState(
          {
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            greenMilestoneDataLoaded: true,
          },
          this.updateDownloadButtonState
        );
      });
  }

  getAllLineChartData(trendType) {
    this.setState({ lineChartDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.getLineChartDataRequestURL +
        "all" +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data;
        let empObj = { totalDiff: 0, avgDiff: 0 };
        if (
          data.chartData &&
          JSON.stringify(data.chartData) != JSON.stringify({}) &&
          JSON.stringify(data.chartData) != JSON.stringify(empObj)
        ) {
          this.setState((prevState) => {
            let lineChartData = Object.assign({}, prevState.lineChartData);
            lineChartData["all"] = data;
            return { lineChartData };
          });
        }
      })
      .catch((error) => {
        console.log("error in fetching green milestone data 1" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getTop5LineChartData(trendType) {
    this.setState({ lineChartDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.getLineChartDataRequestURL +
        "top5" +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data;
        let empObj = { totalDiff: 0, avgDiff: 0 };
        if (
          data.chartData &&
          JSON.stringify(data.chartData) != JSON.stringify({}) &&
          JSON.stringify(data.chartData) != JSON.stringify(empObj)
        ) {
          this.setState((prevState) => {
            let lineChartData = Object.assign({}, prevState.lineChartData);
            lineChartData["top5"] = data;
            return { lineChartData };
          });
        }
      })
      .catch((error) => {
        console.log("error in fetching green milestone data 2" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getBottom5LineChartData(trendType) {
    this.setState({ lineChartDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.getLineChartDataRequestURL +
        "bottom5" +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data;
        let empObj = { totalDiff: 0, avgDiff: 0 };
        if (
          data.chartData &&
          JSON.stringify(data.chartData) != JSON.stringify({}) &&
          JSON.stringify(data.chartData) != JSON.stringify(empObj)
        ) {
          this.setState((prevState) => {
            let lineChartData = Object.assign({}, prevState.lineChartData);
            lineChartData["bottom5"] = data;
            return { lineChartData };
          });
        }
      })
      .catch((error) => {
        console.log("error in fetching green milestone data 3" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getAggregateLineChartData(trendType) {
    this.setState({ lineChartDataLoaded: false });
    const filters = this.state.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url:
        this.state.getLineChartDataRequestURL +
        "aggregate" +
        "?filters=" +
        filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        let data = response.data;
        let empObj = { totalDiff: 0, avgDiff: 0 };
        if (
          data.chartData &&
          JSON.stringify(data.chartData) != JSON.stringify({}) &&
          JSON.stringify(data.chartData) != JSON.stringify(empObj)
        ) {
          this.setState((prevState) => {
            let lineChartData = Object.assign({}, prevState.lineChartData);
            lineChartData["aggregate"] = data;
            return { lineChartData };
          });
        }
      })
      .catch((error) => {
        console.log("error in fetching green milestone data 4" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  async getLineChartData() {
    await this.getAllLineChartData();
    await this.getTop5LineChartData();
    await this.getBottom5LineChartData();
    await this.getAggregateLineChartData();

    this.setState(
      { lineChartDataLoaded: true },
      this.updateDownloadButtonState
    );
  }

  escapeFilterValue(filterJsonObject) {
    let encodedFilterJsonObject = {};
    Object.entries(filterJsonObject).forEach((filterItem) => {
      let encodedFilterValueArray = [];
      filterItem[1].forEach((filterValue) => {
        encodedFilterValueArray.push(encodeURIComponent(filterValue));
      });
      encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
    });
    return encodedFilterJsonObject;
  }

  updateDownloadButtonState() {
    const { latestData, chartData, monthsChartData, lineChartData } =
      this.state;

    this.setState({
      downloadEnabled:
        this.state.activeTab == "1"
          ? this.state.latestDataLoaded &&
            this.state.lineChartDataLoaded &&
            this.state.tcqsDataLoaded &&
            this.state.allMonthsDataLoaded &&
            !(
              (latestData == null ||
                JSON.stringify(latestData) === JSON.stringify({})) &&
              (lineChartData == null ||
                JSON.stringify(lineChartData) === JSON.stringify({})) &&
              (chartData == null ||
                JSON.stringify(chartData) === JSON.stringify({})) &&
              (monthsChartData == null ||
                JSON.stringify(monthsChartData) === JSON.stringify({}))
            )
          : this.state.projectMapDataLoaded &&
            !(
              this.state.singleProjectData == null ||
              JSON.stringify(this.state.singleProjectData) ===
                JSON.stringify({})
            ),
    });
  }

  updateProjectMapDataLoadStatus(status) {
    this.setState(
      { projectMapDataLoaded: status },
      this.updateDownloadButtonState
    );
  }

  updateSingleProjectMapData(singleProjectData) {
    this.setState({ singleProjectData: singleProjectData });
  }

  applyFilters() {
    // remove session data for Months Activity chart
    sessionStorage.removeItem("MonthsActivityChartData-R");
    sessionStorage.removeItem("MonthsActivityChartData-G");
    sessionStorage.removeItem("MonthsActivityChartData-B");
    sessionStorage.removeItem("MonthsActivityChartData-A");
    sessionStorage.removeItem("MonthsActivityChartData-NA");
    sessionStorage.removeItem("MonthsActivityChartData-All");

    this.closeFilterPaneOnApplying();
    this.getAllProjectsData();
    this.getLatestData();
    this.getTCQSData();
    this.getAllMonthsData();
    this.getLineChartData();
    this.getProjectCount();
    this.fetchProjectNames();
    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(this.state.selectedFiltersData)
    );
  }

  getFiltersData() {
    axios({
      url: this.state.getFiltersDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const filtersData = response.data.filtersData;
        this.setState({ filtersAPIData: filtersData });
      })
      .catch((error) => {
        console.log("error in fetching filters data" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  getCsvColumnsMapping() {
    axios({
      url: this.state.getCsvColumnsMappingRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const csvColumnMapping = response.data.csvColumnMapping;
        this.setState({ csvColumnMapping: csvColumnMapping });
      })
      .catch((error) => {
        console.log("error in fetching csv columns mapping data");
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  updateFilters(name, list) {
    // update values in selectedFiltersData
    let selectedFiltersData = this.state.selectedFiltersData;
    if (selectedFiltersData == null) {
      selectedFiltersData = {};
    }
    if (list == null || list.length == 0) {
      delete selectedFiltersData[name];
    } else {
      selectedFiltersData[name] = list;
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
  }

  removeFilter(filterName, filterVal) {
    // remove session data for Months Activity chart
    sessionStorage.removeItem("MonthsActivityChartData-R");
    sessionStorage.removeItem("MonthsActivityChartData-G");
    sessionStorage.removeItem("MonthsActivityChartData-B");
    sessionStorage.removeItem("MonthsActivityChartData-A");
    sessionStorage.removeItem("MonthsActivityChartData-NA");
    sessionStorage.removeItem("MonthsActivityChartData-All");
    // remove filter
    let selectedFiltersData = this.state.selectedFiltersData;
    let filterList = selectedFiltersData[filterName];
    selectedFiltersData[filterName] = filterList.filter((item) => {
      return item != filterVal;
    });
    if (
      selectedFiltersData[filterName] == null ||
      selectedFiltersData[filterName].length == 0
    ) {
      delete selectedFiltersData[filterName];
    }
    this.setState({ selectedFiltersData: selectedFiltersData });
    // update data
    this.getAllProjectsData();
    this.getLatestData();
    this.getTCQSData();
    this.getAllMonthsData();
    this.getProjectCount();
    this.getLineChartData();
    this.fetchProjectNames();
    sessionStorage.setItem(
      "selectedFiltersData",
      JSON.stringify(selectedFiltersData)
    );
  }

  closeFilterPaneOnApplying() {
    this.setState({ isFilterOpen: false });
  }

  toggleFilter() {
    const isFilterOpen = this.state.isFilterOpen;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  async clearFilters() {
    await this.setState({ selectedFiltersData: {} });
    this.applyFilters();
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab }, this.updateDownloadButtonState);
    }
  }

  downloadChartPdf(elementId, fileName) {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentonthMap").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialProjectMap").click();
    }
    setTimeout(() => {
      const input = document.getElementById(elementId);

      html2canvas(input, { scale: 5 }).then((canvas) => {
        var a = Object.keys(this.state.selectedFiltersData);
        var filter = [];
        for (let i = 0; i < a.length; i++) {
          for (
            let j = 0;
            j < this.state.selectedFiltersData[a[i]].length;
            j++
          ) {
            filter.push(this.state.selectedFiltersData[a[i]][j] + " ");
          }
        }
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("portrait", "pt", [
          canvas.width + 20,
          canvas.height + 20,
        ]);

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        pdf.setFontSize(width / 70);
        pdf.text(50, 150, "Current Performance");
        pdf.text(
          width / 3,
          150,
          this.state.activeTab == 1
            ? "All Programmes"
            : this.state.activeTab == 2
            ? this.state.selectedProject
            : ""
        );

        pdf.text(
          50,
          250,
          "Based on data uploaded for " + this.props.month + this.props.year
        );
        if (this.state.activeTab == 1) {
          pdf.setFontSize(width / 70);
          pdf.text(50, 350, "Total Projects: " + this.state.totalProjects);
        }

        if (filter.length > 0 && this.state.activeTab == 1) {
          pdf.text(50, 450, "Filters: " + filter.toString());
        }

        pdf.addImage(imgData, "JPEG", 20, 500, width, canvas.height - 500);
        pdf.save(fileName);
        this.setState({ pdfDownloading: false });
        // });
      });
    }, 1000);
  }

  downloadAllProgramsDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    let sheets = {};
    let sheetNames = [];
    if (this.state.latestData) {
      const ws1 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertPerformanceMilestoneData(
          this.state.latestData,
          this.state.csvColumnMapping
        )
      );
      sheets["PerformanceMilestone"] = ws1;
      sheetNames.push("PerformanceMilestone");
    }

    /*
    if (this.state.greenMilestoneData) {
      const ws2 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertGreenMilestoneData(
          this.state.greenMilestoneData
        )
      );
      sheets["MilestonePerfomanceTrend"] = ws2;
      sheetNames.push("MilestonePerfomanceTrend");
    }
    */

    if (this.state.lineChartData["all"]) {
      const ws2 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertLineChartData(
          this.formatLineChartData(this.state.lineChartData["all"])
        )
      );
      sheets["MilestonePerfomanceTrend"] = ws2;
      sheetNames.push("MilestonePerfomanceTrend");
    }

    if (this.state.chartData) {
      const ws3 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertTCQSData(this.state.chartData)
      );
      sheets["TCQS"] = ws3;
      sheetNames.push("TCQS");
    }

    if (this.state.monthsChartData) {
      const ws4 = XLSX.utils.json_to_sheet(
        CurrentStatusDataConverter.convertActivityPerformanceData(
          this.state.monthsChartData,
          this.state.csvColumnMapping
        )
      );
      sheets["ActivityPerformance"] = ws4;
      sheetNames.push("ActivityPerformance");
    }

    const wb = {
      Sheets: sheets,
      SheetNames: sheetNames,
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "CurrentStatus_AllProgramsChartsData.xlsx");
  };

  downloadProjectMapDataExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws1 = XLSX.utils.json_to_sheet(
      CurrentStatusDataConverter.convertProjectMapData(
        this.state.singleProjectData,
        this.state.csvColumnMapping
      )
    );

    const wb = {
      Sheets: {
        PerformanceMilestone: ws1,
      },
      SheetNames: ["PerformanceMilestone"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "CurrentStatus_ProjectMapData.xlsx");
  };

  downloadData = () => {
    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "CurrentStatus_AllProgramsCharts.pdf"
      );
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "searchByProjectDIv",
        "CurrentStatus_ProjectMapChart.pdf"
      );
      this.downloadProjectMapDataExcel();
    }
  };

  downloadExcelClicked = () => {
    if (this.state.activeTab == "1") {
      this.downloadAllProgramsDataExcel();
    } else if (this.state.activeTab == "2") {
      this.downloadProjectMapDataExcel();
    }
  };

  downloadPdfClicked = () => {
    if (this.state.activeTab == 1) {
      document.getElementById("zoomInitialCurrentonthMap").click();
    }
    if (this.state.activeTab == 2) {
      document.getElementById("zoomInitialProjectMap").click();
    }
    this.setState({ pdfDownloading: true });
    if (this.state.activeTab == "1") {
      this.downloadChartPdf(
        "allprogramscontentdiv",
        "CurrentStatus_AllProgramsCharts.pdf"
      );
    } else if (this.state.activeTab == "2") {
      this.downloadChartPdf(
        "searchByProjectDIv",
        "CurrentStatus_ProjectMapChart.pdf"
      );
    }
  };

  closeFilter() {
    this.clearFilters();
    this.setState({
      showMatchingProjects: false,
      totalProjectLoading: true,
      openCloseConfirmation: false,
    });
  }

  noFilterSelectedCloseFilter() {
    this.toggleFilter();
    this.setState({
      openCloseConfirmation: false,
    });
  }

  closeFilterConfirmation() {
    this.setState({ openCloseConfirmation: true });
  }

  callbackFunction = (childData) => {
    console.log(childData);
    this.setState({ selectedProject: childData });
  };

  setSelectedActivityLineChart = (activity) => {
    this.setState({ selectedActivityLineChart: activity });
  };

  searchByProject(event) {
    let value = event.target.value.split("(")[0];
    let listOfProjectsFilterData = [];
    let dataArray = [];
    if (value === null || value === "") {
      this.setState({
        listOfProjectsFilterData: this.state.listOfProjectsData,
      });
    } else {
      this.state.listOfProjectsData.map((data) => {
        dataArray.push(data);
        if (
          data["Project Id"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          listOfProjectsFilterData.push(data);
        }
        if (dataArray.length === this.state.listOfProjectsData.length) {
          this.setState({
            listOfProjectsFilterData: listOfProjectsFilterData,
          });
        }
      });
    }
  }

  searchByProject2(event, val) {
    let value = val.split("(")[0];
    let listOfProjectsFilterData = [];
    let dataArray = [];
    if (value === null || value === "") {
      this.setState({
        listOfProjectsFilterData: this.state.listOfProjectsData,
      });
    } else {
      this.state.listOfProjectsData.map((data) => {
        dataArray.push(data);
        if (
          data["Project Id"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          listOfProjectsFilterData.push(data);
        }
        if (dataArray.length === this.state.listOfProjectsData.length) {
          this.setState({
            listOfProjectsFilterData: listOfProjectsFilterData,
          });
        }
      });
    }
  }

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A2"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let title = "List Of Selected Projects In Current Performance";

    let headers = [];
    let arr = [];
    for (
      let k = 0;
      k < Object.entries(this.state.listOfProjectsData[0]).length;
      k++
    ) {
      arr.push(Object.entries(this.state.listOfProjectsData[0])[k][0]);
    }
    headers.push(arr);

    let data = [];
    for (let j = 0; j < this.state.listOfProjectsData.length; j++) {
      let arr = [];
      for (
        let k = 0;
        k < Object.entries(this.state.listOfProjectsData[j]).length;
        k++
      ) {
        arr.push(Object.entries(this.state.listOfProjectsData[j])[k][1]);
      }
      data.push(arr);
    }

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "Correct") {
          data.cell.styles.textColor = [45, 197, 147];
        }

        if (data.cell.raw == "Incorrect") {
          data.cell.styles.textColor = "#E45266";
        }

        if (data.cell.raw == "Pending") {
          data.cell.styles.textColor = "#919BA3";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("List_Of_Selected_Projects_In_Current_Performance.pdf");
  };

  formatLineChartData(data) {
    if (data) {
      let chartData = data.chartData;
      let formattedData = {};
      if (chartData && this.state.csvColumnMapping) {
        Object.entries(chartData).forEach((series, seriesindex) => {
          if (series[0] != "totalDiff" && series[0] != "avgDiff") {
            formattedData[this.state.csvColumnMapping[series[0]]] = {};
            Object.entries(series[1]).forEach((yearItem, index) => {
              Object.entries(yearItem[1]).forEach((monthItem, index) => {
                let key = monthItem[0] + "'" + String(yearItem[0]).substr(2);
                formattedData[this.state.csvColumnMapping[series[0]]][key] =
                  Math.round(
                    (((monthItem[1]["G"] + monthItem[1]["B"]) * 100) /
                      monthItem[1]["T"]) *
                      100
                  ) / 100;
              });
            });
          }
        });
      }
      return formattedData;
    }
  }

  keepFilters() {
    let selectedFiltersData = JSON.parse(
      sessionStorage.getItem("selectedFiltersData")
    );

    this.setState(
      {
        selectedFiltersData: selectedFiltersData,
        showKeepFiltersModal: false,
        showMatchingProjects: true,
        totalProjectLoading: true,
      },
      this.applyFilters
    );
  }

  closeKeepFiltersModal() {
    this.getAllProjectsData();
    this.getLatestData();
    this.getTCQSData();
    this.getAllMonthsData();
    this.getProjectCount();
    this.getLineChartData();
    this.fetchProjectNames();
    this.setState({ showKeepFiltersModal: false, totalProjectLoading: true });
    sessionStorage.setItem("selectedFiltersData", "{}");
  }

  bindEvent() {
    setTimeout(() => {
      if (document.querySelector(".table-scroll-modal"))
        document
          .querySelector(".table-scroll-modal")
          .addEventListener("scroll", () => {
            let maxWidth = parseInt(
              window
                .getComputedStyle(document.querySelector("thead"))
                .width.replace("px", "")
            );
            let computedWidth =
              parseInt(
                window
                  .getComputedStyle(
                    document.querySelector(".table-scroll-modal")
                  )
                  .width.replace("px", "")
              ) +
              document.querySelector(".table-scroll-modal").scrollLeft +
              1;
            if (computedWidth > maxWidth) {
              computedWidth = maxWidth;
            }
            document.querySelectorAll("table > *")[1].style.width =
              computedWidth + "px";
            document.querySelectorAll("table > *")[0].style.width =
              computedWidth + "px";
          });
    }, 5);
  }

  render() {
    const {
      activeTab,
      latestData,
      isFilterOpen,
      chartData,
      monthsChartData,
      csvColumnMapping,
      filtersAPIData,
      latestDataLoaded,
      tcqsDataLoaded,
      allMonthsDataLoaded,
      lineChartData,
      lineChartDataLoaded,
      activityList,
      milestoneDbColName,
      milestoneProjectCount,
      projectIdName,
      projectIdNameTotalProj,
    } = this.state;

    let uiFilterData, filterSection;
    let filtersData = filtersAPIData;

    if (filtersData) {
      const filterNames = Object.keys(filtersData);
      uiFilterData = filterNames.map((item, index) => {
        const filterName = item;
        const filterValues = filtersData[filterName].filter((itm) => {
          return itm != null && itm.trim() != "";
        });
        /*
        const filterValues = filtersData[filterName].map((itm, idx) => {
          return itm == "" ? "(Blanks)" : itm;
        });
        */
        return (
          <span
            key={`span${index}`}
            style={{
              float: index % 2 === 0 ? "left" : "right",
            }}
          >
            <Filter
              key={index}
              filterValues={filterValues}
              csvColumnMapping={csvColumnMapping}
              filterData={this.state.selectedFiltersData}
              filterName={filterName}
              setSelectedOptions={(name, list) =>
                this.updateFilters(name, list)
              }
            ></Filter>
          </span>
        );
      });
    }
    if (uiFilterData) {
      filterSection = <div>{uiFilterData}</div>;
    }
    const filters = this.state.selectedFiltersData;
    let filterSummaryData, list, singleFilter;
    let test = [];
    let filtersArray = [];
    Object.entries(filters).forEach((filterItem) => {
      filterItem[1].forEach((filter) => {
        filtersArray.push({
          filterCategory: filterItem[0],
          filter: filter,
        });
      });
    });

    const getSingleFilterObject = (length, filterCategory, filter) => {
      return (
        <BootstrapTooltip title={filter}>
          <span>
            <div
              className="filter-summary-inner"
              style={
                length > this.state.tablet - 1
                  ? { display: "none" }
                  : { display: "inline" }
              }
            >
              {filter.length < 15 ? filter : filter.substr(0, 14) + "..."}{" "}
              <FaTimes
                className="filter-delete"
                onClick={() => this.removeFilter(filterCategory, filter)}
              ></FaTimes>
            </div>
          </span>
        </BootstrapTooltip>
      );
    };

    let individualFilterArray = [];
    for (let i = 0; i < filtersArray.length; i++) {
      if (filtersArray[i]) {
        individualFilterArray.push(
          getSingleFilterObject(
            i,
            filtersArray[i].filterCategory,
            filtersArray[i].filter
          )
        );
      }
    }

    // if (Object.keys(filters).length != 0) {
    //   filterSummaryData = Object.keys(filters).map((filterName, index) => {
    //     let filtersKeys = Object.keys(filters);
    //     this.state.countFilter = 0;
    //     for(let i=0;i<filtersKeys.length;i++)
    //     {
    //       this.state.countFilter = this.state.countFilter + filters[filtersKeys[i]].length;
    //     }
    //     list = filters[filterName];
    //     test=[];
    //     singleFilter = list.map((filterVal, idx) => {
    //       return (
    //         // style={this.state.test.length>3?{display:'none'}:{display:'inline'}}
    //         <div  className="filter-summary-inner">
    //           {filterVal}{" "}
    //           <FaTimes
    //             className="filter-delete"
    //             onClick={() => this.removeFilter(filterName, filterVal)}
    //           ></FaTimes>
    //         </div>
    //       );
    //     });
    //     if (this.state.countFilter != 0 ) {
    //       return (
    //         <div id='summary-outer' style={{'display':'inline'}} className="filter-summary-outer"  >
    //           {/* {csvColumnMapping[filterName]}: for filter section */}
    //           {singleFilter }
    //           {/* {this.state.countFilter} */}
    //           {/* <div className="filter-summary-inner" style={test.length<=4?{display:'none'}:{display:'inline'}}>
    //           {test.length>4? test.length-4 + ' +':''}
    //             </div> */}
    //         </div>
    //       );
    //     }
    //   });
    // }

    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }
    return (
      <React.Fragment>
        {this.state.showListOfProjectsModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={this.closeListOfProjectsModal}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col>
                    <center>
                      <span
                        id="key-tasks-to-text"
                        style={{ color: "#252525", fontSize: "1rem" }}
                      >
                        List Of Selected Projects
                      </span>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper">
                        <Autocomplete
                          options={this.state.projectIdNameTotalProj}
                          onInputChange={this.searchByProject2}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Project"
                            />
                          )}
                          size="small"
                        />
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="table-fixed-start-columns">
                        <Table
                          responsive
                          className="current-vs-forecasted table table-md table-custom table-list-of-forecasted-projects table-scroll-modal table-modal"
                          id="table-task-list"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <thead className="table-head">
                            {this.state.listOfProjectsFilterData &&
                            JSON.stringify(
                              this.state.listOfProjectsFilterData
                            ) !== JSON.stringify([]) ? (
                              this.state.listOfProjectsFilterData.length >=
                              1 ? (
                                <tr>
                                  {Object.entries(
                                    this.state.listOfProjectsFilterData[0]
                                  ).map((item, index) => (
                                    <th
                                      key={index}
                                      className={
                                        item[0] == "Project Id" ||
                                        item[0] == "Project Name"
                                          ? item[0] == "Project Id"
                                            ? "project-id-col"
                                            : ""
                                          : item[0] ==
                                              "Status of Last Milestone Achieved" ||
                                            item[0] == "Recommendation Status"
                                          ? "centre-align-data padding-data-col"
                                          : "padding-data-col"
                                      }
                                      style={{
                                        width:
                                          item[0] == "Project Id" ? "6vw" : "",
                                      }}
                                    >
                                      {item[0]}
                                    </th>
                                  ))}
                                </tr>
                              ) : (
                                <tr>
                                  <th className="project-id-col">Project Id</th>
                                  <th>Project Name</th>
                                  <th>Last Milestone Achieved</th>
                                </tr>
                              )
                            ) : (
                              <tr>
                                <th className="project-id-col">Project Id</th>
                                <th>Project Name</th>
                                <th>Last Milestone Achieved</th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {this.state.listOfProjectsFilterData &&
                            JSON.stringify(
                              this.state.listOfProjectsFilterData
                            ) !== JSON.stringify([]) ? (
                              this.state.listOfProjectsFilterData.length >=
                              1 ? (
                                this.state.listOfProjectsFilterData.map(
                                  (activityObject, index) => (
                                    <tr key={index}>
                                      {activityObject
                                        ? Object.entries(activityObject).map(
                                            (item) => (
                                              <td
                                                className={
                                                  item[0] == "Project Id" ||
                                                  item[0] == "Project Name"
                                                    ? item[0] == "Project Id"
                                                      ? "project-id-col table-data-bold"
                                                      : "table-data-bold"
                                                    : item[0] ==
                                                        "Status of Last Milestone Achieved" ||
                                                      item[0] ==
                                                        "Recommendation Status"
                                                    ? "centre-align-data padding-data-col"
                                                    : "table-data-black padding-data-col"
                                                }
                                                style={{
                                                  width:
                                                    item[0] == "Project Id"
                                                      ? "6vw"
                                                      : "",
                                                }}
                                              >
                                                {item[0] ==
                                                  "Status of Last Milestone Achieved" ||
                                                item[0] ==
                                                  "Recommendation Status" ? (
                                                  item[1] ? (
                                                    item[1] != "NA" ? (
                                                      <div
                                                        className={
                                                          item[1] === "R"
                                                            ? "red-circle"
                                                            : item[1] === "GR"
                                                            ? "grey-circle"
                                                            : item[1] === "A"
                                                            ? "amber-circle"
                                                            : item[1] === "B"
                                                            ? "blue-circle"
                                                            : "green-circle"
                                                        }
                                                      ></div>
                                                    ) : (
                                                      "-"
                                                    )
                                                  ) : (
                                                    "-"
                                                  )
                                                ) : (
                                                  <span>{item[1]}</span>
                                                )}
                                              </td>
                                            )
                                          )
                                        : ""}
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr></tr>
                              )
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                        {this.state.listOfProjectsData &&
                        JSON.stringify(this.state.listOfProjectsData) !==
                          JSON.stringify([]) ? (
                          <div></div>
                        ) : (
                          <span>
                            <div
                              id="current-month-forecast-modal-no-data"
                              className="no-data-text-modal"
                            >
                              No data available for list of projects
                            </div>
                          </span>
                        )}
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <Button
                        color="primary"
                        onClick={this.downloadTablePdf}
                        size="sm"
                        id="download-list-key-actvity"
                        disabled={
                          this.state.listOfProjectsData &&
                          JSON.stringify(this.state.listOfProjectsData) !==
                            JSON.stringify([])
                            ? false
                            : true
                        }
                      >
                        {this.state.isListOfProjectsDataDownloading ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>{" "}
                            Downloading...
                          </div>
                        ) : (
                          <div>
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </div>
                        )}
                      </Button>
                    </center>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {this.state.showKeepFiltersModal === true ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Retain Filters</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div>Would you like to retain the filters applied?</div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                id="yes-no-button"
                onClick={this.keepFilters}
              >
                YES
              </Button>
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeKeepFiltersModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        {this.state.openCloseConfirmation === true ? (
          <Modal
            isOpen="true"
            centered="true"
            id="confirmation-modal"
            size="sm"
          >
            <ModalHeader id="confirmation-modal-head" size="sm">
              <div id="delete-confirmation-text">Close Filter Pane</div>
            </ModalHeader>
            <ModalBody size="sm" id="confirmation-modal-body">
              <div>Are you sure to close the filter pane?</div>
            </ModalBody>
            <ModalFooter id="confirmation-modal-footer" size="sm">
              <Button
                color="primary"
                size="sm"
                id="yes-no-button"
                onClick={this.closeFilter}
              >
                YES
              </Button>
              <Button
                color="secondary"
                size="sm"
                id="yes-no-button"
                onClick={this.toggleCloseConfirmationModal}
                active={true}
              >
                NO
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        <Header
          id="currhead"
          open={this.props.open}
          setOpen={this.props.setOpen}
        >
          <Row>
            <Col xs={4}>
              <div className="currentClass">Current Performance</div>
              <div className="basedClass">
                <span className="year-month-dashboard">
                  {GenericConstants.MONTH_MAPPING[this.props.month]
                    ? GenericConstants.MONTH_MAPPING[this.props.month]
                    : this.props.month}{" "}
                  {this.props.year}
                </span>
                {this.props.month ? " snapshot" : ""}, <span style={{color:'black'}}>Uploaded On:</span> {this.state.latestCSVDate}
                <br />
                <span style={{ float: "left" }}>{"Live Projects Only"}</span>
              </div>
            </Col>
            <Col xs={5}>
              <Nav tabs className="justify-content-center" size="sm">
                <NavItem>
                  <NavLink
                    id="nav-link-all-programs-search-project"
                    className={classnames({
                      activeTab: activeTab === "1",
                      "nav-label": true,
                    })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    All Programmes
                  </NavLink>
                  <div
                    className="triangle-outer"
                    style={{ display: activeTab === "1" ? "block" : "none" }}
                  >
                    <div className="triangle-inner"></div>
                  </div>
                </NavItem>
                &nbsp;&nbsp;&nbsp;
                <NavItem>
                  <NavLink
                    id="nav-link-all-programs-search-project"
                    className={classnames({
                      activeTab: activeTab === "2",
                      "nav-label": true,
                    })}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Search By Project
                  </NavLink>
                  <div
                    className="triangle-outer"
                    style={{
                      display: activeTab === "2" ? "block" : "none",
                      marginLeft: "1.1rem",
                    }}
                  >
                    <div
                      className="triangle-inner"
                      style={{ marginLeft: "1.1rem" }}
                    ></div>
                  </div>
                </NavItem>
              </Nav>
            </Col>
            <Col xs={3}>
              <ButtonDropdown
                isOpen={this.state.isDownloadOpen}
                toggle={this.toggleDownload}
                color="primary"
                className="button-dropdown-dashboard"
              >
                <DropdownToggle
                  caret
                  size="sm"
                  color="primary"
                  disabled={
                    !this.state.downloadEnabled || this.state.pdfDownloading
                      ? true
                      : false
                  }
                >
                  {this.state.pdfDownloading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span> Downloading...
                    </span>
                  ) : (
                    <span>
                      <GetAppIcon fontSize="small" />
                      <span className="download-text-dashboard"> Download</span>
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu size="sm">
                  <DropdownItem
                    onClick={this.downloadPdfClicked}
                    className="dropdown-item-dashboard"
                    size="sm"
                  >
                    Pdf
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadExcelClicked}
                  >
                    Xlsx
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item-dashboard"
                    size="sm"
                    onClick={this.downloadData}
                  >
                    Both
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </Header>
        <div>
          {this.state.showAlert ? (
            <AutoCloseAlert
              color={this.state.alertcolor}
              body={this.state.alertMsg}
              showAlert={this.state.showAlert}
              setShowAlert={this.setShowAlert}
            />
          ) : (
            ""
          )}
          <TabContent activeTab={activeTab} style={{ marginTop: "-14px" }}>
            <TabPane tabId="1">
              <Row>
                <Col xs={2} md={2}>
                  <Row>
                    <Col xs={12} md={12} className="total-label">
                      {this.state.totalProjects &&
                      !(
                        this.state.totalProjectLoading ||
                        !latestDataLoaded ||
                        !lineChartDataLoaded ||
                        !tcqsDataLoaded ||
                        !allMonthsDataLoaded
                      ) ? (
                        <BootstrapTooltip title="Click here to see the list of projects">
                          <a
                            onClick={this.openListOfProjectsModal}
                            className="list-of-proj-hyperlink"
                          >
                            <span style={{ color: "black" }}>
                              {individualFilterArray.length !== 0 &&
                              this.state.showMatchingProjects === true
                                ? "Matching Projects"
                                : "Total Projects"}
                            </span>
                          </a>
                        </BootstrapTooltip>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12} md={12} className="project-count">
                      {this.state.totalProjectLoading ||
                      !latestDataLoaded ||
                      !lineChartDataLoaded ||
                      !tcqsDataLoaded ||
                      !allMonthsDataLoaded ? (
                        <div style={{ color: "#268cd1" }}>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <div>
                          <BootstrapTooltip title="Click here to see the list of projects">
                            <a
                              onClick={this.openListOfProjectsModal}
                              className="list-of-proj-hyperlink"
                            >
                              <span>
                                <u>{this.state.totalProjects}</u>
                              </span>
                            </a>
                          </BootstrapTooltip>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} md={5}>
                  {/* {individualFilterArray} */}
                  {
                    <div
                      className="filter-summary"
                      style={{
                        width: "auto",
                        background: "none",
                        marginBottom: "0px",
                      }}
                    >
                      <div className="filter-summary-title">
                        {/* Applied Filters */}
                      </div>
                      {/* {filterSummaryData} */}
                      {individualFilterArray}
                      <div
                        className="filter-summary-inner"
                        style={
                          individualFilterArray.length <= this.state.tablet
                            ? { display: "none" }
                            : { display: "inline" }
                        }
                      >
                        {individualFilterArray.length > this.state.tablet
                          ? individualFilterArray.length -
                            this.state.tablet +
                            " +"
                          : ""}
                      </div>
                    </div>
                  }
                </Col>
                <Col xs={3} md={3}>
                  {/*
                  {latestData != null && (
                    <Button
                      color="primary"
                      onClick={this.openListOfProjectsModal}
                      size="sm"
                      className="list-of-proj-dashboard"
                    >
                      List Of Projects
                    </Button>
                  )}
                  */}
                </Col>
                <Col xs={2} md={2}>
                  {latestData != null && (
                    <div style={{ position: "relative" }}>
                      <div className="filter">
                        <CardHeader className="filter-header">
                          <span onClick={this.toggleFilter}>
                            <b>All Filters </b>
                            <span style={{ color: "#BD2967" }}>
                              {!isFilterOpen && <FaAngleDown></FaAngleDown>}{" "}
                              {isFilterOpen && <FaAngleUp></FaAngleUp>}
                            </span>
                          </span>
                        </CardHeader>
                        <Collapse
                          isOpen={isFilterOpen}
                          className="filter-main"
                          timeout={0}
                        >
                          <Row>
                            <Col>
                              <CancelIcon
                                className="cancel-close-icon"
                                onClick={
                                  individualFilterArray.length !== 0
                                    ? this.closeFilterConfirmation
                                    : this.noFilterSelectedCloseFilter
                                }
                                id="cancel-filter"
                                fontSize="small"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {filterSection && (
                                <div className="filter-div">
                                  {filterSection}
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="filter-buttons">
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.clearFilters();
                                    this.setState({
                                      showMatchingProjects: false,
                                      totalProjectLoading: true,
                                    });
                                  }}
                                  outline
                                >
                                  Reset All
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.applyFilters();
                                    this.setState({
                                      showMatchingProjects: true,
                                      totalProjectLoading: true,
                                    });
                                  }}
                                >
                                  Apply Filters
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Collapse>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row id="allprogramscontentdiv">
                <Col>
                  {/*
                  {(!latestDataLoaded ||
                    !greenMilestoneDataLoaded ||
                    !tcqsDataLoaded ||
                    !allMonthsDataLoaded) && (
                    <div>
                      <PageSpinner />
                    </div>
                    )}
                    */}
                  {latestDataLoaded &&
                    latestData &&
                    JSON.stringify(latestData) !== JSON.stringify({}) && (
                      <CurrentMonthMap
                        mapData={latestData}
                        activityList={activityList}
                        milestoneDbColName={milestoneDbColName}
                        milestoneProjectCount={milestoneProjectCount}
                        selectedFiltersData={this.state.selectedFiltersData}
                        selectedMilestone={this.state.selectedMilestone}
                        updateSelectedMilestone={(selectedMilestone) =>
                          this.updateSelectedMilestone(selectedMilestone)
                        }
                        milestoneProjectsData={this.state.milestoneProjectsData}
                        projectIdName={projectIdName}
                      ></CurrentMonthMap>
                    )}
                  {latestDataLoaded && lineChartDataLoaded && lineChartData
                    ? lineChartData.length != 0 &&
                      JSON.stringify(lineChartData) !== JSON.stringify({}) && (
                        <LineChart
                          chartData={lineChartData}
                          csvColumnMapping={csvColumnMapping}
                        ></LineChart>
                      )
                    : ""}
                  {latestDataLoaded &&
                    tcqsDataLoaded &&
                    chartData &&
                    csvColumnMapping &&
                    JSON.stringify(chartData) !== JSON.stringify({}) &&
                    JSON.stringify(csvColumnMapping) !== JSON.stringify({}) && (
                      <CurrentMonthPieChart
                        chartData={chartData}
                        csvColumnMapping={csvColumnMapping}
                        filterData={this.state.selectedFiltersData}
                      ></CurrentMonthPieChart>
                    )}
                  {latestDataLoaded &&
                    allMonthsDataLoaded &&
                    monthsChartData &&
                    monthsChartData.chartMonthLables.length != 0 &&
                    csvColumnMapping &&
                    JSON.stringify(monthsChartData) !== JSON.stringify({}) &&
                    JSON.stringify(csvColumnMapping) !== JSON.stringify({}) && (
                      <MonthsActivityChart
                        monthsChartData={monthsChartData}
                        csvColumnMapping={csvColumnMapping}
                        filterData={this.state.selectedFiltersData}
                        currentStatusPage={true}
                        selectedActivityType={this.state.activityType}
                      ></MonthsActivityChart>
                    )}
                  {latestDataLoaded &&
                    lineChartDataLoaded &&
                    tcqsDataLoaded &&
                    allMonthsDataLoaded &&
                    (latestData == null ||
                      JSON.stringify(latestData) === JSON.stringify({})) &&
                    (lineChartData == null ||
                      JSON.stringify(lineChartData) === JSON.stringify({})) &&
                    (chartData == null ||
                      JSON.stringify(chartData) === JSON.stringify({})) &&
                    (monthsChartData == null ||
                      JSON.stringify(monthsChartData) ===
                        JSON.stringify({})) && (
                      <div className="no-data-available-dashboard">
                        No project data available for dashboard. Please upload
                        data to view dashboard
                      </div>
                    )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <ProjectMap
                    updateProjectMapDataLoadStatus={
                      this.updateProjectMapDataLoadStatus
                    }
                    parentCallback={this.callbackFunction}
                    updateSingleProjectMapData={this.updateSingleProjectMapData}
                    selectedFiltersData={this.state.selectedFiltersData}
                    projectNameIdMap={this.state.projectNameIdMap}
                    isFetchProjectNamesLoading={
                      this.state.isFetchProjectNamesLoading
                    }
                    csvColumnMapping={this.state.csvColumnMapping}
                    projectsDataDefaultVal={this.state.projectsDataDefaultVal}
                    qualityParamDefaultVal={this.state.qualityParamDefaultVal}
                  ></ProjectMap>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </React.Fragment>
    );
  }
}
export default CurrentStatusPage;
