import React, { Component } from "react";
import * as d3 from "d3";
import axios from "axios";
import { MdRemove, MdAdd, MdSearch, MdInfo, MdUndo } from "react-icons/md";
import { Row, Col, Button } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Slider } from "@material-ui/core";
import { Modal, ModalBody, Spinner, Table } from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import chroma from "chroma-js";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { Redirect } from "react-router-dom";

import PageSpinner from "../../../PageSpinner";
import map from "../../../../utils/tubemap/map";
import * as data from "../../../../utils/tubemap/activities.json";
import "../../../../styles/components/Chart.scss";
import { ErrorHandler } from "../../../ErrorHandler";
import AutoCloseAlert from "../../../AutoCloseAlert";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import SingleProjectHealthScoreTrendChart from "../../summary/charts/SingleProjectHealthScoreTrendChart";
import SingleProjectActivities from "../../summary/SingleProjectActivities";
import ProjectTCQIChartSingleMonth from "../charts/ProjectTCQIChartSingleMonth"
import SingleProjectTcqiActivities from "../SingleProjectTcqiActivities"

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

const colourOptions = [
  { value: "A", label: "A", color: "#eb7b15" },
  { value: "R", label: "R", color: "#FF5630" },
  { value: "G", label: "G", color: "#36B37E" },
  { value: "B", label: "B", color: "#0052CC" },
  { value: "GR", label: "GR", color: "grey" },
  { value: "NA", label: "NA", color: "black" },
];

class ProjectMap extends Component {
  bgColors = ["#96e079", "#E45266", "#eb7b15"];
  constructor(props) {
    super(props);
    this.state = {
      fetchProjectNamesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-names?filters=`,
      fetchProjectDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-project/`,
      fetchMonthlyChangesRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-monthly-changes/`,
      getSingleProjectHealthURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-health-trend/`,
      getSingleProjectActivitiesURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-important-activities/`,
      getSingleProjectActivitiesMonthlyURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-important-activities-monthly/`,
      getSingleProjectHealthSelectedMonthURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/project-health-score-monthly/`,
      selectedSliderData: null,
      sliderValues: null,
      sliderSelectedValue: 0,
      sliderMax: 0,
      sliderSize: 0,
      sliderOptionList: null,
      projectAllData: null,
      selectedMonthData: null,
      allData: null,
      zoomSliderVal: 2,
      projectName: null,
      projectNameIdMap: null,
      showMap: null,
      isLoading: null,
      isActivitiesVisible: true,
      isMilestoneVisible: true,
      singleProjectData: null,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      isZoom: false,
      selectedStatusValue: {
        lastMonthStatus: [],
        currentMonthStatus: [],
      },
      searchSelection: true,
      defaultProjectVal: null,
      defaultValueChecked: false,
      showMonthlyTCQIChart: false,
      showTCQIActivitiesTable: false,
      montlyTCQIData: null,
      selectedYear: null,
      selectedMonth: null,
      selectedMeasure: null,
      selectedMonthHealthScore: null,
      milestoneToAchieve: 0,
      projectTcqiActivities: null,
      measureHealthScore: 'NA',
      healthScoreButtonColor: null,
      timeButtonColor: null,
      costButtonColor: null,
      qualityButtonColor: null,
      scopeButtonColor: null,
      hsButtonColor: null,
      smeButtonColor: null,
      tcqiButtonColor: null,
      tcqiStatusTextColor: null,
      tcqiStatusButtonVisible: false,
      fy22tcqi: null,
      tcqiStatus: null,
      showTcqiStatusModal: false,
      isMonthlyChangeLoading: false,
      goToForecastedPerformance: false,
      showLivesLostModal: false,
      livesLostButtonColor: null
    };
    this.props.updateProjectMapDataLoadStatus(false);
    this.showTubeMap = this.showTubeMap.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.changeMapZoom = this.changeMapZoom.bind(this);
    this.fetchProjectNames = this.fetchProjectNames.bind(this);
    this.fetchProjectData = this.fetchProjectData.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
    this.updateSliderValue = this.updateSliderValue.bind(this);
    this.toggleActivities = this.toggleActivities.bind(this);
    this.toggleMilestones = this.toggleMilestones.bind(this);
    this.updateDataToShow = this.updateDataToShow.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.escapeFilterValue = this.escapeFilterValue.bind(this);
    this.fetchMonthlyChanges = this.fetchMonthlyChanges.bind(this);
    this.searchByActivity = this.searchByActivity.bind(this);
    this.downloadTablePdf = this.downloadTablePdf.bind(this);
    this.applyBRAGFilters = this.applyBRAGFilters.bind(this);
    //this.onRecommendationSelect = this.onRecommendationSelect.bind(this);
    this.getSingleProjectHealthScore =
      this.getSingleProjectHealthScore.bind(this);
    this.fetchProjectHealthScoreSelectedMonth = this.fetchProjectHealthScoreSelectedMonth.bind(this);
    this.showTcqiHealthScoreModel = this.showTcqiHealthScoreModel.bind(this);
    this.closeTcqiHealthScoreModel = this.closeTcqiHealthScoreModel.bind(this);
    this.closeTcqiActivitiesTableModal = this.closeTcqiActivitiesTableModal.bind(this);
    this.showTcqiActivitiesTableModal = this.showTcqiActivitiesTableModal.bind(this);
    this.showTcqiStatusData = this.showTcqiStatusData.bind(this);
    this.closeTcqiStatusModal = this.closeTcqiStatusModal.bind(this);
    this.handleForecastedPerformance = this.handleForecastedPerformance.bind(this);
    this.showLivesLostData = this.showLivesLostData.bind(this);
    this.closeLivesLostModal = this.closeLivesLostModal.bind(this);
  }

  handleForecastedPerformance() {
    this.setState({
      goToForecastedPerformance: true
    });
  }

  getSingleProjectHealthScore() {
    //this.setState({ isLoading: true });
    const projectId = this.props.projectNameIdMap[this.state.projectName];
    axios({
      url: this.state.getSingleProjectHealthURL + projectId,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;

        this.setState({
          isLoading: false,
          projectCurrentHealthData: data.projectHealthScoreData.current,
          projectForecastedHealthData: data.projectHealthScoreData.forecasted,
        });
      })
      .catch((error) => {
        console.log("error in getting project health score" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
        });
      });
  }

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  componentDidMount() { }

  setProjectName(projectName, fetchData = false) {
    this.setState({ projectName: projectName }, () => {
      if (fetchData) {
        this.fetchProjectData();
      }
    });
  }

  escapeFilterValue(filterJsonObject) {
    let encodedFilterJsonObject = {};
    Object.entries(filterJsonObject).forEach((filterItem) => {
      let encodedFilterValueArray = [];
      filterItem[1].forEach((filterValue) => {
        encodedFilterValueArray.push(encodeURIComponent(filterValue));
      });
      encodedFilterJsonObject[filterItem[0]] = encodedFilterValueArray;
    });
    return encodedFilterJsonObject;
  }

  fetchProjectNames() {
    this.setState({ isLoading: true });
    const filters = this.props.selectedFiltersData;
    let filterQuery;
    if (filters) {
      const encodedFilters = this.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }
    axios({
      url: this.state.fetchProjectNamesRequestURL + filterQuery,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const projectNameIdMap = response.data.projectNameIdMap;
        this.setState({ projectNameIdMap: projectNameIdMap });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log("error in fetching project names");
        this.setState({ isLoading: false });
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  }

  fetchProjectData() {
    const { projectName } = this.state;
    const { projectNameIdMap } = this.props;
    this.props.parentCallback(projectName);
    this.setState({ showMap: false });
    if (projectNameIdMap && projectNameIdMap[projectName]) {
      this.props.updateProjectMapDataLoadStatus(false);
      const projectId = projectNameIdMap[projectName];
      axios({
        url: this.state.fetchProjectDataRequestURL + projectId,
        method: "GET",
        headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
      })
        .then((response) => {
          const res = response;
          const projectAllData = res.data.projectData;
          const monthNumbers = {
            1: "Jan",
            2: "Feb",
            3: "Mar",
            4: "Apr",
            5: "May",
            6: "Jun",
            7: "Jul",
            8: "Aug",
            9: "Sep",
            10: "Oct",
            11: "Nov",
            12: "Dec",
          };

          // data will be sorted by year and month. Oldest data will come first.
          let sliderValues = [];
          let monthNumberList;
          /*const yearList = Object.keys(projectAllData);
          for (let i = 0; i < yearList.length; i++) {
            monthNumberList = [];
            monthNumberList = Object.keys(projectAllData[yearList[i]]);
            monthNumberList.forEach((element) => {
              sliderValues.push({
                year: yearList[i],
                month: monthNumbers[element],
              });
            });
          }*/

          //---start---above code was adding only available months to slider. Below code will add all month between start and end month
          const yearList = Object.keys(projectAllData);
          const startingYear = yearList[0];
          const endingYear = yearList[yearList.length - 1];
          const startingMonth = Object.keys(projectAllData[startingYear])[0];
          const endingMonth = Object.keys(projectAllData[endingYear])[
            Object.keys(projectAllData[endingYear]).length - 1
          ];
          const startTime = {
            month: parseInt(startingMonth),
            year: parseInt(startingYear),
          };
          const endTime = {
            month: parseInt(endingMonth),
            year: parseInt(endingYear),
          };

          if (startTime.year == endTime.year) {
            // if year of startTime and endTime are same
            for (let i = startTime.month; i <= endTime.month; i++) {
              sliderValues.push({
                month: monthNumbers[i],
                year: startTime.year,
              });
            }
          } else {
            // if endTime year is greater than startTime year
            let startingMonth;
            const yearDiff = endTime.year - startTime.year;
            for (let i = 0; i < yearDiff; i++) {
              if (i == 0) {
                startingMonth = startTime.month;
              } else {
                startingMonth = 1;
              }
              for (let j = startingMonth; j <= 12; j++) {
                sliderValues.push({
                  month: monthNumbers[j],
                  year: startTime.year + i,
                });
              }
            }
            // add remaining months
            for (let j = 1; j <= endTime.month; j++) {
              sliderValues.push({ month: monthNumbers[j], year: endTime.year });
            }
          }
          //---end---

          // get slider values to show on slider
          let sliderOptionList;
          if (sliderValues) {
            sliderOptionList = sliderValues.map((item, index) => {
              return {
                value: index,
                scaledValue: index,
                label: item.month + "-" + item.year,
              };
            });
          }

          this.setState({ sliderValues: sliderValues });
          this.setState({
            selectedSliderData: sliderValues[sliderValues.length - 1],
          }); // set latest month data
          this.setState({ sliderMax: sliderValues.length - 1 });
          this.setState({ sliderSelectedValue: 0 });
          this.setState({ sliderOptionList: sliderOptionList });
          this.setState({ projectAllData: projectAllData });
          this.setState({ showMap: true });
          this.setState({ sliderSize: 70 * sliderValues.length });
          //this.setState({ sliderSize: 1200 })
          this.updateMap(sliderValues.length - 1);
          this.props.updateProjectMapDataLoadStatus(true);
          // fetch health score and activities data
          this.getSingleProjectHealthScore(); //uncomment after this is ready
          //this.getSingleProjectActivities("c_time");
          this.fetchProjectHealthScoreSelectedMonth(sliderValues.length - 1)
        })

        .catch((error) => {
          console.log("error in fetching project data");
          let alert = ErrorHandler(error);
          this.setState({
            showAlert: alert.setAlertShow,
            alertMsg: alert.setMsgAlert,
            isLoading: false,
          });
          this.props.updateProjectMapDataLoadStatus(true);
        });
    } else {
      this.setState({ showMap: null });
    }
  }

  fetchMonthlyChanges() {
    const { projectName } = this.state;
    const { projectNameIdMap } = this.props;
    if (projectNameIdMap && projectNameIdMap[projectName]) {
      const projectId = projectNameIdMap[projectName];
      let activityNames = [];
      axios({
        url: this.state.fetchMonthlyChangesRequestURL + projectId,
        method: "GET",
        headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
      })
        .then((response) => {
          const res = response;
          let recommendationList = [];
          let recommendationOptions = [];

          console.log(res);
          if (
            res.data &&
            res.data.monthlyChangesData &&
            res.data.monthlyChangesData.activityChanges
          ) {
            let arr = res.data.monthlyChangesData.activityChanges;
            for (let i = 0; i < arr.length; i++) {
              activityNames.push(arr[i]["activityName"]);
              let recommendation = arr[i]["recommendation"];

              if (
                recommendationList.find((element) => element == recommendation)
              ) {
              } else {
                recommendationList.push(recommendation);
                recommendationOptions.push({
                  value: recommendation,
                  label: recommendation,
                  color: "black",
                });
              }
            }
            this.setState({
              monthlyChangesData: res.data.monthlyChangesData.activityChanges,
              monthlyChangesDisplayData:
                res.data.monthlyChangesData.activityChanges,
              showMonthlyChangesModal: true,
              activityNames: activityNames,
              currentMonth: res.data.monthlyChangesData.currentMonth,
              lastMonth: res.data.monthlyChangesData.lastMonth,
              recommendationOptions: recommendationOptions,
            });
          }
        })
        .catch((error) => { });
    }
  }

  closeTcqiHealthScoreModel() {
    this.setState({
      showMonthlyTCQIChart: false,
    });
  };
  showTcqiHealthScoreModel() {
    this.setState({
      showMonthlyTCQIChart: true,
    });
  }

  closeTcqiActivitiesTableModal() {
    this.setState({
      showTCQIActivitiesTable: false,
    });
  }

  showTcqiActivitiesTableModal(selectedMeasure) {
    this.setState({ selectedMeasure: selectedMeasure });
    const projectId = this.props.projectNameIdMap[this.state.projectName];
    // get the data for selected month
    const monthVal = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    axios({
      url:
        this.state.getSingleProjectActivitiesMonthlyURL +
        projectId +
        "/" +
        selectedMeasure + '?month=' + monthVal[this.state.selectedMonth] + '&year=' + this.state.selectedYear,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        const allMeasures = {
          c_time: "Time",
          c_cost: "Cost",
          c_quality: "Quality",
          c_scope: "Scope",
          c_h_s: "H&S",
          c_sme_self_assessments: "SME SA",
        }
        let measureHealthScore = 'NA'
        if (this.state.montlyTCQIData !== null) {
          measureHealthScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear][allMeasures[selectedMeasure]] + '%'
        }

        this.setState({
          isLoading: false,
          allActivities: data.allActivities,
          filteredActivities: data.allActivities,
          showTCQIActivitiesTable: true,
          measureHealthScore: measureHealthScore
        });

      })
      .catch((error) => {
        console.log("error in getting project activities" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isLoading: false,
        });
      });
  }

  showTcqiStatusData() {
    this.setState({
      showTcqiStatusModal: true,
    });
  }

  closeTcqiStatusModal() {
    this.setState({
      showTcqiStatusModal: false,
    });
  }

  showLivesLostData() {
    this.setState({
      showLivesLostModal: true,
    });
  }

  closeLivesLostModal() {
    this.setState({
      showLivesLostModal: false,
    });
  }

  updateSliderValue(e, sliderSelectedValue) {
    this.updateMap(sliderSelectedValue);
    this.fetchProjectHealthScoreSelectedMonth(sliderSelectedValue)
  }

  updateMap(sliderSelectedValue) {
    this.setState({ sliderSelectedValue: sliderSelectedValue });
    const selectedSliderData = this.state.sliderValues[sliderSelectedValue];
    this.setState({ selectedSliderData: selectedSliderData });

    // get the data for selected month
    const monthNames = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const projectAllData = this.state.projectAllData;
    const monthData =
      projectAllData[selectedSliderData.year][
      monthNames[selectedSliderData.month]
      ];
    if (monthData == undefined || monthData == null) {
      return;
    }
    monthData.sort(function (a, b) {
      return a.project_id - b.project_id;
    });
    this.setState({ selectedMonthData: monthData });

    // get the project data for first project
    const singleProjectData = monthData[0];
    this.setState({ singleProjectData: singleProjectData });
    this.props.updateSingleProjectMapData(singleProjectData);
  }

  showTubeMap(isActivitiesVisible, isMilestoneVisible, singleProjectData) {
    d3.select("#projectTubeMapDiv").select("svg").remove();
    let container = d3.select("#projectTubeMapDiv");
    const showActivities = isActivitiesVisible;
    const tubemapData = {
      singleProjectData: singleProjectData,
      isMilestoneVisible: isMilestoneVisible,
      isActivitiesVisible: showActivities,
      isCurrentData: true,
    };

    let tubeMap = map(tubemapData);
    let dataJson = data.default;

    container.datum(dataJson).call(tubeMap);
    this.changeMapZoom();
  }

  changeMapZoom() {
    let containerProjectMap = d3.select("#projectTubeMapDiv");
    let svgProjectMap = containerProjectMap.select("svg");
    let zoomProjectMap = d3
      .zoom()
      .scaleExtent([1, 10])
      .on("zoom", zoomedProjectMap);

    svgProjectMap.call(zoomProjectMap);

    d3.select("#zoomInProjectMap").on("click", zoomInProjectMap);

    d3.select("#zoomOutProjectMap").on("click", zoomOutProjectMap);
    d3.select("#zoomInitialProjectMap").on("click", zoomInitialMap);

    let zoomContainerProjectMap = svgProjectMap.call(zoomProjectMap);
    //let initialScaleProjectMap = 1.7;
    //let initialTranslateProjectMap = [68, 50];

    let initialScaleProjectMap = sessionStorage.getItem(
      "initialScaleProjectMap"
    );
    let initialTranslateXProjectMap = parseInt(
      sessionStorage.getItem("initialTranslateXProjectMap")
    );
    let initialTranslateYProjectMap = parseInt(
      sessionStorage.getItem("initialTranslateYProjectMap")
    );

    zoomProjectMap.scaleTo(zoomContainerProjectMap, initialScaleProjectMap);
    zoomProjectMap.translateTo(
      zoomContainerProjectMap,
      initialTranslateXProjectMap / initialScaleProjectMap,
      initialTranslateYProjectMap / initialScaleProjectMap
    );

    function zoomedProjectMap() {
      const currentTransform = d3.event.transform;
      svgProjectMap.select("g").attr("transform", currentTransform);
      initialScaleProjectMap = currentTransform.k;
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
      sessionStorage.setItem(
        "initialTranslateXProjectMap",
        currentTransform.x * -1
      );
      sessionStorage.setItem(
        "initialTranslateYProjectMap",
        currentTransform.y * -1
      );
    }

    function zoomInProjectMap(d) {
      initialScaleProjectMap += 1;
      if (initialScaleProjectMap > 10) {
        initialScaleProjectMap = 10;
      }
      zoomProjectMap.scaleTo(svgProjectMap, initialScaleProjectMap);
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
    }

    function zoomOutProjectMap(d) {
      initialScaleProjectMap -= 1;
      if (initialScaleProjectMap < 2) {
        initialScaleProjectMap = 2;
      }
      zoomProjectMap.scaleTo(svgProjectMap, initialScaleProjectMap);
      sessionStorage.setItem("initialScaleProjectMap", initialScaleProjectMap);
    }

    let copyThis = this;
    function zoomInitialMap(d) {
      sessionStorage.setItem("initialScaleProjectMap", 1.7);
      sessionStorage.setItem("initialTranslateXProjectMap", 50);
      sessionStorage.setItem("initialTranslateYProjectMap", 50);
      copyThis.setState({ isZoom: false });
    }
  }

  resetZoom() {
    sessionStorage.setItem("initialScaleProjectMap", 1.7);
    sessionStorage.setItem("initialTranslateXProjectMap", 50);
    sessionStorage.setItem("initialTranslateYProjectMap", 50);
    this.setState({ isZoom: false });
  }

  toggleActivities() {
    const isActivitiesVisible = this.state.isActivitiesVisible;
    this.setState({ isActivitiesVisible: !isActivitiesVisible });
    // if isActivitiesVisible is false then make isMilestoneVisible to true because at a time either of one should be visible
    let isMilestoneVisible = this.state.isMilestoneVisible;
    if (!isActivitiesVisible == false) {
      isMilestoneVisible = true;
      this.setState({ isMilestoneVisible: isMilestoneVisible });
    }
  }

  toggleMilestones() {
    const isMilestoneVisible = this.state.isMilestoneVisible;
    this.setState({ isMilestoneVisible: !isMilestoneVisible });
    // if isMilestoneVisible is false then make isActivitiesVisible to true because at a time either of one should be visible
    let isActivitiesVisible = this.state.isActivitiesVisible;
    if (!isMilestoneVisible == false) {
      isActivitiesVisible = true;
      this.setState({ isActivitiesVisible: isActivitiesVisible });
    }
  }

  updateDataToShow(e) {
    if (e.target.value === "milestone") {
      this.setState({
        isMilestoneVisible: true,
        isActivitiesVisible: false,
      });
    } else if (e.target.value === "activity") {
      this.setState({
        isActivitiesVisible: true,
        isMilestoneVisible: false,
      });
    } else {
      this.setState({
        isActivitiesVisible: true,
        isMilestoneVisible: true,
      });
    }
  }

  fetchProjectHealthScoreSelectedMonth(sliderSelectedValue) {
    const selectedSliderData = this.state.sliderValues[sliderSelectedValue];
    const projectId = this.props.projectNameIdMap[this.state.projectName];
    this.setState({ isMonthlyChangeLoading: true })
    // get the data for selected month
    const monthVal = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    axios({
      url: this.state.getSingleProjectHealthSelectedMonthURL + projectId + '?month=' + monthVal[selectedSliderData.month] + '&year=' + selectedSliderData.year,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const res = response;
        const data = res.data;
        let selectedMonthHealthScore = 'NA'
        let montlyTCQIData = null
        let healthScoreButtonColor = null
        if (data.projectHealthScoreData[selectedSliderData.month + ' - ' + selectedSliderData.year]) {
          selectedMonthHealthScore = data.projectHealthScoreData[selectedSliderData.month + ' - ' + selectedSliderData.year]["Overall"] + '%'
          if (!selectedMonthHealthScore) {
            selectedMonthHealthScore = 'NA'
          } else {
            let hScoreInt = parseInt(selectedMonthHealthScore)
            if (hScoreInt >= 0 && hScoreInt <= 33) {
              healthScoreButtonColor = 'secondary'
            } else if (hScoreInt >= 34 && hScoreInt <= 66) {
              healthScoreButtonColor = 'amber'
            } else if (hScoreInt >= 67 && hScoreInt <= 99) {
              healthScoreButtonColor = 'success'
            } else if (hScoreInt == 100) {
              healthScoreButtonColor = 'primary'
            } else {
              healthScoreButtonColor = 'light'
            }
          }

          delete data.projectHealthScoreData[selectedSliderData.month + ' - ' + selectedSliderData.year]["Overall"]
          montlyTCQIData = data.projectHealthScoreData
        }

        /*let projectTcqiActivities = data.projectTcqiActivities
        let timeButtonColor = projectTcqiActivities && projectTcqiActivities.c_time ? projectTcqiActivities.c_time == "G" ? "success" : projectTcqiActivities.c_time == "A" ? "amber" : "secondary" : "light"
        let costButtonColor = projectTcqiActivities && projectTcqiActivities.c_cost ? projectTcqiActivities.c_cost == "G" ? "success" : projectTcqiActivities.c_cost == "A" ? "amber" : "secondary" : "light"
        let qualityButtonColor = projectTcqiActivities && projectTcqiActivities.c_quality ? projectTcqiActivities.c_quality == "G" ? "success" : projectTcqiActivities.c_quality == "A" ? "amber" : "secondary" : "light"
        let scopeButtonColor = projectTcqiActivities && projectTcqiActivities.c_scope ? projectTcqiActivities.c_scope == "G" ? "success" : projectTcqiActivities.c_scope == "A" ? "amber" : "secondary" : "light"
        let hsButtonColor = projectTcqiActivities && projectTcqiActivities.c_h_s ? projectTcqiActivities.c_h_s == "G" ? "success" : projectTcqiActivities.c_h_s == "A" ? "amber" : "secondary" : "light"
        let smeButtonColor = projectTcqiActivities && projectTcqiActivities.c_sme_self_assessments ? projectTcqiActivities.c_sme_self_assessments == "G" ? "success" : projectTcqiActivities.c_sme_self_assessments == "A" ? "amber" : "secondary" : "light"*/

        let projectTcqiActivities = data.projectHealthScoreData[selectedSliderData.month + ' - ' + selectedSliderData.year]
        let timeVal = projectTcqiActivities['Time']
        let costVal = projectTcqiActivities['Cost']
        let qualityVal = projectTcqiActivities['Quality']
        let scopeVal = projectTcqiActivities['Scope']
        let hsVal = projectTcqiActivities['H&S']
        let smeVal = projectTcqiActivities['SME SA']

        let timeButtonColor = (timeVal >= 67 && timeVal <= 100) ? "success" : (timeVal >= 34 && timeVal <= 66) ? "amber" : "secondary"
        let costButtonColor = (costVal >= 67 && costVal <= 100) ? "success" : (costVal >= 34 && costVal <= 66) ? "amber" : "secondary"
        let qualityButtonColor = (qualityVal >= 67 && qualityVal <= 100) ? "success" : (qualityVal >= 34 && qualityVal <= 66) ? "amber" : "secondary"
        let scopeButtonColor = (scopeVal >= 67 && scopeVal <= 100) ? "success" : (scopeVal >= 34 && scopeVal <= 66) ? "amber" : "secondary"
        let hsButtonColor = (hsVal >= 67 && hsVal <= 100) ? "success" : (hsVal >= 34 && hsVal <= 66) ? "amber" : "secondary"
        let smeButtonColor = (smeVal >= 67 && smeVal <= 100) ? "success" : (smeVal >= 34 && smeVal <= 66) ? "amber" : "secondary"

        const allMilestones = {
          0: "NA",
          1: "Project / batch creation",
          2: "PAC1",
          3: "PAC2",
          4: "Award contract",
          5: "Start on site",
          6: "Project in use",
          7: "Completion date",
          8: "PAC3",
          9: "Final acceptance",
          10: "Gateway 4",
        }
        const milestoneToAchieve = allMilestones[parseInt(data.currentMilestone) + 1]

        const tcqiStatusData = data.tcqiStatusData
        let tcqiButtonColor, tcqiStatusTextColor
        let tcqiStatusButtonVisible = false
        const fy22tcqi = tcqiStatusData.fy22tcqi
        let tcqiStatus = tcqiStatusData.tcqiStatus
        const tcqiStatusActual = tcqiStatusData.tcqiStatus
        if (fy22tcqi && tcqiStatus) {
          tcqiStatusButtonVisible = true
          tcqiStatus = tcqiStatus.trim().replace(/\s/g, '').toLowerCase()
          if (tcqiStatus === 'Complete On-time'.replace(/\s/g, '').toLowerCase() ||
            tcqiStatus === 'Fcst >28 Days Early'.replace(/\s/g, '').toLowerCase() ||
            tcqiStatus === 'Fcst > = 28 days early'.replace(/\s/g, '').toLowerCase()) {
            tcqiButtonColor = "success"
            tcqiStatusTextColor = 'green'
          } else if (tcqiStatus === 'Missed (Outstanding)'.replace(/\s/g, '').toLowerCase() ||
            tcqiStatus === 'Fcst <=28 Days Late'.replace(/\s/g, '').toLowerCase() ||
            tcqiStatus === 'Fcst  >28 Days Late'.replace(/\s/g, '').toLowerCase() ||
            tcqiStatus === 'Complete Late'.replace(/\s/g, '').toLowerCase()) {
            tcqiButtonColor = "secondary"
            tcqiStatusTextColor = 'red'
          } else if (tcqiStatus === 'Fcst <=28 Days Early'.replace(/\s/g, '').toLowerCase()) {
            tcqiButtonColor = "amber"
            tcqiStatusTextColor = '#FFBF00'
          } else if (tcqiStatus === 'CC Approved'.replace(/\s/g, '').toLowerCase()) {
            tcqiButtonColor = "primary"
            tcqiStatusTextColor = '#268cd1'
          } else {
            tcqiStatusTextColor = '#fff' //For any other value set white color
            tcqiButtonColor = "light"
          }
        }
        let livesLostButtonColor
        let livesLostButtonVisible = false
        // livesLostButtonVisible will be true only when livesLost is 'R' or 'A'. For 'G' and 'GR' it will be false
        const livesLostData = data.livesData
        if (livesLostData.livesLost == 'R') {
          if (livesLostData.bodYTD || livesLostData.ammoniaYTD || livesLostData.solidiumYTD || livesLostData.otherYTD) {
            livesLostButtonColor = 'secondary'
            livesLostButtonVisible = true
          }
        } else if (livesLostData.livesLost == 'A') {
          if (livesLostData.bodYTD || livesLostData.ammoniaYTD || livesLostData.solidiumYTD || livesLostData.otherYTD) {
            livesLostButtonColor = 'amber'
            livesLostButtonVisible = true
          }
        }

        this.setState({
          isMonthlyChangeLoading: false,
          montlyTCQIData: montlyTCQIData,
          fy22tcqi: fy22tcqi,
          tcqiStatus: tcqiStatusActual,
          tcqiButtonColor: tcqiButtonColor,
          tcqiStatusTextColor: tcqiStatusTextColor,
          tcqiStatusButtonVisible: tcqiStatusButtonVisible,
          selectedMonth: selectedSliderData.month,
          selectedYear: selectedSliderData.year,
          selectedMonthHealthScore: selectedMonthHealthScore,
          milestoneToAchieve: milestoneToAchieve,
          projectTcqiActivities: projectTcqiActivities,
          healthScoreButtonColor: healthScoreButtonColor,
          timeButtonColor: timeButtonColor,
          costButtonColor: costButtonColor,
          qualityButtonColor: qualityButtonColor,
          scopeButtonColor: scopeButtonColor,
          hsButtonColor: hsButtonColor,
          smeButtonColor: smeButtonColor,
          livesLostButtonColor: livesLostButtonColor,
          livesLostButtonVisible: livesLostButtonVisible,
          livesLostData: livesLostData
        });
      })
      .catch((error) => {
        console.log("error in getting project health score for selected month" + error);
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
          isMonthlyChangeLoading: false,
        });
      });
  }

  searchByActivity(event, value) {
    let monthlyChangesDisplayData = [];
    let dataArray = [];

    this.setState({
      selectedStatusValue: {
        lastMonthStatus: [],
        currentMonthStatus: [],
      },
    });

    if (value === null || value === "") {
      this.setState({
        monthlyChangesDisplayData: this.state.monthlyChangesData,
      });
    } else {
      this.state.monthlyChangesData.map((data) => {
        dataArray.push(data);
        if (
          data["activityName"].toLowerCase().indexOf(value.toLowerCase()) === 0
        ) {
          monthlyChangesDisplayData.push(data);
        }
        if (dataArray.length === this.state.monthlyChangesData.length) {
          this.setState({
            monthlyChangesDisplayData: monthlyChangesDisplayData,
          });
        }
      });
    }
  }

  /*
  onRecommendationSelect(selectedList, selectedItem) {
    this.setState({
      selectedRecommendationValue: selectedList,
    });

    let tableData = this.state.monthlyChangesFilteredData;
    let modalTableFilterData = [];
    let dataArray = [];

    if (!selectedList) {
      this.setState({ monthlyChangesFurtherFilteredData: tableData });
    } else if (selectedList.length == 0) {
      this.setState({ monthlyChangesFurtherFilteredData: tableData });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < tableData.length; j++) {
          let data = tableData[j];
          dataArray.push(data);
          if (
            data["recommendation"]
              .toLowerCase()
              .indexOf(selectedList[i].value.toLowerCase()) === 0
          ) {
            modalTableFilterData.push(data);
          }
        }
        if (dataArray.length === selectedList.length * tableData.length) {
          this.setState({
            monthlyChangesFurtherFilteredData: modalTableFilterData,
          });
        }
      }
    }
  }
  */

  onStatusSelect = async (item, selectedList, selectedItem) => {
    let selectedStatusValue = this.state.selectedStatusValue;
    selectedStatusValue[item] = selectedList;

    await this.setState({
      selectedStatusValue: selectedStatusValue,
      searchSelection: !this.state.searchSelection,
    });

    let filteredData = this.state.monthlyChangesData.slice();
    let lastMonthSelect = selectedStatusValue["lastMonthStatus"];
    let curMonthSelect = selectedStatusValue["currentMonthStatus"];

    if (lastMonthSelect && lastMonthSelect.length != 0) {
      filteredData = await this.applyBRAGFilters(
        lastMonthSelect,
        filteredData,
        "lastMonthStatus"
      );
    }

    if (curMonthSelect && curMonthSelect.length != 0) {
      filteredData = await this.applyBRAGFilters(
        curMonthSelect,
        filteredData,
        "currentMonthStatus"
      );
    }

    await this.setState({ monthlyChangesDisplayData: filteredData });
  };

  applyBRAGFilters = (selectedList, baseData, item) => {
    let filteredData = [];
    let dataArray = [];

    for (let i = 0; i < selectedList.length; i++) {
      for (let j = 0; j < baseData.length; j++) {
        let data = baseData[j];
        dataArray.push(data);
        if (data[item]) {
          if (data[item].toLowerCase() == selectedList[i].value.toLowerCase()) {
            filteredData.push(data);
          }
        } else {
          if (selectedList[i].value == "NA") {
            filteredData.push(data);
          }
        }
      }
      if (dataArray.length === selectedList.length * baseData.length) {
        return filteredData;
      }
    }
  };

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let title =
      "Monthly Changes Data for '" +
      this.state.projectName +
      "' of " +
      this.state.currentMonth;

    const headers = [
      [
        "Activity",
        this.state.lastMonth
          ? "Last month status (" + this.state.lastMonth + ")"
          : "Last month status",
        this.state.currentMonth
          ? "Current month status (" + this.state.currentMonth + ")"
          : "Current month status",
        "Duration in months",
        //"Recommendation",
      ],
    ];

    let data = this.state.monthlyChangesDisplayData.map((elt) => [
      elt["activityName"],
      elt["lastMonthStatus"],
      elt["currentMonthStatus"],
      elt["durationInMonths"],
      //elt["recommendation"],
    ]);

    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "B") {
          data.cell.styles.textColor = "#268cd1";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "GR") {
          data.cell.styles.textColor = "#939190";
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }

        if (data.cell.raw == "NA") {
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => { },
      startY: 50,
      head: headers,
      columnStyles: { 2: { halign: "left" } },
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Monthly Changes Data for " + this.state.projectName + ".pdf");
  };
  componentDidUpdate(prevProps) {
    if (
      this.state?.defaultProjectVal === null &&
      this.props.projectsDataDefaultVal
    ) {
      const { projectNameIdMap } = prevProps;
      if (projectNameIdMap && this.props?.projectNameIdMap) {
        this.setDefaultVal();
      }
    }
  }

  setDefaultVal() {
    if (this.props?.projectsDataDefaultVal) {
      const { projectNameIdMap } = this.props;
      if (projectNameIdMap) {
        for (let key in projectNameIdMap) {
          if (projectNameIdMap[key] === this.props.projectsDataDefaultVal) {
            this.setState({
              defaultProjectVal: true,
            });
            this.setProjectName(key, true);
            break;
          }
        }
        this.setState({ defaultValueChecked: true });
      }
    }
  }

  render() {
    if (this.state?.goToForecastedPerformance) {
      let pathname = `/ui/landing/forecast`;
      const projectId = this.props.projectNameIdMap[this.state.projectName];
      return (
        <Redirect
          to={{
            pathname,
            state: {
              projectId: projectId
            },
          }}
        />
      );
    }
    const {
      selectedSliderData,
      showMap,
      sliderSize,
      sliderOptionList,
      isLoading,
      isMonthlyChangeLoading,
    } = this.state;
    const { projectNameIdMap } = this.props;
    const projectNameIdMapKeys =
      projectNameIdMap && Object.keys(projectNameIdMap);
    this.showTubeMap(
      this.state.isActivitiesVisible,
      this.state.isMilestoneVisible,
      this.state.singleProjectData
    );
    let year = 0,
      month = 0;
    if (selectedSliderData) {
      year = selectedSliderData.year;
      month = selectedSliderData.month;
    }
    /*const milestoneButtonLabel = this.state.isMilestoneVisible
      ? "Hide Milestones"
      : "Show Milestones";
    const activityButtonLabel = this.state.isActivitiesVisible
      ? "Hide Activities"
      : "Show Activities";
    const milestoneButtonColor = this.state.isMilestoneVisible
      ? "map-primary"
      : "map-secondary";
    const activityButtonColor = this.state.isActivitiesVisible
      ? "map-primary"
      : "map-secondary";*/
    let milestoneRadioChecked = false;
    let activityRadioChecked = false;
    let bothRadioChecked = false;
    if (this.state.isMilestoneVisible && !this.state.isActivitiesVisible) {
      milestoneRadioChecked = true;
    }
    if (!this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      activityRadioChecked = true;
    }
    if (this.state.isMilestoneVisible && this.state.isActivitiesVisible) {
      bothRadioChecked = true;
    }

    /*
    if (
      selectedSliderData &&
      selectedSliderData.month &&
      selectedSliderData.year
    ) {
      console.log(selectedSliderData.month + " " + selectedSliderData.year);
    }
    */
    const allMeasures = {
      c_time: "Time",
      c_cost: "Cost",
      c_quality: "Quality",
      c_scope: "Scope",
      c_h_s: "H&S",
      c_sme_self_assessments: "SME SA",
    }

    let timeScore = '', costScore = '', qualityScore = '', scopeScore = '', hsScore = '', smeScore = ''
    if (this.state.selectedMonth) {
      timeScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["Time"] + '%'
      costScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["Cost"] + '%'
      qualityScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["Quality"] + '%'
      scopeScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["Scope"] + '%'
      hsScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["H&S"] + '%'
      smeScore = this.state.montlyTCQIData[this.state.selectedMonth + ' - ' + this.state.selectedYear]["SME SA"] + '%'
    }

    return (
      <div>
        {this.state.showMonthlyChangesModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="show-key-activities-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={async () => {
                    await this.setState({
                      showMonthlyChangesModal: false,
                      selectedRecommendationValue: null,
                      selectedStatusValue: {
                        lastMonthStatus: [],
                        currentMonthStatus: [],
                      },
                      searchSelection: !this.state.searchSelection,
                    });
                  }}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col>
                    <center>
                      <div
                        id="key-tasks-to-text"
                        style={{
                          color: "#252525",
                          fontSize: "0.94rem",
                          marginTop: "0.1rem",
                        }}
                      >
                        {this.state.projectName}
                        <BootstrapTooltip
                          placement="right"
                          title="This table shows monthly changes activity data"
                        >
                          <span>
                            <MdInfo
                              size={25}
                              className="c-info"
                              style={{ marginTop: "-0.5rem" }}
                            ></MdInfo>
                          </span>
                        </BootstrapTooltip>
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper-current-vs-forecasted">
                        <Autocomplete
                          options={this.state.activityNames}
                          key={this.state.searchSelection}
                          onInputChange={this.searchByActivity}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Activity"
                            />
                          )}
                          size="small"
                        />
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div>
                        <Table
                          responsive
                          className="current-vs-forecasted table-scroll-modal table-modal table table-md table-custom table-current-vs-forecasted"
                          id="table-task-list"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <thead className="table-head monthly-changes-head">
                            <tr>
                              <th
                                className="activity-name-col"
                                style={{ textAlign: "bottom" }}
                              >
                                <span>Activity</span>
                              </th>
                              <th
                                className="month-status-col centre-align-data"
                                style={{
                                  textAlign: "bottom",
                                  paddingBottom: "0.2rem",
                                  width: "8vw",
                                }}
                              >
                                <BootstrapTooltip
                                  placement="top-center"
                                  title={
                                    "Last month status (" +
                                    this.state.lastMonth +
                                    ")"
                                  }
                                >
                                  <span>
                                    <Select
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      isMulti
                                      options={colourOptions}
                                      styles={colourStyles}
                                      value={
                                        this.state.selectedStatusValue[
                                        "lastMonthStatus"
                                        ]
                                      }
                                      onChange={this.onStatusSelect.bind(
                                        this,
                                        "lastMonthStatus"
                                      )}
                                      placeholder={
                                        "Last month status (" +
                                        this.state.lastMonth +
                                        ")"
                                      }
                                      isSearchable
                                    />
                                  </span>
                                </BootstrapTooltip>
                              </th>
                              <th
                                className="month-status-col centre-align-data"
                                style={{
                                  textAlign: "bottom",
                                  paddingBottom: "0.2rem",
                                  width: "8vw",
                                }}
                              >
                                <BootstrapTooltip
                                  placement="top-center"
                                  title={
                                    "Current month status (" +
                                    this.state.currentMonth +
                                    ")"
                                  }
                                >
                                  <span>
                                    <Select
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      isMulti
                                      options={colourOptions}
                                      styles={colourStyles}
                                      value={
                                        this.state.selectedStatusValue[
                                        "currentMonthStatus"
                                        ]
                                      }
                                      onChange={this.onStatusSelect.bind(
                                        this,
                                        "currentMonthStatus"
                                      )}
                                      placeholder={
                                        "Current month status (" +
                                        this.state.currentMonth +
                                        ")"
                                      }
                                      isSearchable
                                    />
                                  </span>
                                </BootstrapTooltip>
                              </th>
                              <th
                                className="dur-month-col"
                                style={{ textAlign: "bottom" }}
                              >
                                <span>Duration in months</span>
                              </th>
                              {/*
                                <th
                                  style={{
                                    paddingBottom: "0.2rem",
                                    paddingTop: "0.2rem",
                                    width: "12vw",
                                  }}
                                  className="rec-col"
                                >
                                  <BootstrapTooltip
                                    title="Recommendation"
                                    placement="top-start"
                                  >
                                    <Select
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      isMulti
                                      options={this.state.recommendationOptions}
                                      styles={colourStyles}
                                      value={
                                        this.state.selectedRecommendationValue
                                      }
                                      onChange={this.onRecommendationSelect}
                                      placeholder="Recommendation"
                                      isSearchable
                                    />
                                  </BootstrapTooltip>
                                </th>
                              */}
                            </tr>
                          </thead>
                          <tbody className="monthly-changes-body">
                            {this.state.monthlyChangesDisplayData ? (
                              this.state.monthlyChangesDisplayData.length >=
                                1 ? (
                                this.state.monthlyChangesDisplayData.map(
                                  (activityObject) => (
                                    <tr>
                                      <td className="table-data-bold activity-name-col">
                                        {activityObject["activityName"]}
                                      </td>
                                      <td className="table-data-black centre-align-data month-status-col">
                                        {activityObject["lastMonthStatus"] ? (
                                          ["R", "A", "G", "GR", "B"].includes(
                                            activityObject["lastMonthStatus"]
                                          ) ? (
                                            <div
                                              className={
                                                activityObject[
                                                  "lastMonthStatus"
                                                ] === "R"
                                                  ? "red-circle"
                                                  : activityObject[
                                                    "lastMonthStatus"
                                                  ] === "GR"
                                                    ? "grey-circle"
                                                    : activityObject[
                                                      "lastMonthStatus"
                                                    ] === "A"
                                                      ? "amber-circle"
                                                      : activityObject[
                                                        "lastMonthStatus"
                                                      ] === "B"
                                                        ? "blue-circle"
                                                        : "green-circle"
                                              }
                                            ></div>
                                          ) : activityObject[
                                            "lastMonthStatus"
                                          ] != "NA" ? (
                                            activityObject["lastMonthStatus"]
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td className="table-data-black centre-align-data month-status-col">
                                        {activityObject[
                                          "currentMonthStatus"
                                        ] ? (
                                          ["R", "A", "G", "GR", "B"].includes(
                                            activityObject["currentMonthStatus"]
                                          ) ? (
                                            <div
                                              className={
                                                activityObject[
                                                  "currentMonthStatus"
                                                ] === "R"
                                                  ? "red-circle"
                                                  : activityObject[
                                                    "currentMonthStatus"
                                                  ] === "GR"
                                                    ? "grey-circle"
                                                    : activityObject[
                                                      "currentMonthStatus"
                                                    ] === "A"
                                                      ? "amber-circle"
                                                      : activityObject[
                                                        "currentMonthStatus"
                                                      ] === "B"
                                                        ? "blue-circle"
                                                        : "green-circle"
                                              }
                                            ></div>
                                          ) : activityObject[
                                            "currentMonthStatus"
                                          ] != "NA" ? (
                                            activityObject["currentMonthStatus"]
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td className="table-data-bold dur-month-col">
                                        {activityObject["durationInMonths"]}
                                      </td>
                                      {/*
                                        <td className="table-data-bold rec-col">
                                          {activityObject["recommendation"]}
                                        </td>
                                      */}
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr></tr>
                              )
                            ) : (
                              <tr>
                                <td style={{ width: "15vw" }}></td>
                                <td style={{ width: "26vw" }}>
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <Button
                        color="primary"
                        onClick={this.downloadTablePdf}
                        size="sm"
                        id="download-list-key-actvity"
                        disabled={
                          this.state.monthlyChangesDisplayData &&
                            JSON.stringify(
                              this.state.monthlyChangesDisplayData
                            ) !== JSON.stringify([])
                            ? false
                            : true
                        }
                      >
                        {this.state.isDownloading ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>{" "}
                            Downloading...
                          </div>
                        ) : (
                          <div>
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </div>
                        )}
                      </Button>
                    </center>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        {this.props.isFetchProjectNamesLoading == false &&
          (projectNameIdMap == null || projectNameIdMap == "{}") && (
            <div className="no-data-available-dashboard">
              No project data available for dashboard. Please upload data to
              view dashboard
            </div>
          )}
        {this.props.isFetchProjectNamesLoading == true && (
          <PageSpinner></PageSpinner>
        )}
        {isLoading == true && <PageSpinner></PageSpinner>}
        {isMonthlyChangeLoading == true && <div style={{ position: 'absolute', top: '60%', zIndex: '1000', left: '46%' }}><PageSpinner></PageSpinner></div>}
        {this.props.isFetchProjectNamesLoading == false &&
          projectNameIdMap != null && (
            <div>
              <Row>
                <Col
                  md={{ size: 8, offset: 2 }}
                  sm={{ size: 10, offset: 1 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <center>
                    {/* <div style={{ width: "650px" }}> */}
                    <Row>
                      <div className="col-8">
                        {((this.props.projectsDataDefaultVal &&
                          (this.state.defaultValueChecked ||
                            this.state?.defaultProjectVal !== null)) ||
                          !this.props.projectsDataDefaultVal) &&
                          projectNameIdMap != null && (
                            <Autocomplete
                              id="projectSearch"
                              defaultValue={this.state.projectName}
                              options={projectNameIdMapKeys}
                              className="auto-complete"
                              onSelect={(e) =>
                                this.setProjectName(e.target.value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  className="search-input"
                                  {...params}
                                  placeholder="Search Project"
                                />
                              )}
                            />
                          )}
                        {projectNameIdMap == null && (
                          <div>
                            <div className="auto-complete">
                              <input
                                className="search-input"
                                placeholder="Loading..."
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="col-"></div> */}
                      <Button
                        color="primary"
                        size="md"
                        style={{
                          width: window.innerWidth < 1000 ? "7rem" : "9rem",
                        }}
                        onClick={(e) => this.fetchProjectData()}
                      >
                        <MdSearch size={20}></MdSearch>
                        <div className="search-text">Search</div>
                      </Button>
                    </Row>
                    {/* </div> */}
                  </center>
                </Col>
              </Row>
              {showMap == false && (
                <div>
                  <PageSpinner />
                </div>
              )}
              {showMap == null && <div></div>}
              {showMap == true && (
                <div>
                  <Row>
                    <Col id="searchByProjectDIv">
                      <div className="map-top">
                        <div className="chart-header">
                          Performance by milestones{" "}
                          <span data-title="The tube map represents BRAG statuses of the selected live project at each milestone and activity shown in their respective colour​">
                            <MdInfo size={20} className="c-info"></MdInfo>
                          </span>
                          <div className="chart-buttons2">
                            {/*<Button className='map-buttons' size='sm' color={milestoneButtonColor} onClick={() => { this.toggleMilestones() }}>
                              {milestoneButtonLabel}
                            </Button> &nbsp;
                            <Button className="map-buttons" size="sm" color={activityButtonColor} onClick={() => { this.toggleActivities() }}>
                              {activityButtonLabel}
                            </Button>*/}
                            <div>
                              <label className="radio-label">
                                Milestones
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="milestone"
                                  checked={milestoneRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>

                              <label className="radio-label">
                                Activities
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="activity"
                                  checked={activityRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>

                              <label className="radio-label">
                                Both
                                <input
                                  type="radio"
                                  name="projectDataToShow"
                                  onClick={(e) => {
                                    this.updateDataToShow(e);
                                  }}
                                  value="both"
                                  checked={bothRadioChecked}
                                ></input>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            {/*<div>
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {" "}
                            Milestones &nbsp;{" "}
                          </div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={() => {
                                this.toggleMilestones();
                              }}
                              checked={this.state.isMilestoneVisible}
                            />
                            <span className="toggle-slider round"></span>
                          </label>
                          &nbsp;
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {" "}
                            Activities &nbsp;{" "}
                          </div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={() => {
                                this.toggleActivities();
                              }}
                              checked={this.state.isActivitiesVisible}
                            />
                            <span className="toggle-slider round"></span>
                          </label>
                        </div>*/}
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              float: "right",
                              paddingTop: "0.5rem",
                              paddingRight: "0.5rem",
                            }}
                          >
                            <Button
                              size="sm"
                              onClick={() => this.handleForecastedPerformance()}
                              id="current-performance-button"
                              color="primary"
                            >
                              Forecasted Performance
                            </Button>&nbsp;&nbsp;
                            <Button
                              color="primary"
                              size="sm"
                              onClick={(e) => this.fetchMonthlyChanges()}
                              disabled={
                                selectedSliderData &&
                                  selectedSliderData.month &&
                                  selectedSliderData.year &&
                                  this.state.currentMonth
                                  ? selectedSliderData.month +
                                    " " +
                                    selectedSliderData.year ==
                                    this.state.currentMonth
                                    ? false
                                    : true
                                  : false
                              }
                            >
                              Monthly Changes
                            </Button>
                          </div>
                        </div>
                        <div
                          className="map-zoom-div1"
                          style={{ width: "auto" }}
                        >
                          <div className="map-zoom-div2">
                            <div id="zoomDiv" className="zoom-div">
                              <div className="zoom-out" id="zoomOutProjectMap">
                                <MdRemove size="20px"></MdRemove>
                              </div>
                              <div className="zoom-in" id="zoomInProjectMap">
                                <MdAdd size="20px"></MdAdd>
                              </div>
                              <div className="zoom-reset">
                                <MdUndo
                                  size="15px"
                                  onClick={() => this.resetZoom()}
                                ></MdUndo>
                              </div>
                              <div
                                className="zoom"
                                id="zoomInitialProjectMap"
                              ></div>
                            </div>
                            <div id="bragDiv" className="brag-div">
                              <div>
                                <div className="brag-inner-div1">
                                  Needs attention or action
                                  <div
                                    className="brag-circle"
                                    style={{ background: "red" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="redDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  A risk but not an issue yet
                                  <div
                                    className="brag-circle"
                                    style={{ background: "#FFBF00" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="amberDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  On track, no issues
                                  <div
                                    className="brag-circle"
                                    style={{ background: "#0dc70d" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="greenDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Completed, no further action required
                                  <div
                                    className="brag-circle"
                                    style={{ background: "blue" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="blueDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Expired, not due yet, not required
                                  <div
                                    className="brag-circle"
                                    style={{ background: "grey" }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="greyDiv"
                                ></div>
                              </div>
                              <div>
                                <div className="brag-inner-div1">
                                  Data not available (NA)
                                  <div
                                    className="brag-circle"
                                    style={{
                                      background: "white",
                                      border: "1px solid #22A2DF",
                                    }}
                                  ></div>
                                </div>
                                <div
                                  className="brag-inner-div2"
                                  id="naDiv"
                                ></div>
                              </div>
                            </div>
                            <div
                              id="projectTubeMapDiv"
                              className="tubemap"
                            ></div>
                          </div>
                          <div style={{ width: '98%', paddingTop: '5px' }}>
                            {/*Health score div */}
                            <div style={{ display: "flex" }}>
                              <div style={{ width: "73%" }}>
                                <BootstrapTooltip placement="top" title={this.state.selectedMonthHealthScore}>
                                  <Button
                                    color={this.state.healthScoreButtonColor}
                                    onClick={this.showTcqiHealthScoreModel}
                                    size="sm"
                                    id="t-c-q-s-buttons"
                                  >
                                    Health Score
                                  </Button>
                                </BootstrapTooltip>{" "}&nbsp;
                                {this.state.showMonthlyTCQIChart &&
                                  <Modal
                                    isOpen="true"
                                    centered="true"
                                    size="lg"
                                    style={{ top: '25px' }}
                                  >
                                    <ModalBody
                                      id="show-key-activities-modal"
                                      className="p-0"
                                    >
                                      <div className="p-2">
                                        <CancelIcon
                                          onClick={this.closeTcqiHealthScoreModel}
                                          className="cancel-close-icon"
                                        />
                                        <Row>
                                          <Col>
                                            <center>
                                              <span
                                                style={{ color: "#252525" }}
                                              >
                                                <div style={{ fontWeight: "bold" }}>{this.state.projectName}</div>
                                                Overall health score {this.state.selectedMonthHealthScore} for {this.state.selectedMonth} - {this.state.selectedYear}
                                                <BootstrapTooltip
                                                  placement="bottom-end"
                                                  title="This chart represents the six business measures and their current percentage score. Their average score is represented as the overall project health score."
                                                >
                                                  <span>
                                                    <MdInfo
                                                      size={25}
                                                      className="c-info"
                                                      style={{ marginTop: "-0.5rem" }}
                                                    ></MdInfo>
                                                  </span>
                                                </BootstrapTooltip>
                                              </span>
                                            </center>
                                          </Col>
                                        </Row>
                                      </div>
                                      {this.state.montlyTCQIData &&
                                        <div className="p-3" style={{ background: "#fff" }}>
                                          <div style={{ display: "flex", float: "right" }}>
                                            <div className="d-flex justify-content-center align-items-center mr-2">
                                              <div
                                                className="rect mr-2"
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                  backgroundColor: this.bgColors[1],
                                                }}
                                              ></div>
                                              {"0 to 33%"}
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center mr-2">
                                              <div
                                                className="rect mr-2"
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                  backgroundColor: this.bgColors[2],
                                                }}
                                              ></div>
                                              {">33 to 67%"}
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center mr-2">
                                              <div
                                                className="rect mr-2"
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                  backgroundColor: this.bgColors[0],
                                                }}
                                              ></div>
                                              {">67 to 100%"}
                                            </div>
                                          </div>
                                          <ProjectTCQIChartSingleMonth tcqiChartData={this.state.montlyTCQIData} barClickFunc={this.showTcqiActivitiesTableModal}></ProjectTCQIChartSingleMonth>
                                        </div>
                                      }
                                      {this.state.montlyTCQIData == null &&
                                        <div className="chart-top p-3" style={{ margin: '10px', height: '400px', textAlign: 'center' }}>
                                          Data not available
                                        </div>
                                      }

                                    </ModalBody>
                                  </Modal>
                                }
                                {this.state.showTCQIActivitiesTable &&
                                  <Modal
                                    isOpen="true"
                                    centered="true"
                                    size="lg"
                                    style={{ top: '25px' }}
                                  >
                                    <ModalBody
                                      id="show-key-activities-modal"
                                      className="p-0"
                                    >
                                      <div className="p-2">
                                        <CancelIcon
                                          onClick={this.closeTcqiActivitiesTableModal}
                                          className="cancel-close-icon"
                                        />
                                        <Row>
                                          <Col>
                                            <center>
                                              <span
                                                style={{ color: "#252525" }}
                                              >
                                                <div style={{ fontWeight: "bold" }}>{this.state.projectName}</div>
                                                {allMeasures[this.state.selectedMeasure]} health score {this.state.measureHealthScore} for {this.state.selectedMonth} - {this.state.selectedYear}
                                              </span>
                                            </center>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="p-3" style={{ background: "#fff" }}>
                                        <SingleProjectTcqiActivities measureVal={allMeasures[this.state.selectedMeasure]} milestoneToAchieve={this.state.milestoneToAchieve} allActivities={this.state.allActivities} filteredActivities={this.state.filteredActivities}></SingleProjectTcqiActivities>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                }
                                <BootstrapTooltip placement="top" title={timeScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.timeButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_time")}
                                    title={timeScore}
                                  >
                                    &#8239;&nbsp;&#8239;{"Time"}&#8239;&nbsp;&#8239;
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                <BootstrapTooltip placement="top" title={costScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.costButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_cost")}
                                    title={costScore}
                                  >
                                    &#8239;&nbsp;&#8239;{"Cost"}&#8239;&nbsp;&#8239;
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                <BootstrapTooltip placement="top" title={qualityScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.qualityButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_quality")}
                                    title={qualityScore}
                                  >
                                    {"Quality"}
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                <BootstrapTooltip placement="top" title={scopeScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.scopeButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_scope")}
                                    title={scopeScore}
                                  >
                                    &nbsp;{"Scope"}&nbsp;
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                <BootstrapTooltip placement="top" title={hsScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.hsButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_h_s")}
                                    title={hsScore}
                                  >
                                    &#8239;&nbsp;&nbsp;{"H&S"}&nbsp;&nbsp;&#8239;
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                <BootstrapTooltip placement="top" title={smeScore}>
                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.smeButtonColor}
                                    onClick={() => this.showTcqiActivitiesTableModal("c_sme_self_assessments")}

                                  >
                                    &#8239;{"SME SA"}&#8239;
                                  </Button>
                                </BootstrapTooltip>
                                {"  "}&nbsp;
                                {this.state.tcqiStatusButtonVisible &&

                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.tcqiButtonColor}
                                    onClick={() => this.showTcqiStatusData()}
                                  >
                                    &#8239;{"Reg Output"}&#8239;
                                  </Button>
                                }{"  "}&nbsp;
                                {this.state.livesLostButtonVisible &&

                                  <Button
                                    id="t-c-q-s-buttons"
                                    size="sm"
                                    color={this.state.livesLostButtonColor}
                                    onClick={() => this.showLivesLostData()}
                                  >
                                    &#8239;{"Lives Lost"}&#8239;
                                  </Button>
                                }
                                {this.state.showTcqiStatusModal &&
                                  <Modal
                                    isOpen="true"
                                    centered="true"
                                    size="sm"
                                  >
                                    <ModalBody
                                      id="show-key-activities-modal"
                                      className="p-0 tcqs-health-modal-body"
                                      style={{ width: '600px' }}
                                    >
                                      <div className="p-2">
                                        <CancelIcon
                                          onClick={this.closeTcqiStatusModal}
                                          className="cancel-close-icon"
                                        />
                                        <Row>
                                          <Col>
                                            <center>
                                              <span
                                                style={{ color: "#252525" }}
                                              >
                                                <div style={{ fontWeight: "bold" }}>{this.state.projectName}</div>
                                              </span>
                                            </center>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="p-3" style={{ background: "#fff" }}>
                                        <div style={{ height: '100px', paddingLeft: '25%', paddingTop: '25px', margin: '10px' }}>
                                          <div><span>CPD Status: </span><b>{this.state.tcqiStatus}</b></div>
                                          <div><span>Number of outputs in this financial year: </span><b>{this.state.fy22tcqi}</b></div>
                                        </div>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                }
                                {this.state.showLivesLostModal &&
                                  <Modal
                                    isOpen="true"
                                    centered="true"
                                    size="sm"
                                  >
                                    <ModalBody
                                      id="show-key-activities-modal"
                                      className="p-0 tcqs-health-modal-body"
                                      style={{ width: '600px' }}
                                    >
                                      <div className="p-2">
                                        <CancelIcon
                                          onClick={this.closeLivesLostModal}
                                          className="cancel-close-icon"
                                        />
                                        <Row>
                                          <Col>
                                            <center>
                                              <span
                                                style={{ color: "#252525" }}
                                              >
                                                <div style={{ fontWeight: "bold" }}>{this.state.projectName}</div>
                                              </span>
                                            </center>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="p-3" style={{ background: "#fff" }}>
                                        <div style={{ height: '100px', paddingLeft: '25%', paddingTop: '25px', margin: '10px' }}>
                                          {this.state.livesLostData.bodYTD && <div><span>No of lives lost for BOD: </span><b>{this.state.livesLostData.bodYTD}</b></div>}
                                          {this.state.livesLostData.ammoniaYTD && <div><span>No of lives lost for Ammonia: </span><b>{this.state.livesLostData.ammoniaYTD}</b></div>}
                                          {this.state.livesLostData.solidiumYTD && <div><span>No of lives lost for Solidium: </span><b>{this.state.livesLostData.solidiumYTD}</b></div>}
                                          {this.state.livesLostData.otherYTD && <div><span>No of lives lost for other: </span><b>{this.state.livesLostData.otherYTD}</b></div>}
                                        </div>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                }
                              </div>
                              <div style={{ paddingTop: "7px", width: "27%" }}>
                                <div style={{ display: "flex", float: "right" }}>
                                  <div className="d-flex justify-content-center align-items-center mr-2">
                                    <div
                                      className="rect mr-2"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: this.bgColors[1],
                                      }}
                                    ></div>
                                    {"0 to 33%"}
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center mr-2">
                                    <div
                                      className="rect mr-2"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: this.bgColors[2],
                                      }}
                                    ></div>
                                    {">33 to 67%"}
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center mr-2">
                                    <div
                                      className="rect mr-2"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: this.bgColors[0],
                                      }}
                                    ></div>
                                    {">67 to 100%"}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <Slider
                            style={{ width: sliderSize + "px" }}
                            className="slider"
                            value={this.state.sliderSelectedValue}
                            min={0}
                            max={this.state.sliderMax}
                            marks={sliderOptionList}
                            onChange={this.updateSliderValue}
                            valueLabelFormat={(val) => {
                              return (
                                selectedSliderData.month +
                                "-" +
                                selectedSliderData.year
                              );
                            }}
                          />

                          <Row>
                            <br />
                            <br />
                            <br />
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <SingleProjectHealthScoreTrendChart
                      projectCurrentHealthData={
                        this.state.projectCurrentHealthData
                      }
                      projectForecastedHealthData={
                        this.state.projectForecastedHealthData
                      }
                      projectId={
                        this.props.projectNameIdMap[this.state.projectName]
                      }
                      isForecasted={false}
                    />
                    {/*this.props.csvColumnMapping &&
                      this.props.projectNameIdMap &&
                      this.state.projectName ? (
                      <SingleProjectActivities
                        csvColumnMapping={this.props.csvColumnMapping}
                        projectId={
                          this.props.projectNameIdMap[this.state.projectName]
                        }
                        qualityParamDefaultVal={this.props.qualityParamDefaultVal}
                      />
                    ) : (
                      ""
                    )*/}
                  </Row>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

export default ProjectMap;
