import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PageSpinner from "../../PageSpinner";
import TablePagination from "../../TablePagination";
import "../../../styles/components/admin/all-users/TableAllUsers.scss";

const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    let creationDate, deletionDate;
    if (key === "creationDate") {
      creationDate = props.data[key].split(",")[0];
    }
    if (key === "deletionDate") {
      deletionDate = props.data[key].split(",")[0];
    }
    if (index === 3) {
      return null;
    }
    return (
      <td
        key={props.data[key]}
        style={{
          color: index === 0 ? "#252525" : "#919BA3",
          fontFamily: index === 0 ? "Ubuntu" : "Ubuntu-Medium",
          paddingTop: "0.63rem",
        }}
      >
        {key === "creationDate"
          ? creationDate
          : key === "deletionDate"
          ? deletionDate
          : props.data[key]}
      </td>
    );
  });
};

class TableAllDeletedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 5,
      showDeleteModal: false,
      username: null,
      userId: null,
      isSortingCalled: 1,
      lessClickKey: "",
      moreClickKey: "",
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.showDeleteConfirmationModal = this.showDeleteConfirmationModal.bind(
      this
    );
    this.onClickingDeleteUser = this.onClickingDeleteUser.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  getKeys = function () {
    if (this.props.data.length === 0) {
      return null;
    }
    return Object.keys(this.props.data[0]);
  };

  getHeader = function () {
    let keys = this.getKeys();
    if (keys === null) {
      return null;
    }

    return keys.map((key, index) => {
      let headerlabel = key;
      if (key === "creationDate") {
        headerlabel = "Request Date";
      }
      if (key === "userId") {
        headerlabel = "User ID";
      }
      if (key === "deletionDate") {
        headerlabel = "Deletion Date";
      }
      if (index === 3) {
        return null;
      }
      return (
        <th key={key}>
          <span>{headerlabel}</span>
          <ExpandLessIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] < b[prop]) {
                    return 1;
                  } else if (a[prop] > b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ lessClickKey: key, moreClickKey: "" });
            }}
            fontSize="small"
            className="expand-less-icon-sorting"
            style={{ color: key === this.state.lessClickKey ? "red" : "black" }}
          />
          <ExpandMoreIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] > b[prop]) {
                    return 1;
                  } else if (a[prop] < b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ moreClickKey: key, lessClickKey: "" });
            }}
            fontSize="small"
            className="expand-more-icon-sorting"
            style={{ color: key === this.state.moreClickKey ? "red" : "black" }}
          />
        </th>
      );
    });
  };

  getRowsData = function () {
    const items = this.props.data;
    const keys = this.getKeys();
    if (keys == null) {
      return null;
    }
    return items
      .slice(
        this.state.currentPage * this.state.pageSize,
        (this.state.currentPage + 1) * this.state.pageSize
      )
      .map((row, index) => {
        let userId = row.userId;
        let username = row.username;
        return (
          <tr key={index} id="active-users-row">
            <RenderRow key={index} data={row} keys={keys} />
            {/*
            <td>
              <Button
                type="button"
                color="light"
                id="delete-user-button"
                size="sm"
                onClick={this.onClickingDeleteUser.bind(this, userId, username)}
              >
                <div id="delete-user-button-text">&#10005; Delete User</div>
              </Button>
                </td>
                */}
          </tr>
        );
      });
  };

  onClickingDeleteUser(userId, username) {
    this.setState({
      userId: userId,
      showDeleteModal: true,
      username: username,
    });
  }

  toggleModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  onDeleteUser() {
    const cnt = this.props.cnt;
    const setCnt = this.props.setCnt;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    const count = this.props.count;
    const setCount = this.props.setCount;

    this.props.deleteUser(
      this.state.userId,
      cnt,
      setCnt,
      setShowAlert,
      setAlertMsg,
      setAlertcolor,
      count,
      setCount
    );
    this.toggleModal();
  }

  showDeleteConfirmationModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Delete Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Delete the user{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onDeleteUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    if (this.props.isloading) {
      return <PageSpinner />;
    }
    if (this.getKeys() === null) {
      return (
        <div>
          <div className="table-responsive">
            <Table
              responsive
              className="table table-border table-sm table-users"
            >
              <thead className="table-head">
                <tr className="text-capitalize" id="active-users-row">
                  <th>Username</th>
                  <th>Request Date</th>
                  <th>Deletion Date</th>
                </tr>
              </thead>
            </Table>
            <Card id="no-active-user-card">
              <h6 id="no-active-user-heading">
                No data available for all deleted users
              </h6>
            </Card>
          </div>
          <br />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.state.showDeleteModal ? this.showDeleteConfirmationModal() : ""}
        <div className="table-responsive">
          <Table responsive className="table table-border table-sm table-users">
            <thead className="table-head">
              <tr className="text-capitalize" id="active-users-row">
                {this.getHeader()}
                {/*
                    <th style={{ paddingBottom: "0.68rem" }}>
                    <span>Actions</span>
                    </th>
                */}
              </tr>
            </thead>
            <tbody>{this.getRowsData()}</tbody>
          </Table>
        </div>
        {Math.ceil(this.props.data.length / this.state.pageSize) > 1 ? (
          <div>
            <span className="showing-span">
              <span className="showing-text-pagination">Showing </span>
              <span>{this.state.currentPage + 1} </span>
              <span className="showing-text-pagination">out of </span>
              <span>
                {Math.ceil(this.props.data.length / this.state.pageSize)}{" "}
              </span>
              <span className="showing-text-pagination">pages </span>
            </span>
            <div id="active-users-pagination-div">
              <div id="active-users-pagination-style">
                <TablePagination
                  pagesCount={Math.ceil(
                    this.props.data.length / this.state.pageSize
                  )}
                  currentPage={this.state.currentPage}
                  setCurrentPage={this.setCurrentPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    );
  }
}

export default TableAllDeletedUsers;
