import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Col, Row, Table, Card } from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "../styles/pages/HistoryPage.scss";
import PageSpinner from "../components/PageSpinner";
import TablePagination from "../components/TablePagination";
import AutoCloseAlert from "../components/AutoCloseAlert";
import * as GenericConstants from "../src-constants/GenericConstants";
import { ErrorHandler, ErrorHandlerTimeData } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
//import { timeDataPromise } from "../services/TimeDataService";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";

class HistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getHistoryRequestURL: `${[process.env.REACT_APP_BACKEND_API]}/csv/files`,
      timeDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/time-data`,
      goToUploadDataPage: false,
      isloading: true,
      currentPage: 0,
      pageSize: 15,
      csvFileList: [],
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      monthNames: GenericConstants.MONTH_NAMES,
      isSortingCalled: 1,
      lessClickKey: "",
      moreClickKey: "",
    };
    /*
    timeDataPromise
      .then((response) => {
        if (response.data.success) {
          this.setState({
            monthNames: response.data.timeData.monthNames,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
    */
    this.getHistory = this.getHistory.bind(this);
    this.uploadData = this.uploadData.bind(this);
    this.getHistory();
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.getSortAscOrder = this.getSortAscOrder.bind(this);
    this.getSortDesOrder = this.getSortDesOrder.bind(this);
    this.arraySortAscending = this.arraySortAscending.bind(this);
    this.arraySortDescending = this.arraySortDescending.bind(this);
  }

  componentWillMount() {
    this.getTimeData();
  }

  getTimeData = () => {
    axios({
      url: this.state.timeDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            monthNames: response.data.timeData.monthNames,
            yearList: response.data.timeData.years,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  uploadData = () => {
    this.props.setPage("uploaddata");
    this.setState({ goToUploadDataPage: true });
  };

  getHistory = () => {
    axios({
      url: this.state.getHistoryRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        this.setState({
          csvFileList: response.data.csvFileList,
          isloading: false,
        });
      })
      .catch((error) => {
        let alert = ErrorHandler(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  getSortDesOrder(prop) {
    return function (a, b) {
      if (prop == "uploadDate" && a[prop] && b[prop]) {
        let aa = a[prop].split(",")[0].split("-").reverse().join(),
          bb = b[prop].split(",")[0].split("-").reverse().join();
        return aa < bb ? 1 : aa > bb ? -1 : 0;
      } else {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
        return 0;
      }
    };
  }

  getSortAscOrder(prop) {
    return function (a, b) {
      if (prop == "uploadDate" && a[prop] && b[prop]) {
        let aa = a[prop].split(",")[0].split("-").reverse().join(),
          bb = b[prop].split(",")[0].split("-").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      } else {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      }
    };
  }

  arraySortAscending(key) {
    let array = this.state.csvFileList;
    if (array !== null) {
      array.sort(this.getSortAscOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      csvFileList: array,
      lessClickKey: "",
      moreClickKey: key,
    });
  }

  arraySortDescending(key) {
    let array = this.state.csvFileList;
    if (array !== null) {
      array.sort(this.getSortDesOrder(key));
    }
    this.setState({
      isSortingCalled: this.state.isSortingCalled + 1,
      csvFileList: array,
      lessClickKey: key,
      moreClickKey: "",
    });
  }

  render() {
    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }

    if (this.state.goToUploadDataPage) {
      return (
        <Redirect
          to={{
            pathname: "/ui/landing/uploaddata",
            state: {},
          }}
        />
      );
    }

    if (this.state.isloading) {
      return (
        <div>
          {this.state.showAlert ? (
            <AutoCloseAlert
              color={this.state.alertcolor}
              body={this.state.alertMsg}
              showAlert={this.state.showAlert}
              setShowAlert={this.setShowAlert}
            />
          ) : (
            ""
          )}
          <Header open={this.props.open} setOpen={this.props.setOpen}>
            <h5 className="header-common-heading">Upload History</h5>
          </Header>
          <PageSpinner />
        </div>
      );
    }

    if (
      this.state.isloading === false &&
      this.state.csvFileList &&
      this.state.csvFileList.length === 0
    ) {
      return (
        <div>
          <Header open={this.props.open} setOpen={this.props.setOpen}>
            <h5 className="header-common-heading">Upload History</h5>
          </Header>
          <Row>
            <Col md={{ size: 12, offset: 0 }}>
              <Table responsive className="table table-sm" id="history-table">
                <thead className="table-head">
                  <tr>
                    <th>File Name</th>
                    <th>User</th>
                    <th>Year</th>
                    <th>Month</th>
                    <th>Uploaded On</th>
                  </tr>
                </thead>
              </Table>
              <Card id="no-history-data-upload-card">
                <h6 id="no-history-data-upload">
                  No data available for upload history
                </h6>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <Button color="primary" size="lg" onClick={this.uploadData}>
                <div id="history-upload-button-div">UPLOAD DATA</div>
              </Button>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <h5 className="header-common-heading">Upload History</h5>
        </Header>
        <Row>
          <Col md={{ size: 12, offset: 0 }}>
            <Table responsive className="table table-sm" id="history-table">
              <thead className="table-head">
                <tr>
                  <th>
                    <span>File Name</span>
                    <ExpandLessIcon
                      onClick={() => {
                        this.arraySortDescending("fileName");
                      }}
                      fontSize="small"
                      className="expand-less-icon-sorting"
                      style={{
                        color:
                          "fileName" === this.state.lessClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                    <ExpandMoreIcon
                      onClick={() => {
                        this.arraySortAscending("fileName");
                      }}
                      fontSize="small"
                      className="expand-more-icon-sorting"
                      style={{
                        color:
                          "fileName" === this.state.moreClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                  </th>
                  <th>
                    <span>User</span>
                    <ExpandLessIcon
                      onClick={() => {
                        this.arraySortDescending("uploadedBy");
                      }}
                      fontSize="small"
                      className="expand-less-icon-sorting"
                      style={{
                        color:
                          "uploadedBy" === this.state.lessClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                    <ExpandMoreIcon
                      onClick={() => {
                        this.arraySortAscending("uploadedBy");
                      }}
                      fontSize="small"
                      className="expand-more-icon-sorting"
                      style={{
                        color:
                          "uploadedBy" === this.state.moreClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                  </th>
                  <th>
                    <span>Year</span>
                    <ExpandLessIcon
                      onClick={() => {
                        this.arraySortDescending("year");
                      }}
                      fontSize="small"
                      className="expand-less-icon-sorting"
                      style={{
                        color:
                          "year" === this.state.lessClickKey ? "red" : "black",
                      }}
                    />
                    <ExpandMoreIcon
                      onClick={() => {
                        this.arraySortAscending("year");
                      }}
                      fontSize="small"
                      className="expand-more-icon-sorting"
                      style={{
                        color:
                          "year" === this.state.moreClickKey ? "red" : "black",
                      }}
                    />
                  </th>
                  <th>
                    <span>Month</span>
                    <ExpandLessIcon
                      onClick={() => {
                        this.arraySortDescending("month");
                      }}
                      fontSize="small"
                      className="expand-less-icon-sorting"
                      style={{
                        color:
                          "month" === this.state.lessClickKey ? "red" : "black",
                      }}
                    />
                    <ExpandMoreIcon
                      onClick={() => {
                        this.arraySortAscending("month");
                      }}
                      fontSize="small"
                      className="expand-more-icon-sorting"
                      style={{
                        color:
                          "month" === this.state.moreClickKey ? "red" : "black",
                      }}
                    />
                  </th>
                  <th className="centre-align-data">
                    <span style={{ marginLeft: "1.1rem" }}>Uploaded On</span>
                    <ExpandLessIcon
                      onClick={() => {
                        this.arraySortDescending("uploadDate");
                      }}
                      fontSize="small"
                      className="expand-less-icon-sorting"
                      style={{
                        color:
                          "uploadDate" === this.state.lessClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                    <ExpandMoreIcon
                      onClick={() => {
                        this.arraySortAscending("uploadDate");
                      }}
                      fontSize="small"
                      className="expand-more-icon-sorting"
                      style={{
                        color:
                          "uploadDate" === this.state.moreClickKey
                            ? "red"
                            : "black",
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.csvFileList
                  ? this.state.csvFileList
                      .slice(
                        this.state.currentPage * this.state.pageSize,
                        (this.state.currentPage + 1) * this.state.pageSize
                      )
                      .map((file) => (
                        <tr>
                          <td
                            className="table-data-main"
                            style={{ width: "33%" }}
                          >
                            {file.fileName}
                          </td>
                          <td style={{ width: "20%" }}>{file.uploadedBy}</td>
                          <td style={{ width: "10%" }}>{file.year}</td>
                          <td style={{ width: "15%" }}>
                            {this.state.monthNames[file.month]}
                          </td>
                          <td
                            className="centre-align-data"
                            style={{ width: "22%" }}
                          >
                            {file.uploadDate.split(",")[0]}
                          </td>
                        </tr>
                      ))
                  : ""}
              </tbody>
            </Table>
            {this.state.csvFileList ? (
              Math.ceil(this.state.csvFileList.length / this.state.pageSize) >
              1 ? (
                <div style={{ width: "100%" }}>
                  <span className="showing-span-history">
                    <span className="showing-text-pagination">Showing </span>
                    <span>{this.state.currentPage + 1} </span>
                    <span className="showing-text-pagination">out of </span>
                    <span>
                      {this.state.csvFileList
                        ? Math.ceil(
                            this.state.csvFileList.length / this.state.pageSize
                          )
                        : ""}{" "}
                    </span>
                    <span className="showing-text-pagination">pages </span>
                  </span>
                  <div id="history-pagination-div" className="ml-4">
                    <div id="history-pagination-style">
                      <TablePagination
                        pagesCount={
                          this.state.csvFileList
                            ? Math.ceil(
                                this.state.csvFileList.length /
                                  this.state.pageSize
                              )
                            : 0
                        }
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <br />
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Button color="primary" size="lg" onClick={this.uploadData}>
              <div id="history-upload-button-div">UPLOAD DATA</div>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default HistoryPage;
