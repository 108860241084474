import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PublishIcon from "@material-ui/icons/Publish";
import ErrorIcon from "@material-ui/icons/Error";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from "reactstrap";
import XLSX from "xlsx";
import { Spinner } from "reactstrap";
import { saveAs } from "file-saver";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import "../styles/pages/UploadDataPage.scss";
import AutoCloseAlert from "../components/AutoCloseAlert";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import { ErrorHandlerTimeData } from "../components/ErrorHandler";
import Header from "../components/Header";
import { HEADER_TYPE_TEXT } from "../components/RequestHeader";
//import { timeDataPromise } from "../services/TimeDataService";
import SessionExpired from "../components/SessionExpired";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class UploadDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploadRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/files`,
      timeDataRequestURL: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/csv/time-data`,
      selectedFile: null,
      year: null,
      month: null,
      fileUploadSuccessful: false,
      goToTrainModelPage: false,
      errorlist: null,
      uploading: false,
      wrongFileFormat: false,
      showAlert: false,
      alertMsg: "",
      alertcolor: "danger",
      monthNames: null,
      yearList: null,
    };
    /*
    timeDataPromise
      .then((response) => {
        if (response.data.success) {
          this.setState({
            monthNames: response.data.timeData.monthNames,
            yearList: response.data.timeData.years,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
    */
    this.createMonthDropdown = this.createMonthDropdown.bind(this);
    this.createYearDropdown = this.createYearDropdown.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.showFileUploadSuccess = this.showFileUploadSuccess.bind(this);
    this.showFileUpload = this.showFileUpload.bind(this);
    this.showFileUploadError = this.showFileUploadError.bind(this);
    this.downloadErrorReport = this.downloadErrorReport.bind(this);
    this.closeErrorView = this.closeErrorView.bind(this);
    this.uploadAndReplaceFile = this.uploadAndReplaceFile.bind(this);
    this.dontReplaceFile = this.dontReplaceFile.bind(this);
    this.showFileUploadReplaceConfirmationModal = this.showFileUploadReplaceConfirmationModal.bind(
      this
    );
    this.showFileFormatErrorModal = this.showFileFormatErrorModal.bind(this);
    this.setShowAlert = this.setShowAlert.bind(this);
  }

  componentWillMount() {
    this.getTimeData();
  }

  getTimeData = () => {
    axios({
      url: this.state.timeDataRequestURL,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            monthNames: response.data.timeData.monthNames,
            yearList: response.data.timeData.years,
          });
        }
      })
      .catch((error) => {
        let alert = ErrorHandlerTimeData(error);
        this.setState({
          showAlert: alert.setAlertShow,
          alertMsg: alert.setMsgAlert,
        });
      });
  };

  createYearDropdown = () => {
    return (
      <div id="year-month-list-wrapper">
        <select
          onChange={this.onYearChange}
          id="year-month-select"
          value={this.state.year || "Select"}
        >
          <option value="">Select</option>
          {this.state.yearList
            ? this.state.yearList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))
            : ""}
        </select>
      </div>
    );
  };
  onYearChange = (event) => {
    // Update the state
    this.setState({ year: event.target.value || null });
  };

  createMonthDropdown = () => {
    return (
      <div id="year-month-list-wrapper">
        <select
          onChange={this.onMonthChange}
          id="year-month-select"
          value={this.state.month || "Select"}
        >
          <option value="">Select</option>
          {this.state.monthNames
            ? Object.keys(this.state.monthNames).map((key) => (
                <option key={key} value={key}>
                  {this.state.monthNames[key]}
                </option>
              ))
            : ""}
        </select>
      </div>
    );
  };
  onMonthChange = (event) => {
    this.setState({ month: event.target.value || null });
  };

  setShowAlert = (value) => {
    this.setState({ showAlert: value });
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event[0] });
    if (this.state.selectedFile !== null) {
      if (
        this.state.selectedFile.name
          .substring(this.state.selectedFile.name.lastIndexOf(".") + 1)
          .toLowerCase() !== "xlsx"
      ) {
        this.setState({
          wrongFileFormat: true,
          showAlert: true,
          alertMsg: "Please upload xlsx file only",
          alertcolor: "danger",
        });
      } else {
        this.setState({
          wrongFileFormat: false,
        });
      }
    } else {
      this.setState({
        wrongFileFormat: false,
      });
    }
  };

  onFileRemove = (event) => {
    this.setState({ selectedFile: null });
  };

  onFileUpload = () => {
    this.setState({ uploading: true });
    const formData = new FormData();
    formData.append("year", this.state.year);
    formData.append("month", this.state.month);
    formData.append("csvfile", this.state.selectedFile);
    if (this.state.replaceFile) {
      formData.append("isReplace", this.state.replaceFile);
    }

    axios({
      url: this.state.fileUploadRequestURL,
      method: "POST",
      data: formData,
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        this.setState({ uploading: false });
        if (response.data.success) {
          this.setState({
            fileData: response.data.fileData,
            fileValidated: "Yes",
            fileUploadSuccessful: true,
            fileUploadError: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ uploading: false });
        if (error.response) {
          if (error.response.status === 409) {
            //console.log("File already exist");
            this.setState({ showFileReplace: true });
          } else if (error.response.status === 400) {
            if (error.response.data.error === "syntax error at end of input") {
              this.setState({
                showAlert: true,
                alertMsg: ErrorConstants.ERROR_INVALID_FILE_UPLOAD,
              });
            } else {
              if (typeof error.response.data.error == "string") {
                this.setState({
                  showAlert: true,
                  alertMsg: ErrorConstants.ERROR_INVALID_FILE_UPLOAD,
                });
              } else if (typeof error.response.data.error == "object") {
                this.setState({
                  errorlist: error.response.data.error,
                  fileUploadError: true,
                });
              } else {
                this.setState({
                  showAlert: true,
                  alertMsg: ErrorConstants.ERROR_INVALID_FILE_UPLOAD,
                });
              }
            }
          } else {
            this.setState({
              showAlert: true,
              alertMsg: ErrorConstants.ERROR_GENERIC,
            });
          }
        } else {
          this.setState({
            showAlert: true,
            alertMsg: ErrorConstants.ERROR_GENERIC,
          });
        }
      });
  };

  trainModel = () => {
    this.props.setPage("trainmodel");
    this.setState({ goToTrainModelPage: true });
  };

  downloadErrorReport = () => {
    if (this.state.errorlist && this.state.errorlist.length > 0) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel;application/octet-stream;charset=UTF-8";
      const fileExtension = ".xlsx";
      const excelData = this.state.errorlist;
      const fileName =
        this.state.selectedFile.name.slice(
          0,
          this.state.selectedFile.name.lastIndexOf(".")
        ) + "-error";
      const ws = XLSX.utils.json_to_sheet(excelData);
      //hard coded custom headers
      ws["A1"].v = "Record ID";
      ws["B1"].v = "Error Field";
      ws["C1"].v = "Validation Error";
      const wb = { Sheets: { Errors: ws }, SheetNames: ["Errors"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
    }
  };

  uploadAndReplaceFile = () => {
    this.setState({ replaceFile: true, showFileReplace: false }, () => {
      this.onFileUpload();
    });
  };

  dontReplaceFile = () => {
    this.setState({
      showFileReplace: false,
      errorlist: null,
      fileUploadError: false,
      fileData: null,
      fileValidated: null,
      fileUploadSuccessful: false,
      selectedFile: null,
      year: null,
      month: null,
    });
  };

  closeErrorView = () => {
    this.setState({
      errorlist: null,
      fileUploadError: false,
      fileData: null,
      fileValidated: null,
      fileUploadSuccessful: false,
      selectedFile: null,
      showFileReplace: false,
      year: null,
      month: null,
    });
  };

  showFileUploadSuccess = () => {
    return (
      <React.Fragment>
        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <h5 className="header-common-heading">Upload Data</h5>
        </Header>
        <CancelIcon
          onClick={this.closeErrorView}
          className="cancel-close-icon"
        />
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <CheckCircleIcon id="check-icon" />
          </Col>
          <br />
          <br />
          <Col md={{ size: 4, offset: 4 }}>
            <br />
            File Uploaded Successfully
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <div id="file-upload-success-div">
              <div id="summary-div">
                <br />
                Summary
                <br />
                <br />
                <table id="file-upload-success-table">
                  <tbody>
                    <tr>
                      <td id="file-upload-success-table-data-left">
                        File Name:{" "}
                      </td>
                      <td id="file-upload-success-table-data-right">
                        {this.state.fileData.fileName}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td id="file-upload-success-table-data-left">
                        File Validated:{" "}
                      </td>
                      <td id="file-upload-success-table-data-right">
                        {this.state.fileValidated}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td id="file-upload-success-table-data-left">
                        File Size:{" "}
                      </td>
                      <td id="file-upload-success-table-data-right">
                        {this.state.fileData.fileSize}
                        {" Bytes"}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td id="file-upload-success-table-data-left">
                        Line Count:{" "}
                      </td>
                      <td id="file-upload-success-table-data-right">
                        {this.state.fileData.noOfLines - 1}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Button
              color="primary"
              onClick={this.trainModel}
              id="train-model-button"
            >
              TRAIN MODEL
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  showFileUploadError = () => {
    if (this.state.errorlist === undefined) {
      return (
        <React.Fragment>
          <Header open={this.props.open} setOpen={this.props.setOpen}>
            <h5 className="header-common-heading">Upload Data</h5>
          </Header>
          <Row>
            <Col md={{ size: 1, offset: 11 }}>
              <CancelIcon
                id="cancel-icon-error-validation"
                onClick={this.closeErrorView}
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <ErrorIcon id="error-icon" />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <div id="validate-heading">
                Validation Errors for File "
                <i style={{ color: "#334D6E" }}>
                  {this.state.selectedFile.name}
                </i>
                "
              </div>
              <br />
              <div id="validate-subheading">
                Please correct these errors and upload the file again
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={{ size: 10, offset: 2 }}>
              <div className="table-wrapper-scroll-y my-custom-scrollbar">
                <Table
                  responsive
                  id="validate-table"
                  className="table table-sm table-users"
                >
                  <thead id="validate-table-head">
                    <tr>
                      <th>Record ID</th>
                      <th>Error Field</th>
                      <th>Validation Error</th>
                    </tr>
                  </thead>
                </Table>
                <Card id="no-standard-error-found-card">
                  <h6 id="no-standard-error-found">
                    No standard errors found. Please check the file and upload
                    the data again
                  </h6>
                </Card>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <h5 className="header-common-heading">Upload Data</h5>
        </Header>
        <Row>
          <Col md={{ size: 1, offset: 11 }}>
            <CancelIcon
              id="cancel-icon-error-validation"
              onClick={this.closeErrorView}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <ErrorIcon id="error-icon" />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div id="validate-heading">
              Validation Errors for File "
              <i style={{ color: "#334D6E" }}>{this.state.selectedFile.name}</i>{" "}
              "
            </div>
            <br />
            <div id="validate-subheading">
              Please correct these errors and upload the file again
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 10, offset: 2 }}>
            <div className="table-wrapper-scroll-y my-custom-scrollbar">
              <Table
                responsive
                id="validate-table"
                className="table table-sm table-users"
              >
                <thead id="validate-table-head">
                  <tr>
                    <th>Record ID</th>
                    <th>Error Field</th>
                    <th>Validation Error</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.errorlist
                    ? this.state.errorlist.map((error) => (
                        <tr>
                          <td id="validate-table-row-no">{error.rowNo}</td>
                          <td id="validate-table-data">{error.errorField}</td>
                          <td id="validate-table-data">{error.errorMessage}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Button
              color="primary"
              size="lg"
              onClick={this.downloadErrorReport}
              id="download-button"
              className="rounded-lg"
              disabled={
                !this.state.errorlist || this.state.errorlist.length === 0
              }
            >
              <div id="download-report-text">
                <GetAppIcon fontSize="small" /> DOWNLOAD ERROR REPORT
              </div>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  showFileUploadReplaceConfirmationModal = () => {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <h9 id="file-exist-text">File Exists!</h9>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <span>
              File already exists on server for the selected month{" "}
              <span id="month-year-text">
                {this.state.monthNames[this.state.month]}
              </span>{" "}
              and year <span id="month-year-text">{this.state.year}</span>.
            </span>
            <br />
            <br />
            <span>
              Do you want to replace the file on server with{" "}
              <span id="month-year-text">{this.state.selectedFile.name}</span>?
            </span>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.uploadAndReplaceFile}
              id="yes-no-button"
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              onClick={this.dontReplaceFile}
              id="yes-no-button"
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  showFileFormatErrorModal = () => {
    return (
      <div>
        <Modal
          isOpen="true"
          centered="true"
          id="file-format-error-modal"
          size="sm"
        >
          <ModalHeader id="file-format-error-modal-head">
            <h9 id="wrongformattext">Wrong File Format</h9>
          </ModalHeader>
          <ModalBody>
            <span>Please upload xlsx file only</span>
            <br />
            <br />
            <br />
            <br />
          </ModalBody>
          <ModalFooter id="file-format-error-modal-footer">
            {" "}
            <Button
              color="secondary"
              size="sm"
              onClick={() =>
                this.setState({ wrongFileFormat: false, selectedFile: null })
              }
              id="file-format-error-modal-close-button"
            >
              <div id="close-file-format-modal-text">CLOSE</div>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  showFileUpload = () => {
    return (
      <React.Fragment>
        <Header open={this.props.open} setOpen={this.props.setOpen}>
          <h5 className="header-common-heading">Upload Data</h5>
        </Header>
        {this.state.showFileReplace
          ? this.showFileUploadReplaceConfirmationModal()
          : ""}
        {/*{this.state.wrongFileFormat ? this.showFileFormatErrorModal() : ""}*/}
        {this.state.showAlert ? (
          <AutoCloseAlert
            color={this.state.alertcolor}
            body={this.state.alertMsg}
            showAlert={this.state.showAlert}
            setShowAlert={this.setShowAlert}
          />
        ) : (
          ""
        )}
        <div>
          <Row>
            <Col
              lg={{ size: 4, offset: 4 }}
              md={{ size: 6, offset: 3 }}
              sm={{ size: 8, offset: 2 }}
            >
              <Row>
                <Col>
                  <div id="select-year-text">
                    &nbsp;Select Year
                    <br />
                    {this.createYearDropdown()}
                  </div>
                </Col>
                <Col>
                  <div id="select-month-text">
                    &nbsp;Select Month
                    <br />
                    {this.createMonthDropdown()}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              lg={{ size: 4, offset: 4 }}
              md={{ size: 6, offset: 3 }}
              sm={{ size: 8, offset: 2 }}
            >
              <h6>Upload File</h6>
              <div id="file-should-be-text">File should be in .XLSX format</div>
              <br />
              <div id="dropzone-div">
                {this.state.selectedFile ? (
                  <div>
                    <br />
                    <PublishIcon id="publish-icon" />
                    <br />
                    <span>{this.state.selectedFile.name}</span>
                    <br />
                    <br />
                    <Button
                      color="link"
                      onClick={this.onFileRemove}
                      id="remove-button"
                    >
                      Remove
                    </Button>
                    <br />
                    <br />
                  </div>
                ) : (
                  <Dropzone
                    onDrop={this.onFileChange}
                    onChange={this.onFileChange}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <br />
                          <PublishIcon id="publish-icon" />
                          <p id="drag-and-drop-text">
                            Drag and Drop your file here <br /> or{" "}
                          </p>
                          <Button
                            color="link"
                            onClick={this.onFileChange}
                            id="browse-button"
                          >
                            BROWSE
                          </Button>
                          <br />
                          <br />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              </div>
              <br />
              <BootstrapTooltip
                title={
                  this.state.selectedFile !== null &&
                  this.state.year !== null &&
                  this.state.month !== null
                    ? this.state.wrongFileFormat === true
                      ? "Please select xlsx file only"
                      : "Upload"
                    : "Please select year, month and a file to enable upload"
                }
              >
                <Button
                  color="primary"
                  disabled={
                    this.state.selectedFile != null &&
                    this.state.year != null &&
                    this.state.month != null
                      ? this.state.wrongFileFormat === true
                        ? true
                        : false
                      : true
                  }
                  id="main-upload-button"
                  block
                  onClick={this.onFileUpload}
                >
                  {this.state.uploading ? (
                    <div>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span> UPLOADING...
                    </div>
                  ) : (
                    "UPLOAD"
                  )}
                </Button>
              </BootstrapTooltip>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  render() {
    if (this.state.alertMsg === ErrorConstants.ERROR_SESSION) {
      return <SessionExpired />;
    }
    if (this.state.wrongFileFormat === true) {
    }
    if (this.state.goToTrainModelPage) {
      return (
        <Redirect
          to={{
            pathname: "/ui/landing/trainmodel",
            state: {},
          }}
        />
      );
    }
    if (this.state.fileUploadError) {
      return this.showFileUploadError();
    }
    if (this.state.fileUploadSuccessful) {
      return this.showFileUploadSuccess();
    }
    return this.showFileUpload();
  }
}

export default UploadDataPage;
