import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Carousel from "react-multi-carousel";
import { MdArrowBack, MdArrowForward, MdInfo } from "react-icons/md";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Row, Col, Modal, ModalBody, Table, Button } from "reactstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";
import Select from "react-select";
import chroma from "chroma-js";

import "react-multi-carousel/lib/styles.css";
import PageSpinner from "../../../PageSpinner";
import "../../../../styles/components/Chart.scss";
import * as FilterProcessingService from "../../../../services/FilterProcessingService";
import { HEADER_TYPE_TEXT } from "../../../RequestHeader";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {compareAsc, compareDesc, isValid, parse} from 'date-fns';

const inOutDateOptions = [
  { value: "In date", label: "In date", color: "#36B37E" },
  { value: "out of date", label: "Out of date", color: "#FF5630" },
];

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#268cd1",
    fontFamily: "Ubuntu-Regular",
    fontSize: "0.8rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "0.5px solid #F0F0F0",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontSize: "0.46rem",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: "#334d6e",
  }),
};

class MonthsActivityChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActivity: "",
      projectIdName: [],
      AllRadioChecked: true,
      RedRadioChecked: false,
      GreenRadioChecked: false,
      BlueRadioChecked: false,
      AmberRadioChecked: false,
      NARadioChecked: false,
      monthsChartData: null,
      selectedActivityType: 'R',
      isLoading: false,
      selectedValue: [],
      sortData: {
        hitem:'',
        dir:'',
      }
    };
    this.searchByActivity = this.searchByActivity.bind(this);
    this.onBarClick = this.onBarClick.bind(this);
    this.fetchActivityProjectsBreakdownData =
      this.fetchActivityProjectsBreakdownData.bind(this);
    this.closeProjectListModal = this.closeProjectListModal.bind(this);
    this.downloadTablePdf = this.downloadTablePdf.bind(this);
    this.searchByProject = this.searchByProject.bind(this);
  }

  SetSortData(sortData){
    if(this.state.listOfProjectsDisplayData && this.state.listOfProjectsDisplayData.length){
      const data = [...this.state.listOfProjectsDisplayData];
      const getSortOrder = ({hitem:prop, dir}) => {
        return function (a, b) {
            if((a[prop] > b[prop])){
                return dir === 'asc'?1:-1;
            }
            else if((a[prop] < b[prop])){
                return dir === 'asc'?-1:1;
            }
            return 0;
        };
      };
      data.sort(getSortOrder(sortData));
      this.setState({listOfProjectsDisplayData: data,sortData});
    }
  }

  searchByActivity(event) {
    let value = event.target.value;
    this.setState({ selectedActivity: value });
  }

  fetchActivityProjectsBreakdownData(activity, month, year, activityVal) {
    const filters = this.props.filterData;
    let filterQuery;
    if (filters) {
      const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
      filterQuery = JSON.stringify(encodedFilters);
    } else {
      filterQuery = "";
    }

    axios({
      url: `${[
        process.env.REACT_APP_BACKEND_API,
      ]}/dashboard/performance-by-months/projectBreakdown/${activity}/${activityVal}?filters=${filterQuery}&month=${month}&year=${year}`,
      method: "GET",
      headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
    })
      .then((response) => {
        const listOfProjectsData = response.data.chartData;
        let projectIdName = [];

        for (let k = 0; k < listOfProjectsData.length; k++) {
          projectIdName.push(
            listOfProjectsData[k].projectId +
              "(" +
              listOfProjectsData[k].projectName +
              ")"
          );
        }

        this.setState({
          listOfProjectsData: listOfProjectsData,
          listOfProjectsDisplayData: listOfProjectsData,
          showProjectListModal: true,
          projectIdName: projectIdName,
          selectedValue:[]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onBarClick(event, i, activity, monthToYearMapping) {
    const monthNames = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    if (i && i[0]) {
      let month = monthNames[i[0]._view.label];
      let year = monthToYearMapping[i[0]._view.label];
      let selectedDatasetIndex =
        i[0]._chart.getElementAtEvent(event)[0]._datasetIndex;
      let activityVal = "G";

      if (selectedDatasetIndex == 1) {
        activityVal = "B";
      } else if (selectedDatasetIndex == 2) {
        activityVal = "R";
      } else if (selectedDatasetIndex == 3) {
        activityVal = "A";
      } else if (selectedDatasetIndex == 4) {
        activityVal = "NA";
      }

      this.setState(
        {
          activitySelected: activity,
          monthSelected: i[0]._view.label,
          yearSelected: year,
          activityValSelected: activityVal,
        },
        this.fetchActivityProjectsBreakdownData(
          activity,
          month,
          year,
          activityVal
        )
      );
    }
  }

  downloadTablePdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let title = "List of projects";

    if (
      this.props.csvColumnMapping &&
      this.props.csvColumnMapping[this.state.activitySelected]
    ) {
      title =
        "List of projects for " +
        this.props.csvColumnMapping[this.state.activitySelected] +
        " with " +
        this.state.activityValSelected +
        " in " +
        this.state.monthSelected +
        " " +
        this.state.yearSelected;
    }
    let headers, data
    if (this.state.activityValSelected == 'B') {
      headers = [["Project Id", "Project Name"]];
      data = this.state.listOfProjectsDisplayData.map((elt) => [
        elt.projectId,
        elt.projectName
      ]);
    }
    else {
      if (this.state?.activitySelected == 'c_is_the_output_being_delivered_on_a_temp_solution') {
        // for c_is_the_output_being_delivered_on_a_temp_solution show extra column Permanent solution delivery date 
        headers = [["Project Id", "Project Name", "Permanent solution delivery date", "In date / out of date"]];
        data = this.state.listOfProjectsDisplayData.map((elt) => [
          elt.projectId,
          elt.projectName,
          elt.permanentSolutionDate,
          elt["In date / out of date"]
        ]);
      } else if (this.state?.activitySelected == 'c_detailed_design_tap_4') {
        // for c_detailed_design_tap_4 show extra column Forecasted Start On Site Date 
        headers = [["Project Id", "Project Name", "Forecasted Start On Site Date", "In date / out of date"]];
        data = this.state.listOfProjectsDisplayData.map((elt) => [
          elt.projectId,
          elt.projectName,
          elt.forecastedStartOnSiteDate,
          elt["In date / out of date"]
        ]);
      } else if (this.state?.activitySelected == 'c_has_the_capitalisation_process_been_completed_for_the_output') {
        // for c_has_the_capitalisation_process_been_completed_for_the_output show extra column Forecasted Contract Completion Date
        headers = [["Project Id", "Project Name", "Forecasted Contract Completion Date", "In date / out of date"]];
        data = this.state.listOfProjectsDisplayData.map((elt) => [
          elt.projectId,
          elt.projectName,
          elt.forecastedContractCompletionDate,
          elt["In date / out of date"]
        ]);
      }      
      else {
        headers = [["Project Id", "Project Name", "In date / out of date"]];
        data = this.state.listOfProjectsDisplayData.map((elt) => [
          elt.projectId,
          elt.projectName,
          elt["In date / out of date"]
        ]);
      }      
      
    }
    let content = {
      didParseCell: (data) => {
        if (data.cell.raw == "G") {
          data.cell.styles.textColor = [45, 197, 147];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "-") {
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "R") {
          data.cell.styles.textColor = [228, 82, 102];
          data.cell.styles.halign = "center";
        }
        if (data.cell.raw == "A") {
          data.cell.styles.textColor = [255, 191, 0];
          data.cell.styles.halign = "center";
        }
      },
      didDrawPage: (data) => {},
      startY: 50,
      head: headers,
      body: data,
      theme: "grid",
      fontStyle: "bold",
      headStyles: { halign: "center" },
      columnStyles: { 0: { halign: "center" } },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(title + ".pdf");
  };

  searchByProject(event, val) {
    let value = val.split("(")[0];
    let filteredData = [];
    let dataArray = [];

    if (value === null || value === "") {
      this.setState({
        listOfProjectsDisplayData: this.state.listOfProjectsData,
      });
    } else {
      this.state.listOfProjectsData.map((data) => {
        dataArray.push(data);
        if (data.projectId.toLowerCase().indexOf(value.toLowerCase()) === 0) {
          filteredData.push(data);
        }
        if (dataArray.length === this.state.listOfProjectsData.length) {
          this.setState({ listOfProjectsDisplayData: filteredData });
        }
      });
    }
  }

  /*convertExcelDateToDDMMYY(excelDate) {
    if (isNaN(excelDate)) {
      excelDate = ''
    }
    if (excelDate == null || excelDate == '') {
      return 'NA'
    }
    const actualDate = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
    return ('0' + actualDate.getDate()).slice(-2) + '-' + ('0' + (actualDate.getMonth() + 1)).slice(-2) + '-' + actualDate.getFullYear();
  }*/

  closeProjectListModal() {
    this.setState({ showProjectListModal: false, currentPage: 0 });
  }

  onRadioCheck = (e) => {
    const selectedActivityType = e.target.value
    this.getAllMonthsData(selectedActivityType)
  };

  getTitle() {
    let title;
    if(this.props.currentStatusPage){
      const selectedVal = this.state?.activityValSelected?.toLowerCase()
      let selected = 'a Red status'
      if (selectedVal === 'r') {
        selected = 'a Red status'
      } else if (selectedVal === 'g') {
        selected = 'a Green status'
      } else if (selectedVal === 'b') {
        selected = 'a Blue status'
      } else if (selectedVal === 'a') {
        selected = 'a Amber status'
      } else if (selectedVal === 'na') {
        selected = 'a NA status'
      }
      title = `List of projects for ${this.props.csvColumnMapping[this.state.activitySelected]} with ${selected} in ${this.state.monthSelected} ${this.state.yearSelected}`;
    } else {
      title = `List of projects for ${this.props.csvColumnMapping[
        this.state.activitySelected
      ]} with ${this.state.activityValSelected} in ${this.state.monthSelected} ${this.state.yearSelected}`;
    }
    return (<span> {title} </span>)
  }

  getAllMonthsData(selectedActivityType) {
    this.setState({ isLoading: true, selectedActivityType: selectedActivityType })
    let existingData = sessionStorage.getItem("MonthsActivityChartData-" + selectedActivityType);
    if (existingData) {
      this.processChartData(JSON.parse(existingData))
    } else {
      const filters = this.props.filterData;
      let filterQuery;
      if (filters) {
        const encodedFilters = FilterProcessingService.escapeFilterValue(filters);
        filterQuery = JSON.stringify(encodedFilters);
      } else {
        filterQuery = "";
      }
      axios({
        url: `${[
          process.env.REACT_APP_BACKEND_API,
        ]}/dashboard/performance-by-months?filters=${filterQuery}&activityType=${selectedActivityType}`,
        method: "GET",
        headers: HEADER_TYPE_TEXT(sessionStorage.getItem("token")),
      })
        .then((response) => {
          const data = response.data.chartData;
          sessionStorage.setItem("MonthsActivityChartData-" + selectedActivityType, JSON.stringify(data));
          this.processChartData(data)
        })
        .catch((error) => {
          console.log("error in fetching all projects(all months)data" + error);
          this.setState(
            {
              isLoading: false,
            }
          );
        });
    }
  }

  processChartData(data){
    let years,
          months,
          chartMonthLables = [],
          activityRGMap = {},
          rVal,
          gVal,
          bVal,
          aVal,
          naVal,
          count = 0,
          latestRedActivityData = [],
          monthsChartData;
        const monthNames = {
          Jan: "1",
          Feb: "2",
          Mar: "3",
          Apr: "4",
          May: "5",
          Jun: "6",
          Jul: "7",
          Aug: "8",
          Sep: "9",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };
        const monthToYearMapping = {};
        let latestYear = true

        if (data && JSON.stringify(data) !== JSON.stringify({})) {
          // get current year
          years = Object.keys(data);
          if (years && JSON.stringify(years) !== JSON.stringify({})) {
            years.forEach((year) => {
              months = Object.keys(data[year]);
              // sort the months Jan to Dec order
              months = months.sort(function (a, b) {
                return monthNames[a] - monthNames[b];
              });
              months.forEach((month) => {
                chartMonthLables.push(month);
                monthToYearMapping[month] = year;
                count++;
                // prepare chart data for activities
                const activityDataArray = data[year][month];
                activityDataArray.forEach((activityObject) => {
                  Object.entries(activityObject).forEach((activityItem) => {
                    let activityName = activityItem[0];
                    if (activityRGMap[activityName] == null) {
                      activityRGMap[activityName] = {};
                    }
                    if (activityRGMap[activityName].gList == null) {
                      activityRGMap[activityName].gList = [];
                    }
                    if (activityRGMap[activityName].rList == null) {
                      activityRGMap[activityName].rList = [];
                    }
                    if (activityRGMap[activityName].bList == null) {
                      activityRGMap[activityName].bList = [];
                    }
                    if (activityRGMap[activityName].aList == null) {
                      activityRGMap[activityName].aList = [];
                    }
                    if (activityRGMap[activityName].naList == null) {
                      activityRGMap[activityName].naList = [];
                    }
                    rVal = activityItem[1].R;
                    gVal = activityItem[1].G;
                    bVal = activityItem[1].B;
                    aVal = activityItem[1].A;
                    naVal = activityItem[1].NA;
                    activityRGMap[activityName].rList.push(rVal ? rVal : "0");
                    activityRGMap[activityName].gList.push(gVal ? gVal : "0");
                    activityRGMap[activityName].bList.push(bVal ? bVal : "0");
                    activityRGMap[activityName].aList.push(aVal ? aVal : "0");
                    activityRGMap[activityName].naList.push(naVal ? naVal : "0");
                    if (latestYear && count == months.length) {
                      // get latest month activities data to find out activity having maximum red. Activity with maximum red will be show first in the chart.
                      const redVal = rVal ? rVal : "0";
                      latestRedActivityData.push({
                        redVal: redVal,
                        activityName,
                      });
                    }
                  });
                });
              });
              latestYear = false;
            });
          }
          // sort by hightest value first
          //latestRedActivityData = latestRedActivityData.sort((a,b) => a.redVal - b.redVal)
          monthsChartData = {
            activityChartData: activityRGMap,
            chartMonthLables: chartMonthLables,
            latestRedActivityData: latestRedActivityData,
            monthToYearMapping: monthToYearMapping,
          };
        } else {
          monthsChartData = null;
        }
        this.setState(
          {
            isLoading: false,
            monthsChartData: monthsChartData,
          }
        );

  }

  forecastDateSort(projectData, asc = true, key){
    if(!projectData || !projectData?.length){
      return;
    }

    let sortArray = [];
    let otherArray = [];
    projectData.forEach((item) => {
      let aDate = parse(item[key].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
      if(isValid(aDate)){
        sortArray.push(item);
      } else {
        otherArray.push(item);
      }
    })
    
    function GetDateSort(prop) {
      return function (a, b) {        
        let aDate = parse(a[prop].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
        let bDate = parse(b[prop].split('-').reverse().join('-'), 'yyyy-MM-dd', new Date());
        return asc?compareAsc(aDate,bDate):compareDesc(aDate,bDate);
      };
    }
    if (sortArray !== null) {
      sortArray.sort(GetDateSort(key));
    }
    this.setState({
      isSortingCalled:
        this.state.isSortingCalled + 1,
        listOfProjectsDisplayData: [...sortArray,...otherArray],
      moreClickKey: '',
      lessClickKey: "",
      forecastMore: (!asc)?key:'',
      forecastLess: (asc)?key:'',
    });
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedValue: selectedList,
    });
    let inOutDateProjectsFilterData = [];
    let dataArray = [];
    const prjData = this.state.listOfProjectsData
    if (!selectedList) {
      this.setState({
        listOfProjectsDisplayData: prjData,
      });
    } else if (selectedList.length == 0) {
      this.setState({
        listOfProjectsDisplayData: prjData,
      });
    } else {
      for (let i = 0; i < selectedList.length; i++) {
        for (let j = 0; j < prjData.length; j++) {
          let data = prjData[j];
          dataArray.push(data);
          if (data["In date / out of date"]) {
            if (
              data["In date / out of date"].toLowerCase() ==
              selectedList[i].value.toLowerCase()
            ) {
              inOutDateProjectsFilterData.push(data);
            }
          } else {
            if (selectedList[i].value == "NA") {
              inOutDateProjectsFilterData.push(data);
            }
          }
        }
        if (
          dataArray.length ===
          selectedList.length * prjData.length
        ) {
          this.setState({
            listOfProjectsDisplayData: inOutDateProjectsFilterData,
          });
        }
      }
    }
  };

  render() {
    const selectedActivityType = this.state.selectedActivityType
    const monthsChartData = this.state.monthsChartData ? this.state.monthsChartData : this.props.monthsChartData
    let GreenRadioChecked= false, BlueRadioChecked=false, AmberRadioChecked=false, NARadioChecked=false, RedRadioChecked=false, AllRadioChecked=false
    let chartAnchor = "end"
    let chartTextAlign = "top"
    if (selectedActivityType === "R") {
      RedRadioChecked = true
    } else if (selectedActivityType === "G") {
      GreenRadioChecked = true
    } else if (selectedActivityType === "B") {
      BlueRadioChecked = true
    } else if (selectedActivityType === "A") {
      AmberRadioChecked = true
    } else if (selectedActivityType === "NA") {
      NARadioChecked = true
    } else {
      AllRadioChecked = true
      chartAnchor = "middle"
      chartTextAlign = "middle"
    }
    let allChartsData = null;
    let activityList = [];
    const activityNames = {
      'R': 'Red',
      'G': 'Green',
      'A': 'Amber',
      'B': 'Blue',
      'All': 'All',
      'NA': 'NA'
    }
    const activityNamesLower = {
      'R': 'red',
      'G': 'green',
      'A': 'amber',
      'B': 'blue',
      'All': 'all',
      'NA': 'NA'
    }
    if (monthsChartData != null) {
      const activityChartData = monthsChartData.activityChartData;
      const chartMonthLables = monthsChartData.chartMonthLables;
      const monthToYearMapping = monthsChartData.monthToYearMapping;

      const chartDataTemplate = {
        data: {
          labels: ["A", "B", "C", "D"],
          datasets: [
            {
              label: "Green",
              backgroundColor: "#96e079", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                color: "black",
                fontSize: 8,
                align: chartTextAlign,
                anchor: chartAnchor,
              },
            },
            {
              label: "Blue",
              backgroundColor: "#77add9", //#C4F2B2',
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                color: "black",
                fontSize: 8,
                align: chartTextAlign,
                anchor: chartAnchor,
              },
            },
            {
              label: "Red",
              backgroundColor: "#E45266",
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                color: "black",
                fontSize: 8,
                align: chartTextAlign,
                anchor: chartAnchor,
              },
            },
            {
              label: "Amber",
              backgroundColor: "#eb7b15",
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                color: "black",
                fontSize: 8,
                align: chartTextAlign,
                anchor: chartAnchor,
              },
            },
            {
              label: "NA",
              backgroundColor: "#f4f4f4",
              //borderColor: 'rgba(255,99,132,1)',
              borderWidth: 0,
              //stack: 1,
              //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              //hoverBorderColor: 'rgba(255,99,132,1)',
              data: null,
              barThickness: 20,
              fontFamily: "Ubuntu-Medium",
              datalabels: {
                display: true,
                color: "black",
                fontSize: 8,
                align: chartTextAlign,
                anchor: chartAnchor,
              },
            },
          ],
        },
      };
      let getChartOptions = (activity, monthToYearMapping) => {
        let options = {
          responsive: false,
          title: {
            display: false,
            text: "",
            position: "left",
            fontFamily: "Ubuntu-Medium",
          },
          legend: {
            display: false,
            position: "left",
            align: "left",
            fontFamily: "Ubuntu-Medium",
          },
          type: "bar",
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  offsetGridLines: false,
                  fontFamily: "Ubuntu-Medium",
                },
                ticks: {
                  display: true,
                  fontColor: "#252525",
                  fontFamily: "Ubuntu-Medium",
                },
                stacked: true,
                display: true,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: "white",
                  borderDash: [5, 3],
                  offsetGridLines: false,
                  fontFamily: "Ubuntu-Medium",
                },
                ticks: {
                  beginAtZero: true,
                  display: false,
                  stepSize: 1,
                  fontColor: "#919BA3",
                  fontFamily: "Ubuntu-Medium",
                },
                stacked: true,
                display: true,
              },
            ],
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 50,
              bottom: 0,
            },
          },
          plugins: {
            datalabels: {
              display: false,
              color: "#919BA3",
              //align: 'top, middle',
              align: "top",
              anchor: "end",
              fontFamily: "Ubuntu-Medium",
            },
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              label: function (tooltipItem, data) {
                let label =
                    data.datasets[tooltipItem.datasetIndex].label || "";

                  if (label) {
                    label += ": ";
                  }
                  label += Math.round(tooltipItem.yLabel * 100) / 100;
                  return label;
              },
            },
          },
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? "pointer" : "default";
          },
          onClick: (event, i) => {
            this.onBarClick(event, i, activity, monthToYearMapping);
          },
        };
        return options;
      };

      if (activityChartData) {
        const latestRedActivityData = monthsChartData.latestRedActivityData;
        const columnsMapping = this.props.csvColumnMapping;

        latestRedActivityData.map((item, index) => {
          activityList.push(columnsMapping[item.activityName]);
        });

        if (
          this.state.selectedActivity == "" ||
          this.state.selectedActivity == null
        ) {
          allChartsData = latestRedActivityData.map((item, index) => {
            let chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
            const activity = item.activityName;
            const greenDataSet = activityChartData[activity].gList;
            const redDataSet = activityChartData[activity].rList;
            const blueDataSet = activityChartData[activity].bList;
            const amberDataSet = activityChartData[activity].aList;
            const naDataSet = activityChartData[activity].naList;
            chartData.data.datasets[0].data = greenDataSet;
            chartData.data.datasets[1].data = blueDataSet;
            chartData.data.datasets[2].data = redDataSet;
            chartData.data.datasets[3].data = amberDataSet;
            chartData.data.datasets[4].data = naDataSet;
            chartData.data.labels = chartMonthLables;
            chartData.data.datasets[0].datalabels.display = function (context) {
              //display non-zero labels
              return parseInt(context.dataset.data[context.dataIndex]) !== 0;
            };
            chartData.data.datasets[1].datalabels.display = function (context) {
              //display non-zero labels
              return parseInt(context.dataset.data[context.dataIndex]) !== 0;
            };
            chartData.data.datasets[2].datalabels.display = function (context) {
              //display non-zero labels
              return parseInt(context.dataset.data[context.dataIndex]) !== 0;
            };
            chartData.data.datasets[3].datalabels.display = function (context) {
              //display non-zero labels
              return parseInt(context.dataset.data[context.dataIndex]) !== 0;
            };
            chartData.data.datasets[4].datalabels.display = function (context) {
              //display non-zero labels
              return parseInt(context.dataset.data[context.dataIndex]) !== 0;
            };

            if (index == 0) {
              /*const firstChartOptions = JSON.parse(
                JSON.stringify(getChartOptions(activity, monthToYearMapping))
              ); //clone options
              */
             const firstChartOptions = getChartOptions(activity, monthToYearMapping)
              return (
                <div className="bar-chart-first" key={index}>
                  <Bar
                    data={chartData.data}
                    width={220}
                    height={200}
                    options={firstChartOptions}
                  />
                  <div className="chart-activity">
                    {columnsMapping[activity]}
                  </div>
                </div>
              );
            }
            if (index > 0) {
              return (
                <div className="bar-chart" key={index}>
                  <Bar
                    data={chartData.data}
                    width={220}
                    height={200}
                    options={getChartOptions(activity, monthToYearMapping)}
                  />
                  <div className="chart-activity">
                    {columnsMapping[activity]}
                  </div>
                </div>
              );
            }
          });
        } else {
          allChartsData = latestRedActivityData.map((item, index) => {
            if (
              columnsMapping[item.activityName]
                .toLowerCase()
                .indexOf(this.state.selectedActivity.toLowerCase()) == 0
            ) {
              let chartData = JSON.parse(JSON.stringify(chartDataTemplate)); // clone chartDataTemplate
              const activity = item.activityName;
              const greenDataSet = activityChartData[activity].gList;
              const redDataSet = activityChartData[activity].rList;
              const blueDataSet = activityChartData[activity].bList;
              const amberDataSet = activityChartData[activity].aList;
              const naDataSet = activityChartData[activity].naList;
              chartData.data.datasets[0].data = greenDataSet;
              chartData.data.datasets[1].data = blueDataSet;
              chartData.data.datasets[2].data = redDataSet;
              chartData.data.datasets[3].data = amberDataSet;
              chartData.data.datasets[4].data = naDataSet;
              chartData.data.labels = chartMonthLables;
              chartData.data.datasets[0].datalabels.display = function (
                context
              ) {
                //display non-zero labels
                return parseInt(context.dataset.data[context.dataIndex]) !== 0;
              };
              chartData.data.datasets[1].datalabels.display = function (
                context
              ) {
                //display non-zero labels
                return parseInt(context.dataset.data[context.dataIndex]) !== 0;
              };
              chartData.data.datasets[2].datalabels.display = function (
                context
              ) {
                //display non-zero labels
                return parseInt(context.dataset.data[context.dataIndex]) !== 0;
              };
              chartData.data.datasets[3].datalabels.display = function (
                context
              ) {
                //display non-zero labels
                return parseInt(context.dataset.data[context.dataIndex]) !== 0;
              };
              chartData.data.datasets[4].datalabels.display = function (
                context
              ) {
                //display non-zero labels
                return parseInt(context.dataset.data[context.dataIndex]) !== 0;
              };

              if (index == 0) {
                const firstChartOptions = JSON.parse(
                  JSON.stringify(getChartOptions(activity, monthToYearMapping))
                ); //clone options
                return (
                  <div className="bar-chart-first" key={index}>
                    <Bar
                      data={chartData.data}
                      width={220}
                      height={200}
                      options={firstChartOptions}
                    />
                    <div className="chart-activity">
                      {columnsMapping[activity]}
                    </div>
                    <br />
                  </div>
                );
              }
              if (index > 0) {
                return (
                  <div className="bar-chart" key={index}>
                    <Bar
                      data={chartData.data}
                      width={220}
                      height={200}
                      options={getChartOptions(activity, monthToYearMapping)}
                    />
                    <div className="chart-activity">
                      {columnsMapping[activity]}
                    </div>
                    <br />
                  </div>
                );
              }
            }
          });
        }
      }
    }
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 5,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const {
        carouselState: { currentSlide },
      } = rest;
      return (
        <div className="carousel-button-group absolute-div">
          <div
            color="corousel"
            className={
              currentSlide === 0
                ? "carousel-btn carousel-btn-pre carousel-btn-disabled"
                : "carousel-btn carousel-btn-pre"
            }
            onClick={() => previous()}
          >
            <MdArrowBack></MdArrowBack>
          </div>
          <div
            color="corousel"
            className={
              currentSlide === goToSlide.length
                ? "carousel-btn carousel-btn-next carousel-btn-disabled"
                : "carousel-btn carousel-btn-next"
            }
            onClick={() => next()}
          >
            <MdArrowForward></MdArrowForward>
          </div>
        </div>
      );
    };
    return (
      <div className="chart-top-carousel">
        <div className="chart-header-carousel">
          Activity performance by month
          <span data-title="The chart represents a month by month trend on the count of projects in every tube map activity by their Red and Green status">
            <MdInfo size={20} className="c-info"></MdInfo>
          </span>
          <span className="line-chart-radio-buttons" style={{float: "right"}}>
                  <label className="radio-label">
                  Red
                    <input
                      type="radio"
                      name="RToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="R"
                      checked={RedRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  Amber
                    <input
                      type="radio"
                      name="AToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="A"
                      checked={AmberRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  Green
                    <input
                      type="radio"
                      name="GToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="G"
                      checked={GreenRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  Blue
                    <input
                      type="radio"
                      name="BToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="B"
                      checked={BlueRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  NA
                    <input
                      type="radio"
                      name="NAToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="NA"
                      checked={NARadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-label">
                  All
                    <input
                      type="radio"
                      name="AllToShow"
                      onClick={(e) => {
                        this.onRadioCheck(e);
                      }}
                      value="All"
                      checked={AllRadioChecked}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </span>
          {/*
          <span id="activity-list-dropdown-span">
            <span className="form-input-wrapper-activity-chart">
              {/*
              <input
                type="text"
                value={this.state.value}
                onChange={this.searchByActivity}
                className="form-input-textbox"
                placeholder="Search By Activity Name"
                style={{ fontSize: "0.78rem" }}
              />
            
              <Autocomplete
                options={activityList}
                onSelect={(e) => this.searchByActivity(e)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search by Activity" />
                )}
                size="small"
              />
            </span>
          </span>
          */}
          <div className="rg-div">
            {RedRadioChecked && <span><div className="r-div"></div>Red</span>
            }
            {AmberRadioChecked && <span><div className="a-div"></div>Amber</span>
            }
            {GreenRadioChecked && <span><div className="g-div"></div>Green</span>
            }
            {BlueRadioChecked && <span><div className="b-div"></div>Blue</span>
            }            
            {NARadioChecked && <span><div className="na-div"></div>NA</span>
            }
            {AllRadioChecked && <span><div className="r-div"></div>Red<div className="a-div"></div>Amber<div className="g-div"></div>Green<div className="b-div"></div>Blue<div className="na-div"></div>NA</span>
            }
          </div>
        </div>
        <br />
        <div className="chart-body-carousel">
          {allChartsData != null && !this.state.isLoading &&(
            <Carousel
              responsive={responsive}
              arrows={false}
              customButtonGroup={<ButtonGroup />}
            >
              {allChartsData}
            </Carousel>
          )}
          {allChartsData == null || this.state.isLoading ? <PageSpinner></PageSpinner> :
            allChartsData != null && allChartsData.length == 0 && (
              <div style={{ lineHeight: '100px' }}>
                {activityNames[this.state.selectedActivityType]} statuses are only shown when all projects have a {activityNamesLower[this.state.selectedActivityType]} status for that activity.
              </div>
            )}
        </div>
        {this.state.showProjectListModal ? (
          <div>
            <Modal
              isOpen="true"
              centered="true"
              size="lg"
              id="current-month-forecast-modal-main"
              className="current-forecasted-modal"
            >
              <ModalBody
                id="current-month-forecast-modal"
                className="tcqs-current-modal-body"
              >
                <CancelIcon
                  onClick={this.closeProjectListModal}
                  className="cancel-close-icon"
                />
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <center>
                      <div id="current-month-forecast-modal-head">
                        {this.getTitle()}
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <center>
                      <div className="form-input-wrapper">
                        <Autocomplete
                          options={this.state.projectIdName}
                          onInputChange={this.searchByProject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Project"
                            />
                          )}
                          size="small"
                        />
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <center>
                      <div>
                        <Table
                          responsive
                          className="stacked-chart-forecast table-current-vs-forecasted table table-md table-scroll-modal table-modal"
                        >
                          <thead className="table-head">
                            <tr>
                              <th className="table-string project-id-col">
                                Project Id
                              </th>
                              <th className="table-string project-name-col">
                                Project Name
                              </th>
                              {this.state.activitySelected == 'c_is_the_output_being_delivered_on_a_temp_solution' &&
                                <th className="table-string project-name-col">
                                  Permanent solution delivery date

                                  <ExpandLessIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,true, 'permanentSolutionDate')}
                                  fontSize="small"
                                  className="expand-less-icon-sorting"
                                  style={{
                                    color:
                                      "permanentSolutionDate" ===
                                      this.state.forecastLess
                                        ? "red"
                                        : "black",
                                  }}
                                />
                                <ExpandMoreIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,false, 'permanentSolutionDate')}
                                  fontSize="small"
                                  className="expand-more-icon-sorting"
                                  style={{
                                    color:
                                      "permanentSolutionDate" ===
                                      this.state.forecastMore
                                        ? "red"
                                        : "black",
                                  }}></ExpandMoreIcon>
                                </th>
                              }
                              {this.state.activitySelected == 'c_detailed_design_tap_4' &&
                                <th className="table-string project-name-col">
                                  Forecasted Start On Site Date

                                  <ExpandLessIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,true, 'forecastedStartOnSiteDate')}
                                  fontSize="small"
                                  className="expand-less-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedStartOnSiteDate" ===
                                      this.state.forecastLess
                                        ? "red"
                                        : "black",
                                  }}
                                />
                                <ExpandMoreIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,false, 'forecastedStartOnSiteDate')}
                                  fontSize="small"
                                  className="expand-more-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedStartOnSiteDate" ===
                                      this.state.forecastMore
                                        ? "red"
                                        : "black",
                                  }}></ExpandMoreIcon>
                                </th>
                              }
                              {this.state.activitySelected == 'c_has_the_capitalisation_process_been_completed_for_the_output' &&
                                <th className="table-string project-name-col">
                                  Forecasted Contract Completion Date

                                  <ExpandLessIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,true, 'forecastedContractCompletionDate')}
                                  fontSize="small"
                                  className="expand-less-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedContractCompletionDate" ===
                                      this.state.forecastLess
                                        ? "red"
                                        : "black",
                                  }}
                                />
                                <ExpandMoreIcon
                                  onClick={() => this.forecastDateSort(this.state.listOfProjectsDisplayData,false, 'forecastedContractCompletionDate')}
                                  fontSize="small"
                                  className="expand-more-icon-sorting"
                                  style={{
                                    color:
                                      "forecastedContractCompletionDate" ===
                                      this.state.forecastMore
                                        ? "red"
                                        : "black",
                                  }}></ExpandMoreIcon>
                                </th>
                              }
                              {this.state.activityValSelected != 'B' &&
                                <th style={{
                                  paddingBottom: "0.2rem",
                                  paddingTop: "0.2rem",
                                  width: "12vw",
                                }}>
                                  <BootstrapTooltip
                                    title="In date / out of date"
                                    placement="top-start"
                                  >
                                    <Select
                                      closeMenuOnSelect={false}
                                      blurInputOnSelect={false}
                                      isMulti
                                      options={inOutDateOptions}
                                      styles={colourStyles}
                                      value={this.state.selectedValue}
                                      onChange={this.onSelect}
                                      placeholder={"In date / out of date"}
                                      isSearchable
                                    />
                                  </BootstrapTooltip>
                                </th>
                              }
                                                       
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listOfProjectsDisplayData
                              ? this.state.listOfProjectsDisplayData.map(
                                  (projectObject) => (
                                    <tr>
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-id-col"
                                      >
                                        {projectObject.projectId}
                                      </td>
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-name-col"
                                      >
                                        {projectObject.projectName}
                                      </td>
                                    {this.state.activitySelected == 'c_is_the_output_being_delivered_on_a_temp_solution' &&
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-name-col"
                                      >
                                        <div style={{paddingLeft: "25px"}}>{projectObject.permanentSolutionDate}</div>
                                      </td>
                                    }
                                    {this.state.activitySelected == 'c_detailed_design_tap_4' &&
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-name-col"
                                      >
                                        <div style={{paddingLeft: "25px"}}>{projectObject.forecastedStartOnSiteDate}</div>
                                      </td>
                                    }
                                    {this.state.activitySelected == 'c_has_the_capitalisation_process_been_completed_for_the_output' &&
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string project-name-col"
                                      >
                                        <div style={{paddingLeft: "25px"}}>{projectObject.forecastedContractCompletionDate}</div>
                                      </td>
                                    }
                                    {this.state.activityValSelected != 'B' &&
                                      <td
                                        style={{ fontFamily: "Ubuntu-Medium" }}
                                        className="table-string in-out-date-col"
                                      >
                                        <div style={{paddingLeft: "40px"}}>{projectObject['In date / out of date']}</div>
                                      </td>
                                    }
                                    </tr>
                                  )
                                )
                              : ""}
                          </tbody>
                        </Table>
                        {this.state.listOfProjectsData ? (
                          ""
                        ) : (
                          <div>No data available for project list</div>
                        )}
                      </div>
                    </center>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 12, offset: 0 }}>
                    <Row>
                      <Col>
                        <center>
                          <Button
                            color="primary"
                            onClick={this.downloadTablePdf}
                            size="sm"
                            className="standard-modal-button"
                            disabled={
                              this.state.listOfProjectsDisplayData
                                ? false
                                : true
                            }
                          >
                            <GetAppIcon fontSize="small" /> DOWNLOAD LIST
                          </Button>
                        </center>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MonthsActivityChart;

const ListProjectHeader = ({ hitem, SetSortData, sortData }) => {

    //console.log(hitem, sortData)

    return (<th
      className="table-string in-out-date-col"
    >
      {hitem}
      <ExpandLessIcon
        onClick={() => {
          SetSortData({ hitem, dir: 'asc' });
        }}
        fontSize="small"
        className="expand-less-icon-sorting"
        style={{
          color:
            (sortData.hitem === hitem && sortData.dir === 'asc')
              ? "red"
              : "black",
        }}
      />
      <ExpandMoreIcon
        onClick={() => {
          SetSortData({ hitem, dir: 'desc' });
        }}
        fontSize="small"
        className="expand-more-icon-sorting"
        style={{
          color:
            (sortData.hitem === hitem && sortData.dir === 'desc')
              ? "red"
              : "black",
        }}
      />
    </th>)
}
