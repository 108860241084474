import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { withRouter } from "react-router-dom";

import TableRegistrationPending from "../components/admin/pending-requests/TableRegistrationPending";
import TableLockedUserPending from "../components/admin/pending-requests/TableLockedUserPending";
import TablePasswordPending from "../components/admin/pending-requests/TablePasswordPending";
import AutoCloseAlert from "../components/AutoCloseAlert";
import { ErrorHandler } from "../components/ErrorHandler";
import Header from "../components/Header";
import PendingRequestsHeader from "../components/admin/pending-requests/PendingRequestsHeader";
import { HEADER_TYPE_JSON } from "../components/RequestHeader";
import * as ErrorConstants from "../src-constants/ErrorConstants";
import SessionExpired from "../components/SessionExpired";
import NotAuthorized from "../components/NotAuthorized";

const GetPendingApprovalUsers = withRouter((props) => {
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(2);
  const [isActivateUserloading, setIsActivateUserLoading] = React.useState(
    true
  );
  const setShowAlert = props.setShowAlert;
  const setAlertMsg = props.setAlertMsg;
  const setAlertcolor = props.setAlertcolor;
  let setX = props.setX;

  useEffect(() => {
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=2`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          let temp = response.data;
          setRows(temp.userList);
          setX(temp.userList.length);
          setIsActivateUserLoading(false);
        }
      })
      .catch((error) => {
        setAlertcolor("danger");
        let alert = ErrorHandler(error);
        setShowAlert(alert.setAlertShow);
        setAlertMsg(alert.setMsgAlert);
      });
  }, [count]);

  return (
    <TableRegistrationPending
      approveUsers={approveUsers}
      rejectUsers={rejectUsers}
      data={rows}
      setData={setRows}
      count={count}
      setCount={setCount}
      isActivateUserloading={isActivateUserloading}
      setShowAlert={setShowAlert}
      setAlertMsg={setAlertMsg}
      setAlertcolor={setAlertcolor}
      showNotifyModal={props.showNotifyModal}
      setShowNotifyModal={props.setShowNotifyModal}
      username={props.username}
      setUsername={props.setUsername}
    />
  );
});

const GetPendingPasswordReset = (props) => {
  const [rows, setRows] = React.useState([]);
  let setY = props.setY;
  const [counter, setCounter] = React.useState(2);
  const [isPasswordResetloading, setIsPasswordResetLoading] = React.useState(
    true
  );
  const setShowAlert = props.setShowAlert;
  const setAlertMsg = props.setAlertMsg;
  const setAlertcolor = props.setAlertcolor;

  useEffect(() => {
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/password-reset`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          let temp = response.data;
          setRows(temp.userList);
          setY(temp.userList.length);
          setIsPasswordResetLoading(false);
        }
      })
      .catch((error) => {
        setAlertcolor("danger");
        let alert = ErrorHandler(error);
        setShowAlert(alert.setAlertShow);
        setAlertMsg(alert.setMsgAlert);
      });
  }, [counter]);

  return (
    <TablePasswordPending
      approvePasswordReset={approvePasswordReset}
      rejectPasswordReset={rejectPasswordReset}
      data={rows}
      setData={setRows}
      counter={counter}
      setCounter={setCounter}
      isPasswordResetloading={isPasswordResetloading}
      setShowAlert={setShowAlert}
      setAlertMsg={setAlertMsg}
      setAlertcolor={setAlertcolor}
      showNotifyModal={props.showNotifyModal}
      setShowNotifyModal={props.setShowNotifyModal}
      username={props.username}
      setUsername={props.setUsername}
    />
  );
};

const GetPendingLockedUsers = withRouter((props) => {
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(2);
  const [isLockedUserloading, setIsLockedUserLoading] = React.useState(
    true
  );
  const setShowAlert = props.setShowAlert;
  const setAlertMsg = props.setAlertMsg;
  const setAlertcolor = props.setAlertcolor;
  let setZ = props.setZ;

  useEffect(() => {
    axios({
      url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users?userStatus=4`,
      method: "GET",
      headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
    })
      .then((response) => {
        if (response.data.success === true) {
          let temp = response.data;
          setRows(temp.userList);
          setZ(temp.userList.length);
          setIsLockedUserLoading(false);
        }
      })
      .catch((error) => {
        setAlertcolor("danger");
        let alert = ErrorHandler(error);
        setShowAlert(alert.setAlertShow);
        setAlertMsg(alert.setMsgAlert);
      });
  }, [count]);

  return (
    <TableLockedUserPending
      lockedUsers={lockedUsers}
      data={rows}
      setData={setRows}
      count={count}
      setCount={setCount}
      isLockedUserloading={isLockedUserloading}
      setShowAlert={setShowAlert}
      setAlertMsg={setAlertMsg}
      setAlertcolor={setAlertcolor}
      showNotifyUnlockModal={props.showNotifyUnlockModal}
      setShowNotifyUnlockModal={props.setShowNotifyUnlockModal}
      username={props.username}
      setUsername={props.setUsername}
    />
  );
});

const approveUsers = (
  index,
  count,
  setCount,
  setShowAlert,
  setAlertMsg,
  setAlertcolor
) => {
  const requestId = index;
  const postData = {
    data: {
      approved: true,
    },
  };

  return axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/${requestId}`,
    method: "PUT",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCount(++count);
      setShowAlert(true);
      setAlertMsg("Success: User activation request approved");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const rejectUsers = (
  index,
  count,
  setCount,
  setShowAlert,
  setAlertMsg,
  setAlertcolor
) => {
  const requestId = index;
  const postData = {
    data: {
      approved: false,
    },
  };

  return axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/${requestId}`,
    method: "PUT",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCount(++count);
      setShowAlert(true);
      setAlertMsg("Success: User activation request rejected");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const lockedUsers = (
  index,
  count,
  setCount,
  setShowAlert,
  setAlertMsg,
  setAlertcolor
) => {
  const requestId = index;

  return axios({
    url: `${[process.env.REACT_APP_BACKEND_API]}/admin/users/unlock/${requestId}`,
    method: "PUT",
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCount(++count);
      setShowAlert(true);
      setAlertMsg("Success: Username unlocked");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const approvePasswordReset = (
  index,
  counter,
  setCounter,
  setShowAlert,
  setAlertMsg,
  setAlertcolor
) => {
  const requestId = index;
  const postData = {
    data: {
      approved: true,
    },
  };

  return axios({
    url: `${[
      process.env.REACT_APP_BACKEND_API,
    ]}/admin/users/password-reset/${requestId}`,
    method: "PUT",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCounter(++counter);
      setShowAlert(true);
      setAlertMsg("Success: Password reset request approved");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const rejectPasswordReset = (
  index,
  counter,
  setCounter,
  setShowAlert,
  setAlertMsg,
  setAlertcolor
) => {
  const requestId = index;
  const postData = {
    data: {
      approved: false,
    },
  };

  return axios({
    url: `${[
      process.env.REACT_APP_BACKEND_API,
    ]}/admin/users/password-reset/${requestId}`,
    method: "PUT",
    data: postData,
    headers: HEADER_TYPE_JSON(sessionStorage.getItem("token")),
  })
    .then((response) => {
      setCounter(++counter);
      setShowAlert(true);
      setAlertMsg("Success: Password reset request rejected");
      setAlertcolor("success");
    })
    .catch((error) => {
      setAlertcolor("danger");
      let alert = ErrorHandler(error);
      setShowAlert(alert.setAlertShow);
      setAlertMsg(alert.setMsgAlert);
    });
};

const CloseNotifyModal = (props) => {
  props.setShowNotifyModal(false);
};

const ShowNotifyUserModal = (props) => {
  return (
    <div>
      <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
        <ModalHeader id="confirmation-modal-head" size="sm">
          <div id="delete-confirmation-text">Notify the user</div>
        </ModalHeader>
        <ModalBody size="sm" id="confirmation-modal-body">
          <div>
            Please notify{" "}
            <span id="delete-confirmation-username-text">{props.username}</span>{" "}
            whether the request is approved or rejected.
          </div>
        </ModalBody>
        <ModalFooter id="confirmation-modal-footer" size="sm">
          <Button
            color="primary"
            size="sm"
            id="yes-no-button"
            active={true}
            onClick={() => {
              CloseNotifyModal(props);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const CloseNotifyUnlockModal = (props) => {
  props.setShowNotifyUnlockModal(false);
};

const ShowNotifyUserUnlockModal = (props) => {
  return (
    <div>
      <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
        <ModalHeader id="confirmation-modal-head" size="sm">
          <div id="delete-confirmation-text">Notify the user</div>
        </ModalHeader>
        <ModalBody size="sm" id="confirmation-modal-body">
          <div>
            Please notify{" "}
            <span id="delete-confirmation-username-text">
              {props.username}
            </span>{" "}
            that the username is unlocked.
          </div>
        </ModalBody>
        <ModalFooter id="confirmation-modal-footer" size="sm">
          <Button
            color="primary"
            size="sm"
            id="yes-no-button"
            active={true}
            onClick={() => {
              CloseNotifyUnlockModal(props);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const PendingRequestsPage = (props) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertcolor, setAlertcolor] = React.useState("success");
  const [showNotifyModal, setShowNotifyModal] = React.useState(false);
  const [showNotifyUnlockModal, setShowNotifyUnlockModal] = React.useState(false);
  const [username, setUsername] = React.useState("");

  if (alertMsg === ErrorConstants.ERROR_SESSION) {
    return <SessionExpired />;
  }
  if (alertMsg === ErrorConstants.ERROR_NOT_AUTHORIZED) {
    return <NotAuthorized />;
  }
  return (
    <React.Fragment>
      <Header open={props.open} setOpen={props.setOpen}>
        <PendingRequestsHeader x={props.x} y={props.y} z={props.z} />
      </Header>
      {showAlert ? (
        <AutoCloseAlert
          color={alertcolor}
          body={alertMsg}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        />
      ) : (
        ""
      )}
      {showNotifyModal ? (
        <ShowNotifyUserModal
          username={username}
          setShowNotifyModal={setShowNotifyModal}
        />
      ) : (
        ""
      )}
      {showNotifyUnlockModal ? (
        <ShowNotifyUserUnlockModal
          username={username}
          setShowNotifyUnlockModal={setShowNotifyUnlockModal}
        />
      ) : (
        ""
      )}
      <div>
        <h6 className="users-list-heading">Reset Password</h6>
        <GetPendingPasswordReset
          y={props.y}
          setY={props.setY}
          setShowAlert={setShowAlert}
          setAlertMsg={setAlertMsg}
          setAlertcolor={setAlertcolor}
          showNotifyModal={showNotifyModal}
          setShowNotifyModal={setShowNotifyModal}
          username={username}
          setUsername={setUsername}
        />
        <br />
        <h6 className="users-list-heading">New Users</h6>
        <GetPendingApprovalUsers
          x={props.x}
          setX={props.setX}
          setShowAlert={setShowAlert}
          setAlertMsg={setAlertMsg}
          setAlertcolor={setAlertcolor}
          showNotifyModal={showNotifyModal}
          setShowNotifyModal={setShowNotifyModal}
          username={username}
          setUsername={setUsername}
        />
        <br />
        <h6 className="users-list-heading">Unlock Users</h6>
        <GetPendingLockedUsers
          z={props.z}
          setZ={props.setZ}
          setShowAlert={setShowAlert}
          setAlertMsg={setAlertMsg}
          setAlertcolor={setAlertcolor}
          showNotifyUnlockModal={showNotifyUnlockModal}
          setShowNotifyUnlockModal={setShowNotifyUnlockModal}
          username={username}
          setUsername={setUsername}
        />
      </div>
    </React.Fragment>
  );
};

export default PendingRequestsPage;
