import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import "../styles/components/SessionExpired.scss";

class SessionExpired extends Component {
  render() {
    sessionStorage.removeItem("token");
    return (
      <div>
        <Modal isOpen="true" centered="true" size="sm">
          <ModalHeader size="sm" id="session-expired-modal-header">
            <div id="session-expired-text">
              <AccessTimeIcon
                fontSize="small"
                color="secondary"
                id="access-time-icon-session"
              />{" "}
              <span id="heading-session">Session Expired!</span>
            </div>
          </ModalHeader>
          <ModalBody size="sm" id="session-expired-modal-body">
            Your session is expired. Please login again
          </ModalBody>
          <ModalFooter size="sm" id="session-expired-modal-footer">
            <Link to={`/signin`}>
              <Button color="secondary" size="sm" id="login-again-button">
                <div id="login-again-button-text">
                  <b>LOGIN</b>
                </div>
              </Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SessionExpired;
