import { Radio, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner, Table } from 'reactstrap';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import GetAppIcon from "@material-ui/icons/GetApp";
import "jspdf-autotable";
import jsPDF from 'jspdf';
import { Redirect } from 'react-router-dom';



const ConfidenceScoreChartDetails = ({ modalTableData, current, modalTitle, activeItem }) => {

    const [goToProjectId, setPerformanceEvent] = useState(null);

    let pathname = `/ui/landing/currentstatus`;
    if (current === false) {
        pathname = `/ui/landing/forecast`;
    }

    if (goToProjectId) {
        return (
            <Redirect
                to={{
                    pathname,
                    state: {
                        projectId: goToProjectId,
                        from: "summary",
                    },
                }}
            />
        );
    }
    return <><ProjectData modalTableData={modalTableData} current={current} activeItem={activeItem} modalTitle={modalTitle} setPerformanceEvent={setPerformanceEvent} /></>;

}



const ProjectData = ({ modalTableData, current, modalTitle, setPerformanceEvent,activeItem }) => {

    const [selectedCurrentProjectValue, setSelectedCurrentProjectValue] = useState(null);
    const [modalTableDataHeader, setModalTableDataHeader] = useState([]);
    const [projectIdName, setProjectIdName] = useState([]);
    const [modalTableFilterData, setModalTableFilterData] = useState([]);
    const [sortData, setSortedData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadTablePdf = () => {

        const unit = "pt";
        const size = "A3"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        let title = modalTitle;

        const headers = [
            ["Project Id", "Project Name", ...modalTableDataHeader],
        ];

        let data = modalTableFilterData.map((dataItem) => [
            dataItem["Project Id"],
            dataItem["Project Name"],
            ...modalTableDataHeader.map((item) => dataItem[item]),
        ]);

        let content = {
            didDrawPage: (data) => { },
            startY: 50,
            head: headers,
            columnStyles: { 2: { halign: "left" } },
            body: data,
            theme: "grid",
            fontStyle: "bold",
            headStyles: { halign: "center" },
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`List of Projects confidenceScore.pdf`);
        setIsDownloading(false);
    }

    // const searchByProject = (event, val) => {
    //     let value = val.split("(")[0];
    //     let modalTableFilterDataLocal = [];
    //     let dataArray = [];
    //     if (value === null || value === "") {
    //         setModalTableFilterData(modalTableData);
    //     } else {
    //         value = value.trim().toLowerCase()
    //         modalTableData.map((data) => {
    //             dataArray.push(data);
    //             if (
    //                 data["Project Id"].toLowerCase() === value
    //             ) {
    //                 modalTableFilterDataLocal.push(data);
    //             }
    //         });
    //         if (dataArray.length === modalTableData.length) {
    //             setModalTableFilterData([...modalTableData]);
    //         } else {
    //             setModalTableFilterData([...modalTableFilterDataLocal]);
    //             debugger;
    //         }
    //     }
    // }

    useEffect(() => {
        if(searchVal){
        let value = searchVal.split("(")[0];
        let modalTableFilterDataLocal = [];
        let dataArray = [];
            value = value.trim().toLowerCase()
            modalTableData.map((data) => {
                dataArray.push(data);
                if (
                    data["Project Id"].toLowerCase() === value
                ) {
                    modalTableFilterDataLocal.push(data);
                }
            });
            if (dataArray.length === modalTableFilterDataLocal.length) {
                setModalTableFilterData([...modalTableData]);
            } else {
                setModalTableFilterData([...modalTableFilterDataLocal]);
                debugger;
            }
        } else {
            setModalTableFilterData(modalTableData);
        }
    },[searchVal,modalTableData]);


    const setSortData = (sortData) => {
        if (modalTableFilterData && modalTableFilterData.length) {
            const data = [...modalTableFilterData];
            const getSortOrder = ({ hitem: prop, dir }) => {
                const transform = k => {
                    if (isNaN(k)) return dir === 'asc' ? Infinity : -Infinity;
                    else return k;
                }

                return function (a, b) {
                    const floatA = transform(Number(a[prop]));
                    const floatB = transform(Number(b[prop]));

                    if ((floatA > floatB)) {
                        return dir === 'asc' ? 1 : -1;
                    }
                    else if ((floatA < floatB)) {
                        return dir === 'asc' ? -1 : 1;
                    }
                    return 0;
                };
            };
            data.sort(getSortOrder(sortData));
            setModalTableFilterData(data);
            setSortedData(sortData);
        }
    };

    // useEffect(() => {
    //     if (modalTableFilterData?.length && !sortData) {
    //         setSortData({ hitem: 'Confidence Score', dir: 'asc' });
    //     }
    // }, [modalTableFilterData, sortData, setSortData])


    useEffect(() => {
        if (modalTableData?.length) {
            const projectIdName = [];
            for (let prj of modalTableData) {
                projectIdName.push(`${prj['Project Id']} (${prj['Project Name']})`);
            }

            setProjectIdName(projectIdName);
            if (modalTableData[0]) {
                const keys = { ...modalTableData[0] };
                delete keys['Project Id'];
                delete keys['Project Name'];
                setModalTableDataHeader(Object.keys(keys));
            }
            setModalTableFilterData(modalTableData);
        }
    }, [modalTableData])



    if (!modalTableData || !modalTableData.length) {
        return (
            <div style={{ height: "55.5vh" }}>
                <br />
                <div className="no-data-available-current-month-activity">
                    There are no projects within the '{activeItem}' phase
                </div>
            </div>
        );
    }


    return (
        <div className="p-3">
            <Row>
                <Col>
                    <div className="d-flex">
                        <div>
                            <Button
                                size="sm"
                                onClick={() =>
                                    setPerformanceEvent(selectedCurrentProjectValue)
                                }
                                id="current-performance-button"
                                disabled={
                                    !selectedCurrentProjectValue
                                }
                                color={
                                    selectedCurrentProjectValue
                                        ? "primary"
                                        : "light"
                                }
                            >
                                {current ? 'Current' : 'Forecasted'} Performance
                            </Button>
                        </div>

                        <center className="flex-grow-1">
                            <div className="form-input-wrapper-current-month-modal">
                                <Autocomplete
                                    options={projectIdName}
                                    onInputChange={(e,val) => {setSearchVal(val)}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search Project"
                                        />
                                    )}
                                    size="small"
                                />
                            </div>
                        </center>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <center>
                        <div className="performance-chart">
                            <Table
                                responsive
                                className="table table-scroll-modal table-modal table-md table-list-of-projects table-tcqs-modal"
                                id="table-task-list"
                            >
                                <thead className="table-head">
                                    <tr>
                                        <th
                                            style={{
                                                paddingBottom: "0.97rem",
                                            }}
                                            className="project-id-col"
                                        >
                                            Project ID
                                        </th>
                                        <th
                                            style={{ paddingBottom: "0.97rem" }}
                                            className="project-name-col"
                                        >
                                            Project Name
                                        </th>
                                        {modalTableDataHeader && modalTableDataHeader.map(
                                            (item, index) => (
                                                <ListProjectHeader hitem={item} key={index} sortData={sortData} SetSortData={(e) => setSortData(e)} />
                                            )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalTableFilterData ? (
                                        modalTableFilterData.length >=
                                            1 ? (
                                            modalTableFilterData.map(
                                                (activityObject, index) => (
                                                    <tr key={index}>
                                                        <td className="table-data-bold project-id-col">
                                                            <div className="d-flex align-items-center">
                                                                <Radio
                                                                    size="small"
                                                                    onChange={() =>
                                                                        setSelectedCurrentProjectValue(activityObject['Project Id'])
                                                                    }
                                                                    value={
                                                                        activityObject['Project Id']
                                                                    }
                                                                    name="radio-buttons"
                                                                    checked={
                                                                        selectedCurrentProjectValue === activityObject['Project Id']
                                                                    }
                                                                />
                                                                {activityObject['Project Id']}
                                                            </div>
                                                        </td>
                                                        <td className="table-data-bold project-name-col">
                                                            {activityObject['Project Name']}
                                                        </td>
                                                        {modalTableDataHeader.map(
                                                            (item, index) => (
                                                                <td
                                                                    key={index}
                                                                    className="table-data-black  centre-align-data"
                                                                >
                                                                    {/* {activityObject[item]} */}
                                                                    <GetColorCodeViaStatus itemVal={activityObject[item]} />
                                                                </td>
                                                            )
                                                        )}
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr></tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                style={{
                                                    width: "100vw",
                                                    paddingLeft: "40vw",
                                                }}
                                            >
                                                <span style={{ marginLeft: "18rem" }}>
                                                    No data available
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </center>
                </Col>
            </Row>
            <Row>
                <Col>
                    <center>
                        <Button
                            color="primary"
                            onClick={() => { setIsDownloading(true); downloadTablePdf() }}
                            size="sm"
                            id="download-list-key-actvity"
                            disabled={
                                modalTableFilterData &&
                                    JSON.stringify(
                                        modalTableFilterData
                                    ) !== JSON.stringify([])
                                    ? false
                                    : true
                            }
                            className="download-list-current-month"
                        >
                            {isDownloading ? (
                                <div>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Loading...</span>{" "}
                                    Downloading...
                                </div>
                            ) : (
                                <div>
                                    <GetAppIcon fontSize="small" /> DOWNLOAD
                                    LIST
                                </div>
                            )}
                        </Button>
                    </center>
                </Col>
            </Row>
        </div>
    );
}

export default ConfidenceScoreChartDetails;




const ListProjectHeader = ({ hitem, SetSortData, sortData }) => {

    let applySorting = hitem.toLowerCase().includes('confidence score');

    if (applySorting) {

        return (<th
            style={{ paddingBottom: "0.97rem" }}
            className="centre-align-data"
        >
            {hitem}
            <ExpandLessIcon
                onClick={() => {
                    SetSortData({ hitem, dir: 'asc' });
                }}
                fontSize="small"
                className="expand-less-icon-sorting"
                style={{
                    color:
                        (sortData.hitem === hitem && sortData.dir === 'asc')
                            ? "red"
                            : "black",
                }}
            />
            <ExpandMoreIcon
                onClick={() => {
                    SetSortData({ hitem, dir: 'desc' });
                }}
                fontSize="small"
                className="expand-more-icon-sorting"
                style={{
                    color:
                        (sortData.hitem === hitem && sortData.dir === 'desc')
                            ? "red"
                            : "black",
                }}
            />
        </th>)
    } else {
        return <th style={{ paddingBottom: "0.97rem" }} className="centre-align-data">
            {hitem}
        </th>
    }
}




const GetColorCodeViaStatus = ({ itemVal }) => {
    let classVal = "";
    if (!itemVal) {
        return <div>-</div>;
    }
    if (itemVal === "NA") {
        return <div></div>;
    }
    switch (itemVal) {
        case "R":
            classVal = "red-circle";
            break;
        case "A":
            classVal = "amber-circle";
            break;
        case "GR":
            classVal = "grey-circle";
            break;
        case "B":
            classVal = "blue-circle";
            break;
        case "G":
            classVal = "green-circle";
            break;
        default:
            classVal = "";
    }
    if (!classVal) {
        return <div>{itemVal}</div>
    }
    return (
        <>
            <div className={classVal}></div>
        </>
    );
};
