import * as GenericConstants from "../src-constants/GenericConstants";

export function convertPerformanceMilestoneData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    outputData.push({
      Milestone: chartNameMapping[item[0]]
        ? chartNameMapping[item[0]]
        : item[0],
      Green: item[1]["G"],
      Red: item[1]["R"],
      Amber: item[1]["A"],
      Blue: item[1]["B"],
      Grey: item[1]["GR"],
      NA: item[1]["NA"],
    });
  });
  return outputData;
}

export function convertTopFocusAreasData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data).forEach((activityItem) => {
    activityItem[1].activities.forEach((focusArea) => {
      outputData.push({
        Activity: chartNameMapping[activityItem[0]]
          ? chartNameMapping[activityItem[0]]
          : activityItem[0],
        FocusArea: chartNameMapping[focusArea["activity_id"].replace(/-/g, "_")]
          ? chartNameMapping[focusArea["activity_id"].replace(/-/g, "_")]
          : focusArea["activity_id"].replace(/-/g, " "),
        Value: parseFloat(focusArea["avg"]).toFixed(2),
      });
    });
  });
  return outputData;
}

export function convertTCQSData(data, chartNameMapping) {
  let outputData = [];
  const labels = ["Time", "Cost", "Quality", "Scope", "H&S", "SME"];
  labels.forEach((item, index) => {
    outputData.push({
      Milestone: chartNameMapping[item] ? chartNameMapping[item] : item,
      Green: data["greenDataSet"][index],
      Red: data["redDataSet"][index],
    });
  });
  return outputData;
}

export function convertPredictionMilestoneData(data, chartNameMapping) {
  let outputData = [];
  data.milestones.forEach((item, index) => {
    outputData.push({
      Milestone: chartNameMapping[item] ? chartNameMapping[item] : item,
      Green: data["greenDataSet"][index],
      Red: data["redDataSet"][index],
    });
  });
  return outputData;
}

export function convertProjectMapData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    let milestone = chartNameMapping[item[0]]
      ? chartNameMapping[item[0]]
      : item[0];
    let value = item[1];
    if (item[0] == "month" || item[0] == "c_month") {
      value = GenericConstants.MONTH_NUMBER_NAME_MAP[value];
    }
    outputData.push({
      "Activities/Milestones": milestone,
      Values: value,
    });
  });
  return outputData;
}

export function convertProjectQualityParamData(data) {
  let outputData = [];
  data.forEach((projectObject) => {
    outputData.push({
      "Project Id": projectObject["c_project_id"],
      "Project Name": projectObject["c_project_name"],
      Time: projectObject["c_time"],
      Cost: projectObject["c_cost"],
      Quality: projectObject["c_quality"],
      "H&S": projectObject["c_h_s"],
      Scope: projectObject["c_scope"],
      SME: projectObject["c_sme_self_assessments"],
    });
  });
  return outputData;
}

export function convertPredictionProjectMilestoneData(
  data,
  selectedMilestone,
  milestoneDisplayText
) {
  let outputData = [];
  data.forEach((projectObject) => {
    let dataObject = {
      "Project Id": projectObject["c_project_id"],
      "Project Name": projectObject["c_project_name"],
    };
    dataObject[milestoneDisplayText] = projectObject[selectedMilestone];
    outputData.push(dataObject);
  });
  return outputData;
}

export function convertCurrentVsForecast(data) {
  let outputData = [];
  Object.entries(data[0]).forEach((item) => {
    outputData.push({
      Month: item[0],
      "Success Percentage": item[1] + "%",
    });
  });
  return outputData;
}
