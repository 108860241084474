import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PageSpinner from "../../PageSpinner";
import TablePagination from "../../TablePagination";
import "../../../styles/components/admin/pending-requests/TableLockedUserPending.scss";

const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    if (index >= 4) {
      return null;
    }
    if (key === "creationDate") {
      //let dateresponse = props.data[key].split(",");
      //props.data[key] = dateresponse[0];
      return null;
    }
    if (key === "lockedDate") {
      let dateresponse = props.data[key].split(",");
      props.data[key] = dateresponse[0];
    }
    if (key === "userId") {
      return null;
    }
    return (
      <td
        key={props.data[key]}
        style={{
          color: index === 0 ? "#252525" : "#919BA3",
          fontFamily: index === 0 ? "Ubuntu" : "Ubuntu-Medium",
          paddingTop: "0.63rem",
        }}
      >
        {props.data[key]}
      </td>
    );
  });
};

class TableLockedUserPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 4,
      showUnlockModal: false,
      username: null,
      requestId: null,
      isSortingCalled: 1,
      lessClickKey: "",
      moreClickKey: "",
    };
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.showUnlockConfirmationModal = this.showUnlockConfirmationModal.bind(this);
    this.onClickingUnlockUser = this.onClickingUnlockUser.bind(this);
    this.onUnlockUser = this.onUnlockUser.bind(this);
    this.toggleUnlockModal = this.toggleUnlockModal.bind(this);
    this.showNotifyUserModal = this.showNotifyUserModal.bind(this);
    this.toggleNotifyModal = this.toggleNotifyModal.bind(this);
  }

  setCurrentPage = function (pageNumber) {
    this.setState({ currentPage: pageNumber });
  };

  getKeys = function () {
    if (this.props.data.length === 0) {
      return null;
    }
    return Object.keys(this.props.data[0]);
  };

  getHeader = function () {
    let keys = this.getKeys();
    if (keys === null) {
      return null;
    }
    return keys.map((key, index) => {
      if (index >= 4) {
        return null;
      }
      let headerlabel = key;
      if (key === "creationDate") {
        //headerlabel = "User Creation Date";
        return null;
      }
      if (key === "lockedDate") {
        headerlabel = "Locked out date";
      }
      if (key === "userId") {
        //headerlabel = "User Creation Date";
        return null;
      }
      return (
        <th key={key}>
          <span>{headerlabel}</span>
          <ExpandLessIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] < b[prop]) {
                    return 1;
                  } else if (a[prop] > b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ lessClickKey: key, moreClickKey: "" });
            }}
            fontSize="small"
            className="expand-less-icon-sorting"
            style={{ color: key === this.state.lessClickKey ? "red" : "black" }}
          />
          <ExpandMoreIcon
            onClick={() => {
              let array = this.props.data;
              function GetSortOrder(prop) {
                return function (a, b) {
                  if (a[prop] > b[prop]) {
                    return 1;
                  } else if (a[prop] < b[prop]) {
                    return -1;
                  }
                  return 0;
                };
              }
              if (array !== null) {
                array.sort(GetSortOrder(key));
              }
              this.props.setData(array);
              this.setState({
                isSortingCalled: this.state.isSortingCalled + 1,
              });
              this.setState({ moreClickKey: key, lessClickKey: "" });
            }}
            fontSize="small"
            className="expand-more-icon-sorting"
            style={{ color: key === this.state.moreClickKey ? "red" : "black" }}
          />
        </th>
      );
    });
  };

  getRowsData = function () {
    let items = this.props.data;
    let count = this.props.count;
    let setCount = this.props.setCount;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;
    let keys = this.getKeys();
    if (keys == null) {
      return null;
    }
    return items
      .slice(
        this.state.currentPage * this.state.pageSize,
        (this.state.currentPage + 1) * this.state.pageSize
      )
      .map((row, index) => {
        let requestId = row.userId;
        let username = row.username;
        return (
          <tr key={index}>
            <RenderRow key={index} data={row} keys={keys} />
            <td>
              <Button
                type="button"
                color="primary"
                id="unlock-button"
                size="sm"
                onClick={this.onClickingUnlockUser.bind(
                  this,
                  requestId,
                  username
                )}
              >
                <div id="unlock-button-text">
                  &#10003; <span className="unlock-string">Unlock</span>
                </div>
              </Button>
              &nbsp;&nbsp;&nbsp;
            </td>
          </tr>
        );
      });
  };


  onClickingUnlockUser(requestId, username) {
    this.setState({
      requestId: requestId,
      showUnlockModal: true,
      username: username,
    });
  }

  toggleUnlockModal() {
    this.setState({
      showUnlockModal: !this.state.showUnlockModal,
    });
  }

  onUnlockUser() {
    let count = this.props.count;
    let setCount = this.props.setCount;
    const setShowAlert = this.props.setShowAlert;
    const setAlertMsg = this.props.setAlertMsg;
    const setAlertcolor = this.props.setAlertcolor;

    this.toggleUnlockModal();
    this.props.setShowNotifyUnlockModal(true);
    this.props.setUsername(this.state.username);
    this.props.lockedUsers(
      this.state.requestId,
      count,
      setCount,
      setShowAlert,
      setAlertMsg,
      setAlertcolor
    );
  }

  showUnlockConfirmationModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Unlock User Confirmation</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Unlock username{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              ?
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              onClick={this.onUnlockUser}
              id="yes-no-button"
              active={false}
            >
              YES
            </Button>{" "}
            <Button
              color="secondary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleUnlockModal}
              active={true}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  

  toggleNotifyModal() {
    this.props.setShowNotifyUnlockModal(false);
  }

  showNotifyUserModal(userId) {
    return (
      <div>
        <Modal isOpen="true" centered="true" id="confirmation-modal" size="sm">
          <ModalHeader id="confirmation-modal-head" size="sm">
            <div id="delete-confirmation-text">Notify the user</div>
          </ModalHeader>
          <ModalBody size="sm" id="confirmation-modal-body">
            <div>
              Please notify{" "}
              <span id="delete-confirmation-username-text">
                {this.state.username}
              </span>{" "}
              that the username is unlocked.
            </div>
          </ModalBody>
          <ModalFooter id="confirmation-modal-footer" size="sm">
            <Button
              color="primary"
              size="sm"
              id="yes-no-button"
              onClick={this.toggleNotifyModal}
              active={true}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    if (this.props.isLockedUserloading) {
      return <PageSpinner />;
    }

    if (this.getKeys() === null) {
      return (
        <div>
          <div className="table-responsive">
            <table className="table table-border table-sm table-pending-requests table-users">
              <thead className="table-head">
                <tr className="text-capitalize">
                  <th>Username</th>
                  <th>Locked Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
            </table>
            <Card id="no-locked-user-card">
              <h6 id="no-locked-user-heading">
                No data available for locked users
              </h6>
            </Card>
          </div>
          <br />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.state.showUnlockModal ? this.showUnlockConfirmationModal() : ""}

        <div className="table-responsive">
          <table className="table table-border table-sm table-pending-requests table-users">
            <thead className="table-head">
              <tr className="text-capitalize">
                {this.getHeader()}
                <th
                  style={{
                    paddingBottom: "0.68rem",
                  }}
                >
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.getRowsData()}</tbody>
          </table>
        </div>
        {Math.ceil(this.props.data.length / this.state.pageSize) > 1 ? (
          <div>
            <span className="showing-span">
              <span className="showing-text-pagination">Showing </span>
              <span>{this.state.currentPage + 1} </span>
              <span className="showing-text-pagination">out of </span>
              <span>
                {Math.ceil(this.props.data.length / this.state.pageSize)}{" "}
              </span>
              <span className="showing-text-pagination">pages </span>
            </span>
            <div id="locked-user-pagination-div">
              <div id="locked-user-pagination-style">
                <TablePagination
                  pagesCount={Math.ceil(
                    this.props.data.length / this.state.pageSize
                  )}
                  currentPage={this.state.currentPage}
                  setCurrentPage={this.setCurrentPage}
                />{" "}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </React.Fragment>
    );
  }
}

export default TableLockedUserPending;
