import * as GenericConstants from "../src-constants/GenericConstants";

export function convertPerformanceMilestoneData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    outputData.push({
      Milestone: chartNameMapping[item[0]]
        ? chartNameMapping[item[0]]
        : item[0],
      Green: item[1]["G"],
      Red: item[1]["R"],
      Amber: item[1]["A"],
      Blue: item[1]["B"],
      Grey: item[1]["GR"],
    });
  });
  return outputData;
}

export function convertGreenMilestoneData(data) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    outputData.push({
      Month: item[0],
      "Total Percentage": item[1].totalPercentage,
      "Green Percentage": item[1].G,
      "Blue Percentage": item[1].B,
      "Total No. of Projects": item[1].T,
    });
  });
  return outputData;
}

export function convertLineChartData(data) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    Object.entries(item[1]).forEach((mon) => {
      outputData.push({
        Milestone: item[0],
        Month: mon[0],
        Value: mon[1],
      });
    });
  });
  return outputData;
}

export function convertTCQSData(data) {
  let outputData = [];
  const labels = ["Time", "Cost", "Quality", "Scope", "H&S", "SME"];
  labels.forEach((item, index) => {
    outputData.push({
      Milestone: item,
      Green: data["greenDataSet"][index],
      Red: data["redDataSet"][index],
      Amber: data["amberDataSet"][index],
    });
  });
  return outputData;
}

export function convertActivityPerformanceData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data.activityChartData).forEach((activityItem) => {
    data.chartMonthLables.forEach((monthItem, index) => {
      outputData.push({
        Activity: chartNameMapping[activityItem[0]]
          ? chartNameMapping[activityItem[0]]
          : activityItem[0],
        Month: monthItem,
        Green: activityItem[1].gList[index],
        Blue: activityItem[1].bList[index],
        Red: activityItem[1].rList[index],
      });
    });
  });
  return outputData;
}

export function convertProjectMapData(data, chartNameMapping) {
  let outputData = [];
  Object.entries(data).forEach((item) => {
    let milestone = chartNameMapping[item[0]]
      ? chartNameMapping[item[0]]
      : item[0];
    let value = item[1];
    if (item[0] == "month" || item[0] == "c_month") {
      value = GenericConstants.MONTH_NUMBER_NAME_MAP[value];
    }
    if (value != "" && !isNaN(value)) {
      if (Number(value) >= 1) {
        //discard decimal values
        value = Math.round(Number(value));
      } else {
        //retain 2 decimal places
        value = Math.round(Number(value) * 100) / 100;
      }
    }
    outputData.push({
      "Activities/Milestones": milestone,
      Values: value,
    });
  });
  return outputData;
}
